import React, { useEffect } from "react";
import { Collapse } from "antd";

import "./PrivacyPolicy.css";

import { privpol } from "../../reference/PrivacyPolicy";
import { ModalContext } from "../../hooks/ModalContext";

import FadeView from "./FadeView";

const { Panel } = Collapse;

function PrivacyPolicy() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <FadeView show={true} duration="0">
                  <div className="privacypolicy">
                     <div className="privacypolicy-wrapper">
                        <div className="privacypolicy-header">
                           <div className="privacypolicy-title bold-bold-font">Kebijakan Privasi</div>
                           <div className="privacypolicy-date big-font">Mulai 1 Oktober 2021</div>
                        </div>
                        <div className="privacypolicy-desc big-font">
                           {privpol.descriptions.map((description) => {
                              return <p>{description}</p>;
                           })}
                        </div>
                        <Collapse>
                           {privpol.articles.map((article, key) => (
                              <Panel header={article.title} key={key} className="big-font">
                                 <div className="big-font">
                                    <ol type="1">
                                       {article.clauses.map((clause) => {
                                          return (
                                             <li>
                                                {/* { Menampilkan Pasal } */}
                                                {clause.content}
                                                <ol type="a">
                                                   {clause.subitems.map((subitem) => {
                                                      return (
                                                         <li>
                                                            {/* { Menampilkan Tiap Ayat Per Pasal } */}
                                                            {subitem.text}
                                                            <ul>
                                                               {subitem.points.map((point) => {
                                                                  return (
                                                                     <li>
                                                                        {/* { Menampilkan Tiap Poin Per Ayat } */}
                                                                        {point}
                                                                     </li>
                                                                  );
                                                               })}
                                                            </ul>
                                                         </li>
                                                      );
                                                   })}
                                                </ol>
                                             </li>
                                          );
                                       })}
                                    </ol>
                                 </div>
                              </Panel>
                           ))}
                        </Collapse>
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default PrivacyPolicy;
