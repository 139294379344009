import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { GoChevronRight } from "react-icons/go";

import { PageView, ShipmentSteps, CustomButton } from "../common";

import { CacheService, ProviderService, ErrorService } from "../../service";

import { Provider, Shipment, Fee, Service, Place, Voucher, User } from "../../model";

import { getEmptyProviders } from "../../reference/Provider";
import { defaultPlace } from "../../reference/Place";
import * as Master from "../../reference/Master";
import * as Category from "../../reference/Category";

import * as PricingHelper from "../../helper/PricingHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as AlertHelper from "../../helper/AlertHelper";
import * as FormatHelper from "../../helper/FormatHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";
import * as CommonHelper from "../../helper/CommonHelper";

import * as Config from "../../Config";

import "./SelectProvider.css";

let modalContext: ModalContextInterface;

export default function SelectProvider(props: any) {
   const history = useHistory();

   const pageSize = 6;
   const [providers, setProviders] = useState(getEmptyProviders(pageSize));
   const [pageProviders, setPageProviders] = useState<Provider[]>();
   const [tollFeeAmount, setTollFeeAmount] = useState(0);
   const [ferryFeeAmount, setFerryFeeAmount] = useState(0);
   const [page, setPage] = useState(CacheService.getPage());
   const [pageCount, setPageCount] = useState(CacheService.getPageCount());

   const shipment: Shipment = props.shipment;
   const user = CacheService.getUser();
   console.log("user: ", user);

   const serviceCode = shipment.transportTypeCode ? shipment.transportTypeCode : "";
   const distance = shipment.distance ? shipment.distance : 0;
   const weightEst = shipment.weightEst;
   const origin = shipment.origin ? shipment.origin : defaultPlace;
   // const destinations: Place[] = shipment.destinations ? shipment.destinations : new Array<Place>();
   const [destinations] = useState(shipment.destinations ? shipment.destinations : new Array<Place>());
   const categoryCache = CacheService.getCategory();
   const categoryCode = categoryCache ? categoryCache : "";
   const categoryDesc = Category.categorySet.get(categoryCode)?.desc;

   const onPressMore = (provider: Provider) => {
      history.push(`/provider/${provider.gid}`);
   };

   useEffect(() => {
      CacheService.savePrev();
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      const calculatePrice = (provider: Provider, shipmentDistance: number, isComs: boolean, tollPrice: number, ferryPrice: number, shipmentIsReqTransWorker: boolean, voucher?: Voucher) => {
         let pickupFee = 0;

         const transportUnitCost = PricingHelper.getTransportPriceByDistanceWeight(provider.service ? provider.service : new Service(), shipmentDistance, shipment.weightEst);
         const transportFee = PricingHelper.roundThousand(shipmentDistance * calculateUnitPrice(transportUnitCost, isComs));

         const transitCount = shipment.destinations ? shipment.destinations?.length - 1 : 0;
         const transitFee = PricingHelper.roundThousand(transitCount * calculateUnitPrice(provider.service ? provider.service?.transitPrice : 0, isComs));

         const tollFee = PricingHelper.roundThousand(calculateUnitPrice(tollPrice, isComs));
         const ferryFee = PricingHelper.roundThousand(calculateUnitPrice(ferryPrice, isComs));

         const transWorkerBasePrice = PricingHelper.calculateTransWorkerPrice(provider.transWorkerService ? provider.transWorkerService : new Service(), shipmentDistance, shipment.weightEst);
         const transWorkerFee = shipmentIsReqTransWorker ? PricingHelper.roundThousand(calculateUnitPrice(transWorkerBasePrice, isComs)) : 0;

         const adminFee = !shipment.isProviderRef ? PricingHelper.calculateAdminFee(transportFee) : 0;

         const total = pickupFee + transitFee + transportFee + tollFee + ferryFee + transWorkerFee + adminFee;

         const discountAmount = PricingHelper.getDiscount(voucher, total);
         const totalAfterDisc = total - discountAmount;

         return totalAfterDisc;
      };

      const calculateUnitPrice = (basePrice: number, isComs: boolean) => {
         if (isComs) {
            return basePrice + PricingHelper.round((basePrice * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
         } else {
            return basePrice;
         }
      };

      ProviderService.searchServiceProvider(serviceCode, distance, weightEst, origin, destinations, shipment.deliveryDate, shipment.isReqTransWorker ? Master.YES : Master.NO, props.harbor)
         .then((result) => {
            let providers = CommonHelper.copyObject(result.providers);
            console.log("providers old: ", JSON.stringify(providers, null, 2));
            providers = providers.sort(function (providerA: Provider, providerB: Provider) {
               return calculatePrice(providerA, distance, true, 0, 0, false, undefined) - calculatePrice(providerB, distance, true, 0, 0, false, undefined);
            });
            console.log("providers new: ", JSON.stringify(providers, null, 2));

            setProviders(providers);

            setTollFeeAmount(result.tollFee);
            setFerryFeeAmount(result.ferryFee);
            AlertHelper.hideLoading(modalContext);

            if (result.providers && result.providers.length === 0 && (!user || (user && !user.isAdmin))) {
               onContactWa();
            }
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [serviceCode, distance, weightEst, origin, destinations, shipment.deliveryDate, shipment.isReqTransWorker, shipment.destinations, shipment.isProviderRef, shipment.weightEst, history, props.harbor]);

   useEffect(() => {
      let startIndex = (page - 1) * pageSize;
      let endIndex = page * pageSize < providers.length ? page * pageSize : providers.length;
      setPageCount(Math.ceil(providers.length / pageSize));
      setPageProviders(providers.slice(startIndex, endIndex));
   }, [page, providers]);

   const onPressSelect = (provider: Provider) => {
      setShipmentProviderAndFees(props.shipment, provider, tollFeeAmount, ferryFeeAmount);

      CacheService.setNewShipment(props.shipment);
      //console.log('shipment: ', JSON.stringify(CacheService.getNewShipment(), null, 2));

      if (CacheService.getUser() && (CacheService.getUser().customer || CacheService.getUser().isAdmin)) {
         //console.log('getUser: ', CacheService.getUser());
         history.push("/confirm-shipment");
      } else {
         // change otp verification from initial booking to shipment confirmation
         // console.log("user: ", JSON.stringify(CacheService.getUser(), null, 2));
         //history.push("/register/" + (CacheService.getPath() === Master.PATH_BOOKING ? Master.PATH_CUSTOMER : CacheService.getPath()));

         history.push("/login/booking");
      }
   };

   const composeShipmentDetails = () => {
      let shipmentDetails = "";
      let shipment = CacheService.getNewShipment();
      if (shipment && shipment.destinations) {
         const transportTypeDesc = shipment.transportTypeDesc;
         const deliveryDate = CalendarHelper.getDayDateMonthTime(shipment.deliveryDate);
         const weight = shipment.weightEst + " Kg";
         let originDestinations = LocationHelper.getSubRegionCity(shipment.origin?.address);
         for (let i = 0; i < shipment.destinations.length; i++) {
            const destination = shipment.destinations[i];
            originDestinations += " > " + LocationHelper.getSubRegionCity(destination.address);
         }
         shipmentDetails = "Jenis angkutan " + transportTypeDesc + " untuk " + originDestinations + ". Pada hari " + deliveryDate + ", berat " + weight + ".";
      }
      return shipmentDetails;
   }

   const onContactWa = () => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Whatsapp Admin - Transporter Not Found");

      let shipment = CacheService.getNewShipment();
      if (shipment && shipment.destinations) {
         window.open(
            "https://wa.me/" +
               Master.MOBILE_LINTAS +
               "?text=" +
               encodeURI("Halo Customer Service, mohon bantuan mencarikan angkutan. " + composeShipmentDetails())
         );
      } else {
         window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Mohon bantuan, tidak menemukan angkutan yang di cari. "));
      }
   };

   const onChangeTransportType = () => {
      history.push("/change-transport-type/" + CacheService.getCategory());
   };

   const onChangeCategory = () => {
      history.push("/change-category");
   };

   const orderWhatsApp = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya nego biaya angkutan. " + composeShipmentDetails()));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event WhatsApp Order");
   };

   const copyProviders = () => {
      if (providers && providers.length > 0) {
         const providerContacts = providers.map((provider) => {return (provider.name ? provider.name.trim() : provider.name) + "," + (provider.phone ? provider.phone.replace("08", "628") : provider.phone)}) 
         // console.log("providerContacts: ", JSON.stringify(providerContacts, null, 2));
         const providerStr = providerContacts.join("\n");
         navigator.clipboard.writeText(providerStr);
         AlertHelper.alertSuccess("Copy Penyedia Jasa Berhasil", modalContext);
      }
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="select-provider-container">
                  <div className="select-provider-content">
                     <ShipmentSteps status={props.shipment.status} />
                     <div className="select-provider-change-type regular-bottom-border" onClick={onChangeCategory}>
                        <div className="regular-bold-font">Ganti Kategori Angkutan</div>
                        <div>
                           <GoChevronRight className="select-provider-navigate" />
                        </div>
                     </div>
                     <div className="select-provider-change-type regular-bottom-border" onClick={onChangeTransportType}>
                        <div className="regular-bold-font">Ganti Tipe Angkutan {categoryDesc}</div>
                        <div>
                           <GoChevronRight className="select-provider-navigate" />
                        </div>
                     </div>
                     {user && user.isAdmin && <div className="select-provider-whatsapp">
                        <CustomButton text="Copy Penyedia Jasa" icon={"copy"} onClick={() => copyProviders()} style={{}} />
                     </div>}
                     {(!user || (user && !user.isAdmin)) && <div className="select-provider-whatsapp">
                        <CustomButton text="Nego Harga via WhatsApp" icon={"whatsapp"} onClick={orderWhatsApp} style={{}} />
                     </div>}
                     <PageView
                        shipment={shipment}
                        serviceProviders={pageProviders}
                        voucher={props.voucher}
                        tollFee={tollFeeAmount}
                        ferryFee={ferryFeeAmount}
                        onPressMore={onPressMore}
                        onPressSelect={onPressSelect}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export function setShipmentProviderAndFees(shipment: Shipment, provider: Provider, tollFeeAmount: number, ferryFeeAmount: number) {
   const path = window.location.pathname;
   const isReview = path.indexOf("/admin") > -1;
   const isProviderRef = shipment.isProviderRef;

   shipment.provider = provider;
   let totalPayment = 0;
   let fees = new Array<Fee>();
   let service = provider.service ? provider.service : new Service();
   let transWorkerService = provider.transWorkerService ? provider.transWorkerService : new Service();

   let providerLocation = provider.location!;
   let pickupLocation = shipment.origin!;
   let pickupDistance = LocationHelper.calcDistance(providerLocation.lat, providerLocation.lng, pickupLocation.lat, pickupLocation.lng);
   let pickupChargeDistance = 0;

   if (pickupDistance > service.pickupFreeKmDistance) {
      pickupChargeDistance = Math.round(pickupDistance - service.pickupFreeKmDistance);
   }

   // if return shipment, waive the pickup fee
   if (!shipment.isReturnShipment && pickupChargeDistance * service.pickupPricePerKm > 0) {
      let pickupFee = new Fee();
      pickupFee.code = Master.FEE_CODE_TRANSPORT;
      pickupFee.description = "Biaya Penjemputan Muatan " + Math.round(pickupDistance) + " KM";
      pickupFee.count = pickupChargeDistance;
      pickupFee.unitPriceBase = service.pickupPricePerKm;
      pickupFee.unitPriceComs = isReview || isProviderRef ? 0 : PricingHelper.round((pickupFee.unitPriceBase * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
      pickupFee.unitPrice = pickupFee.unitPriceBase + pickupFee.unitPriceComs;
      pickupFee.subTotal = PricingHelper.roundThousand(pickupFee.count * pickupFee.unitPrice);
      pickupFee.feeType = Master.FEE_TYPE_PROVIDER;
      pickupFee.groupType = Master.FEE_GROUP_TYPE_SERVICE;
      pickupFee.groupOrder = Master.FEE_GROUP_ORDER_SERVICE;
      totalPayment += pickupFee.subTotal;
      fees.push(pickupFee);
   }

   let serviceFee = new Fee();
   serviceFee.code = Master.FEE_CODE_TRANSPORT;
   serviceFee.description = "Biaya Angkutan " + FormatHelper.formatIntNumber(shipment.distance || 0) + " Km " + shipment.weightEst / 1000 + " Ton";
   serviceFee.count = shipment.distance || 0;
   if (serviceFee.count < service.minKm && serviceFee.count !== 0) {
      serviceFee.count = service.minKm;
   }

   let basePrice = PricingHelper.getTransportPriceByDistance(service, shipment.distance || 0);
   basePrice = basePrice < service.minPrice ? service.minPrice : basePrice;

   let surcharge = 0;

   if (shipment.weightEst > service.capacityKg) {
      surcharge = ((basePrice * (shipment.weightEst - service.capacityKg)) / service.capacityKg) * service.overCapacitySurcharge;
   }

   console.log("weightEst: ", shipment.weightEst);
   console.log("capacityKg: ", service.capacityKg);
   console.log("overCapacitySurcharge: ", service.overCapacitySurcharge);
   console.log("basePrice: ", basePrice);
   console.log("surcharge: ", surcharge);

   serviceFee.unitPriceBase = PricingHelper.round((basePrice + surcharge) / serviceFee.count);
   // handle commission if any
   serviceFee.unitPriceComs = isReview || isProviderRef ? 0 : PricingHelper.round((serviceFee.unitPriceBase * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
   serviceFee.unitPrice = serviceFee.unitPriceBase + serviceFee.unitPriceComs;
   serviceFee.subTotal = PricingHelper.roundThousand(serviceFee.count * serviceFee.unitPrice);
   serviceFee.feeType = Master.FEE_TYPE_PROVIDER;
   serviceFee.groupType = Master.FEE_GROUP_TYPE_SERVICE;
   serviceFee.groupOrder = Master.FEE_GROUP_ORDER_SERVICE;
   totalPayment += serviceFee.subTotal;
   fees.push(serviceFee);

   console.log("serviceFee: ", serviceFee.subTotal);
   console.log("unitPriceBase: ", serviceFee.unitPriceBase);
   console.log("unitPriceComs: ", serviceFee.unitPriceComs);
   console.log("unitPrice: ", serviceFee.unitPrice);
   console.log("distance :", serviceFee.count);
   console.log("minKm: ", service.minKm);

   const transitCount = shipment.destinations ? shipment.destinations.length - 1 : 0;
   if (transitCount > 0) {
      let transitFee = new Fee();
      transitFee.code = Master.FEE_CODE_TRANSPORT;
      transitFee.description = "Biaya Multi-Transit (" + transitCount + " transit)";
      transitFee.count = transitCount;
      transitFee.unitPriceBase = service.transitPrice;
      transitFee.unitPriceComs = isReview || isProviderRef ? 0 : PricingHelper.round((transitFee.unitPriceBase * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
      transitFee.unitPrice = transitFee.unitPriceBase + transitFee.unitPriceComs;
      transitFee.subTotal = PricingHelper.roundThousand(transitFee.count * transitFee.unitPrice);
      transitFee.feeType = Master.FEE_TYPE_PROVIDER;
      transitFee.groupType = Master.FEE_GROUP_TYPE_SERVICE;
      transitFee.groupOrder = Master.FEE_GROUP_ORDER_SERVICE;
      totalPayment += transitFee.subTotal;
      fees.push(transitFee);

      console.log("transitFee: ", transitFee.subTotal);
   }

   // override toll fee
   tollFeeAmount = Math.round((0.1 * serviceFee.unitPriceBase * serviceFee.count) / 10000) * 10000;
   tollFeeAmount = tollFeeAmount > 500000 ? 500000 : tollFeeAmount;
   console.log("tollFeeAmount: ", tollFeeAmount);

   let tollFee = new Fee();
   tollFee.code = Master.FEE_CODE_TRANSPORT;
   tollFee.description = (tollFeeAmount === -1 ? "* Tidak Termasuk " : "") + "Biaya Jalan Tol";
   tollFee.count = 1;
   tollFee.unitPriceBase = tollFeeAmount;
   tollFee.unitPriceComs = isReview || isProviderRef ? 0 : PricingHelper.round((tollFee.unitPriceBase * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
   tollFee.unitPrice = tollFee.unitPriceBase + tollFee.unitPriceComs;
   tollFee.subTotal = PricingHelper.roundThousand(tollFee.count * tollFee.unitPrice);
   tollFee.feeType = Master.FEE_TYPE_PROVIDER;
   tollFee.groupType = Master.FEE_GROUP_TYPE_OTHERS;
   tollFee.groupOrder = Master.FEE_GROUP_ORDER_OTHERS;
   totalPayment += tollFee.subTotal > 0 ? tollFee.subTotal : 0;
   fees.push(tollFee);

   if (shipment.isFerryShipment === true) {
      let ferryFee = new Fee();
      ferryFee.code = Master.FEE_CODE_FERRY;
      ferryFee.description = (ferryFeeAmount === -1 ? "* Tidak Termasuk " : "") + "Biaya Penyebrangan Antar Pulau";
      ferryFee.count = 1;
      ferryFee.unitPriceBase = ferryFeeAmount;
      ferryFee.unitPriceComs = isReview || isProviderRef ? 0 : PricingHelper.round((ferryFee.unitPriceBase * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
      ferryFee.unitPrice = ferryFee.unitPriceBase + ferryFee.unitPriceComs;
      // console.log("unit price: " + ferryFee.unitPrice + ", coms: ", ferryFee.unitPriceBase, ", coms: ", ferryFee.unitPriceComs);
      ferryFee.subTotal = PricingHelper.round10Thousand(ferryFee.count * ferryFee.unitPrice);
      ferryFee.feeType = Master.FEE_TYPE_PROVIDER;
      ferryFee.groupType = Master.FEE_GROUP_TYPE_OTHERS;
      ferryFee.groupOrder = Master.FEE_GROUP_ORDER_OTHERS;
      totalPayment += ferryFee.subTotal > 0 ? ferryFee.subTotal : 0;
      fees.push(ferryFee);
   }

   if (shipment.isReqTransWorker) {
      let transWorkerFee = new Fee();
      transWorkerFee.code = Master.FEE_CODE_TKBM;
      transWorkerFee.description = "Biaya Tenaga Kerja Bongkar Muat";

      transWorkerFee.count = 1;

      let basePrice = PricingHelper.getTransportPriceByDistance(transWorkerService, shipment.distance || 0);
      let surcharge = 0;

      if (shipment.weightEst > transWorkerService.capacityKg) {
         surcharge = ((basePrice * (shipment.weightEst - transWorkerService.capacityKg)) / transWorkerService.capacityKg) * transWorkerService.overCapacitySurcharge;
      }

      transWorkerFee.unitPriceBase = basePrice + surcharge;

      transWorkerFee.unitPriceComs = isReview || isProviderRef ? 0 : PricingHelper.round((transWorkerFee.unitPriceBase * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
      transWorkerFee.unitPrice = transWorkerFee.unitPriceBase + transWorkerFee.unitPriceComs;
      transWorkerFee.subTotal = PricingHelper.roundThousand(transWorkerFee.count * transWorkerFee.unitPrice);
      transWorkerFee.feeType = Master.FEE_TYPE_PROVIDER;
      transWorkerFee.groupType = Master.FEE_GROUP_TYPE_OTHERS;
      transWorkerFee.groupOrder = Master.FEE_GROUP_ORDER_OTHERS;
      totalPayment += transWorkerFee.subTotal;
      fees.push(transWorkerFee);

      console.log("transWorkerFee: ", transWorkerFee.subTotal);
   }

   let adminFee = new Fee();
   if (!shipment.isProviderRef) {
      adminFee.code = Master.FEE_CODE_ADMIN;
      adminFee.description = "Biaya Administrasi";
      adminFee.count = 1;
      adminFee.unitPriceBase = 0;
      adminFee.unitPriceComs = PricingHelper.calculateAdminFee(serviceFee.subTotal);
      adminFee.unitPrice = adminFee.unitPriceBase + adminFee.unitPriceComs;
      adminFee.subTotal = adminFee.count * adminFee.unitPrice;
      adminFee.feeType = Master.FEE_TYPE_PLATFORM;
      adminFee.groupType = Master.FEE_GROUP_TYPE_OTHERS;
      adminFee.groupOrder = Master.FEE_GROUP_ORDER_OTHERS;
      totalPayment += adminFee.subTotal;
      fees.push(adminFee);
   }

   shipment.isReturnShipment = service.returnPriceRatio < 100;

   shipment.payment.discountAmount = 0;
   shipment.payment.totalCharge = totalPayment;
   shipment.payment.totalPaymentAmount = totalPayment;

   shipment.fees = fees;

   // console.log("shipment: ", shipment);
   // console.log("tollFee: ", tollFee.subTotal);
   // console.log("adminFee: ", adminFee.subTotal);
   // console.log("totalPayment: ", totalPayment);
}
