import { Voucher, Image } from ".";

export default class Payment {
   id?: number;
   gid?: string;
   voucher?: Voucher;
   type: string;
   totalCharge: number;
   discountCode: string;
   discountType: string;
   discountValue: number;
   discountValueCap: number;
   discountAmount: number;
   // totalPaymentAmount = totalCharge - discountAmount
   totalPaymentAmount: number;
   totalProviderAmount: number;
   totalPlatformAmount: number;
   bookingPaymentAmount: number;
   bookingPaymentDate: Date;
   bookingPaymentRef: string;
   initialPaymentAmount: number;
   initialPaymentDate: Date;
   initialPaymentMethod: string;
   initialPaymentRef: string;
   finalPaymentAmount: number;
   finalPaymentDate: Date;
   finalPaymentMethod: string;
   finalPaymentRef: string;
   platformPaymentAmount: number;
   platformPaymentDate: Date;
   platformPaymentRef: string;
   platformPaymentStatus: string;
   topDuration: number;
   planPaymentBooking: number;
   planPaymentInitial: number;
   planPaymentFinal: number;
   planPayoutBooking: number;
   planPayoutInitial: number;
   planPayoutFinal: number;
   bookingPayoutAmount: number;
   bookingPayoutDate: Date;
   bookingPayoutMethod: string;
   bookingPayoutRef: string;
   initialPayoutAmount: number;
   initialPayoutDate: Date;
   initialPayoutMethod: string;
   initialPayoutRef: string;
   finalPayoutAmount: number;
   finalPayoutDate: Date;
   finalPayoutMethod: string;
   finalPayoutRef: string;
   bookingPaymentImg: Image;
   bookingPaymentImgUrl: string;
   initialPaymentImg: Image;
   initialPaymentImgUrl: string;
   finalPaymentImg: Image;
   finalPaymentImgUrl: string;
   bookingPayoutImg: Image;
   bookingPayoutImgUrl: string;
   initialPayoutImg: Image;
   initialPayoutImgUrl: string;
   finalPayoutImg: Image;
   finalPayoutImgUrl: string;
   status: string;
}
