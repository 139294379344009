import React from "react";
import { Card } from "antd";
import mixpanel from "mixpanel-browser";

import * as Config from "../../../Config";

import { FadeView } from "../../common";

import * as Master from "../../../reference/Master";

import { CacheService } from "../../../service";

import "./Category.css";

function Categories(props: any) {
   let categories = [
      { code: Master.CATEGORY_PICKUP, desc: "Pick Up", url: "./category/category-pickup.svg" },
      { code: Master.CATEGORY_VAN, desc: "Van", url: "./category/category-van.svg" },
      { code: Master.CATEGORY_COLT_DIESEL, desc: "Colt Diesel", url: "./category/category-cdd.svg" },
      { code: Master.CATEGORY_FUSO, desc: "Fuso", url: "./category/category-fuso.svg" },
      { code: Master.CATEGORY_TRONTON, desc: "Tronton", url: "./category/category-tronton.svg" },
      { code: Master.CATEGORY_WINGBOX, desc: "Wingbox", url: "./category/category-wingbox.svg" },
      { code: Master.CATEGORY_TRAILER, desc: "Trailer", url: "./category/category-trailer.svg" },
      { code: Master.CATEGORY_CONTAINER, desc: "Container", url: "./category/category-container.svg" },
   ];
   const { Meta } = Card;

   const onWhatsapp = (type: string) => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI(`Halo Lintas Admin, saya mau cek harga / pesan angkutan ${type}.`));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Select Transport WA");
   };

   return (
      <div className="booking-category-content">
         {categories.map((category, i) => (
            <div
               onClick={() => {
                  const user = CacheService.getUser();
                  if (user && user.isAdmin) {
                     props.onSelect(category.code);
                  } else {
                     // onWhatsapp(category.desc);
                     props.onSelect(category.code);
                  }
               }}
               key={i}
            >
               <Card key={i} hoverable style={{ width: 240 }} cover={<img alt={category.desc} src={process.env.PUBLIC_URL + "/images/" + category.url} />} className="booking-category-item">
                  <Meta title={category.desc} />
               </Card>
            </div>
         ))}
      </div>
   );
}

function RegularCategories(props: any) {
   let categories = [
      { code: Master.CATEGORY_REGULAR, desc: "Reguler", url: "./category/category-cdd.svg" },
      { code: Master.CATEGORY_MONTHLY, desc: "Sewa Bulanan", url: "./category/category-fuso.svg" },
   ];
   const { Meta } = Card;

   const onWhatsapp = (type: string) => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI(`Halo Lintas Admin, saya mau cek harga / pesan angkutan ${type}.`));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Select Transport WA");
   };

   return (
      <div className="booking-category-content">
         {categories.map((category, i) => (
            <div
               onClick={() => {
                  const user = CacheService.getUser();
                  if (user && user.isAdmin) {
                     props.onSelect(category.code);
                  } else {
                     onWhatsapp(category.desc);
                  }
               }}
               key={i}
            >
               <Card key={i} hoverable style={{ width: 240 }} cover={<img alt={category.desc} src={process.env.PUBLIC_URL + "/images/" + category.url} />} className="booking-category-item">
                  <Meta title={category.desc} />
               </Card>
            </div>
         ))}
      </div>
   );
}

function Category(props: any) {
   return (
      <div className="booking-category">
         <div className="booking-category-title">Kategori Angkutan</div>
         <div className="booking-category-description">Pilih kategori angkutan sekali jalan sesuai kebutuhan anda</div>
         <FadeView show={true} duration="0">
            <Categories onSelect={props.onSelect} />
         </FadeView>
         <div className="booking-category-description">Butuh angkutan reguler / sewa bulanan?</div>
         <FadeView show={true} duration="0">
            <RegularCategories onSelect={props.onSelectRegular} />
         </FadeView>
      </div>
   );
}

export default Category;
