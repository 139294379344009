import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AuthService, CacheService } from "../../service";

import JavaScriptInterface from "../../interface/AndroidInterface";

const isWebView = CacheService.isWebView();

declare var android: JavaScriptInterface;

function Logout(props: any) {
   const history = useHistory();

   useEffect(() => {
      AuthService.logout();
      props.setLogin(false);
      props.setUser({});
      history.push("/");

      if (isWebView) {
         android.stopUpdateLocation();
      }
   });

   return <div className="profile">Logging out ...</div>;
}

export default Logout;
