import React from 'react';
import './StoryItem.css';

function StoryItem(props) {
  return (
    <div className="category-story-item">
      <div className="content">
        <div>
          <img
            alt="Story"
            src={
              process.env.PUBLIC_URL + '/images/' + props.story.imageUrl
            }
          />
        </div>
        <div className="title">{props.story.title}</div>
        <i className="fa fa-clock-o fa-lg length-icon" />
        <div className="length">{props.story.length}</div>
      </div>
    </div>
  );
}

export default StoryItem;
