let timer: any;

export const searchInputs = (inputs: string[], searchReq: any, duration?: number) => {
   if (isSearchInputEmpty(inputs) || isSearchInputValid(inputs)) {
      if (timer) {
         clearTimeout(timer);
      }

      timer = setTimeout(
         () => {
            searchReq();
         },
         duration ? duration : 300
      );
   }
};

export const searchInput = (input: string, searchReq: any, duration?: number) => {
   if (isSearchInputEmpty([input]) || isSearchInputValid([input])) {
      if (timer) {
         clearTimeout(timer);
      }

      timer = setTimeout(
         () => {
            searchReq();
         },
         duration ? duration : 300
      );
   }
};

export const isSearchInputValid = (input: string[]) => {
   for (let i = 0; i < input.length; i++) {
      if (input[i] && input[i].length >= 3) {
         return true;
      }
   }
   return false;
};

export const isSearchInputEmpty = (input: string[]) => {
   for (let i = 0; i < input.length; i++) {
      if (input[i] !== "") {
         return false;
      }
   }
   return true;
};
