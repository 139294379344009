import { User, Image } from ".";

export default class Complaint {
   id?: number;
   gid?: string;
   customerComplaintType: string;
   customerComplaintDesc: string;
   customerComplaintDate: Date;
   customerComplaintImages?: Image[];
   customerUser: User;
   transporterResponseType: string;
   transporterResponseDesc: string;
   transporterResponseDate: Date;
   transporterResponseImages?: Image[];
   transporterUser: User;
   providerResponseType: string;
   providerResponseDesc: string;
   providerResponseDate: Date;
   providerResponseImages?: Image[];
   providerUser: User;
   resolutionType: string;
   resolutionDesc: string;
   resolutionDate: Date;
   adminUser: User;
   status: string;
}
