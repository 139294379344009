import React, { useState } from "react";
// import JavaScriptInterface from "../../../interface/AndroidInterface";

// declare var android: JavaScriptInterface;

const error = (error: any) => {
   alert(error + " -> " + JSON.stringify(error, null, 2));
};

function success(position: any) {
   alert("position -> " + JSON.stringify(position, null, 2));
}

export function CaptureUploadTest(props: any) {
   const [position, setPosition] = useState({ lat: 0, lng: 0 });

   navigator.geolocation.watchPosition(
      (p) => {
         setPosition({ lat: p.coords.latitude, lng: p.coords.longitude });
         //console.log('getPosition: ', JSON.stringify(position, null, 2));
      },
      error,
      {
         enableHighAccuracy: true,
         maximumAge: 0,
         timeout: 10000,
      }
   );

   navigator.geolocation.getCurrentPosition(success, error);

   return (
      <div style={{ height: window.innerHeight - 130, overflow: "auto", padding: 10, marginTop: 5, marginBottom: 5 }}>
         Position: {position.lat}, {position.lng}
      </div>
   );
}

export default CaptureUploadTest;
