import React, { useEffect, useState } from "react";
import { Steps } from "antd";

import Skeleton from "react-loading-skeleton";

import { Route } from "../../model";

import * as CalendarHelper from "../../helper/CalendarHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as CommonHelper from "../../helper/CommonHelper";

import * as Master from "../../reference/Master";

import "./RouteView.css";

const { Step } = Steps;

const Description = (props: any) => {
   const route: Route = props.route;
   let date = CalendarHelper.getDayDateMonthTime(route.completedDate);
   if (route.status === Master.STEP_STATUS_PENDING) {
      date = "Estimasi: " + date;
   }
   if (props.route.status) {
      return (
         <div className="description">
            <div className="description-title regular-font">
               {LocationHelper.getPlaceShortAddress(route.place)}
               <br />
               {date}
            </div>
         </div>
      );
   } else {
      return <Skeleton delay={1} count={2} width={300} />;
   }
};

const RouteView = (props: any) => {
   const [current, setCurrent] = useState(-1);

   useEffect(() => {
      if (!props.routes) {
         return;
      }

      // console.log("RouteView: ", props);
      // console.log("Routes: ", JSON.stringify(props.routes, null, 2));

      let current = -1;
      let destIndex = -1;

      let origin;

      for (let i = 0; i < props.routes.length; i++) {
         let route: Route = props.routes[i];
         route = CommonHelper.copyObject(route);
         if (!route.status) {
            return;
         }

         destIndex = i;

         if (i === 0) {
            origin = route.place;
         }
         if (route.status !== Master.STEP_STATUS_COMPLETED) {
            if (i === 0) {
               route.desc = "Lokasi Pengambilan Muatan";
            } else if (i === props.routes.length - 1) {
               route.desc = "Lokasi Tujuan Muatan";
            } else {
               route.desc = "Lokasi Transit Muatan";
            }
            props.setCurrentRoute(route);
            break;
         }

         current = i;
      }

      setCurrent(current);

      if (props.setNextDestination) {
         if (props.stepStatus === "" || props.stepStatus === Master.STEP_CODE_TRAVEL_TO_ORIGIN) {
            props.setNextDestination(origin);
         } else {
            let currentRoute: Route = props.routes[destIndex];
            if (currentRoute) {
               props.setNextDestination(currentRoute.place);
            }
         }
      }

      if (current === props.routes.length - 1) {
         let route: Route = props.routes[current];
         route = CommonHelper.copyObject(route);
         route.desc = "Lokasi Tujuan Muatan";
         props.setCurrentRoute(route);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.routes, props.setCurrentRoute, props.stepStatus]);

   return (
      <div className="route-view">
         <Steps progressDot direction="vertical" current={current}>
            {props.routes &&
               props.routes.map((route: Route, key: number) => {
                  return <Step key={key} title={route.status ? LocationHelper.getSubRegionCity(route.place.address) : <Skeleton delay={1} width={200} />} description={<Description route={route} />} />;
               })}
         </Steps>
      </div>
   );
};

export default RouteView;
