import { User } from "../model";
import * as Master from "../reference/Master";

export function getMenu(user?: User) {
   if (user && user.gid && user.role === Master.ROLE_ADMIN) {
      return [
         {
            title: "Beranda",
            link: "/",
            desc: undefined,
            submenus: [],
            mobile: false,
         },
         {
            title: "Pengiriman",
            link: "/shipment",
            submenus: [],
         },
         {
            title: "Kelola",
            submenus: [
               { title: "Penyedia Jasa", link: "/admin/provider", desc: undefined },
               { title: "Kendaraan", desc: "Kelola Kendaraan", link: "/admin/carrier" },
               { title: "Pengemudi", desc: "Kelola Pengemudi", link: "/admin/transporter" },
               { title: "Layanan", desc: "Kelola Layanan", link: "/admin/service" },
               { title: "Pangkalan", desc: "Kelola Pangkalan", link: "/admin/site" },
               { title: "Pengguna", desc: "Kelola Pengguna", link: "/admin/user" },
               { title: "Pelanggan", desc: "Kelola Pelanggan", link: "/admin/customer" },
               { title: "Voucher", desc: "Kelola Voucher", link: "/admin/voucher" },
               { title: "Cek Angkutan", desc: "Cek Angkutan", link: "/booking" },
               { title: "Tes Pemesanan", desc: "Tes Pemesanan", link: "/test/booking" },
            ],
         },
         {
            title: "Akun",
            submenus: [
               { title: "Profil Saya", link: "/profile", desc: undefined },
               { title: "Keluar", link: "/logout" },
            ],
         },
      ];
   } else if (user && user.gid && user.role === Master.ROLE_PROVIDER) {
      let addOnMenus = [];
      if (user.isAdmin) {
         addOnMenus = [
            { title: "Info Bank", link: "/profile/provider/bank", desc: undefined },
            { title: "Salin Referal Link", link: "#" },
            { title: "Kembali Ke Admin", link: "/admin" },
         ];
      } else {
         addOnMenus = [
            { title: "Profil Saya", link: "/profile", desc: undefined },
            { title: "Review Saya", link: "/review/provider" },
            { title: "Info Bank", link: "/profile/provider/bank" },
            { title: "Salin Referal Link", link: "#" },
         ];
      }

      let adminMenus = [];
      if (user.providerUser?.role === Master.PROVIDER_ROLE_ADMIN) {
         adminMenus = [
            { title: "Kendaraan", desc: "Kelola - Kendaraan", link: "/admin/carrier" },
            { title: "Pengemudi", desc: "Kelola - Pengemudi", link: "/admin/transporter" },
            { title: "Layanan", desc: "Kelola - Layanan", link: "/admin/service" },
            { title: "Pangkalan", desc: "Kelola - Pangkalan", link: "/admin/site" },
            { title: "Pengguna", desc: "Kelola Pengguna", link: "/admin/user" },
         ];
      } else {
         adminMenus = [
            { title: "Kendaraan", desc: "Kelola - Kendaraan", link: "/admin/carrier" },
            { title: "Pengemudi", desc: "Kelola - Pengemudi", link: "/admin/transporter" },
            { title: "Layanan", desc: "Kelola - Layanan", link: "/admin/service" },
            { title: "Pangkalan", desc: "Kelola - Pangkalan", link: "/admin/site" },
         ];
      }

      return [
         {
            title: "Beranda",
            link: "/",
            submenus: [],
            mobile: false,
            desc: undefined,
         },
         {
            title: "Pengiriman",
            link: "/shipment",
            submenus: [],
         },
         {
            title: "Kelola",
            submenus: [...adminMenus, { title: "Cek Harga Angkutan", desc: "Cek Harga Angkutan Layanan", link: "/admin/review" }],
         },
         {
            title: "Bantuan",
            submenus: [
               { title: "Telepon", link: "#" },
               { title: "Chat / Pesan", link: "#" },
            ],
         },
         {
            title: "Akun",
            submenus: [{ title: "Profil Jasa Angkutan", link: "/profile/provider" }, ...addOnMenus, { title: "Keluar", link: "/logout" }],
         },
      ];
   } else if (user && user.gid && user.role === Master.ROLE_TRANSPORTER) {
      return [
         {
            title: "Beranda",
            link: "/",
            submenus: [],
            mobile: false,
            desc: undefined,
         },
         {
            title: "Pengiriman",
            link: "/shipment",
            submenus: [],
         },
         {
            title: "Bantuan",
            submenus: [
               { title: "Telepon", link: "#", desc: undefined },
               { title: "Chat / Pesan", link: "#" },
            ],
         },
         {
            title: "Akun",
            submenus: [
               { title: "Profil Saya", link: "/profile", desc: undefined },
               { title: "Review Saya", link: "/review/transporter" },
               { title: "Keluar", link: "/logout" },
            ],
         },
      ];
   } else if (user && user.gid && user.role === Master.ROLE_CUSTOMER) {
      let submenusPersonal = [];
      let submenusCompany = [];

      if (user.isAdmin) {
         submenusPersonal = [
            { title: "Profil Pelanggan", link: "/profile/customer", desc: undefined },
            { title: "Review Saya", link: "/review/customer" },
            { title: "Kembali Ke Admin", link: "/admin" },
         ];
         submenusCompany = [
            { title: "Profil Pelanggan", link: "/profile/customer", desc: undefined },
            { title: "Profil Saya", link: "/profile" },
            { title: "Kembali Ke Admin", link: "/admin" },
         ];
      } else {
         submenusPersonal = [
            { title: "Profil Pelanggan", link: "/profile/customer", desc: undefined },
            { title: "Review Saya", link: "/review/customer" },
            { title: "Keluar", link: "/logout" },
         ];
         submenusCompany = [
            { title: "Profil Pelanggan", link: "/profile/customer", desc: undefined },
            { title: "Profil Saya", link: "/profile" },
            { title: "Keluar", link: "/logout" },
         ];
      }

      return [
         {
            title: "Beranda",
            link: "/",
            submenus: [],
            mobile: false,
            desc: undefined,
         },
         {
            title: "Pengiriman",
            link: "/shipment",
            submenus: [],
         },
         {
            title: "Cek Harga",
            link: "/booking",
            submenus: [],
         },
         {
            title: "Bantuan",
            submenus: [
               { title: "Telepon", link: "#", desc: undefined },
               { title: "Chat / Pesan", link: "#" },
            ],
         },
         {
            title: "Akun",
            submenus: user.customer?.type === Master.ENTITY_TYPE_CORPORATE ? submenusCompany : submenusPersonal,
         },
      ];
   } else if (user?.mobile && user.mobile !== "") {
      return [
         {
            title: "Beranda",
            link: "/",
            submenus: [],
            mobile: false,
            desc: undefined,
         },
         {
            title: "Keluar",
            link: "/logout",
            submenus: [],
            mobile: false,
            desc: undefined,
         },
         {
            title: "Informasi",
            submenus: [
               { title: "Tentang Kami", link: "/about_us", desc: undefined },
               { title: "Kebijakan Penggunaan", link: "/tnc" },
               { title: "Kebijakan Privasi", link: "/privacy" },
            ],
            mobile: true,
         },
         {
            title: "Jadi Mitra",
            link: "/login/" + Master.PATH_PROVIDER,
            submenus: [],
         },
         {
            title: "Bantuan",
            submenus: [
               { title: "Telepon", link: "#", desc: undefined },
               { title: "Chat / Pesan", link: "#" },
            ],
         },
      ];
   } else {
      return [
         {
            title: "Beranda",
            link: "/",
            submenus: [],
            mobile: false,
            desc: undefined,
         },
         {
            title: "Masuk",
            submenus: [
               { title: "Pelanggan", link: "/login/" + Master.PATH_CUSTOMER, desc: undefined },
               { title: "Mitra", link: "/login/" + Master.PATH_PROVIDER },
               { title: "Pengemudi", link: "/login/" + Master.PATH_TRANSPORTER },
               { title: "Admin", link: "/login/" + Master.PATH_ADMIN },
            ],
         },
         {
            title: "Informasi",
            submenus: [
               { title: "Informasi Muatan", link: "/muatan" },
               { title: "Tentang Kami", link: "/about_us", desc: undefined },
               { title: "Kebijakan Penggunaan", link: "/tnc" },
               { title: "Kebijakan Privasi", link: "/privacy" },
            ],
            mobile: true,
         },
         {
            title: "Jadi Mitra",
            link: "/login/" + Master.PATH_PROVIDER,
            submenus: [],
         },
         {
            title: "Bantuan",
            submenus: [
               { title: "Telepon", link: "#", desc: undefined },
               { title: "Chat / Pesan", link: "#" },
            ],
         },
      ];
   }
}

export function getMenuDesc(user?: User, link?: string) {
   const menus = getMenu(user);
   for (const menu of menus) {
      if (menu.link === link) {
         return menu.desc ? menu.desc : menu.title;
      } else {
         for (const submenu of menu.submenus) {
            if (submenu.link === link) {
               return submenu.desc ? submenu.desc : submenu.title;
            }
         }
      }
   }
   return undefined;
}
