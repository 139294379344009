import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import { CacheService } from "../../../service";

import { CustomButton, SearchInput, SubNavigation, Places } from "../../common";

import { Place } from "../../../model";

import * as PlacesRef from "../../../reference/Place";

import MapService from "../../../service/MapService";

import * as LocationHelper from "../../../helper/LocationHelper";
import * as SearchHelper from "../../../helper/SearchHelper";
import * as AlertHelper from "../../../helper/AlertHelper";

import * as Master from "../../../reference/Master";

import * as Config from "../../../Config";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import "./PlaceSelection.css";

let modalContext: ModalContextInterface;

export default function SearchPlace(props: any) {
   const [isOrigin, setIsOrigin] = useState(true);
   const [index, setIndex] = useState(0);
   const [places, setPlaces] = useState<any>(getDefaultPlaces());
   const [refresh, setRefresh] = useState(true);

   useEffect(() => {
      AlertHelper.enableScroll(!props.show, "PlaceSelection");
   }, [props.show]);

   const selectViaMap = () => {
      if (isOrigin) {
         props.shipment.origin = props.shipment.origin && props.shipment.origin.address ? props.shipment.origin : PlacesRef.defaultPlace;
         //is destination
      } else {
         props.shipment.destination = props.shipment.destination && props.shipment.destination.address ? props.shipment.destination : PlacesRef.defaultPlace;
      }
      props.setShowSelectPlaceMap(true);
      setTimeout(() => {
         props.setPlace(isOrigin ? props.shipment.origin : props.shipment.destinations[index]);
      }, 500);
      props.setSelectPlace(() => onSelectPlace);
   };

   const addDestination = () => {
      props.shipment.destinations = props.shipment.destinations.concat({});
      // console.log("destinations: ", JSON.stringify(props.shipment.destinations, null, 2));
      refreshSearchAddress();
   };

   const searchPlace = (input: any) => {
      setPlaces(PlacesRef.getEmptyPlaces(5));
      SearchHelper.searchInput(
         input,
         () => {
            if (input == "") {
               setPlaces(getDefaultPlaces());
            } else {
               MapService.getOsmPlace(input).then((responseJson) => {
                  setPlaces(responseJson.results);
               });
            }
         },
         1000
      );
   };

   const refreshSearchAddress = () => {
      setRefresh(false);
      setTimeout(() => {
         setRefresh(true);
      }, 0);
   };

   const onSelectPlace = (place: any) => {
      // console.log("place: ", JSON.stringify(place, null, 2));
      if (!place) {
         return;
      }

      if (place.formatted_address) {
         place = {
            name: place.name,
            address: place.formatted_address,
            lat: place.geometry.location.lat,
            lng: place.geometry.location.lng,
         };
      }

      console.log("select place: ", JSON.stringify(place, null, 2));

      if (isOrigin) {
         props.shipment.origin = place;
         // setIsOrigin(false);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Select Origin");
      } else {
         props.shipment.destinations[index] = place;
         props.shipment.destination = props.shipment.destinations[props.shipment.destinations.length - 1];
         //setDestinationAddresses(place.name);
         // setIsOrigin(true);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Select Destination");
      }
      setPlaces(getDefaultPlaces());
      refreshSearchAddress();
      if (props.shipment.destinations.length > 0 && props.shipment.origin && props.shipment.origin.lat === props.shipment.destinations[0].lat && props.shipment.origin.lng === props.shipment.destinations[0].lng) {
         AlertHelper.alertWarning("Lokasi asal dan tujuan tidak boleh sama atau gunakan lokasi yang lebih spesifik. ", modalContext, "warning");
         return;
      } else {
         setIsOrigin(!isOrigin);
         close();
      }
   };

   const close = () => {
      // console.log("shipment: ", JSON.stringify(props.shipment, null, 2));

      if (!props.shipment.origin || (props.shipment.origin && !props.shipment.origin.address)) {
         return;
      }
      for (let i = 0; i < props.shipment.destinations.length; i++) {
         if (!props.shipment.destinations[i] || (props.shipment.destinations[i] && !props.shipment.destinations[i].address)) {
            return;
         }
      }
      CacheService.setNewShipment(props.shipment);
      clearEmptyDestinations();
      props.setShow(false);
   };

   const onClickPrev = () => {
      clearEmptyDestinations();
      props.setShow(false);
   };
   const onClickClose = () => {
      clearEmptyDestinations();
      props.setShow(false);
   };

   const clearEmptyDestinations = () => {
      props.shipment.destinations = props.shipment.destinations.filter((destination: Place) => destination.address);
      if (props.shipment.destinations.length === 0) {
         props.shipment.destinations = props.shipment.destinations.concat({});
         props.shipment.destination = undefined;
      }
      CacheService.setNewShipment(props.shipment);
   };

   const orderWhatsApp = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau cek harga / pesan angkutan"));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event WhatsApp Order");
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               props.show &&
               !props.showSelectPlaceMap && (
                  <div className="select-place">
                     <div className="select-place-container">
                        <SubNavigation title="Lokasi Asal dan Tujuan" onClickPrev={onClickPrev} onClickClose={onClickClose} />
                        <div className="select-place-search-content dark-border">
                           <div className="search">
                              {refresh && (
                                 <SearchInput
                                    placeholder="Cari Lokasi Asal"
                                    onFocus={() => {
                                       setIsOrigin(true);
                                       props.setIsOrigin(true);
                                    }}
                                    onChangeText={(text: any) => {
                                       searchPlace(text);
                                    }}
                                    onClearText={() => {
                                       props.shipment.origin = undefined;
                                    }}
                                    value={props.shipment.origin ? LocationHelper.getSubRegionCity(props.shipment.origin.address) : ""}
                                    className="select-place-search"
                                 />
                              )}
                              {refresh &&
                                 props.shipment.destinations &&
                                 props.shipment.destinations.map((destination: Place, key: number) => (
                                    <SearchInput
                                       placeholder="Cari Lokasi Tujuan"
                                       onFocus={() => {
                                          setIndex(key);
                                          console.log("index: ", index);
                                          setIsOrigin(false);
                                          props.setIsOrigin(false);
                                       }}
                                       onChangeText={(text: string) => {
                                          searchPlace(text);
                                       }}
                                       onClearText={() => {
                                          props.shipment.destinations[key].address = "";
                                          if (props.shipment.destinations.length > 1) {
                                             props.shipment.destinations = props.shipment.destinations.filter((destination: Place) => destination.address);
                                             refreshSearchAddress();
                                          }
                                       }}
                                       value={LocationHelper.getSubRegionCity(destination.address)}
                                       className="select-place-search"
                                       key={key}
                                    />
                                 ))}
                           </div>
                           {refresh && (
                              <div className="buttons">
                                 <CustomButton text="Tambah Tujuan " icon={"add"} onClick={addDestination} style={{ marginTop: 5, marginRight: 5, width: 160 }} />
                                 <CustomButton text="Pilih di Peta " icon={"map"} onClick={selectViaMap} style={{ marginTop: 5, width: 130 }} />
                              </div>
                           )}
                           <div className="origin-destination regular-bold-font">
                              <div className="origin-destination-select-info" style={{ backgroundColor: isOrigin ? "#ffd8e2" : "#fce2c6" }}>
                                 <span style={{}}>Pilih Lokasi {isOrigin ? "Asal" : "Tujuan"}</span>
                              </div>
                              <CustomButton text="Pesan via WhatsApp" icon={"whatsapp"} onClick={orderWhatsApp} style={{}} />
                           </div>
                           <Places data={places} onPress={(place: any) => onSelectPlace(place)} />
                           {!refresh && <div style={{ height: 1000 }} />}
                        </div>
                     </div>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}

function getDefaultPlaces(): any[] {
   const places = [];
   places.push({
      name: "Bandung",
      formatted_address: "Kota Bandung",
      geometry: {
         location: {
            lat: -6.92321,
            lng: 107.61672,
         },
      },
   });
   places.push({
      name: "Bogor",
      formatted_address: "Kota Bogor",
      geometry: {
         location: {
            lat: -6.59144,
            lng: 106.80665,
         },
      },
   });
   places.push({
      name: "Bekasi",
      formatted_address: "Kota Bekasi",
      geometry: {
         location: {
            lat: -6.25689,
            lng: 106.98092,
         },
      },
   });
   places.push({
      name: "Jakarta",
      formatted_address: "Kota Jakarta",
      geometry: {
         location: {
            lat: -6.21812,
            lng: 106.82675,
         },
      },
   });
   places.push({
      name: "Malang",
      formatted_address: "Kota Malang",
      geometry: {
         location: {
            lat: -7.97829,
            lng: 112.628,
         },
      },
   });
   places.push({
      name: "Semarang",
      formatted_address: "Kota Semarang",
      geometry: {
         location: {
            lat: -6.99507,
            lng: 110.42427,
         },
      },
   });
   places.push({
      name: "Surabaya",
      formatted_address: "Kota Surabaya",
      geometry: {
         location: {
            lat: -7.26812,
            lng: 112.74392,
         },
      },
   });
   places.push({
      name: "Tangerang",
      formatted_address: "Kota Tangerang",
      geometry: {
         location: {
            lat: -6.19144,
            lng: 106.56935,
         },
      },
   });

   return places;
}
