import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Input as InputField, Button } from "antd";

import { AuthService, CacheService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import * as Master from "../../reference/Master";

import { FadeView } from "../common";

import "./Login.css";

function Login(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const [init, setInit] = useState(false);
   const [title, setTitle] = useState("");
   const { control, errors, handleSubmit } = useForm();
   const [modalContext, setModalContext] = useState<ModalContextInterface>();

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
      if (!props.showBottomNavigation) {
         let elm = document.getElementById("login-container");
         if (elm) {
            elm.scrollTo({
               top: elm.scrollHeight,
               behavior: "auto",
            });
         }
      }
   }, [init, props]);

   useEffect(() => {
      if (match.params.path) {
         CacheService.setPath(match.params.path);
      }

      const user = CacheService.getUser();
      if (user && !user.gid) {
         history.replace("/register/" + (CacheService.getPath() === Master.PATH_BOOKING ? Master.PATH_CUSTOMER : CacheService.getPath()));
      }

      if (match.params.path === Master.PATH_ADMIN) {
         setTitle("Admin");
      } else if (match.params.path === Master.PATH_BOOKING) {
         setTitle("Pelanggan");
      } else if (match.params.path === Master.PATH_CUSTOMER) {
         setTitle("Pelanggan");
      } else if (match.params.path === Master.PATH_PROVIDER) {
         setTitle("Mitra");
      } else if (match.params.path === Master.PATH_TRANSPORTER) {
         setTitle("Pengemudi");
      }
   }, [match, history]);

   const onSubmit = (data: any) => {
      props.profile.mobile = data.mobile;
      AlertHelper.alertLoading(`Mengirimkan SMS OTP ke nomer ${data.mobile}`, modalContext);

      AuthService.sendOtp(data.mobile, CacheService.getRole())
         .then((result) => {
            AlertHelper.hideLoading(modalContext);
            AlertHelper.enableScroll(true, "Login");
            history.replace("/otp");

            //console.log(JSON.stringify(result));
         })
         .catch((error) => {
            // @ts-ignore
            ErrorService.handleError(error, history, modalContext);
         });
   };
   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <div id="login-container" className={"login-container " + (!props.showBottomNavigation ? "login-container-no-nav" : "")}>
                  <div className="login">
                     <div className="login-left">
                        <FadeView show={true} duration="0">
                           <img src="/images/doodle/login.png" alt="login" className="doodle" />
                        </FadeView>
                     </div>
                     <div className="login-right">
                        <div className="title">{title}</div>
                        <div className="sub-title">Masuk ke Lintas</div>
                        <div className="desc">Masukkan nomer handphone anda, SMS OTP akan dikirimkan ke nomer anda untuk verifikasi.</div>
                        <div className="login-form">
                           <form onSubmit={handleSubmit(onSubmit)}>
                              <Controller
                                 as={InputField}
                                 name="mobile"
                                 control={control}
                                 defaultValue=""
                                 rules={{
                                    required: {
                                       value: true,
                                       message: " Mohon input no telepon",
                                    },
                                    minLength: {
                                       value: 8,
                                       message: " No telepon terlalu pendek",
                                    },
                                    maxLength: {
                                       value: 16,
                                       message: " No telepon terlalu panjang",
                                    },
                                    pattern: {
                                       value: /^08[0-9]*$/,
                                       message: " No telepon tidak valid. No telepon harus dalam format 08XXXXXXXXX.",
                                    },
                                 }}
                                 placeholder="No. Handphone"
                              />
                              {errors.mobile && (
                                 <div className="error">
                                    <i className="fa fa-times-circle fa-lg" />
                                    {errors.mobile.message}
                                 </div>
                              )}
                              <div style={{ display: "flex" }}>
                                 <Button type="primary" htmlType="submit" className="submit" onClick={() => setModalContext(context)}>
                                    Kirim OTP
                                 </Button>
                                 {/*<Button type="primary" htmlType="submit" className="join" onClick={() => setModalContext(context)}>
                                    Daftar
                              </Button>*/}
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default Login;
