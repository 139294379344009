import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";
import moment from "moment";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { InputText, FormLayout, InputDate, InputNumber } from "../../common";

import * as Notification from "../../../constants/Notification";

import { VoucherService, ErrorService } from "../../../service";

import * as AlertHelper from "../../../helper/AlertHelper";
import * as FormatHelper from "../../../helper/FormatHelper";

import { Voucher } from "../../../model";

import * as Master from "../../../reference/Master";

import "./VoucherForm.css";

let modalContext: ModalContextInterface;

function RegisterNewVoucher(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const { control, errors, handleSubmit, setValue } = useForm();

   const [voucher, setVoucher] = useState<Voucher>(new Voucher());
   const [type, setType] = useState("");
   const [isCreate, setIsCreate] = useState(true);
   const [status, setStatus] = useState("");

   const onChangeStatusOptions = (e: any) => {
      setStatus(e.target.value);
   };

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!match.params.id) {
         return;
      }
      setIsCreate(false);
      VoucherService.getVoucher(match.params.id)
         .then((result) => {
            setVoucher(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history]);

   useEffect(() => {
      if (!voucher.gid) {
         return;
      }

      let vStartDate = moment(voucher.startDate);
      let vEndDate = moment(voucher.endDate);

      setValue([{ code: voucher.code }, { desc: voucher.desc }, { value: voucher.value }, { usageCount: voucher.usageCount }, { usageCap: voucher.usageCap }, { startDate: vStartDate }, { endDate: vEndDate }, { status: voucher.status }]);
      setType(voucher.type);
      setStatus(voucher.status);
   }, [voucher, setValue]);

   useEffect(() => {
      setValue([{ valueCap: voucher.valueCap }]);
   }, [type, setValue, voucher.valueCap]);

   const onChangeVoucherTypeOptions = (e: any) => {
      setType(e.target.value);
   };

   const onSubmit = (data: any) => {
      const v: Voucher = new Voucher();
      v.gid = voucher.gid;
      v.type = type;
      v.code = data.code;
      v.desc = data.desc;
      v.value = data.value;
      v.valueCap = data.valueCap;
      v.usageCap = data.usageCap;
      v.startDate = data.startDate;
      v.endDate = data.endDate;
      v.status = status;

      if (isCreate) {
         AlertHelper.alertLoading(Notification.DATA_REGISTER, modalContext);

         VoucherService.registerVoucher(v)
            .then((result) => {
               AlertHelper.alertSuccess("Proses registrasi voucher", modalContext);
               setTimeout(() => history.push("/admin/voucher"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         AlertHelper.alertLoading(Notification.DATA_SAVING, modalContext);

         VoucherService.updateVoucher(v)
            .then((result) => {
               AlertHelper.alertSuccess("Proses pembaruan data voucher", modalContext);
               setTimeout(() => history.push("/admin/voucher"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   const onBack = () => {
      history.goBack();
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FormLayout doodle="/images/doodle/delivery.png" title={isCreate ? "Registrasi Voucher" : "Perubahan Data Voucher"} desc={isCreate ? "Mohon lengkapi data voucher" : "Mohon perbarui data voucher"}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <Radio.Group name="type" options={Master.voucherTypeOptions} onChange={onChangeVoucherTypeOptions} value={type} buttonStyle="solid" />
                     <InputText loading={!isCreate} loadingRef={voucher.gid} name="code" placeholder="Kode" control={control} error={errors.code} />
                     <InputText loading={!isCreate} loadingRef={voucher.gid} name="desc" placeholder="Deskripsi" rows={2} control={control} error={errors.desc} />
                     <InputNumber
                        loading={!isCreate}
                        loadingRef={voucher.value}
                        name="value"
                        placeholder={`Nilai`}
                        control={control}
                        error={errors.value}
                        formatter={(value: string) => {
                           value = type === Master.VOUCHER_TYPE_AMOUNT ? FormatHelper.formatCurrencyToNumber(value) : value;
                           return type === Master.VOUCHER_TYPE_AMOUNT ? FormatHelper.formatCurrency(parseInt(value)) : value;
                        }}
                        parser={(value: string) => {
                           return type === Master.VOUCHER_TYPE_AMOUNT ? FormatHelper.formatCurrencyToNumber(value) : value;
                        }}
                     />
                     {type === Master.VOUCHER_TYPE_PERCENT && (
                        <InputNumber
                           loading={!isCreate}
                           loadingRef={voucher.valueCap}
                           name="valueCap"
                           placeholder={`Potongan Maksimum`}
                           control={control}
                           error={errors.valueCap}
                           formatter={(value: string) => {
                              value = FormatHelper.formatCurrencyToNumber(value);
                              return FormatHelper.formatCurrency(parseInt(value));
                           }}
                           parser={(value: string) => {
                              return FormatHelper.formatCurrencyToNumber(value);
                           }}
                        />
                     )}
                     <InputNumber loading={!isCreate} loadingRef={voucher.usageCap} name="usageCap" placeholder={`Jumlah Alokasi`} control={control} error={errors.usageCap} />
                     {!isCreate && <InputNumber loading={!isCreate} loadingRef={voucher.code} name="usageCount" readOnly nullable placeholder={`Jumlah Terpakai`} control={control} error={errors.usageCount} />}
                     <InputDate loading={!isCreate} loadingRef={voucher.code} name="startDate" placeholder="Tanggal Mulai" control={control} error={errors.startDate} />
                     <InputDate loading={!isCreate} loadingRef={voucher.code} name="endDate" placeholder="Tanggal Berakhir" control={control} error={errors.endDate} />
                     {!isCreate && <Radio.Group name="status" options={Master.statusOptions} onChange={onChangeStatusOptions} value={status} buttonStyle="solid" />}

                     <div className="action-button">
                        <Button type="primary" htmlType="submit">
                           Simpan
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onBack} className="cancel-button">
                           Batal
                        </Button>
                     </div>
                  </form>
               </FormLayout>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterNewVoucher;
