import React from "react";

import SignaturePad from "react-signature-canvas";

import Skeleton from "react-loading-skeleton";

import { CustomButton } from ".";

import "./InputSign.css";

export default function InputSign(props: any) {
   let sigPad: any;

   const onClear = () => {
      sigPad.clear();
      props.setSign("");
   };

   const onSigned = () => {
      props.setSign(sigPad.toDataURL("image/png"));
   };

   return (
      <div className="input-sign regular-border">
         <div className="input-sign-label-container">
            <div className="input-sign-label regular-grey-font">{props.placeholder}</div>
         </div>
         <div className="input-sign-button-container">
            <CustomButton icon={"remove"} warning style={{}} onClick={onClear} />
         </div>
         {props.loading && !props.loadingRef ? (
            <div className="input-sign-skeleton regular-border">
               <Skeleton height={20} />
            </div>
         ) : (
            <div className="input-sign-pad">
               <SignaturePad
                  canvasProps={{ width: window.innerWidth > 500 ? 475 : window.innerWidth - 45, height: 125 }}
                  ref={(ref) => {
                     sigPad = ref;
                  }}
                  onEnd={onSigned}
               />
            </div>
         )}
      </div>
   );
}
