import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Select } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { CacheService, UserService, ErrorService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";

import { User, Provider } from "../../../model";

import { getEmptyUsers } from "../../../reference/User";
import * as Master from "../../../reference/Master";

import "./Users.css";

const { Option } = Select;
let modalContext: ModalContextInterface;

export default function RegisterUser(props: any) {
   const history = useHistory();

   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [users, setUsers] = useState(getEmptyUsers(pageSize));
   const [searchUser, setSearchUser] = useState("");
   const [searchProvider, setSearchProvider] = useState("");
   const [status, setStatus] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setUsers(getEmptyUsers(pageSize));
      SearchHelper.searchInputs([searchUser, searchProvider], () => {
         let provider = new Provider();
         provider.name = searchProvider;
         let user = new User();
         user.name = searchUser;
         user.provider = provider;
         user.status = status;

         UserService.searchProviderUser(user, pageSize, page)
            .then((result) => {
               setUsers(result.users);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchUser, searchProvider, status, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchUser, searchProvider, status]);

   const onPressNewUser = (shipment: User) => {
      history.push("/admin/user/add");
   };

   const onPressMore = (user: User) => {
      //CacheService.setUser(user);
      history.push(`/admin/user/update/${user.gid}`);
   };

   const handleChange = (value: any) => {
      setStatus(value);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="users">
                  <div className="users-header regular-bottom-border">
                     <div className="users-header-search">
                        <div className="users-header-search-section">
                           <SectionIcon home className="users-header-search-icon" />
                           <div className="users-header-search-input">
                              <InputSearch placeholder="Cari Pengguna" value={searchUser} setValue={setSearchUser} />
                           </div>
                        </div>
                        {user.isAdmin && (
                           <div className="users-header-search-section">
                              <SectionIcon building className="users-header-search-icon" />
                              <div className="users-header-search-input">
                                 <InputSearch placeholder="Cari Penyedia Jasa" value={searchProvider} setValue={setSearchProvider} />
                              </div>
                           </div>
                        )}
                        {user.isAdmin && (
                           <div className="users-header-search-section">
                              <SectionIcon option className="users-header-search-icon" />
                              <div className="users-header-search-input">
                                 <Select className="users-header-search-select" placeholder="Status" onChange={handleChange} allowClear>
                                    <Option value={Master.STATUS_PENDING}>Pending</Option>
                                    <Option value={Master.STATUS_ACTIVE}>Aktif</Option>
                                    <Option value={Master.STATUS_INACTIVE}>Tidak Aktif</Option>
                                 </Select>
                              </div>
                           </div>
                        )}
                     </div>
                     {user.provider && (
                        <div className="users-header-button">
                           <CustomButton icon="truck" text="Tambah Pengguna" onClick={onPressNewUser} style={{ width: 190 }} />
                        </div>
                     )}
                  </div>
                  <div id="search-result" className={"search-result " + (user.isAdmin ? (props.showBottomNavigation ? "search-result-admin" : "search-result-admin-no-nav") : props.showBottomNavigation ? "search-result" : "search-result-no-nav")}>
                     <PageView
                        users={users}
                        onPressMore={(user: User) => {
                           console.log("user:", JSON.stringify(user, null, 2));
                           onPressMore(user);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchUser && !searchProvider && !status && "Belum terdapat pool / pangkalan"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
