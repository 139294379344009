import { createContext } from "react";

export interface ModalContextInterface {
   setShowLoading: any;
   setHideLoading: any;
   setLoadingText: any;
   setShowAlert: any;
   setAlertType: any;
   setAlertTitle: any;
   setAlertDesc: any;
   setAlertMsg: any;
   setAlertSrc: any;
   setLogin: any;
   setShowConfirm: any;
   setConfirmDesc: any;
   setConfirm: any;
   setAnchor: any;
   setHarbor: any;
   setCheckPrice: any;
   setNewShipment: any;
}

export const ModalContext = createContext<ModalContextInterface>({
   setShowLoading: (isShow: boolean) => {},
   setHideLoading: (isHide: boolean) => {},
   setLoadingText: (text: string) => {},
   setShowAlert: (isShow: boolean) => {},
   setAlertType: (text: string) => {},
   setAlertTitle: (text: string) => {},
   setAlertDesc: (text: string) => {},
   setAlertMsg: (text: string) => {},
   setAlertSrc: (text: string) => {},
   setLogin: (isLogin: boolean) => {},
   setShowConfirm: (isShow: boolean) => {},
   setConfirmDesc: (text: string) => {},
   setConfirm: (confirm: any) => {},
   setAnchor: (ref: any) => {},
   setHarbor: (harbor: string) => {},
   setCheckPrice: (isCheckPrice: boolean) => {},
   setNewShipment: (isNewShipment: boolean) => {},
});
