export default class Step {
   id?: number;
   code: string;
   description: string;
   durationEst: number;
   duration: number;
   completedDateEst: Date;
   completedDate: Date;
   signImgUrl: string;
   remarks: string;
   status: string;
   posLat: number;
   posLng: number;
}
