import React from 'react';
import Carousel from 'react-multi-carousel';

import { Story } from './story';
import { WhyUs } from './why-us';
import UsefulInfo from './UsefulInfo';

import './CategoryInfo.css';

const responsive = {
   desktop: {
      breakpoint: {
         max: 3000,
         min: 1100,
      },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 40,
   },
   mobile: {
      breakpoint: {
         max: 700,
         min: 0,
      },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
   },
   tablet: {
      breakpoint: {
         max: 1100,
         min: 700,
      },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 30,
   },
};

function CategoryInfo(props) {
   const category = props.category;

   return (
      <div className="category-info-content">
         <div className="category-info-content-left regular-font">
            {category.info}
            <Carousel
               className="category-info-photos"
               itemClass="category-info-photos-item"
               arrows={true}
               autoPlaySpeed={1}
               infinite={true}
               responsive={responsive}
               swipeable
            >
               {category.imageUrls.map((imageUrl, i) => (
                  <div key={i}>
                     <img
                        alt=""
                        src={process.env.PUBLIC_URL + '/images' + imageUrl}
                        className="category-info-photo-image"
                     />
                  </div>
               ))}
            </Carousel>
            <WhyUs />
            <UsefulInfo />
         </div>
         <div className="category-info-content-right">
            <Story />
         </div>
      </div>
   );
}

export default CategoryInfo;
