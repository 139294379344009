import React, { useEffect, useState } from "react";
import { CarouselRef } from "antd/lib/carousel";
import { Carousel } from "antd";

import ModalView from "./ModalView";

import CustomButton from "./CustomButton";
import SimpleButton from "./SimpleButton";

import * as Config from "../../Config";

import * as AlertHelper from "../../helper/AlertHelper";

import "./ImagesGallery.css";

function ImagesGallery(props: any) {
   let tImages = props.images;
   const [title, setTitle] = useState("");
   const [index, setIndex] = useState(0);
   const [images, setImage] = useState(tImages);
   const carousel = React.createRef<CarouselRef>();

   useEffect(() => {
      if (props.index >= 0) {
         let t1 = tImages.slice(0, props.index);
         let t2 = tImages.slice(props.index, tImages.length);
         setImage([...t2, ...t1]);
      }
   }, [props.index, tImages]);

   useEffect(() => {
      if (props.show) {
         setIndex(0);
      }
      AlertHelper.enableScroll(!props.show, "ImagesGallery");
   }, [props.show]);

   useEffect(() => {
      if (images && index < images.length) {
         setTitle(images[index].name);
      }
   }, [index, images]);

   return (
      <ModalView show={props.show}>
         <div className="images-gallery">
            <div className="images-gallery-heading">
               <div className="regular-bold-font" style={{ flex: 1 }}>
                  {title}
               </div>
               <SimpleButton
                  close
                  text="Tutup"
                  onClick={() => {
                     props.setShow(false);
                  }}
               ></SimpleButton>
            </div>
            <div className="images-gallery-content">
               <CustomButton
                  icon="prev"
                  className="images-gallery-nav-prev"
                  onClick={() => {
                     carousel.current?.prev();
                     if (index > 0) {
                        setIndex(index - 1);
                     } else {
                        setIndex(images.length - 1);
                     }
                  }}
               />
               <CustomButton
                  icon="more"
                  className="images-gallery-nav-next"
                  onClick={() => {
                     carousel.current?.next();
                     if (index < images.length - 1) {
                        setIndex(index + 1);
                     } else {
                        setIndex(0);
                     }
                  }}
               />
               <Carousel ref={carousel} className="images-gallery-carousel">
                  {images.map((image: any, i: number) => (
                     <div className="images-gallery-item" key={i}>
                        <img alt="gallery" src={`${Config.STORAGE_API}${image.url}`} className="images-gallery-image regular-border" />
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>
      </ModalView>
   );
}

export default ImagesGallery;
