import { ModalContextInterface } from "../hooks/ModalContext";
//import { ErrorService, CacheService } from "../service";

import * as Master from "../reference/Master";

let source: string | undefined;

// export const handleError = (error: Error, history: any, path?: String, modalContext?: ModalContextInterface) => {
//    if (ErrorService.isAccessDenied(error)) {
//       modalContext?.setShowLoading(false);
//       if (path) {
//          history.push("/login/" + path);
//       } else if (!CacheService.getUser()) {
//          enableScroll(false);
//          modalContext?.setShowLoading(false);
//          modalContext?.setAlertType(Master.ALERT_ERROR);
//          modalContext?.setAlertDesc(ErrorService.getDesc(error));
//          modalContext?.setShowAlert(true);
//          history.push("/home");
//       } else if (CacheService.getUser().role === Master.ROLE_PROVIDER) {
//          history.push("/login/" + Master.PATH_PROVIDER);
//       } else if (CacheService.getUser().role === Master.ROLE_TRANSPORTER) {
//          history.push("/login/" + Master.PATH_TRANSPORTER);
//       } else if (CacheService.getUser().role === Master.ROLE_CUSTOMER) {
//          history.push("/login/" + Master.PATH_CUSTOMER);
//       }
//    } else {
//       enableScroll(false);
//       modalContext?.setShowLoading(false);
//       modalContext?.setAlertType(Master.ALERT_ERROR);
//       modalContext?.setAlertDesc(ErrorService.getDesc(error));
//       modalContext?.setShowAlert(true);
//    }
// };

export const alertWarning = (message: string, modalContext?: ModalContextInterface, src?: string) => {
   src = src ? src : "warning";
   enableScroll(false, src);
   modalContext?.setShowLoading(false);
   modalContext?.setAlertType(Master.ALERT_WARNING);
   modalContext?.setAlertDesc(message);
   modalContext?.setShowAlert(true);
   modalContext?.setAlertSrc(src);
};

export const alertWarningWithRef = (message: string, modalContext?: ModalContextInterface, src?: string, ref?: any) => {
   src = src ? src : "warning";
   enableScroll(false, src);
   modalContext?.setShowLoading(false);
   modalContext?.setAlertType(Master.ALERT_WARNING);
   modalContext?.setAlertDesc(message);
   modalContext?.setShowAlert(true);
   modalContext?.setAnchor(ref);
   modalContext?.setAlertSrc(src);
};

export const alertError = (desc: string, msg: string, modalContext?: ModalContextInterface, src?: string) => {
   src = src ? src : "error";
   enableScroll(false, src);
   modalContext?.setShowLoading(false);
   modalContext?.setAlertType(Master.ALERT_ERROR);
   modalContext?.setAlertDesc(desc);
   modalContext?.setAlertMsg(msg);
   modalContext?.setShowAlert(true);
   modalContext?.setAlertSrc(src);
};

export const alertSuccess = (message: string, modalContext?: ModalContextInterface, src?: string) => {
   enableScroll(false, src);
   modalContext?.setShowLoading(false);
   modalContext?.setAlertType(Master.ALERT_SUCCESS);
   modalContext?.setAlertDesc(message);
   modalContext?.setShowAlert(true);
   modalContext?.setAlertSrc(src);
};

export const alertInfo = (message: string, modalContext?: ModalContextInterface, src?: string) => {
   enableScroll(false, src);
   modalContext?.setShowLoading(false);
   modalContext?.setAlertType(Master.ALERT_INFO);
   modalContext?.setAlertDesc(message);
   modalContext?.setShowAlert(true);
   modalContext?.setAlertSrc(src);
};

export const alertLoading = (message: string, modalContext?: ModalContextInterface, src?: string) => {
   enableScroll(false, src);
   modalContext?.setLoadingText(message);
   modalContext?.setShowLoading(true);
   modalContext?.setAlertSrc(src);
};

export const hideLoading = (modalContext?: ModalContextInterface, src?: string) => {
   enableScroll(true, src);
   modalContext?.setHideLoading(true);
};

export const enableScroll = (isEnable: boolean, src?: string) => {
   if (isEnable) {
      scrollEnable(src);
   } else {
      scrollDisable(src);
   }
};

const scrollEnable = (src?: string) => {
   if (src === source || source === "warning") {
      //console.log('enable scroll : ' + src);
      document.body.style.overflow = "visible";
      source = undefined;
      //throw new Error('whos calling');
   }
};

const scrollDisable = (src?: string) => {
   if (!source) {
      source = src;
      //console.log('disable scroll : ' + src);
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
      //throw new Error('whos calling');
   }
};
