import React, { useEffect, useState } from "react";

import { SubNavigation, SearchInput, CustomButton, Places } from ".";

import * as PlaceRef from "../../reference/Place";

import MapService from "../../service/MapService";

import * as LocationHelper from "../../helper/LocationHelper";
import * as SearchHelper from "../../helper/SearchHelper";

import "./PlaceSelection.css";

export default function PlaceSelection(props: any) {
   const [places, setPlaces] = useState(PlaceRef.getEmptyPlaces(0));
   const [place, setPlace] = useState(props.place);
   const [address, setAddress] = useState("");
   const [refresh, setRefresh] = useState(true);

   useEffect(() => {
      setPlace(props.place);
      setAddress(LocationHelper.getSubRegionCity(props.place ? props.place.address : ""));
      refreshSearchAddress();
   }, [props.place]);

   const selectViaMap = () => {
      props.setPlace(place ? place : PlaceRef.defaultPlace);
      props.setShowSelectPlaceMap(true);
   };

   const searchPlace = (input: any) => {
      setPlaces(PlaceRef.getEmptyPlaces(10));
      SearchHelper.searchInput(
         input,
         () => {
            MapService.getOsmPlace(input).then((responseJson) => {
               setPlaces(responseJson.results);
            });
         },
         1000
      );
   };

   const refreshSearchAddress = () => {
      setRefresh(false);
      setTimeout(() => {
         setRefresh(true);
      }, 0);
   };

   const onSelectPlace = (place: any) => {
      props.setPlace({
         name: place.name,
         address: place.formatted_address,
         lat: place.geometry.location.lat,
         lng: place.geometry.location.lng,
      });
      setAddress(place.name);
      refreshSearchAddress();
      props.setShow(false);
   };

   const onClickPrev = () => {
      props.setShow(false);
   };
   const onClickClose = () => {
      props.setShow(false);
   };

   return (
      props.show && (
         <div className="place-select">
            <div className="place-select-container">
               <SubNavigation title="Lokasi" onClickPrev={onClickPrev} onClickClose={onClickClose} />
               <div className="place-select-search dark-border">
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                     {refresh && (
                        <SearchInput
                           placeholder="Cari Lokasi"
                           onChangeText={(text: any) => {
                              searchPlace(text);
                           }}
                           value={address}
                           className="search"
                        />
                     )}
                  </div>
                  <div style={{ marginTop: 5, marginBottom: 5, paddingLeft: 10, paddingRight: 10, display: "flex", justifyContent: "flex-end" }}>
                     <CustomButton text="Pilih di Peta " icon={"map"} onClick={selectViaMap} style={{ marginTop: 5, width: 130 }} />
                  </div>
                  <div className="place-select-search-result">
                     <Places data={places} onPress={(place: any) => onSelectPlace(place)} />
                  </div>
               </div>
            </div>
         </div>
      )
   );
}
