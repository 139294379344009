import {Route} from '../model';

export const steps: Route[] = [];

export const newRoute: Route = new Route();

export const getEmptyRoutes = (count: number) => {
   const routes = Array<Route>();
   for (let i = 0; i < count; i++) {
      routes.push(newRoute);
   }
   return routes;
};
