export default class Voucher {
   id?: number;
   gid?: string;
   code: string;
   desc: string;
   type: string;
   value: number;
   valueCap: number;
   usageCount: number;
   usageCap: number;
   startDate: Date;
   endDate: Date;
   status: string;
}
