import { gql } from "@apollo/client";

import { Complaint, Image } from "../model";

import { authGraphClient, initAuthClient } from "./GraphService";

class ComplaintService {
   async registerComplaint(shipmentGid: string, complaint: Complaint) {
      await initAuthClient();

      let supportImgs = "";
      let images = complaint.customerComplaintImages;
      for (let i = 0; i < (images ? images.length : 0); i++) {
         let image: Image | undefined = images ? images[i] : undefined;
         supportImgs += `{
            ID: ${image?.id}
         },`;
      }

      let g = `
         mutation {
            RegisterComplaint(
               shipmentGid: "${shipmentGid}",
               inputComplaint: {
                  type: "${complaint.customerComplaintType}",
                  desc: """${complaint.customerComplaintDesc}""",
               },
               supportImgs: [${supportImgs}]
            ) {
               gid
               customerComplaintType
               customerComplaintDesc
               customerComplaintDate
               customerUser {
                  name
                  mobile
               }
               transporterResponseType
               transporterResponseDesc
               transporterResponseDate
               transporterUser {
                  name
                  mobile
               }
               providerResponseType
               providerResponseDesc
               providerResponseDate
               providerUser {
                  name
                  mobile
               }
               resolutionType
               resolutionDesc
               resolutionDate
               adminUser {
                  name
                  mobile
               }
               status
            }
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let resp: Complaint = result.data.RegisterComplaint;

      console.log("result:", result.data);
      return resp;
   }

   async responseComplaint(respComplaint: any) {
      await initAuthClient();

      let supportImgs = "";
      let images = respComplaint.images;
      for (let i = 0; i < (images ? images.length : 0); i++) {
         let image: Image | undefined = images ? images[i] : undefined;
         supportImgs += `{
            ID: ${image?.id}
         },`;
      }

      let g = `
         mutation {
            ResponseComplaint(
               inputComplaint: {
                  gid: "${respComplaint.gid}",
                  type: "${respComplaint.type}",
                  desc: """${respComplaint.desc}""",
               },
               supportImgs: [${supportImgs}]
            ) {
               gid
               customerComplaintType
               customerComplaintDesc
               customerComplaintDate
               customerUser {
                  name
                  mobile
               }
               transporterResponseType
               transporterResponseDesc
               transporterResponseDate
               transporterUser {
                  name
                  mobile
               }
               providerResponseType
               providerResponseDesc
               providerResponseDate
               providerUser {
                  name
                  mobile
               }
               resolutionType
               resolutionDesc
               resolutionDate
               adminUser {
                  name
                  mobile
               }
               status
            }
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let resp: Complaint = result.data.ResponseComplaint;

      console.log("result:", result.data);
      return resp;
   }

   async resolveComplaint(respComplaint: any) {
      await initAuthClient();

      let g = `
         mutation {
            ResolveComplaint(
               inputComplaint: {
                  gid: "${respComplaint.gid}",
                  type: "${respComplaint.type}",
                  desc: """${respComplaint.desc}""",
               }
            ) {
               gid
               customerComplaintType
               customerComplaintDesc
               customerComplaintDate
               customerUser {
                  name
                  mobile
               }
               transporterResponseType
               transporterResponseDesc
               transporterResponseDate
               transporterUser {
                  name
                  mobile
               }
               providerResponseType
               providerResponseDesc
               providerResponseDate
               providerUser {
                  name
                  mobile
               }
               resolutionType
               resolutionDesc
               resolutionDate
               adminUser {
                  name
                  mobile
               }
               status
            }
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let resp: Complaint = result.data.ResponseComplaint;

      console.log("result:", result.data);
      return resp;
   }

   async getComplaint(shipmentGid: string | undefined) {
      await initAuthClient();

      let g = `
         query {
            GetComplaint(
               shipmentGid: "${shipmentGid}",
            ) {
               gid
               customerComplaintType
               customerComplaintDesc
               customerComplaintDate
               customerUser {
                  name
                  mobile
               }
               transporterResponseType
               transporterResponseDesc
               transporterResponseDate
               transporterUser {
                  name
                  mobile
               }
               providerResponseType
               providerResponseDesc
               providerResponseDate
               providerUser {
                  name
                  mobile
               }
               resolutionType
               resolutionDesc
               resolutionDate
               adminUser {
                  name
                  mobile
               }
               status
            }
         }`;

      //console.log("gql:", g);

      let result = await authGraphClient.query({
         query: gql`
            ${g}
         `,
      });

      let resp: Complaint = result.data.GetComplaint;

      //console.log("result:", result.data);
      return resp;
   }

   async getComplaintImages(complaintGid: string | undefined, imageType: string) {
      await initAuthClient();

      let g = `
         query {
            GetComplaintImages(
               complaintGid: "${complaintGid}",
               imageType: "${imageType}",
            ) {
               id
               name
               size
               type
               url
               status
            }
         }`;

      //console.log("gql:", g);

      let result = await authGraphClient.query({
         query: gql`
            ${g}
         `,
      });

      let resp: Image[] = result.data.GetComplaintImages;

      //console.log("result:", JSON.stringify(result.data.GetComplaintImages, null, 2));
      return resp;
   }
}

const Service = new ComplaintService();

export default Service;
