import { gql } from "@apollo/client";

import { Site } from "../model";
import { authGraphClient, initAuthClient } from "./GraphService";

class SiteService {
   async registerSite(site: Site) {
      await initAuthClient();
      console.log("registerSite:", JSON.stringify(site, null, 2));

      let g = `
         mutation {
            RegisterSite(
               inputSite: {
                  name: "${site.name}", 
                  desc: """${site.desc}""",
                  status: "",
               },
               inputLocation: {
                  name: "${site.location.name}", 
                  address: "${site.location.address}",
                  lat: ${site.location.lat},
                  lng: ${site.location.lng},
               }
            )
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let siteID = result.data.RegisterSite;

      console.log(result.data);

      return siteID;
   }

   async updateSite(site: Site) {
      await initAuthClient();
      console.log("updateSite:", JSON.stringify(site, null, 2));

      let g = `
            mutation {
               UpdateSite(
                  inputSite: {
                     gid: "${site.gid}",
                     name: "${site.name}", 
                     desc: """${site.desc}""",
                     status: "${site.status}",
                  },
                  inputLocation: {
                     name: "${site.location.name}", 
                     address: "${site.location.address}",
                     lat: ${site.location.lat},
                     lng: ${site.location.lng},
                  }
               )
            }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let siteID = result.data.RegisterSite;

      console.log(result.data);

      return siteID;
   }

   async searchSite(site: Site, pageSize: number, page: number) {
      await initAuthClient();
      //console.log('searchSite: ', JSON.stringify(site, null, 2));

      let sitePageCountGql =
         page === 1
            ? `
            GetSitesPageCount(
               siteName: "${site.name ? site.name : ""}", 
               providerName: "${site.provider ? site.provider.name : ""}",
               status: "${site.status ? site.status : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetSites(
                  siteName: "${site.name ? site.name : ""}", 
                  providerName: "${site.provider ? site.provider.name : ""}",
                  status: "${site.status ? site.status : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  gid
                  name
                  desc
                  location {
                     name
                     address
                     lat
                     lng
                  }
                  provider {
                     name
                  }
               }
               ${sitePageCountGql}
            }`;

      //console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let resp = { sites: result.data.GetSites, pageCount: result.data.GetSitesPageCount };

      console.log("searchSite:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getSite(gid?: string) {
      await initAuthClient();
      console.log("getSite:", gid);

      let gqlReq = `
            query { 
               GetSite(gid: "${gid}") {
                  gid
                  name
                  desc
                  status
                  location {
                     name
                     address
                     lat
                     lng
                  }
               }
            }`;

      console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      console.log("result:", JSON.stringify(result, null, 2));

      let site: Site = result ? result.data.GetSite : null;

      console.log("getSite:", JSON.stringify(site, null, 2));

      return site;
   }
}

const Service = new SiteService();

export default Service;
