import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { CustomButton, ImageView } from ".";

import { User } from "../../model";

import { CacheService } from "../../service";

import * as Master from "../../reference/Master";

import "./UserView.css";

export function UserView(props: any) {
   const [profileImgUrl, setProfileImgUrl] = useState("");
   const item: User = props.user;

   useEffect(() => {
      if (props.user) {
         if (props.user.profileImg && props.user.profileImg.url) {
            setProfileImgUrl(props.user.profileImg.url);
         } else if (props.user.name) {
            setProfileImgUrl(Master.IMAGE_ALT_PERSON);
         } else {
            setProfileImgUrl("");
         }
      } else {
         setProfileImgUrl("");
      }
   }, [props.user]);

   const onPressMore = () => {
      props.onPressMore(item);
   };

   return (
      <div className="user-view-item regular-bottom-border">
         <div className="user-view-content">
            <div>
               <ImageView className="user-view-pic-image" uploadUrl={profileImgUrl} profile alt="user" />
            </div>
            <div className="user-view-desc">
               <div className="regular-bold-font">{item.name || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
               {CacheService.getUser().isAdmin && (
                  <div className="user-view-provider">
                     {item.provider?.name && <span className="user-view-provider-info regular-white-font">{item.provider?.name}</span>}
                     {!item.provider?.name && <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 215 : 200} />}
                  </div>
               )}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default UserView;
