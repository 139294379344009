import React, { useRef } from "react";
import Resizer from "react-image-file-resizer";
import Webcam from "react-webcam";
import { FaRegImage } from "react-icons/fa";
import { CustomButton } from ".";

import { CacheService } from "../../service";

import JavaScriptInterface from "../../interface/AndroidInterface";

import "./CapturePhoto.css";

const width = window.innerWidth < 1000 ? window.innerWidth - 30 : 468;
const height = width;

const videoConstraints = {
   width: 720,
   height: 720,
   facingMode: "environment",
};

let isWebView = CacheService.isWebView();

declare var android: JavaScriptInterface;

const resizeFile = (file: File) =>
   new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         1024,
         1024,
         "JPEG",
         60,
         0,
         (uri: any) => {
            resolve(uri);
         },
         "base64"
      );
   });

export function TakePhoto(props: any) {
   const webcamRef = useRef<Webcam>(null);
   const inputFile = useRef<any>(null);

   const handleFileUpload = (e: any) => {
      const { files } = e.target;
      if (files && files.length) {
         const filename = files[0].name;

         var parts = filename.split(".");
         const fileType = parts[parts.length - 1];
         console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.

         resizeFile(files[0]).then((imageUrl: any) => {
            props.setImageBase64(imageUrl);
         });
      }
   };

   const capture = React.useCallback(() => {
      isWebView = CacheService.isWebView();
      if (isWebView) {
         if (android) {
            android.setCameraOnly(true);
         }
         inputFile?.current?.click();
      } else {
         const imageSrc = webcamRef?.current?.getScreenshot();
         props.setImageBase64(imageSrc);
      }
   }, [webcamRef, props]);

   const deletePhoto = () => {
      props.setImageBase64("");
   };

   isWebView = CacheService.isWebView();
   return (
      <div className="capture-photo">
         <input
            style={{ display: "none" }}
            // accept=".zip,.rar"
            ref={inputFile}
            onChange={handleFileUpload}
            type="file"
         />
         {!props.imageBase64 && isWebView && (
            <div className="image-default" style={{ height: height, width: width }}>
               <FaRegImage style={{ height: 50, width: 50 }} />
            </div>
         )}
         {!props.imageBase64 && !isWebView && <Webcam audio={false} height={height} width={width} ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />}
         {!props.imageBase64 && <CustomButton icon={"camera"} text="Foto Bukti" width={130} onClick={capture} />}
         {props.imageBase64 && <img className="image" alt="capture" src={props.imageBase64} style={{ height: height, width: width, objectFit: "cover" }} />}
         {props.imageBase64 && <CustomButton icon={"remove"} text="Hapus Bukti" width={130} onClick={deletePhoto} warning />}
      </div>
   );
}

export default TakePhoto;
