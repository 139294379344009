import * as React from "react";
import Skeleton from "react-loading-skeleton";

import { ImageView, CustomButton } from ".";

import { Transporter } from "../../model";

import { CacheService } from "../../service";

import * as ReferenceHelper from "../../helper/ReferenceHelper";

import "./TransporterView.css";

export function ShipmentView(props: any) {
   const item: Transporter = props.transporter;

   const status = ReferenceHelper.getVerificationStatusDesc(item.verificationStatus);

   const onPressMore = () => {
      props.onPressMore(item);
   };

   return (
      <div className="transporter-view-item regular-bottom-border">
         <div className="transporter-view-content">
            <div>
               <ImageView className="transporter-view-pic-image" uploadUrl={item.profileImgUrl} profile alt="transporter" />
            </div>
            <div className="transporter-view-pic">
               <div className="regular-bold-font">{item.name || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
               <div className="transporter-view-detail">
                  {CacheService.getUser().isAdmin && item.provider?.name && <span className="transporter-view-provider regular-white-font">{item.provider?.name}</span>}
                  {status && <span className={`transporter-view-status ${item.verificationStatus} regular-white-font`}>{status}</span>}
               </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default ShipmentView;
