import * as React from "react";

import { ImageView, CustomButton } from "../common";

import Skeleton from "react-loading-skeleton";

import { CacheService } from "../../service";

import * as GraphHelper from "../../helper/GraphHelper";

import "./ProfileView.css";

export function ProfileView(props: any) {
   const item: any = props.profile;
   const user = GraphHelper.graphToUser(item.user, CacheService.getRole());
   //console.log('profileView: ', JSON.stringify(user, null, 2));
   const name = user?.provider ? user.provider.name : user?.customer?.name;
   const imgUrl = user?.provider ? user.provider.profileImgUrl : user?.customer?.profileImgUrl;

   const onPressMore = () => {
      props.onSelect(item);
   };

   return (
      <div className="profile-view-item regular-bottom-border">
         <div className="profile-view-content">
            <div>
               <ImageView className="profile-view-pic-image" uploadUrl={imgUrl} alt="transporter" />
            </div>
            <div className="profile-view-pic">
               <div className="regular-bold-font">{name || <Skeleton delay={1} width={350} />}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default ProfileView;
