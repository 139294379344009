import React from 'react';
import { useHistory } from "react-router";
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RiWhatsappLine } from "react-icons/ri";
import { FiTruck } from "react-icons/fi";
import mixpanel from "mixpanel-browser";

import * as Config from "../../../Config";
import * as Master from "../../../reference/Master";

import { categories } from '../../../reference/Category';

import './Category.css';

function Categories() {

   const { Meta } = Card;

   // return categories.map((category, i) => (
   //    <Link to={`/category/${category.code}`} key={i}>
   //       <Card
   //          key={i}
   //          hoverable
   //          style={{ width: 240 }}
   //          cover={
   //             <img
   //                alt={category.category}
   //                src={process.env.PUBLIC_URL + '/images' + category.imageUrl}
   //             />
   //          }
   //          className="home-category-item"
   //       >
   //          <Meta title={category.desc} />
   //       </Card>
   //    </Link>
   // ));

   const onWhatsapp = (type) => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Info Muatan - CS");

      window.open("https://wa.me/" + Master.MOBILE_LINTAS_PARTNERSHIP + "?text=" + encodeURI(`Halo Lintas, saya mencari muatan angkutan ${type}.`));
   };

   return categories.map((category, i) => (
      <Link to={`#`} key={i} onClick={() => onWhatsapp(category.desc)}>
         <Card
            key={i}
            hoverable
            style={{ width: 240 }}
            cover={
               <img
                  alt={category.category}
                  src={process.env.PUBLIC_URL + '/images' + category.imageUrl}
               />
            }
            className="home-category-item"
         >
            <Meta title={category.desc} />
         </Card>
      </Link>
   ));
}

function Category() {
   const history = useHistory();

   const onWhatsapp = (type) => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Info Muatan - CS");

      window.open("https://wa.me/" + Master.MOBILE_LINTAS_PARTNERSHIP + "?text=" + encodeURI(`Halo Lintas, saya mencari muatan angkutan ${type}.`));
   };

   const onJoin = () => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Info Muatan - Join");

      history.push("/login/provider");
   };

   return (
      <div className="home-category">
         <div className="home-category-title">Dapatkan Info Muatan</div>
         <div className="home-category-description">
            Pilih sesuai tipe angkutan anda
         </div>
         <div className="home-category-content">
            <Categories />
         </div>
         <div className="home-category-buttons">
            <Button
               className="category-btn"
               type="primary"
               icon={<RiWhatsappLine style={style.icon} />}
               size="large"
               onClick={() => onWhatsapp("")}
            >
               Info Muatan via WhatsApp
            </Button>
            <Button
               className="category-btn"
               type="primary"
               icon={<FiTruck style={style.icon} />}
               size="large"
               onClick={onJoin}
            >
               Jadi Mitra
            </Button>
         </div>
      </div>
   );
}

const style = {
   icon: {
      fontSize: 17, position: 'relative', top: 2, marginRight: 7
   }
}

export default Category;
