import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import Headroom from 'react-headroom';

import { ModalContext } from '../../hooks/ModalContext';

import SearchBar from './SearchBar';
import TopMenu from './TopMenu';

import * as MenuHelper from '../../helper/MenuHelper';
import * as CommonHelper from "../../helper/CommonHelper";

import './Navigation.css';
import 'antd/dist/antd.css';

const { Header } = Layout;

let modalContext;

function Navigation(props) {
   const [menus, setMenus] = useState([]);
   const [user, setUser] = useState();
   const [lintasImg, setLintasImg] = useState("");
   let isMounted = false;

   const setImg = (img) => {
      if (isMounted) {
         setLintasImg(img);
      }
   }

   useEffect(() => {
      isMounted = true;
      if (!lintasImg) {
         CommonHelper.getImage("/images/lintas.png", setImg);
      }
      return () => { isMounted = false };
   }, [lintasImg]);

   useEffect(() => {
      if (props.user && JSON.stringify(user) !== JSON.stringify(props.user)) {
         setUser(props.user);
      }
      return () => {
         //setUser({});
      };
   }, [user, props.user]);

   useEffect(() => {
      //console.log('Navigation User: ', JSON.stringify(user, null, 2));
      let ms = MenuHelper.getMenu(user).filter(menu => !menu.mobile);
      setMenus(ms);
      return () => {
         //setMenus([]);
      };
   }, [user]);

   let headStyle = props.showSearchBar ? "head" : "head no-search-bar";
   let searchBarStyle = props.showSearchBar ? "search-bar-container" : "search-bar-container search-bar-hide";

   //console.log('showSearchBar: ' + props.showSearchBar);

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className={headStyle}>
                  <Headroom className="headroom">
                     <Header className="header">
                        <div className="header-content">
                           <div className="header-left">
                              <span className="image">
                                 <Link to="/">
                                    <img
                                       src="#"
                                       srcSet={lintasImg}
                                       alt=""
                                    />
                                 </Link>
                              </span>
                              <span className={searchBarStyle}>
                                 {props.showSearchBar && <SearchBar />}
                              </span>
                           </div>
                           <div className="header-right">
                              <Menu theme="dark" mode="horizontal" className="menu">
                                 {menus.map((menu, key) => {
                                    // console.log("menu key: " + key);
                                    return (
                                       <TopMenu menu={menu} key={key} id={key} context={modalContext} />
                                    )
                                 })}
                              </Menu>
                           </div>
                        </div>
                     </Header>
                  </Headroom>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default Navigation;
