import {Place, Image} from '.';

export default class Customer {
   id?: number;
   gid?: string;
   name: string;
   type: string;
   phone: string;
   email: string;
   level: string;
   rating: number;
   location: Place;
   profileImg: Image;
   profileImgUrl?: string;
}
