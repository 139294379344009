import React from 'react';
import './ItWorkItem.css';

function ItWorkItem (props) {
  return (
    <div className="it-work-item">
      <div className="it-work-item-top">
        <div className="it-work-item-round">
          {props.itWork.no}
        </div>
      </div>
      <div className="it-work-item-middle">
        {props.itWork.title}
      </div>
      <div className="it-work-item-bottom">
        {props.itWork.desc}
      </div>
    </div>
  );
}

export default ItWorkItem;
