import * as React from 'react';

import './FormLayout.css';

export default function FormLayout(props: any) {
   return (
      <div className="form-container">
         <div className="form-layout">
            <div className="form-layout-left">
               <img src={props.doodle} alt="doodle" className="doodle" />
            </div>
            <div className="form-layout-right">
               <div className="title">{props.title}</div>
               <div className="desc">{props.desc}</div>
               <div className="form-layout-form">{props.children}</div>
            </div>
         </div>
      </div>
   );
}
