import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { ImageView, CustomButton } from ".";

import { Customer } from "../../model";

import * as Master from "../../reference/Master";

import "./CustomerView.css";

export function ShipmentView(props: any) {
   const [profileImgUrl, setProfileImgUrl] = useState("");
   const item: Customer = props.customer;

   useEffect(() => {
      // console.log("customer: ", JSON.stringify(props.customer, null, 2));
      if (props.customer) {
         if (props.customer.profileImg && props.customer.profileImg.url) {
            setProfileImgUrl(props.customer.profileImg.url);
         } else if (props.customer.name) {
            setProfileImgUrl(Master.IMAGE_ALT_PERSON);
         } else {
            setProfileImgUrl("");
         }
      } else {
         setProfileImgUrl("");
      }
   }, [props.customer]);

   const onPressMore = () => {
      props.onPressMore(item);
   };

   return (
      <div className="customer-view-item regular-bottom-border">
         <div className="customer-view-content">
            <div>
               <ImageView className="customer-view-pic-image" uploadUrl={profileImgUrl} profile alt="customer" />
            </div>
            <div className="customer-view-pic">
               <div className="regular-bold-font">{item.name || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default ShipmentView;
