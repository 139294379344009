export function copyObject(obj: any) {
   return JSON.parse(JSON.stringify(obj));
}

export function getLabel(options: any[], code: string | undefined) {
   let opts = options.filter((option) => option.value === code);
   if (opts.length > 0) {
      return opts[0].label;
   } else {
      return code;
   }
}

const imageMap = new Map<string, string>();

export async function getImage(url: string, setImage: any) {
   let image = null;

   image = imageMap.get(url);
   // console.log("getImage: " + url + " " + image);
   if (!image) {
      // console.log("fetchImage: ", url);
      fetchImage(url, (img: string) => {
         if (img && img.indexOf("data:image") > -1) {
            imageMap.set(url, img);
            setImage(img);
         }
      });
   } else {
      // console.log("fromCache: ", url);
      setImage(image);
   }
}

async function fetchImage(url: string, setImage: any) {
   const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors",
   });
   const blob = await response.blob();
   var reader = new FileReader();
   reader.readAsDataURL(blob);
   reader.onloadend = function () {
      // console.log("fetchImage: " + url + " " + reader.result);
      setImage(reader.result);
   };
}

export function formatMobile(mobile: string) {
   return mobile && mobile.indexOf("0") === 0 ? "62" + mobile.substring(1, mobile.length) : mobile;
}

export function gqlStr(input: string | undefined): any {
   return input ? input.replaceAll('"', "") : input;
}
