import { Shipment, Place } from "../model";
import * as Master from "./Master";
import * as Step from "./Step";
import * as Image from "./Image";
import Payment from "../model/Payment";

export const newShipment: Shipment = {
   id: -1,
   payment: new Payment(),
   requestDate: new Date(new Date().setHours(8, 0, 0, 0)),
   status: Master.SHIPMENT_STATUS_NEW_ORDER,
   images: [],
   origin: undefined,
   destination: undefined,
   destinations: [new Place()],
   deliveryProgress: Step.steps,
   deliveryDate: new Date(),
   deliveryStartDate: new Date(),
   deliveryEndDate: new Date(),
   weightEst: 0,
   remarks: "",
   rating: 0,
   ratingRemarks: "",
   declineCode: "",
   declineDesc: "",
   cancelCode: "",
   cancelDesc: "",
   reminderCount: 0,
   stepStatus: "",
   isReqTransWorker: false,
   isReturnShipment: false,
   isFerryShipment: false,
   isProviderRef: false,
   ratingCustomer: 0,
   ratingCustomerRemarks: "",
   voucherCode: "",
   path: "",
};

export const getEmptyShipments = (count: number) => {
   const shipments = Array<Shipment>();
   for (let i = 0; i < count; i++) {
      shipments.push(newShipment);
   }
   return shipments;
};

export const emptyShipment: Shipment = {
   id: -1,
   provider: undefined,
   payment: new Payment(),
   picOriginName: "",
   picOriginMobile: "",
   current: {
      name: "",
      lat: -6.2087634,
      lng: 106.845599,
      address: "",
   },
   origin: {
      name: "",
      lat: -6.2087634,
      lng: 106.845599,
      address: "",
   },
   destinations: [
      {
         name: "",
         lat: -6.2087634,
         lng: 106.845599,
         address: "",
      },
   ],
   status: "",
   requestDate: new Date(),
   deliveryProgress: undefined,
   description: "",
   images: [],
   deliveryDate: new Date(),
   deliveryStartDate: new Date(),
   deliveryEndDate: new Date(),
   fees: undefined,
   declineCode: "",
   declineDesc: "",
   cancelCode: "",
   cancelDesc: "",
   reminderCount: 0,
   stepStatus: "",
   isReqTransWorker: false,
   isReturnShipment: false,
   isFerryShipment: false,
   isProviderRef: false,
   weightEst: 0,
   remarks: "",
   rating: 0,
   ratingRemarks: "",
   ratingCustomer: 0,
   ratingCustomerRemarks: "",
   voucherCode: "",
   path: "",
};

let dummyProvider = {
   id: -1,
   name: "PT IMS Transport",
   profileImg: Image.newImage,
   phone: "+62822210105231",
   location: {
      name: "Kantor Pusat PT Lintas Nusantara",
      lat: -6.197107780493985,
      lng: 106.81620413437484,
      address: "Jakarta, DKI Jakarta, Indonesia",
   },
   rating: 4.9,
   rate: 4000,
   fleet: [
      {
         fleetCategoryCode: Master.CATEGORY_VAN,
         count: 3,
         images: ["../assets/images/catalog/van-1.jpg", "../assets/images/catalog/van-2.jpg", "../assets/images/catalog/van-3.jpg", "../assets/images/catalog/van-4.jpg"],
      },
      {
         fleetCategoryCode: Master.CATEGORY_COLT_DIESEL,
         count: 5,
         images: ["../assets/images/catalog/colt-1.jpg", "../assets/images/catalog/colt-2.jpg", "../assets/images/catalog/colt-3.jpg", "../assets/images/catalog/colt-4.jpg"],
      },
   ],
   transWorkerFee: 100000,
   reviewCount: 0,
   deliveryCount: 0,
};

export const dummyShipment: Shipment = {
   id: -1,
   provider: dummyProvider,
   payment: new Payment(),
   picOriginName: "Yusuf Bin Sanusi",
   picOriginMobile: "+62822210105231",
   current: {
      name: "Jakarta",
      lat: -6.2087634,
      lng: 106.845599,
      address: "Jakarta, Daerah Khusus Ibukota Jakarta",
   },
   origin: {
      name: "Jakarta",
      lat: -6.2087634,
      lng: 106.845599,
      address: "Jakarta, Daerah Khusus Ibukota Jakarta",
   },
   destinations: [
      {
         name: "Cikarang",
         lat: -6.307923199999999,
         lng: 107.172085,
         address: "Cikarang, Bekasi, Jawa Barat",
      },
   ],
   status: Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS,
   requestDate: new Date(),
   etaDate: "Minggu, 03/09",
   etaTime: "12:50",
   deliveryProgress: Step.steps,
   description: "Komponen automotif",
   images: [Image.newImage],
   deliveryDate: new Date(),
   deliveryStartDate: new Date(),
   deliveryEndDate: new Date(),
   fees: undefined,
   declineCode: "",
   declineDesc: "",
   cancelCode: "",
   cancelDesc: "",
   reminderCount: 0,
   stepStatus: "",
   isReqTransWorker: false,
   isReturnShipment: false,
   isFerryShipment: false,
   isProviderRef: false,
   weightEst: 0,
   remarks: "",
   rating: 0,
   ratingRemarks: "",
   ratingCustomer: 0,
   ratingCustomerRemarks: "",
   voucherCode: "",
   path: "",
};

export const dummyShipments: Shipment[] = [dummyShipment, dummyShipment, dummyShipment];
