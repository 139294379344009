export default class Search {
  id: number;
  customerId: number;
  phone: string;
  originDesc: string;
  destinationDesc: string;
  transportTypeCode: string;
  transWorkerStatus: string;
  requestDate: Date;
  deliveryDate: Date;
  count: number;
}