import React from "react";
import { useHistory } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";

import mixpanel from "mixpanel-browser";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { PopupLayout } from "../common";

import * as AlertHelper from "../../helper/AlertHelper";
import * as Config from "../../Config";
import * as Master from "../../reference/Master";

import "./NewBooking.css";

let modalContext: ModalContextInterface;

export default function NewBooking(props: any) {
   const history = useHistory();

   const onClose = () => {
      modalContext.setCheckPrice(false);
      modalContext.setNewShipment(false);
      AlertHelper.enableScroll(true, "NewBooking");
   };

   const onShelf = () => {
      history.push("/booking");

      modalContext.setCheckPrice(false);
      modalContext.setNewShipment(false);

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Shelf");
   };

   const onAssistance = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau " + (props.isCheckPrice ? "cek harga" : "pesan angkutan")));

      modalContext.setCheckPrice(false);
      modalContext.setNewShipment(false);

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Assistance");
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            AlertHelper.enableScroll(!props.show, "NewBooking");
            return (
               props.show && (
                  <div className="new-booking">
                     <PopupLayout small className="rating-container" title={props.isCheckPrice ? "Cek harga angkutan" : "Pesan angkutan"} onClickPrev={onClose} onClickClose={onClose}>
                        <div className="new-booking-content">
                           <div className="new-booking-option" onClick={onShelf}>
                              <span className="regular-bold-font">{props.isCheckPrice ? "Cek harga" : "Pesan angkutan"} sendiri di Aplikasi</span>
                              <MdArrowForwardIos className="icon" />
                           </div>
                           <div className="new-booking-option" onClick={onAssistance}>
                              <span className="regular-bold-font">{props.isCheckPrice ? "Cek harga" : "Pesan angkutan"} melalui Customer Service</span>
                              <MdArrowForwardIos className="icon" />
                           </div>
                        </div>
                     </PopupLayout>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
