import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import mixpanel from "mixpanel-browser";
import { Select } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { InputSearch, SectionIcon, PageView } from "../common";

import { CacheService, SearchService, ErrorService, ReferenceService } from "../../service";

import * as SearchHelper from "../../helper/SearchHelper";

import { Category, TransportType, Search } from "../../model";

import { getEmptySearches } from "../../reference/Search";
import { categorySet, fullCategorySet } from "../../reference/Category";
import { transportTypes, transportTypeSet } from "../../reference/TransportType";

import * as Master from "../../reference/Master";
import * as Config from "../../Config";

import "./SearchServices.css";

let modalContext: ModalContextInterface;

export default function RegisterSite(props: any) {
   const history = useHistory();

   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [searchServices, setSearchServices] = useState(getEmptySearches(pageSize));
   const [categoryCode, setCategoryCode] = useState("");
   const [categoryDesc, setCategoryDesc] = useState("");
   const [categoryOptions, setCategoryOptions] = useState(new Array());
   const [transportTypeCode, setTransportTypeCode] = useState("");
   const [transportTypeDesc, setTransportTypeDesc] = useState("");
   const [transportTypeOptions, setTransportTypeOptions] = useState(new Array());
   const [originCity, setOriginCity] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      const categories = Array.from(fullCategorySet.values());
      const options = categories.map((category: Category) => ({
         value: category.code,
         label: category.desc,
      }));
      setCategoryOptions(options);
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      const transTypes = transportTypes.filter((transportType: TransportType) => new RegExp(categoryCode, "i").test(transportType.code));
      const options = transTypes.map((transportType: TransportType) => ({
         value: transportType.code,
         label: transportType.desc,
      }));
      setTransportTypeOptions(options);
   }, [categoryCode]);

   useEffect(() => {
      if (!init) {
         if (transportTypes.length === 0) {
            ReferenceService.initTransportTypes().then((result) => {
               const transTypes = transportTypes.filter((transportType: TransportType) => new RegExp(categoryCode, "i").test(transportType.code));
               const options = transTypes.map((transportType: TransportType) => ({
                  value: transportType.code,
                  label: transportType.desc,
               }));
               setTransportTypeOptions(options);
            });
         }
         setInit(true);
      }
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [init, categoryCode, props]);

   useEffect(() => {
      setSearchServices(getEmptySearches(pageSize));
      SearchHelper.searchInputs(
         [originCity],
         () => {
            SearchService.searchServices(categoryCode, transportTypeCode, originCity, pageSize, page)
               .then((result) => {
                  setSearchServices(result.searchServices);
                  if (page === 1) {
                     setPageCount(result.pageCount);
                  }
                  if (result.searchServices && result.searchServices.length === 0) {
                     onContactWa();
                  }
               })
               .catch((error) => {
                  ErrorService.handleError(error, history, modalContext);
               });
         },
         3000
      );
   }, [categoryCode, transportTypeCode, originCity, page, history]);

   useEffect(() => {
      setPage(1);
   }, [categoryCode, transportTypeCode, originCity]);

   const onPressMore = (search: Search) => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Select Muatan");

      let transportType: TransportType = transportTypeSet.get(search.transportTypeCode);
      window.open(
         "https://wa.me/" + Master.MOBILE_LINTAS_PARTNERSHIP + "?text=" + encodeURI("Halo Lintas, saya tertarik memberikan layanan angkutan. Jenis angkutan " + transportType.desc + " dari " + search.originDesc + " ke " + search.destinationDesc)
      );
   };

   const onContactWa = () => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Whatsapp Admin - Shipment Not Found");

      window.open("https://wa.me/" + Master.MOBILE_LINTAS_PARTNERSHIP + "?text=" + encodeURI("Halo Lintas, mohon bantuan untuk mencarikan muatan " + (transportTypeDesc ? transportTypeDesc : categoryDesc) + originCity));
   };

   const onSelectCategory = (value: any, event: any) => {
      setCategoryCode(value || "");
      setCategoryDesc(categorySet.get(value)?.desc || "");
   };

   const onSelectTransportType = (value: any, event: any) => {
      setTransportTypeCode(value || "");
      setTransportTypeDesc(transportTypeSet.get(value)?.desc || "");
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="search-services">
                  <div className="search-services-header regular-bottom-border">
                     <div className="search-services-header-title regular-bold-font">Pelanggan mencari angkutan berikut</div>
                     <div className="search-services-header-search">
                        <div className="search-services-header-search-section">
                           <SectionIcon truck className="search-services-header-search-icon" />
                           <div className="search-services-header-search-input">
                              <Select className="search-services-header-search-select" placeholder="Kategori" options={categoryOptions} onChange={onSelectCategory} allowClear />
                           </div>
                        </div>
                        <div className="search-services-header-search-section">
                           <SectionIcon truck className="search-services-header-search-icon" />
                           <div className="search-services-header-search-input">
                              <Select className="search-services-header-search-select" placeholder="Tipe Angkutan" options={transportTypeOptions} onChange={onSelectTransportType} allowClear />
                           </div>
                        </div>
                        <div className="search-services-header-search-section">
                           <SectionIcon home className="search-services-header-search-icon" />
                           <div className="search-services-header-search-input">
                              <InputSearch placeholder="Kota Asal Muatan" value={originCity} setValue={setOriginCity} />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div id="search-result" className={"search-result"}>
                     <PageView
                        searchServices={searchServices}
                        onPressMore={(search: Search) => {
                           console.log("search:", JSON.stringify(search, null, 2));
                           onPressMore(search);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!categoryCode && !transportTypeCode && !originCity && "Belum terdapat muatan"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
