import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router";
// import mixpanel from "mixpanel-browser";

import { AdsProvider } from "../home/ads";
import { CategoryProvider } from "../home/category";

import { ModalContext } from "../../hooks/ModalContext";
// import { ModalContextInterface } from "../../hooks/ModalContext";

// import * as Master from "../../reference/Master";
// import * as Config from "../../Config";

import "./SearchInfo.css";

// let modalContext: ModalContextInterface;

export default function RegisterSite(props: any) {
   // const history = useHistory();
   const [init, setInit] = useState(false);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   // const onSearch = () => {
   //    mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
   //    mixpanel.track("Event Info Muatan - Self");

   //    history.push("/muatan/cari");
   // };

   return (
      <ModalContext.Consumer>
         {(context) => {
            // modalContext = context;
            return (
               <div className="home">
                  <div className="home-content">
                     <AdsProvider {...props} />
                     <CategoryProvider />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
