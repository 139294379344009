import { User } from "../model";
import * as Master from "./Master";

export const newUser: User = new User(
   0,
   "",
   "",
   "",
   //Master.USER_ROLE_PARTNER,
   //Master.USER_ROLE_TRANSPORTER,
   Master.USER_ROLE_CUSTOMER,
   undefined
);

export const getEmptyUsers = (count: number) => {
   const users = Array<User>();
   for (let i = 0; i < count; i++) {
      users.push(new User());
   }
   return users;
};
