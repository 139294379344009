import {Place} from '.';

export default class Route {
   place: Place;
   status: string;
   completedDate: Date;
   desc: string;
   picName: string;
   picMobile: string;
}
