import ReactGA from "react-ga";
import { ModalContextInterface } from "../hooks/ModalContext";

import * as AlertHelper from "../helper/AlertHelper";
import { CacheService } from ".";

import * as Config from "../Config";

const ERROR_DESC_UNKNOWN = "Sistem sedang mengalami kendala. Mohon coba beberapa saat lagi atau hubungi tim helpdesk kami di 08111-6564-01";
const ERROR_CODE_INVALID_TOKEN = "E0000";
const ERROR_DESC_INVALID_TOKEN = "Invalid Token";
const ERROR_CODE_OTP_INVALID = "E0001";
const ERROR_DESC_OTP_INVALID = "Token OTP tidak valid";
const ERROR_CODE_OTP_EXPIRED = "E0002";
const ERROR_DESC_OTP_EXPIRED = "Token OTP telah kadaluarsa";
const ERROR_CODE_OTP_STILL_VALID = "E0003";
const ERROR_DESC_OTP_STILL_VALID = "Token OTP sebelumnya masih valid";
const ERROR_CODE_INVALID_OPERATION = "E0004";
const ERROR_DESC_INVALID_OPERATION = "Proses tidak diperbolehkan";
const ERROR_CODE_INVALID_REFERENCE = "E0005";
const ERROR_DESC_INVALID_REFERENCE = "Identifier referensi tidak valid";
const ERROR_CODE_VERSION_NOT_VALID = "E0006";
const ERROR_DESC_VERSION_NOT_VALID = "Terdapat aplikasi versi terbaru";
const ERROR_CODE_INVALID_VOUCHER_CODE = "E0007";
const ERROR_DESC_INVALID_VOUCHER_CODE = "Kode voucher tidak valid";
const ERROR_CODE_INSUFFICIENT_PAYMENT = "E0008";
const ERROR_DESC_INSUFFICIENT_PAYMENT = "Pembayaran tidak mencukupi";

const ERROR_CODE_ACCESS_DENIED = "E9999";
const ERROR_DESC_ACCESS_DENIED = "Akses tidak diperbolehkan";

function isErrorMatch(error: any, code: string) {
   try {
      let message = error.graphQLErrors[0].message.replace("\\", "");
      let e = JSON.parse(message);
      console.log("error code:", e.code);
      console.log("error desc:", e.desc);
      return e.code === code;
   } catch (err) {
      console.error(error);
      return false;
   }
}

function isAppVersionNotValid(error: any) {
   try {
      return error.networkError.statusCode === 205;
   } catch (err) {
      console.error(error);
      return false;
   }
}

function isNoConnection(error: any) {
   try {
      return error.message === "Failed to fetch";
   } catch (err) {
      console.error(error);
      return false;
   }
}

class ErrorService {
   isAccessDenied(error: any) {
      return isErrorMatch(error, ERROR_CODE_ACCESS_DENIED);
   }

   isInvalidToken(error: any) {
      return isErrorMatch(error, ERROR_CODE_INVALID_TOKEN);
   }

   isOtpInvalid(error: any) {
      return isErrorMatch(error, ERROR_CODE_OTP_INVALID);
   }

   isOtpExpired(error: any) {
      return isErrorMatch(error, ERROR_CODE_OTP_EXPIRED);
   }

   isOtpStillValid(error: any) {
      return isErrorMatch(error, ERROR_CODE_OTP_STILL_VALID);
   }

   getDesc(error: any) {
      console.error(error);
      try {
         if (isErrorMatch(error, ERROR_CODE_ACCESS_DENIED)) {
            return ERROR_DESC_ACCESS_DENIED;
         } else if (isErrorMatch(error, ERROR_CODE_INVALID_TOKEN)) {
            return ERROR_DESC_INVALID_TOKEN;
         } else if (isErrorMatch(error, ERROR_CODE_OTP_INVALID)) {
            return ERROR_DESC_OTP_INVALID;
         } else if (isErrorMatch(error, ERROR_CODE_OTP_EXPIRED)) {
            return ERROR_DESC_OTP_EXPIRED;
         } else if (isErrorMatch(error, ERROR_CODE_OTP_STILL_VALID)) {
            return ERROR_DESC_OTP_STILL_VALID;
         } else if (isErrorMatch(error, ERROR_CODE_INVALID_OPERATION)) {
            return ERROR_DESC_INVALID_OPERATION;
         } else if (isErrorMatch(error, ERROR_CODE_INVALID_REFERENCE)) {
            return ERROR_DESC_INVALID_REFERENCE;
         } else if (isErrorMatch(error, ERROR_CODE_VERSION_NOT_VALID)) {
            return ERROR_DESC_VERSION_NOT_VALID;
         } else if (isErrorMatch(error, ERROR_CODE_INVALID_VOUCHER_CODE)) {
            return ERROR_DESC_INVALID_VOUCHER_CODE;
         } else if (isErrorMatch(error, ERROR_CODE_INSUFFICIENT_PAYMENT)) {
            return ERROR_DESC_INSUFFICIENT_PAYMENT;
         } else {
            return ERROR_DESC_UNKNOWN;
         }
      } catch (err) {
         console.error(error);
      }
   }

   log(error: any) {
      try {
         console.error(error.message);
         if (error.response) {
            console.error("Error Response", JSON.stringify(error.response));
         }
      } catch (e) {}
   }

   gaLogError(error: any) {
      ReactGA.initialize(Config.GA_REF);

      let errorDesc = JSON.stringify(error, null, 0);
      errorDesc += " at " + window.location.pathname;

      ReactGA.exception({
         description: errorDesc,
         fatal: true,
      });

      if (CacheService.getApiRequest()) {
         let apiReq = CacheService.getApiRequest().replaceAll("@", "_at_");
         ReactGA.exception({
            description: "API Req: " + apiReq,
            fatal: true,
         });
      }

      if (error.graphQLErrors) {
         for (let graphQLError of error.graphQLErrors) {
            errorDesc = JSON.stringify(graphQLError, null, 0);
            errorDesc += " at " + window.location.pathname;
            ReactGA.exception({
               description: errorDesc,
               fatal: true,
            });
         }
      } else if (error.networkError) {
         errorDesc = JSON.stringify(error.networkError, null, 0);
         errorDesc += " at " + window.location.pathname;
         ReactGA.exception({
            description: errorDesc,
            fatal: true,
         });
      }

      //if (window.location.pathname === "/Confirm-Shipment") {
      if (CacheService.getNewShipment()) {
         let shipment = JSON.stringify(CacheService.getNewShipment(), null, 0);
         shipment = shipment.replaceAll("@", "_at_");
         ReactGA.exception({
            description: "Shipment: " + shipment,
            fatal: true,
         });
      }
   }

   handleError(error: Error, history: any, modalContext?: ModalContextInterface) {
      console.error("path: ", CacheService.getPath());
      console.error("error:", JSON.stringify(error, null, 2));

      if (isNoConnection(error)) {
         AlertHelper.alertWarning("Tidak terdapat koneksi internet. Mohon periksa sinyal internet anda ...", modalContext);
      } else if (isAppVersionNotValid(error)) {
         AlertHelper.alertInfo("Aplikasi versi terbaru tersedia. Aplikasi akan melaksanakan pembaruan halaman ...", modalContext);
         setTimeout(() => {
            AlertHelper.alertLoading("Melaksanakan pembaruan aplikasi ...", modalContext);
         }, 3000);
         setTimeout(() => {
            window.location.reload();
         }, 10000);
      } else if (this.isAccessDenied(error) || this.isInvalidToken(error)) {
         let desc = this.getDesc(error);
         AlertHelper.alertError(desc || "", JSON.stringify(error, null, 0), modalContext);
         history.push("/login/" + CacheService.getPath());
      } else {
         this.gaLogError(error);
         let desc = this.getDesc(error);
         if (desc === ERROR_DESC_UNKNOWN) {
            AlertHelper.alertError(desc || "", JSON.stringify(error, null, 0), modalContext);
         } else {
            AlertHelper.alertWarning(desc || "", modalContext);
         }
      }
   }
}

const service = new ErrorService();
export default service;
