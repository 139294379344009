import { Category } from "../model";
import * as Master from "./Master";

export const PICKUP: Category = {
   code: Master.CATEGORY_PICKUP,
   desc: "Pick Up",
   imageUrl: "/category/category-pickup.svg",
   imageUrls: ["/showroom/pck-1.png", "/showroom/pck-2.png"],
   info: "Kendaraan jenis Pickup hadir sebagai solusi ringkas untuk memenuhi kebutuhan anda, dengan spesifikasi mesin berkapasitas hingga 1495 cc, mesin ini mampu menghasilkan tenaga hingga 97 PS pada 6000 rpm dengan torsi maksimal mencapai 13,7 kgm pada perputaran mesin 4400 rpm. Mobil yang memiliki kecepatan maksimal hingga 140 km/jam ini memiliki berat 1,95 ton dan mampu mengangkut barang hingga 1 ton disertai 3 orang penumpang.",
};

export const VAN = {
   code: Master.CATEGORY_VAN,
   desc: "Van",
   tollFeeCategory: 1,
   imageUrl: "/category/category-van.svg",
   imageUrls: ["/showroom/van-1.png", "/showroom/van-2.png"],
   info: "Kendaraan jenis Van merupakan pilihan yang tepat bagi Anda yang menginginkan perlindungan ekstra pada barang dari hujan maupun teriknya matahari. Tipe kendaraan van memiliki spesifikasi mesin berkapasitas hingga 1495 cc, di mana mesin ini mampu menghasilkan tenaga hingga 97 PS pada 6000 rpm dengan torsi maksimal mencapai 13,7 kgm pada perputaran mesin 4400 rpm. Mobil yang memiliki kecepatan maksimal hingga 140 km/jam.",
};

export const COLT_DIESEL = {
   code: Master.CATEGORY_COLT_DIESEL,
   desc: "Colt Diesel",
   tollFeeCategory: 1,
   imageUrl: "/category/category-cdd.svg",
   imageUrls: ["/showroom/cdd-1.png", "/showroom/cdd-2.png"],
   info: "Mitsubishi Truk Colt Diesel hadir dengan membawa mesin berkapasitas 3908 cc, dimana mesin ini mampu menghasilkan tenaga sampai 110 PS pada 2900 rpm dengan torsi maksimal mencapai 28 kgm pada perputaran mesin 1600 rpm. Mobil yang memiliki kecepatan maksimal sampai 100 km/jam ini memiliki berat 2,1 ton namun jika digunakan untuk mengangkut barang bisa memuat sampai dengan 6 ton. Sedangkan untuk melalui medan tanjakan, truk yang satu ini tercatat memiliki kemampuan mencapai 39 ton.",
};

export const COLT_DIESEL_ENGKEL = {
   code: Master.CATEGORY_COLT_DIESEL_ENGKEL,
   desc: "Colt Diesel Engkel",
   tollFeeCategory: 1,
   imageUrl: "/category/category-cdd.svg",
   imageUrls: ["/showroom/cdd-1.png", "/showroom/cdd-2.png"],
   info: "Mitsubishi Truk Colt Diesel hadir dengan membawa mesin berkapasitas 3908 cc, dimana mesin ini mampu menghasilkan tenaga sampai 110 PS pada 2900 rpm dengan torsi maksimal mencapai 28 kgm pada perputaran mesin 1600 rpm. Mobil yang memiliki kecepatan maksimal sampai 100 km/jam ini memiliki berat 2,1 ton namun jika digunakan untuk mengangkut barang bisa memuat sampai dengan 6 ton. Sedangkan untuk melalui medan tanjakan, truk yang satu ini tercatat memiliki kemampuan mencapai 39 ton.",
};

export const COLT_DIESEL_DOUBLE = {
   code: Master.CATEGORY_COLT_DIESEL_DOUBLE,
   desc: "Colt Diesel Double",
   tollFeeCategory: 1,
   imageUrl: "/category/category-cdd.svg",
   imageUrls: ["/showroom/cdd-1.png", "/showroom/cdd-2.png"],
   info: "Mitsubishi Truk Colt Diesel hadir dengan membawa mesin berkapasitas 3908 cc, dimana mesin ini mampu menghasilkan tenaga sampai 110 PS pada 2900 rpm dengan torsi maksimal mencapai 28 kgm pada perputaran mesin 1600 rpm. Mobil yang memiliki kecepatan maksimal sampai 100 km/jam ini memiliki berat 2,1 ton namun jika digunakan untuk mengangkut barang bisa memuat sampai dengan 6 ton. Sedangkan untuk melalui medan tanjakan, truk yang satu ini tercatat memiliki kemampuan mencapai 39 ton.",
};

export const FUSO = {
   code: Master.CATEGORY_FUSO,
   desc: "Fuso",
   tollFeeCategory: 1,
   imageUrl: "/category/category-fuso.svg",
   imageUrls: ["/showroom/fso-1.png", "/showroom/fso-2.png"],
   info: "Mitsubishi Fuso telah dipercaya berkontribusi membangun perekonomian dan merupakan pilihan yang tepat bagi Anda para pengusaha, karena konsumsi bahan bakar yang efisien sehingga dapat menurunkan biaya operasional. Memiliki mesin berkapasitas hingga 7.545 cc, truk Fuso mampu menghasilkan tenaga hingga 270 PS pada 2800 rpm dengan torsi maksimal mencapai 64,95 kgm. Kendaraan jenis Fuso dapat melaju hingga kecepatan maksimum 96 km/jam dan mampu mengangkut beban muatan hingga 8 ton.",
};

export const TRONTON = {
   code: Master.CATEGORY_TRONTON,
   desc: "Tronton",
   tollFeeCategory: 1,
   imageUrl: "/category/category-tronton.svg",
   imageUrls: ["/showroom/tro-1.png", "/showroom/tro-2.png"],
   info: "Tronton merupakan jenis truk yang cukup laris digunakan sebagai angkutan logistik, karena truk ini memiliki dimensi tidak terlalu panjang dan kapasitas muatannya cukup besar. Keunggulan ini membuatnya bisa melewati jalan di kota kecil yang cukup sempit. Tronton adalah sebutan untuk truk dengan tiga as roda. Konfigurasinya satu as di depan dan dua lainnya di belakang (konfigurasi 1.1, 2.2, 2.2). Sumbu tengah berdekatan dengan sumbu belakang, jumlah ban yang dimiliki truk tronton yaitu 10 ban dan memiliki perkiraan bobot 11 sampai 12 ton. Kapasitas angkut dari tronton ini bisa mencapai 22 ton. Truk jenis ini biasanya menggunakan konfigurasi roda 2-4-4. Apabila dilihat dari jumlah ban, maka truk tronton memiliki ban berjumlah 10 buah, 2 di roda depan dan 8 di bagian belakang. Distribusi tenaga di truk tronton ini bervariasi, ada yang 6×2, atau 6×4, tergantung medan jalan yang sering dilalui",
};

export const WINGBOX = {
   code: Master.CATEGORY_WINGBOX,
   desc: "Wingbox",
   tollFeeCategory: 1,
   imageUrl: "/category/category-wingbox.svg",
   imageUrls: ["/showroom/wbx-1.png", "/showroom/wbx-1.png"],
   info: "Truk Wingbox merupakan truk box dengan pintu samping yang dapat dibuka ke atas, sehingga menyerupai sayap burung saat dibentangkan. Truk jenis ini mampu mempermudah dan mengefisienkan waktu proses bongkar muat barang karena terdapat tiga pintu yang dapat dibuka sekaligus. Truk Wingbox mampu menghasilkan tenaga hingga 230 PS pada 2.200 rpm, dan memiliki kecepatan maksimum 90 km/jam. Kapasitas angkut truk juga tergolong besar, yaitu hingga 30 ton, sehingga cocok bagi Anda yang ingin mengirim barang dalam jumlah sangat besar.",
};

export const TRAILER = {
   code: Master.CATEGORY_TRAILER,
   desc: "Trailer",
   tollFeeCategory: 1,
   imageUrl: "/category/category-trailer.svg",
   imageUrls: ["/showroom/trl-1.png", "/showroom/trl-1.png"],
   info: "Truk Trailer merupakan  kendaraan yang terdiri dari mesin penarik yang tersambung pada semi trailer.  Truk trailer tidak memiliki roda depan, melainkan ditopang oleh bagian ekor dari truk penarik, sehingga sebagian fraksi berat dari trailer dibawa oleh mesin penggerak. Truk Trailer memiliki mesin dengan kapasitas 7.545 cc yang mampu menghasilkan daya hingga 2.600 PS pada 270 rpm. truk Trailer dapat melaju hingga kecepatan 74 km/jam dengan radius putar minimum 8,7 m, memiliki berat kosong sebesar 7,3 ton dan kapasitas angkut barang hingga 36 ton. Kendaraan ini biasanya digunakan sebagai kepala trailer 40 feet atau kepala tangki BBM.",
};

export const CONTAINER = {
   code: Master.CATEGORY_CONTAINER,
   desc: "Container",
   tollFeeCategory: 1,
   imageUrl: "/category/category-container.svg",
   imageUrls: ["/showroom/con-1.png", "/showroom/con-1.png"],
   info: "Peti kemas (Container) mempunyai karakteristik yang kuat, tahan cuaca dan dapat dipakai berulang ulang dengan bongkar muat yang cepat dan jaminan safety yang luar biasa serta saat ini dilengkapi dengan electronic seal untuk kemudahan pengawasan (tracer). Sistem peti kemas memungkinkan untuk melakukan pengangkutan dengan sistem door to door.",
};

export const categories: Category[] = [PICKUP, VAN, COLT_DIESEL, FUSO, TRONTON, WINGBOX, TRAILER, CONTAINER];
export const fullCategories: Category[] = [PICKUP, VAN, COLT_DIESEL_ENGKEL, COLT_DIESEL_DOUBLE, FUSO, TRONTON, WINGBOX, TRAILER, CONTAINER];

export const categorySet = new Map([
   [Master.CATEGORY_PICKUP, PICKUP],
   [Master.CATEGORY_VAN, VAN],
   [Master.CATEGORY_COLT_DIESEL, COLT_DIESEL],
   [Master.CATEGORY_FUSO, FUSO],
   [Master.CATEGORY_TRONTON, TRONTON],
   [Master.CATEGORY_WINGBOX, WINGBOX],
   [Master.CATEGORY_TRAILER, TRAILER],
   [Master.CATEGORY_CONTAINER, CONTAINER],
]);

export const fullCategorySet = new Map([
   [Master.CATEGORY_PICKUP, PICKUP],
   [Master.CATEGORY_VAN, VAN],
   [Master.CATEGORY_COLT_DIESEL_ENGKEL, COLT_DIESEL_ENGKEL],
   [Master.CATEGORY_COLT_DIESEL_DOUBLE, COLT_DIESEL_DOUBLE],
   [Master.CATEGORY_FUSO, FUSO],
   [Master.CATEGORY_TRONTON, TRONTON],
   [Master.CATEGORY_WINGBOX, WINGBOX],
   [Master.CATEGORY_TRAILER, TRAILER],
   [Master.CATEGORY_CONTAINER, CONTAINER],
]);
