import {Step} from '../model';

export const steps: Step[] = [];

export const newStep: Step = new Step();

export const getEmptySteps = (count: number) => {
   const steps = Array<Step>();
   for (let i = 0; i < count; i++) {
      steps.push(newStep);
   }
   return steps;
};
