import React, {useState} from 'react';

import SearchInput from '../common/SearchInput';
import SubNavigation from '../common/SubNavigation';
import {OptionView} from '../common';

import {Option} from '../../model';

import * as AlertHelper from '../../helper/AlertHelper';

import './SelectOption.css';

export default function SelectOption(props: any) {
   const [options, setOptions] = useState<Option[]>(props.options);

   React.useEffect(() => {
      AlertHelper.enableScroll(!props.show, 'SelectOption');
   }, [props.show]);

   const searchOption = (input: any) => {
      let opts: Option[] = props.options;
      setOptions(opts.filter((opt) => new RegExp(input, 'i').test(opt.desc)));
   };

   const onClickPrev = () => {
      props.setShow(false);
   };
   const onClickClose = () => {
      props.setShow(false);
   };

   return (
      props.show && (
         <div className="select-option-container">
            <div className="select-option">
               <div className="select-option-content">
                  <SubNavigation title={props.title} onClickPrev={onClickPrev} onClickClose={onClickClose} />
                  <div className="select-option-search dark-border-bottom-side">
                     {props.enableSelect && (
                        <div style={{height: 50}}>
                           <SearchInput
                              transporterholder={props.searchTitle}
                              onChangeText={(text: any) => {
                                 searchOption(text);
                              }}
                              style={{marginBottom: 5}}
                           />
                        </div>
                     )}
                     <div style={{paddingTop: 5, paddingBottom: 10}} className="select-option-search-result">
                        {options.map((option: Option, key: number) => (
                           <OptionView key={key} option={option} onPressSelect={(option: any) => props.onSelectOption(option)} />
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   );
}
