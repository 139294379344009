import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { AuthService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import * as Notification from "../../constants/Notification";

import "./Forward.css";

let modalContext: ModalContextInterface;

function Forward(props: any) {
   const history = useHistory();
   const [init, setInit] = useState(false);
   const { match }: any = props;

   useEffect(() => {
      if (!init) {
         setInit(true);

         setTimeout(() => {
            AlertHelper.alertLoading(Notification.DATA_LOADING, modalContext);
         }, 1000);

         AuthService.authenticate(match.params.uid, match.params.role)
            .then((result) => {
               setTimeout(() => {
                  AlertHelper.hideLoading(modalContext);
                  modalContext?.setLogin(true);
               }, 1000);

               history.replace("/shipment-detail/" + match.params.shipmentId);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   }, [init, match, history]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return <div className="forward"></div>;
         }}
      </ModalContext.Consumer>
   );
}

export default Forward;
