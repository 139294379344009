import * as React from "react";
import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

import SquareButton from "../../common/SquareButton";
import MapMarker from "../../common/MapMarker";
import Places from "../../common/Places";
import SearchInput from "../../common/SearchInput";
import SubNavigation from "../../common/SubNavigation";

import { Place } from "../../../model";
import * as PlaceRef from "../../../reference/Place";
import * as Config from "../../../Config";

import MapService from "../../../service/MapService";

import * as SearchHelper from "../../../helper/SearchHelper";

import "./PlaceMapSelection.css";

class LatLng {
   lat: number;
   lng: number;
}

export default function SelectPlaceMap(props: any) {
   const refMap = React.createRef;
   const [searchText, setSearchText] = useState("");
   const [places, setPlaces] = useState<any[]>([]);
   const [place, setPlace] = useState<Place>(
      props.place
         ? {
              name: props.place.name,
              lat: props.place.lat,
              lng: props.place.lng,
              address: props.place.address,
           }
         : PlaceRef.defaultPlace
   );
   const [position, setPosition] = useState<LatLng>({
      lat: 0,
      lng: 0,
   });
   const [searchReset, setSearchReset] = useState(false);

   /*const getUserLocation = async () => {
    //get user location
  };*/

   useEffect(() => {
      setPosition({
         lat: props.isOrigin ? (props.shipment.origin ? props.shipment.origin.lat : PlaceRef.defaultLatLng.lat) : props.shipment.destination ? props.shipment.destination.lat : PlaceRef.defaultLatLng.lat,
         lng: props.isOrigin ? (props.shipment.origin ? props.shipment.origin.lng : PlaceRef.defaultLatLng.lng) : props.shipment.destination ? props.shipment.destination.lng : PlaceRef.defaultLatLng.lng,
      });
   }, [props]);

   const onSelectLocation = () => {
      if (place) {
         props.selectPlace(place);
      }
      if (props.shipment.origin && props.shipment.destination && props.shipment.origin.address && props.shipment.destination.address) {
         // return to new shipment
         props.setShowSelectPlace(false);
         props.setShow(false);
      } else {
         // return to select place
         props.setShow(false);
      }
   };

   const onMapChange = (camera: any) => {
      console.log("onChange: ", camera);
      setPosition({ lat: camera.center.lat, lng: camera.center.lng });
      MapService.getOsmPlaceFromGeoLoc(position).then((place: Place) => {
         setPlace(place);
      });
   };

   const searchPlace = (input: any) => {
      setSearchText(input);

      SearchHelper.searchInput(
         input,
         () => {
            MapService.getOsmPlace(input).then((responseJson) => {
               setPlaces(responseJson.results);
            });
         },
         1000
      );
   };

   const onSelectPlace = (place: any) => {
      let p: Place = {
         name: place.name,
         address: place.formatted_address,
         lat: place.geometry.location.lat,
         lng: place.geometry.location.lng,
      };
      setPosition({
         lat: place.geometry.location.lat,
         lng: place.geometry.location.lng,
      });
      setPlace(p);
      setSearchReset(true);
      setSearchText("");
      setPlaces([]);
   };

   const onClickPrev = () => {
      props.setShow(false);
   };
   const onClickClose = () => {
      props.setShow(false);
   };

   return (
      props.show && (
         <div className="select-place-map">
            <div className="container regular-border">
               <SubNavigation title="Pilih Lokasi" onClickPrev={onClickPrev} onClickClose={onClickClose} />
               <GoogleMapReact
                  ref={refMap}
                  bootstrapURLKeys={{ key: Config.GOOGLE_MAP_KEY }}
                  center={{
                     lat: position.lat,
                     lng: position.lng,
                  }}
                  defaultZoom={17}
                  onChange={onMapChange}
               >
                  <MapMarker size="small" lat={position.lat} lng={position.lng} />
               </GoogleMapReact>

               <div className="top-panel">
                  <div className="search-panel">
                     <SearchInput
                        placeholder={props.isOrigin ? "Lokasi Asal" : "Lokasi Tujuan"}
                        onChangeText={(text: any) => {
                           searchPlace(text);
                        }}
                        reset={searchReset}
                        setReset={setSearchReset}
                        style={{ marginBottom: 5 }}
                     />
                  </div>
                  {searchText.length === 0 && place.address && <div className="address regular-border regular-bold-font">{place.address}</div>}
                  {places.length > 0 && (
                     <div className="search-result">
                        <Places data={places} onPress={(place: any) => onSelectPlace(place)} className="regular-border" />
                     </div>
                  )}
               </div>
               {place && (
                  <div className="bottom-panel">
                     <SquareButton text="Pilih Lokasi" onClick={onSelectLocation} />
                  </div>
               )}
            </div>
         </div>
      )
   );
}
