import React, { useEffect, useState } from "react";

import { ImageView, CustomButton } from ".";

import Skeleton from "react-loading-skeleton";

import { Carrier } from "../../model";

import { CacheService } from "../../service";

import { transportTypeSet } from "../../reference/TransportType";

import * as ReferenceHelper from "../../helper/ReferenceHelper";

import "./CarrierView.css";

export function CarrierView(props: any) {
   const item: Carrier = props.carrier;
   const [desc, setDesc] = useState("");

   const onPressMore = () => {
      props.onPressMore(item);
   };

   const status = ReferenceHelper.getVerificationStatusDesc(item.verificationStatus);

   useEffect(() => {
      if (item.code || item.licenseNo) {
         setDesc(item.code + " / " + item.licenseNo);
      } else {
         setDesc("");
      }
   }, [item]);

   return (
      <div className="carrier-view-item regular-bottom-border">
         <div className="carrier-view-content">
            <div>
               <ImageView className="carrier-view-pic-image" uploadUrl={item.profileImgUrl} profile alt="carrier" />
            </div>
            <div className="carrier-view-pic">
               <div className="regular-bold-font">{desc || <Skeleton delay={1} width={200} />}</div>
               <div className="regular-font">{transportTypeSet.get(item.transportTypeCode)?.desc || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 250} />}</div>
               <div className="carrier-view-detail">
                  {CacheService.getUser().isAdmin && item.provider?.name && <span className="carrier-view-provider regular-white-font">{item.provider?.name}</span>}
                  {status && <span className={`carrier-view-status ${item.verificationStatus} regular-white-font`}>{status}</span>}
               </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.code} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default CarrierView;
