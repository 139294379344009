import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Select } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { CacheService, TransporterService, ErrorService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";

import { Transporter, Provider } from "../../../model";

import { getEmptyTransporters } from "../../../reference/Transporter";
import * as Master from "../../../reference/Master";

import "./Transporters.css";

let modalContext: ModalContextInterface;

export default function RegisterTransporter(props: any) {
   const history = useHistory();
   const { Option } = Select;
   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [transporters, setTransporters] = useState(getEmptyTransporters(pageSize));
   const [searchTransporter, setSearchTransporter] = useState("");
   const [searchProvider, setSearchProvider] = useState("");
   const [status, setStatus] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setTransporters(getEmptyTransporters(pageSize));
      SearchHelper.searchInputs([searchTransporter, searchProvider], () => {
         let provider = new Provider();
         provider.name = searchProvider;
         let transporter = new Transporter();
         transporter.name = searchTransporter;
         transporter.provider = provider;
         transporter.status = status;

         TransporterService.searchTransporter(transporter, pageSize, page)
            .then((result) => {
               setTransporters(result.transporters);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchTransporter, searchProvider, status, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchTransporter, searchProvider, status]);

   const onPressNewTransporter = (shipment: Transporter) => {
      history.push("/admin/transporter/add");
   };

   const onPressMore = (transporter: Transporter) => {
      CacheService.setTransporter(transporter);
      history.push(`/admin/transporter/update/${transporter.gid}`);
   };

   const handleChange = (value: any) => {
      setStatus(value);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="transporters">
                  <div className="transporters-header regular-bottom-border">
                     <div className="transporters-header-search">
                        <div className="transporters-header-search-section">
                           <SectionIcon person className="transporters-header-search-icon" />
                           <div className="transporters-header-search-input">
                              <InputSearch placeholder="Cari Pengemudi" value={searchTransporter} setValue={setSearchTransporter} />
                           </div>
                        </div>
                        {user.isAdmin && (
                           <div className="transporters-header-search-section">
                              <SectionIcon building className="transporters-header-search-icon" />
                              <div className="transporters-header-search-input">
                                 <InputSearch placeholder="Cari Penyedia Jasa" value={searchProvider} setValue={setSearchProvider} />
                              </div>
                           </div>
                        )}
                        {user.isAdmin && (
                           <div className="transporters-header-search-section">
                              <SectionIcon option className="transporters-header-search-icon" />
                              <div className="transporters-header-search-input">
                                 <Select className="transporters-header-search-select" placeholder="Status" onChange={handleChange} allowClear>
                                    <Option value={Master.STATUS_PENDING}>Pending</Option>
                                    <Option value={Master.STATUS_ACTIVE}>Aktif</Option>
                                    <Option value={Master.STATUS_INACTIVE}>Tidak Aktif</Option>
                                 </Select>
                              </div>
                           </div>
                        )}
                     </div>
                     {user.provider && (
                        <div className="transporters-header-button">
                           <CustomButton icon="truck" text="Tambah Pengemudi" onClick={onPressNewTransporter} style={{ width: 190 }} />
                        </div>
                     )}
                  </div>
                  <div id="search-result" className={"search-result " + (user.isAdmin ? (props.showBottomNavigation ? "search-result-admin" : "search-result-admin-no-nav") : props.showBottomNavigation ? "search-result" : "search-result-no-nav")}>
                     <PageView
                        transporters={transporters}
                        onPressMore={(transporter: Transporter) => {
                           console.log("transporter: ", JSON.stringify(transporter, null, 2));
                           onPressMore(transporter);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchTransporter && !searchProvider && !status && "Belum terdapat pengemudi"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
