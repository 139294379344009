import React from "react";
import mixpanel from "mixpanel-browser";

import * as Config from "../../Config";

import FooterItem from "./FooterItem";

import * as Master from "../../reference/Master";

import "./Footer.css";

function Footer(props: any) {
   const footerItems = [
      {
         title: window.innerWidth < 450 ? "Info Penting" : "Tentang Lintas",
         items: [
            { type: "text", title: "Tentang Kami", url: "/about_us" },
            { type: "text", title: "FAQs", url: "#" },
            { type: "text", title: "Kebijakan Penggunaan", url: "/tnc" },
            { type: "text", title: "Kebijakan Privasi", url: "/privacy" },
         ],
      },
      {
         title: "Media Sosial",
         items: [
            {
               type: "icon",
               icon: "fa-instagram",
               url: "/instagram",
               label: "instagram",
            },
            {
               type: "icon",
               icon: "fa-facebook-square",
               url: "/facebook",
               label: "facebook",
            },
         ],
      },
   ];

   const onClickWa = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS);

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Whatsapp Admin");
   };

   const onClickCall = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS_WA_CALL);

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Whatsapp Admin");
   };

   return (
      <div>
         {window.outerWidth > 1000 && (
            <div className="footer">
               <div className="footer-content">
                  <div className="footer-right">
                     <div className="footer-items">
                        {footerItems.map((footerItem, i) => (
                           <FooterItem footerItem={footerItem} key={i} id={i} />
                        ))}
                     </div>
                  </div>
                  <div className="footer-left">
                     <div className="big-bold-font">PT Lintas Teknologi Internasional</div>
                     <div className="content big-font">
                        <div className="big-font">Jembatan Logistik Indonesia</div>
                     </div>
                     <div className="big-bold-font">Dwijaya Plaza</div>
                     <div className="content big-font">
                        <div className="big-font">Jalan Radio Dalam Raya Nomor 3, Jakarta 12140, Indonesia</div>
                     </div>
                     <div className="contact">
                        <i className="fa fa-envelope-o fa-lg contact-icon" />
                        <span className="big-font">info@lintas.app</span>
                        <br />
                        <i className="fa fa-whatsapp fa-lg contact-icon" />
                        <span className="big-font footer-wa" onClick={onClickWa}>
                           +62 08111-6564-01
                        </span>
                        <br />
                        <i className="fa fa-phone fa-lg contact-icon" />
                        <span className="big-font footer-wa" onClick={onClickCall}>
                           +62 08111-6566-01
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         )}
      </div>
   );
}

export default Footer;
