import * as React from "react";
import Rating from "react-rating";

import { ImageView, CustomButton } from ".";

import Skeleton from "react-loading-skeleton";

import { AiFillStar } from "react-icons/ai";

import { Provider } from "../../model";

import * as FormatHelper from "../../helper/FormatHelper";

import { yellow } from "../../constants/Color";

import "./ProviderView.css";

export function ProviderView(props: any) {
   const provider: Provider = props.provider;
   const providerStyle = props.full ? "provider-view-item-full" : "provider-view-item";

   const onPressSelect = () => {
      props.onSelect(provider);
   };

   return (
      <div className={`${providerStyle} regular-bottom-border`}>
         <div className="provider-view-content">
            <div>
               <ImageView className="provider-view-pic-image" uploadUrl={provider.profileImgUrl} profile alt="transporter" />
            </div>
            <div className="provider-view-pic">
               <div className="regular-bold-font">{provider.name || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 170 : 250} />}</div>
               {props.full && <div className="regular-font">{provider.location?.address || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 170 : 300} />}</div>}
               {props.full && (
                  <div className="section-info-rating">
                     <div style={{ display: "flex" }}>
                        <Rating
                           stop={5}
                           placeholderRating={(provider?.name && provider.rating) || 0}
                           readonly
                           emptySymbol={<AiFillStar style={{ color: "#ccc" }} />}
                           placeholderSymbol={<AiFillStar style={{ color: yellow }} />}
                           fullSymbol={<AiFillStar style={{ color: yellow }} />}
                        />
                        <div className="service-provider-view-rating-content regular-bold-font">{(provider?.name && FormatHelper.formatFloatNumber(provider?.rating || 0).replace(",", ".")) || <Skeleton delay={1} width={50} />}</div>
                     </div>
                     <div
                        className="regular-bold-font"
                        style={{
                           marginLeft: 5,
                           marginRight: 5,
                        }}
                     >
                        {(provider?.name && provider.reviewCount + " Review") || <Skeleton delay={1} width={50} />}
                     </div>
                     <div
                        className="regular-bold-font"
                        style={{
                           marginLeft: 5,
                           marginRight: 5,
                        }}
                     >
                        {(provider?.name && provider.deliveryCount + " Pengiriman") || <Skeleton delay={1} width={50} />}
                     </div>
                  </div>
               )}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={provider.name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressSelect} />
            </div>
         </div>
      </div>
   );
}

export default ProviderView;
