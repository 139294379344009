import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { AuthService, CacheService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";
import * as GraphHelper from "../../helper/GraphHelper";

import * as Master from "../../reference/Master";

import "./Admin.css";

let modalContext: ModalContextInterface;

export function Admin(props: any) {
   const history = useHistory();

   useEffect(() => {
      AlertHelper.alertLoading("Loading ...", modalContext);

      AuthService.getSudoToken("", Master.ROLE_ADMIN)
         .then((auth) => {
            AlertHelper.hideLoading(modalContext);
            modalContext?.setLogin(true);

            CacheService.setToken({ token: auth.token, refreshToken: auth.refreshToken });
            console.log("auth: ", JSON.stringify(auth, null, 2));

            let user = GraphHelper.graphToUser(auth.user, Master.ROLE_ADMIN);

            CacheService.setUser(user);
            props.setUser(user);

            history.replace("/admin/provider");
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   });

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return <div className="admin"></div>;
         }}
      </ModalContext.Consumer>
   );
}
