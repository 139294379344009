import { DirectionService } from "../service";
import { Place } from "../model";

import * as CommonHelper from "../helper/CommonHelper";

import * as Config from "../Config";

let directions = new Map<string, any>();

let googleMaps: any;

export function initGoogleMaps(gmaps: any) {
   googleMaps = gmaps;
}

export async function getDirectionApi(origin: Place, destinations: Place[], callback: any) {
   const destination = destinations[destinations.length - 1];

   let directionId = `A[${origin.lat},${origin.lng}]`;
   for (const dest of destinations) {
      directionId += `,[${dest.lat},${dest.lng}]`;
   }

   let direction = directions.get(directionId);
   if (direction) {
      // console.log("direction from cache: ", JSON.stringify(direction.info, null, 2));
      callback(direction.info, direction.status);
      return;
   }

   DirectionService.getDirection(directionId).then((directionResp) => {
      if (directionResp) {
         direction = JSON.parse(directionResp.path);
      }

      if (direction) {
         // console.log("direction from srv: ", JSON.stringify(direction.info, null, 2));
         callback(direction.info, direction.status);
         return;
      }

      let paramOrigin = `origin=${origin.lat},${origin.lng}`;
      let paramDestination = `destination=${destination.lat},${destination.lng}`;
      let paramTravelMode = "travelMode=DRIVING";
      let paramWaypoints = "waypoints=";
      let paramAvoidTolls = "avoidTolls=true";

      const wps = destinations.slice(0, destinations.length);
      for (let i = 0; i < wps.length; i++) {
         if (i > 0) {
            paramWaypoints += "|";
         }
         paramWaypoints += `${wps[i].lat},${wps[i].lng}`;
      }

      let googleMapReq = `${Config.MAPS_API}/maps/api/directions/json?${paramOrigin}&${paramDestination}&${paramTravelMode}&${paramWaypoints}&${paramAvoidTolls}&key=${Config.GOOGLE_MAP_KEY}`;
      // console.log("Google Maps Request: ", googleMapReq);

      fetch(googleMapReq, {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         },
      })
         .then((response) => response.json())
         .then((respJson) => {
            console.log("getDirection: ", respJson);
            if (respJson.status === "OK") {
               let simpleDirection = getSimpleDirection(respJson);

               // save at local cache
               directions.set(directionId, { info: simpleDirection, status: respJson.status });

               // save at server cache
               let path = JSON.stringify({ info: simpleDirection, status: respJson.status });
               let d = { route: directionId, path: path };
               DirectionService.registerDirection(d);
               callback(simpleDirection, respJson.status);
            }
         });
   });
}

export function getDirectionMaps(origin: Place, destinations: Place[], callback: any) {
   const directionsService = new googleMaps.DirectionsService();
   const destination = destinations[destinations.length - 1];

   let directionId = `M[${origin.lat},${origin.lng}]`;
   for (const dest of destinations) {
      directionId += `,[${dest.lat},${dest.lng}]`;
   }

   let direction = directions.get(directionId);
   if (direction) {
      callback(direction.info, direction.status);
      // console.log("direction from cache: ", JSON.stringify(direction.info, null, 2));
      return;
   }

   DirectionService.getDirection(directionId).then((directionResp) => {
      if (directionResp) {
         direction = JSON.parse(directionResp.path);
      }

      if (direction) {
         callback(direction.info, direction.status);
         // console.log("direction from srv: ", JSON.stringify(direction, null, 2));
         return;
      }

      getOsmCityProvince(origin.lat, origin.lng).then((originCityProvince) => {
         console.log("origin cityProvince: ", JSON.stringify(originCityProvince, null, 2));

         getOsmCityProvince(destination.lat, destination.lng).then((destinationCityProvince) => {
            console.log("destination cityProvince: ", JSON.stringify(destinationCityProvince, null, 2));

            const originProvince = originCityProvince?.province ? originCityProvince?.province : "";
            const destinationProvince = destinationCityProvince?.province ? destinationCityProvince?.province : "";

            if (
               destinations.length === 1 &&
               (((originProvince.includes("Jawa") || originProvince === "Banten") && destinationProvince === "Kalimantan Timur") || ((destinationProvince.includes("Jawa") || destinationProvince === "Banten") && originProvince === "Kalimantan Timur"))
            ) {
               let originDestLat = 0;
               let originDestLng = 0;
               let destinationOrgLat = 0;
               let destinationOrgLng = 0;

               const sailingDuration = 35 * 60 * 60;
               const sailingDirection = [
                  {
                     lat: -3.28015,
                     lng: 117.0272,
                  },
                  {
                     lat: -5.700497,
                     lng: 115.77476,
                  },
                  {
                     lat: -6.65075,
                     lng: 112.74253,
                  },
               ];

               if (originProvince === "Kalimantan Timur") {
                  originDestLat = -1.27188;
                  originDestLng = 116.80622;
                  destinationOrgLat = -7.20767;
                  destinationOrgLng = 112.72705;
               } else {
                  originDestLat = -7.20767;
                  originDestLng = 112.72705;
                  destinationOrgLat = -1.27188;
                  destinationOrgLng = 116.80622;
                  sailingDirection.reverse();
               }

               directionsService.route(
                  {
                     origin: {
                        lat: origin.lat,
                        lng: origin.lng,
                     },
                     destination: {
                        lat: originDestLat,
                        lng: originDestLng,
                     },
                     travelMode: "DRIVING",
                     /* avoidTolls: true, */
                     drivingOptions: {
                        departureTime: new Date(),
                        trafficModel: "pessimistic",
                     },
                  },
                  (originResponse: any, status: any) => {
                     if (status === "OK") {
                        directionsService.route(
                           {
                              origin: {
                                 lat: destinationOrgLat,
                                 lng: destinationOrgLng,
                              },
                              destination: {
                                 lat: destination.lat,
                                 lng: destination.lng,
                              },
                              travelMode: "DRIVING",
                              /* avoidTolls: true, */
                              drivingOptions: {
                                 departureTime: new Date(),
                                 trafficModel: "pessimistic",
                              },
                           },
                           (destinationResponse: any, status: any) => {
                              if (status === "OK") {
                                 const originSimpleDirection = getSimpleDirection(originResponse);
                                 const destinationSimpleDirection = getSimpleDirection(destinationResponse);

                                 const originPath = CommonHelper.copyObject(originSimpleDirection.path);
                                 const destinationPath = CommonHelper.copyObject(destinationSimpleDirection.path);

                                 let simpleDirection = { distance: 0, duration: 0, path: [] };
                                 simpleDirection.distance = originSimpleDirection.distance + destinationSimpleDirection.distance;
                                 simpleDirection.duration = originSimpleDirection.duration + destinationSimpleDirection.duration + sailingDuration;

                                 simpleDirection.path = originPath.concat(sailingDirection, destinationPath);

                                 // save at local cache
                                 directions.set(directionId, { info: simpleDirection, status: status });

                                 // save at server cache
                                 let path = JSON.stringify({ info: simpleDirection, status: status });
                                 let d = { route: directionId, path: path };

                                 // console.log("simpleDirection: ", d);
                                 DirectionService.registerDirection(d);

                                 callback(simpleDirection, status);
                              }
                           }
                        );
                     }
                  }
               );
            } else {
               const wps = destinations.slice(0, destinations.length);
               const waypoints = wps.map((wp: Place) => ({
                  location: {
                     lat: wp.lat,
                     lng: wp.lng,
                  },
                  stopover: true,
               }));

               directionsService.route(
                  {
                     origin: {
                        lat: origin.lat,
                        lng: origin.lng,
                     },
                     destination: {
                        lat: destination.lat,
                        lng: destination.lng,
                     },
                     waypoints: waypoints,
                     travelMode: "DRIVING",
                     /* avoidTolls: true, */
                     drivingOptions: {
                        departureTime: new Date(),
                        trafficModel: "pessimistic",
                     },
                  },
                  (response: any, status: any) => {
                     if (status === "OK") {
                        let simpleDirection = getSimpleDirection(response);

                        // save at local cache
                        directions.set(directionId, { info: simpleDirection, status: status });

                        // save at server cache
                        let path = JSON.stringify({ info: simpleDirection, status: status });
                        let d = { route: directionId, path: path };

                        // console.log("simpleDirection: ", d);
                        DirectionService.registerDirection(d);

                        callback(simpleDirection, status);
                     }
                  }
               );
            }
         });
      });
   });
}

export function getSimpleDirection(direction: any) {
   let simpleDirection = { distance: 0, duration: 0, path: [] };
   simpleDirection.distance = Math.round(direction.routes[0].legs.reduce((d: number, v: any) => (d = d + v.distance.value / 1000), 0));
   simpleDirection.duration = Math.round(direction.routes[0].legs.reduce((d: number, v: any) => (d = d + v.duration.value), 0));
   simpleDirection.path = simpleDirection.path.concat(direction.routes[0].overview_path);
   return simpleDirection;
}

export async function getOsmCityProvince(lat: number, lng: number) {
   console.log("GetCityProvince");

   let endpoint = "/reverse?lat=" + lat + "&lon=" + lng + "&format=json&accept-language=id";
   let osmReq = "https://nominatim.openstreetmap.org" + endpoint;

   console.log("OSM Request: ", osmReq);

   let response = await fetch(osmReq, {
      method: "GET",
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
      },
   });

   let respJson = await response.json();

   console.log("getDirection: ", respJson);
   if (respJson.address) {
      let address = respJson.address;

      let city = "";
      let province = "";

      if (address.state && address.city) {
         city = address.city;
         province = address.state;
      } else if (address.state && address.county) {
         city = address.county;
         province = address.state;
      } else if (address.city && address.city_district) {
         city = address.city_district;
         province = address.city;
      } else if (address.state) {
         province = address.state;
      } else {
         city = "";
         province = "";
      }

      let result = {
         city: city,
         province: province,
      };

      return result;
   }
}
