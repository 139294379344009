import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CacheService } from '../../service';

import './Browser.css';

function Home(props) {
   const history = useHistory();
   const user = CacheService.getUser();

   // Opera 8.0+
   var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

   // Firefox 1.0+
   var isFirefox = typeof InstallTrigger !== 'undefined';

   // Safari 3.0+ "[object HTMLElementConstructor]" 
   var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

   // Internet Explorer 6-11
   var isIE = /*@cc_on!@*/false || !!document.documentMode;

   // Edge 20+
   var isEdge = !isIE && !!window.StyleMedia;

   // Chrome 1 - 79
   var isChrome = !!window.chrome && (!!window.chrome.webstore);

   // Edge (based on chromium) detection
   var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

   // Blink engine detection
   var isBlink = (isChrome || isOpera) && !!window.CSS;

   var output = 'isFirefox: ' + isFirefox + ' ';
   output += 'isChrome: ' + isChrome + ' ';
   output += 'isSafari: ' + isSafari + ' ';
   output += 'isOpera: ' + isOpera + ' ';
   output += 'isIE: ' + isIE + ' ';
   output += 'isEdge: ' + isEdge + ' ';
   output += 'isEdgeChromium: ' + isEdgeChromium + ' ';
   output += 'isBlink: ' + isBlink + ' ';

   useEffect(() => {
      props.setShowBackNavigation(false);
      props.setShowBottomNavigation(true);
   }, [props, history, user]);

   return (
      <div className="browser">
         Detecting browsers by ducktyping: <br />
         {output}<br /><br />
         Width: {window.innerWidth} / {window.outerWidth}<br />
         Height: {window.innerHeight} / {window.outerHeight}
      </div>
   );
}

export default Home;
