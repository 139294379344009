import { gql } from "@apollo/client";

import { Voucher } from "../model";
import { graphClient, authGraphClient, initClient, initAuthClient } from "./GraphService";

// import * as Master from "../reference/Master";

class VoucherService {
   async registerVoucher(voucher: Voucher) {
      await initAuthClient();
      // console.log("registerVoucher:", JSON.stringify(voucher, null, 2));

      let g = `
         mutation {
            RegisterVoucher(
               inputVoucher: {
                  gid: "",
                  code: "${voucher.code}",
                  desc: """${voucher.desc}""",
                  type: "${voucher.type}",
                  value: ${voucher.value},
                  valueCap: ${voucher.valueCap ? voucher.valueCap : 0},
                  usageCap: ${voucher.usageCap},
                  startDate: "${voucher.startDate.toISOString()}",
                  endDate: "${voucher.endDate.toISOString()}",
                  status: "",
                }
            )
         }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let voucherID = result.data.RegisterVoucher;

      // console.log(result.data);

      return voucherID;
   }

   async updateVoucher(voucher: Voucher) {
      await initAuthClient();
      // console.log("updateVoucher:", JSON.stringify(voucher, null, 2));

      let g = `
            mutation {
               UpdateVoucher(
                  inputVoucher: {
                     gid: "${voucher.gid}",
                     code: "${voucher.code}",
                     desc: """${voucher.desc}""",
                     type: "${voucher.type}",
                     value: ${voucher.value},
                     valueCap: ${voucher.valueCap ? voucher.valueCap : 0},
                     usageCap: ${voucher.usageCap},
                     startDate: "${voucher.startDate.toISOString()}",
                     endDate: "${voucher.endDate.toISOString()}",
                     status: "${voucher.status}",
                  }
               )
            }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let voucherID = result.data.RegisterVoucher;

      // console.log(result.data);

      return voucherID;
   }

   async searchVoucher(voucher: Voucher, pageSize: number, page: number) {
      await initAuthClient();
      //console.log('searchVoucher: ', JSON.stringify(voucher, null, 2));

      let voucherPageCountGql =
         page === 1
            ? `
            GetVouchersPageCount(
               voucherCode: "${voucher.code ? voucher.code : ""}", 
               providerName: "",
               status: "${voucher.status ? voucher.status : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetVouchers(
                  voucherCode: "${voucher.code ? voucher.code : ""}", 
                  providerName: "",
                  status: "${voucher.status ? voucher.status : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  gid
                  code
                  desc
                  type
                  value
                  valueCap
                  usageCount
                  usageCap
                  startDate
                  endDate
                  status
               }
               ${voucherPageCountGql}
            }`;

      //console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let resp = { vouchers: result.data.GetVouchers, pageCount: result.data.GetVouchersPageCount };

      // console.log("searchVoucher:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getVoucher(gid?: string) {
      await initAuthClient();
      // console.log("getVoucher:", gid);

      let gqlReq = `
            query { 
               GetVoucher(gid: "${gid}") {
                  gid
                  code
                  desc
                  type
                  value
                  valueCap
                  usageCount
                  usageCap
                  startDate
                  endDate
                  status
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log("result:", JSON.stringify(result, null, 2));

      let voucher: Voucher = result ? result.data.GetVoucher : null;

      // console.log("getVoucher:", JSON.stringify(voucher, null, 2));

      return voucher;
   }

   async getActiveVoucher(code?: string) {
      await initClient();

      console.log("getActiveVoucher:", code);

      let gqlReq = `
            query { 
               GetActiveVoucher(code: "${code}") {
                  gid
                  code
                  desc
                  type
                  value
                  valueCap
                  usageCount
                  usageCap
                  startDate
                  endDate
                  status
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await graphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log("result:", JSON.stringify(result, null, 2));

      let voucher: Voucher = result ? result.data.GetActiveVoucher : null;

      // console.log("getActiveVoucher:", JSON.stringify(voucher, null, 2));

      return voucher;
   }
}

const Service = new VoucherService();

export default Service;
