import { gql } from "@apollo/client";

import { graphClient, initClient } from "./GraphService";

class ReviewService {
   async GetReviewsProvider(gid?: string) {
      await initClient();
      // console.log("GetReviewsProvider", gid);

      let gqlReq = `
        query{
          GetReviewsProvider(gid: "${gid}") {
            reviewer
            rating
            remarks
            date
          }
        }`;

      // console.log("gql query: ", gqlReq);
      let result;

      try {
         result = await graphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      // console.log("result:", JSON.stringify(result, null, 2));
      let ReviewsProvider = result ? result.data.GetReviewsProvider : null;
      // console.log("GetReviewsProvider:", JSON.stringify(ReviewsProvider, null, 2));

      return ReviewsProvider;
   }

   async GetReviewsTransporter(gid?: string) {
      await initClient();
      // console.log("GetReviewsTransporter", gid);

      let gqlReq = `
        query{
          GetReviewsTransporter(gid: "${gid}") {
            reviewer
            rating
            remarks
            date
          }
        }`;

      // console.log("gql query: ", gqlReq);
      let result;

      try {
         result = await graphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      // console.log("result:", JSON.stringify(result, null, 2));
      let ReviewsTransporter = result ? result.data.GetReviewsTransporter : null;
      // console.log("GetReviewsTransporter:", JSON.stringify(ReviewsTransporter, null, 2));

      return ReviewsTransporter;
   }

   async GetReviewsCustomer(gid?: string) {
      await initClient();
      // console.log("GetReviewsCustomer", gid);

      let gqlReq = `
       query{
         GetReviewsCustomer(gid: "${gid}") {
           reviewer
           rating
           remarks
           date
         }
       }`;

      // console.log("gql query: ", gqlReq);
      let result;

      try {
         result = await graphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      // console.log("result:", JSON.stringify(result, null, 2));
      let ReviewsCustomer = result ? result.data.GetReviewsCustomer : null;
      // console.log("GetReviewsTransporter:", JSON.stringify(ReviewsCustomer, null, 2));

      return ReviewsCustomer;
   }

   async GetReviewsRating(gid?: string, role?: string) {
      await initClient();
      // console.log("GetReviewsRating", gid);

      let gqlReq = `
       query{
         GetReviewsRating(gid: "${gid}", role: "${role}")
       }`;

      // console.log("gql query: ", gqlReq);
      let result;

      try {
         result = await graphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      // console.log("result:", JSON.stringify(result, null, 2));
      let reviewsRating: number = result ? result.data.GetReviewsRating : null;
      // console.log("GetReviewsTransporter:", JSON.stringify(reviewsRating, null, 2));

      if (reviewsRating) {
         reviewsRating = Math.round(reviewsRating * 10) / 10;
      }

      return reviewsRating;
   }

   async UpdateReviewsCustomer(shipmentGid: string, rating: number, remarks: string) {
      await initClient();
      // console.log("UpdateReviewsCustomer:", JSON.stringify(shipmentGid, null, 2));

      let g = `
          mutation {
             UpdateReviewsCustomer(
                shipmentGid: "${shipmentGid}",
                rating: ${rating},
                remarks: """${remarks}""",
             ) {
                id
                gid
                bookingCode
                transportTypeDesc
                provider {
                   name
                   profileImgUrl
                }
                transporter {
                   name
                   profileImgUrl
                }
                customer {
                   name
                   profileImgUrl
                }
                carrier {
                   code
                   licenseNo
                   profileImgUrl
                }
                originDesc
                destinationDesc
                deliveryDate
                picOriginName
                picOriginMobile
                picOrderName
                picOrderMobile
                rescheduleFromDate
                rescheduleToDate
                rescheduleRemarks
                rescheduleRemarksHistory
                rescheduleStatus
                totalPayment
                description
                declineDesc
                weightEst
                remarks
                rating
                ratingRemarks
                status
             }
          }`;

      // console.log("gql:", g);

      let result = await graphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipment = result.data.UpdateReviewsCustomer;

      // console.log("result:", result.data);

      return shipment;
   }
}

const Service = new ReviewService();

export default Service;
