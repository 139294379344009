import React, { useState, useEffect } from "react";

import Skeleton from "react-loading-skeleton";

import { FadeView } from ".";
import OriginDestination from "./OriginDestination";
import CustomButton from "./CustomButton";

import { Search, TransportType } from "../../model";

// import * as CalendarHelper from "../../helper/CalendarHelper";

import { transportTypeSet } from "../../reference/TransportType";

import "./SearchServiceView.css";

export function SearchServiceView(props: any) {
   const searchService: Search = props.searchService;
   const [transportTypeDesc, setTransportTypeDesc] = useState("");

   // const [statusLabel, setStatusLabel] = useState("");
   // const [status, setStatus] = useState("");
   // const [deliveryDateLabel, setDeliveryDateLabel] = useState("");
   // const [deliveryDate, setDeliveryDate] = useState("");

   const onPressMore = () => {
      props.onPressMore(searchService);
   };

   useEffect(() => {
      if (searchService.transportTypeCode) {
         var date = new Date();
         date.setDate(date.getDate() + 1);
         let transportType: TransportType = transportTypeSet.get(searchService.transportTypeCode);
         setTransportTypeDesc(transportType.desc);
         // setStatusLabel("Jenis Muatan");
         // setStatus("Bahan Makanan / Produk Kemasan");
         // setDeliveryDateLabel("Estimasi Kirim");
         // setDeliveryDate(CalendarHelper.getDayDateMonthYear(date));
      } else {
         setTransportTypeDesc("");
         // setStatusLabel("");
         // setStatus("");
         // setDeliveryDateLabel("");
         // setDeliveryDate("");
      }
   }, [searchService]);

   return (
      <div>
         <FadeView show={true} duration="0" style={props.style}>
            <div className="search-service-view-item regular-bottom-border">
               <div className="search-service-view-top">
                  <div className="search-service-view-transport regular-bold-purple-font">{transportTypeDesc || <Skeleton delay={1} width={75} />}</div>
                  <div>
                     <CustomButton loading text="Pilih" loadingRef={transportTypeDesc} iconRight={true} icon={"more"} style={{ marginLeft: 5, width: 76 }} onClick={onPressMore} />
                  </div>
               </div>
               <div className="search-service-view-bottom">
                  <div className="search-service-view-origin-destination">
                     <OriginDestination origin={searchService.originDesc} destination={searchService.destinationDesc} />
                  </div>
                  {/*<div style={{ flex: 1 }}>
                     <div className="search-service-view-title regular-bold-font">{statusLabel || <Skeleton delay={1} width={75} />}</div>
                     <div className="search-service-view-desc regular-font">{status || <Skeleton delay={1} width={100} />}</div>
                     <div>
                        <div className="search-service-view-title regular-bold-font">{deliveryDateLabel || <Skeleton delay={1} width={75} />}</div>
                        <div className="search-service-view-desc regular-font">{deliveryDate || <Skeleton delay={1} width={100} />}</div>
                     </div>
                  </div>*/}
               </div>
            </div>
         </FadeView>
      </div>
   );
}

export default SearchServiceView;
