import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { UserService, CacheService, SiteService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import { ImageUpload, InputText, FormLayout, InputSelect } from "../common";

import { User, Image, Site } from "../../model";

import * as Master from "../../reference/Master";

import "./UpdateUser.css";

let modalContext: ModalContextInterface;

function UpdateUser(props: any) {
   const history = useHistory();
   const { control, errors, handleSubmit, setValue } = useForm();

   const userId = CacheService.getUser().gid;
   console.log("UpdateUser:", JSON.stringify(CacheService.getUser(), null, 2));

   const [user, setUser] = useState<User>(new User());
   const [profileImg, setProfileImg] = useState<Image>();

   const [siteOptions, setSiteOptions] = useState<Site[]>(new Array<Site>());
   const [role, setRole] = useState("");

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      SiteService.searchSite(new Site(), 1000, 1).then((result) => {
         const baseOptions = [{ value: "", label: "Semua Area" }];
         let options = result.sites.map((site: Site) => ({ value: site.gid, label: site.name }));
         options = baseOptions.concat(options);
         setSiteOptions(options);
         setRole(Master.PROVIDER_ROLE_GENERAL);
      });
      UserService.getUser(userId)
         .then((resp) => {
            setUser(resp);
            setProfileImg(resp.profileImg);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [userId, history]);

   useEffect(() => {
      if (user) {
         setValue([{ name: user.name }, { phone: user.mobile }, { email: user.email }, { site: user.providerUser?.site?.gid }]);
      }
   }, [user, setValue]);

   const onSubmit = (data: any) => {
      if (!profileImg) {
         //message.info('Mohon upload/unggah foto anda');
         //return;
      }

      AlertHelper.alertLoading("Memperbarui akun anda ...", modalContext);

      const usr: User = new User();
      usr.name = data.name;
      usr.mobile = data.phone;
      usr.email = data.email;
      usr.profileImg = profileImg;
      usr.profileImgUrl = profileImg?.url;

      console.log("profileImg:", JSON.stringify(profileImg, null, 2));

      UserService.updateUser(usr)
         .then((result) => {
            AlertHelper.alertSuccess("Proses pembaruan data", modalContext);
         })
         .catch((error: any) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <FormLayout doodle="/images/doodle/checklist.png" title="Pembaruan Data" desc="Anda dapat memperbarui akun anda">
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <ImageUpload single reference="provider-profile" image={profileImg} person text="Foto Anda" setImage={setProfileImg} />

                        <InputText name="name" placeholder="Nama" control={control} error={errors.name} />

                        <InputText name="phone" placeholder="No. Telepon" phone control={control} error={errors.phone} />

                        <InputText name="email" placeholder="Email" email control={control} error={errors.email} />

                        <InputSelect loading loadingRef={user.gid} name="site" placeholder="Pangkalan" disabled optional control={control} error={errors.site} options={siteOptions} />

                        <Radio.Group name="role" options={Master.providerRoleOptions} disabled value={role} buttonStyle="solid" />

                        <div className="action-button">
                           <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                              Simpan
                           </Button>
                        </div>
                     </form>
                  </FormLayout>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default UpdateUser;
