import * as React from "react";

import Skeleton from "react-loading-skeleton";

import "./OriginDestination.css";

export default function OriginDestination(props: any) {
   return (
      <div>
         {props.origin && (
            <div className="origin-destination-container">
               <div className="origin-destination-line"></div>
               <div className="origin-destination-place">
                  <div className="origin-destination-circle">
                     <div className="origin-destination-line-top" />
                     <div className="origin-destination-circle-outline">
                        <div className="origin-destination-circle-inline"></div>
                     </div>
                  </div>
                  <div className="origin-destination-text regular-bold-font">{props.origin}</div>
               </div>
               <div className="origin-destination-place">
                  <div className="origin-destination-circle">
                     <div className="origin-destination-line-bottom" />
                     <div className="origin-destination-circle-outline">
                        <div className="origin-destination-circle-inline"></div>
                     </div>
                  </div>
                  <div className="origin-destination-text regular-bold-font">{props.destination}</div>
               </div>
            </div>
         )}
         {!props.origin && (
            <div style={{ display: "flex", flexDirection: "column", width: 160 }}>
               <Skeleton delay={1} width={120} />
               <br />
               <Skeleton delay={1} width={120} />
            </div>
         )}
      </div>
   );
}
