import { Provider, Place } from ".";

export default class Site {
   id?: number;
   gid?: string;
   provider?: Provider;
   name: string;
   desc: string;
   location: Place;
   status: string;
}
