import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import mixpanel from "mixpanel-browser";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { MenuIcon } from "../common";

import { BsChevronCompactDown } from "react-icons/bs";

import * as MenuHelper from "../../helper/MenuHelper";
import * as AlertHelper from "../../helper/AlertHelper";

import { CacheService } from "../../service";

import * as Config from "../../Config";

import * as Master from "../../reference/Master";

import "./BottomNavigation.css";

let modalContext: ModalContextInterface;

export default function BottomNavigation(props: any) {
   const [menus, setMenus] = useState(new Array<any>());
   const [menu, setMenu] = useState();
   const [showSubMenus, setShowSubMenus] = useState(false);
   const [subMenus, setSubMenus] = useState(new Array<any>());
   const [user, setUser] = useState();

   useEffect(() => {
      if (props.user && JSON.stringify(user) !== JSON.stringify(props.user)) {
         setUser(props.user);
      }
   }, [user, props.user]);

   useEffect(() => {
      // console.log("menu user: ", JSON.stringify(user));
      // console.log("menu user: ", JSON.stringify(MenuHelper.getMenu(user)));
      setMenus(MenuHelper.getMenu(user));
   }, [user]);

   const onClose = () => {
      setShowSubMenus(false);
      const delay = setTimeout(() => {
         setSubMenus(new Array<any>());
         clearTimeout(delay);
      }, 350);
   };

   if (window.innerWidth < 1000) {
      return (
         <div className="bottom-navigation-container">
            <div className="bottom-navigation regular-top-border">
               {subMenus.length > 0 && (
                  <div className="sub-menus">
                     <div className="sub-menus-hide">
                        <div className="sub-menus-hide-btn" onClick={onClose}>
                           <BsChevronCompactDown className="sub-menus-hide-icon" />
                        </div>
                     </div>
                     {subMenus.map((subMenu: any, key: number) => (
                        <SubMenuItem menu={menu} subMenu={subMenu} setSubMenus={setSubMenus} showSubMenus={showSubMenus} setShowSubMenus={setShowSubMenus} setSelectedMenu={props.setSelectedMenu} key={key} />
                     ))}
                  </div>
               )}
               <div className="menus">
                  {menus.map((menu: any, key: number) => (
                     <MenuItem
                        menu={menu}
                        setMenu={setMenu}
                        active={menu.title === props.selectedMenu.title}
                        subMenus={subMenus}
                        setSubMenus={setSubMenus}
                        showSubMenus={showSubMenus}
                        setShowSubMenus={setShowSubMenus}
                        setSelectedMenu={props.setSelectedMenu}
                        key={key}
                     />
                  ))}
               </div>
            </div>
         </div>
      );
   } else {
      return <span />;
   }
}

function MenuItem(props: any) {
   const history = useHistory();
   const [user] = useState(CacheService.getUser());

   const onClick = () => {
      //console.log("onClick: ", props.menu.title);
      if (props.menu.submenus.length > 0) {
         CacheService.resetPage();
         props.setMenu(props.menu);
         props.setShowSubMenus(false);
         const delayClose = setTimeout(
            () => {
               props.setSubMenus(new Array<any>());
               const delayShow = setTimeout(
                  () => {
                     props.setShowSubMenus(true);
                     props.setMenu(props.menu);
                     props.setSubMenus(props.menu.submenus);
                     clearTimeout(delayShow);
                  },
                  props.subMenus.length > 0 ? 250 : 0
               );
               clearTimeout(delayClose);
            },
            props.subMenus.length > 0 ? 350 : 0
         );
      } else {
         CacheService.resetPage();
         props.setSelectedMenu(props.menu);
         props.setShowSubMenus(false);
         if (user) {
            if (props.menu.title === "Beranda") {
               if (user && user.gid) {
                  history.push("/home/login");
               } else {
                  history.push("/home");
               }
            } else {
               history.push(props.menu.link);
            }
         } else {
            history.push(props.menu.link);
         }

         const delay = setTimeout(() => {
            CacheService.savePrev();
            props.setSubMenus(new Array<any>());
            clearTimeout(delay);
         }, 350);
      }
   };

   return (
      <div className="menu-container">
         <div className={`menu ${props.active ? "menu-active" : ""}`} onClick={onClick}>
            <MenuIcon title={props.menu.title} className={`bottom-navigation ${props.active ? "icon-active" : "icon"}`} />
         </div>
         <div className="sub-regular-font">{props.menu.title}</div>
      </div>
   );
}

function SubMenuItem(props: any) {
   const history = useHistory();
   const [style, setStyle] = useState(props.showSubMenus ? "sub-menu-item" : "sub-menu-item-hide");

   useEffect(() => {
      setStyle(props.showSubMenus ? "sub-menu-item" : "sub-menu-item-hide");
   }, [props.showSubMenus]);

   const onClick = () => {
      props.setShowSubMenus(false);
      props.setSubMenus(new Array<any>());
      history.push(props.subMenu.link);
      CacheService.savePrev();

      if (props.subMenu.title === "Salin Referal Link") {
         let user = CacheService.getUser();
         navigator.clipboard.writeText(Config.REFFERAL_LINK + (user.provider?.refId ? user.provider.refId.toLowerCase() : user.provider?.refId));
         AlertHelper.alertSuccess("Salin referral link", modalContext);
      } else if (props.subMenu.title === "Telepon") {
         window.open("tel:+" + Master.MOBILE_LINTAS);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Call Admin");
      } else if (props.subMenu.title === "Chat / Pesan") {
         window.open("https://wa.me/" + Master.MOBILE_LINTAS);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Whatsapp Admin");
      } else {
         props.setSelectedMenu(props.menu);
      }
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div onClick={onClick} className="sub-menu-item-container regular-bottom-border">
                  <div className={`${style} bold-bold-light-font`}>{props.subMenu.title}</div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
