import React, { useState, useEffect } from "react";

import { ShipmentSteps } from "../common";

import * as Master from "../../reference/Master";

import "./BookingRegular.css";

function BookingRegular(props: any) {
   const [waCall, setWaCall] = useState(false);

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [props]);

   useEffect(() => {
      if (!waCall) {
         setWaCall(false);
         setTimeout(() => window.open("https://wa.me/" + Master.MOBILE_LINTAS), 3000);
      }
   }, [waCall]);

   return (
      <div className="booking-regular">
         <div className="booking-regular-content">
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
               <ShipmentSteps status={Master.SHIPMENT_STATUS_NEW_ORDER} />
            </div>
            <div className="booking-regular-category">
               <div className="booking-regular-category-description regular-bold-font">Mohon tunggu kami akan hubungkan anda dengan customer service melalui Whatsapp ...</div>
               <img src="/images/icon/whatsapp.png" alt="whatsapp" className="booking-regular-whatsapp" />
            </div>
         </div>
      </div>
   );
}

export default BookingRegular;
