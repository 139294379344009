import { Place, Fleet, Image, Service, Site } from ".";

export default class Provider {
   id: number;
   gid?: string;
   refId?: string;
   name?: string;
   type?: string;
   phone?: string;
   email?: string;
   location?: Place;
   level?: string;
   rating?: number;
   rate: number;
   profileImg?: Image;
   profileImgUrl?: string;
   profileDesc?: string;
   service?: Service;
   fleet?: Fleet[];
   transWorkerService?: Service;
   reviewCount: number;
   deliveryCount: number;
   bankCode?: string;
   bankName?: string;
   bankAccNo?: string;
   bankAccName?: string;
   site?: Site;
   status?: string;
}
