import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "antd";

import Skeleton from "react-loading-skeleton";

import "./InputText.css";

export default function InputText(props: any) {
   const { TextArea } = Input;
   //const [size, setSize] = useState(0);

   let patternRule = props.email
      ? {
           pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: ` Alamat ${props.placeholder.toLowerCase()} tidak valid`,
           },
        }
      : {};

   patternRule =
      props.phone || props.number
         ? {
              pattern: {
                 value: /^[0-9]*$/,
                 message: `  ${props.placeholder.toLowerCase()} tidak valid, hanya angka yang diperbolehkan`,
              },
           }
         : patternRule;

   return (
      <div className="input-text" style={props.width ? { width: props.width } : {}}>
         {props.loading && !props.loadingRef ? (
            <div className="input-text-skeleton regular-border">
               <Skeleton height={20} />
            </div>
         ) : props.rows ? (
            props.control ? (
               <Controller
                  as={TextArea}
                  name={props.name}
                  control={props.control}
                  rows={props.rows}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  rules={{
                     required: {
                        value: true,
                        message: ` Mohon isi ${props.placeholder.toLowerCase()}`,
                     },
                     maxLength: {
                        value: props.size ? props.size : 50,
                        message: ` Input ${props.placeholder.toLowerCase()} terlalu panjang`,
                     },
                     ...patternRule,
                  }}
                  readOnly={props.readOnly}
                  // onChange={([e]: any) => {
                  //    console.log("text: ", e.target.value);
                  // }}
                  style={props.readOnly ? { backgroundColor: "#f7f7f7" } : {}}
               />
            ) : (
               <TextArea
                  name={props.name}
                  rows={props.rows}
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  defaultValue={props.defaultValue}
                  onChange={(e: any) => {
                     if (props.onChange) {
                        props.onChange(e);
                     }
                  }}
                  readOnly={props.readOnly}
                  style={props.readOnly ? { backgroundColor: "#f7f7f7" } : {}}
               />
            )
         ) : (
            <Controller
               as={Input}
               name={props.name}
               control={props.control}
               rules={{
                  required: {
                     value: true,
                     message: ` Mohon isi ${props.placeholder.toLowerCase()}`,
                  },
                  maxLength: {
                     value: props.size ? props.size : 50,
                     message: ` Input ${props.placeholder.toLowerCase()} terlalu panjang`,
                  },
                  ...patternRule,
               }}
               readOnly={props.readOnly}
               /*onChange={(e: any) => {
                  if (props.onChange) {
                     props.onChange(e);
                  }
               }}*/
               style={props.readOnly ? { backgroundColor: "#f7f7f7" } : {}}
            />
         )}
         {props.error && (
            <div className="error">
               <i className="fa fa-times-circle fa-lg" />
               {props.error.message}
            </div>
         )}
         <div className="input-text-label-container">
            <div className="input-text-label regular-grey-font">{props.placeholder}</div>
            {/*props.rows && <div className="input-text-label regular-grey-font">{(props.size ? props.size : 50) - size}</div>*/}
         </div>
      </div>
   );
}
