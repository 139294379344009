import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { Menu } from "./menu";

import * as HomeRef from "../../reference/Home";

import { ShipmentService, ErrorService, CacheService, ReviewService } from "../../service";

import { Shipment } from "../../model";

import { InputSearch, PageView, FadeView } from "../common";

import * as ShipmentRef from "../../reference/Shipment";

import * as SearchHelper from "../../helper/SearchHelper";
import * as PositionHelper from "../../helper/PositionHelper";
import * as ConfirmHelper from "../../helper/ConfirmHelper";

import * as Master from "../../reference/Master";

import { isCustomer, isProvider, isTransporter } from "../../model/User";

// import JavaScriptInterface from "../../interface/AndroidInterface";

import "./LoginHome.css";

let modalContext: ModalContextInterface;

// declare var android: JavaScriptInterface;

function LoginHome(props: any) {
   const history = useHistory();
   const user = CacheService.getUser();

   const pageSize = 10;
   const [shipments, setShipments] = useState(ShipmentRef.getEmptyShipments(pageSize));
   const [page, setPage] = useState(CacheService.getPage());
   const [pageCount, setPageCount] = useState(CacheService.getPageCount());

   const [searchShipment, setSearchShipment] = useState("");
   const [shipmentStatuses] = useState(user ? (user.customer ? HomeRef.customerShipmentStatus : user.provider ? HomeRef.providerShipmentStatus : user.transporter ? HomeRef.transporterShipmentStatus : HomeRef.adminShipmentStatus) : []);
   const [providerActions] = useState(HomeRef.providerActions);
   const [statusMap, setStatusMap] = useState(new Map<string, number>());
   const [showSearchResult, setShowSearchResult] = useState(false);
   const [isInitGpsPermission, setInitGpsPermission] = useState(CacheService.isGpsPermissionRequested());

   const [userRating, setUserRating] = useState(0);

   useEffect(() => {
      props.setShowBackNavigation(false);
      props.setShowBottomNavigation(true);
   }, [props]);

   useEffect(() => {
      ShipmentService.getShipmentStatusSummary()
         .then((shipmentStatuses) => {
            let map = new Map<string, number>(shipmentStatuses);
            for (const shipmentStatus of shipmentStatuses) {
               map.set(shipmentStatus.status, shipmentStatus.count);
            }

            let confirmPaymentCount = map.get(Master.SHIPMENT_STATUS_CONFIRM_PAYMENT);
            if (!confirmPaymentCount) {
               confirmPaymentCount = 0;
            }
            let confirmNoPaymentCount = map.get(Master.SHIPMENT_STATUS_CONFIRM_NO_PAYMENT);
            if (!confirmNoPaymentCount) {
               confirmNoPaymentCount = 0;
            }
            map.set(Master.SHIPMENT_STATUS_CONFIRM_PAYMENT, confirmPaymentCount + confirmNoPaymentCount);

            setStatusMap(map);
            // console.log("status map: " + JSON.stringify(map, null, 2));
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [history]);

   useEffect(() => {
      if (user && user.transporter) {
         if (!isInitGpsPermission) {
            ConfirmHelper.confirm(
               "Lintas menggunakan data lokasi anda untuk proses tracking pengiriman angkutan walaupun aplikasi tidak ditutup atau tidak digunakan. \nLintas collects location data to enable shipment delivery tracking even when the app is closed or not in use. ",
               () => updateShipmentInDeliveryPosition,
               modalContext
            );
            setInitGpsPermission(true);
            CacheService.setGpsPermissionRequested(true);
         } else if (CacheService.isGpsPermissionRequested()) {
            PositionHelper.updateShipmentInDeliveryPosition();
         }
      }
   }, [isInitGpsPermission, user]);

   useEffect(() => {
      if (searchShipment && searchShipment.length > 3) {
         setShipments(ShipmentRef.getEmptyShipments(pageSize));
         ShipmentService.searchShipment(searchShipment, "", pageSize, page)
            .then((result) => {
               setShipments(result.shipments);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         setShipments(ShipmentRef.getEmptyShipments(0));
      }
   }, [page, history, searchShipment]);

   useEffect(() => {
      setShowSearchResult((searchShipment && searchShipment.length >= 3) || false);
   }, [searchShipment]);

   useEffect(() => {
      if (isCustomer(user)) {
         ReviewService.GetReviewsRating(user.customer?.gid, user.role).then((result) => {
            setUserRating(result);
         });
      } else if (isProvider(user)) {
         ReviewService.GetReviewsRating(user.provider?.gid, user.role).then((result) => {
            setUserRating(result);
         });
      } else if (isTransporter(user)) {
         ReviewService.GetReviewsRating(user.transporter?.gid, user.role).then((result) => {
            setUserRating(result);
         });
      }
   }, [user]);

   const updateShipmentInDeliveryPosition = () => {
      PositionHelper.updateShipmentInDeliveryPosition();
      CacheService.setGpsPermissionGiven(true);
   };

   const shipmentSearch = (input: string) => {
      SearchHelper.searchInput(input, () => {
         setSearchShipment(input);
      });
   };

   const onPressMore = (shipment: Shipment) => {
      CacheService.setShipment(shipment);
      history.push(`/shipment-detail/${shipment.gid}`);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FadeView show={true} duration="0">
                  <div className="provider-home">
                     <div className="provider-home-search">
                        <InputSearch placeholder="Cari Pengiriman" value={searchShipment} setValue={shipmentSearch} />
                     </div>
                     <div className={"provider-home-content"} style={!props.setShowBottomNavigation && window.innerWidth < 1000 ? { height: window.innerHeight - 194 } : {}}>
                        <div className={"status-action"}>
                           {!showSearchResult &&
                              statusMap &&
                              shipmentStatuses.map((providerSummary: any, key: number) => (
                                 <Menu
                                    key={key}
                                    section="status"
                                    id={providerSummary.code}
                                    action={
                                       providerSummary.code === Master.PARTNER_SUMMARY_BOOKING && user.role === "CUSTOMER"
                                          ? "/booking"
                                          : providerSummary.code === Master.PARTNER_SUMMARY_RATING && user.role === "CUSTOMER"
                                          ? "/review/customer"
                                          : providerSummary.code === Master.PARTNER_SUMMARY_RATING && user.role === "PROVIDER"
                                          ? "/review/provider"
                                          : providerSummary.code === Master.PARTNER_SUMMARY_RATING && user.role === "TRANSPORTER"
                                          ? "/review/transporter"
                                          : "/shipment/status/" + providerSummary.code.toLowerCase() + "/" + providerSummary.desc
                                    }
                                    title={providerSummary.desc}
                                    menu={{ title: "Pengiriman" }}
                                    setSelectedMenu={props.setSelectedMenu}
                                    count={statusMap ? statusMap.get(providerSummary.code) : ""}
                                    rating={providerSummary.code === Master.PARTNER_SUMMARY_RATING ? userRating : undefined}
                                 />
                              ))}
                           {window.outerWidth < 1000 && <div className="divider"></div>}
                           {!showSearchResult &&
                              !isCustomer(user) &&
                              providerActions.map((providerAction: any, key: number) => (
                                 <Menu key={key} section="action" action={providerAction.action} id={providerAction.code} menu={providerAction.menu} title={providerAction.desc} setSelectedMenu={props.setSelectedMenu} />
                              ))}
                        </div>
                        {showSearchResult && (
                           <div id="search-result" className={"search-result"}>
                              <PageView
                                 shipments={shipments}
                                 onPressMore={(shipment: Shipment) => {
                                    onPressMore(shipment);
                                 }}
                                 page={page}
                                 setPage={setPage}
                                 pageCount={pageCount}
                                 emptyText="Tidak ditemukan pengiriman"
                              />
                           </div>
                        )}
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default LoginHome;
