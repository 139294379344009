import React from 'react';
import WhyUsItem from './WhyUsItem';

import './WhyUs.css';

function WhyUs() {
  let whyUsList = [
    {
      icon: 'fa-truck',
      title: 'Pilihan angkutan terlengkap',
      info: 'Berbagai jenis angkutan yang sesuai untuk kapasitas muatan',
    },
    {
      icon: 'fa-money',
      title: 'Kepastian harga',
      info: 'Informasi biaya sewa di awal tanpa perlu bernegosiasi langsung dengan mitra',
    },
    {
      icon: 'fa-magic',
      title: 'Pemesanan Mudah',
      info: 'Langkah mudah untuk dapat segera memesan kendaraan angkutan',
    },
    {
      icon: 'fa-key',
      title: 'Keamanan Pembayaran',
      info: 'Biaya angkutan hanya akan diteruskan ke mitra hanya ketika pengiriman selesai',
    },
  ];

  return (
    <div className="why-us">
      <div className="why-us-content">
        <div className="why-us-title">Kenapa Lintas menjadi pilihan terbaik?</div>
        <div className="why-us-description">
          Berbagai keunggulan layanan yang kami berikan mendukung berbagai kebutuhan angkutan
        </div>
        <div className="why-us-items">
          {whyUsList.map((whyUs, i) => (
            <WhyUsItem whyUs={whyUs} key={i} id={i} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
