import React from "react";

import { AiOutlineHome, AiOutlineCar, AiOutlineUser, AiOutlineAppstore, AiOutlineFolder } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { BiInfoCircle, BiExit, BiMoney } from "react-icons/bi";

import { BsChatDots } from "react-icons/bs";
import { FiTruck } from "react-icons/fi";

import "antd/dist/antd.css";
import "./MenuIcon.css";

function MenuIcon(props: any) {
   if (props.title === "Home") {
      return <AiOutlineHome className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Akun") {
      return <AiOutlineUser className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Pesan") {
      return <BsChatDots className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Beranda") {
      return <AiOutlineHome className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Pengiriman") {
      return <FiTruck className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Jadi Mitra") {
      return <AiOutlineCar className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Masuk") {
      return <AiOutlineUser className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Keluar") {
      return <BiExit className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Download") {
      return <AiOutlineAppstore className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Kelola") {
      return <AiOutlineFolder className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Bantuan") {
      return <IoCallOutline className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Informasi") {
      return <BiInfoCircle className={"menu-icon " + props.className} {...props} />;
   } else if (props.title === "Cek Harga") {
      return <BiMoney className={"menu-icon " + props.className} {...props} />;
   } else {
      return <div className={"menu-icon " + props.className} {...props}></div>;
   }
}

export default MenuIcon;
