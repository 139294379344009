import {Provider, Image, Carrier} from '.';

export default class Transporter {
   id?: number;
   gid?: string;
   carrier?: Carrier;
   name: string;
   dob: Date;
   phone: string;
   email: string;
   profileImg: Image;
   profileImgUrl?: string;
   citizenCardNo: string;
   citizenCardImg?: Image;
   drivingLicenseNo: string;
   drivingLicenseExpiry: Date;
   drivingLicenseImg?: Image;
   mobile: string;
   provider?: Provider;
   verificationStatus: string;
   status: string;
}
