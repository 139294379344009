import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { ImageUpload, InputText, InputSelect, InputDate, FormLayout } from "../../common";

import * as Notification from "../../../constants/Notification";

import { CarrierService, CacheService, ErrorService } from "../../../service";

import * as AlertHelper from "../../../helper/AlertHelper";

import { Image, Carrier } from "../../../model";

import * as Master from "../../../reference/Master";
import { transportTypes } from "../../../reference/TransportType";

import "./CarrierForm.css";

import moment from "moment";

let modalContext: ModalContextInterface;

function RegisterNewCarrier(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const { control, errors, handleSubmit, setValue } = useForm();

   const [carrier, setCarrier] = useState<Carrier>(new Carrier());
   const [isCreate, setIsCreate] = useState(true);
   const [profileImg, setProfileImg] = useState<Image>();
   const [licenseImg, setLicenseImg] = useState<Image>();
   const [certificateImg, setCertificateImg] = useState<Image>();
   const [verificationStatus, setVerificationStatus] = useState("");
   const [status, setStatus] = useState("");

   const transportTypeOptions = transportTypes.map((transportType) => ({ value: transportType.code, label: transportType.desc }));

   const user = CacheService.getUser();
   let fleetCategory = "";

   const onChangeStatusOptions = (e: any) => {
      setStatus(e.target.value);
   };

   const onChangeVerifiedStatusOptions = (e: any) => {
      setVerificationStatus(e.target.value);
   };

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!match.params.id) {
         return;
      }
      setIsCreate(false);
      CarrierService.getCarrier(match.params.id)
         .then((result) => {
            setCarrier(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history]);

   useEffect(() => {
      if (!carrier.id) {
         return;
      }
      let licenseExpiry = moment(carrier.licenseExpiry);
      let certificateExpiry = moment(carrier.certificateExpiry);
      setProfileImg(carrier.profileImg);
      setLicenseImg(carrier.licenseImg);
      setCertificateImg(carrier.certificateImg);
      setValue([
         { name: carrier.name },
         { code: carrier.code },
         { licenseNo: carrier.licenseNo },
         { licenseExpiry: licenseExpiry },
         { certificateNo: carrier.certificateNo },
         { certificateExpiry: certificateExpiry },
         { transportTypeCode: carrier.transportTypeCode },
         { capacityLength: carrier.capacityLength },
         { capacityWidth: carrier.capacityWidth },
         { capacityHeight: carrier.capacityHeight },
      ]);
      setVerificationStatus(carrier.verificationStatus);
      setStatus(carrier.status);
   }, [carrier, setValue]);

   const onSubmit = (data: any) => {
      if (!profileImg || !profileImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto armada", modalContext);
         return;
      }
      if (!licenseImg || !licenseImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto STNK", modalContext);
         return;
      }
      if (!certificateImg || !certificateImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto KIR", modalContext);
         return;
      }

      const selectedTransportType = transportTypes.filter((transportType) => transportType.code === data.transportTypeCode);
      if (selectedTransportType.length > 0) {
         fleetCategory = selectedTransportType[0].category;
      }

      const c: Carrier = new Carrier();
      c.gid = carrier.gid;
      c.name = "";
      c.code = data.code;
      c.transportTypeCode = data.transportTypeCode;
      c.fleetCategoryCode = fleetCategory;
      c.profileImg = new Image().setId(profileImg?.id);
      c.profileImgUrl = profileImg?.url;
      c.licenseNo = data.licenseNo;
      c.licenseExpiry = data.licenseExpiry;
      c.licenseImg = new Image().setId(licenseImg?.id);
      c.certificateNo = data.certificateNo;
      c.certificateExpiry = data.certificateExpiry;
      c.certificateImg = new Image().setId(certificateImg?.id);
      c.verificationStatus = verificationStatus;
      c.capacityLength = data.capacityLength;
      c.capacityWidth = data.capacityWidth;
      c.capacityHeight = data.capacityHeight;
      c.status = status;

      console.log(JSON.stringify(c, null, 2));

      if (isCreate) {
         AlertHelper.alertLoading(Notification.DATA_REGISTER, modalContext);

         CarrierService.registerCarrier(c)
            .then((result) => {
               AlertHelper.alertSuccess("Proses registrasi data kendaraan angkutan", modalContext);
               setTimeout(() => history.push("/admin/carrier"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         AlertHelper.alertLoading(Notification.DATA_SAVING, modalContext, "CarrierForm");

         CarrierService.updateCarrier(c)
            .then((result) => {
               AlertHelper.alertSuccess("Proses pembaruan data kendaraan angkutan", modalContext, "CarrierForm");
               setTimeout(() => history.push("/admin/carrier"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   const onBack = () => {
      history.goBack();
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FormLayout doodle="/images/doodle/delivery.png" title={isCreate ? "Registrasi Kendaraan" : "Perubahan Data Kendaraan"} desc={isCreate ? "Mohon lengkapi data kendaraan" : "Mohon perbarui data kendaraan"}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <ImageUpload single reference="carrier-profile" image={profileImg} person text="Foto Armada" setImage={setProfileImg} />

                     <InputText loading={!isCreate} loadingRef={carrier.code} name="code" placeholder="Kode Armada" control={control} error={errors.code} />
                     <InputSelect loading={!isCreate} loadingRef={carrier.transportTypeCode} name="transportTypeCode" placeholder="Tipe Kendaraan" control={control} error={errors.transportTypeCode} options={transportTypeOptions} />
                     <InputText loading={!isCreate} loadingRef={carrier.licenseNo} name="licenseNo" placeholder="No. Plat Kendaraan" control={control} error={errors.licenseNo} />
                     <InputDate loading={!isCreate} loadingRef={carrier.licenseExpiry} name="licenseExpiry" placeholder="Tanggal STNK Berakhir" control={control} error={errors.licenseExpiry} />
                     <InputText loading={!isCreate} loadingRef={carrier.certificateNo} name="certificateNo" placeholder="No. KIR" control={control} error={errors.certificateNo} />
                     <InputDate loading={!isCreate} loadingRef={carrier.certificateExpiry} name="certificateExpiry" placeholder="Tanggal KIR Berakhir" control={control} error={errors.certificateExpiry} />
                     <div className="carrier-form-dimension-label">Dimensi Kapasitas Muatan</div>
                     <div className="carrier-form-dimension">
                        <div>
                           <InputText
                              loading={!isCreate}
                              loadingRef={carrier.certificateNo}
                              name="capacityLength"
                              placeholder="Panjang Cm"
                              control={control}
                              error={errors.capacityLength}
                              width={((window.innerWidth < 400 ? window.innerWidth : 450) - 90) / 3}
                           />
                        </div>
                        <div>
                           <InputText
                              loading={!isCreate}
                              loadingRef={carrier.certificateNo}
                              name="capacityWidth"
                              placeholder="Lebar Cm"
                              control={control}
                              error={errors.capacityWidth}
                              width={((window.innerWidth < 400 ? window.innerWidth : 450) - 90) / 3}
                           />
                        </div>
                        <div>
                           <InputText
                              loading={!isCreate}
                              loadingRef={carrier.certificateNo}
                              name="capacityHeight"
                              placeholder="Tinggi Cm"
                              control={control}
                              error={errors.capacityHeight}
                              width={((window.innerWidth < 400 ? window.innerWidth : 450) - 90) / 3}
                           />
                        </div>
                     </div>

                     <div className="carrier-form-images">
                        <ImageUpload single reference="carrier-license" image={licenseImg} card text="Foto STNK" setImage={setLicenseImg} confidential />
                        <ImageUpload single reference="carrier-certificate" image={certificateImg} card text="Foto KIR" setImage={setCertificateImg} confidential />
                     </div>

                     <Radio.Group name="status" options={Master.statusOptions} onChange={onChangeStatusOptions} value={status} buttonStyle="solid" />

                     {user.isAdmin && !user.provider && <Radio.Group name="verificationStatus" options={Master.verificationStatusOptions} onChange={onChangeVerifiedStatusOptions} value={verificationStatus} buttonStyle="solid" />}

                     <div className="action-button">
                        <Button type="primary" htmlType="submit">
                           Simpan
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onBack} className="cancel-button">
                           Batal
                        </Button>
                     </div>
                  </form>
               </FormLayout>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterNewCarrier;
