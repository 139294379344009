import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { CustomerService, CacheService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import { ImageUpload, InputText, FormLayout } from "../common";

import { Customer, Image, Place } from "../../model";

import * as PlaceRef from "../../reference/Place";
import * as Master from "../../reference/Master";

import "./UpdateCustomer.css";

let modalContext: ModalContextInterface;

const typeOptions = Master.entityTypes.map((entityType) => ({ label: entityType.desc, value: entityType.code }));

function UpdateCustomer(props: any) {
   const history = useHistory();
   const { control, errors, handleSubmit, setValue } = useForm();

   const customerGid = CacheService.getUser().customer?.gid;
   //console.log('UpdateCustomer user: ', JSON.stringify(CacheService.getUser(), null, 2));

   const [customer, setCustomer] = useState<Customer>(new Customer());
   const [profileImg, setProfileImg] = useState<Image>();
   const [type, setType] = useState(Master.ENTITY_TYPE_PERSONAL);
   const [location, setLocation] = useState<Place>(PlaceRef.defaultPlace);

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   const onChangeTypeOptions = (e: any) => {
      setType(e.target.value);
   };

   useEffect(() => {
      CustomerService.getCustomer(customerGid)
         .then((resp) => {
            setCustomer(resp);
            setType(resp.type);
            setProfileImg(resp.profileImg);
            setLocation(resp.location ? resp.location : PlaceRef.defaultPlace);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [customerGid, history]);

   useEffect(() => {
      if (customer) {
         setValue([{ name: customer.name }, { phone: customer.phone }, { email: customer.email }]);
      }
   }, [customer, setValue]);

   const onSubmit = (data: any) => {
      AlertHelper.alertLoading("Memperbarui data pelanggan ...", modalContext);

      const cust: Customer = new Customer();
      cust.gid = CacheService.getUser().customer?.gid;
      cust.name = data.name;
      cust.type = type;
      cust.phone = data.phone;
      cust.email = data.email;
      cust.location = location;

      if (profileImg) {
         cust.profileImg = profileImg;
         cust.profileImgUrl = profileImg.url;
      }

      CustomerService.updateCustomer(cust)
         .then((result) => {
            let user = CacheService.getUser();
            user.customer = cust;
            CacheService.setUser(user);
            AlertHelper.hideLoading(modalContext);
            AlertHelper.alertSuccess("Proses pembaruan data", modalContext);
         })
         .catch((error: any) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <FormLayout doodle="/images/doodle/checklist.png" title="Pembaruan Data" desc="Anda dapat memperbarui data pelanggan">
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <ImageUpload single reference="customer-profile" image={profileImg} fleet text="Foto Pelanggan" setImage={setProfileImg} />

                        <Radio.Group name="type" options={typeOptions} onChange={onChangeTypeOptions} value={type} buttonStyle="solid" />

                        <InputText name="name" placeholder="Nama Pelanggan" control={control} error={errors.name} />

                        <InputText name="phone" placeholder="No. Telepon" phone control={control} error={errors.phone} />

                        <InputText name="email" placeholder="Email" email control={control} error={errors.email} />

                        <div className="action-button">
                           <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                              Simpan
                           </Button>
                        </div>
                     </form>
                  </FormLayout>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default UpdateCustomer;
