import React, {useEffect, useState} from 'react';
import {Steps} from 'antd';

import Skeleton from 'react-loading-skeleton';

import {CustomButton} from '../common';

import {Step as StepType} from '../../model';

import * as CalendarHelper from '../../helper/CalendarHelper';
import * as ReferenceHelper from '../../helper/ReferenceHelper';

import * as Master from '../../reference/Master';

import './DeliveryProgress.css';

const {Step} = Steps;

const Description = (props: any) => {
   if (props.step.code) {
      return (
         <div className="description">
            <div className="description-title regular-font">
               {props.date}
               <br />
               {props.step.remarks}
            </div>
            <div className="description-image">
               {(props.step.code === Master.STEP_CODE_COMPLETE_LOADING || props.step.code === Master.STEP_CODE_COMPLETE_UNLOADING) && props.step.status === Master.STEP_STATUS_COMPLETED && (
                  <CustomButton icon={'image'} onClick={() => props.onShowStepImages(props.step)} />
               )}
            </div>
         </div>
      );
   } else {
      return <Skeleton delay={1} width={250} />;
   }
};

const DeliveryProgress = (props: any) => {
   const [current, setCurrent] = useState(-1);

   useEffect(() => {
      if (!props.steps) {
         return;
      }

      let current = -1;
      for (let i = 0; i < props.steps.length; i++) {
         const step: StepType = props.steps[i];
         if (!step.status || step.status === Master.STEP_STATUS_PENDING) {
            break;
         }
         current = i;
      }
      setCurrent(current);
      const nextStep: StepType = props.steps[current + 1];
      props.setNextStep(nextStep);
   }, [props]);

   return (
      <div className="delivery-progress">
         <Steps progressDot direction="vertical" current={current}>
            {props.steps &&
               props.steps.map((step: StepType, key: number) => {
                  let date = CalendarHelper.getDay(step.completedDate) + ', ' + CalendarHelper.getDateMonthTime(step.completedDate);
                  if (step.status === Master.STEP_STATUS_PENDING) {
                     date = 'Estimasi: ' + date;
                  }
                  return <Step key={key} title={step.code ? ReferenceHelper.getStepDesc(step.code) : <Skeleton delay={1} width={200} />} description={<Description step={step} date={date} onShowStepImages={props.onShowStepImages} />} />;
               })}
         </Steps>
      </div>
   );
};

export default DeliveryProgress;
