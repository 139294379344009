import * as React from "react";

import { SubNavigation } from "../common";

import "./PopupLayout.css";

export default function PopupLayout(props: any) {
   return (
      <div className={`popup-layout ${props.full ? "popup-layout-full" : ""} ${props.className}`}>
         <SubNavigation title={props.title} onClickPrev={props.onClickPrev} small={props.small} onClickClose={props.onClickClose} />
         <div className={`popup-layout-form ${props.full ? "popup-layout-form-full" : ""} dark-border-bottom-side`}>{props.children}</div>
      </div>
   );
}
