import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Checkbox, message, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { InputText, FormLayout } from "../common";

import * as AlertHelper from "../../helper/AlertHelper";

import { CustomerService, CacheService, ErrorService } from "../../service";

import * as Master from "../../reference/Master";

import { User, Customer } from "../../model";

import "./RegisterCustomer.css";

let isAgreeTnc = false;

const typeOptions = Master.entityTypes.map((entityType) => ({ label: entityType.desc, value: entityType.code }));

let modalContext: ModalContextInterface;

function RegisterCustomer(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const { control, errors, handleSubmit } = useForm();
   const [type, setType] = useState(Master.ENTITY_TYPE_PERSONAL);

   useEffect(() => {
      console.log("User: ", JSON.stringify(CacheService.getUser(), null, 2));
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   const onChangeTypeOptions = (e: any) => {
      setType(e.target.value);
   };

   const onAgreeTncChange = (e: any) => {
      isAgreeTnc = e.target.checked;
   };

   const onSubmit = (data: any) => {
      if (!isAgreeTnc) {
         message.info("Mohon setujui aturan dan kondisi penggunaan sistem");
         return;
      }
      //props.profile.name = data.name;
      //props.profile.email = data.email;

      AlertHelper.alertLoading("Memproses registrasi ...", modalContext);

      const user: User = new User();
      user.name = type === Master.ENTITY_TYPE_PERSONAL ? data.name : data.userName;
      user.email = type === Master.ENTITY_TYPE_PERSONAL ? data.email : data.userEmail;
      user.mobile = CacheService.getUser().role === Master.ROLE_ADMIN ? match.params.mobile : "";

      const customer: Customer = new Customer();
      customer.name = data.name;
      customer.type = type;
      customer.phone = type === Master.ENTITY_TYPE_PERSONAL ? (CacheService.getUser().role === Master.ROLE_ADMIN ? match.params.mobile : CacheService.getMobile()) : data.phone;
      customer.email = data.email;

      console.log("User: ", JSON.stringify(CacheService.getUser(), null, 2));
      //console.log('Register Customer: ', JSON.stringify(customer, null, 2));
      //console.log('Register User: ', JSON.stringify(user, null, 2));

      CustomerService.registerCustomer(user, customer)
         .then((user) => {
            AlertHelper.hideLoading(modalContext);
            modalContext?.setLogin(true);

            if (CacheService.getUser().role === Master.ROLE_CUSTOMER) {
               if (Master.PATH_BOOKING === CacheService.getPath()) {
                  history.replace("/confirm-shipment");
               } else {
                  history.replace("/shipment");
               }
               props.setUser(user);
            } else {
               history.replace("/admin/customer");
            }
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FormLayout doodle="/images/doodle/checklist.png" title="Anda belum terdaftar di Lintas!" desc="Mohon lengkapi data diri anda">
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <Radio.Group name="type" options={typeOptions} onChange={onChangeTypeOptions} value={type} buttonStyle="solid" />

                     <InputText name="name" placeholder={"Nama" + (type === Master.ENTITY_TYPE_CORPORATE ? " Perusahaan" : "")} control={control} error={errors.name} />

                     {type === Master.ENTITY_TYPE_CORPORATE && <InputText name="phone" placeholder="No. Telepon Perusahaan" phone control={control} error={errors.phone} />}

                     <InputText name="email" placeholder={"Email" + (type === Master.ENTITY_TYPE_CORPORATE ? " Perusahaan" : "")} email control={control} error={errors.email} />

                     {type === Master.ENTITY_TYPE_CORPORATE && <InputText name="userName" placeholder="Nama Anda" control={control} error={errors.userName} />}

                     {type === Master.ENTITY_TYPE_CORPORATE && <InputText name="userEmail" placeholder="Email Anda" email control={control} error={errors.userEmail} />}

                     <div className="register-agree-tnc">
                        <Checkbox onChange={onAgreeTncChange} className="register-agree-tnc">
                           Saya menyetujui aturan dan kondisi penggunaan sistem
                        </Checkbox>
                     </div>
                     <Button type="primary" htmlType="submit">
                        Daftar
                     </Button>
                  </form>
               </FormLayout>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterCustomer;
