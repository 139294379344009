import React from "react";
import { useHistory } from "react-router-dom";

import { FadeView } from "../common";

import * as Master from "../../reference/Master";

import { CacheService } from "../../service";

import * as GraphHelper from "../../helper/GraphHelper";

import { ModalContext } from "../../hooks/ModalContext";
//import {ModalContextInterface} from '../../hooks/ModalContext';

import ProfileView from "./ProfileView";

import "./SelectProfile.css";

//let modalContext: ModalContextInterface;

function SelectProvider(props: any) {
   const history = useHistory();
   const profiles = props.profiles;

   const onSelectProfile = (userProfile: any) => {
      CacheService.setToken({ token: userProfile.token, refreshToken: userProfile.refreshToken });
      // console.log('userProfile: ', JSON.stringify(userProfile, null, 2));

      let tokenValidity = new Date();
      tokenValidity.setSeconds(tokenValidity.getSeconds() + userProfile.tokenValidity);
      CacheService.setTokenValidity(tokenValidity);

      let user = GraphHelper.graphToUser(userProfile.user, CacheService.getRole());

      CacheService.setUser(user);
      props.setUser(user);

      if (CacheService.getUser()) {
         if (Master.PATH_CUSTOMER === CacheService.getPath()) {
            history.replace("/home/login");
         } else if (Master.PATH_PROVIDER === CacheService.getPath()) {
            history.replace("/home/login");
         } else if (Master.PATH_BOOKING === CacheService.getPath()) {
            history.replace("/confirm-shipment");
         } else if (Master.PATH_ADMIN === CacheService.getPath()) {
            history.replace("/admin/provider");
         } else {
            history.replace("/home/login");
         }
      } else {
         history.replace("/register/" + (CacheService.getPath() === Master.PATH_BOOKING ? Master.PATH_CUSTOMER : CacheService.getPath()));
      }
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            //modalContext = context;
            return (
               <div className="select-profile">
                  <div className="select-profile-content">
                     <FadeView show={true} duration="0">
                        <div className="select-profile-header regular-dash-border">
                           <div className="regular-bold-font">Anda terhubung ke beberapa penyedia jasa angkutan. Pilih jasa angkutan anda.</div>
                        </div>
                        {profiles.map((profile: any, key: number) => (
                           <ProfileView key={key} profile={profile} onSelect={onSelectProfile} />
                        ))}
                     </FadeView>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default SelectProvider;
