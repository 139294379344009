import React, { useEffect, useState } from "react";
import { Upload, Modal } from "antd";
import Resizer from "react-image-file-resizer";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { IoMdPerson } from "react-icons/io";
import { FaRegAddressCard, FaRegImage } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import * as Config from "../../Config";

import { Image } from "../../model";
import { toUploadFile, toImages } from "../../model/Image";

import "./ImageUpload.css";
import { RcFile } from "antd/lib/upload";

function getBase64(img: any, callback: any) {
   const reader = new FileReader();
   reader.addEventListener("load", () => callback(reader.result));
   reader.readAsDataURL(img);
}

const getBase64Images = (file: any) => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });
};

const resizeFile = (file: File) => {
   console.log("resizeFile: ", JSON.stringify(file, null, 2));
   return new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         1024,
         1024,
         "JPEG",
         60,
         0,
         (uri: any) => {
            resolve(uri);
         },
         "base64"
      );
   });
};

export function ImageUpload(props: any) {
   const [images, setImages] = useState<any>(props.images ? props.images.map((image: Image) => toUploadFile(image)) : []);
   const [loading, setLoading] = useState(false);
   const [previewVisible, setPreviewVisible] = useState(false);
   const [previewImage, setPreviewImage] = useState("");
   const [previewTitle, setPreviewTitle] = useState("");

   useEffect(() => {
      if (props.image && props.image.id) {
         setImages([props.image].map((image: Image) => toUploadFile(image, props.confidential)));
      }
   }, [props.image, props.confidential]);

   const beforeUpload = async (file: RcFile, fileList: RcFile[]) => {
      // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      // if (!isJpgOrPng) {
      //    message.error("Hanya gambar yang diperbolehkan diupload");
      //    return false;
      // }

      // const isLt2M = file.size / 1024 / 1024 < 3;
      // if (!isLt2M) {
      //    message.error("Ukuran foto lebih besar dari 3MB!");
      //    return false;
      // }

      const imageUri = await resizeFile(file);
      return await (await fetch("" + imageUri)).blob();
   };

   const handleChange = ({ file, fileList }: any) => {
      console.log("file:", JSON.stringify(file, null, 2));
      console.log("fileList:", JSON.stringify(fileList, null, 2));
      if (file.status === "removed") {
         console.log("filelist:", JSON.stringify(fileList, null, 2));
         if (props.image) {
            setImages([]);
            props.setImage({});
         } else if (props.images) {
            setImages(fileList);
            props.setImages(toImages(fileList));
         }
      } else if (file.status === "uploading") {
         setLoading(true);
         let image: Image = new Image();
         if (props.image) {
            props.setImage(image);
         }
         return;
      } else if (file.status === "done") {
         // Get this url from response in real world.
         getBase64(file.originFileObj, (imageUrl: any) => {
            let respImage = file.response;
            // console.log("respImage:", JSON.stringify(respImage, null, 2));
            let image: Image = new Image().init(respImage.id, respImage.name, respImage.status, respImage.size, respImage.type, respImage.url);

            setLoading(false);

            if (props.single) {
               props.setImage(image);
            } else if (props.multiple) {
               setImages(fileList);
               props.setImages(toImages(fileList));
            }
         });
      }
   };

   const handleCancel = () => {
      setPreviewVisible(false);
   };

   const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
         file.preview = await getBase64Images(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(file.name);
   };

   const UploadButton = (props: any) => (
      <div className="image-upload-icon">
         {loading ? <LoadingOutlined /> : props.person ? <IoMdPerson /> : props.card ? <FaRegAddressCard /> : props.fleet ? <FiTruck /> : props.photo ? <FaRegImage /> : <PlusOutlined />}
         <div className="image-upload-text">{props.text}</div>
      </div>
   );

   return (
      <div style={props.style}>
         <Upload
            name="file"
            listType="picture-card"
            className="image-upload"
            fileList={images}
            showUploadList={true}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            onPreview={handlePreview}
            disabled={props.disabled}
            action={`${Config.STORAGE_API}/upload?module=${props.reference}&title=${props.desc ? props.desc : props.text}`}
         >
            {loading ? <LoadingOutlined /> : images.length < 1 ? <UploadButton {...props} /> : props.images && images.length < (props.max ? props.max : 8) ? <UploadButton {...props} /> : null}
         </Upload>
         <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} centered>
            <img alt="upload" style={{ width: "100%" }} src={previewImage} />
         </Modal>
      </div>
   );
}

export default ImageUpload;
