import React from "react";
import { Controller } from "react-hook-form";
import { InputNumber as InputField } from "antd";

import Skeleton from "react-loading-skeleton";

import "./InputNumber.css";

export default function InputNumber(props: any) {
   return (
      <div className="input-number">
         {props.loading && !props.loadingRef ? (
            <div className="input-number-skeleton regular-border">
               <Skeleton height={20} />
            </div>
         ) : props.control ? (
            <Controller
               as={InputField}
               name={props.name}
               control={props.control}
               rules={{
                  required: {
                     value: !props.nullable,
                     message: ` Mohon isi required ${props.placeholder.toLowerCase()}`,
                  },
                  min: {
                     value: props.min ? 0 : !props.nullable ? 1 : 0,
                     message: ` Mohon isi min ${props.placeholder.toLowerCase()} lebih dari ${props.min}`,
                  },
               }}
               formatter={props.formatter}
               parser={props.parser}
               readOnly={props.readOnly}
               style={props.readOnly ? { backgroundColor: "#f7f7f7" } : {}}
            />
         ) : (
            <InputField name={props.name} onChange={props.onChange} readOnly={props.readOnly} style={props.readOnly ? { backgroundColor: "#f7f7f7" } : {}} />
         )}
         {props.error && (
            <div className="error">
               <i className="fa fa-times-circle fa-lg" />
               {props.error.message}
            </div>
         )}
         <div className="input-number-label-container">
            <div className="input-number-label regular-grey-font">{props.placeholder}</div>
         </div>
      </div>
   );
}
