import React, { useState } from 'react';

//import useWebSocket, {ReadyState} from 'react-use-websocket';
import Routing from './Routing.js';

import './App.css';
import './styles/Layout.css';
import './styles/Font.css';
import './styles/Color.css';

function App(props) {
   const [stdHeight] = useState(window.innerHeight);

   /* WebSocket Approach - Not yet supported in GAE Standard
   const [socketUrl] = useState(Config.POSITION_WS_API);

   const {
      sendMessage,
      readyState,
   } = useWebSocket(socketUrl);

   const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
   }[readyState];

   useEffect(() => {
      if (shipmentsInDelivery.length > 0) {
         //console.log('shipmentsInDelivery: ', JSON.stringify(shipmentsInDelivery, null, 2));
         let carrierIds = shipmentsInDelivery.map((shipment) => {
            return shipment.carrier.id
         });
         let msg = {
            t: Master.MESSAGE_TYPE_PUBLISH, // Publish
            i: carrierIds,
         }
         sendMessage(JSON.stringify(msg));
         navigator.geolocation.watchPosition((position) => {
            position = { lt: position.coords.latitude, lg: position.coords.longitude }
            if (shipmentsInDelivery.length > 0) {
               let msg = {
                  t: Master.MESSAGE_TYPE_UPDATE, // Update Position
                  p: position,
               }
               sendMessage(JSON.stringify(msg));
               console.log('sendMessage');
            }
         }, error, {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000
         });
      }
   }, [shipmentsInDelivery, sendMessage])
   */

   return (
      <div className="App" id="App">
         <Routing stdHeight={stdHeight} {...props} />
      </div>
   );
}

export default App;
