import React, { useEffect, useState } from "react";
import { Input } from "antd";

import "./SearchInput.css";

export default function SearchInput(props: any) {
   const [searchText, setSearchText] = useState(props.value);

   useEffect(() => {
      if (props.reset) {
         setSearchText("");
         props.setReset(false);
      }
   }, [props, props.reset]);

   return (
      <div className={`search-input-container regular-border ${props.className}`} style={{ ...props.style }}>
         <Input
            className="regular-bold-font"
            placeholder={props.placeholder}
            onFocus={props.onFocus}
            onChange={(e) => {
               props.onChangeText(e.target.value);
               setSearchText(e.target.value);
               if (e.type === "click") {
                  if (props.onClearText) {
                     props.onClearText();
                  }
               }
            }}
            defaultValue={props.value}
            value={searchText}
            allowClear
         />
         <div className="search-input-clear-btn">
            <img alt="search" src={process.env.PUBLIC_URL + "/images/icon/search.png"} className="search-input-image" />
         </div>
      </div>
   );
}
