import React from "react";

import Skeleton from "react-loading-skeleton";

import "./InputStatic.css";

export default function InputStatic(props: any) {
   return (
      <div className="input-static" onClick={props.onClick}>
         {props.loading && !props.loadingRef ? (
            <div className="input-static-skeleton regular-form-border">
               <Skeleton height={20} />
            </div>
         ) : (
            <div className="input-static-content regular-bold-font regular-form-border">{props.value}</div>
         )}
         <div className="input-static-label-container">
            <div className="input-static-label regular-grey-font">{props.placeholder}</div>
         </div>
      </div>
   );
}
