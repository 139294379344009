import React from "react";

import { AiFillStar } from "react-icons/ai";
import { yellow } from "../../constants/Color";
import Rating from "react-rating";

import * as CalendarHelper from "../../helper/CalendarHelper";

import "./ReviewView.css";

export function ReviewView(props: any){

  return(
    <div className="provider-info-review-item">
      <div>
        <img className="provider-info-review-img" alt="user" src={process.env.PUBLIC_URL + "/images/misc/user.png"} />
      </div>
      <div className="provider-info-review-profile">
        <div className="regular-bold-font">{props.review.reviewer}</div>
        <div className="regular-font">{CalendarHelper.getDateShortMonthYear(props.review.date)}</div>
      </div>
      <div className="provider-info-review-content">
        <Rating
                stop={5}
                placeholderRating={props.review.rating || 0}
                readonly
                emptySymbol={<AiFillStar style={{ color: "#ccc" }} />}
                placeholderSymbol={<AiFillStar style={{ color: yellow }} />}
                fullSymbol={<AiFillStar style={{ color: yellow }} />}
        />
        <div className="provider-info-review-remarks regular-font">{props.review.remarks}</div>
      </div>

    </div>
  )
}

export default ReviewView;