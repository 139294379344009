import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ProviderService, CacheService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import { InputText, FormLayout } from "../common";

import { Provider } from "../../model";

import "./UpdateBank.css";

let modalContext: ModalContextInterface;

function UpdateProvider(props: any) {
   const history = useHistory();
   const { control, errors, handleSubmit, setValue } = useForm();
   const user = CacheService.getUser();

   const providerGid = user.provider?.gid;
   console.log("UpdateProvider user:", JSON.stringify(CacheService.getUser(), null, 2));

   const [provider, setProvider] = useState<Provider>(new Provider());

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      ProviderService.getProvider(providerGid)
         .then((resp) => {
            setProvider(resp);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [providerGid, history]);

   useEffect(() => {
      if (provider) {
         setValue([{ name: provider.name }, { bankName: provider.bankName }, { bankAccNo: provider.bankAccNo }, { bankAccName: provider.bankAccName }]);
      }
   }, [provider, setValue]);

   const onSubmit = (data: any) => {
      AlertHelper.alertLoading("Memperbarui data bank ...", modalContext);

      const pvd: Provider = new Provider();
      pvd.bankCode = "";
      pvd.bankName = data.bankName;
      pvd.bankAccNo = data.bankAccNo;
      pvd.bankAccName = data.bankAccName;

      ProviderService.updateProviderBank(pvd)
         .then((result) => {
            AlertHelper.alertSuccess("Proses pembaruan bank", modalContext);
         })
         .catch((error: any) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <FormLayout doodle="/images/doodle/payment.png" title="Info Bank" desc="Rekening Pembayaran Jasa Angkutan">
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <InputText name="name" readOnly={true} placeholder="Nama Jasa Angkutan" control={control} error={errors.name} />

                        <InputText name="bankName" readOnly={!user.isAdmin} placeholder="Nama Bank" control={control} error={errors.bankName} />

                        <InputText name="bankAccNo" readOnly={!user.isAdmin} placeholder="No. Rekening" number control={control} error={errors.bankAccNo} />

                        <InputText name="bankAccName" readOnly={!user.isAdmin} placeholder="Nama Pemilik Rekening" control={control} error={errors.bankAccName} />

                        {user.isAdmin && (
                           <div className="action-button">
                              <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                                 Simpan
                              </Button>
                           </div>
                        )}
                     </form>
                  </FormLayout>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default UpdateProvider;
