import numbro from "numbro";

// define a language
numbro.registerLanguage({
   languageTag: "id-ID",
   delimiters: {
      thousands: ".",
      decimal: ",",
   },
   abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t",
   },
   ordinal: (number) => {
      return number === 1 ? "er" : "ème";
   },
   currency: {
      symbol: "Rp",
      position: "prefix",
      code: "IDR",
   },
   currencyFormat: {
      thousandSeparated: true,
      totalLength: 4,
      spaceSeparated: true,
      average: true,
   },
   formats: {
      fourDigits: {
         totalLength: 4,
         spaceSeparated: true,
         average: true,
      },
      fullWithTwoDecimals: {
         output: "currency",
         mantissa: 2,
         spaceSeparated: true,
         thousandSeparated: true,
      },
      fullWithTwoDecimalsNoCurrency: {
         mantissa: 2,
         thousandSeparated: true,
      },
      fullWithNoDecimals: {
         output: "currency",
         spaceSeparated: true,
         thousandSeparated: true,
         mantissa: 0,
      },
   },
});

numbro.setLanguage("id-ID");

/*export function formatRoundCurrency(value: number) {
   return 'Rp ' + numbro(Math.round(value / 1000) * 1000).format('0,0');
}*/

export function formatCurrency(value: number) {
   if (value < 0) {
      return "- Rp " + numbro(Math.abs(value)).format("0,0");
   } else if (value >= 0) {
      return "Rp " + numbro(value).format("0,0");
   } else {
      return "";
   }
}

export function formatCurrencyToNumber(value: string) {
   value = value.replace("Rp", "").replace(".", "").replace(" ", "");
   if (value.length === 0) {
      value = "0";
   }
   return value;
}

export function formatFloatNumber(value: number) {
   value = Math.round(value * 10) / 10;
   return numbro(value).format("0.0");
}

export function formatIntNumber(value: number) {
   return numbro(value).format("0,0");
}

export function toString(val?: number) {
   return val ? val.toString() : "";
}

export function formatTitle(letter: string | undefined) {
   return letter ? letter.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) : "";
}

export function formatCamel(str: string | undefined) {
   return str ? str.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) : "";
}
