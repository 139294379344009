import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Input as InputField, Button } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { AuthService, ErrorService, CacheService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";
import * as GraphHelper from "../../helper/GraphHelper";

import * as Master from "../../reference/Master";

import { FadeView, SimpleButton } from "../common";

import TagManager from "react-gtm-module";

import "./Otp.css";

let timer: NodeJS.Timeout;

let modalContext: ModalContextInterface;

function Otp(props: any) {
   const history = useHistory();
   const { control, errors, handleSubmit, reset } = useForm();
   const mobile = CacheService.getMobile();
   const [init, setInit] = useState(false);
   const [countdown, setCountdown] = useState("");
   const [counter, setCounter] = useState(CacheService.getAuth()?.expiryPeriod);
   //const [counter, setCounter] = useState(0);
   const [otp, setOtp] = useState("");

   const updateCountdown = () => {
      const mm = ("" + Math.floor(counter / 60)).padStart(2, "0");
      const ss = ((counter % 60) + "").padStart(2, "0");
      setCountdown(mm + ":" + ss);
   };

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
      if (!props.showBottomNavigation) {
         let elm = document.getElementById("otp-container");
         if (elm) {
            elm.scrollTo({
               top: elm.scrollHeight,
               behavior: "auto",
            });
         }
      }
   }, [init, props]);

   useEffect(() => {
      timer = setTimeout(() => {
         if (counter > 0) {
            setCounter(counter - 1);
         }
         updateCountdown();
      }, 1000);
   });

   useEffect(() => {
      return () => {
         if (timer) {
            clearTimeout(timer);
         }
      };
   });

   const gtm = () => {
      // gtm conversion
      const tagManagerArgs = {
         gtmId: "GTM-MW67KPL",
      };
      TagManager.initialize(tagManagerArgs);
   };

   const onSubmit = (data: any) => {
      reset();
      AlertHelper.alertLoading("Verifikasi kode OTP ...", modalContext);

      AuthService.authenticateOtp(CacheService.getAuth().key, data.otp, CacheService.getRole())
         .then((userProfiles) => {
            AlertHelper.hideLoading(modalContext);
            AlertHelper.enableScroll(true, "Otp");

            modalContext?.setLogin(true);
            CacheService.setProfiles(userProfiles);

            if (userProfiles.length > 1) {
               props.setProfiles(userProfiles);
               history.replace("/select-profile");
               return;
            }

            let userProfile = userProfiles[0];

            CacheService.setToken({ token: userProfile.token, refreshToken: userProfile.refreshToken });
            console.log("userProfile: ", JSON.stringify(userProfile, null, 2));

            let tokenValidity = new Date();
            tokenValidity.setSeconds(tokenValidity.getSeconds() + userProfile.tokenValidity);
            CacheService.setTokenValidity(tokenValidity);

            let user = GraphHelper.graphToUser(userProfile.user, CacheService.getRole());

            CacheService.setUser(user);
            props.setUser(user);

            if (CacheService.getUser().gid) {
               if (Master.PATH_CUSTOMER === CacheService.getPath()) {
                  history.replace("/home/login");
                  // gtm conversion
                  // gtm();
               } else if (Master.PATH_PROVIDER === CacheService.getPath()) {
                  history.replace("/home/login");
               } else if (Master.PATH_BOOKING === CacheService.getPath()) {
                  history.replace("/confirm-shipment");

                  // gtm conversion
                  // gtm();
               } else if (Master.PATH_ADMIN === CacheService.getPath()) {
                  history.replace("/admin/provider");
               } else if (Master.PATH_REFERRAL === CacheService.getPath()) {
                  let transportType = CacheService.getTransportType();
                  let transportTypeCode = transportType ? transportType.code.toLowerCase() : "";
                  history.replace("/new-shipment/" + transportTypeCode);
               } else {
                  history.replace("/home/login");
               }
            } else {
               history.replace("/register/" + (CacheService.getPath() === Master.PATH_BOOKING ? Master.PATH_CUSTOMER : CacheService.getPath()));
               // gtm();
            }
         })
         .catch((error) => {
            if (ErrorService.isOtpExpired(error)) {
               setCounter(0);
            }

            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onChangeMobileNo = () => {
      history.replace("/login/" + CacheService.getPath());
   };

   const onSendOtp = (modalContext: ModalContextInterface) => {
      reset();
      AlertHelper.alertLoading(`Mengirimkan SMS OTP ke nomer ${mobile}`, modalContext);

      AuthService.sendOtp(mobile, CacheService.getRole())
         .then((result) => {
            AlertHelper.hideLoading(modalContext);
            setCounter(CacheService.getAuth().expiryPeriod);
            setOtp("");
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div id="otp-container" className={"otp-container " + (!props.showBottomNavigation ? "otp-container-no-nav" : "")}>
                  <div className="otp">
                     <div className="otp-left">
                        <FadeView show={true} duration="0">
                           <img src="/images/doodle/message.png" alt="login" className="doodle" />
                        </FadeView>
                     </div>
                     <div className="otp-right">
                        <div className="title">6 digit kode OTP telah dikirim</div>
                        <div className="desc" style={{ display: "flex", flexDirection: "column" }}>
                           Masukkan kode OTP yang telah dikirim ke no {props.profile.mobile}
                           <SimpleButton mobile text="Ganti No. Telepon" onClick={onChangeMobileNo} style={{ marginTop: 20 }} />
                        </div>
                        <div className="otp-form">
                           <form onSubmit={handleSubmit(onSubmit)}>
                              <Controller
                                 as={InputField}
                                 name="otp"
                                 control={control}
                                 defaultValue={otp}
                                 value={otp}
                                 rules={{
                                    required: {
                                       value: true,
                                       message: " Mohon input kode OTP",
                                    },
                                    minLength: {
                                       value: 6,
                                       message: " kode OTP terlalu pendek",
                                    },
                                    maxLength: {
                                       value: 6,
                                       message: " kode OTP terlalu panjang",
                                    },
                                    pattern: {
                                       value: /^[0-9]*$/,
                                       message: " kode OTP tidak valid, Hanya angka yang diperbolehkan",
                                    },
                                 }}
                                 placeholder="Kode OTP"
                              />
                              {errors.otp && (
                                 <div className="error">
                                    <i className="fa fa-times-circle fa-lg" />
                                    {errors.otp.message}
                                 </div>
                              )}
                              <div className="desc">
                                 <span>Belum menerima kode OTP? </span>
                                 {counter > 0 ? (
                                    <span style={{ marginLeft: 12 }}>Tunggu {countdown}</span>
                                 ) : (
                                    <SimpleButton
                                       sms
                                       text="Kirim OTP"
                                       onClick={() => {
                                          onSendOtp(modalContext);
                                       }}
                                       style={{ marginTop: 2, marginLeft: 10 }}
                                       textStyle={{ fontSize: 15 }}
                                    />
                                 )}
                              </div>
                              <Button type="primary" htmlType="submit" className="submit">
                                 Verifikasi
                              </Button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default Otp;
