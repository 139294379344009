import { TransportType } from "../model";

export const setTransportTypes = (types: TransportType[]) => {
   transportTypes = types;
   for (let i = 0; i < types.length; i++) {
      let transportType = transportTypes[i];
      transportTypeSet.set(transportType.code, transportType);
   }
   // console.log("setTransportTypes : ", JSON.stringify(transportTypes, null, 2));
};

export let transportTypes: TransportType[] = [];
export let isInitTransportTypes: boolean = false;

export const setInitTransportTypes = (isInit: boolean) => {
   isInitTransportTypes = isInit;
};

export const transportTypeSet = new Map();

export const getEmptyTransportTypes = (count: number) => {
   const transportTypes = Array<TransportType>();
   for (let i = 0; i < count; i++) {
      transportTypes.push(new TransportType());
   }
   return transportTypes;
};
