import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import {} from "google-maps";
import GoogleMapReact from "google-map-react";
import TimeKeeper from "react-timekeeper";
import { SelectPlace } from "./select-place";

import { FiTruck } from "react-icons/fi";
import { FaRegCalendarAlt, FaRegMoneyBillAlt, FaRegClock } from "react-icons/fa";

import ReactCalendar from "react-calendar";

import { ShipmentService, VoucherService, ErrorService } from "../../service";

import { ShipmentSteps, FeeView } from "../common";

import { SquareButton, MapMarker, ShipmentDescUpdate, SimpleButton, ModalView, PlaceMapSelection } from "../common";

import CacheService from "../../service/CacheService";

import { Shipment, Place, Voucher, Payment } from "../../model";
import { isCustomer, isProvider } from "../../model/User";

import * as PlaceRef from "../../reference/Place";
import * as UserRef from "../../reference/User";
import * as Master from "../../reference/Master";

import * as Config from "../../Config";

import * as FormatHelper from "../../helper/FormatHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";
import * as AlertHelper from "../../helper/AlertHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as ConfirmHelper from "../../helper/ConfirmHelper";
import * as DirectionHelper from "../../helper/DirectionHelper";
import * as SearchHelper from "../../helper/SearchHelper";
import * as PricingHelper from "../../helper/PricingHelper";

import "./ConfirmShipment.css";

let googleMap: any;
let googleMaps: any;
let routePolyline: any;

let modalContext: ModalContextInterface;

function NewShipment(props: any) {
   const history = useHistory();

   const [images, setImages] = useState(props.shipment.images);
   const [isValidated, setValidated] = useState(false);

   const [isOrigin, setIsOrigin] = useState(true);
   const [place, setPlace] = useState<Place>(PlaceRef.defaultPlace);
   const [showSelectPlace, setShowSelectPlace] = useState(false);
   const [showSelectPlaceMap, setShowSelectPlaceMap] = useState(false);
   const [mapCenter, setMapCenter] = useState({ lat: -6.2087634, lng: 106.845599 });

   const [time, setTime] = useState(CalendarHelper.getTime(props.shipment.deliveryDate));
   const [showFee, setShowFee] = useState(false);
   const [showCalendar, setShowCalendar] = useState(false);
   const [showTime, setShowTime] = useState(false);
   const [voucherCode, setVoucherCode] = useState(props.voucher ? props.voucher.code : "");
   const [voucher, setVoucher] = useState<Voucher>();

   const user = UserRef.newUser;
   const [shipment, setShipment] = useState<Shipment>(props.shipment);

   const refPicOrigin = useRef<HTMLDivElement>(null);
   const refPicDestination = useRef<HTMLDivElement>(null);
   const refCargo = useRef<HTMLDivElement>(null);
   const refRemarks = useRef<HTMLDivElement>(null);
   const refVoucher = useRef<HTMLDivElement>(null);

   useEffect(() => {
      // console.log("Shipment: ", JSON.stringify(props.shipment, null, 2));
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      props.shipment.images = images;

      props.shipment.requestDate = new Date(props.shipment.requestDate);
      props.shipment.deliveryDate = new Date(props.shipment.deliveryDate);
      props.shipment.deliveryStartDate = new Date(props.shipment.deliveryStartDate);
      props.shipment.deliveryEndDate = new Date(props.shipment.deliveryEndDate);

      setShipment(props.shipment);
      CacheService.setNewShipment(props.shipment);
      //console.log('Confirm Shipment: ', JSON.stringify(props.shipment, null, 2));
   }, [images, props.shipment.images, props.shipment, shipment.deliveryDate]);

   useEffect(() => {
      setVoucherCode(props.voucher ? props.voucher.code : "");
      //setVoucher(props.voucher);
   }, [props.voucher]);

   const onPressConfirmOrder = () => {
      //props.shipment.images = images;
      shipment.images = images;

      console.log("Confirm Shipment: ", JSON.stringify(props.shipment, null, 2));
      CacheService.setNewShipment(props.shipment);

      if (validate()) {
         CacheService.savePrev();
         ConfirmHelper.confirm("Pesan Angkutan?", () => confirmOrder, modalContext);
      }
   };

   const confirmOrder = () => {
      //CacheService.setNewShipment(props.shipment);

      if (isCustomer(user)) {
         AlertHelper.alertLoading("Memproses Angkutan Anda", modalContext);
         ShipmentService.registerShipment(shipment)
            .then((shipmentId_ConfirmPeriodDesc) => {
               let results = shipmentId_ConfirmPeriodDesc.split("|");
               let shipmentId = results[0];
               let confirmPeriodDesc = results[1];

               setTimeout(() => {
                  AlertHelper.alertSuccess(`Mohon tunggu ${confirmPeriodDesc} untuk mitra Lintas memberikan konfirmasi`, modalContext);
                  history.push(`/new-shipment-detail/${shipmentId}`);
               }, 1000);
            })
            .catch((error: Error) => {
               CacheService.setPath(Master.PATH_BOOKING);
               ErrorService.handleError(error, history, modalContext);
            });
      } else if (isProvider(user)) {
         props.shipment.provider = user.provider;
         //history.push('/confirm-shipment');
      }
   };

   const validate = () => {
      setValidated(true);

      let shipment: Shipment = props.shipment;
      let isValidated = true;
      let isScrollToRemarks = false;

      console.log("validate shipment: ", shipment);
      let message = "";
      let anchor: any;

      let gapMins = (shipment.deliveryDate.getTime() - new Date().getTime()) / (60 * 1000);

      if (gapMins < 30) {
         message = "Waktu booking paling cepat dalam 30 menit mendatang";
         isValidated = false;
      } else if (!shipment.origin?.picName) {
         message = "Mohon isi nama pengirim";
         isValidated = false;
         anchor = refPicOrigin;
      } else if (!shipment.origin?.picMobile) {
         message = "Mohon isi telepon pengirim";
         isValidated = false;
         anchor = refPicOrigin;
      } else if (shipment.destinations) {
         for (let i = 0; i < shipment.destinations?.length; i++) {
            let destination = shipment.destinations[i];
            if (!destination.picName) {
               message = "Mohon isi nama Pic di " + LocationHelper.getSubRegionCity(destination.address);
               isValidated = false;
               anchor = refPicDestination;
               break;
            } else if (!destination.picMobile) {
               message = "Mohon isi telepon Pic di " + LocationHelper.getSubRegionCity(destination.address);
               isValidated = false;
               anchor = refPicDestination;
               break;
            }
         }

         if (!shipment.description) {
            message = "Mohon berikan informasi muatan anda";
            isValidated = false;
            anchor = refCargo;
         } else if (shipment.weightEst === 0) {
            message = "Mohon isi estimasi berat muatan";
            isValidated = false;
            anchor = refCargo;
            // } else if (shipment.images.length === 0) {
            //    message = "Mohon lampirkan foto muatan angkutan sehingga kami dapat memproses muatan anda dengan baik";
            //    isValidated = false;
            //    anchor = refRemarks;
         } else if (shipment.remarks === "") {
            message = "Mohon lampirkan catatan";
            isValidated = false;
            anchor = refRemarks;
            isScrollToRemarks = true;
         } else if (voucherCode !== "" && voucher === undefined) {
            message = "Kode voucher tidak valid";
            isValidated = false;
            anchor = refRemarks;
            isScrollToRemarks = true;
         }
      }

      if (!isValidated) {
         AlertHelper.alertWarningWithRef(message, modalContext, "warning", anchor);
         setTimeout(() => {
            if (window.innerWidth < 1000) {
               if (isScrollToRemarks) {
                  window.scrollTo(0, 860);
               } else {
                  window.scrollTo(0, 635);
               }
            } else {
               window.scrollTo(0, 500);
            }
         }, 3500);
      }

      return isValidated;
   };

   const apiIsLoaded = (map: any, maps: any) => {
      googleMap = map;
      googleMaps = maps;
      refreshDirection(props.shipment.origin, props.shipment.destination);
   };

   const refreshDirection = useCallback(
      (origin: Place, destination: Place) => {
         //console.log('Shipment: ', JSON.stringify(props.shipment, null, 2));
         if (!googleMaps) {
            return;
         }

         //const directionsDisplay = new googleMaps.DirectionsRenderer();
         DirectionHelper.initGoogleMaps(googleMaps);

         origin &&
            destination &&
            DirectionHelper.getDirectionMaps(origin, props.shipment.destinations, (response: any, status: any) => {
               if (status === "OK") {
                  //directionsDisplay.setDirections(response);
                  const path = response.path;
                  shipment.path = JSON.stringify(path);
                  if (routePolyline) {
                     routePolyline.setMap(null);
                  }
                  routePolyline = new google.maps.Polyline({
                     path: path,
                     geodesic: true,
                     strokeColor: "hotpink",
                     strokeOpacity: 1.0,
                     strokeWeight: 3,
                  });
                  routePolyline.setMap(googleMap);

                  const distance = response.distance;
                  const duration = Math.round(response.duration * Master.GOOGLE_MAPS_DURATION_RATIO);

                  props.shipment.distance = distance;
                  props.shipment.duration = duration;

                  let bounds = new google.maps.LatLngBounds();

                  let place: Place = props.shipment.origin;
                  bounds.extend(new google.maps.LatLng(place.lat, place.lng));

                  for (let i = 0; i < props.shipment.destinations.length; i++) {
                     place = props.shipment.destinations[i];
                     bounds.extend(new google.maps.LatLng(place.lat, place.lng));
                  }

                  googleMap.fitBounds(bounds);
               } else {
                  window.alert("Gagal mendapatkan arah dari Google Maps karena " + status);
               }
            });
      },
      [props.shipment, shipment]
   );

   useEffect(() => {
      refreshDirection(props.shipment.origin, props.shipment.destination);
   });

   const takePhoto = () => {
      //navigation.navigate('TakePhotoScreen', {return: 'ConfirmShipmentScreen'});
   };

   useEffect(() => {
      if (props.shipment.origin && props.shipment.destination) {
         setMapCenter({
            lat: props.shipment.origin.lat + (props.shipment.destination.lat - props.shipment.origin.lat) / 2,
            lng: props.shipment.origin.lng + (props.shipment.destination.lng - props.shipment.origin.lng) / 2,
         });
      }
   }, [props.shipment.origin, props.shipment.destination]);

   useEffect(() => {
      SearchHelper.searchInput(
         voucherCode,
         () => {
            let totalPaymentAmt = 0;
            const refreshPayment = (voucher: Voucher | undefined) => {
               const payment: Payment = props.shipment.payment;
               let discountAmount = 0;
               if (voucher) {
                  //discountAmount = voucher.type === Master.VOUCHER_TYPE_AMOUNT ? voucher.value : (voucher.value / 100) * payment.totalCharge;
                  //discountAmount = voucher.type === Master.VOUCHER_TYPE_PERCENT && discountAmount > voucher.valueCap ? voucher.valueCap : discountAmount;

                  discountAmount = PricingHelper.getDiscount(voucher, payment.totalCharge);
                  props.shipment.voucherCode = voucher.code;
               } else {
                  props.shipment.voucherCode = "";
               }

               // console.log("payment", JSON.stringify(payment, null, 2));
               // console.log("voucher", JSON.stringify(vcr, null, 2));
               // console.log("discountAmount", discountAmount);
               // console.log("totalCharge", payment.totalCharge);
               // console.log("totalPaymentAmt", totalPaymentAmt);

               totalPaymentAmt = payment.totalCharge - discountAmount;
               props.shipment.payment.discountAmount = discountAmount;
               props.shipment.payment.totalPaymentAmount = totalPaymentAmt;
            };
            const processVoucher = () => {
               //AlertHelper.alertLoading("Memproses Voucher ...", modalContext);

               if (props.voucher && props.voucher.code === voucherCode) {
                  refreshPayment(props.voucher);
                  setVoucher(props.voucher);
                  return;
               }

               VoucherService.getActiveVoucher(voucherCode)
                  .then((voucher: Voucher) => {
                     setTimeout(() => {
                        refreshPayment(voucher);
                        setVoucher(voucher);
                        if (voucher) {
                           AlertHelper.alertSuccess(`Total biaya menjadi ${FormatHelper.formatCurrency(totalPaymentAmt)} (${voucherCode})`, modalContext);
                        } else {
                           AlertHelper.alertWarning(`Kode voucher ${voucherCode} tidak valid`, modalContext);
                        }
                     }, 500);
                  })
                  .catch((error: Error) => {
                     CacheService.setPath(Master.PATH_BOOKING);
                     ErrorService.handleError(error, history, modalContext);
                  });
            };

            if (!voucherCode) {
               refreshPayment(undefined);
               return;
            }

            console.log("Search VoucherCode: ", voucherCode);
            processVoucher();
         },
         1000
      );
   }, [voucherCode, history, props.shipment, props.voucher]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div ref={refPicOrigin} className="confirm-shipment">
                  <div className="confirm-shipment-content">
                     <SelectPlace show={showSelectPlace} setShow={setShowSelectPlace} setShowSelectPlaceMap={setShowSelectPlaceMap} isOrigin={isOrigin} setIsOrigin={setIsOrigin} setPlace={setPlace} shipment={props.shipment} />
                     <PlaceMapSelection show={showSelectPlaceMap} setShow={setShowSelectPlaceMap} setShowSelectPlace={setShowSelectPlace} isOrigin={isOrigin} place={place} shipment={props.shipment} />
                     <ShipmentSteps status={props.shipment.status} />
                     <div className="confirm-shipment-map regular-border">
                        <div className="content">
                           <div className="section">
                              <div className="section-icon">
                                 <FiTruck style={{ fontSize: 17 }} />
                              </div>
                              <div className="section-content">
                                 <div className="section-title regular-bold-grey-font">Tipe Angkutan</div>
                                 <div className="section-info regular-font">{shipment.transportTypeDesc}</div>
                              </div>
                           </div>
                           <div className="section">
                              <div className="section-icon">
                                 <FaRegMoneyBillAlt style={{ fontSize: 17 }} />
                              </div>
                              <div className="section-content">
                                 <div className="section-title regular-bold-grey-font">Biaya</div>
                                 <div className="section-layout">
                                    <div className="section-info regular-font" style={{ flex: 1 }}>
                                       {FormatHelper.formatCurrency(props.shipment.payment.totalPaymentAmount)}
                                    </div>
                                    <SimpleButton
                                       info
                                       text={window.innerWidth > 450 ? "Info Biaya" : "Info"}
                                       onClick={() => {
                                          setShowFee(true);
                                       }}
                                       style={{ marginRight: 5 }}
                                    />
                                    <FeeView show={showFee} setShow={setShowFee} voucherCode={voucherCode} shipment={props.shipment} />
                                 </div>
                              </div>
                           </div>
                           <div className="section">
                              <div className="section-icon">
                                 <FaRegCalendarAlt style={{ fontSize: 17 }} />
                              </div>
                              <div className="section-content">
                                 <div className="section-title regular-bold-grey-font">Tgl. Pengiriman</div>
                                 <div className="section-layout">
                                    <div className="section-info regular-font" style={{ flex: 1, display: "flex", flexWrap: "wrap" }}>
                                       <span style={{ marginRight: 5 }}>{CalendarHelper.getDay(shipment.deliveryDate) + ","}</span>
                                       <span>{CalendarHelper.getDateMonth(shipment.deliveryDate)}</span>
                                    </div>
                                    <SimpleButton
                                       calendar
                                       text="Ganti Hari"
                                       onClick={() => {
                                          setShowCalendar(true);
                                       }}
                                       style={{ marginRight: 5 }}
                                    />
                                    {showCalendar && (
                                       <div className="custom-calendar-picker">
                                          <ReactCalendar
                                             formatMonthYear={(locale: any, date: Date) => CalendarHelper.dateFormat(date, "mmm yyyy")}
                                             formatShortWeekday={(locale: any, date: Date) => CalendarHelper.dateFormat(date, "ddd")}
                                             value={props.shipment.deliveryDate}
                                             onChange={(date: Date | Date[]) => {
                                                let deliveryDate = new Date(props.shipment.deliveryDate);
                                                //props.shipment.deliveryDate = new Date(date.toString());
                                                props.shipment.deliveryDate = CalendarHelper.dateSetHourMinute(new Date(date.toString()), deliveryDate.getHours(), deliveryDate.getMinutes());
                                                props.shipment.deliveryStartDate = props.shipment.deliveryDate;
                                                props.shipment.deliveryEndDate = new Date(props.shipment.deliveryStartDate.getTime() + props.shipment.duration * Master.MILISECONDS);
                                                setShowCalendar(false);
                                             }}
                                          />
                                       </div>
                                    )}
                                 </div>
                              </div>
                           </div>
                           <div className="section">
                              <div className="section-icon">
                                 <FaRegClock style={{ fontSize: 17 }} />
                              </div>
                              <div className="section-content">
                                 <div className="section-title regular-bold-grey-font">Jam. Pengiriman</div>
                                 <div className="section-layout">
                                    <div className="section-info regular-font" style={{ flex: 1 }}>
                                       {CalendarHelper.getDayTime(props.shipment.deliveryStartDate)}
                                    </div>
                                    <SimpleButton clock text="Ganti Jam" onClick={() => setShowTime(true)} style={{ marginRight: 5 }} />
                                    <ModalView show={showTime}>
                                       <TimeKeeper
                                          time={time}
                                          onChange={(newTime) => {
                                             props.shipment.deliveryDate = CalendarHelper.dateSetHourMinute(props.shipment.deliveryDate, newTime.hour, newTime.minute);
                                             props.shipment.deliveryStartDate = props.shipment.deliveryDate;
                                             props.shipment.deliveryEndDate = new Date(props.shipment.deliveryStartDate.getTime() + props.shipment.duration * Master.MILISECONDS);
                                             setTime(newTime.formatted12);
                                          }}
                                          onDoneClick={() => setShowTime(false)}
                                          switchToMinuteOnHourSelect
                                       />
                                    </ModalView>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <GoogleMapReact bootstrapURLKeys={{ key: Config.GOOGLE_MAP_KEY }} center={mapCenter} defaultZoom={11} options={{ disableDefaultUI: true }} onGoogleApiLoaded={({ map, maps }: any) => apiIsLoaded(map, maps)}>
                           {props.shipment.origin && <MapMarker size="small" lat={props.shipment.origin.lat} lng={props.shipment.origin.lng} />}
                           {props.shipment.destinations && props.shipment.destinations.map((dest: Place, key: number) => <MapMarker key={key} color="blue" size="small" lat={dest.lat} lng={dest.lng} />)}
                        </GoogleMapReact>
                     </div>
                     <div className="bottom-panel">
                        <div className="bottom-content">
                           <ShipmentDescUpdate
                              onPressTakePhoto={takePhoto}
                              imageSize={4}
                              images={images}
                              setImages={setImages}
                              shipment={props.shipment}
                              isValidated={isValidated}
                              refPicOrigin={refPicOrigin}
                              refPicDestination={refPicDestination}
                              refCargo={refCargo}
                              refRemarks={refRemarks}
                              refVoucher={refVoucher}
                              voucherCode={voucherCode}
                              setVoucherCode={setVoucherCode}
                           />
                        </div>
                        <div className="bottom-content">
                           <SquareButton
                              text="Pesan Angkutan"
                              next
                              onClick={() => {
                                 onPressConfirmOrder();
                              }}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default NewShipment;
