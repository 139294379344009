import React, { useEffect, useState } from "react";
import { ModalContext } from "../../hooks/ModalContext";
//import {ModalContextInterface} from '../../hooks/ModalContext';

import { ImagesPanel, ImagesGallery, PopupLayout } from "../common";

import * as FormatHelper from "../../helper/FormatHelper";
import * as ReferenceHelper from "../../helper/ReferenceHelper";
import * as AlertHelper from "../../helper/AlertHelper";

import "./StepImages.css";

//let modalContext: ModalContextInterface;

export default function StepImages(props: any) {
   const [showImagesGallery, setShowImagesGallery] = useState(false);
   const [images, setImages] = useState();
   const [imageIndex, setImageIndex] = useState(0);

   const onClickPrev = () => {
      props.setShow(false);
   };

   const onClickClose = () => {
      props.setShow(false);
   };

   useEffect(() => {
      setImages(props.images);
   }, [props.images]);

   useEffect(() => {
      AlertHelper.enableScroll(!props.show, "StepImages");
   }, [props.show]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            // modalContext = context;
            return (
               props.show && (
                  <div className="step-images">
                     <PopupLayout small title={props.step && "Foto " + FormatHelper.formatTitle(ReferenceHelper.getStepAction(props.step.code))} onClickPrev={onClickPrev} onClickClose={onClickClose}>
                        <div className="step-images-container">
                           <ImagesPanel
                              images={images}
                              width={window.innerWidth > 500 ? 500 : window.innerWidth - 40}
                              size={window.innerWidth < 1000 ? 2 : 4}
                              onPress={(index: number) => {
                                 setImageIndex(index);
                                 setShowImagesGallery(true);
                              }}
                           />
                        </div>
                        {images && <ImagesGallery images={images} index={imageIndex} show={showImagesGallery} setShow={setShowImagesGallery} />}
                     </PopupLayout>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
