import { Provider } from "../model";
import { defaultPlace } from "./Place";
import { newImage } from "./Image";

export const newProvider: Provider = {
   id: 0,
   name: "",
   type: "",
   phone: "",
   email: "",
   location: defaultPlace,
   level: "",
   rating: 0,
   rate: 0,
   profileImg: newImage,
   fleet: undefined,
   reviewCount: 0,
   deliveryCount: 0,
};

export const getEmptyProviders = (count: number) => {
   const providers = Array<Provider>();
   for (let i = 0; i < count; i++) {
      providers.push(newProvider);
   }
   return providers;
};
