import React, { useEffect } from "react";
import { Collapse } from "antd";

import "./TermsConditions.css";

import { tnc } from "../../reference/TermsConditions";
import { ModalContext } from "../../hooks/ModalContext";

import FadeView from "./FadeView";

const { Panel } = Collapse;

function TermsConditions() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <FadeView show={true} duration="0">
                  <div className="termsconditions">
                     <div className="termsconditions-wrapper">
                        <div className="termsconditions-header">
                           <div className="bold-bold-font">Syarat dan Ketentuan</div>
                        </div>
                        <div className="termsconditions-desc">
                           {tnc.descriptions.map((description) => {
                              return <p className="big-font">{description}</p>;
                           })}
                           <ol type="a">
                              {tnc.prefaces.map((preface) => {
                                 return <li className="big-font">{preface}</li>;
                              })}
                           </ol>
                        </div>
                        <Collapse>
                           {tnc.articles.map((article, key) => (
                              <Panel header={article.title} key={key}>
                                 <div className="big-font">
                                    <ol type="1">
                                       {article.clauses.map((clause) => {
                                          return (
                                             <li>
                                                {/* { Menampilkan Pasal } */}
                                                {clause.content}
                                                <ol type="a">
                                                   {clause.subitems.map((subitem) => {
                                                      return (
                                                         <li>
                                                            {/* { Menampilkan Tiap Ayat Per Pasal } */}
                                                            {subitem.text}
                                                            <ul>
                                                               {subitem.points.map((point) => {
                                                                  return (
                                                                     <li>
                                                                        {/* { Menampilkan Tiap Poin Per Ayat } */}
                                                                        {point}
                                                                     </li>
                                                                  );
                                                               })}
                                                            </ul>
                                                         </li>
                                                      );
                                                   })}
                                                </ol>
                                             </li>
                                          );
                                       })}
                                    </ol>
                                 </div>
                              </Panel>
                           ))}
                        </Collapse>
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default TermsConditions;
