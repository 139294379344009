import React, { useEffect, useState } from "react";

import { HiOutlineRefresh } from "react-icons/hi";
import { FadeView, ImageView, CustomButton } from ".";
import Skeleton from "react-loading-skeleton";
import { Provider, Service, Shipment, Voucher } from "../../model";
import * as Master from "../../reference/Master";
import * as FormatHelper from "../../helper/FormatHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as PricingHelper from "../../helper/PricingHelper";
import * as ReferenceHelper from "../../helper/ReferenceHelper";

import { CacheService } from "../../service";

import "./ServiceProviderView.css";

export default function ServiceProviderView(props: any) {
   const shipment: Shipment = props.shipment;
   const provider: Provider = props.provider;
   const voucher: Voucher = props.voucher;
   // if fail to fetch, the tollFee = -1, error on BPJT system
   let tollPrice = props.tollFee && props.tollFee > 0 ? props.tollFee : 0;
   const ferryPrice = props.ferryFee && props.ferryFee > 0 ? props.ferryFee : 0;
   const [isReturnShipment, setReturnShipment] = useState(Master.NO);
   const [totalFee, setTotalFee] = useState(0);
   const [totalFeeAfterDisc, setTotalFeeAfterDisc] = useState(0);
   const [isWithDisc, setWithDisc] = useState(false);
   const [verificationStatus, setVerificationStatus] = useState("");
   const user = CacheService.getUser();

   useEffect(() => {
      const service = provider.service ? provider.service : new Service();
      const shipmentIsReqTransWorker = shipment.isReqTransWorker!;
      const shipmentDistance = shipment.distance!;
      setReturnShipment(service.returnPriceRatio < 100 ? Master.YES : Master.NO);

      const path = window.location.pathname;
      const isReview = path.indexOf("/admin") > -1;
      const isProviderRef = shipment.isProviderRef;

      const isComs = !(isReview || isProviderRef);
      console.log("isReview: ", isReview);
      console.log("isProviderRef: ", isProviderRef);

      let pickupChargeDistance = 0;

      if (provider.location && shipment.origin) {
         let providerLocation = provider.location;
         let pickupLocation = shipment.origin;
         let pickupDistance = LocationHelper.calcDistance(providerLocation.lat, providerLocation.lng, pickupLocation.lat, pickupLocation.lng);

         if (pickupDistance > service.pickupFreeKmDistance) {
            pickupChargeDistance = Math.round(pickupDistance - service.pickupFreeKmDistance);
            //console.log('pickupChargeDistance: ', pickupChargeDistance);
         }
      }

      let pickupFee = PricingHelper.roundThousand(pickupChargeDistance * calculateUnitPrice(service.pickupPricePerKm, isComs));

      // if return shipment, waive the pickup fee
      if (isReturnShipment === Master.YES) {
         pickupFee = 0;
      }

      console.log("==============================");
      console.log("" + provider.name);
      const transportUnitCost = PricingHelper.getTransportPriceByDistanceWeight(provider.service ? provider.service : new Service(), shipmentDistance, shipment.weightEst);
      console.log("transportUnitCost: " + transportUnitCost);
      console.log("shipmentDistance: " + shipmentDistance);
      console.log("transportUnitCost * shipmentDistance: " + shipmentDistance * transportUnitCost);
      const transportFee = PricingHelper.roundThousand(shipmentDistance * calculateUnitPrice(transportUnitCost, isComs));
      console.log("transportFee: " + transportFee);

      const transitCount = shipment.destinations ? shipment.destinations?.length - 1 : 0;
      const transitFee = PricingHelper.roundThousand(transitCount * calculateUnitPrice(provider.service ? provider.service?.transitPrice : 0, isComs));

      // override toll fee
      tollPrice = Math.round((0.1 * shipmentDistance * transportUnitCost) / 10000) * 10000;
      tollPrice = tollPrice > 500000 ? 500000 : tollPrice;
      console.log("tollFee: " + tollPrice);

      const tollFee = PricingHelper.roundThousand(calculateUnitPrice(tollPrice, isComs));
      console.log("tollFee: " + tollFee);

      const ferryFee = PricingHelper.round10Thousand(calculateUnitPrice(ferryPrice, isComs));
      console.log("ferryFee: " + ferryFee);

      const transWorkerBasePrice = PricingHelper.calculateTransWorkerPrice(provider.transWorkerService ? provider.transWorkerService : new Service(), shipmentDistance, shipment.weightEst);
      const transWorkerFee = shipmentIsReqTransWorker ? PricingHelper.roundThousand(calculateUnitPrice(transWorkerBasePrice, isComs)) : 0;
      console.log("transWorkerFee: " + transWorkerFee);

      const adminFee = !shipment.isProviderRef ? PricingHelper.calculateAdminFee(transportFee) : 0;
      console.log("adminFee: " + adminFee);

      const total = pickupFee + transitFee + transportFee + tollFee + ferryFee + transWorkerFee + adminFee;
      setTotalFee(total);

      const discountAmount = PricingHelper.getDiscount(voucher, total);
      const totalAfterDisc = total - discountAmount;
      setTotalFeeAfterDisc(totalAfterDisc);
      setWithDisc(total !== totalAfterDisc);

      setVerificationStatus(ReferenceHelper.getServiceVerificationStatusDesc(provider.service?.verificationStatus));

      // console.log("service: ", JSON.stringify(service, null, 2));
      // console.log("shipmentIsReqTransWorker: ", shipmentIsReqTransWorker);
      // console.log("pickupFee: ", pickupFee);
      // console.log("transportFee: ", transportFee);
      // console.log("transit: ", transitFee);
      // console.log("tollFee: ", tollFee);
      // console.log("transWorkerFee: ", transWorkerFee);
      // console.log("ferryFee: ", ferryFee);
      // console.log("adminFee: ", adminFee);
      // console.log("totalFee: ", total);
   }, [shipment, provider, tollPrice, isReturnShipment, ferryPrice, voucher]);

   const calculateUnitPrice = (basePrice: number, isComs: boolean) => {
      if (isComs) {
         return basePrice + PricingHelper.round((basePrice * Master.COMS_RATIO) / (1 - Master.COMS_RATIO));
      } else {
         return basePrice;
      }
   };

   const onPressMore = () => {
      props.onPressMore(provider);
   };

   const onPressSelect = () => {
      props.onPressSelect(provider);
   };

   return (
      <FadeView show={true} duration="0">
         <div className="service-provider-view regular-bottom-border">
            <div style={{ display: "flex", flexDirection: "row" }}>
               <div
                  style={{
                     width: 85,
                  }}
               >
                  <ImageView className="service-provider-view-image regular-border" size={75} uploadUrl={provider.profileImgUrl} alt="provider" />
               </div>
               <div className="service-provider-view-content">
                  <div className="service-provider-view-top">
                     <div
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           alignItems: "flex-start",
                           justifyContent: "flex-start",
                        }}
                     >
                        <div className="service-provider-view-name regular-bold-font">{provider.name || <Skeleton delay={1} width={150} />}</div>
                        <div className="service-provider-view-address regular-font">{(provider.location?.address && LocationHelper.getSubRegionCity(provider.location?.address)) || <Skeleton delay={1} width={150} />}</div>
                     </div>
                     <CustomButton loading loadingRef={provider.name} icon={"check"} text="Pilih" style={{ marginLeft: 5, width: 80 }} onClick={onPressSelect} height={30} width={80} />
                  </div>
                  <div className="service-provider-view-bottom">
                     <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        {/*<div className="service-provider-view-title regular-bold-grey-font">{(provider.name && "Rating") || <Skeleton delay={1} width={50} />}</div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: 2, flexWrap: "wrap" }}>
                           <Rating
                              stop={5}
                              placeholderRating={(provider.name && provider.rating) || 0}
                              readonly
                              emptySymbol={<AiFillStar style={{ color: "#ccc" }} />}
                              placeholderSymbol={<AiFillStar style={{ color: yellow }} />}
                              fullSymbol={<AiFillStar style={{ color: yellow }} />}
                           />
                           <div className="service-provider-view-rating-content regular-font">{(provider.name && FormatHelper.formatFloatNumber(provider.rating || 0).replace(",", ".")) || <Skeleton delay={1} width={50} />}</div>
                           <div
                              className="regular-font"
                              style={{
                                 marginLeft: 5,
                                 marginRight: 5,
                              }}
                           >
                              {(provider?.name && provider.reviewCount + " Review") || <Skeleton delay={1} width={50} />}
                           </div>
                           <div
                              className="regular-font"
                              style={{
                                 marginLeft: 5,
                                 marginRight: 5,
                              }}
                           >
                              {(provider?.name && provider.deliveryCount + " Pengiriman") || <Skeleton delay={1} width={50} />}
                           </div>
                           </div>*/}
                        <div className="service-provider-view-title regular-bold-grey-font"> {(provider.name && "Biaya") || <Skeleton delay={1} width={50} />}</div>
                        <div style={{ display: "flex" }}>
                           <div className="service-provider-view-desc regular-font" style={{ width: 190 }}>
                              {(!isWithDisc && provider.name && FormatHelper.formatCurrency(totalFee)) || (!isWithDisc && <Skeleton delay={1} width={50} />)}
                              <span className="before-disc">{(isWithDisc && provider.name && FormatHelper.formatCurrency(totalFee)) || (isWithDisc && <Skeleton delay={1} width={50} />)}</span>
                              {(isWithDisc && provider.name && FormatHelper.formatCurrency(totalFeeAfterDisc)) || (isWithDisc && <Skeleton delay={1} width={50} />)}
                           </div>
                           {provider.name && isReturnShipment === Master.YES && (
                              <div>
                                 <HiOutlineRefresh className="bold-bold-purple-font" />
                              </div>
                           )}
                        </div>
                        {user && user.isAdmin && (
                           <div style={{ marginTop: 5 }}>
                              <span className={verificationStatus ? "service-provider-view-status regular-white-font" : ""}>{verificationStatus || <Skeleton delay={1} width={150} />}</span>
                           </div>
                        )}
                     </div>
                     <div>
                        <CustomButton loading loadingRef={provider.name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </FadeView>
   );
}
