import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BiMoney } from 'react-icons/bi'
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";

import { Ads } from './ads';
import { Category } from './category';
import { WhyUs } from './why-us';
import { ItWork } from './how-it-works';
import { Download } from './download';
import { Promo } from './promo';

import { SearchBar } from '../common';

import { CacheService } from '../../service';

import * as Master from '../../reference/Master'
import * as Config from "../../Config";

import "antd/dist/antd.css";
import './Home.css';

function Home(props) {
   const history = useHistory();
   const user = CacheService.getUser();

   if (props.webview) {
      CacheService.setWebView(props.webview);
      // CacheService.setWebView(false);
      // alert("webview: " + CacheService.isWebView());
   }

   useEffect(() => {
      //console.log('user home: ', JSON.stringify(user, null, 2));

      if (user && user.gid) {

         if (user.transporter) {
            if (!CacheService.isGpsPermissionGiven()) {
               CacheService.isGpsPermissionRequested(false);
            }
         }

         //console.log('go to home login');
         history.push('/home/login');
      }
      props.setShowBackNavigation(false);
      props.setShowBottomNavigation(true);

   }, [props, history, user]);

   const onContactWa = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service ..."));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Whatsapp Admin");

      // gtm();
   };

   /*const onBookNow = data => {
      CacheService.setCategory("");
      if (CacheService.getUser() && CacheService.getUser().customer) {
         //console.log('getUser: ', CacheService.getUser());
         history.push('/booking');
      } else {
         history.push("/login/" + Master.PATH_BOOKING);
      }

      gtm();
   };*/

   const onBookNow = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau cek harga"));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Book Now");

      // gtm();
   };

   const gtm = () => {
      // gtm conversion
      const tagManagerArgs = {
         gtmId: "GTM-MW67KPL",
      };
      TagManager.initialize(tagManagerArgs);
   };

   if (user && user.gid) {
      return <div></div>;
   } else {
      return (
         <div className="home">
            {/*window.innerWidth < 1000 && <SearchBar />*/}
            <div className="home-content">
               <Ads {...props} />
               <Category />
               <Promo />
               <WhyUs />
               <ItWork />
               <Download />
            </div>
            <div className="home-booking">
               <div className="home-booking-btn" onClick={onBookNow}>
                  <BiMoney className={"home-booking-icon"} />
                  <div>Cek Harga</div>
               </div>
            </div>
            <div>
               <div onClick={onContactWa} className="float cursor-pointer" target="_blank" rel="noreferrer">
                  <i className="fa fa-whatsapp my-float" />
               </div>
            </div>
         </div >
      );
   }
}

export default Home;
