import { CacheService, ShipmentService } from "../service";
import JavaScriptInterface from "../interface/AndroidInterface";
import { Shipment } from "../model";

import * as Master from "../reference/Master";

let position = { lat: 0, lng: 0 };
let shipmentsInDelivery: Shipment[] = [];
let isInitPosition = false;
let isWebView = CacheService.isWebView();

declare var android: JavaScriptInterface;

export const updateShipmentInDeliveryPosition = () => {
   let user = CacheService.getUser();
   // console.log("getShipmetInDelivery user: " + JSON.stringify(user, null, 2));
   if (user && user.transporter) {
      // only get status in delivery when user is the transporter
      ShipmentService.searchShipment("", "TRANSPORTER" + Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS, 100, 1)
         .then((resp) => {
            shipmentsInDelivery = resp.shipments;
            // alert("shipments in delivery: " + JSON.stringify(shipmentsInDelivery));
            if (!isInitPosition) {
               getPosition();
               isInitPosition = true;
            }
            setTimeout(() => {
               updatePosition();
            }, 5000);
         })
         .catch((error) => {
            console.error("getShipmetInDelivery: ", error);
         });
   }
};

export const getPosLatLng = () => {
   return position;
};

export const getPosition = () => {
   // monitor GPS changes and update accordingly
   if (CacheService.isGpsPermissionRequested()) {
      navigator.geolocation.watchPosition(
         (p) => {
            position = { lat: p.coords.latitude, lng: p.coords.longitude };
            // console.log('getPosition: ', JSON.stringify(position, null, 2));
         },
         error,
         {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 10000,
         }
      );
   }
};

export const updatePosition = () => {
   const updatePos = () => {
      // console.log("run interval");
      // console.log("shipmensInDelivery: " + shipmentsInDelivery.length);
      // console.log("position: " + JSON.stringify(position, null, 2));
      // console.log("prevPosition: " + JSON.stringify(prevPosition, null, 2));

      const carrierMap = new Map<number, number>();

      if (shipmentsInDelivery.length > 0) {
         let carrierIds = shipmentsInDelivery.map((shipment: Shipment) => {
            return shipment.carrier ? (shipment.carrier.id ? shipment.carrier.id : 0) : 0;
         });

         let shipmentsInTravelToOrigin = shipmentsInDelivery.filter((shipment: Shipment) => {
            return shipment.stepStatus === Master.STEP_CODE_TRAVEL_TO_ORIGIN;
         });

         for (let i in carrierIds) {
            let carrierId = carrierIds[i];
            carrierMap.set(carrierId, carrierId);
         }
         let uniqueCarrierIds = Array.from(carrierMap.keys());

         // in android webview container call android interface
         if (isWebView) {
            if (shipmentsInTravelToOrigin.length > 0) {
               android.setUpdateLocationInterval(5);
            } else {
               android.setUpdateLocationInterval(15);
            }
            android.setCarrierIds(uniqueCarrierIds.toString());
            android.startUpdateLocation();
         }
      } else {
         if (isWebView) {
            if (!CacheService.isGpsPermissionRequested()) {
               android.requestGpsPermission();
            }
            android.stopUpdateLocation();
         }
      }
   };

   updatePos();
   // console.log("interval: ", updatePeriod);
};

const error = (error: any) => {
   console.error(error + " -> " + JSON.stringify(error, null, 2));
};
