import {Provider, Image} from '.';

export default class Carrier {
   id?: number;
   gid?: string;
   provider?: Provider;
   code: string;
   name: string;
   fleetCategoryCode: string;
   transportTypeCode: string;
   licenseNo: string;
   licenseExpiry: Date;
   licenseImg?: Image;
   certificateNo?: string;
   certificateImg?: Image;
   certificateExpiry: Date;
   profileImg?: Image;
   profileImgUrl?: string;
   capacityLength: number;
   capacityWidth: number;
   capacityHeight: number;
   verificationStatus: string;
   status: string;
}
