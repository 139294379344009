import React, { useEffect, useState } from "react";
import { Input, Checkbox } from "antd";
import { useForm } from "react-hook-form";
import { FiPackage, FiCamera } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { RiScissorsCutLine } from "react-icons/ri";

import { InputSelect, ImageUpload } from ".";

import { Place } from "../../model";

import * as Master from "../../reference/Master";

import * as LocationHelper from "../../helper/LocationHelper";
import * as FormatHelper from "../../helper/FormatHelper";

import { CacheService } from "../../service";

import "./ShipmentDescUpdate.css";

const { TextArea } = Input;

function ShipmentDescInput(props: any) {
   const [input, setInput] = useState(props.value);

   useEffect(() => {
      setInput(props.value);
   }, [props.value]);

   if (props.number) {
      return (
         <div className="sect-border regular-border" style={{ marginTop: 5 }}>
            <Input
               name={props.name}
               placeholder={props.placeholder}
               value={input}
               onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9,]/, "");
                  setInput(value);
                  props.onChange(value.replace(",", "."));
               }}
               style={props.isValidated && !input ? { backgroundColor: "#f7d8d6" } : {}}
            />
         </div>
      );
   } else if (props.textArea) {
      return (
         <div className="sect-content-text-area">
            <TextArea
               name={props.name}
               placeholder={props.placeholder}
               defaultValue={input}
               rows={4}
               onChange={(e: any) => {
                  const value = e.target.value;
                  setInput(value);
                  props.onChange(value);
               }}
               style={props.isValidated && !input ? { backgroundColor: "#f7d8d6" } : {}}
            />
         </div>
      );
   } else {
      return (
         <div className="sect-border regular-border" style={{ marginTop: 5 }}>
            <Input
               name={props.name}
               placeholder={props.placeholder}
               value={input}
               onChange={(e) => {
                  const value = props.caps ? e.target.value.toUpperCase() : e.target.value;
                  setInput(value);
                  props.onChange(value);
               }}
               style={props.isValidated && !input ? { backgroundColor: "#f7d8d6" } : {}}
            />
         </div>
      );
   }
}

function ShipmentPic(props: any) {
   const place: Place = props.place;
   const [isIamPic, setIamPic] = useState(false);

   const onIamPic = (e: any) => {
      console.log("I am PIC: " + e.target.checked);
      setIamPic(e.target.checked);
      let user = CacheService.getUser();
      if (user) {
         place.picName = user.name;
         place.picMobile = user.mobile;
         console.log("Place: ", JSON.stringify(place, null, 2));
      }
   };

   return (
      <div className="sect-content">
         <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div className="sect-icon-container">
               <AiOutlineUser className="sect-icon" />
            </div>
            <div ref={props.anchor} className="sect-title regular-bold-grey-font">
               Kontak {props.title}
            </div>
         </div>
         <div className="sect-address">
            <div className="sub-regular-font">{LocationHelper.getSubRegionCity(props.place && props.place.address)}</div>
            <div className="sub-regular-font">{LocationHelper.getShortAddress(props.place && props.place.address)}</div>
         </div>
         <Checkbox onChange={onIamPic} className="regular-bold-grey-font" style={{ marginTop: 10 }}>
            Gunakan kontak saya sebagai {props.title}
         </Checkbox>
         {!isIamPic && <ShipmentDescInput name={props.name + "Name"} isIamPic={isIamPic} placeholder="Nama" value={place.picName} onChange={props.onChangeName} isValidated={props.isValidated} />}
         {!isIamPic && <ShipmentDescInput name={props.name + "Mobile"} isIamPic={isIamPic} placeholder="No. Telepon" value={place.picMobile} onChange={props.onChangeMobile} isValidated={props.isValidated} />}
         {isIamPic && (
            <div className="regular-font" style={{ marginTop: 10 }}>
               {place.picName} / {place.picMobile}{" "}
            </div>
         )}
      </div>
   );
}

export default function ShipmentDescUpdate(props: any) {
   const { control, errors, setValue } = useForm();

   const [weight, setWeight] = useState(props.shipment.weightEst);

   useEffect(() => {
      setValue([{ desc: props.shipment.description }]);
   }, [props.shipment.description, setValue]);

   return (
      <div className="shipment-desc-update regular-border">
         <div className="sect" ref={props.refPicOrigin}>
            <ShipmentPic
               title="Pengirim"
               name="picOrigin"
               place={props.shipment.origin}
               onChangeName={(value: any) => {
                  props.shipment.origin.picName = value;
                  props.shipment.picOriginName = value;
               }}
               onChangeMobile={(value: any) => {
                  props.shipment.origin.picMobile = value;
                  props.shipment.picOriginMobile = value;
               }}
               isValidated={props.isValidated}
               anchor={props.refPicOrigin}
            />
            {props.shipment.destinations.map((destination: Place, key: number) => {
               const lastIndex = props.shipment.destinations.length - 1;
               return (
                  <ShipmentPic
                     key={key}
                     title={key < lastIndex ? "Transit" : "Penerima"}
                     name={`picDestination${key}`}
                     place={destination}
                     onChangeName={(value: any) => {
                        destination.picName = value;
                        if (key === lastIndex) {
                           props.shipment.picDestinationName = value;
                        }
                     }}
                     onChangeMobile={(value: any) => {
                        destination.picMobile = value;
                        if (key === lastIndex) {
                           props.shipment.picDestinationMobile = value;
                        }
                     }}
                     isValidated={props.isValidated}
                     anchor={props.refPicDestination}
                  />
               );
            })}
            <div className="sect-content">
               <div className="sect-content-top">
                  <div className="sect-icon-container">
                     <FiPackage className="sect-icon" />
                  </div>
                  <div ref={props.refCargo} className="sect-title regular-bold-grey-font">
                     Informasi Muatan
                  </div>
               </div>
               <form>
                  <InputSelect
                     name="desc"
                     placeholder="Informasi Muatan"
                     hidePlaceholder
                     loadingRef={props.shipment.description}
                     control={control}
                     error={errors.description}
                     options={Master.cargoTypeOptions}
                     onSelect={(value: any, event: any) => {
                        props.shipment.description = value;
                     }}
                  />
               </form>
               <div className="sect-content-top" style={{ marginTop: 12, marginBottom: 10 }}>
                  <div className="sect-icon-container">
                     <FiPackage className="sect-icon" />
                  </div>
                  <div className="sect-title sect-title-info regular-bold-grey-font">
                     <span>Estimasi Berat Kg</span>
                  </div>
               </div>
               <ShipmentDescInput
                  name={"tonase"}
                  placeholder="Berat Dalam Kg"
                  value={weight}
                  onChange={(value: any) => {
                     props.shipment.weightEst = value;
                     setWeight(value);
                  }}
                  number
                  isValidated={props.isValidated}
               />
            </div>
            <div className="sect-content">
               <div className="sect-content-top">
                  <div className="sect-icon-container">
                     <FiCamera className="sect-icon" />
                  </div>
                  <div className="sect-title regular-bold-grey-font" style={{ flex: 1 }}>
                     Foto Kargo
                  </div>
               </div>
               <div className="sect-content-top">
                  <ImageUpload multiple reference="shipment-photo" images={props.images} setImages={props.setImages} photo text="Pilih Photo" desc="Foto Muatan" />
               </div>
            </div>
            <div className="sect-content">
               <div className="sect-content-top">
                  <div className="sect-icon-container">
                     <FiCamera className="sect-icon" />
                  </div>
                  <div ref={props.refRemarks} className="sect-title regular-bold-grey-font" style={{ flex: 1 }}>
                     Catatan
                  </div>
               </div>
               <div className="sect-content-top">
                  <ShipmentDescInput
                     name="remarks"
                     placeholder="Catatan khusus, dimensi terpanjang muatan P x L x T"
                     value={props.shipment.remarks}
                     onChange={(value: any) => {
                        props.shipment.remarks = value;
                     }}
                     textArea
                     isValidated={props.isValidated}
                  />
               </div>
            </div>
            <div className="sect-content">
               <div className="sect-content-top">
                  <div className="sect-icon-container">
                     <FaRegMoneyBillAlt className="sect-icon" />
                  </div>
                  <div className="sect-title sect-title-info regular-bold-grey-font">
                     <span>Biaya Angkutan</span>
                     <span>{FormatHelper.formatCurrency(props.shipment.payment.totalCharge)}</span>
                  </div>
               </div>
               <div className="sect-content-top" style={{ marginTop: 5, marginBottom: 5 }}>
                  <div className="sect-icon-container">
                     <RiScissorsCutLine className="sect-icon" />
                  </div>
                  <div ref={props.refVoucher} className="sect-title sect-title-info regular-bold-grey-font">
                     <span>Kode Promosi</span>
                     <span>{FormatHelper.formatCurrency(-props.shipment.payment.discountAmount)}</span>
                  </div>
               </div>
               <ShipmentDescInput
                  name={"voucherCode"}
                  placeholder="Kode Promosi"
                  value={props.voucherCode}
                  onChange={(value: any) => {
                     props.setVoucherCode(value);
                  }}
                  caps
               />
               <div className="sect-content-top" style={{ marginTop: 5, marginBottom: 5 }}>
                  <div className="sect-icon-container">
                     <FaRegMoneyBillAlt className="sect-icon" />
                  </div>
                  <div className="sect-title sect-title-info regular-bold-grey-font">
                     <span>Total Biaya</span>
                     <span>{FormatHelper.formatCurrency(props.shipment.payment.totalPaymentAmount)}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
