import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { ShipmentSteps } from "../common";
import { Category } from "./category";

import * as Master from "../../reference/Master";

import { CacheService } from "../../service";

import "./Booking.css";

function Booking(props: any) {
   const history = useHistory();
   const location = useLocation();

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [props]);

   const onSelectCategory = (category: string) => {
      if (location.pathname.includes("/test/")) {
         history.push("/test/select-transport-type/" + category);
      } else {
         history.push("/select-transport-type/" + category);
      }
      CacheService.setCategory(category);
   };

   const onSelectRegularCategory = (category: string) => {
      history.push("/select-regular-shipment/" + category);
   };

   return (
      <div className="booking">
         <div className="booking-content">
            <div className="booking-content-steps">
               <ShipmentSteps status={Master.SHIPMENT_STATUS_NEW_ORDER} />
            </div>
            <Category onSelect={onSelectCategory} onSelectRegular={onSelectRegularCategory} />
         </div>
      </div>
   );
}

export default Booking;
