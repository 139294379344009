import { gql } from "@apollo/client";

import { Shipment, Image, Fee, Step, Route, Payment } from "../model";

import { authGraphClient, initAuthClient } from "./GraphService";

import * as LocationHelper from "../helper/LocationHelper";
import * as PositionHelper from "../helper/PositionHelper";
import * as GraphHelper from "../helper/GraphHelper";
import { CacheService } from ".";

/*
RegisterShipment(inputShipment: InputShipment!, inputOrigin: InputPlace!, inputDestination: InputPlace!): Int!

input InputShipment {
   ID: ID
   providerID: Int
   transporterID: Int
   carrierID: Int
   transportTypeCode: String!
   transportTypeDesc: String!
   originDesc: String!
   destinationDesc: String!
   description: String!
   distance: Int!
   duration: Int!
   requestDate: Time!
   deliveryDate: Time!
   deliveryStartDate: Time!
   deliveryEndDate: Time!
   picOriginName: String!
   picOriginMobile: String!
   picOrderName: String!
   picOrderMobile: String!
   totalPayment: Int!
   status: String!
}

input InputPlace {
   ID: ID
   name: String!
   address: String!
   lat: Float!
   lng: Float!
}
*/

class ShipmentService {
   async registerShipment(shipment: Shipment) {
      await initAuthClient();
      console.log("registerShipment:", JSON.stringify(shipment, null, 2));

      let inputImages = "";
      for (let i = 0; i < (shipment.images ? shipment.images.length : 0); i++) {
         inputImages += `{
            ID: ${shipment.images[i].id}
         },`;
      }

      // console.log("Type Of Request Date:", typeof shipment.requestDate);

      let path = shipment.path;

      path = path.replaceAll("\\", "\\\\");
      path = path.replaceAll('"', '\\"');

      let g = `
         mutation {
            RegisterShipment(
               inputShipment: {
                  providerGid: "${shipment.provider?.gid}",
                  transportTypeCode: "${shipment.transportTypeCode}",
                  transportTypeDesc: "${shipment.transportTypeDesc}",
                  originDesc: "${LocationHelper.getSubRegionCity(shipment.origin?.address)}",
                  destinationDesc:  "${LocationHelper.getSubRegionCity(shipment.destination?.address)}",
                  description:  "${shipment.description}",
                  distance: ${shipment.distance},
                  duration: ${shipment.duration},
                  requestDate: "${shipment.requestDate.toISOString()}",
                  deliveryDate: "${shipment.deliveryStartDate.toISOString()}",
                  deliveryStartDate: "${shipment.deliveryStartDate.toISOString()}",
                  deliveryEndDate: "${shipment.deliveryEndDate.toISOString()}",
                  picOriginName: "${shipment.picOriginName}",
                  picOriginMobile: "${shipment.picOriginMobile}",
                  weightEst: ${shipment.weightEst},
                  remarks: """${shipment.remarks}""",
                  isReqTransWorker: ${shipment.isReqTransWorker ? true : false},
                  isReturnShipment: ${shipment.isReturnShipment ? true : false},
                  isFerryShipment: ${shipment.isFerryShipment ? true : false},
                  isProviderRef: ${shipment.isProviderRef ? true : false},
                  isSpecialPrice: ${shipment.provider?.service?.isSpecialPrice ? true : false},
                  voucherCode: "${shipment.voucherCode}",
                  status:  "${shipment.status}",
                  siteGid: "${shipment.provider?.site?.gid}",
                  path: "${path}",
               },
               inputOrigin: {
                  name: "${shipment.origin?.name}",
                  address: "${shipment.origin?.address}",
                  lat: ${shipment.origin?.lat},
                  lng: ${shipment.origin?.lng},
               },
               inputDestination: {
                  name: "${shipment.destination?.name}",
                  address: "${shipment.destination?.address}",
                  lat: ${shipment.destination?.lat},
                  lng: ${shipment.destination?.lng},
               },
               inputDestinations: [${shipment.destinations?.map(
                  (destination) =>
                     `{
                     name: "${destination?.name}",
                     address: "${destination?.address}",
                     lat: ${destination?.lat},
                     lng: ${destination?.lng},
                     picName: "${destination?.picName}",
                     picMobile: "${destination?.picMobile}",
                  }`
               )}],
               inputImages: [${inputImages}],
            )
         }`;

      console.log("gql:", g);
      CacheService.setApiRequest(g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipmentGid = result.data.RegisterShipment;

      console.log(result.data);

      return shipmentGid;
   }

   async updateShipmentStatus(shipmentGid: string, status: string, reasonCode: string, reasonDesc: string) {
      await initAuthClient();
      // console.log("updateShipmentStatus:", JSON.stringify(shipmentGid, null, 2));

      let g = `
            mutation {
               UpdateShipmentStatus(
                  shipmentGid: "${shipmentGid}",
                  status: "${status}",
                  reasonCode: "${reasonCode}",
                  reasonDesc: "${reasonDesc}",
               )
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipmentID = result.data.UpdateShipmentStatus;

      // console.log("result:", result.data);

      return shipmentID;
   }

   async updateShipmentCharge(
      shipmentGid?: string,
      totalAmount?: number,
      providerAmount?: number,
      planPaymentBooking?: number,
      planPaymentInitial?: number,
      planPaymentFinal?: number,
      planPayoutBooking?: number,
      planPayoutInitial?: number,
      planPayoutFinal?: number,
      topDuration?: number
   ) {
      await initAuthClient();
      // console.log("updateShipmentCharge: ", shipmentGid, " amount: ", amount);

      let g = `
            mutation {
               UpdateShipmentCharge(
                  shipmentGid: "${shipmentGid}",
                  totalAmount: ${totalAmount},
                  providerAmount: ${providerAmount},
                  planPaymentBooking: ${planPaymentBooking},
                  planPaymentInitial: ${planPaymentInitial},
                  planPaymentFinal: ${planPaymentFinal},
                  planPayoutBooking: ${planPayoutBooking},
                  planPayoutInitial: ${planPayoutInitial},
                  planPayoutFinal: ${planPayoutFinal},
                  topDuration: ${topDuration}
               ) {
                  type
                  totalCharge
                  discountCode
                  discountType
                  discountValue
                  discountValueCap
                  discountAmount
                  totalPaymentAmount
                  totalProviderAmount
                  totalPlatformAmount
                  bookingPaymentAmount
                  bookingPaymentDate
                  bookingPaymentMethod
                  bookingPaymentRef
                  initialPaymentAmount
                  initialPaymentDate
                  initialPaymentMethod
                  initialPaymentRef
                  finalPaymentAmount
                  finalPaymentDate
                  finalPaymentMethod
                  finalPaymentRef
                  platformPaymentAmount
                  platformPaymentDate
                  platformPaymentRef
                  platformPaymentStatus
                  topDuration
                  planPaymentBooking
                  planPaymentInitial
                  planPaymentFinal
                  planPayoutBooking
                  planPayoutInitial
                  planPayoutFinal
                  bookingPayoutAmount
                  bookingPayoutDate
                  bookingPayoutMethod
                  bookingPayoutRef
                  initialPayoutAmount
                  initialPayoutDate
                  initialPayoutMethod
                  initialPayoutRef
                  finalPayoutAmount
                  finalPayoutDate
                  finalPayoutMethod
                  finalPayoutRef
                  bookingPaymentImgUrl
                  initialPaymentImgUrl
                  finalPaymentImgUrl
                  bookingPayoutImgUrl
                  initialPayoutImgUrl
                  finalPayoutImgUrl
                  status
               }
            }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      const payment: Payment = result ? result.data.UpdateShipmentCharge : null;

      console.log("result:", result.data);

      return payment;
   }

   async updateShipmentStepEstimate(shipmentGid?: string, duration?: number) {
      await initAuthClient();
      // console.log("updateShipmentStepEstimate: ", shipmentGid, " duration: ", duration);

      let g = `
            mutation {
               UpdateShipmentStepEstimate(
                  shipmentGid: "${shipmentGid}",
                  duration: ${duration},
               ) {
                  id
                  code
                  description
                  durationEst
                  duration
                  completedDateEst
                  completedDate
                  signImgUrl
                  remarks
                  status
                  posLat
                  posLng
               }
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      const steps: Step[] = result ? result.data.UpdateShipmentStepEstimate : null;

      // console.log("result:", result.data);

      return steps;
   }

   async rescheduleShipment(shipmentGid: string, date: Date, remarks: string) {
      await initAuthClient();
      // console.log("rescheduleShipment:", JSON.stringify(date, null, 2));

      let g = `
            mutation {
               RescheduleShipment(
                  shipmentGid: "${shipmentGid}",
                  date: "${date.toISOString()}",
                  remarks: """${remarks}""",
               ) {
                  id
                  gid
                  bookingCode
                  transportTypeDesc
                  provider {
                     name
                     profileImgUrl
                  }
                  transporter {
                     name
                     profileImgUrl
                  }
                  customer {
                     name
                     profileImgUrl
                  }
                  carrier {
                     code
                     licenseNo
                     profileImgUrl
                  }
                  payment {
                     type
                     totalCharge
                     discountCode
                     discountType
                     discountValue
                     discountValueCap
                     discountAmount
                     totalPaymentAmount
                     totalProviderAmount
                     totalPlatformAmount
                     bookingPaymentAmount
                     bookingPaymentDate
                     bookingPaymentMethod
                     bookingPaymentRef
                     initialPaymentAmount
                     initialPaymentDate
                     initialPaymentMethod
                     initialPaymentRef
                     finalPaymentAmount
                     finalPaymentDate
                     finalPaymentMethod
                     finalPaymentRef
                     platformPaymentAmount
                     platformPaymentDate
                     platformPaymentRef
                     platformPaymentStatus
                     topDuration
                     planPaymentBooking
                     planPaymentInitial
                     planPaymentFinal
                     planPayoutBooking
                     planPayoutInitial
                     planPayoutFinal
                     bookingPayoutAmount
                     bookingPayoutDate
                     bookingPayoutMethod
                     bookingPayoutRef
                     initialPayoutAmount
                     initialPayoutDate
                     initialPayoutMethod
                     initialPayoutRef
                     finalPayoutAmount
                     finalPayoutDate
                     finalPayoutMethod
                     finalPayoutRef
                     bookingPaymentImgUrl
                     initialPaymentImgUrl
                     finalPaymentImgUrl
                     bookingPayoutImgUrl
                     initialPayoutImgUrl
                     finalPayoutImgUrl
                     status
                  }
                  originDesc
                  destinationDesc
                  deliveryDate
                  picOriginName
                  picOriginMobile
                  picOrderName
                  picOrderMobile
                  rescheduleFromDate
                  rescheduleToDate
                  rescheduleRemarks
                  rescheduleRemarksHistory
                  rescheduleStatus
                  totalPayment
                  description
                  declineDesc
                  weightEst
                  remarks
                  rating
                  ratingRemarks
                  status
               }
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipment = result.data.RescheduleShipment;

      // console.log("result:", result.data);

      return shipment;
   }

   async processRescheduleShipment(shipmentGid: string, accept: string) {
      await initAuthClient();
      // console.log("processRescheduleShipment:", JSON.stringify(shipmentGid, null, 2));

      let g = `
            mutation {
               ProcessRescheduleShipment(
                  shipmentGid: "${shipmentGid}",
                  accept: "${accept}",
               ) {
                  steps {
                     id
                     code
                     description
                     durationEst
                     duration
                     completedDateEst
                     completedDate
                     signImgUrl
                     remarks
                     status
                     posLat
                     posLng
                  }
                  shipment {
                     id
                     gid
                     bookingCode
                     transportTypeDesc
                     provider {
                        name
                        profileImgUrl
                     }
                     transporter {
                        name
                        profileImgUrl
                     }
                     customer {
                        name
                        profileImgUrl
                     }
                     carrier {
                        code
                        licenseNo
                        profileImgUrl
                     }
                     payment {
                        type
                        totalCharge
                        discountCode
                        discountType
                        discountValue
                        discountValueCap
                        discountAmount
                        totalPaymentAmount
                        totalProviderAmount
                        totalPlatformAmount
                        bookingPaymentAmount
                        bookingPaymentDate
                        bookingPaymentMethod
                        bookingPaymentRef
                        initialPaymentAmount
                        initialPaymentDate
                        initialPaymentMethod
                        initialPaymentRef
                        finalPaymentAmount
                        finalPaymentDate
                        finalPaymentMethod
                        finalPaymentRef
                        platformPaymentAmount
                        platformPaymentDate
                        platformPaymentRef
                        platformPaymentStatus
                        topDuration
                        planPaymentBooking
                        planPaymentInitial
                        planPaymentFinal
                        planPayoutBooking
                        planPayoutInitial
                        planPayoutFinal
                        bookingPayoutAmount
                        bookingPayoutDate
                        bookingPayoutMethod
                        bookingPayoutRef
                        initialPayoutAmount
                        initialPayoutDate
                        initialPayoutMethod
                        initialPayoutRef
                        finalPayoutAmount
                        finalPayoutDate
                        finalPayoutMethod
                        finalPayoutRef
                        bookingPaymentImgUrl
                        initialPaymentImgUrl
                        finalPaymentImgUrl
                        bookingPayoutImgUrl
                        initialPayoutImgUrl
                        finalPayoutImgUrl
                        status
                     }
                     originDesc
                     destinationDesc
                     deliveryDate
                     picOriginName
                     picOriginMobile
                     picOrderName
                     picOrderMobile
                     rescheduleFromDate
                     rescheduleToDate
                     rescheduleRemarks
                     rescheduleRemarksHistory
                     rescheduleStatus
                     totalPayment
                     description
                     declineDesc
                     weightEst
                     remarks
                     rating
                     ratingRemarks
                     status
                  }
               }
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipment = result.data.ProcessRescheduleShipment;

      // console.log("result:", result.data);

      return shipment;
   }

   async rateShipment(shipmentGid: string, rating: number, remarks: string) {
      await initAuthClient();
      // console.log("rateShipment:", JSON.stringify(shipmentGid, null, 2));

      let g = `
            mutation {
               RateShipment(
                  shipmentGid: "${shipmentGid}",
                  rating: ${rating},
                  remarks: """${remarks}""",
               ) {
                  id
                  gid
                  bookingCode
                  transportTypeDesc
                  provider {
                     name
                     profileImgUrl
                  }
                  transporter {
                     name
                     profileImgUrl
                  }
                  customer {
                     name
                     profileImgUrl
                  }
                  carrier {
                     code
                     licenseNo
                     profileImgUrl
                  }
                  payment {
                     type
                     totalCharge
                     discountCode
                     discountType
                     discountValue
                     discountValueCap
                     discountAmount
                     totalPaymentAmount
                     totalProviderAmount
                     totalPlatformAmount
                     bookingPaymentAmount
                     bookingPaymentDate
                     bookingPaymentMethod
                     bookingPaymentRef
                     initialPaymentAmount
                     initialPaymentDate
                     initialPaymentMethod
                     initialPaymentRef
                     finalPaymentAmount
                     finalPaymentDate
                     finalPaymentMethod
                     finalPaymentRef
                     platformPaymentAmount
                     platformPaymentDate
                     platformPaymentRef
                     platformPaymentStatus
                     topDuration
                     planPaymentBooking
                     planPaymentInitial
                     planPaymentFinal
                     planPayoutBooking
                     planPayoutInitial
                     planPayoutFinal
                     bookingPayoutAmount
                     bookingPayoutDate
                     bookingPayoutMethod
                     bookingPayoutRef
                     initialPayoutAmount
                     initialPayoutDate
                     initialPayoutMethod
                     initialPayoutRef
                     finalPayoutAmount
                     finalPayoutDate
                     finalPayoutMethod
                     finalPayoutRef
                     bookingPaymentImgUrl
                     initialPaymentImgUrl
                     finalPaymentImgUrl
                     bookingPayoutImgUrl
                     initialPayoutImgUrl
                     finalPayoutImgUrl
                     status
                  }
                  originDesc
                  destinationDesc
                  deliveryDate
                  picOriginName
                  picOriginMobile
                  picOrderName
                  picOrderMobile
                  rescheduleFromDate
                  rescheduleToDate
                  rescheduleRemarks
                  rescheduleRemarksHistory
                  rescheduleStatus
                  totalPayment
                  description
                  declineDesc
                  weightEst
                  remarks
                  rating
                  ratingRemarks
                  status
               }
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipment = result.data.RateShipment;

      // console.log("result:", result.data);

      return shipment;
   }

   async processShipment(shipment: Shipment, transitType: String, remarks: String, signImg: Image | undefined, supportImgs: Image[] | undefined, isSharedCapacity: boolean | undefined, loadCapacity: number | undefined) {
      await initAuthClient();
      // console.log("processShipment:", JSON.stringify(shipment, null, 2));

      let supImgsParam = "";
      if (supportImgs) {
         for (let i = 0; i < (supportImgs ? supportImgs.length : 0); i++) {
            supImgsParam += `{
            ID: ${supportImgs[i].id}
         },`;
         }
         supImgsParam = `supportImgs: [${supImgsParam}]`;
      }

      let signImgParam = "";
      if (signImg) {
         signImgParam = `signImg: {
            ID: ${signImg.id},
            url: "${signImg.url}",
         },`;
      }

      let isSharedCapacityParam = "";
      if (isSharedCapacity) {
         isSharedCapacityParam = `isSharedCapacity: ${isSharedCapacity},`;
      }

      let loadCapacityParam = "";
      if (loadCapacity) {
         loadCapacityParam = `loadCapacity: ${loadCapacity},`;
      }

      let position = PositionHelper.getPosLatLng();

      let g = `
            mutation {
               ProcessShipment(
                  shipmentGid: "${shipment.gid}",
                  transitType: "${transitType}",
                  remarks: """${remarks}""",
                  posLat: ${position.lat},
                  posLng: ${position.lng},
                  ${signImgParam}
                  ${supImgsParam}
                  ${isSharedCapacityParam}
                  ${loadCapacityParam}
               ) {
                  steps {
                     id
                     code
                     description
                     durationEst
                     duration
                     completedDateEst
                     completedDate
                     signImgUrl
                     remarks
                     status
                  }
                  routes {
                     place {
                        id
                        name
                        address
                        lat
                        lng
                     }
                     status
                     completedDate
                     picName
                     picMobile
                  }
                  shipmentStatus
                  shipmentStepStatus
               }
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      //const steps: Step[] = result ? result.data.ProcessShipment.steps : null;
      //const shipmentStatus: String = result ? result.data.ProcessShipment.shipmentStatus : null;

      // console.log("processShipment:", JSON.stringify(result.data.ProcessShipment, null, 2));

      return result.data.ProcessShipment;
   }

   async assignShipmentCarrier(shipmentGid: string, carrierGid: string) {
      await initAuthClient();
      // console.log("assignShipmentCarrier:", JSON.stringify(shipmentGid, null, 2));

      let g = `
            mutation {
               AssignShipmentCarrier(
                  shipmentGid: "${shipmentGid}",
                  carrierGid: "${carrierGid}",
               )
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipmentID = result.data.AssignShipmentCarrier;

      // console.log("result:", result.data);

      return shipmentID;
   }

   async assignShipmentTransporter(shipmentGid: string, transporterGid: string) {
      await initAuthClient();
      // console.log("assignShipmentTransporter:", JSON.stringify(shipmentGid, null, 2));

      let g = `
            mutation {
               AssignShipmentTransporter(
                  shipmentGid: "${shipmentGid}",
                  transporterGid: "${transporterGid}",
               )
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipmentID = result.data.AssignShipmentTransporter;

      // console.log("result:", result.data);

      return shipmentID;
   }

   async getShipmentStatusSummary() {
      await initAuthClient();

      let gqlReq = `
            query { 
               GetShipmentStatus 
               {
                  status
                  count
               }
            }`;

      // console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetShipmentStatus;
      // console.log("getShipmentStatus: " + JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentPath(gid: string) {
      await initAuthClient();

      let gqlReq = `
            query { 
               GetShipmentPath(shipmentGid: "${gid}")
               {
                  plan
                  actual
               }
            }`;

      // console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetShipmentPath;

      const path = { plan: GraphHelper.respToJsonString(resp.plan), actual: `[${GraphHelper.respToJsonString(resp.actual)}]` };

      // console.log("getShipmentStatus: ", path.actual);

      return path;
   }

   async searchShipment(search: string, status: string, pageSize: number, page: number) {
      await initAuthClient();
      // console.log("GetShipments");

      let shipmentPageCountGql =
         page === 1
            ? `
            GetShipmentsPageCount(search: "${search}", status: "${status}", pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetShipments(
                  search: "${search}",
                  status: "${status}", 
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  id
                  gid
                  bookingCode
                  provider {
                     name
                     phone
                     profileImgUrl
                     service {
                        verificationStatus
                     }
                  }
                  transporter {
                     name
                     phone
                     profileImgUrl
                  }
                  carrier {
                     id
                  }
                  customer {
                     name
                     phone
                     profileImgUrl
                  }
                  picOrderName
                  picOrderMobile
                  originDesc
                  destinationDesc
                  deliveryDate
                  deliveryEndDate
                  stepStatus
                  status
               }
               ${shipmentPageCountGql}
            }`;

      // console.log("gql query: ", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log("result: " + JSON.stringify(result, null, 2));
      let resp = { shipments: result.data.GetShipments, pageCount: result.data.GetShipmentsPageCount };
      console.log("searchShipment:" + JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentImages(shipmentGid: number) {
      await initAuthClient();
      // console.log("getShipmentImages");

      let gqlReq = `
            query { 
               GetShipmentImages(
                  shipmentGid: ${shipmentGid}) 
               {
                  id
                  name
                  size
                  type
                  url
                  status
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetShipmentImages;
      // console.log("getShipmentImages:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentStepImages(shipmentGid: String | undefined, stepId: number | undefined) {
      await initAuthClient();
      // console.log("getShipmentStepImages");

      let gqlReq = `
            query { 
               GetStepImages(
                  shipmentGid: "${shipmentGid}",
                  stepId: ${stepId}) 
               {
                  id
                  name
                  size
                  type
                  url
                  status
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetStepImages;
      // console.log("getShipmentStepImages:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentFees(shipmentId: number) {
      await initAuthClient();
      // console.log("getShipmentFees");

      let gqlReq = `
            query { 
               GetFees(
                  ID: ${shipmentId}) 
               {
                  code
                  description
                  count
                  unitPriceBase
                  unitPriceComs
                  unitPrice
                  subTotal
                  feeType
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetFees;
      // console.log("getShipmentFees:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentSteps(shipmentId: number) {
      await initAuthClient();
      // console.log("getShipmentSteps");

      let gqlReq = `
            query { 
               GetSteps(
                  ID: ${shipmentId}) 
               {
                  id
                  code
                  description
                  durationEst
                  duration
                  completedDateEst
                  completedDate
                  signImgUrl
                  remarks
                  status
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetSteps;
      // console.log("getShipmentSteps:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentRoutes(shipmentId: number) {
      await initAuthClient();
      // console.log("getShipmentRoutes");

      let gqlReq = `
            query { 
               GetShipmentRoutes(
                  shipmentGid: "${shipmentId}") 
               {
                  place {
                     id
                     name
                     address
                     lat
                     lng
                  }
                  status
                  completedDate
                  picName
                  picMobile
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetShipmentRoutes;
      // console.log("getShipmentRoutes:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipment(shipmentId?: string) {
      await initAuthClient();
      // console.log("getShipmentDesc: ", shipmentId);

      let gqlReq = `
            query { 
               GetShipment(gid: "${shipmentId}") {
                  id
                  gid
                  bookingCode
                  transportTypeCode
                  transportTypeDesc
                  provider {
                     gid
                     name
                     phone
                     profileImgUrl
                  }
                  transporter {
                     gid
                     name
                     phone
                     profileImgUrl
                  }
                  customer {
                     gid
                     name
                     phone
                     profileImgUrl
                  }
                  carrier {
                     gid
                     id
                     code
                     licenseNo
                     profileImgUrl
                  }
                  payment {
                     type
                     totalCharge
                     discountCode
                     discountType
                     discountValue
                     discountValueCap
                     discountAmount
                     totalPaymentAmount
                     totalProviderAmount
                     totalPlatformAmount
                     bookingPaymentAmount
                     bookingPaymentDate
                     bookingPaymentMethod
                     bookingPaymentRef
                     initialPaymentAmount
                     initialPaymentDate
                     initialPaymentMethod
                     initialPaymentRef
                     finalPaymentAmount
                     finalPaymentDate
                     finalPaymentMethod
                     finalPaymentRef
                     platformPaymentAmount
                     platformPaymentDate
                     platformPaymentRef
                     platformPaymentStatus
                     topDuration
                     planPaymentBooking
                     planPaymentInitial
                     planPaymentFinal
                     planPayoutBooking
                     planPayoutInitial
                     planPayoutFinal
                     bookingPayoutAmount
                     bookingPayoutDate
                     bookingPayoutMethod
                     bookingPayoutRef
                     initialPayoutAmount
                     initialPayoutDate
                     initialPayoutMethod
                     initialPayoutRef
                     finalPayoutAmount
                     finalPayoutDate
                     finalPayoutMethod
                     finalPayoutRef
                     bookingPaymentImgUrl
                     initialPaymentImgUrl
                     finalPaymentImgUrl
                     bookingPayoutImgUrl
                     initialPayoutImgUrl
                     finalPayoutImgUrl
                     status
                  }
                  originDesc
                  destinationDesc
                  deliveryDate
                  picOriginName
                  picOriginMobile
                  picOrderName
                  picOrderMobile
                  rescheduleFromDate
                  rescheduleToDate
                  rescheduleRemarks
                  rescheduleRemarksHistory
                  rescheduleStatus
                  description
                  declineDesc
                  weightEst
                  remarks
                  rating
                  ratingRemarks
                  isReturnShipment
                  isProviderRef
                  distance
                  duration
                  stepStatus
                  status
               }
               GetShipmentImages(
                  shipmentGid: "${shipmentId}") 
               {
                  id
                  name
                  size
                  type
                  url
                  status
               }
               GetFees(
                  shipmentGid: "${shipmentId}") 
               {
                  code
                  description
                  count
                  unitPriceBase
                  unitPriceComs
                  unitPrice
                  subTotal
                  feeType
               }
               GetSteps(
                  shipmentGid: "${shipmentId}") 
               {
                  id
                  code
                  description
                  durationEst
                  duration
                  completedDateEst
                  completedDate
                  signImgUrl
                  remarks
                  status
                  posLat
                  posLng
               }
               GetShipmentRoutes(
                  shipmentGid: "${shipmentId}") 
               {
                  place {
                     id
                     name
                     address
                     lat
                     lng
                  }
                  status
                  completedDate
                  picName
                  picMobile
               }
            }`;

      // console.log("gql query: ", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      console.log("result: ", JSON.stringify(result, null, 2));

      const shipment: Shipment = result ? result.data.GetShipment : null;
      const images: Image[] = result ? result.data.GetShipmentImages : null;
      const fees: Fee[] = result ? result.data.GetFees : null;
      const steps: Step[] = result ? result.data.GetSteps : null;
      const routes: Route[] = result ? result.data.GetShipmentRoutes : null;

      const resp = { shipment: shipment, images: images, fees: fees, steps: steps, routes: routes };

      // console.log("getShipmentDesc:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getShipmentPayment(shipmentId?: string) {
      await initAuthClient();
      // console.log("getShipmentDesc: ", shipmentId);

      let gqlReq = `
            query { 
               GetShipment(gid: "${shipmentId}") {
                  status
                  stepStatus
                  payment {
                     type
                     totalCharge
                     discountCode
                     discountType
                     discountValue
                     discountValueCap
                     discountAmount
                     totalPaymentAmount
                     totalProviderAmount
                     totalPlatformAmount
                     bookingPaymentAmount
                     bookingPaymentDate
                     bookingPaymentMethod
                     bookingPaymentRef
                     initialPaymentAmount
                     initialPaymentDate
                     initialPaymentMethod
                     initialPaymentRef
                     finalPaymentAmount
                     finalPaymentDate
                     finalPaymentMethod
                     finalPaymentRef
                     platformPaymentAmount
                     platformPaymentDate
                     platformPaymentRef
                     platformPaymentStatus
                     topDuration
                     planPaymentBooking
                     planPaymentInitial
                     planPaymentFinal
                     planPayoutBooking
                     planPayoutInitial
                     planPayoutFinal
                     bookingPayoutAmount
                     bookingPayoutDate
                     bookingPayoutMethod
                     bookingPayoutRef
                     initialPayoutAmount
                     initialPayoutDate
                     initialPayoutMethod
                     initialPayoutRef
                     finalPayoutAmount
                     finalPayoutDate
                     finalPayoutMethod
                     finalPayoutRef
                     bookingPaymentImgUrl
                     initialPaymentImgUrl
                     finalPaymentImgUrl
                     bookingPayoutImgUrl
                     initialPayoutImgUrl
                     finalPayoutImgUrl
                     status
                  }
               }
            }`;

      // console.log("gql query: ", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log("result: ", JSON.stringify(result, null, 2));

      const shipment: Shipment = result ? result.data.GetShipment : null;
      const resp = { shipment: shipment };

      // console.log("getShipmentDesc:", JSON.stringify(resp, null, 2));
      return resp;
   }

   async getShipmentStatus(shipmentId?: string) {
      await initAuthClient();
      // console.log('getShipmentDesc: ', shipmentId);

      let gqlReq = `
            query { 
               GetShipment(gid: "${shipmentId}") {
                  status
               }
            }`;

      // console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log('result: ', JSON.stringify(result, null, 2));

      const shipment: Shipment = result ? result.data.GetShipment : null;

      // console.log("getShipmentStatus:", JSON.stringify(result.data.GetShipment, null, 2));

      return shipment.status;
   }

   /*async getPaymentRedirectUrl(shipmentGid?: string) {
      await initAuthClient();

      const response = await fetch(`${Config.LINTAS_API}/payment/${shipmentGid}`, {
         method: 'GET',
      });

      return response.json();
   }*/

   async getPaymentUrl(shipmentId?: string, amount?: number) {
      await initAuthClient();
      // console.log("getPaymentUrl");

      let g = `
            mutation { 
               GetPaymentUrl(
                  shipmentGid: "${shipmentId}",
                  amount: ${amount ? amount : 0})
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let paymentUrl = result.data.GetPaymentUrl;

      // console.log("result:", result.data);

      return paymentUrl;
   }

   async verifyPayment(paymentRefNo?: string) {
      await initAuthClient();
      // console.log("verifyPayment");

      let g = `
            mutation { 
               VerifyPayment(
                  paymentRefId: "${paymentRefNo}")
            }`;

      // console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let shipmentGid = result.data.VerifyPayment;

      // console.log("result:", result.data);

      return shipmentGid;
   }

   async acceptPayment(shipmentGid: string, amount: number, paymentType: string, img?: Image) {
      await initAuthClient();
      console.log("acceptPayment");

      let gImg = img
         ? `
            imgID: ${img?.id},
            imgUrl: "${img?.url}",
         `
         : ``;

      let g = `
            mutation { 
               AcceptPayment(
                  shipmentGid: "${shipmentGid}", 
                  amount: ${amount},
                  paymentType: "${paymentType}",
                  ${gImg}) {
                     type
                     totalCharge
                     discountCode
                     discountType
                     discountValue
                     discountValueCap
                     discountAmount
                     totalPaymentAmount
                     totalProviderAmount
                     totalPlatformAmount
                     bookingPaymentAmount
                     bookingPaymentDate
                     bookingPaymentMethod
                     bookingPaymentRef
                     initialPaymentAmount
                     initialPaymentDate
                     initialPaymentMethod
                     initialPaymentRef
                     finalPaymentAmount
                     finalPaymentDate
                     finalPaymentMethod
                     finalPaymentRef
                     platformPaymentAmount
                     platformPaymentDate
                     platformPaymentRef
                     platformPaymentStatus
                     topDuration
                     planPaymentBooking
                     planPaymentInitial
                     planPaymentFinal
                     planPayoutBooking
                     planPayoutInitial
                     planPayoutFinal
                     bookingPayoutAmount
                     bookingPayoutDate
                     bookingPayoutMethod
                     bookingPayoutRef
                     initialPayoutAmount
                     initialPayoutDate
                     initialPayoutMethod
                     initialPayoutRef
                     finalPayoutAmount
                     finalPayoutDate
                     finalPayoutMethod
                     finalPayoutRef
                     bookingPaymentImgUrl
                     initialPaymentImgUrl
                     finalPaymentImgUrl
                     bookingPayoutImgUrl
                     initialPayoutImgUrl
                     finalPayoutImgUrl
                     status
                  }
            }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let payment = result.data.AcceptPayment;

      // console.log("result:", JSON.stringify(result.data, null, 2));

      return payment;
   }

   async makePayout(shipmentGid: string, amount: number, payoutType: string, img?: Image) {
      await initAuthClient();
      console.log("registerPayout");

      let gImg = img
         ? `
            imgID: ${img?.id},
            imgUrl: "${img?.url}",
         `
         : ``;

      let g = `
            mutation { 
               RegisterPayout(
                  shipmentGid: "${shipmentGid}", 
                  amount: ${amount},
                  payoutType: "${payoutType}",
                  ${gImg}) {
                     type
                     totalCharge
                     discountCode
                     discountType
                     discountValue
                     discountValueCap
                     discountAmount
                     totalPaymentAmount
                     totalProviderAmount
                     totalPlatformAmount
                     bookingPaymentAmount
                     bookingPaymentDate
                     bookingPaymentMethod
                     bookingPaymentRef
                     initialPaymentAmount
                     initialPaymentDate
                     initialPaymentMethod
                     initialPaymentRef
                     finalPaymentAmount
                     finalPaymentDate
                     finalPaymentMethod
                     finalPaymentRef
                     platformPaymentAmount
                     platformPaymentDate
                     platformPaymentRef
                     platformPaymentStatus
                     topDuration
                     planPaymentBooking
                     planPaymentInitial
                     planPaymentFinal
                     planPayoutBooking
                     planPayoutInitial
                     planPayoutFinal
                     bookingPayoutAmount
                     bookingPayoutDate
                     bookingPayoutMethod
                     bookingPayoutRef
                     initialPayoutAmount
                     initialPayoutDate
                     initialPayoutMethod
                     initialPayoutRef
                     finalPayoutAmount
                     finalPayoutDate
                     finalPayoutMethod
                     finalPayoutRef
                     bookingPaymentImgUrl
                     initialPaymentImgUrl
                     finalPaymentImgUrl
                     bookingPayoutImgUrl
                     initialPayoutImgUrl
                     finalPayoutImgUrl
                     status
                  }
            }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let payment = result.data.RegisterPayout;

      // console.log("result:", JSON.stringify(result.data, null, 2));

      return payment;
   }
}

const Service = new ShipmentService();

export default Service;
