import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";

import { useHistory } from 'react-router';
import * as Master from '../../../reference/Master';

import * as CommonHelper from '../../../helper/CommonHelper';

import './Menu.css';

function Menu(props) {
   const history = useHistory();

   const [size] = useState(window.innerWidth < 450 ? ((window.innerWidth - 30) / 3) : window.innerWidth < 500 ? ((window.innerWidth - 30) / 4) : 105);
   const isReschedule = props.id === Master.RESCHEDULE_STATUS_REQUEST_PENDING || props.id === Master.RESCHEDULE_STATUS_REQUEST_APPROVED || props.id === Master.RESCHEDULE_STATUS_REQUEST_REJECT
   const isHide = (props.id === Master.SHIPMENT_STATUS_COMPLAINT || props.id === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED || isReschedule) && !props.count;
   const [count, setCount] = useState(props.count && props.count > 0 ? props.count : undefined);

   const [image, setImage] = useState(Master.IMAGE_TRANSPARENT);

   useEffect(() => {
      if (image === Master.IMAGE_TRANSPARENT) {
         CommonHelper.getImage(process.env.PUBLIC_URL + '/images/' + props.section + '/' + props.id + '.png', setImage)
      }
   }, [image, props.id, props.section]);

   useEffect(() => {
      setCount(props.count && props.count > 0 ? props.count : undefined)
   }, [props.count])

   const onClick = () => {
      history.push(props.action);
      props.setSelectedMenu(props.menu);
   }

   return (
      <div className="home-menu" style={isHide ? { display: 'none' } : { width: size, height: size + 40 }}>
         {image === Master.IMAGE_TRANSPARENT && <Skeleton height={size - 18} width={size - 10} style={{ marginBottom: 5 }}></Skeleton>}
         {image !== Master.IMAGE_TRANSPARENT && <img
            className="image"
            alt={props.id}
            src={image}
            onClick={onClick}
         />}
         {count && <div className={`count ${isReschedule ? 'count-warning' : ''} regular-white-font`} style={count > 99 ? { fontSize: 18 } : {}}>{count === 0 ? '' : count < 100 ? count : '\u221e'}</div>}
         {props.rating > 0 && <div className="count count-rating regular-white-font">{props.rating}</div>}
         <div className="title sub-regular-bold-font">{props.title}</div>
      </div>
   );
}

export default Menu;
