import React from 'react';
import ItWorkItem from './ItWorkItem';

import './ItWork.css';

function ItWork() {
   let itWorks = [
      {
         no: '1',
         title: 'Booking',
         desc: 'Pilih dan booking angkutan sesuai kebutuhan',
      },
      {
         no: '2',
         title: 'Konfirmasi',
         desc: 'Konfirmasi mitra Lintas menerima permintaan angkutan',
      },
      {
         no: '3',
         title: 'Pembayaran',
         desc: 'Pembayaran biaya sesuai tipe angkutan dan jarak',
      },
      {
         no: '4',
         title: 'Pengiriman',
         desc: 'Pengiriman kargo anda dari tempat asal ke tujuan',
      },
   ];

   return (
      <div className="it-work">
         <div className="it-work-content">
            <div className="it-work-title">Cara mudah mendapatkan solusi angkutan anda</div>
            <div className="it-work-description">
               Hanya dengan beberapa langkah
            </div>
            <div className="it-work-line" />
            <div className="it-work-items">
               {itWorks.map((itWork, i) => (
                  <ItWorkItem itWork={itWork} key={i} id={i} />
               ))}
            </div>
         </div>
      </div>
   );
}

export default ItWork;
