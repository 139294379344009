import moment from "moment";
import "moment/locale/id";
moment.locale("id");

export function parse(dateStr: string) {
   try {
      let date = new Date(Date.parse(dateStr));
      return date;
   } catch (err) {
      console.error(err);
      return null;
   }
}

export function getDayDateMonth(date: Date) {
   return moment(date).format("dddd, DD MMMM");
}

export function getDayDateMonthYear(date: Date) {
   return moment(date).format("dddd, D MMMM YYYY");
}

export function getDayDateMonthTime(date: Date | undefined) {
   if (undefined) {
      return "";
   }
   return moment(date).format("dddd, DD MMM YYYY HH:mm");
}

export function getDateMonthYear(date: Date) {
   return moment(date).format("DD-MM-YYYY");
}

export function getDateShortMonthYear(date: Date) {
   return moment(date).format("DD MMM YYYY");
}

export function getDateMonthYearFromJsonStr(dateStr: any) {
   return moment(parse(dateStr)).format("DD-MM-YYYY");
}

export function getTime(date: Date) {
   return moment(date).format("HH:mm");
}

export function getDayTime(date: Date) {
   const h = parseInt(moment(date).format("H"));
   const daytime = h < 12 ? "Pagi" : h < 15 ? "Siang" : h < 18 ? "Sore" : "Malam";
   return moment(date).format("hh:mm") + " " + daytime;
}

export function getDay(date: Date) {
   //console.log("getDay: " + date);
   return moment(date).format("dddd");
}

export function getHour(date: Date) {
   return parseInt(moment(date).format("H"));
}

export function getMinute(date: Date) {
   return parseInt(moment(date).format("m"));
}

export function getDateMonth(date: Date) {
   return moment(date).format("DD MMMM");
}

export function getDateShortMonth(date: Date) {
   return moment(date).format("DD MMM");
}

export function getDateMonthTime(date: Date | undefined) {
   //console.log("getDateMonthTime: " + date);
   if (date) {
      return moment(date).format("DD MMM HH:mm");
   } else {
      return "";
   }
}

export function dateSetHourMinute(date: Date, hour: number, minute: number) {
   //console.log('dateSetHourMinute: date: ' + date + ', hour: ' + hour + ', minute: ' + minute);
   let newDate = new Date(new Date(date).setHours(0, 0, 0, 0));
   newDate = new Date(newDate.getTime() + (hour * 3600 * 1000 + minute * 60 * 1000));
   return newDate;
}

export function getToday() {
   let today = new Date(new Date().setHours(0, 0, 0, 0));
   return today;
}

export function getEarliestDeliveryDate(date: Date): Date {
   let today = new Date();
   let gapMins = (date.getTime() - today.getTime()) / (60 * 1000);
   //console.log('difference: ' + gapMins + ' mins');

   if (gapMins < 30) {
      let i = 1;
      let newDate = new Date();
      newDate.setHours(today.getHours(), 0, 0, 0);
      gapMins = (newDate.getTime() - today.getTime()) / (60 * 1000);
      //console.log('difference: ' + gapMins + ' msecs');

      while (gapMins < 30) {
         //console.log('newDate: ' + getDayDateMonthTime(newDate));
         newDate.setHours(today.getHours(), i * 15, 0, 0);
         gapMins = (newDate.getTime() - today.getTime()) / (60 * 1000);
         //console.log('difference: ' + gapMins + ' msecs');
         i++;
      }
      return newDate;
   } else {
      return date;
   }
}

export function isSameDay(date1: Date, date2: Date) {
   date1 = dateSetHourMinute(date1, 0, 0);
   date2 = dateSetHourMinute(date2, 0, 0);
   return date1.getTime() === date2.getTime();
}

export const dateFormat = require("dateformat");
dateFormat.i18n = {
   dayNames: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
   monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
   /*timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],*/
};
