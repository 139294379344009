import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { TransporterService, ShipmentService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import SearchInput from "../common/SearchInput";
import SubNavigation from "../common/SubNavigation";
import { TransporterView } from "../common";

import * as SearchHelper from "../../helper/SearchHelper";

import { Transporter } from "../../model";

import * as TransporterRef from "../../reference/Transporter";
import * as Master from "../../reference/Master";

import "./SelectTransporter.css";

let modalContext: ModalContextInterface;

export default function SelectTransporter(props: any) {
   const history = useHistory();
   const [search, setSearch] = useState("");
   const [transporters, setTransporters] = useState<Transporter[]>(TransporterRef.getEmptyTransporters(10));

   useEffect(() => {
      if (props.show) {
         searchTransporter(search);
      }
   }, [search, props.show]);

   const searchTransporter = (input: any) => {
      SearchHelper.searchInput(input, () => {
         let transporter = new Transporter();
         transporter.name = input;
         transporter.status = Master.STATUS_VERIFIED;
         TransporterService.searchTransporter(transporter, 10, 1).then((result) => {
            setTransporters(result.transporters);
         });
      });
   };

   const onSelectTransporter = (transporter: any) => {
      console.log("selectTransporter: ", JSON.stringify(transporter, null, 2));
      AlertHelper.alertLoading("Menyimpan data pengemudi", modalContext);

      ShipmentService.assignShipmentTransporter(props.shipment.gid, transporter.gid)
         .then((result) => {
            props.onSelectTransporter(transporter);
            props.shipment.transporter = transporter;
            const carrier = transporter.carrier;
            if (carrier) {
               ShipmentService.assignShipmentCarrier(props.shipment.gid, carrier.gid)
                  .then((result) => {
                     props.onSelectCarrier(carrier);
                     props.setShow(false);
                     AlertHelper.alertSuccess("Proses berhasil", modalContext);
                  })
                  .catch((error: Error) => {
                     ErrorService.handleError(error, history, modalContext);
                  });
            } else {
               props.setShow(false);
               AlertHelper.alertSuccess("Proses berhasil", modalContext);
            }
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onClickPrev = () => {
      props.setShow(false);
   };
   const onClickClose = () => {
      props.setShow(false);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               props.show && (
                  <div className="select-transporter">
                     <div className="select-transporter-container">
                        <SubNavigation title="Pilih Pengemudi" onClickPrev={onClickPrev} onClickClose={onClickClose} />
                        <div className="select-transporter-search dark-border-bottom-side">
                           <div className="select-transporter-search-input">
                              <SearchInput
                                 transporterholder="Pengemudi"
                                 onChangeText={(text: any) => {
                                    //searchTransporter(text);
                                    setSearch(text);
                                 }}
                                 style={{ marginBottom: 5 }}
                              />
                           </div>
                           <div className="select-transporter-search-result">
                              {transporters.map((transporter: Transporter, key: number) => (
                                 <TransporterView key={key} transporter={transporter} onPressMore={(transporter: any) => onSelectTransporter(transporter)} />
                              ))}
                           </div>
                        </div>
                     </div>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
