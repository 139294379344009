import {Carrier} from '../model';

export const newCarrier: Carrier = new Carrier();

export const getEmptyCarriers = (count: number) => {
   const carriers = Array<Carrier>();
   for (let i = 0; i < count; i++) {
      carriers.push(newCarrier);
   }
   return carriers;
};
