import React from 'react';
import StoryItem from './StoryItem';

import 'react-multi-carousel/lib/styles.css';

import './Story.css';

function Story(props) {
  const stories = [
    {
      title: 'Tentang kami, apa yang perlu anda tahu',
      type: 'read',
      length: 'Bacaan 2 menit',
      imageUrl: 'story/story-2.jpg',
    },
    {
      title: 'Butuh angkutan logistik? dapatkan solusi dengan mudah',
      type: 'read',
      length: 'Bacaan 3 menit',
      imageUrl: 'story/story-1.jpg',
    },
    {
      title: 'Optimisasi rute dan muatan untuk efisiensi biaya',
      type: 'read',
      length: 'Bacaan 5 menit',
      imageUrl: 'story/story-3.jpg',
    },
  ];

  return (
    <div className="category-story">
      {stories.map((story, i) => (
        <StoryItem story={story} key={i} />
      ))}
    </div>
  );
}

export default Story;
