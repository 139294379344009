import React, { useState } from 'react';
import { Input, AutoComplete } from 'antd';

import { FiBox, FiPackage } from 'react-icons/fi';
import { GiWeight } from 'react-icons/gi';


import * as Category from '../../reference/Category';
import * as TransportType from '../../reference/TransportType';

import './SearchBar.css';

const renderTitle = (title) => (
   <span className="search-bar-category">{title}</span>
);

const renderTransportType = (transportType) => (
   <div>
      <div className='search-bar-result'>
         <img
            alt="Transport Type"
            src={process.env.PUBLIC_URL + '/images' + transportType.image}
            className='search-bar-result-image'
         />
         <div className='search-bar-result-content'>
            <div
               style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  float: 'left',
               }}
            >
               <div className="search-bar-result-description">{transportType.desc}</div>
               <div style={{ display: 'flex' }}>
                  <div className="search-bar-result-more-info-section">
                     <div className="search-bar-result-more-info-title">
                        <FiBox className="search-bar-result-more-info-icon" />
                    Kapasitas P x L x T
                  </div>
                     <div className="search-bar-result-more-info-content">{transportType.dimension}</div>
                  </div>
                  <div className="search-bar-result-more-info-section">
                     <div className="search-bar-result-more-info-title">
                        <FiPackage className="search-bar-result-more-info-icon" />
                    Volume
                  </div>
                     <div className="search-bar-result-more-info-content">{transportType.bcm}</div>
                  </div>
                  <div className="search-bar-result-more-info-section">
                     <div className="search-bar-result-more-info-title">
                        <GiWeight className="search-bar-result-more-info-icon" />
                    Berat
                  </div>
                     <div className="search-bar-result-more-info-content">{transportType.capacity}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
);

const renderOption = (type, option) => ({
   value:
      type === 'TransportType'
         ? option.code
         : option.code,
   label: (
      <div
         style={{
            display: 'flex',
            justifyContent: 'flex-start',
         }}
      >
         {renderTransportType(option)}
      </div>
   ),
});

const searchResult = (value) => {

   let results = [];

   for (var i = 0; i < Category.categories.length; i++) {
      let category = Category.categories[i];
      console.log('category: ', JSON.stringify(category));
      const categoryTransportTypes = TransportType.transportTypes.filter((transportType) =>
         new RegExp(category.code, 'i').test(transportType.category.code)
      );
      const searchTransportTypes = categoryTransportTypes.filter((transportType) =>
         new RegExp(value.replaceAll(" ", ""), 'i').test(transportType.desc.replaceAll(" ", ""))
      );
      if (searchTransportTypes.length > 0) {
         const transportTypeOptions = searchTransportTypes.map((transportType) =>
            renderOption('TransportType', transportType)
         );
         results = [...results, { label: renderTitle(category.desc), options: transportTypeOptions }];
      }
   }

   return results;
};

const SearchBar = () => {
   const [options, setOptions] = useState([]);

   const onSearch = (value) => {
      setOptions(value ? searchResult(value.replace(/\W/g, '')) : []);
   };

   const onSelect = (value) => {
      console.log('onSelect', value);
   };

   return (
      <div className="search-bar">
         <AutoComplete
            dropdownMatchSelectWidth={500}
            style={{
               width: 150,
            }}
            options={options}
            onSearch={onSearch}
            onSelect={onSelect}
         >
            <Input.Search
               size="large"
               placeholder="Cari moda angkutan"
            />
         </AutoComplete>
      </div>
   );
};

export default SearchBar;
