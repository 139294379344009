import * as Master from "./Master";

export class ShipmentStatus {
   code: string;
   desc: string;
   count: number;
}

export class Action {
   code: string;
   desc: string;
   menu: { title: string };
   action: string;
}

export const customerShipmentStatus: ShipmentStatus[] = [
   {
      code: "BOOKING",
      desc: "Pesan Angkutan",
      count: 0,
   },
   {
      code: "NEWORDER",
      desc: "Menunggu Konfirmasi",
      count: 0,
   },
   {
      code: "PENDINGPY",
      desc: "Menunggu Pembayaran",
      count: 0,
   },
   {
      code: "CONFRMPY",
      desc: "Antrian Pengiriman",
      count: 0,
   },
   {
      code: "DLVRPRGS",
      desc: "Proses Pengiriman",
      count: 0,
   },
   {
      code: "DLVRCOMP",
      desc: "Pengiriman Sampai",
      count: 0,
   },
   {
      code: "COMPLNT",
      desc: "Proses Keluhan",
      count: 0,
   },
   {
      code: "COMPLNTRSD",
      desc: "Keluhan Selesai",
      count: 0,
   },
   {
      code: "DLVRCONF",
      desc: "Pengiriman Selesai",
      count: 0,
   },
   {
      code: "REQPDG",
      desc: "Reschedule Pending",
      count: 0,
   },
   {
      code: "REQAPR",
      desc: "Reschedule Diterima",
      count: 0,
   },
   {
      code: "REQRJC",
      desc: "Reschedule Ditolak",
      count: 0,
   },
   {
      code: Master.PARTNER_SUMMARY_RATING,
      desc: "Rating",
      count: 0,
   },
];

export const providerShipmentStatus: ShipmentStatus[] = [
   {
      code: "NEWORDER",
      desc: "Menunggu Konfirmasi",
      count: 0,
   },
   {
      code: "PENDINGPY",
      desc: "Menunggu Pembayaran",
      count: 0,
   },
   {
      code: "CONFRMPY",
      desc: "Antrian Pengiriman",
      count: 0,
   },
   {
      code: "DLVRPRGS",
      desc: "Proses Pengiriman",
      count: 0,
   },
   {
      code: "DLVRCOMP",
      desc: "Pengiriman Sampai",
      count: 0,
   },
   {
      code: "DLVRCONF",
      desc: "Pengiriman Selesai",
      count: 0,
   },
   {
      code: "COMPLNT",
      desc: "Proses Keluhan",
      count: 0,
   },
   {
      code: "COMPLNTRSD",
      desc: "Keluhan Selesai",
      count: 0,
   },
   {
      code: "TRANSPY",
      desc: "Pembayaran Selesai",
      count: 0,
   },
   {
      code: "REQPDG",
      desc: "Reschedule Pending",
      count: 0,
   },
   {
      code: "REQAPR",
      desc: "Reschedule Diterima",
      count: 0,
   },
   {
      code: "REQRJC",
      desc: "Reschedule Ditolak",
      count: 0,
   },
   {
      code: Master.PARTNER_SUMMARY_RATING,
      desc: "Rating",
      count: 0,
   },
];

export const transporterShipmentStatus: ShipmentStatus[] = [
   {
      code: "CONFRMPY",
      desc: "Antrian Pengiriman",
      count: 0,
   },
   {
      code: "DLVRPRGS",
      desc: "Proses Pengiriman",
      count: 0,
   },
   {
      code: "DLVRCOMP",
      desc: "Pengiriman Sampai",
      count: 0,
   },
   {
      code: "COMPLNT",
      desc: "Proses Keluhan",
      count: 0,
   },
   {
      code: "COMPLNTRSD",
      desc: "Keluhan Selesai",
      count: 0,
   },
   {
      code: Master.PARTNER_SUMMARY_RATING,
      desc: "Rating",
      count: 0,
   },
];

export const adminShipmentStatus = providerShipmentStatus;

export const customerActions: Action[] = [
   {
      code: Master.ACTION_ADD_SHIPMENT,
      desc: "Tambah Pengiriman",
      menu: { title: "Administrasi" },
      action: "/booking",
   },
];

export const providerActions: Action[] = [
   {
      code: Master.ACTION_ADMIN_CARRIER,
      desc: "Kendaraan",
      menu: { title: "Administrasi" },
      action: "/admin/carrier",
   },
   {
      code: Master.ACTION_ADMIN_TRANSPORTER,
      desc: "Transporter",
      menu: { title: "Administrasi" },
      action: "/admin/transporter",
   },
   {
      code: Master.ACTION_MANAGE_EXPENSE,
      desc: "Layanan",
      menu: { title: "Administrasi" },
      action: "/admin/service",
   },
   {
      code: Master.ACTION_ANALYTIC_TREND,
      desc: "Analisa Trend",
      menu: { title: "Analisa" },
      action: "/analytic/trend",
   },
   {
      code: Master.ACTION_ANALYTIC_PIE,
      desc: "Analisa Pie",
      menu: { title: "Analisa" },
      action: "/analytic/pie",
   },
];
