import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import mixpanel from "mixpanel-browser";
import { Select } from "antd";

import { AdsProvider } from "../home/ads";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { InputSearch, SectionIcon, PageView } from "../common";

import { CacheService, LeadService, ErrorService, ReferenceService } from "../../service";

import * as SearchHelper from "../../helper/SearchHelper";

import { Category, TransportType, Search, Lead } from "../../model";

import { getEmptyLeads } from "../../reference/Lead";
import { categorySet, fullCategorySet } from "../../reference/Category";
import { transportTypes, transportTypeSet } from "../../reference/TransportType";

import * as Master from "../../reference/Master";
import * as Config from "../../Config";

import "./SearchLead.css";

let modalContext: ModalContextInterface;

export default function SearchLead(props: any) {
   const history = useHistory();

   const pageSize = 25;
   const [init, setInit] = useState(false);
   const [leads, setLeads] = useState(getEmptyLeads(pageSize));
   const [categoryCode, setCategoryCode] = useState("");
   const [categoryDesc, setCategoryDesc] = useState("");
   const [categoryOptions, setCategoryOptions] = useState(new Array());
   const [transportTypeCode, setTransportTypeCode] = useState("");
   const [transportTypeDesc, setTransportTypeDesc] = useState("");
   const [transportTypeOptions, setTransportTypeOptions] = useState(new Array());
   const [originCity, setOriginCity] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      const categories = Array.from(fullCategorySet.values());
      const options = categories.map((category: Category) => ({
         value: category.code,
         label: category.desc,
      }));
      setCategoryOptions(options);
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      const transTypes = transportTypes.filter((transportType: TransportType) => new RegExp(categoryCode, "i").test(transportType.code));
      const options = transTypes.map((transportType: TransportType) => ({
         value: transportType.code,
         label: transportType.desc,
      }));
      setTransportTypeOptions(options);
   }, [categoryCode]);

   useEffect(() => {
      if (!init) {
         if (transportTypes.length === 0) {
            ReferenceService.initTransportTypes().then((result) => {
               const transTypes = transportTypes.filter((transportType: TransportType) => new RegExp(categoryCode, "i").test(transportType.code));
               const options = transTypes.map((transportType: TransportType) => ({
                  value: transportType.code,
                  label: transportType.desc,
               }));
               setTransportTypeOptions(options);
            });
         }
         setInit(true);
      }
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [init, categoryCode, props]);

   useEffect(() => {
      setLeads(getEmptyLeads(pageSize));
      SearchHelper.searchInputs(
         [originCity],
         () => {
            LeadService.searchLead(transportTypeCode, originCity, pageSize, page)
               .then((result) => {
                  setLeads(result.searchLead);
                  if (page === 1) {
                     setPageCount(result.pageCount);
                  }
                  if (result.searchLead && result.searchLead.length === 0) {
                     onContactWa();
                  }
               })
               .catch((error) => {
                  ErrorService.handleError(error, history, modalContext);
               });
         },
         3000
      );
   }, [categoryCode, transportTypeCode, originCity, page, history]);

   useEffect(() => {
      setPage(1);
   }, [categoryCode, transportTypeCode, originCity]);

   const onPressMore = (lead: Lead) => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Select Muatan");

      window.open(
         "https://wa.me/" + Master.MOBILE_LINTAS_PARTNERSHIP + "?text=" + encodeURI("Halo Lintas, saya tertarik memberikan layanan angkutan. Jenis angkutan " + lead.transportTypeDesc + " dari " + lead.origin + " ke " + lead.destination) + "  %23" + encodeURI(lead.reference.replaceAll("#", ""))
      );
      // console.log(lead);
   };

   const onContactWa = () => {
      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Whatsapp Admin - Shipment Not Found");

      window.open("https://wa.me/" + Master.MOBILE_LINTAS_PARTNERSHIP + "?text=" + encodeURI("Halo Lintas, mohon bantuan untuk mencarikan muatan " + (transportTypeDesc ? transportTypeDesc : categoryDesc) + originCity));
   };

   const onSelectCategory = (value: any, event: any) => {
      setCategoryCode(value || "");
      setCategoryDesc(categorySet.get(value)?.desc || "");
   };

   const onSelectTransportType = (value: any, event: any) => {
      setTransportTypeCode(value || "");
      setTransportTypeDesc(transportTypeSet.get(value)?.desc || "");
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <div className="home">
                     <div className="home-content">
                        <AdsProvider {...props} />
                        <div className="search-lead">
                           <div className={window.innerWidth < 1000 ? "search-lead-header regular-bottom-border" : "search-lead-header"}>
                              <div className="search-lead-header-title regular-bold-font">Pelanggan mencari angkutan berikut</div>
                              {/*
                              <div className="search-lead-header-search">
                                 <div className="search-lead-header-search-section">
                                    <SectionIcon truck className="search-lead-header-search-icon" />
                                    <div className="search-lead-header-search-input">
                                       <Select className="search-lead-header-search-select" placeholder="Kategori" options={categoryOptions} onChange={onSelectCategory} allowClear />
                                    </div>
                                 </div>
                                 <div className="search-lead-header-search-section">
                                    <SectionIcon truck className="search-lead-header-search-icon" />
                                    <div className="search-lead-header-search-input">
                                       <Select className="search-lead-header-search-select" placeholder="Tipe Angkutan" options={transportTypeOptions} onChange={onSelectTransportType} allowClear />
                                    </div>
                                 </div>
                                 <div className="search-lead-header-search-section">
                                    <SectionIcon home className="search-lead-header-search-icon" />
                                    <div className="search-lead-header-search-input">
                                       <InputSearch placeholder="Kota Asal Muatan" value={originCity} setValue={setOriginCity} />
                                    </div>
                                 </div>
                              </div>
                              */}
                           </div>
                           <div id="search-result" className={"search-result"}>
                              <PageView
                                 leads={leads}
                                 onPressMore={(lead: Lead) => {
                                    console.log("search:", JSON.stringify(lead, null, 2));
                                    onPressMore(lead);
                                 }}
                                 page={page}
                                 setPage={setPage}
                                 pageCount={pageCount}
                                 emptyText={!categoryCode && !transportTypeCode && !originCity && "Belum terdapat muatan"}
                              />
                           </div>
                        </div>
                     </div>
                  </div>                  
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
