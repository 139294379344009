export const fontFamily = {
   //fontFamily: 'MuseoSans-Regular',
   fontWeight: 600,
};

export const fontFamilyBold = {
   fontFamily: 'Poppins-Bold',
   fontWeight: 500,
};

export const fontColorDark = {
   color: '#555',
};

export const fontColorGrey = {
   color: '#999',
};

export const fontColorWhite = {
   color: '#fff',
};

export const fontColorPurple = {
   color: '#8393ca',
};

export const fontColorGreen = {
   color: '#02a29b',
};

export const fontSizeSuper = {
   fontSize: 17,
};

export const fontSizeBold = {
   fontSize: 15,
};

export const fontSizeBig = {
   fontSize: 15,
};

export const fontSizeRegular = {
   fontSize: 13,
};

export const fontSizeSubRegular = {
   fontSize: 12,
};

export const fontSizeSmall = {
   fontSize: 11,
};

export const fontSizeTiny = {
   fontSize: 9,
};

export const superBoldFont = {
   ...fontFamilyBold,
   fontSize: 16,
   color: '#666',
};

export const superFont = {
   ...fontFamily,
   ...fontSizeSuper,
   color: '#666',
};

export const boldFont = {
   ...fontFamilyBold,
   ...fontSizeBold,
   color: '#666',
};

export const boldWhiteFont = {
   ...fontFamilyBold,
   ...fontSizeRegular,
   color: '#fff',
};

export const bigFont = {
   ...fontFamily,
   ...fontSizeBig,
   color: '#666',
};

export const bigGreyFont = {
   ...fontFamily,
   ...fontSizeBig,
   ...fontColorGrey,
};

export const regularBoldFont = {
   ...fontFamilyBold,
   ...fontSizeRegular,
   color: '#666',
};

export const regularBoldPurpleFont = {
   ...fontFamilyBold,
   ...fontSizeRegular,
   ...fontColorPurple,
};

export const regularFont = {
   ...fontFamily,
   ...fontSizeRegular,
   ...fontColorDark,
};

export const regularGreyFont = {
   ...fontFamily,
   ...fontSizeRegular,
   ...fontColorGrey,
};

export const regularWhiteFont = {
   ...fontFamily,
   ...fontSizeRegular,
   ...fontColorWhite,
};

export const regularPurpleFont = {
   ...fontFamily,
   ...fontSizeRegular,
   ...fontColorPurple,
};

export const subRegularFont = {
   ...fontFamily,
   ...fontSizeSubRegular,
   ...fontColorDark,
};

export const subRegularWhiteFont = {
   ...fontFamily,
   ...fontSizeSubRegular,
   ...fontColorWhite,
};

export const subRegularGreyFont = {
   ...fontFamily,
   ...fontSizeSubRegular,
   ...fontColorGrey,
};

export const subRegularPurpleFont = {
   ...fontFamily,
   ...fontSizeSubRegular,
   ...fontColorPurple,
};

export const smallBoldFont = {
   ...fontFamilyBold,
   ...fontSizeSmall,
   ...fontColorDark,
};

export const smallFont = {
   ...fontFamily,
   ...fontSizeSmall,
   ...fontColorDark,
};

export const smallGreyFont = {
   ...fontFamily,
   ...fontSizeSmall,
   ...fontColorGrey,
};

export const tinyFont = {
   ...fontFamily,
   ...fontSizeTiny,
   ...fontColorDark,
};
