import React, {useEffect, useState} from 'react';
import {Input} from 'antd';
import {SimpleButton} from '.';

import './InputSearch.css';

export default function InputSearch(props: any) {
   const [value, setValue] = useState(props.value);

   const onClear = () => {
      setValue('');
   };

   useEffect(() => {
      props.setValue(value);
   }, [props, value]);

   return (
      <div className="search-input dark-border">
         <Input placeholder={props.placeholder} value={value} onFocus={props.onFocus} onBlur={props.onBlur} onChange={(e: any) => setValue(e.target.value)} />
         {!value ? <SimpleButton search textStyle={{color: '#8393ca', fontSize: 20}} /> : <SimpleButton clear textStyle={{color: '#8393ca', fontSize: 20}} onClick={onClear} />}
      </div>
   );
}
