import { User, Customer, Transporter, Provider, Place } from "../model";

export function graphToUser(grUser: any, role: string) {
   if (!grUser) {
      return undefined;
   }

   const user: User = new User();
   user.id = grUser.id;
   user.gid = grUser.gid;
   user.name = grUser.name;
   user.email = grUser.email;
   user.mobile = grUser.mobile;
   user.isAdmin = grUser.isAdmin;
   user.role = role;

   if (grUser.customer) {
      user.customer = graphToCustomer(grUser.customer);
   }

   if (grUser.provider) {
      user.provider = graphToProvider(grUser.provider);
   }

   if (grUser.transporter) {
      user.transporter = graphToTransporter(grUser.transporter);
   }

   return user;
}

export function graphToCustomer(grCustomer: any): Customer {
   const customer: Customer = new Customer();
   customer.id = grCustomer.id;
   customer.gid = grCustomer.gid;
   customer.name = grCustomer.name;
   customer.profileImgUrl = grCustomer.profileImgUrl;
   customer.type = grCustomer.type;
   customer.phone = grCustomer.phone;
   customer.level = grCustomer.level;
   customer.rating = grCustomer.rating;

   return customer;
}

export function graphToProvider(grProvider: any): Provider {
   const provider: Provider = new Provider();
   provider.id = grProvider.id;
   provider.gid = grProvider.gid;
   provider.refId = grProvider.refId;
   provider.name = grProvider.name;
   provider.profileImgUrl = grProvider.profileImgUrl;
   provider.type = grProvider.type;
   provider.phone = grProvider.phone;
   provider.email = grProvider.email;
   provider.level = grProvider.level;
   provider.rating = grProvider.rating;

   if (grProvider.location) {
      const location = new Place();
      location.name = grProvider.location.name;
      location.address = grProvider.location.address;
      location.lat = grProvider.location.lat;
      location.lng = grProvider.location.lng;
   }

   return provider;
}

export function graphToTransporter(grTransporter: any): Transporter {
   const transporter: Transporter = new Transporter();
   transporter.id = grTransporter.id;
   transporter.gid = grTransporter.gid;
   transporter.name = grTransporter.name;
   transporter.dob = grTransporter.dob;
   transporter.email = grTransporter.email;
   transporter.citizenCardNo = grTransporter.citizenCardNo;
   transporter.drivingLicenseNo = grTransporter.drivingLicenseNo;
   transporter.profileImgUrl = grTransporter.profileImgUrl;
   transporter.phone = grTransporter.phone;

   return transporter;
}

export function jsonStrToStringParam(str: any): string {
   let output = str.replaceAll("\\", "\\\\");
   output = output.replaceAll('"', '\\"');

   return output;
}

export function respToJsonString(str: any): string {
   let output = str.replaceAll(String.fromCharCode(92), "");
   // console.log("respToJsonString: ", output);
   return output;
}
