import { gql } from "@apollo/client";
import { CacheService } from ".";
import { graphClient, authGraphClient, initClient, initAuthClient } from "./GraphService";

import * as GraphHelper from "../helper/GraphHelper";

class AuthService {
   async sendOtp(mobile: string, role: string) {
      await initClient();
      CacheService.setMobile(mobile);

      let q = `
         mutation {
            SendOtp(mobile: "${mobile}", role: "${role}") {
               key
               expiryPeriod
            }
        }`;

      // console.log("gql: ", q);

      let result = await graphClient.mutate({
         mutation: gql`
            ${q}
         `,
      });

      // console.log(result.data);
      CacheService.setAuth(result.data.SendOtp);
      return result.data;
   }

   async authenticateOtp(otpKey: string, otpToken: string, role: string) {
      await initClient();
      let q = `
            mutation {
               AuthenticateOtp(otpKey: "${otpKey}", otpToken: "${otpToken}", role: "${role}") {
                  token
                  refreshToken
                  tokenValidity
                  user {
                     id
                     gid
                     name
                     email
                     mobile
                     isAdmin
                     customer {
                        id
                        name
                        profileImgUrl
                        gid
                        type
                     }
                     provider {
                        id
                        name
                        profileImgUrl
                        gid
                        refId
                     }
                     transporter {
                        id
                        gid
                     }
                  }
               }
            }`;

      // console.log("gql: ", q);

      let result = await graphClient.mutate({
         mutation: gql`
            ${q}
         `,
      });

      // console.log("result auth: ", JSON.stringify(result.data.AuthenticateOtp, null, 2));
      return result.data.AuthenticateOtp;
   }

   async authenticate(key: string, role: string) {
      await initAuthClient();
      let q = `
            mutation {
               Authenticate(key: "${key}", role: "${role}") {
                  token
                  refreshToken
                  user {
                     id
                     gid
                     name
                     email
                     mobile
                     isAdmin
                     customer {
                        id
                        gid
                        type
                     }
                     provider {
                        id
                        gid
                     }
                     transporter {
                        id
                        gid
                     }
                  }
               }
            }`;

      //console.log("gql: ", q);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${q}
         `,
      });

      let auth = result.data.Authenticate;
      CacheService.setToken({ token: auth.token, refreshToken: auth.refreshToken });
      //console.log("authenticate", JSON.stringify(auth, null, 2));

      let user = GraphHelper.graphToUser(auth.user, role);

      CacheService.setUser(user);

      //console.log(result.data);
      return result.data;
   }

   async getSudoToken(gid: string, role: string) {
      await initAuthClient();
      let q = `
            mutation {
               GetSudoToken(gid: "${gid}", role: "${role}") {
                  token
                  refreshToken
                  user {
                     id
                     gid
                     name
                     email
                     mobile
                     isAdmin
                     customer {
                        id
                        name
                        profileImgUrl
                        gid
                        type
                     }
                     provider {
                        id
                        name
                        profileImgUrl
                        gid
                        refId
                     }
                     transporter {
                        id
                        gid
                     }
                  }
               }
            }`;

      // console.log("gql: ", q);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${q}
         `,
      });

      return result.data.GetSudoToken;
   }

   logout() {
      CacheService.setToken(undefined);
      CacheService.setUser(undefined);
   }
}

const Service = new AuthService();

export default Service;
