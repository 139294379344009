import React from 'react';
import { Button } from 'antd';
import { RiScissorsCutLine } from 'react-icons/ri';
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";

import * as Config from "../../../Config";

import './Promo.css';

function Promo() {

   const onGetPromo = data => {
      window.open("http://bit.ly/DiskonKirimMuatanRutin");

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event Get Promo");

      // gtm();
   };

   const gtm = () => {
      // gtm conversion
      const tagManagerArgs = {
         gtmId: "GTM-MW67KPL",
      };
      TagManager.initialize(tagManagerArgs);
   };

   return (
      <div className="promo">
         <div className="promo-content">
            <div className="promo-title">Promo Spesial</div>
            <div className="promo-description">
               Daftarkan kebutuhan angkutan anda minimal H-1 sebelum pengiriman dan dapatkan promo harga angkutan hingga 50% dari harga normal dengan menggunakan angkutan balikan yang lebih hemat
            </div>
            <Button
               className="promo-btn"
               type="primary"
               icon={<RiScissorsCutLine style={style.icon} />}
               size="large"
               onClick={onGetPromo}
            >
               Dapatkan Harga Promo
            </Button>
         </div>
      </div>
   );
}

const style = {
   icon: {
      fontSize: 17, position: 'relative', top: 2, marginRight: 7
   }
}

export default Promo;
