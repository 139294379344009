import React, { useState, useEffect } from "react";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";
import { FadeView } from "../common";
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { AnalyticService, ErrorService } from "../../service";
import { useHistory } from "react-router";

import * as Color from "../../constants/Color";

import "./PieAnalytic.css";

let modalContext: ModalContextInterface;

function PieAnalytic(props: any) {
   const history = useHistory();
   const [init, setInit] = useState(false);
   const [dataFleet, setDataFleet] = useState<any[]>();
   const [dataTransportType, setDataTransportType] = useState<any[]>();
   const [dataSitesFleet, setDataSitesFleet] = useState<any[]>();
   const [dataSitesTransport, setDataSitesTransport] = useState<any[]>();
   const [dataTO1, setDataTO1] = useState<any[]>();
   const [dataTO2, setDataTO2] = useState<any[]>();
   const [dataOriginDestinations, setDataOriginDestinations] = useState<any[]>();

   const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

   useEffect(() => {
      //props.setShowBottomNavigation(true);
      props.setShowBackNavigation(true);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [props]);

   useEffect(() => {
      AnalyticService.GetServicesAnalytic("fleet")
         .then((result) => {
            setDataFleet(result);
            // console.log("result fleet: ", result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetServicesAnalytic("transport")
         .then((result) => {
            setDataTransportType(result);
            // console.log("result transport: ", result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetSitesCarriersAnalytic("fleet")
         .then((result) => {
            setDataSitesFleet(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetSitesCarriersAnalytic("transport")
         .then((result) => {
            setDataSitesTransport(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetTransportOriginAnalytic("all")
         .then((result) => {
            setDataTO1(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetTransportOriginAnalytic("only false")
         .then((result) => {
            setDataTO2(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetOriginDestinationsAnalytic()
         .then((result) => {
            setDataOriginDestinations(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [history]);

   const RADIAN = Math.PI / 180;
   const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }: any) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
         <text x={x} y={y} fill={COLORS[index % COLORS.length]} textAnchor="middle" dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}% (${value})`}
         </text>
      );
   };

   const ColoredLine = ({ color }: any) => (
      <hr
         style={{
            color: color,
            backgroundColor: color,
            marginTop: 20,
            marginBottom: 20,
            opacity: 0.25,
         }}
      />
   );

   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <FadeView show={true} duration="0" className="pie-analytic-fadeview">
                  <div className="pie-analytic-container">
                     <div className="pie-analytic-content">
                        <div className="regular-bold-font">Kategori Angkutan</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataFleet} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataFleet?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="regular-bold-font">Layanan Angkutan</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataTransportType} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataTransportType?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="regular-bold-font">Kategori Angkutan dan Pangkalan</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataSitesFleet} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataSitesFleet?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="regular-bold-font">Layanan Angkutan dan Pangkalan</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataSitesTransport} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataSitesTransport?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="regular-bold-font">Layanan Angkutan dan Kota Asal (Semua Pencarian)</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataTO1} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataTO1?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="regular-bold-font">Layanan Angkutan dan Kota Asal (Pencarian Tidak Ditemukan)</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataTO2} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataTO2?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="regular-bold-font">Rute Kota Asal dan Tujuan</div>
                        <ResponsiveContainer width="100%" height={400}>
                           <PieChart>
                              <Pie data={dataOriginDestinations} dataKey="count" nameKey="name" cx="50%" cy="50%" fill="#8884d8" isAnimationActive={false} labelLine={false} label={renderCustomizedLabel}>
                                 {dataOriginDestinations?.map((entry: any, index: any) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                 ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                           </PieChart>
                        </ResponsiveContainer>
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default PieAnalytic;
