import { Shipment, Transporter, Carrier, Service, User, Token, Provider, Site, Voucher, TransportType } from "../model";

import * as Master from "../reference/Master";

class CacheService {
   isEmpty(obj: any): boolean {
      return obj === undefined || obj === null || obj === "undefined" || obj === "null" ? true : false;
   }

   public getNewShipment(): Shipment {
      let shipmentStr = localStorage.getItem("newShipment");
      let shipment: Shipment = shipmentStr ? JSON.parse(shipmentStr) : null;
      //console.log('shipment: ', JSON.stringify(shipment, null, 2));

      return shipment;
   }

   public async setNewShipment(shipment: Shipment) {
      localStorage.setItem("newShipment", JSON.stringify(shipment));
   }

   getShipment(): Shipment {
      let shipmentStr = localStorage.getItem("shipment");
      return !this.isEmpty(shipmentStr) && shipmentStr ? JSON.parse(shipmentStr) : null;
   }

   setShipment(shipment: Shipment) {
      localStorage.setItem("shipment", JSON.stringify(shipment));
   }

   getTransporter(): Transporter {
      let transporterStr = localStorage.getItem("transporter");
      return !this.isEmpty(transporterStr) && transporterStr ? JSON.parse(transporterStr) : null;
   }

   setTransporter(transporter: Transporter) {
      localStorage.setItem("transporter", JSON.stringify(transporter));
   }

   setCarrier(carrier: Carrier) {
      localStorage.setItem("carrier", JSON.stringify(carrier));
   }

   getCarrier(): Carrier {
      let carrierStr = localStorage.getItem("carrier");
      return !this.isEmpty(carrierStr) && carrierStr ? JSON.parse(carrierStr) : null;
   }

   setService(service: Service) {
      localStorage.setItem("service", JSON.stringify(service));
   }

   getService(): Service {
      let serviceStr = localStorage.getItem("service");
      return !this.isEmpty(serviceStr) && serviceStr ? JSON.parse(serviceStr) : null;
   }

   getSite(): Site {
      let siteStr = localStorage.getItem("site");
      return !this.isEmpty(siteStr) && siteStr ? JSON.parse(siteStr) : null;
   }

   setSite(site: Site) {
      localStorage.setItem("site", JSON.stringify(site));
   }

   isWebView(): boolean {
      let webViewStr = localStorage.getItem("isWebView");
      return !this.isEmpty(webViewStr) && webViewStr === "true" ? true : false;
   }

   setWebView(isWebView: boolean) {
      localStorage.setItem("isWebView", JSON.stringify(isWebView));
   }

   isTestBooking(): boolean {
      let testBookingStr = localStorage.getItem("isTestBooking");
      return !this.isEmpty(testBookingStr) && testBookingStr === "true" ? true : false;
   }

   setTestBooking(isTestBooking: boolean) {
      localStorage.setItem("isTestBooking", JSON.stringify(isTestBooking));
   }

   isGpsPermissionRequested(): boolean {
      let isGpsPermissionRequestedStr = localStorage.getItem("isGpsPermissionRequested");
      //alert("isGpsPermissionRequestedStr: " + isGpsPermissionRequestedStr);
      return !this.isEmpty(isGpsPermissionRequestedStr) && isGpsPermissionRequestedStr === "true" ? true : false;
   }

   setGpsPermissionRequested(isGpsPermissionRequested: boolean) {
      //alert("setGpsPermissionRequestedStr: " + isGpsPermissionRequested);
      localStorage.setItem("isGpsPermissionRequested", JSON.stringify(isGpsPermissionRequested));
   }

   isGpsPermissionGiven(): boolean {
      let isGpsPermissionGivenStr = localStorage.getItem("isGpsPermissionGiven");
      return !this.isEmpty(isGpsPermissionGivenStr) && isGpsPermissionGivenStr === "true" ? true : false;
   }

   setGpsPermissionGiven(isGpsPermissionGiven: boolean) {
      localStorage.setItem("isGpsPermissionGiven", JSON.stringify(isGpsPermissionGiven));
   }

   getVoucher(): Voucher {
      let voucherStr = localStorage.getItem("voucher");
      return !this.isEmpty(voucherStr) && voucherStr ? JSON.parse(voucherStr) : null;
   }

   setVoucher(voucher: Voucher) {
      localStorage.setItem("voucher", JSON.stringify(voucher));
   }

   getAuth(): any {
      let authStr = localStorage.getItem("auth");
      return !this.isEmpty(authStr) && authStr ? JSON.parse(authStr) : null;
   }

   setAuth(auth: any) {
      localStorage.setItem("auth", JSON.stringify(auth));
   }

   getMobile(): string {
      let mobile = localStorage.getItem("mobile");
      return !this.isEmpty(mobile) && mobile ? mobile : "";
   }

   setMobile(mobile: string) {
      localStorage.setItem("mobile", mobile);
   }

   getToken(): Token | null {
      let tokenStr = localStorage.getItem("token");
      return !this.isEmpty(tokenStr) && tokenStr ? JSON.parse(tokenStr) : null;
   }

   setToken(token?: Token) {
      //console.log('setToken: ', JSON.stringify(token, null, 2));
      localStorage.setItem("token", JSON.stringify(token));
   }

   getUser(): User {
      let userStr = localStorage.getItem("user");
      if (userStr && userStr !== "undefined") {
         //console.log("userStr:", JSON.stringify(JSON.parse(userStr), null, 2));
      }
      return !this.isEmpty(userStr) && userStr ? JSON.parse(userStr) : null;
   }

   setUser(user?: User) {
      // console.log("setUser: ", JSON.stringify(user, null, 2));
      localStorage.setItem("user", JSON.stringify(user));
   }

   getProvider(): Provider {
      let providerStr = sessionStorage.getItem("provider");
      //console.log("providerStr: ", providerStr);
      return !this.isEmpty(providerStr) && providerStr ? JSON.parse(providerStr) : null;
   }

   setProvider(provider?: Provider) {
      sessionStorage.setItem("provider", JSON.stringify(provider));
   }

   getTransportType(): TransportType {
      let transportTypeStr = sessionStorage.getItem("transportType");
      // console.log("transportType: ", transportTypeStr);
      return !this.isEmpty(transportTypeStr) && transportTypeStr ? JSON.parse(transportTypeStr) : null;
   }

   setTransportType(transportType: TransportType) {
      // console.log("transportType: ", transportType);
      sessionStorage.setItem("transportType", JSON.stringify(transportType));
   }

   getPath(): string | null {
      //console.log('getPath: ', localStorage.getItem('path'));
      return localStorage.getItem("path");
   }

   setPath(path: string) {
      //console.log("setPath: ", path);
      localStorage.setItem("path", path);
   }

   getLastPath(): string | null {
      //console.log('getLastPath: ', localStorage.getItem('lastPath'));
      return localStorage.getItem("lastPath");
   }

   setLastPath(lastPath: string) {
      //console.log("setLastPath: ", lastPath);
      localStorage.setItem("lastPath", lastPath);
   }

   getCategory(): string | null {
      //console.log('getCategory: ', localStorage.getItem('category'));
      return localStorage.getItem("category");
   }

   setCategory(category: string) {
      //console.log("setCategory: ", category);
      localStorage.setItem("category", category);
   }

   getHarbor(): string | null {
      return localStorage.getItem("harbor");
   }

   setHarbor(harbor: string) {
      localStorage.setItem("harbor", harbor);
   }

   getPage(): number {
      let numberStr = localStorage.getItem("page");
      let page = 1;
      if (numberStr) {
         page = parseInt(numberStr);
      }
      // console.log("getPage: " + page);
      return page;
   }

   setPage(page: number) {
      // console.log('setPath: ', path);
      // console.log("setPage: ", page);
      if (page) {
         localStorage.setItem("page", "" + page);
      } else {
         localStorage.setItem("page", "" + 1);
      }
   }

   getPageCount(): number {
      let numberStr = localStorage.getItem("pageCount");
      let page = 1;
      if (numberStr) {
         page = parseInt(numberStr);
      }
      return page;
   }

   setPageCount(pageCount: number) {
      //console.log('setPath: ', path);
      if (pageCount) {
         localStorage.setItem("pageCount", "" + pageCount);
      } else {
         localStorage.setItem("pageCount", "" + 1);
      }
   }

   // rest page
   resetPage() {
      this.setPage(1);
      this.setPageCount(1);
      // console.log("reset to 1");
      // console.log("page: ", localStorage.getItem("page"));
      // console.log("pageCount: ", localStorage.getItem("pageCount"));
   }

   getPrev(): string | null {
      //console.log('getPath: ', localStorage.getItem('path'));
      return localStorage.getItem("prev");
   }

   savePrev() {
      //console.log("savePrev: ", window.location.pathname);
      localStorage.setItem("prev", window.location.pathname);
   }

   getTokenValidity(): Date {
      let dateStr = localStorage.getItem("tokenValidity");
      if (dateStr) {
         return new Date(Date.parse(dateStr));
      } else {
         return new Date();
      }
   }

   setTokenValidity(date: Date) {
      localStorage.setItem("tokenValidity", date.toISOString());
   }

   getProfiles(): any {
      let profilesStr = localStorage.getItem("profiles");
      let profiles = profilesStr ? JSON.parse(profilesStr) : null;
      //console.log('shipment: ', JSON.stringify(shipment, null, 2));

      return profiles;
   }

   setProfiles(profiles: any) {
      localStorage.setItem("profiles", JSON.stringify(profiles));
   }

   getApiRequest(): string {
      let apiRequest = localStorage.getItem("apiRequest");
      return !this.isEmpty(apiRequest) && apiRequest ? apiRequest : "";
   }

   setApiRequest(apiRequest: string) {
      localStorage.setItem("apiRequest", apiRequest);
   }

   getRole = () => {
      if (Master.PATH_CUSTOMER === this.getPath()) {
         return Master.ROLE_CUSTOMER;
      } else if (Master.PATH_PROVIDER === this.getPath()) {
         return Master.ROLE_PROVIDER;
      } else if (Master.PATH_TRANSPORTER === this.getPath()) {
         return Master.ROLE_TRANSPORTER;
      } else if (Master.PATH_BOOKING === this.getPath()) {
         return Master.ROLE_CUSTOMER;
      } else if (Master.PATH_ADMIN === this.getPath()) {
         return Master.ROLE_ADMIN;
      } else {
         return Master.ROLE_CUSTOMER;
      }
   };
}

const CacheSrv = new CacheService();

export default CacheSrv;
