import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { AuthService, CacheService, ProviderService, ErrorService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";
import * as AlertHelper from "../../../helper/AlertHelper";
import * as GraphHelper from "../../../helper/GraphHelper";

import { Provider } from "../../../model";

import * as Master from "../../../reference/Master";

import { getEmptyProviders } from "../../../reference/Provider";

import "./Providers.css";

let modalContext: ModalContextInterface;

export default function Providers(props: any) {
   const history = useHistory();

   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [providers, setProviders] = useState(getEmptyProviders(pageSize));
   const [searchProvider, setSearchProvider] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setProviders(getEmptyProviders(pageSize));
      SearchHelper.searchInputs([searchProvider], () => {
         let provider = new Provider();
         provider.name = searchProvider;

         ProviderService.searchProvider(provider, pageSize, page)
            .then((result) => {
               setProviders(result.providers);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchProvider, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchProvider]);

   const onPressNewProvider = (shipment: Provider) => {
      history.push("/register/provider");
   };

   const onPressMore = (provider: Provider) => {
      AlertHelper.alertLoading("Loading ...", modalContext);

      AuthService.getSudoToken(provider.gid || "", Master.ROLE_PROVIDER)
         .then((auth) => {
            modalContext?.setLogin(true);

            CacheService.resetPage();
            // force change on current page, if not current variable page in Navigation caused the page cache remain on current page
            setPage(1);

            CacheService.setToken({ token: auth.token, refreshToken: auth.refreshToken });
            console.log("auth: ", JSON.stringify(auth, null, 2));

            let user = GraphHelper.graphToUser(auth.user, Master.ROLE_PROVIDER);

            CacheService.setUser(user);
            props.setUser(user);

            setTimeout(() => {
               AlertHelper.hideLoading(modalContext);
               history.replace("/admin/carrier");
            }, 1000);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="providers">
                  <div className="providers-header regular-bottom-border">
                     <div className="providers-header-search">
                        {user.isAdmin && (
                           <div className="providers-header-search-section">
                              <SectionIcon building className="providers-header-search-icon" />
                              <div className="providers-header-search-input">
                                 <InputSearch placeholder="Cari Penyedia Jasa" value={searchProvider} setValue={setSearchProvider} />
                              </div>
                           </div>
                        )}
                     </div>
                     <div className="providers-header-button">
                        <CustomButton icon="truck" text="Tambah Penyedia Jasa" onClick={onPressNewProvider} style={{ width: 210 }} />
                     </div>
                  </div>
                  <div id="search-result" className={window.innerWidth < 1000 && !props.showBottomNavigation ? "search-result-no-nav" : "search-result"}>
                     <PageView
                        providers={providers}
                        onPressMore={(provider: Provider) => {
                           console.log("provider: ", JSON.stringify(provider, null, 2));
                           onPressMore(provider);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchProvider && !searchProvider && "Belum terdapat penyedia jasa angkutan"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
