export const width = 1000;
const height = 'calc(100vh)';

export const fullWidth = width + 'px';
export const fullHeight = height;

export const contentWidth = 'calc(100vw-40px)';
export const formWidth = 'calc(100vw-50px)';

export const regularBorder = {
   borderWidth: 0.5,
   borderColor: '#eee',
   borderStyle: 'solid',
   fontWeight: 600,
};

export const darkBorder = {
   borderWidth: 0.5,
   borderColor: '#ccc',
   borderStyle: 'solid',
};

export const regularBottomBorder = {
   borderWidth: 0,
   borderBottomWidth: 0.5,
   borderBottomColor: '#eee',
   borderStyle: 'solid',
};

export const regularShadow = {
   shadowColor: '#000',
   shadowOffset: {width: 1, height: 1},
   shadowOpacity: 0.1,
   shadowRadius: 2,
   elevation: 2,
   borderWidth: 0.5,
   borderColor: '#ccc',
   borderStyle: 'solid',
};

export const redShadow = {
   shadowColor: '#D5392C',
   shadowOffset: {width: 0, height: 1},
   shadowOpacity: 0.3,
   shadowRadius: 5,
   elevation: 5,
};

export const paddingList = {
   paddingHorizontal: 15,
   paddingTop: 10,
   paddingBottom: 20,
};

export const paddingContent = {
   paddingHorizontal: 20,
   paddingTop: 10,
   paddingBottom: 20,
};

export const paddingForm = {
   paddingHorizontal: 25,
   paddingTop: 10,
   paddingBottom: 20,
};

export const paddingBottomContent = {
   paddingHorizontal: 20,
   paddingTop: 10,
   paddingBottom: 20,
};

export const paddingBottomForm = {
   paddingHorizontal: 25,
   paddingTop: 10,
   paddingBottom: 20,
};
