import React, { useState, useEffect } from "react";

import * as Config from "../../Config";
import * as Master from "../../reference/Master";

import * as CommonHelper from "../../helper/CommonHelper";

import Skeleton from "react-loading-skeleton";

import "./ImageView.css";

export default function ImageView(props: any) {
   const [image, setImage] = useState(Master.IMAGE_TRANSPARENT);
   const [imageUpload, setImageUpload] = useState(Master.IMAGE_TRANSPARENT);

   useEffect(() => {
      if (props.url) {
         if (image === Master.IMAGE_TRANSPARENT) {
            CommonHelper.getImage(props.url, setImage);
         }
      } else if (props.uploadUrl) {
         //console.log("imageUrl: ", props.uploadUrl);
         let uploadUrl = props.uploadUrl;
         if (props.uploadUrl === Master.IMAGE_ALT_PERSON) {
            uploadUrl = "/images/misc/user.png";
         } else {
            if (props.profile) {
               uploadUrl = Config.STORAGE_API + uploadUrl + "/profile";
            } else {
               uploadUrl = Config.STORAGE_API + uploadUrl;
            }
         }
         //if (imageUpload === Master.IMAGE_TRANSPARENT) {
         CommonHelper.getImage(uploadUrl, setImageUpload);
         //}
      }
   }, [props.url, props.uploadUrl, props.profile, image, imageUpload]);

   return (
      <div className="image-view">
         {props.url ? (
            <img className={`${props.className}`} src={image} alt={props.alt} />
         ) : props.uploadUrl ? (
            <img className={`${props.className}`} src={imageUpload} alt={props.alt} />
         ) : (
            <Skeleton circle={true} height={props.size ? props.size : 70} width={props.size ? props.size : 70}></Skeleton>
         )}
      </div>
   );
}
