import { gql } from "@apollo/client";

import { User } from "../model";

import { authGraphClient, initAuthClient } from "./GraphService";

class UserService {
   async registerUser(user: User) {
      await initAuthClient();

      let gImg = user.profileImg
         ? `profileImgID: ${user.profileImg?.id},
            profileImgUrl: "${user.profileImgUrl}",`
         : ``;

      let gRole = user.providerUser?.role ? `providerRole: "${user.providerUser.role}",` : ``;
      let gSite = user.providerUser?.site ? `siteGid: "${user.providerUser.site.gid}",` : ``;

      let g = `
         mutation {
            RegisterUser(
               inputUser: {
                  name: "${user.name}",
                  email: "${user.email}",
                  mobile: "${user.mobile}",
                  ${gImg}
                  ${gRole}
                  ${gSite}
               }
            )
         }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let userId = result.data.UpdateUser;

      console.log("result: ", result.data);
      return userId;
   }

   async updateUser(user: User) {
      await initAuthClient();

      let gGid = user.gid ? `gid: "${user.gid}",` : ``;

      let gImg = user.profileImg
         ? `profileImgID: ${user.profileImg?.id},
            profileImgUrl: "${user.profileImgUrl}",`
         : ``;

      let gRole = user.providerUser?.role ? `providerRole: "${user.providerUser.role}",` : ``;
      let gSite = user.providerUser?.site ? `siteGid: "${user.providerUser.site.gid}",` : ``;

      let g = `
         mutation {
            UpdateUser(
               inputUser: {
                  ${gGid}
                  name: "${user.name}",
                  email: "${user.email}",
                  mobile: "${user.mobile}",
                  ${gImg}
                  ${gRole}
                  ${gSite}
               }
            )
         }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let userId = result.data.UpdateUser;

      console.log("result: ", result.data);
      return userId;
   }

   async getUser(gid?: string) {
      await initAuthClient();
      console.log("getUser: ", gid);

      let gqlReq = `
            query { 
               GetUser(gid: "${gid}") {
                  id
                  gid
                  name
                  email
                  isEmailVerified
                  mobile
                  profileImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  profileImgUrl
                  customer {
                     id
                  }
                  transporter {
                     id
                  }
                  provider {
                     id
                  }
                  providerUser {
                     role
                     site {
                        gid
                     }
                  }
                  isAdmin
               }
            }`;

      console.log("gql query: ", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      console.log("result: ", JSON.stringify(result, null, 2));
      let user: User = result ? result.data.GetUser : null;
      console.log("getUser: ", JSON.stringify(user, null, 2));

      return user;
   }

   async searchProviderUser(user: User, pageSize: number, page: number) {
      await initAuthClient();
      //console.log('searchSite: ', JSON.stringify(site, null, 2));

      let sitePageCountGql =
         page === 1
            ? `
            GetProviderUsersPageCount(
               userName: "${user.name ? user.name : ""}", 
               providerName: "${user.provider ? user.provider.name : ""}",
               status: "${user.status ? user.status : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetProviderUsers(
                  userName: "${user.name ? user.name : ""}", 
                  providerName: "${user.provider ? user.provider.name : ""}",
                  status: "${user.status ? user.status : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  gid
                  name
                  providerUser {
                     site {
                        name
                     }   
                  }
                  provider {
                     name
                  }
               }
               ${sitePageCountGql}
            }`;

      //console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let resp = { users: result.data.GetProviderUsers, pageCount: result.data.GetProviderUsersPageCount };

      console.log("searchProviderUsers:", JSON.stringify(resp, null, 2));

      return resp;
   }
}

const Service = new UserService();

export default Service;
