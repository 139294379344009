import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Tabs } from "antd";
import TagManager from "react-gtm-module";

import mixpanel from "mixpanel-browser";

// import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";
import { ModalContext } from "../../hooks/ModalContext";

import { Showcase } from "./showcase";
import CategoryInfo from "./CategoryInfo";
import { categorySet } from "../../reference/Category";

import { CacheService } from "../../service";

import * as Config from "../../Config";

import "./Category.css";

const { TabPane } = Tabs;

// let modalContext: ModalContextInterface;

function Category(props: any) {
   const history = useHistory();
   const { match } = props;
   const category = categorySet.get(match.params.id);

   useEffect(() => {
      props.setShowBottomNavigation(true);
      props.setShowBackNavigation(true);
      window.scrollTo(0, 0);
   }, [props]);

   const onChange = (key: any) => {
      if (key === "2") {
         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Check Price");

         //history.push('/select-transport-type/' + category.code);
         CacheService.setCategory(category ? category.code : "");
         //console.log("booking: ", CacheService.getCategory());

         // change otp verification from initial booking to shipment confirmation
         // history.push('/login/booking');

         history.push("/select-transport-type/" + category?.code);

         // gtm();
      } else if (key === "3") {
         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Book Now");

         //history.push('/select-transport-type/' + category.code);
         CacheService.setCategory(category ? category.code : "");
         //console.log("booking: ", CacheService.getCategory());

         // change otp verification from initial booking to shipment confirmation
         // history.push('/login/booking');

         history.push("/select-transport-type/" + category?.code);

         // gtm();
      }
   };

   const gtm = () => {
      // gtm conversion
      const tagManagerArgs = {
         gtmId: "GTM-MW67KPL",
      };
      TagManager.initialize(tagManagerArgs);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            // modalContext = context;
            return (
               <div className="category-container">
                  <Showcase category={category} />
                  <div className="category">
                     <Tabs defaultActiveKey="1" onChange={onChange}>
                        <TabPane tab={category ? category.code : ""} key="1">
                           <div className="category-content">
                              <CategoryInfo category={category} />
                           </div>
                        </TabPane>
                        <TabPane tab="Cek Harga" key="2">
                           <div className="category-content-professional">
                              <div>Cek Harga Angkutan</div>
                           </div>
                        </TabPane>
                        <TabPane tab="Pesan Angkutan" key="3">
                           <div className="category-content-professional">
                              <div>Cek Harga Angkutan</div>
                           </div>
                        </TabPane>
                     </Tabs>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default Category;
