import { UploadFile, UploadFileStatus } from "antd/lib/upload/interface";
import * as FormatHelper from "../helper/FormatHelper";

import * as Config from "../Config";

import { CacheService } from "../service";

// import * as Master from '../data/Master';

export default class Image {
   id?: number;
   name: string;
   status: string;
   size: number;
   type: string;
   url: string;

   init(id: number, name: string, status: string, size: number, type: string, url: string) {
      this.id = id;
      this.name = name;
      this.status = status;
      this.size = size;
      this.type = type;
      this.url = url;
      return this;
   }

   setId(id?: number) {
      this.id = id;
      return this;
   }
}

export const toUploadFile = (image: Image, confidential?: boolean) => {
   //console.log('url: ', image.url);
   //console.log('confidential: ', confidential);
   let url = image.url + (confidential ? "/confidential/" + CacheService.getToken()?.token : "");
   url = url.replace(Config.STORAGE_API, "");
   url = Config.STORAGE_API + url;

   let file = {
      uid: FormatHelper.toString(image.id),
      name: image.name,
      status: image.status as UploadFileStatus,
      size: image.size,
      type: image.type,
      url: url,
   };

   //console.log('toUploadFile: ', JSON.stringify(file, null, 2));

   return file;
};

export const toImages = (fileList: Array<UploadFile>) => {
   console.log("toImages input: ", JSON.stringify(fileList, null, 2));
   const images = fileList
      ? fileList.map((file) =>
           file.response
              ? new Image().init(parseInt(file.response.id), file.response.name, String(file.response.status), file.response.size, file.response.type, String(file.response.url))
              : new Image().init(parseInt(file.uid), file.name, String(file.status), file && file.size ? file.size : 0, file && file.type ? file.type : "image/jpeg", String(file.url))
        )
      : [];
   console.log("toImages output: ", JSON.stringify(images, null, 2));
   return images;
};
