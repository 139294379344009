import { Provider } from ".";

export default class Service {
   id?: number;
   gid?: string;
   provider?: Provider;
   serviceTypeCode: string;
   serviceTypeDesc: string;
   serviceCode: string;
   serviceDesc: string;
   priceScheme: string;
   pricePerUnit: number;
   priceUom: string;
   pricePerKmShort: number;
   maxKmShort: number;
   pricePerKmMed: number;
   maxKmMed: number;
   pricePerKmLong: number;
   minKm: number;
   minPrice: number;
   isSpecialPrice: boolean;
   pickupPricePerKm: number;
   pickupFreeKmDistance: number;
   pickupCoverageKm: number;
   returnPriceRatio: number;
   coverageKm: number;
   transitPrice: number;
   overCapacitySurcharge: number;
   capacityKg: number;
   maxLoadCapacity: number;
   verificationStatus: string;
   status: string;
}

export class ServiceOption {
   code: string;
   desc: string;
   type: string;
   uom: string;
}
