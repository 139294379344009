import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Navigation from "./Navigation";

import { SectionIcon, CustomButton } from "../common";

import * as MenuHelper from "../../helper/MenuHelper";
import * as CommonHelper from "../../helper/CommonHelper";

import * as Master from "../../reference/Master";

import { CacheService } from "../../service";

import "./Header.css";

function Header(props: any) {
   const history = useHistory();

   const mounted = useRef(false);

   useEffect(() => {
      mounted.current = true;

      return () => {
         mounted.current = false;
      };
   }, []);

   let path = props.location.pathname;
   let isShowSearchBar = path === "/home" || path === "/" || path === "";
   let userInfo = path.indexOf("/admin") === 0 || path.indexOf("/profile") === 0 || path === "/shipment" || path.indexOf("/review") === 0;

   //console.log("userInfo: ", userInfo);

   const [showSearchBar, setShowSearchBar] = useState(isShowSearchBar);
   const [lintasImg, setLintasImg] = useState(Master.IMAGE_TRANSPARENT);
   let isMounted = false;

   const setImg = (img: any) => {
      if (isMounted) {
         setLintasImg(img);
      }
   };

   useEffect(() => {
      isMounted = true;
      if (lintasImg === Master.IMAGE_TRANSPARENT) {
         CommonHelper.getImage("/images/lintas.png", (image: any) => {
            setImg(image);
         });
      }
      return () => {
         isMounted = false;
      };
   }, [lintasImg]);

   useEffect(() => {
      setShowSearchBar(isShowSearchBar);
   }, [isShowSearchBar]);

   const onPrevClick = () => {
      CacheService.resetPage();
      let prev = CacheService.getPrev();
      // console.log("prev: " + prev);
      if (
         path === "/shipment" ||
         path === "/admin/provider" ||
         path === "/admin/carrier" ||
         path === "/admin/transporter" ||
         path === "/admin/service" ||
         path === "/admin/site" ||
         path === "/admin/profile" ||
         path === "/profile" ||
         path === "/profile/provider" ||
         path === "/profile/provider/bank" ||
         path === "/analytic/trend" ||
         path === "/analytic/pie"
      ) {
         history.push("/home/login");
         props.setSelectedMenu({ title: "Beranda" });
      } else if (path.indexOf("/shipment/status") > -1) {
         props.setSelectedMenu({ title: "Beranda" });
         history.push("/home/login");
      } else if (prev && prev.indexOf("/shipment") > -1) {
         history.push(prev);
      } else if (path.indexOf("/new-shipment-detail") > -1) {
         props.setSelectedMenu({ title: "Pengiriman" });
         history.push("/shipment");
      } else if (path.indexOf("/shipment-detail") > -1) {
         props.setSelectedMenu({ title: "Pengiriman" });
         history.push("/shipment");
      } else if (path.indexOf("/shipment/payment") > -1) {
         props.setSelectedMenu({ title: "Pengiriman" });
         history.push("/shipment");
      } else {
         history.goBack();
      }
   };

   const title = () => {
      let desc = MenuHelper.getMenuDesc(CacheService.getUser(), window.location.pathname);
      if (desc === "Beranda") {
         desc = undefined;
      }
      return desc;
   };

   // console.log("innerWidth: ", window.innerWidth);
   // console.log("outerWidth: ", window.outerWidth);

   return (
      <div className="header">
         {!(window.outerWidth < 1000) && (
            <div>
               <Navigation user={props.user} showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} />
               {userInfo && (
                  <div className="header-user-info">
                     <div className="header-user-nav">
                        <CustomButton icon={"prev"} onClick={onPrevClick} />
                        <div className="section big-bold-font">{title()}</div>
                     </div>
                     {window.innerWidth >= 400 && (
                        <div className="header-user-section">
                           <SectionIcon person color="blue" />
                           <div className="header-user-info-content regular-bold-font">{props.user?.name}</div>
                        </div>
                     )}
                     {props.user?.provider && (
                        <div className="header-user-section">
                           <SectionIcon truck color="blue" />
                           <div className="header-user-info-content regular-bold-font">{props.user?.provider?.name}</div>
                        </div>
                     )}
                     {props.user?.isAdmin && !props.user?.provider && (
                        <div className="header-user-section">
                           <SectionIcon star color="blue" />
                           <div className="header-user-info-content regular-bold-font">Admin</div>
                        </div>
                     )}
                  </div>
               )}
            </div>
         )}
         {window.outerWidth < 1000 && (
            <div className="mobile-navigation bold-white-font">
               {props.showBackNavigation && (
                  <div className="navigation">
                     <CustomButton navigate icon={"prev"} onClick={onPrevClick} />
                  </div>
               )}
               {!title() && (
                  <div style={{ flex: 1, display: "flex", justifyContent: "center", paddingRight: props.showBackNavigation ? 44 : 0 }}>
                     <img src="#" srcSet={lintasImg} alt="" width={40} height={40} />
                  </div>
               )}
               {title() && <div style={{ flex: 1, fontSize: 18, textAlign: "center", paddingRight: props.showBackNavigation ? 44 : 0 }}>{title()}</div>}
            </div>
         )}
      </div>
   );
}

export default Header;
