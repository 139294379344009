import { gql } from "@apollo/client";

import { CacheService } from ".";

import * as Master from "../reference/Master";

import { User, Customer } from "../model";

import { authGraphClient, initAuthClient } from "./GraphService";

import * as GraphHelper from "../helper/GraphHelper";

class RegisterService {
   async registerCustomer(user: User, customer: Customer) {
      await initAuthClient();
      let g = `
         mutation {
            RegisterCustomer(
               inputUser: {name: "${user.name}", email: "${user.email}", mobile: "${user.mobile}"}, 
               inputCustomer: {name: "${customer.name}", type: "${customer.type}", phone: "${customer.phone}", email: "${customer.email}"}
            ) {
               token
               refreshToken
               user {
                  id
                  gid
                  name
                  email
                  mobile
                  customer {
                     id
                     gid
                  }
               }
             }
         }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      console.log("result: ", result.data);

      let auth = result.data.RegisterCustomer;
      let usr: User | undefined;

      if (CacheService.getUser().role === Master.ROLE_CUSTOMER) {
         CacheService.setToken({ token: auth.token, refreshToken: auth.refreshToken });

         usr = GraphHelper.graphToUser(auth.user, Master.ROLE_CUSTOMER);
         CacheService.setUser(usr);
      }

      console.log(result.data);
      return usr;
   }

   async updateCustomer(customer: Customer) {
      await initAuthClient();

      let gImg = customer.profileImg
         ? `
            profileImgID: ${customer.profileImg?.id},
            profileImgUrl: "${customer.profileImgUrl}",
         `
         : ``;

      let g = `
         mutation {
            UpdateCustomer(
               inputCustomer: {
                  name: "${customer.name}", 
                  type: "${customer.type}", 
                  phone: "${customer.phone}",
                  email: "${customer.email}",
                  ${gImg}
               }
            )
         }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let customerId = result.data.UpdateCustomer;

      console.log("result: ", result.data);
      return customerId;
   }

   async searchCustomer(customer: Customer, pageSize: number, page: number) {
      await initAuthClient();
      //console.log('searchCustomer: ', JSON.stringify(customer, null, 2));

      let customerPageCountGql =
         page === 1
            ? `
            GetCustomersPageCount(
               customerName: "${customer.name ? customer.name : ""}", 
               customerMobile: "${customer.phone ? customer.phone : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetCustomers(
                  customerName: "${customer.name ? customer.name : ""}", 
                  customerMobile: "${customer.phone ? customer.phone : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  id
                  gid
                  name
                  type 
                  phone
                  email
                  level
                  rating
                  profileImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  profileImgUrl
               }
               ${customerPageCountGql}
            }`;

      //console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let resp = { customers: result.data.GetCustomers, pageCount: result.data.GetCustomersPageCount };

      console.log("searchCustomer: ", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getCustomer(gid?: string) {
      await initAuthClient();
      console.log("getCustomer: ", gid);

      let gqlReq = `
            query { 
               GetCustomer(gid: "${gid}") {
                  id
                  gid
                  name
                  type 
                  phone
                  email
                  level
                  rating
                  profileImg {
                     id
                     name
                     status
                     type
                     url
                  }
               }
            }`;

      console.log("gql query: ", gqlReq);
      let result;

      try {
         result = await authGraphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      console.log("result: ", JSON.stringify(result, null, 2));
      let customer: Customer = result ? result.data.GetCustomer : null;
      console.log("getCustomer: ", JSON.stringify(customer, null, 2));

      return customer;
   }
}

const Service = new RegisterService();

export default Service;
