import * as React from "react";
import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import {} from "google-maps";
import { Checkbox } from "antd";

import MapMarker from "../common/MapMarker";
import SubNavigation from "../common/SubNavigation";

import * as Config from "../../Config";

import { CarrierService, ShipmentService } from "../../service";

import * as DirectionHelper from "../../helper/DirectionHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";
import * as ReferenceHelper from "../../helper/ReferenceHelper";

import { Place, Path, Step, Route } from "../../model";

import * as Master from "../../reference/Master";

import "./TrackPosition.css";

class Pos {
   lat: number;
   lng: number;
   date?: Date;
}

let googleMap: any;
let googleMaps: any;
let toDestRoutePolyline: any;
let originDestRoutePolyline: any;
let travelledRoutePolyline: any;

let timer: NodeJS.Timeout;

export default function TrackPosition(props: any) {
   const refMap = React.createRef;
   const [bounded, setBounded] = useState(false);
   const [distance, setDistance] = useState(0);
   const [duration, setDuration] = useState(0);
   const [eta, setEta] = useState(new Date());
   const [etaText, setEtaText] = useState("Estimasi Sampai");
   const [travelledPath, setTravelledPath] = useState<Pos[]>();
   const [transits, setTransits] = useState<Route[]>();
   const [path, setPath] = useState<Path>();
   const [steps, setSteps] = useState<Step[]>();
   const [isRefreshBounds, setRefreshBounds] = useState(false);
   const [isGoogleApiReady, setGoogleApiReady] = useState(false);
   const [directionPath, setDirectionPath] = useState([]);
   const [shipmentOrigin, setShipmentOrigin] = useState<Pos>();
   const [shipmentDestination, setShipmentDestination] = useState<Pos>();
   const [position, setPosition] = useState<Pos>({
      lat: 0,
      lng: 0,
      date: undefined,
   });
   const [showPosition, setShowPosition] = useState(false);
   const [showShipmentOriginDestination, setShowShipmentOriginDestination] = useState(false);
   const [toDestOrigin, setToDestOrigin] = useState<Place>({
      name: "",
      lat: 0,
      lng: 0,
   });
   const [toDestDestination, setToDestDestination] = useState<Place>({
      name: "",
      lat: 0,
      lng: 0,
   });
   const [destination, setDestination] = useState<Pos>({
      lat: 0,
      lng: 0,
   });

   const [travelledPathColor, setTravelledPathColor] = useState("red");
   const [shipmentDestinationColor, setShipmentDestinationColor] = useState("red");

   useEffect(() => {
      const updatePosition = () => {
         if (props.show) {
            CarrierService.getPosition([props.shipment.carrier?.id || 0]).then((resp: any) => {
               console.log("getPosition Resp: ", JSON.stringify(resp, null, 2));
               if (resp && resp.length > 0) {
                  let newPos = resp[0].pos;
                  if (newPos.lat !== position.lat || newPos.lng !== position.lng) {
                     setPosition(newPos);
                     setShowPosition(true);
                  }
               }
            });
         }
      };

      if (isGoogleApiReady && props.shipment.carrier && props.shipment.status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS) {
         updatePosition();
         timer = setInterval(() => {
            updatePosition();
         }, 5 * 60 * 1000);
      }

      return () => {
         if (timer) {
            clearTimeout(timer);
         }
      };
   }, [props.shipment, props.show, position, isGoogleApiReady]);

   useEffect(() => {
      // console.log("position update: ", position);
      if (position.lat !== 0 && position.lng !== 0) {
         setToDestOrigin({ name: "", lat: position.lat, lng: position.lng });
         setToDestDestination(props.destination);
      }
   }, [position, props.destination]);

   useEffect(() => {
      if (props.destination) {
         setDestination({
            lat: props.destination.lat,
            lng: props.destination.lng,
         });
      }
      if (googleMap && props.show && position.lat !== 0 && position.lng !== 0 && props.destination && !bounded) {
         let bounds = new google.maps.LatLngBounds();
         bounds.extend(new google.maps.LatLng(position.lat, position.lng));
         bounds.extend(new google.maps.LatLng(props.destination.lat, props.destination.lng));
         googleMap.fitBounds(bounds);
         setBounded(true);
      }
   }, [props, position, bounded]);

   useEffect(() => {
      if (props.show) {
         setBounded(false);
         if (showShipmentOriginDestination) {
            setRefreshBounds(false);
            setTimeout(() => {
               setRefreshBounds(true);
            }, 100);
         }
      }
   }, [props.show, showShipmentOriginDestination]);

   const onMapChange = (camera: any) => {
      //console.log('onChange: ', camera);
   };

   const apiIsLoaded = (map: any, maps: any) => {
      //console.log('apiIsLoaded');
      googleMap = map;
      googleMaps = maps;

      if (googleMap && props.show && position.lat !== 0 && position.lng !== 0 && props.destination && !bounded) {
         let bounds = new google.maps.LatLngBounds();
         bounds.extend(new google.maps.LatLng(position.lat, position.lng));
         bounds.extend(new google.maps.LatLng(props.destination.lat, props.destination.lng));
         googleMap.fitBounds(bounds);
         setBounded(true);
      }

      if (props.shipment.status !== Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS) {
         setTravelledPathColor("yellow");
         setShipmentDestinationColor("blue");
         setEtaText("Sampai Tujuan");
         setShowShipmentOriginDestination(true);
      }

      setGoogleApiReady(true);
   };

   useEffect(() => {
      if (!googleMap) {
         return;
      }

      if (!props.show) {
         return;
      }

      //console.log("====> show path");
      //console.log("====> show showShipmentOriginDestination ", showShipmentOriginDestination);
      //console.log("====> show path ", JSON.stringify(path, null, 2));

      let plan;
      setRefreshBounds(true);

      // console.log("path: ", JSON.stringify(path, null, 2));
      if (showShipmentOriginDestination && path && path.plan) {
         // console.log("show plan path: ", JSON.stringify(path, null, 2));
         plan = JSON.parse(path.plan);
         if (originDestRoutePolyline) {
            originDestRoutePolyline.setMap(null);
         }
         originDestRoutePolyline = new google.maps.Polyline({
            path: plan,
            geodesic: true,
            strokeColor: "#00b8f3",
            strokeOpacity: 1.0,
            strokeWeight: 2,
         });
         setTimeout(() => {
            originDestRoutePolyline.setMap(googleMap);
         }, 100);
         if (plan.length >= 2) {
            setShipmentOrigin(plan[0]);
            setShipmentDestination(plan[plan.length - 1]);
         }
      } else if (!showShipmentOriginDestination && originDestRoutePolyline) {
         originDestRoutePolyline.setMap(null);
         setShipmentOrigin(undefined);
         setShipmentDestination(undefined);
      }

      if (showShipmentOriginDestination && path && path.actual) {
         // console.log("actual path: ", path.actual);
         let actual = JSON.parse(path.actual);
         if (actual && actual.length > 0) {
            setTravelledPath(actual);
         }
         // console.log("actual path: ", JSON.stringify(actual));
         actual = actual.map((p: any) => ({ lat: p.lat, lng: p.lng }));
         actual = actual.filter((p: any) => p.lat !== 0 && p.lng !== 0);
         if (travelledRoutePolyline) {
            travelledRoutePolyline.setMap(null);
         }
         // console.log("actual path: ", JSON.stringify(actual));
         travelledRoutePolyline = new google.maps.Polyline({
            path: actual,
            geodesic: true,
            strokeColor: "#b592ff",
            strokeOpacity: 1.0,
            strokeWeight: 2,
         });
         setTimeout(() => {
            travelledRoutePolyline.setMap(googleMap);
         }, 100);
      } else if (!showShipmentOriginDestination && travelledRoutePolyline) {
         if (travelledRoutePolyline) {
            travelledRoutePolyline.setMap(null);
         }
         setTravelledPath([]);
      }
      if (showShipmentOriginDestination) {
         if (toDestRoutePolyline) {
            toDestRoutePolyline.setMap(null);
         }
         toDestRoutePolyline = new google.maps.Polyline({
            path: directionPath,
            geodesic: true,
            strokeColor: "hotpink",
            strokeOpacity: 1.0,
            strokeWeight: 2,
         });
         toDestRoutePolyline.setMap(googleMap);
      }
   }, [showShipmentOriginDestination, directionPath, path, props.show]);

   useEffect(() => {
      let routes = props.routes;
      if (routes && routes.length > 2) {
         setTransits(routes.slice(1, routes.length - 1));
      }
   }, [props.routes]);

   useEffect(() => {
      const refreshBounds = () => {
         if (googleMap) {
            let bounds = new google.maps.LatLngBounds();
            if (position && props.shipment.status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS) {
               bounds.extend(new google.maps.LatLng(position.lat, position.lng));
            }
            if (props.destination) {
               bounds.extend(new google.maps.LatLng(props.destination.lat, props.destination.lng));
            }
            if (shipmentOrigin) {
               bounds.extend(new google.maps.LatLng(shipmentOrigin.lat, shipmentOrigin.lng));
            }
            if (shipmentDestination) {
               bounds.extend(new google.maps.LatLng(shipmentDestination.lat, shipmentDestination.lng));
            }
            googleMap.fitBounds(bounds);
            setBounded(true);
         }
      };
      if (isRefreshBounds) {
         refreshBounds();
         setRefreshBounds(false);
      }
   }, [shipmentOrigin, shipmentDestination, position, props.destination, isRefreshBounds, props.shipment.status]);

   useEffect(() => {
      // console.log("RefreshDirection");
      // console.log("Origin: ", JSON.stringify(origin, null, 2));
      // console.log("Destination: ", JSON.stringify(origin, null, 2));

      if (!googleMaps) {
         return;
      }

      //const directionsDisplay = new googleMaps.DirectionsRenderer();
      DirectionHelper.initGoogleMaps(googleMaps);

      if (toDestOrigin && toDestDestination && toDestOrigin.lat !== 0 && toDestOrigin.lng !== 0 && toDestDestination.lat !== 0 && toDestDestination.lng) {
         console.log("toDestOrigin: ", JSON.stringify(toDestOrigin, null, 2));
         ShipmentService.getShipmentPath(props.shipment.gid).then((path: Path) => {
            setPath(path);

            DirectionHelper.getDirectionMaps(toDestOrigin, [toDestDestination], (response: any, status: any) => {
               if (status === "OK") {
                  //directionsDisplay.setDirections(response);
                  const path = response.path;
                  setDirectionPath(path);
                  if (toDestRoutePolyline) {
                     toDestRoutePolyline.setMap(null);
                  }
                  toDestRoutePolyline = new google.maps.Polyline({
                     path: path,
                     geodesic: true,
                     strokeColor: "hotpink",
                     strokeOpacity: 1.0,
                     strokeWeight: 2,
                  });
                  toDestRoutePolyline.setMap(googleMap);

                  const distance = response.distance;
                  const duration = Math.round(response.duration * Master.GOOGLE_MAPS_DURATION_RATIO);

                  var eta = new Date();
                  eta.setSeconds(eta.getSeconds() + duration);

                  setDistance(distance);
                  setDuration(duration);
                  setEta(eta);
               } else {
                  window.alert("Directions request failed due to " + status);
               }
            });
         });
      } else if (showShipmentOriginDestination) {
         ShipmentService.getShipmentPath(props.shipment.gid).then((path: Path) => {
            setPath(path);
         });
         console.log("steps: ", JSON.stringify(props.steps, null, 2));
         if (props.steps) {
            console.log("distance & eta");
            console.log("shipment: ", JSON.stringify(props.shipment, null, 2));
            let steps = props.steps;
            let lastStep: Step = steps[steps.length - 1];

            let drt = 0;

            for (let i = 0; i < steps.length; i++) {
               let step: Step = steps[i];
               //if (step.code === Master.STEP_CODE_ARRIVE_AT_DESTINATION) {
               if (step.status === Master.STEP_STATUS_COMPLETED) {
                  drt += step.duration;
               }
            }

            setDistance(props.shipment.distance);
            setDuration(drt);
            setEta(lastStep.completedDate);
         }
      }
   }, [props.shipment, props.steps, toDestOrigin, toDestDestination, showShipmentOriginDestination]);

   const showStepsProgress = (isShow: boolean) => {
      if (isShow) {
         setSteps(props.steps);
         console.log("steps", props.steps);
      } else {
         setSteps(undefined);
      }
   };

   const showOriginDestinationSelected = (e: any) => {
      setShowShipmentOriginDestination(e.target.checked);
   };

   const showStepsProgressSelected = (e: any) => {
      showStepsProgress(e.target.checked);
   };

   const onClickPrev = () => {
      props.setShow(false);
      setBounded(false);
   };
   const onClickClose = () => {
      props.setShow(false);
      setBounded(false);
   };

   return (
      props.show && (
         <div className="track-position">
            <div className="container regular-border">
               <SubNavigation title="Lacak Pengiriman" onClickPrev={onClickPrev} onClickClose={onClickClose} />
               <GoogleMapReact
                  ref={refMap}
                  bootstrapURLKeys={{ key: Config.GOOGLE_MAP_KEY }}
                  center={{
                     lat: position.lat,
                     lng: position.lng,
                  }}
                  defaultZoom={17}
                  onChange={onMapChange}
                  onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
               >
                  {travelledPath &&
                     travelledPath
                        .filter((path) => path.lat !== position.lat && path.lng !== position.lng)
                        .map((path: any, i: number) => <MapMarker pin key={i} zIndex={0} size="tiny" color={travelledPathColor} lat={path.lat} lng={path.lng} text={CalendarHelper.getDayDateMonthTime(new Date(path.date * 1000))} />)}
                  {steps &&
                     steps
                        .filter((step: Step, i: number) => step.status === Master.STEP_STATUS_COMPLETED)
                        .map((step: Step, i: number) => (
                           <MapMarker pin key={`step${i}`} zIndex={0} size="tiny" color="blue" lat={step.posLat} lng={step.posLng} text={ReferenceHelper.getStepDesc(step.code)} desc={CalendarHelper.getDayDateMonthTime(step.completedDate)} />
                        ))}
                  {showPosition && <MapMarker key="pos" size="small" color="yellow" zIndex={1} lat={position.lat} lng={position.lng} text="Posisi Saat Ini" />}
                  {showPosition && <MapMarker key="des" size="small" color="blue" zIndex={1} lat={destination.lat} lng={destination.lng} />}
                  {shipmentOrigin && <MapMarker key="shpOrg" size="small" color="red" zIndex={1} lat={shipmentOrigin.lat} lng={shipmentOrigin.lng} text="Asal Muatan" />}
                  {shipmentDestination && <MapMarker key="shpDes" size="small" color={shipmentDestinationColor} zIndex={1} lat={shipmentDestination.lat} lng={shipmentDestination.lng} text="Tujuan Muatan" />}
                  {showShipmentOriginDestination &&
                     transits &&
                     transits.length > 0 &&
                     transits.map((route: Route) => <MapMarker key="shpDes" size="small" color={shipmentDestinationColor} zIndex={1} lat={route.place.lat} lng={route.place.lng} text="Transit" />)}
               </GoogleMapReact>
               {props.destination && (
                  <div>
                     <div className="options">
                        <Checkbox onClick={showOriginDestinationSelected} checked={showShipmentOriginDestination} className="regular-bold-font">
                           Asal Ke Tujuan
                        </Checkbox>
                        <Checkbox onClick={showStepsProgressSelected} className="regular-bold-font">
                           Proses Pengiriman
                        </Checkbox>
                        {position.date && (
                           <div className="last-update">
                              <div className="last-update-title regular-bold-font">Terakhir Update</div>
                              <div className="last-update-desc regular-font">{CalendarHelper.getDayDateMonthTime(position.date)}</div>
                           </div>
                        )}
                     </div>
                     <div className="info">
                        <div className="destination">
                           <div className="regular-bold-font">Tujuan</div>
                           <div className="regular-font">{props.destination.address}</div>
                        </div>
                        <div className="distance">
                           <div className="regular-bold-font">Jarak</div>
                           <div className="regular-font">{LocationHelper.formatDistance(distance)}</div>
                        </div>
                        <div className="duration">
                           <div className="regular-bold-font">Waktu Tempuh</div>
                           <div className="regular-font">{LocationHelper.formatTripTime(duration)}</div>
                        </div>
                        <div className="eta">
                           <div className="regular-bold-font">{etaText}</div>
                           <div className="regular-font">{CalendarHelper.getDayDateMonthTime(eta)}</div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      )
   );
}
