import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Select } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { CacheService, ServiceService, ErrorService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";

import { Provider, Service } from "../../../model";

import { getEmptyServices } from "../../../reference/Service";
import * as Master from "../../../reference/Master";

import "./Services.css";

const { Option } = Select;
let modalContext: ModalContextInterface;

export default function RegisterService(props: any) {
   const history = useHistory();

   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [services, setServices] = useState(getEmptyServices(pageSize));
   const [searchTransportTypeDesc, setSearchTransportTypeDesc] = useState("");
   const [searchProvider, setSearchProvider] = useState("");
   const [searchStatus, setSearchStatus] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();
   //console.log('user: ', JSON.stringify(user, null, 2));

   useEffect(() => {
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setServices(getEmptyServices(pageSize));
      SearchHelper.searchInputs([searchTransportTypeDesc, searchProvider], () => {
         let provider = new Provider();
         provider.name = searchProvider;
         let service = new Service();
         service.serviceTypeCode = "";
         service.serviceDesc = searchTransportTypeDesc;
         service.provider = provider;
         service.status = searchStatus;

         ServiceService.searchService(service, pageSize, page)
            .then((result) => {
               setServices(result.services);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchTransportTypeDesc, searchProvider, searchStatus, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchTransportTypeDesc, searchProvider, searchStatus]);

   const onPressNewService = (shipment: Service) => {
      history.push("/admin/service/add");
   };

   const onPressMore = (service: Service) => {
      CacheService.setService(service);
      history.push(`/admin/service/update/${service.gid}`);
   };

   const onChangeStatus = (value: any) => {
      setSearchStatus(value ? value : "");
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="services">
                  <div className="services-header regular-bottom-border">
                     <div className="services-header-search">
                        <div className="services-header-search-section">
                           <SectionIcon truck className="services-header-search-icon" />
                           <div className="services-header-search-input">
                              <InputSearch placeholder="Cari Layanan" value={searchTransportTypeDesc} setValue={setSearchTransportTypeDesc} />
                           </div>
                        </div>
                        <div className="services-header-search-section">
                           <SectionIcon option className="services-header-search-icon" />
                           <div className="services-header-search-input">
                              <Select className="services-header-search-select" placeholder="Status" onChange={onChangeStatus} allowClear>
                                 <Option value={Master.STATUS_ACTIVE}>Aktif</Option>
                                 <Option value={Master.STATUS_INACTIVE}>Tidak Aktif</Option>
                              </Select>
                           </div>
                        </div>
                        {user.isAdmin && (
                           <div className="services-header-search-section">
                              <SectionIcon building className="services-header-search-icon" />
                              <div className="services-header-search-input">
                                 <InputSearch placeholder="Cari Penyedia Jasa" value={searchProvider} setValue={setSearchProvider} />
                              </div>
                           </div>
                        )}
                     </div>
                     {user.provider && (
                        <div className="services-header-button">
                           <CustomButton icon="truck" text="Tambah Layanan" onClick={onPressNewService} style={{ width: 180 }} />
                        </div>
                     )}
                  </div>
                  <div id="search-result" className={"search-result " + (user.isAdmin ? (props.showBottomNavigation ? "search-result-admin" : "search-result-admin-no-nav") : props.showBottomNavigation ? "search-result" : "search-result-no-nav")}>
                     <PageView
                        services={services}
                        onPressMore={(service: Service) => {
                           console.log("service: ", JSON.stringify(service, null, 2));
                           onPressMore(service);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchTransportTypeDesc && !searchProvider && !searchStatus && "Belum terdapat layanan"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
