export const primary = '#3198c1';
export const headerColor = primary; //'#02a7be'; //'#6785c3'; //'#0297be'; //'#6785c3'; //'#0296bb';
export const tintColor = '#3198c1'; //'#6785c3'; //'#2f95dc';
export const modalColor = '#000';

export const red = '#D5392C';
export const yellow = '#F69F44';
export const green = '#02a29b';
export const purpleDark = '#8393ca';
export const purpleRegular = '#8f9dcf';
export const purpleLight = '#b9c2e1';
export const purpleSuperLight = '#b9c2e177';
export const blue = '#90caf9';
export const lightBlue = '#e5f9ff';
export const lightGreen1 = '#eae7d444';
export const lightGreen2 = '#eae7d488';

export const primaryButton = '#02a29b'; //'#0297be'; //'#02a29b';
export const secondaryButton = '#8393ca';
export const warningButton = '#D5392C';
export const navigateButton = primary;

export const notifRed = '#D5392C';
export const notifYellow = '#F69F44';
export const notifGreen = '#02a29b';
export const notifPurple = '#8393ca';

export const alertError = '#D5392C';
export const alertWarning = '#F69F44';
export const alertSuccess = '#02a29b';
export const alertInfo = '#3198c1';

export const alertErrorBorder = '#D5392C33';
export const alertWarningBorder = '#F69F4433';
export const alertSuccessBorder = '#15885A33';
export const alertInfoBorder = '#3198c133';

export const regularBackground = '#fff'; //'#e5f9ff'; //'#fff'; //'#daeee9';
export const tabBackground = lightBlue;
