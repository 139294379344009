import * as React from 'react';

import {FaCameraRetro, FaRegImage, FaRegMoneyBillAlt, FaMobileAlt} from 'react-icons/fa';
import {GoClock} from 'react-icons/go';
import {ImCancelCircle} from 'react-icons/im';
import {FiTruck} from 'react-icons/fi';
import {MdSms} from 'react-icons/md';
import {BiSearch, BiXCircle, BiInfoCircle} from 'react-icons/bi';
import {AiOutlineCalendar} from 'react-icons/ai';

import './SimpleButton.css';

export default function SimpleButton(props: any) {
   return (
      <div onClick={props.onClick} className="simple-button-container" style={props.style}>
         <div className="simple-button-icon" style={props.textStyle}>
            {props.camera ? (
               <FaCameraRetro />
            ) : props.money ? (
               <FaRegMoneyBillAlt />
            ) : props.calendar ? (
               <AiOutlineCalendar />
            ) : props.clock ? (
               <GoClock />
            ) : props.photo ? (
               <FaRegImage />
            ) : props.close ? (
               <ImCancelCircle />
            ) : props.truck ? (
               <FiTruck />
            ) : props.sms ? (
               <MdSms />
            ) : props.search ? (
               <BiSearch />
            ) : props.clear ? (
               <BiXCircle />
            ) : props.mobile ? (
               <FaMobileAlt />
            ) : props.info ? (
               <BiInfoCircle />
            ) : null}
         </div>
         <div className="simple-button-text" style={props.textStyle}>
            {props.text}
         </div>
      </div>
   );
}
