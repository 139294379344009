import { gql } from "@apollo/client";

import { Search } from "../model";
import { graphClient, initClient } from "./GraphService";

class SearchService {
   async searchServices(categoryCode: string, transportTypeCode: string, originCity: string, pageSize: number, page: number) {
      await initClient();
      //console.log('searchSite: ', JSON.stringify(site, null, 2));

      let searchServicesPageCountGql =
         page === 1
            ? `
            GetSearchServicesPageCount(
               categoryCode: "${categoryCode ? categoryCode : ""}", 
               transportTypeCode: "${transportTypeCode ? transportTypeCode : ""}",
               originCity: "${originCity ? originCity : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetSearchServices(
                  categoryCode: "${categoryCode ? categoryCode : ""}", 
                  transportTypeCode: "${transportTypeCode ? transportTypeCode : ""}",
                  originCity: "${originCity ? originCity : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  transportTypeCode
                  originDesc
                  destinationDesc
               }
               ${searchServicesPageCountGql}
            }`;

      console.log("gql query: ", gqlReq);
      let result;

      result = await graphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let resp = { searchServices: result.data.GetSearchServices, pageCount: result.data.GetSearchServicesPageCount };

      console.log("searchServices:", JSON.stringify(resp, null, 2));

      return resp;
   }
}

const Service = new SearchService();

export default Service;
