import { Service, Voucher } from "../model";
import * as Master from "../reference/Master";

export const getTransportPriceByDistanceWeight = (service: Service, distance: number, weight: number): number => {
   //console.log("distance dalam pricing helper: ", distance);
   //console.log("minKm dalam pricing helper: ", service.minKm);

   const isReturnShipment = service.returnPriceRatio < 100 ? true : false;

   // if (distance < service.minKm) {
   //    distance = service.minKm;
   // }

   //console.log("distance setelah if statement: ", distance);

   let basePrice = getTransportPriceByDistance(service, distance);
   //basePrice = basePrice < service.minPrice ? service.minPrice : basePrice;

   let surcharge = 0;

   if (weight > service.capacityKg) {
      surcharge = ((basePrice * (weight - service.capacityKg)) / service.capacityKg) * service.overCapacitySurcharge;
   }

   let unitPrice = round((basePrice + surcharge) / distance);

   if (isReturnShipment) {
      unitPrice = round((unitPrice * service.returnPriceRatio) / 100);
   }

   console.log("unitPrice: ", unitPrice);
   console.log("distance: ", distance);
   console.log("price: ", unitPrice * distance);

   return unitPrice;
};

export const calculateTransWorkerPrice = (service: Service, distance: number, weight: number): number => {
   //console.log("distance dalam pricing helper: ", distance);
   //console.log("minKm dalam pricing helper: ", service.minKm);

   if (distance < service.minKm) {
      distance = service.minKm;
   }

   //console.log("distance setelah if statement: ", distance);

   let basePrice = getTransportPriceByDistance(service, distance);
   let surcharge = 0;

   if (weight > service.capacityKg) {
      surcharge = ((basePrice * (weight - service.capacityKg)) / service.capacityKg) * service.overCapacitySurcharge;
   }

   console.log("basePrice: ", basePrice);
   console.log("surcharge: ", surcharge);

   // transworker flat price
   // return roundThousand(basePrice + surcharge);
   // console.log("service: ", JSON.stringify(service, null, 2));
   let price = service.pricePerUnit;

   console.log("calculateTransWorkerPrice: ", price);
   return price;
};

export const calculateAdminFee = (transportFee: number): number => {
   return transportFee <= 250000 ? 10000 : transportFee <= 500000 ? 25000 : 50000;
};

export const roundThousand = (currency: number): number => {
   return Math.round(currency / 1000) * 1000;
};

export const round10Thousand = (currency: number): number => {
   return Math.round(currency / 10000) * 10000;
};

export const round = (currency: number): number => {
   return Math.round(currency);
};

export const getTransportPriceByDistance = (service: Service, distance: number): number => {
   console.log("getTransportPriceByDistance: ", JSON.stringify(service, null, 2));
   console.log("distance: ", distance);

   let basePrice = 0;

   if (service.minKm > distance) {
      distance = service.minKm;
   }

   if (service.priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT) {
      basePrice = service.pricePerUnit;
   } else {
      if (distance <= service.maxKmShort) {
         basePrice = service.pricePerKmShort * (service.serviceCode === Master.SERVICE_CODE_TKBM ? 1 : service.returnPriceRatio / 100);
      } else if (distance <= service.maxKmMed) {
         basePrice = service.pricePerKmMed * (service.serviceCode === Master.SERVICE_CODE_TKBM ? 1 : service.returnPriceRatio / 100);
      } else {
         basePrice = service.pricePerKmLong * (service.serviceCode === Master.SERVICE_CODE_TKBM ? 1 : service.returnPriceRatio / 100);
      }
   }

   // console.log("distancePrice: ", basePrice);

   // if (service.serviceCode !== Master.SERVICE_CODE_TKBM) {
   //    if (service.minPrice) {
   //       basePrice = service.minPrice + (distance > service.minKm ? basePrice * (distance - service.minKm) : 0);
   //    } else {
   //       basePrice = basePrice * distance;
   //    }
   // }

   if (service.serviceCode !== Master.SERVICE_CODE_TKBM) {
      basePrice = basePrice * distance;
      console.log("==============================");
      console.log("basePrice: ", basePrice);
      console.log("minPrice: ", service.minPrice);

      if (basePrice < service.minPrice) {
         basePrice = service.minPrice;
      }
   }

   // console.log("distance: ", distance);
   // console.log("basePrice: ", basePrice);

   return basePrice;
};

export const getDiscount = (voucher?: Voucher, totalCharge?: number): number => {
   let discountAmount = 0;
   let charge = 0;

   if (totalCharge) {
      charge = totalCharge;
   }

   if (voucher) {
      discountAmount = voucher.type === Master.VOUCHER_TYPE_AMOUNT ? voucher.value : (voucher.value / 100) * charge;
      discountAmount = voucher.type === Master.VOUCHER_TYPE_PERCENT && discountAmount > voucher.valueCap ? voucher.valueCap : discountAmount;
   }

   return discountAmount;
};
