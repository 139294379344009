import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Checkbox } from "antd";
import mixpanel from "mixpanel-browser";

import { FadeView, ShipmentSteps, CustomButton } from "../common";

import { transportTypes } from "../../reference/TransportType";

import * as Master from "../../reference/Master";

import * as Config from "../../Config";

import { CacheService, ReferenceService } from "../../service";

import { Shipment, TransportType } from "../../model";

import { TransportTypeOption } from "./transport-type";

import "./SelectTransportType.css";

function SelectTransportType(props: any) {
   const history = useHistory();
   const location = useLocation();
   const { match }: any = props;
   const shipment: Shipment = props.shipment;
   const [requireTkbm, setRequireTkbm] = useState(shipment.isReqTransWorker ? true : false);
   const [transTypes, setTransTypes] = useState(transportTypes.filter((transportType: TransportType) => new RegExp(match.params.category, "i").test(transportType.category)));

   useEffect(() => {
      CacheService.savePrev();
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      props.setShowBottomNavigation(false);
      if (transportTypes.length === 0) {
         ReferenceService.initTransportTypes().then((result) => {
            const types = result.filter((transportType: TransportType) => new RegExp(match.params.category, "i").test(transportType.category));
            setTransTypes(types);
         });
      }
   }, [props, match.params.category]);

   const onSelectTransportType = (transportType: TransportType) => {
      shipment.transportTypeCode = transportType.code;
      shipment.transportTypeDesc = transportType.desc;
      shipment.isProviderRef = false;

      CacheService.setNewShipment(shipment);
      CacheService.setProvider(undefined);
      if (location.pathname.includes("/test/")) {
         CacheService.setTestBooking(true);
      } else {
         CacheService.setTestBooking(false);
      }
      history.push("/new-shipment/" + transportType.code.toLowerCase());
   };

   const requireTkbmSelected = (e: any) => {
      shipment.isReqTransWorker = e.target.checked;
      setRequireTkbm(e.target.checked);
   };

   const orderWhatsApp = () => {
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Halo Customer Service, saya mau cek harga / pesan angkutan"));

      mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
      mixpanel.track("Event WhatsApp Order");
   };

   return (
      <div className="select-transport-type">
         <div className="select-transport-type-content">
            <ShipmentSteps status={Master.SHIPMENT_STATUS_NEW_ORDER} />
            <FadeView show={true} duration="0">
               <div className="select-transort-type-header regular-bottom-border">
                  <div className="select-transort-type-whatsapp">
                     <CustomButton text="Pesan via WhatsApp" icon={"whatsapp"} onClick={orderWhatsApp} style={{}} />
                  </div>
                  <div className="regular-bold-font">Pilih Angkutan</div>
                  <Checkbox onClick={requireTkbmSelected} checked={requireTkbm} className="tkbm regular-bold-font">
                     Membutuhkan tenaga kerja bongkar muat
                  </Checkbox>
               </div>
               <TransportTypeOption data={transTypes} onSelect={onSelectTransportType} />
            </FadeView>
         </div>
      </div>
   );
}

export default SelectTransportType;
