import React from 'react';

import './Download.css';

function Download() {
   let downloadList = [
      {
         title: 'Google Play',
         url: 'https://apps.com',
      },
   ];

   return (
      <div className="download">
         <div className="download-content">
            <div className="download-title">Download Aplikasi</div>
            <div className="download-description">
               Download aplikasi untuk mempermudah akses ke Lintas dari ponsel pintar anda
            </div>
            <div className="download-items">
               {downloadList.map((download, i) => (
                  <div key={i}><a href="https://play.google.com/store/apps/details?id=app.lintas" target="_blank" rel="noopener noreferrer"><img src="images/icon/google-play.png" alt="get it on google play"></img></a></div>
               ))}
            </div>
         </div>
      </div>
   );
}

export default Download;
