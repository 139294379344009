import { gql } from "@apollo/client";

import { Transporter } from "../model";
import { authGraphClient, initAuthClient } from "./GraphService";

class TransporterService {
   async registerTransporter(transporter: Transporter) {
      await initAuthClient();
      console.log("registerTransporter: ", JSON.stringify(transporter, null, 2));

      let g = `
         mutation {
            RegisterTransporter(
               inputTransporter: {
               carrierGid: "${transporter.carrier ? transporter.carrier.gid : ""}", 
               name: "${transporter.name}", 
               dob: "${transporter.dob.toISOString()}", 
               phone: "${transporter.phone}",
               email: "${transporter.email}",
               profileImgID: ${transporter.profileImg.id}, 
               profileImgUrl: "${transporter.profileImgUrl}", 
               citizenCardNo: "${transporter.citizenCardNo}", 
               citizenCardImgID: ${transporter.citizenCardImg?.id}, 
               drivingLicenseNo: "${transporter.drivingLicenseNo}", 
               drivingLicenseExpiry: "${transporter.drivingLicenseExpiry.toISOString()}", 
               drivingLicenseImgID: ${transporter.drivingLicenseImg?.id},
               type: "DRIVER",
               verificationStatus: "",
               status: "",
               }
            )
         }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let transporterID = result.data.RegisterTransporter;

      console.log(result.data);

      return transporterID;
   }

   async updateTransporter(transporter: Transporter) {
      await initAuthClient();
      console.log("updateTransporter: ", JSON.stringify(transporter, null, 2));

      let g = `
            mutation {
               UpdateTransporter(
                  inputTransporter: {
                  gid: "${transporter.gid}"
                  carrierGid: "${transporter.carrier ? transporter.carrier.gid : ""}", 
                  name: "${transporter.name}", 
                  dob: "${transporter.dob.toISOString()}", 
                  phone: "${transporter.phone}", 
                  email: "${transporter.email}",
                  profileImgID: ${transporter.profileImg.id}, 
                  profileImgUrl: "${transporter.profileImgUrl}", 
                  citizenCardNo: "${transporter.citizenCardNo}", 
                  citizenCardImgID: ${transporter.citizenCardImg?.id}, 
                  drivingLicenseNo: "${transporter.drivingLicenseNo}", 
                  drivingLicenseExpiry: "${transporter.drivingLicenseExpiry.toISOString()}", drivingLicenseImgID: ${transporter.drivingLicenseImg?.id}
                  verificationStatus: "${transporter.verificationStatus}",
                  type: "DRIVER",
                  status: "${transporter.status}",
                  }
               )
            }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let transporterID = result.data.RegisterTransporter;

      console.log(result.data);

      return transporterID;
   }

   async searchTransporter(transporter: Transporter, pageSize: number, page: number) {
      await initAuthClient();
      //console.log('searchTransporter: ', JSON.stringify(transporter, null, 2));

      let transporterPageCountGql =
         page === 1
            ? `
            GetTransportersPageCount(
               transporterName: "${transporter.name ? transporter.name : ""}", 
               providerName: "${transporter.provider ? transporter.provider.name : ""}",
               status: "${transporter.status ? transporter.status : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetTransporters(
                  transporterName: "${transporter.name ? transporter.name : ""}", 
                  providerName: "${transporter.provider ? transporter.provider.name : ""}",
                  status: "${transporter.status ? transporter.status : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  id
                  gid
                  name
                  dob
                  profileImgUrl
                  provider {
                     name
                  }
                  carrier {
                     gid
                     name
                     code
                     licenseNo
                  }
                  verificationStatus
               }
               ${transporterPageCountGql}
            }`;

      //console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let resp = { transporters: result.data.GetTransporters, pageCount: result.data.GetTransportersPageCount };

      console.log("searchTransporter: ", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getTransporter(gid?: string) {
      await initAuthClient();
      console.log("getTransporter: ", gid);

      let gqlReq = `
            query { 
               GetTransporter(gid: "${gid}") {
                  id
                  gid
                  name
                  dob
                  phone
                  email
                  carrier {
                     gid
                     code
                     licenseNo
                  }
                  profileImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  citizenCardNo
                  citizenCardImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  drivingLicenseNo
                  drivingLicenseExpiry
                  drivingLicenseImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  verificationStatus
                  status
               }
            }`;

      console.log("gql query: ", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      console.log("result: ", JSON.stringify(result, null, 2));

      let transporter: Transporter = result ? result.data.GetTransporter : null;

      console.log("getTransporter: ", JSON.stringify(transporter, null, 2));

      return transporter;
   }
}

const Service = new TransporterService();

export default Service;
