import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { InputStatic, InputText, FormLayout, PlaceSelection, PlaceMapSelection } from "../../common";

import * as Notification from "../../../constants/Notification";

import { SiteService, ErrorService } from "../../../service";

import * as AlertHelper from "../../../helper/AlertHelper";

import { Place, Site } from "../../../model";

import * as Master from "../../../reference/Master";
import * as PlaceRef from "../../../reference/Place";

import "./SiteForm.css";

let modalContext: ModalContextInterface;

function RegisterNewSite(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const { control, errors, handleSubmit, setValue } = useForm();

   const [site, setSite] = useState<Site>(new Site());
   const [isCreate, setIsCreate] = useState(true);
   const [status, setStatus] = useState("");
   const [showSelectPlace, setShowSelectPlace] = useState(false);
   const [showSelectPlaceMap, setShowSelectPlaceMap] = useState(false);
   const [location, setLocation] = useState<Place>(PlaceRef.defaultPlace);

   const onChangeStatusOptions = (e: any) => {
      setStatus(e.target.value);
   };

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!match.params.id) {
         return;
      }
      setIsCreate(false);
      SiteService.getSite(match.params.id)
         .then((result) => {
            setSite(result);
            setLocation(result.location);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history]);

   useEffect(() => {
      if (!site.gid) {
         return;
      }
      setValue([{ name: site.name }, { desc: site.desc }]);
      setStatus(site.status);
   }, [site, setValue]);

   const onSubmit = (data: any) => {
      const s: Site = new Site();
      s.gid = site.gid;
      s.name = data.name;
      s.desc = data.desc;
      s.status = status;
      s.location = location;

      if (isCreate) {
         AlertHelper.alertLoading(Notification.DATA_REGISTER, modalContext);

         SiteService.registerSite(s)
            .then((result) => {
               AlertHelper.alertSuccess("Proses registrasi pangkalan", modalContext);
               setTimeout(() => history.push("/admin/site"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         AlertHelper.alertLoading(Notification.DATA_SAVING, modalContext);

         SiteService.updateSite(s)
            .then((result) => {
               AlertHelper.alertSuccess("Proses pembaruan data pangkalan", modalContext);
               setTimeout(() => history.push("/admin/site"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   const onBack = () => {
      history.goBack();
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FormLayout doodle="/images/doodle/delivery.png" title={isCreate ? "Registrasi Pangkalan" : "Perubahan Data Pangkalan"} desc={isCreate ? "Mohon lengkapi data pangkalan" : "Mohon perbarui data pangkalan"}>
                  <PlaceSelection show={showSelectPlace} setShow={setShowSelectPlace} setShowSelectPlaceMap={setShowSelectPlaceMap} place={location} setPlace={setLocation} />
                  <PlaceMapSelection show={showSelectPlaceMap} setShow={setShowSelectPlaceMap} setShowSelectPlace={setShowSelectPlace} place={location} setPlace={setLocation} />
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <InputText loading={!isCreate} loadingRef={site.gid} name="name" placeholder="Nama Pangkalan" control={control} error={errors.name} />
                     <InputText loading={!isCreate} loadingRef={site.gid} name="desc" placeholder="Deskripsi" rows={2} control={control} error={errors.desc} />

                     <InputStatic
                        placeholder={"Alamat"}
                        onClick={() => {
                           setShowSelectPlace(true);
                        }}
                        value={location.address}
                     />

                     {!isCreate && <Radio.Group name="status" options={Master.statusOptions} onChange={onChangeStatusOptions} value={status} buttonStyle="solid" />}

                     <div className="action-button">
                        <Button type="primary" htmlType="submit">
                           Simpan
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onBack} className="cancel-button">
                           Batal
                        </Button>
                     </div>
                  </form>
               </FormLayout>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterNewSite;
