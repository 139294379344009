import React from "react";

import { CustomButton } from "../common";

import { ModalContext } from "../../hooks/ModalContext";

import * as Master from "../../reference/Master";

import "./RegisterTransporter.css";

const onContactWa = () => {
   window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Mohon bantuan, saya belum terdaftar sebagai pengemudi."));
};

function RegisterTransporter(props: any) {
   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <div className="register-transporter">
                  <div>
                     <div className="info regular-bold-font">Anda belum terdaftar sebagai pengemudi di lintas. Mohon hubungi Customer Service (CS) untuk bantuan.</div>
                     <div style={{ paddingTop: 10, display: "flex", justifyContent: "center" }}>
                        <CustomButton icon="call" text="Hubungi CS via WA" onClick={onContactWa} />
                     </div>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterTransporter;
