import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import GoogleMapReact from "google-map-react";
import { SelectPlace } from "./select-place";
import { } from "google-maps";
import TimeKeeper from "react-timekeeper";
import { InputNumber } from "antd";
import TagManager from "react-gtm-module";

import { BsChevronCompactRight } from "react-icons/bs";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { TransportTypeItem } from "./transport-type";
import { setShipmentProviderAndFees } from "./SelectProvider";

import { CacheService, ProviderService, ReferenceService } from "../../service";

import * as CalendarHelper from "../../helper/CalendarHelper";
import * as AlertHelper from "../../helper/AlertHelper";

import { SquareButton, MapMarker, Calendar, ShipmentSteps, CustomButton, ModalView, PlaceMapSelection, FeeView } from "../common";

import { Place, TransportType, Payment, Site, Shipment } from "../../model";
import { isCustomer, isProvider } from "../../model/User";

import * as Master from "../../reference/Master";
import * as PlaceRef from "../../reference/Place";
import * as UserRef from "../../reference/User";
import * as TransportTypeRef from "../../reference/TransportType";

import * as Config from "../../Config";
import * as Layout from "../../constants/Layout";

import * as LocationHelper from "../../helper/LocationHelper";
import * as DirectionHelper from "../../helper/DirectionHelper";

import "./NewShipment.css";

let googleMap: any;
let googleMaps: any;
let routePolyline: any;

let modalContext: ModalContextInterface;

function NewShipment(props: any) {
   const history = useHistory();
   const path = window.location.pathname;

   // isReview is true when this module is used by provider to check the service price
   const isReview = path.indexOf("/admin/review") > -1;

   props.shipment.deliveryDate = CalendarHelper.getEarliestDeliveryDate(new Date(props.shipment.deliveryDate));
   if (!props.shipment.payment) {
      props.shipment.payment = new Payment();
   }

   const [date, setDate] = useState(props.shipment.deliveryDate);
   const [distance, setDistance] = useState(0);
   const [duration, setDuration] = useState(0);
   const [weight, setWeight] = useState(props.shipment.weightEst ? props.shipment.weightEst : 1000);
   const [isOrigin, setIsOrigin] = useState(true);
   const [place, setPlace] = useState<Place>(PlaceRef.defaultPlace);
   const [showSelectPlace, setShowSelectPlace] = useState(false);
   const [showSelectPlaceMap, setShowSelectPlaceMap] = useState(false);
   const [selectPlace, setSelectPlace] = useState();
   const [mapCenter, setMapCenter] = useState({ lat: -6.2087634, lng: 106.845599 });

   const [time, setTime] = useState(CalendarHelper.getTime(new Date()));
   const [showTime, setShowTime] = useState(false);
   const [showFee, setShowFee] = useState(false);

   const user = UserRef.newUser;
   const { match } = props;

   const [transportTypes] = useState(TransportTypeRef.transportTypes);
   const [transportType, setTransportType] = useState(transportTypes.filter((transportType: TransportType) => new RegExp(match.params.transportType, "i").test(transportType.code))[0]);

   const { innerWidth: width } = window;
   const originDestWidth = width > 1000 ? (Layout.width - 15) / 3 : width - 40;

   useEffect(() => {
      CacheService.savePrev();
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
      if (transportTypes.length === 0) {
         ReferenceService.initTransportTypes().then((result) => {
            const type = result.filter((transportType: TransportType) => new RegExp(match.params.transportType, "i").test(transportType.code))[0];
            setTransportType(type);
         });
      }
      if (!props.shipment.origin || !props.shipment.destination) {
         setShowSelectPlace(true);
      }
   }, [props, match.params.transportType, transportTypes.length]);

   const onDateSelected = (date: Date) => {
      setDate(date);
   };

   const onClickOriginDestination = () => {
      setShowSelectPlace(true);
   };

   const validate = () => {
      if (!props.shipment.origin || !props.shipment.destination) {
         setShowSelectPlace(true);
         return false;
      }

      let isValidated = true;

      console.log("validate weight: ", weight);
      let message = "";

      if (!weight || weight === 0) {
         message = "Mohon isi estimasi muatan";
         isValidated = false;
      }

      let service = CacheService.getProvider()?.service;
      if (!distance || distance === 0) {
         message = "Rute tidak ditemukan, mohon pastikan lokasi asal dan tujuan muatan sudah benar.";
         isValidated = false;
      } else if (isReview && service && weight > service.maxLoadCapacity) {
         message = "Berat melebihi maksimum kapasitas muatan";
         isValidated = false;
      } else if (weight > parseInt(transportType.capacity) * 1.5) {
         message = "Berat melebihi maksimum kapasitas muatan untuk " + transportType.desc;
         isValidated = false;
      } else if (props.shipment.origin.lat === props.shipment.destination.lat && props.shipment.origin.lng === props.shipment.destination.lng) {
         message = "Lokasi asal dan tujuan tidak boleh sama atau gunakan lokasi yang lebih spesifik. ";
         isValidated = false;
      }

      if (!isValidated) {
         AlertHelper.alertWarning(message, modalContext, "warning");
      }

      return isValidated;
   };

   const onPressNext = (modalContext: ModalContextInterface) => {
      if (!validate()) {
         return;
      }

      if (props.shipment.origin && props.shipment.destination) {
         const hour = CalendarHelper.getHour(props.shipment.deliveryDate);
         const minute = CalendarHelper.getMinute(props.shipment.deliveryDate);
         props.shipment.weightEst = weight;
         props.shipment.requestDate = new Date();
         props.shipment.deliveryDate = CalendarHelper.dateSetHourMinute(date, hour, minute);
         props.shipment.deliveryStartDate = props.shipment.deliveryDate;
         props.shipment.deliveryEndDate = new Date(props.shipment.deliveryStartDate.getTime() + props.shipment.duration * Master.MILISECONDS);

         //console.log('Shipment: ', JSON.stringify(props.shipment, null, 2));
         CacheService.setNewShipment(props.shipment);

         const selectedProvider = CacheService.getProvider();
         console.log("provider: ", JSON.stringify(selectedProvider, null, 2));

         // directly redirect to confirm shipment when provider already selected from referral link
         if (selectedProvider) {
            if (isReview) {
               AlertHelper.alertLoading("Menghitung harga layanan", modalContext);
            } else {
               AlertHelper.alertLoading("Memproses pengiriman", modalContext);
            }

            ProviderService.getProviderNearestSite(selectedProvider, props.shipment.origin).then((site: Site) => {
               AlertHelper.hideLoading(modalContext);
               //props.shipment.provider.site = site;
               selectedProvider.location = site.location;
               selectedProvider.site = site;

               ProviderService.getTollFee(props.shipment.transportTypeCode, props.shipment.origin, props.shipment.destinations).then((tollFeeAmount) => {
                  AlertHelper.hideLoading(modalContext);
                  setShipmentProviderAndFees(props.shipment, selectedProvider, tollFeeAmount, 0);
                  CacheService.setNewShipment(props.shipment);
                  // console.log("shipment: ", JSON.stringify(CacheService.getNewShipment(), null, 2));

                  if (isReview) {
                     setShowFee(true);
                  } else {
                     history.push("/confirm-shipment");
                  }
               });
            });
         } else {
            if (isCustomer(user)) {
               AlertHelper.alertLoading("Mencari mitra lintas terbaik", modalContext);
               gtm();

               setTimeout(() => {
                  AlertHelper.hideLoading(modalContext);
                  history.push("/select-provider");
               }, 1000);
            } else if (isProvider(user)) {
               props.shipment.provider = user.provider;
               //history.push('/confirm-shipment');
            }
         }
      } else {
         // AlertHelper.alertWarning(
         //    !props.shipment.origin && !props.shipment.destination ? "Mohon pilih lokasi asal dan tujuan pengiriman kargo." : !props.shipment.origin ? "Mohon pilih lokasi asal pengiriman kargo." : "Mohon pilih lokasi tujuan pengiriman kargo.",
         //    modalContext
         // );
         setShowSelectPlace(true);
      }
   };

   const apiIsLoaded = (map: any, maps: any) => {
      googleMap = map;
      googleMaps = maps;
      refreshDirection(props.shipment.origin, props.shipment.destination);
   };

   const refreshDirection = useCallback(
      (origin: Place, destination: Place) => {
         // console.log('Origin: ', JSON.stringify(origin, null, 2));
         // console.log('Destination: ', JSON.stringify(origin, null, 2));
         // console.log("Shipment: ", JSON.stringify(props.shipment, null, 2));

         const isFerryRoute = (res: any) => {
            let bakauheni_lat = -5.87;
            let bakauheni_lng = 105.75;

            let merak_lat = -5.93;
            let merak_lng = 106.0;

            let ketapang_lat = -8.14;
            let ketapang_lng = 114.4;

            let gilimanuk_lat = -8.16;
            let gilimanuk_lng = 114.44;

            let balikpapan_lat = -1.27;
            let balikpapan_lng = 116.81;

            let surabaya_lat = -7.21;
            let surabaya_lng = 112.73;

            let path = res.path;
            for (let i = 0; i < path.length; i++) {
               let p = res.path[i];

               let pLat = Math.round(p.lat * 100) / 100;
               let pLng = Math.round(p.lng * 100) / 100;

               // console.log("LatLng: ", pLat, ",", pLng);

               if ((pLat === bakauheni_lat && pLng === bakauheni_lng) || (pLat === merak_lat && pLng === merak_lng)) {
                  let harbor = "Bakauheni, Lampung";
                  props.shipment.isFerryShipment = true;
                  props.setHarbor(harbor);
                  CacheService.setHarbor(harbor);
                  console.log("=== FERRY TO/FROM BAKAUHENI ===");
                  break;
               } else if ((pLat === gilimanuk_lat && pLng === gilimanuk_lng) || (pLat === ketapang_lat && pLng === ketapang_lng)) {
                  let harbor = "Gilimanuk, Bali";
                  props.shipment.isFerryShipment = true;
                  props.setHarbor(harbor);
                  CacheService.setHarbor(harbor);
                  console.log("=== FERRY TO/FROM GILIMANUK ===");
                  break;
               } else if ((pLat === balikpapan_lat && pLng === balikpapan_lng) || (pLat === surabaya_lat && pLng === surabaya_lng)) {
                  let harbor = "Balikpapan, Kalimantan Timur";
                  props.shipment.isFerryShipment = true;
                  props.setHarbor(harbor);
                  CacheService.setHarbor(harbor);
                  console.log("=== FERRY TO/FROM BALIKPAPAN ===");
                  break;
               } else {
                  props.setHarbor("");
                  CacheService.setHarbor("");
                  continue;
               }
            }
         };

         if (!googleMaps) {
            return;
         }

         //const directionsDisplay = new googleMaps.DirectionsRenderer();
         DirectionHelper.initGoogleMaps(googleMaps);

         origin &&
            destination &&
            DirectionHelper.getDirectionMaps(origin, props.shipment.destinations, (response: any, status: any) => {
               if (status === "OK") {
                  // directionsDisplay.setDirections(response);
                  // console.log("path: ", response.path);
                  const path = response.path;
                  if (routePolyline) {
                     routePolyline.setMap(null);
                  }
                  routePolyline = new google.maps.Polyline({
                     path: path,
                     geodesic: true,
                     strokeColor: "hotpink",
                     strokeOpacity: 1.0,
                     strokeWeight: 3,
                  });
                  routePolyline.setMap(googleMap);

                  const distance = response.distance;
                  const duration = Math.round(response.duration * Master.GOOGLE_MAPS_DURATION_RATIO);

                  setDistance(distance);
                  setDuration(duration);

                  props.shipment.distance = distance;
                  props.shipment.duration = duration;

                  // console.log("direction: ", JSON.stringify(response, null, 2));

                  isFerryRoute(response);

                  let bounds = new google.maps.LatLngBounds();

                  let place: Place = props.shipment.origin;
                  bounds.extend(new google.maps.LatLng(place.lat, place.lng));

                  for (let i = 0; i < props.shipment.destinations.length; i++) {
                     place = props.shipment.destinations[i];
                     bounds.extend(new google.maps.LatLng(place.lat, place.lng));
                  }

                  googleMap.fitBounds(bounds);
               } else {
                  window.alert("Directions request failed due to " + status);
               }
            });
      },
      [props.shipment, props.setHarbor]
   );

   useEffect(() => {
      if (googleMap && googleMaps) {
         refreshDirection(props.shipment.origin, props.shipment.destination);
      }
   }, [props.shipment.origin, props.shipment.destination, refreshDirection]);

   useEffect(() => {
      if (props.shipment.origin && props.shipment.destination) {
         setMapCenter({
            lat: props.shipment.origin.lat + (props.shipment.destination.lat - props.shipment.origin.lat) / 2,
            lng: props.shipment.origin.lng + (props.shipment.destination.lng - props.shipment.origin.lng) / 2,
         });
      }
   }, [props.shipment.origin, props.shipment.destination]);

   useEffect(() => {
      if (!showSelectPlace && window.innerWidth < 1000) {
         window.scrollTo(0, 70);
      }
   }, [showSelectPlace]);

   const gtm = () => {
      // gtm conversion
      const tagManagerArgs = {
         gtmId: "GTM-MW67KPL",
      };
      TagManager.initialize(tagManagerArgs);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <SelectPlace
                     show={showSelectPlace}
                     setShow={setShowSelectPlace}
                     showSelectPlaceMap={showSelectPlaceMap}
                     setShowSelectPlaceMap={setShowSelectPlaceMap}
                     isOrigin={isOrigin}
                     setIsOrigin={setIsOrigin}
                     setPlace={setPlace}
                     setSelectPlace={setSelectPlace}
                     shipment={props.shipment}
                  />
                  <PlaceMapSelection show={showSelectPlaceMap} setShow={setShowSelectPlaceMap} setShowSelectPlace={setShowSelectPlace} setPlace={selectPlace} place={place} />
                  <FeeView show={showFee} setShow={setShowFee} shipment={props.shipment} />
                  <div className="new-shipment">
                     <div className="new-shipment-content" style={{ minHeight: isReview ? 760 : 820 }}>
                        {!isReview && <ShipmentSteps status={props.shipment.status} />}
                        <div className="new-shipment-map regular-border">
                           <div className="regular-bottom-border" style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
                              {transportType && isReview && <TransportTypeItem single transportType={transportType} tkbm={props.shipment.isReqTransWorker} service={CacheService.getProvider().service} />}
                              {transportType && !isReview && <TransportTypeItem simple single transportType={transportType} tkbm={props.shipment.isReqTransWorker} />}
                              <div className="new-shipment-options" style={isReview ? { justifyContent: "flex-start" } : {}}>
                                 {!isReview && (
                                    <div className="new-shipment-calendar-panel">
                                       <span className="new-shipment-calendar-title regular-bold-font">Tanggal</span>
                                       <div className="new-shipment-calendar">
                                          <Calendar date={date} setDate={onDateSelected}></Calendar>
                                       </div>
                                    </div>
                                 )}
                                 {!isReview && (
                                    <div className="new-shipment-time-panel">
                                       <span className="new-shipment-time-title regular-bold-font">Jam</span>
                                       <div className="new-shipment-time-content">
                                          <div className={"new-shipment-time regular-dash-border regular-bold-font"} style={window.innerWidth < 1000 ? { minWidth: 95 } : {}}>
                                             {CalendarHelper.getDayTime(props.shipment.deliveryDate)}
                                          </div>
                                          <CustomButton icon="clock" onClick={() => setShowTime(true)} />
                                          <ModalView show={showTime}>
                                             <TimeKeeper
                                                time={time}
                                                onChange={(newTime) => {
                                                   props.shipment.deliveryDate = CalendarHelper.dateSetHourMinute(props.shipment.deliveryDate, newTime.hour, newTime.minute);
                                                   props.shipment.deliveryStartDate = props.shipment.deliveryDate;
                                                   props.shipment.deliveryEndDate = new Date(props.shipment.deliveryStartDate.getTime() + props.shipment.duration * Master.MILISECONDS);
                                                   setTime(newTime.formatted12);
                                                }}
                                                onDoneClick={() => setShowTime(false)}
                                                switchToMinuteOnHourSelect
                                             />
                                          </ModalView>
                                       </div>
                                    </div>
                                 )}
                                 <div className="new-shipment-weight-panel">
                                    <span className="new-shipment-weight-title regular-bold-font">Estimasi Muatan</span>
                                    <div className={"new-shipment-weight"}>
                                       <InputNumber
                                          name="weight"
                                          placeholder={"Berat Kg"}
                                          defaultValue={weight}
                                          onChange={(value: any) => {
                                             setWeight(value);
                                          }}
                                          style={{ backgroundColor: weight > 0 ? "" : "#f4cbc8" }}
                                       />
                                       <span className="regular-bold-font" style={{ marginLeft: 5 }}>
                                          Kg
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <GoogleMapReact bootstrapURLKeys={{ key: Config.GOOGLE_MAP_KEY }} center={mapCenter} defaultZoom={11} options={{ disableDefaultUI: true }} onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}>
                              {props.shipment.origin && <MapMarker size="small" lat={props.shipment.origin.lat} lng={props.shipment.origin.lng} />}
                              {props.shipment.destinations && props.shipment.destinations.map((dest: Place, key: number) => <MapMarker key={key} color="blue" size="small" lat={dest.lat} lng={dest.lng} />)}
                           </GoogleMapReact>
                        </div>
                        <div className="bottom-panel">
                           <div className="bottom-content origin-destination">
                              <div onClick={onClickOriginDestination} style={{ cursor: "pointer", width: originDestWidth }}>
                                 <div className="bottom-selection regular-border">
                                    <div>
                                       <span className="dot-red"></span>
                                    </div>
                                    <div className="bottom-selection-left">
                                       {props.shipment.origin && props.shipment.origin.address && <div className="regular-bold-font">{LocationHelper.getSubRegionCity(props.shipment.origin.address)}</div>}
                                       {props.shipment.origin && props.shipment.origin.address && <div className="regular-font">{LocationHelper.getPlaceShortAddress(props.shipment.origin)}</div>}
                                       {!props.shipment.origin && (
                                          <div style={{ paddingTop: 5 }}>
                                             <span className="origin-select regular-bold-white-font">Pilih Lokasi Asal</span>
                                          </div>
                                       )}
                                    </div>
                                    <div className="bottom-selection-right">
                                       <BsChevronCompactRight className="arrow-right" />
                                    </div>
                                 </div>
                              </div>
                              {props.shipment.destinations.map((destination: Place, key: number) => (
                                 <div key={key} onClick={onClickOriginDestination} style={{ cursor: "pointer", width: originDestWidth }}>
                                    <div className="bottom-selection regular-border">
                                       <div>
                                          <span className="dot-blue"></span>
                                       </div>
                                       <div className="bottom-selection-left">
                                          {destination && destination.address && <div className="regular-bold-font">{LocationHelper.getSubRegionCity(destination.address)}</div>}
                                          {destination && destination.address && <div className="regular-font">{LocationHelper.getPlaceShortAddress(destination)}</div>}
                                          {!destination.address && (
                                             <div style={{ paddingTop: 5 }}>
                                                <span className="origin-select regular-bold-white-font">Pilih Lokasi Tujuan</span>
                                             </div>
                                          )}
                                       </div>
                                       <div className="bottom-selection-right">
                                          <BsChevronCompactRight className="arrow-right" />
                                       </div>
                                    </div>
                                 </div>
                              ))}
                              <div onClick={onClickOriginDestination} style={{ cursor: "pointer", width: originDestWidth }}>
                                 <div className="distance-duration regular-border">
                                    <div className="distance-duration-left">
                                       <div className="distance-duration-content">
                                          <div className="distance-duration-label regular-bold-font">Jarak Tempuh</div>
                                          <div className="distance-duration-text regular-bold-font">{LocationHelper.formatDistance(distance)}</div>
                                       </div>
                                       <div className="distance-duration-content">
                                          <div className="distance-duration-label regular-bold-font">Waktu Tempuh</div>
                                          <div className="distance-duration-text regular-bold-font">{LocationHelper.formatTripTime(duration)}</div>
                                       </div>
                                    </div>
                                    <div className="distance-duration-right"></div>
                                 </div>
                              </div>
                           </div>
                           <div className="bottom-content">
                              <SquareButton
                                 text={isReview ? "Cek Harga Angkutan Layanan" : "Cari Truk Angkutan"}
                                 next
                                 onClick={() => {
                                    onPressNext(modalContext);
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default NewShipment;
