import React from 'react';

import TransportTypeItem from '../../common/TransportType';

import './TransportTypeOption.css';

function TransportTypeOption(props: any) {
   return (
      <div className="transportType">
         <div className="transportTypes">
            {props.data.map((transportType: any, key: any) => (
               <TransportTypeItem transportType={transportType} onSelect={props.onSelect} key={key} />
            ))}
         </div>
      </div>
   );
}

export default TransportTypeOption;
