import {Transporter} from '../model';
import * as Image from './Image';

export const newTransporter: Transporter = {
   name: '',
   dob: new Date(),
   phone: '',
   citizenCardNo: '',
   drivingLicenseNo: '',
   drivingLicenseExpiry: new Date(),
   mobile: '',
   email: '',
   profileImg: Image.newImage,
   provider: undefined,
   status: '',
   verificationStatus: '',
   carrier: undefined,
};

export const getEmptyTransporters = (count: number) => {
   const transporters = Array<Transporter>();
   for (let i = 0; i < count; i++) {
      transporters.push(newTransporter);
   }
   return transporters;
};

export const transporters: Transporter[] = [
   {
      id: 1,
      name: 'Burhan Ali',
      dob: new Date(),
      phone: '082221911516',
      citizenCardNo: '364223553455666',
      drivingLicenseNo: '143533522257889',
      drivingLicenseExpiry: new Date(),
      mobile: '08213433433215',
      email: 'burhan.ali@gmail.com',
      profileImg: Image.newImage,
      citizenCardImg: Image.newImage,
      drivingLicenseImg: Image.newImage,
      provider: undefined,
      status: '',
      verificationStatus: '',
   },
   {
      id: 2,
      name: 'Wismoyo Sahid',
      dob: new Date(),
      phone: '082221911516',
      citizenCardNo: '364223553455666',
      drivingLicenseNo: '143533522257889',
      drivingLicenseExpiry: new Date(),
      mobile: '08213433433215',
      email: 'burhan.ali@gmail.com',
      profileImg: Image.newImage,
      citizenCardImg: Image.newImage,
      drivingLicenseImg: Image.newImage,
      provider: undefined,
      status: '',
      verificationStatus: '',
   },
   {
      id: 3,
      name: 'Budi Kusuma',
      dob: new Date(),
      phone: '082221911516',
      citizenCardNo: '364223553455666',
      drivingLicenseNo: '143533522257889',
      drivingLicenseExpiry: new Date(),
      mobile: '08213433433215',
      email: 'burhan.ali@gmail.com',
      profileImg: Image.newImage,
      citizenCardImg: Image.newImage,
      drivingLicenseImg: Image.newImage,
      provider: undefined,
      status: '',
      verificationStatus: '',
   },
];
