import React, { useState } from "react";

import "./MapMarker.css";

export default function MapMarker(props: any) {
   const color = props.color ? props.color : "red";
   const size = props.size ? props.size : "regular";
   const image = props.pin ? "pin-" : "marker-";
   const [showTooltip, setShowTooltip] = useState(false);
   const onMouseOver = (e: any) => {
      setShowTooltip(true);
   };
   const onMouseExit = (e: any) => {
      setShowTooltip(false);
   };
   return (
      <div>
         <div>
            <img
               alt="marker"
               src={process.env.PUBLIC_URL + `/images/maps/${image}${color}.png`}
               style={{
                  width: size === "tiny" ? 20 : "small" ? 30 : 40,
                  height: size === "tiny" ? 20 : "small" ? 30 : 40,
                  position: "absolute",
                  top: size === "tiny" ? -20 : "small" ? -30 : -40,
                  left: size === "tiny" ? -10 : "small" ? -15 : -20,
               }}
               onMouseOver={onMouseOver}
               onMouseOut={onMouseExit}
            />
            {showTooltip && props.text && (
               <div className="map-container">
                  <div className="map-tooltip" style={{ top: size === "tiny" ? -50 : "small" ? -60 : -70, left: size === "tiny" ? 15 : "small" ? 20 : 25 }}>
                     {props.text}
                     <br />
                     {props.desc}
                  </div>
                  <div className="map-filler"></div>
               </div>
            )}
         </div>
      </div>
   );
}
