import {Image} from '../model';

export const newImage: Image = new Image().init(
   -1,
   '', //'image.png',
   '', //'done', // UploadFileStatus
   0, //1024,
   'image/jpeg',
   '' //'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
);
