import { gql } from "@apollo/client";

import { Direction } from "../model";
// import { graphClient, authGraphClient, initClient, initAuthClient } from "./GraphService";
import { graphClient, initClient } from "./GraphService";

class DirectionService {
   async registerDirection(direction: Direction) {
      await initClient();
      //console.log("registerDirection:", JSON.stringify(direction, null, 2));

      let path = direction.path;

      path = path.replaceAll("\\", "\\\\");
      path = path.replaceAll('"', '\\"');

      let g = `
         mutation {
            RegisterDirection(
               inputDirection: {
                  route: "${direction.route}", 
                  path: "${path}",
               },
            )
         }`;

      //console.log("gql:", g);

      let result = await graphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let directionID = result.data.RegisterDirection;

      //console.log(result.data);

      return directionID;
   }

   async getDirection(route?: string) {
      await initClient();
      //console.log("getDirection:", route);

      let gqlReq = `
            query { 
               GetDirection(route: "${route}") {
                  id
                  route
                  path
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await graphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log("result:", JSON.stringify(result, null, 2));

      let direction: Direction = result ? result.data.GetDirection : null;

      // console.log("getDirection:", JSON.stringify(direction, null, 2));

      return direction;
   }
}

const Service = new DirectionService();

export default Service;
