import React, { useEffect } from "react";
import { Card } from "antd";
import { useHistory } from "react-router-dom";

import { ShipmentSteps } from "../common";

import { CacheService } from "../../service";

import * as Master from "../../reference/Master";

import "./ChangeCategory.css";

function Categories(props: any) {
   let categories = [
      { code: Master.CATEGORY_PICKUP, desc: "Pick Up", url: "./category/category-pickup.svg" },
      { code: Master.CATEGORY_VAN, desc: "Van", url: "./category/category-van.svg" },
      { code: Master.CATEGORY_COLT_DIESEL, desc: "Colt Diesel", url: "./category/category-cdd.svg" },
      { code: Master.CATEGORY_FUSO, desc: "Fuso", url: "./category/category-fuso.svg" },
      { code: Master.CATEGORY_TRONTON, desc: "Tronton", url: "./category/category-tronton.svg" },
      { code: Master.CATEGORY_WINGBOX, desc: "Wingbox", url: "./category/category-wingbox.svg" },
      { code: Master.CATEGORY_TRAILER, desc: "Trailer", url: "./category/category-trailer.svg" },
      { code: Master.CATEGORY_CONTAINER, desc: "Container", url: "./category/category-container.svg" },
   ];
   const { Meta } = Card;

   return (
      <div className="booking-category-content">
         {categories.map((category, i) => (
            <div
               onClick={() => {
                  props.onSelect(category.code);
               }}
               key={i}
            >
               <Card key={i} hoverable style={{ width: 240 }} cover={<img alt={category.desc} src={process.env.PUBLIC_URL + "/images/" + category.url} />} className="booking-category-item">
                  <Meta title={category.desc} />
               </Card>
            </div>
         ))}
      </div>
   );
}

function ChangeCategory(props: any) {
   const history = useHistory();

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   const onSelectCategory = (category: string) => {
      CacheService.setCategory(category);
      history.push("/change-transport-type/" + category);
   };

   return (
      <div className="change-category">
         <div className="change-category">
            <div className="change-category-steps">
               <ShipmentSteps status={Master.SHIPMENT_STATUS_NEW_ORDER} />
            </div>
            <Categories onSelect={onSelectCategory} />
         </div>
      </div>
   );
}

export default ChangeCategory;
