import React from 'react';
// import {BrowserRouter as Router} from 'react-router-dom';
import { Link } from 'react-router-dom';

import './FooterItem.css';

function FooterItem(props: any) {
   const footerItem = props.footerItem;

   return (
      // <Router>
         <div className="footer-item">
            <div className="footer-item-top">{footerItem.title}</div>
            {footerItem.items.map((item: any, i: number) =>
               item.type === 'text' ? (
                  <Link to={item.url} className="footer-item-link" key={i}>
                     {item.title}
                  </Link>
               ) : (
                  <Link to={item.url} key={i}>
                     <i className={`fa ${item.icon} fa-lg footer-item-icon ${item.label}`} />
                  </Link>
               )
            )}
         </div>
      // </Router>
   );
}

export default FooterItem;
