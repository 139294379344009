import * as React from "react";

import { ModalContext } from "../../hooks/ModalContext";

import { Fee } from "../../model";

import { SubNavigation, ModalView } from ".";

import * as FormatHelper from "../../helper/FormatHelper";
import * as AlertHelper from "../../helper/AlertHelper";

import "./FeeView.css";

//let modalContext: ModalContextInterface;

export function FeeView(props: any) {
   const onClickPrev = () => {
      props.setShow(false);
   };
   const onClickClose = () => {
      props.setShow(false);
   };

   React.useEffect(() => {
      AlertHelper.enableScroll(!props.show, "FeeView");
   }, [props.show]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            //modalContext = context;
            return (
               <ModalView show={props.show}>
                  <div className="fee-view">
                     <SubNavigation title="Biaya Angkutan" small onClickPrev={onClickPrev} onClickClose={onClickClose} />
                     <div className="fee-view-content">
                        {props.shipment.fees &&
                           props.shipment.fees
                              .filter((fee: Fee) => fee.subTotal > 0)
                              .map((fee: Fee, key: any) => (
                                 <div key={key} className="fee-view-item">
                                    <div className="fee-view-item-desc regular-bold-font">{fee.description}</div>
                                    <div className="fee-view-item-subtotal regular-bold-font">{fee.subTotal !== -1 && FormatHelper.formatCurrency(fee.subTotal)}</div>
                                 </div>
                              ))}
                        {props.shipment.payment.discountAmount > 0 && (
                           <div className="fee-view-item">
                              <div className="fee-view-item-desc regular-bold-font fee-view-promotion">Promosi {props.voucherCode}</div>
                              <div className="fee-view-item-subtotal regular-bold-font fee-view-promotion">{FormatHelper.formatCurrency(-props.shipment.payment.discountAmount)}</div>
                           </div>
                        )}
                        <div className="fee-view-total regular-top-border">
                           <div className="fee-view-item-desc regular-bold-font">Total</div>
                           <div className="fee-view-item-subtotal regular-bold-font">{FormatHelper.formatCurrency(props.shipment.payment.totalPaymentAmount)}</div>
                        </div>
                     </div>
                  </div>
               </ModalView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default FeeView;
