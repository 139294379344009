import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import * as FormatHelper from "../../../helper/FormatHelper";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { InputNumber, InputText, InputSelect, FormLayout } from "../../common";

import * as Notification from "../../../constants/Notification";

import { ServiceService, ErrorService, CacheService } from "../../../service";

import * as AlertHelper from "../../../helper/AlertHelper";

import { Service } from "../../../model";
import { isAdmin } from "../../../model/User";

import * as Master from "../../../reference/Master";

import { getServiceOptionSet } from "../../../reference/Service";

import "./ServiceForm.css";

let modalContext: ModalContextInterface;

function RegisterNewService(props: any) {
   const history = useHistory();

   const user = CacheService.getUser();
   const { match }: any = props;
   const { control, errors, handleSubmit, setValue } = useForm();
   const [service, setService] = useState<Service>(new Service());
   const [isCreate, setIsCreate] = useState(true);
   const [priceScheme, setPriceScheme] = useState("");
   const [serviceType, setServiceType] = useState(Master.SERVICE_TYPE_TRANSPORT);
   const [serviceCode, setServiceCode] = useState("");
   const [transportType, setTransportType] = useState("");
   const [transportTypeOptions, setTransportTypeOptions] = useState([]);
   const [isAllServiceRegistered, setIsAllServiceRegistered] = useState(false);
   const [initCompleted, setInitCompleted] = useState(false);
   const [verificationStatus, setVerificationStatus] = useState("");
   const [status, setStatus] = useState("");

   const onChangeStatusOptions = (e: any) => {
      setStatus(e.target.value);
   };

   const onChangeVerifiedStatusOptions = (e: any) => {
      setVerificationStatus(e.target.value);
   };

   const onChangePriceSchemeOptions = (e: any) => {
      setPriceScheme(e.target.value);
   };

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      let service = new Service();
      service.serviceTypeCode = "";
      service.serviceDesc = "";
      service.status = "";

      let availableServices = new Map();
      ServiceService.searchService(service, 50, 1)
         .then((result) => {
            let services = result.services;
            services.forEach((service: Service) => {
               availableServices.set(service.serviceCode, service);
            });

            ServiceService.getServiceOptions()
               .then((serviceOptions) => {
                  let options = serviceOptions;
                  if (isCreate) {
                     options = options.filter((service: string) => {
                        if (availableServices.has(service)) {
                           return false;
                        } else {
                           return true;
                        }
                     });
                  }
                  options = options.map((option: string) => ({
                     value: getServiceOptionSet().get(option)?.code,
                     label: getServiceOptionSet().get(option)?.desc,
                  }));
                  setTransportTypeOptions(options);
                  setInitCompleted(true);
               })
               .catch((error) => {
                  ErrorService.handleError(error, history, modalContext);
               });
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history, isCreate]);

   useEffect(() => {
      if (!match.params.id) {
         return;
      }
      setIsCreate(false);
      ServiceService.getService(match.params.id)
         .then((result) => {
            //console.log('service: ', JSON.stringify(result, null, 2));
            setService(result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history]);

   useEffect(() => {
      setIsAllServiceRegistered(transportTypeOptions.length === 0);
   }, [transportTypeOptions]);

   useEffect(() => {
      if (!service.id) {
         return;
      }
      setServiceType(service.serviceTypeCode);
      //setStatus(service.status);
   }, [service]);

   useEffect(() => {
      if (!service) {
         return;
      }
      setServiceCode(service.serviceCode);
      setPriceScheme(service.priceScheme);
      setVerificationStatus(service.verificationStatus);
      setStatus(service.status);
   }, [service, setValue]);

   useEffect(() => {
      setValue([
         { provider: service.provider?.name },
         { name: getServiceOptionSet().get(service.serviceCode)?.desc },
         { transportType: service.serviceCode },
         { pricePerUnit: service.pricePerUnit },
         { priceUom: service.priceUom },
         { pricePerKmShort: service.pricePerKmShort },
         { maxKmShort: service.maxKmShort },
         { pricePerKmMed: service.pricePerKmMed },
         { maxKmMed: service.maxKmMed },
         { pricePerKmLong: service.pricePerKmLong },
         { minKm: service.minKm },
         { minPrice: service.minPrice },
         { pickupPricePerKm: service.pickupPricePerKm },
         { pickupFreeKmDistance: service.pickupFreeKmDistance },
         { pickupCoverageKm: service.pickupCoverageKm },
         { returnPriceRatio: service.returnPriceRatio },
         { coverageKm: service.coverageKm },
         { transitPrice: service.transitPrice },
         { overCapacitySurcharge: service.overCapacitySurcharge },
         { capacityKg: service.capacityKg },
         { maxLoadCapacity: service.maxLoadCapacity },
      ]);
   }, [priceScheme, setValue, service]);

   const onSubmit = (data: any) => {
      let transportType = isAdmin(user) || !isCreate ? service.serviceCode : data.transportType;
      let serviceOption = getServiceOptionSet().get(transportType);

      const serviceTypeCode = serviceOption ? serviceOption.type : Master.SERVICE_TYPE_ADDON;
      const uom = serviceOption ? serviceOption.uom : Master.UOM_KM;

      const s: Service = new Service();
      s.gid = service.gid;
      s.serviceTypeCode = serviceTypeCode;
      s.priceScheme = priceScheme;
      s.serviceCode = transportType;
      s.pricePerUnit = data.pricePerUnit;
      s.pricePerKmShort = data.pricePerKmShort;
      s.maxKmShort = data.maxKmShort;
      s.pricePerKmMed = data.pricePerKmMed;
      s.maxKmMed = data.maxKmMed;
      s.pricePerKmLong = data.pricePerKmLong;
      s.minKm = data.minKm;
      s.minPrice = data.minPrice;
      s.priceUom = uom;
      s.pickupPricePerKm = data.pickupPricePerKm;
      s.pickupFreeKmDistance = data.pickupFreeKmDistance;
      s.pickupCoverageKm = data.pickupCoverageKm;
      s.returnPriceRatio = data.returnPriceRatio;
      s.coverageKm = data.coverageKm;
      s.transitPrice = data.transitPrice;
      s.overCapacitySurcharge = data.overCapacitySurcharge;
      s.capacityKg = data.capacityKg;
      s.maxLoadCapacity = data.maxLoadCapacity;
      s.verificationStatus = verificationStatus;
      s.status = status;

      console.log(JSON.stringify(s, null, 2));

      if (isCreate) {
         AlertHelper.alertLoading(Notification.DATA_REGISTER, modalContext);

         ServiceService.registerService(s)
            .then((result) => {
               AlertHelper.alertSuccess("Proses registrasi layanan", modalContext);
               setTimeout(() => history.push("/admin/service"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         AlertHelper.alertLoading(Notification.DATA_SAVING, modalContext);

         ServiceService.updateService(s)
            .then((result) => {
               AlertHelper.alertSuccess("Proses pembaruan data layanan", modalContext);
               setTimeout(() => history.push("/admin/service"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   const onBack = () => {
      history.goBack();
   };

   const onSelectTransportType = (value: any, event: any) => {
      let serviceOption = getServiceOptionSet().get(value);
      let serviceType = serviceOption?.type;
      let serviceCode = serviceOption?.code || "";
      console.log("serviceCode: ", serviceCode);
      setServiceType(serviceType ? serviceType : Master.SERVICE_TYPE_TRANSPORT);
      setServiceCode(serviceCode);
      setPriceScheme(serviceCode === Master.SERVICE_CODE_TKBM ? Master.SERVICE_PRICE_SCHEME_WEIGHT : "");
      setTransportType(value || "");
   };

   useEffect(() => {
      console.log("serviceCode Update: ", serviceCode);
   }, [serviceCode]);

   useEffect(() => {
      setValue([{ transportType: transportType }]);
   }, [transportType, setValue]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FormLayout
                  doodle="/images/doodle/delivery.png"
                  title={isCreate ? "Registrasi Layanan" : "Perubahan Data Layanan"}
                  desc={isCreate && isAllServiceRegistered && initCompleted ? "Semua layanan telah terdaftar" : isCreate ? "Mohon lengkapi data layanan" : "Mohon perbarui data layanan"}
               >
                  {!(isCreate && isAllServiceRegistered) && (
                     <form onSubmit={handleSubmit(onSubmit)}>
                        {!user.provider && <InputText loading={!isCreate} loadingRef={service.serviceCode} readOnly={true} name="provider" placeholder={`Penyedia Jasa`} control={control} />}
                        {!user.provider && <InputText loading={!isCreate} loadingRef={service.serviceCode} readOnly={true} name="name" placeholder={`Layanan Angkutan`} control={control} />}
                        {user.provider && isCreate && (
                           <InputSelect
                              loading={!isCreate}
                              loadingRef={service.serviceCode}
                              name="transportType"
                              placeholder="Layanan Angkutan"
                              control={control}
                              error={errors.transportType}
                              options={transportTypeOptions}
                              onSelect={onSelectTransportType}
                           />
                        )}
                        {user.provider && !isCreate && <InputText loading={!isCreate} loadingRef={service.serviceCode} name="name" placeholder="Layanan Angkutan" control={control} readOnly={true} error={errors.transportType} />}
                        {serviceCode === Master.SERVICE_CODE_TKBM && <Radio.Group name="priceScheme" options={Master.servicePriceSchemeOptions} onChange={onChangePriceSchemeOptions} value={priceScheme} buttonStyle="solid" />}
                        <div>
                           {(serviceType === Master.SERVICE_TYPE_TRANSPORT || priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT_DISTANCE) && (
                              <div>
                                 <InputNumber
                                    loading={!isCreate}
                                    loadingRef={service.serviceCode}
                                    name="pricePerKmShort"
                                    placeholder={priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT_DISTANCE ? `Harga Jarak Pendek` : `Harga Jarak Pendek Per KM`}
                                    control={control}
                                    error={errors.pricePerKmShort}
                                    formatter={(value: string) => {
                                       value = FormatHelper.formatCurrencyToNumber(value);
                                       return FormatHelper.formatCurrency(parseInt(value));
                                    }}
                                    parser={(value: string) => {
                                       return FormatHelper.formatCurrencyToNumber(value);
                                    }}
                                 />
                                 <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="maxKmShort" placeholder={`Batas KM Jarak Pendek`} control={control} error={errors.maxKmShort} />
                                 <InputNumber
                                    loading={!isCreate}
                                    loadingRef={service.serviceCode}
                                    name="pricePerKmMed"
                                    placeholder={priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT_DISTANCE ? `Harga Menengah Pendek` : `Harga Jarak Menengah Per KM`}
                                    control={control}
                                    error={errors.pricePerKmMed}
                                    formatter={(value: string) => {
                                       value = FormatHelper.formatCurrencyToNumber(value);
                                       return FormatHelper.formatCurrency(parseInt(value));
                                    }}
                                    parser={(value: string) => {
                                       return FormatHelper.formatCurrencyToNumber(value);
                                    }}
                                 />
                                 <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="maxKmMed" placeholder={`Batas KM Jarak Menengah`} control={control} error={errors.maxKmMed} />
                                 <InputNumber
                                    loading={!isCreate}
                                    loadingRef={service.serviceCode}
                                    name="pricePerKmLong"
                                    placeholder={priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT_DISTANCE ? `Harga Jarak Panjang` : `Harga Jarak Panjang Per KM`}
                                    control={control}
                                    error={errors.pricePerKmLong}
                                    formatter={(value: string) => {
                                       value = FormatHelper.formatCurrencyToNumber(value);
                                       return FormatHelper.formatCurrency(parseInt(value));
                                    }}
                                    parser={(value: string) => {
                                       return FormatHelper.formatCurrencyToNumber(value);
                                    }}
                                 />
                              </div>
                           )}
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="minKm" placeholder={`Minimal Jarak Pemesanan`} control={control} error={errors.minKm} />}
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && (
                              <InputNumber
                                 loading={!isCreate}
                                 loadingRef={service.serviceCode}
                                 name="minPrice"
                                 placeholder={`Minimal Biaya Angkutan`}
                                 control={control}
                                 error={errors.minPrice}
                                 nullable
                                 formatter={(value: string) => {
                                    value = FormatHelper.formatCurrencyToNumber(value);
                                    return FormatHelper.formatCurrency(parseInt(value));
                                 }}
                                 parser={(value: string) => {
                                    return FormatHelper.formatCurrencyToNumber(value);
                                 }}
                              />
                           )}
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && (
                              <InputNumber
                                 loading={!isCreate}
                                 loadingRef={service.serviceCode}
                                 name="pickupPricePerKm"
                                 placeholder={`Harga Jarak Penjemputan Per KM`}
                                 control={control}
                                 error={errors.pickupPricePerKm}
                                 formatter={(value: string) => {
                                    value = FormatHelper.formatCurrencyToNumber(value);
                                    return FormatHelper.formatCurrency(parseInt(value));
                                 }}
                                 parser={(value: string) => {
                                    return FormatHelper.formatCurrencyToNumber(value);
                                 }}
                                 nullable
                              />
                           )}
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && (
                              <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="pickupFreeKmDistance" placeholder={`Jarak Km Penjemputan Bebas Biaya`} control={control} error={errors.pickupFreeKmDistance} nullable />
                           )}
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && (
                              <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="pickupCoverageKm" placeholder={`Jarak Max Km Penjemputan`} control={control} error={errors.pickupCoverageKm} />
                           )}
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && (
                              <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="returnPriceRatio" placeholder={`% Rasio Biaya Muatan Balik`} control={control} error={errors.returnPriceRatio} />
                           )}
                           {serviceType === Master.SERVICE_TYPE_ADDON && priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT && (
                              <InputNumber
                                 loading={!isCreate}
                                 loadingRef={service.serviceCode}
                                 name="pricePerUnit"
                                 placeholder={`Biaya Angkut`}
                                 control={control}
                                 error={errors.pricePerUnit}
                                 formatter={(value: string) => {
                                    value = FormatHelper.formatCurrencyToNumber(value);
                                    return FormatHelper.formatCurrency(parseInt(value));
                                 }}
                                 parser={(value: string) => {
                                    return FormatHelper.formatCurrencyToNumber(value);
                                 }}
                              />
                           )}
                           <InputNumber
                              loading={!isCreate}
                              loadingRef={service.serviceCode}
                              name="transitPrice"
                              placeholder={`Biaya Transit`}
                              control={control}
                              error={errors.transitPrice}
                              formatter={(value: string) => {
                                 value = FormatHelper.formatCurrencyToNumber(value);
                                 return FormatHelper.formatCurrency(parseInt(value));
                              }}
                              parser={(value: string) => {
                                 return FormatHelper.formatCurrencyToNumber(value);
                              }}
                              nullable
                           />
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="coverageKm" placeholder={`Cakupan Layanan KM`} control={control} error={errors.coverageKm} />}
                           <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="capacityKg" placeholder={`Kapasitas Muatan Kg`} control={control} error={errors.capacityKg} />
                           {serviceType === Master.SERVICE_TYPE_TRANSPORT && (
                              <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="maxLoadCapacity" placeholder={`Kapasitas Maksimal Muatan Kg`} control={control} error={errors.maxLoadCapacity} />
                           )}
                           <InputNumber loading={!isCreate} loadingRef={service.serviceCode} name="overCapacitySurcharge" min={true} placeholder={`% Rasio Tambahan Biaya Over Capacity`} control={control} error={errors.overCapacitySurcharge} />
                        </div>

                        <Radio.Group name="status" options={Master.statusOptions} onChange={onChangeStatusOptions} value={status} buttonStyle="solid" />
                        <br />
                        {user.isAdmin && !user.provider && <Radio.Group name="verificationStatus" options={Master.serviceVerificationStatusOptions} onChange={onChangeVerifiedStatusOptions} value={verificationStatus} buttonStyle="solid" />}

                        <div className="action-button">
                           <Button type="primary" htmlType="submit">
                              Simpan
                           </Button>
                           <Button type="primary" htmlType="button" onClick={onBack} className="cancel-button">
                              Batal
                           </Button>
                        </div>
                     </form>
                  )}
               </FormLayout>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default RegisterNewService;
