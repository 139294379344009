import React from 'react';
import './WhyUsItem.css';

function WhyUsItem (props) {
  return (
    <div className="category-why-us-item">
      <div className="category-why-us-item-left">
        <span>
          <i
            className={
              'fa ' +
                props.whyUs.icon +
                ' fa-lg category-why-us-item-icon color-' +
                props.id
            }
          />
        </span>
      </div>
      <div className="category-why-us-item-right">
        <div className="category-why-us-item-title">{props.whyUs.title}</div>
        <div className="category-why-us-item-info">
          {props.whyUs.info}
        </div>
      </div>
    </div>
  );
}

export default WhyUsItem;
