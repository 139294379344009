import React, { useState, useEffect } from "react";

import Skeleton from "react-loading-skeleton";

import { FadeView } from ".";
import OriginDestination from "./OriginDestination";
import CustomButton from "./CustomButton";

import { Lead, TransportType } from "../../model";

// import * as CalendarHelper from "../../helper/CalendarHelper";

import { transportTypeSet } from "../../reference/TransportType";

import "./LeadView.css";

export function LeadView(props: any) {
   const lead: Lead = props.lead;

   // const [transportTypeDesc, setTransportTypeDesc] = useState("");
   // const [statusLabel, setStatusLabel] = useState("");
   // const [status, setStatus] = useState("");
   // const [deliveryDateLabel, setDeliveryDateLabel] = useState("");
   // const [deliveryDate, setDeliveryDate] = useState("");

   const onPressMore = () => {
      props.onPressMore(lead);
   };

   return (
      <div>
         <FadeView show={true} duration="0" style={props.style}>
            <div className="lead-view-item">
               <div className="lead-view-top">
                  <div>
                  <div className="lead-view-transport regular-bold-purple-font">{lead.transportTypeDesc || <Skeleton delay={1} width={75} />}</div>
                  <div className="regular-font">{lead.cargoDesc}</div>
                  <div className="regular-font">{lead?.weightStr && "Estimasi Berat: " + lead.weightStr.replaceAll(",",".") + " Kg"}</div>
                  <div className="regular-font">{lead?.budgetStr && "Budget: Rp " + lead.budgetStr.replaceAll(",",".")}</div>
                  <div className="regular-font">{lead?.deliveryDateStr && "Tgl Pengiriman: " + lead.deliveryDateStr}</div>
                  </div>
                  
                  <div>
                     <CustomButton loading text="Pilih" loadingRef={lead.transportTypeDesc} iconRight={true} icon={"more"} style={{ marginLeft: 5, width: 76 }} onClick={onPressMore} />
                  </div>
               </div>
               <div className="lead-view-bottom">
                  <div className="lead-view-origin-destination">
                     <OriginDestination origin={lead.origin} destination={lead.destination} />
                  </div>
                  {/*<div style={{ flex: 1 }}>
                     <div className="lead-view-title regular-bold-font">{statusLabel || <Skeleton delay={1} width={75} />}</div>
                     <div className="lead-view-desc regular-font">{status || <Skeleton delay={1} width={100} />}</div>
                     <div>
                        <div className="lead-view-title regular-bold-font">{deliveryDateLabel || <Skeleton delay={1} width={75} />}</div>
                        <div className="lead-view-desc regular-font">{deliveryDate || <Skeleton delay={1} width={100} />}</div>
                     </div>
                  </div>*/}
               </div>
            </div>
         </FadeView>
      </div>
   );
}

export default LeadView;
