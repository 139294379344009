import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";
import moment from "moment";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { ImageUpload, InputText, InputDate, InputSelect, FormLayout } from "../../common";

import * as Notification from "../../../constants/Notification";

import { TransporterService, CacheService, CarrierService, ErrorService } from "../../../service";

import * as AlertHelper from "../../../helper/AlertHelper";

import { Carrier, Image, Transporter } from "../../../model";

import * as Master from "../../../reference/Master";

import "./TransporterForm.css";

let modalContext: ModalContextInterface;

function TransporterForm(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const { control, errors, handleSubmit, setValue } = useForm();

   const [transporter, setTransporter] = useState<Transporter>(new Transporter());
   const [isCreate, setIsCreate] = useState(true);
   const [profileImg, setProfileImg] = useState<Image>();
   const [citizenCardImg, setCitizenCardImg] = useState<Image>();
   const [drivingLicenseImg, setDrivingLicenseImg] = useState<Image>();
   const [verificationStatus, setVerificationStatus] = useState("");
   const [status, setStatus] = useState("");

   const [carrierOptions, setCarrierOptions] = useState<Carrier[]>(new Array<Carrier>());

   const user = CacheService.getUser();

   const onChangeStatusOptions = (e: any) => {
      setStatus(e.target.value);
   };

   const onChangeVerifiedStatusOptions = (e: any) => {
      setVerificationStatus(e.target.value);
   };

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!match.params.id) {
         CarrierService.searchCarrier(new Carrier(), 1000, 1)
            .then((result) => {
               setCarrierOptions(result.carriers.map((carrier: Carrier) => ({ value: carrier.gid, label: carrier.code + " " + carrier.licenseNo })));
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
         return;
      }
      setIsCreate(false);
      CarrierService.searchCarrier(new Carrier(), 1000, 1)
         .then((result) => {
            setCarrierOptions(result.carriers.map((carrier: Carrier) => ({ value: carrier.gid, label: carrier.code + " " + carrier.licenseNo })));
            TransporterService.getTransporter(match.params.id)
               .then((result) => {
                  setTransporter(result);
               })
               .catch((error) => {
                  ErrorService.handleError(error, history, modalContext);
               });
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history]);

   useEffect(() => {
      if (!transporter.id) {
         return;
      }
      let dob = moment(transporter.dob);
      let drivingLicenseExpiry = moment(transporter.drivingLicenseExpiry);
      setProfileImg(transporter.profileImg);
      setCitizenCardImg(transporter.citizenCardImg);
      setDrivingLicenseImg(transporter.drivingLicenseImg);
      setValue([
         { name: transporter.name },
         { dob: dob },
         { citizenCardNo: transporter.citizenCardNo },
         { drivingLicenseNo: transporter.drivingLicenseNo },
         { drivingLicenseExpiry: drivingLicenseExpiry },
         { phone: transporter.phone },
         { email: transporter.email },
         { carrier: transporter.carrier?.gid },
      ]);
      setVerificationStatus(transporter.verificationStatus);
      setStatus(transporter.status);
   }, [transporter, setValue]);

   const onSubmit = (data: any) => {
      if (!profileImg || !profileImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto pengemudi", modalContext);
         return;
      }
      if (!citizenCardImg || !citizenCardImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto KTP", modalContext);
         return;
      }
      if (!drivingLicenseImg || !drivingLicenseImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto SIM", modalContext);
         return;
      }

      let carrier: Carrier | undefined = undefined;

      if (data.carrier) {
         carrier = new Carrier();
         carrier.gid = data.carrier;
      }

      const t: Transporter = new Transporter();
      t.gid = transporter.gid;
      t.carrier = carrier;
      t.name = data.name;
      t.dob = data.dob;
      t.phone = data.phone;
      t.email = data.email;
      t.profileImg = new Image().setId(profileImg?.id);
      t.profileImgUrl = profileImg?.url;
      t.citizenCardNo = data.citizenCardNo;
      t.citizenCardImg = new Image().setId(citizenCardImg?.id);
      t.drivingLicenseNo = data.drivingLicenseNo;
      t.drivingLicenseExpiry = data.drivingLicenseExpiry;
      t.drivingLicenseImg = new Image().setId(drivingLicenseImg?.id);
      t.verificationStatus = verificationStatus;
      t.status = status;

      if (isCreate) {
         AlertHelper.alertLoading(Notification.DATA_REGISTER, modalContext);

         TransporterService.registerTransporter(t)
            .then((result) => {
               AlertHelper.alertSuccess("Proses registrasi pengemudi", modalContext);
               setTimeout(() => history.push("/admin/transporter"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         AlertHelper.alertLoading(Notification.DATA_SAVING, modalContext);

         TransporterService.updateTransporter(t)
            .then((result) => {
               AlertHelper.alertSuccess("Proses pembaruan data pengemudi", modalContext);
               setTimeout(() => history.push("/admin/transporter"), 3000);
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   const onBack = () => {
      history.goBack();
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <FormLayout doodle="/images/doodle/delivery.png" title={isCreate ? "Registrasi Pengemudi" : "Perubahan Data Pengemudi"} desc={isCreate ? "Mohon lengkapi data pengemudi" : "Mohon perbarui data pengemudi"}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <ImageUpload single reference="transporter-profile" image={profileImg} person text="Foto Pengemudi" setImage={setProfileImg} />

                     <InputText loading={!isCreate} loadingRef={transporter.gid} name="name" placeholder="Nama Lengkap" control={control} error={errors.name} />
                     <InputDate loading={!isCreate} loadingRef={transporter.dob} name="dob" placeholder="Tanggal Lahir" control={control} error={errors.dob} />
                     <InputText loading={!isCreate} loadingRef={transporter.citizenCardNo} name="citizenCardNo" placeholder="No. KTP" control={control} error={errors.citizenCardNo} />
                     <InputText loading={!isCreate} loadingRef={transporter.drivingLicenseNo} name="drivingLicenseNo" placeholder="No. SIM" control={control} error={errors.drivingLicenseNo} />
                     <InputDate loading={!isCreate} loadingRef={transporter.drivingLicenseExpiry} name="drivingLicenseExpiry" placeholder="Tanggal SIM Berakhir" control={control} error={errors.drivingLicenseExpiry} />
                     <InputText loading={!isCreate} loadingRef={transporter.phone} name="phone" phone placeholder="No. Ponsel" control={control} error={errors.phone} />
                     <InputText loading={!isCreate} loadingRef={transporter.email} name="email" email placeholder="Email" control={control} error={errors.email} />
                     <InputSelect loading={!isCreate} loadingRef={transporter.gid} name="carrier" placeholder="Kendaraan" optional control={control} error={errors.carrier} options={carrierOptions} />

                     <div className="transporter-form-images">
                        <ImageUpload single reference="transporter-citizen-card" image={citizenCardImg} card text="Foto KTP" setImage={setCitizenCardImg} confidential />
                        <ImageUpload single reference="transporter-driving-license" image={drivingLicenseImg} card text="Foto SIM" setImage={setDrivingLicenseImg} confidential />
                     </div>

                     <Radio.Group name="status" options={Master.statusOptions} onChange={onChangeStatusOptions} value={status} buttonStyle="solid" />

                     {user.isAdmin && !user.provider && <Radio.Group name="verificationStatus" options={Master.verificationStatusOptions} onChange={onChangeVerifiedStatusOptions} value={verificationStatus} buttonStyle="solid" />}

                     <div className="action-button">
                        <Button type="primary" htmlType="submit">
                           Simpan
                        </Button>
                        <Button type="primary" htmlType="button" onClick={onBack} className="cancel-button">
                           Batal
                        </Button>
                     </div>
                  </form>
               </FormLayout>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default TransporterForm;
