import React, { useState } from "react";
import { Layout, Carousel } from "antd";
import AdItem from "./AdItem";
import * as contentful from "contentful";

import "antd/dist/antd.css";

import { FadeView } from "../../common";

function Ads(props: any) {
   const [ads, setAds] = useState<unknown[]>([]);
   //localStorage.clear();
   const client = contentful.createClient({
      space: "kzmes6ysp6k6",
      accessToken: "3VshQpN5R84tNygcl7E-jd5oxlfoSxwxkFH43pHreaQ",
   });

   const fetchAds = () =>
      client.getEntries({
         content_type: "ads",
      });

   useState(() => {
      fetchAds().then((response) => {
         setAds(response.items);
      });
   });

   return (
      <Layout>
         <FadeView show={true} duration="0">
            <Carousel autoplay={true} speed={30000} autoplaySpeed={30000} effect={"fade"} dots={false} pauseOnHover={false}>
               {ads.map((ad, i) => {
                  return <AdItem ad={ad} key={i} {...props} />;
               })}
            </Carousel>
         </FadeView>
      </Layout>
   );
}

export default Ads;
