import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Rating from "react-rating";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { AiFillStar } from "react-icons/ai";

import { ShipmentService, ReviewService, ErrorService } from "../../service";

import { Shipment } from "../../model";

import { InputText, PopupLayout, SquareButton } from "../common";

import * as AlertHelper from "../../helper/AlertHelper";

import { yellow } from "../../constants/Color";

import "./Rating.css";
import { isCustomer, isProvider } from "../../model/User";

let modalContext: ModalContextInterface;

export default function CustomerRating(props: any) {
   const history = useHistory();
   const user = props.user;

   const [rating, setRating] = useState(0);
   const [remarks, setRemarks] = useState("");

   const [ratingCustomer, setRatingCustomer] = useState(0);
   const [remarksCustomer, setRemarksCustomer] = useState("");

   const shipment: Shipment = props.shipment;

   useEffect(() => {
      let shipment: Shipment = props.shipment;
      setRating(shipment.rating);
      setRemarks(shipment.ratingRemarks);
      setRatingCustomer(shipment.ratingCustomer);
      setRemarksCustomer(shipment.ratingCustomerRemarks);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.shipment]);

   useEffect(() => {
      console.log("remarks: ", remarks);
   }, [remarks]);

   const validate = () => {
      let isValidated = true;

      if (rating === 0) {
         AlertHelper.alertWarning("Mohon berikan bintang", modalContext);
         isValidated = false;
      } else if (remarks.trim() === "") {
         AlertHelper.alertWarning("Mohon isi catatan", modalContext);
         isValidated = false;
      }

      if (ratingCustomer === 0) {
         AlertHelper.alertWarning("Mohon berikan bintang", modalContext);
         isValidated = false;
      } else if (remarks.trim() === "") {
         AlertHelper.alertWarning("Mohon isi catatan", modalContext);
         isValidated = false;
      }

      return isValidated;
   };

   const onClose = () => {
      props.setShow(false);
      AlertHelper.enableScroll(true, "Rating");
   };

   const onChangeRemarks = (value: any) => {
      if (isCustomer(user)) {
         setRemarks(value);
      }

      if (isProvider(user)) {
         setRemarksCustomer(value);
      }
   };

   const onClickRating = (rate: number) => {
      if (isCustomer(user)) {
         setRating(rate);
      }

      if (isProvider(user)) {
         setRatingCustomer(rate);
      }
   };

   const onOK = () => {
      if (!validate()) {
         return;
      }

      AlertHelper.alertLoading("Memproses rating", modalContext);

      if (isCustomer(user)) {
         ShipmentService.rateShipment(shipment.gid || "", rating, remarks)
            .then((resp) => {
               props.setShipment(resp);
               props.setShow(false);
               AlertHelper.alertSuccess("Terima kasih telah memberikan penilaian", modalContext);
            })
            .catch((error: Error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }

      if (isProvider(user)) {
         ReviewService.UpdateReviewsCustomer(shipment.gid || "", ratingCustomer, remarksCustomer)
            .then((resp) => {
               console.log("ReviewCustomer: ", JSON.stringify(resp, null, 2));
               props.setShipment(resp);
               props.setShow(false);
               AlertHelper.alertSuccess("Terima kasih telah memberikan penilaian", modalContext);
            })
            .catch((error: Error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
      // ShipmentService.rateShipment(shipment.gid || "", rating, remarks)
      //    .then((resp) => {
      //       props.setShipment(resp);
      //       props.setShow(false);
      //       AlertHelper.alertSuccess("Terima kasih telah memberikan penilaian", modalContext);
      //    })
      //    .catch((error: Error) => {
      //       ErrorService.handleError(error, history, modalContext);
      //    });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            AlertHelper.enableScroll(!props.show, "Rating");
            return (
               props.show && (
                  <div className="rating">
                     <PopupLayout small className="rating-container" title="Penilaian Layanan Jasa Angkutan" onClickPrev={onClose} onClickClose={onClose}>
                        <div className="rating-content">
                           <div className="rating-rate">
                              <div className="regular-font" style={{ marginBottom: 10 }}>
                                 Berikan penilaian jasa angkutan untuk meningkatkan kualitas layanan.
                              </div>
                              <Rating
                                 stop={5}
                                 placeholderRating={isCustomer(user) ? rating : isProvider(user) ? ratingCustomer : 0}
                                 emptySymbol={<AiFillStar style={{ color: "#ccc", fontSize: 24 }} />}
                                 placeholderSymbol={<AiFillStar style={{ color: yellow, fontSize: 24 }} />}
                                 fullSymbol={<AiFillStar style={{ color: yellow, fontSize: 24 }} />}
                                 onClick={(rate) => onClickRating(rate)}
                              />
                           </div>
                           <InputText
                              name="remarks"
                              placeholder="Catatan"
                              rows={2}
                              defaultValue={isCustomer(user) ? shipment.ratingRemarks : isProvider(user) ? shipment.ratingCustomerRemarks : ""}
                              onChange={(e: any) => {
                                 onChangeRemarks(e.target.value);
                              }}
                           />
                           <div className="rating-buttons">
                              <SquareButton ok text="OK" style={{ marginRight: 5 }} onClick={onOK} />
                              <SquareButton cancel text="Batal" warning style={{ marginLeft: 5 }} onClick={onClose} />
                           </div>
                        </div>
                     </PopupLayout>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
