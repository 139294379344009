import React, { useState, useEffect } from "react";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";
import { FadeView } from "../common";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { AnalyticService, ErrorService } from "../../service";
import { useHistory } from "react-router";
import * as Color from "../../constants/Color";

import "./TrendAnalytic.css";

let modalContext: ModalContextInterface;

function TrendAnalytic(props: any) {
   const [init, setInit] = useState(false);
   const [data01, setData01] = useState<any[]>();
   const [data02, setData02] = useState<any[]>();
   const [data03, setData03] = useState<any[]>();
   const [data04, setData04] = useState<any[]>();
   const history = useHistory();
   const period30 = 30;
   const period90 = 90;

   useEffect(() => {
      //props.setShowBottomNavigation(true);
      props.setShowBackNavigation(true);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [props]);

   useEffect(() => {
      AnalyticService.GetSearchesCount(period30)
         .then((result) => {
            setData01(result);
            console.log("result data01: ", result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetSearchesCount(period90)
         .then((result) => {
            setData02(result);
            console.log("result data02: ", result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetOrdersCount(period30)
         .then((result) => {
            setData03(result);
            console.log("result data03: ", result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });

      AnalyticService.GetOrdersCount(period90)
         .then((result) => {
            setData04(result);
            console.log("result data04: ", result);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [history]);

   const ColoredLine = ({ color }: any) => (
      <hr
         style={{
            color: color,
            backgroundColor: color,
            marginTop: 20,
            marginBottom: 20,
            opacity: 0.25,
         }}
      />
   );

   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <FadeView show={true} duration="0" className="analytic-fadeview">
                  <div className="trend-analytic-container">
                     <div className="trend-analytic-content">
                        <div className="title regular-bold-font">Pencarian Angkutan Periode 30 Hari Terakhir</div>
                        <ResponsiveContainer width="100%" height={window.innerWidth < 1000 ? 250 : 450}>
                           <LineChart data={data01} margin={{ top: 5, right: 5, left: -25, bottom: 5 }}>
                              <CartesianGrid />
                              <XAxis dataKey="requestDate" interval={0} angle={-45} tick={{ textAnchor: "end" }} padding={{ left: 20, right: 20 }} height={100} />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={75} />
                              <Line dataKey="countFound" type="monotone" name="Searches Found" stroke={Color.green} strokeWidth={2} />
                              <Line dataKey="countNotFound" type="monotone" name="Searches Not Found" stroke={Color.red} strokeWidth={2} />
                              <Line dataKey="countTotal" type="monotone" name="Total Searches" stroke={Color.yellow} strokeWidth={2} />
                           </LineChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="title regular-bold-font">Pencarian Angkutan Periode 3 Bulan Terakhir</div>
                        <ResponsiveContainer width="100%" height={window.innerWidth < 1000 ? 250 : 450}>
                           <LineChart data={data02} margin={{ top: 5, right: 5, left: -25, bottom: 5 }}>
                              <CartesianGrid />
                              <XAxis dataKey="requestDate" interval={0} angle={-45} tick={{ textAnchor: "end" }} padding={{ left: 20, right: 20 }} height={150} />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={75} />
                              <Line dataKey="countFound" type="monotone" name="Searches Found" stroke={Color.green} strokeWidth={2} />
                              <Line dataKey="countNotFound" type="monotone" name="Searches Not Found" stroke={Color.red} strokeWidth={2} />
                              <Line dataKey="countTotal" type="monotone" name="Total Searches" stroke={Color.yellow} strokeWidth={2} />
                           </LineChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="title regular-bold-font">Pemesanan Angkutan Periode 30 Hari Terakhir</div>
                        <ResponsiveContainer width="100%" height={window.innerWidth < 1000 ? 250 : 450}>
                           <LineChart data={data03} margin={{ top: 5, right: 5, left: -25, bottom: 5 }}>
                              <CartesianGrid />
                              <XAxis dataKey="requestDate" interval={0} angle={-45} tick={{ textAnchor: "end" }} padding={{ left: 20, right: 20 }} height={150} />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={75} />
                              <Line dataKey="countNewOrder" type="monotone" name="New Orders" stroke={Color.purpleLight} strokeWidth={2} />
                              <Line dataKey="countAcceptOrder" type="monotone" name="Accepted Orders" stroke={Color.purpleRegular} strokeWidth={2} />
                              <Line dataKey="countConfirmPayment" type="monotone" name="Confirmed Payments" stroke={Color.purpleDark} strokeWidth={2} />
                              <Line dataKey="countTotal" type="monotone" name="Total Orders" stroke={Color.yellow} strokeWidth={2} />
                           </LineChart>
                        </ResponsiveContainer>

                        <ColoredLine color={Color.primary} />

                        <div className="title regular-bold-font">Pemesanan Angkutan Periode 3 Bulan Terakhir</div>
                        <ResponsiveContainer width="100%" height={window.innerWidth < 1000 ? 250 : 450}>
                           <LineChart data={data04} margin={{ top: 5, right: 5, left: -25, bottom: 5 }}>
                              <CartesianGrid />
                              <XAxis dataKey="requestDate" interval={0} angle={-45} tick={{ textAnchor: "end" }} padding={{ left: 20, right: 20 }} height={150} />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={75} />
                              <Line dataKey="countNewOrder" type="monotone" name="New Orders" stroke={Color.purpleLight} strokeWidth={2} />
                              <Line dataKey="countAcceptOrder" type="monotone" name="Accepted Orders" stroke={Color.purpleRegular} strokeWidth={2} />
                              <Line dataKey="countConfirmPayment" type="monotone" name="Confirmed Payments" stroke={Color.purpleDark} strokeWidth={2} />
                              <Line dataKey="countTotal" type="monotone" name="Total Orders" stroke={Color.yellow} strokeWidth={2} />
                           </LineChart>
                        </ResponsiveContainer>
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default TrendAnalytic;
