import { gql } from "@apollo/client";

import { Lead } from "../model";
import { graphClient, initClient } from "./GraphService";

class LeadService {
   async searchLead(transportTypeDesc: string, originCity: string, pageSize: number, page: number) {
      await initClient();
      //console.log('searchSite: ', JSON.stringify(site, null, 2));

      let gqlReq = `
            query { 
               GetLeads {
                  transportTypeDesc
                  origin
                  destination
                  deliveryDateStr
                  cargoDesc
                  weightStr
                  budgetStr
                  reference
               }
            }`;

      console.log("gql query: ", gqlReq);
      let result;

      result = await graphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let leads : Array<Lead> = result.data.GetLeads;
      let pageCount = Math.floor(leads.length / pageSize)

      let sortedLeads = [...leads]
      sortedLeads.sort(function(leadA, leadB) {
         return leadA.transportTypeDesc.localeCompare(leadB.transportTypeDesc)
         //return leadA.transportTypeDesc < leadB.transportTypeDesc ? 0 : 1
      });

      let resp = { searchLead: sortedLeads, pageCount: pageCount};

      console.log("searchLead:", JSON.stringify(resp, null, 2));

      return resp;
   }
}

const Service = new LeadService();

export default Service;
