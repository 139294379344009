import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import * as Notification from "../../../constants/Notification";

import { UserService, SiteService, ErrorService } from "../../../service";

import * as AlertHelper from "../../../helper/AlertHelper";

import { ImageUpload, InputText, FormLayout, InputSelect } from "../../common";

import { User, Image, Site, ProviderUser } from "../../../model";

import * as Master from "../../../reference/Master";

import "./UserForm.css";

let modalContext: ModalContextInterface;

function UserForm(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const { control, errors, handleSubmit, setValue } = useForm();

   const [isCreate, setIsCreate] = useState(true);
   const [user, setUser] = useState<User>(new User());
   const [profileImg, setProfileImg] = useState<Image>();
   const [role, setRole] = useState("");

   const [siteOptions, setSiteOptions] = useState<Site[]>(new Array<Site>());

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      SiteService.searchSite(new Site(), 1000, 1)
         .then((result) => {
            const baseOptions = [{ value: "", label: "Semua Area" }];
            let options = result.sites.map((site: Site) => ({ value: site.gid, label: site.name }));
            options = baseOptions.concat(options);
            setSiteOptions(options);
            setRole(Master.PROVIDER_ROLE_GENERAL);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
      if (!match.params.id) {
         return;
      }
      setIsCreate(false);
      UserService.getUser(match.params.id)
         .then((resp) => {
            setUser(resp);
            setProfileImg(resp.profileImg);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [match.params.id, history]);

   useEffect(() => {
      if (user) {
         setValue([{ name: user.name }, { phone: user.mobile }, { email: user.email }, { site: user.providerUser?.site?.gid }]);
         setRole(user.providerUser?.role || "");
      }
   }, [user, setValue]);

   const onChangeRoleOptions = (e: any) => {
      setRole(e.target.value);
   };

   const onSubmit = (data: any) => {
      if (!profileImg) {
         //message.info('Mohon upload/unggah foto anda');
         //return;
      }

      AlertHelper.alertLoading("Memperbarui akun anda ...", modalContext);

      const usr: User = new User();
      usr.gid = match.params.id;
      usr.name = data.name;
      usr.mobile = data.phone;
      usr.email = data.email;
      usr.profileImg = profileImg;
      usr.profileImgUrl = profileImg?.url;

      const providerUser = new ProviderUser();
      providerUser.role = role;

      if (data.site) {
         const site = new Site();
         site.gid = data.site;
         providerUser.site = site;
      }

      usr.providerUser = providerUser;

      //console.log("profileImg:", JSON.stringify(profileImg, null, 2));

      if (isCreate) {
         AlertHelper.alertLoading(Notification.DATA_REGISTER, modalContext);

         UserService.registerUser(usr)
            .then((result) => {
               AlertHelper.alertSuccess("Proses registrasi pengguna", modalContext);
               setTimeout(() => history.push("/admin/user"), 3000);
            })
            .catch((error: any) => {
               ErrorService.handleError(error, history, modalContext);
            });
      } else {
         AlertHelper.alertLoading(Notification.DATA_SAVING, modalContext);

         UserService.updateUser(usr)
            .then((result) => {
               AlertHelper.alertSuccess("Proses pembaruan data pengguna", modalContext);
               setTimeout(() => history.push("/admin/user"), 3000);
            })
            .catch((error: any) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <FormLayout doodle="/images/doodle/checklist.png" title={isCreate ? "Registrasi Pengguna" : "Perubahan Data Pengguna"} desc={isCreate ? "Mohon lengkapi data pengguna" : "Mohon perbarui data pengguna"}>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <ImageUpload single reference="provider-profile" disabled={!isCreate} image={profileImg} person text="Foto Anda" setImage={setProfileImg} />

                        <InputText name="name" placeholder="Nama" readOnly={!isCreate} control={control} error={errors.name} />

                        <InputText name="phone" placeholder="No. Telepon" readOnly={!isCreate} phone control={control} error={errors.phone} />

                        <InputText name="email" placeholder="Email" readOnly={!isCreate} email control={control} error={errors.email} />

                        <InputSelect loading={!isCreate} loadingRef={user.gid} name="site" placeholder="Pangkalan" optional control={control} error={errors.site} options={siteOptions} />

                        <Radio.Group name="role" options={Master.providerRoleOptions} onChange={onChangeRoleOptions} value={role} buttonStyle="solid" />

                        <div className="action-button">
                           <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                              Simpan
                           </Button>
                        </div>
                     </form>
                  </FormLayout>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default UserForm;
