import * as React from "react";

import CustomButton from "../common/CustomButton";

import "./SubNavigation.css";

export default function SubNavigation(props: any) {
   return (
      <div className="sub-navigation" style={props.style}>
         <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
            <CustomButton icon="prev" navigate onClick={props.onClickPrev} />
         </div>
         <div
            className={props.small || window.innerWidth >= 1000 ? "bold-white-font" : "super-bold-white-font"}
            style={{
               flex: 1,
               textAlign: "center",
               marginTop: "auto",
               marginBottom: "auto",
               lineHeight: "20px",
            }}
         >
            {props.title}
         </div>
         <div style={{ height: "100%", display: "flex", alignItems: "center", opacity: !props.small && window.innerWidth < 1000 ? 0 : 1 }}>
            <CustomButton icon="close" navigate onClick={props.onClickClose} />
         </div>
      </div>
   );
}
