import React from "react";
import Skeleton from "react-loading-skeleton";

import { FiBox, FiPackage } from "react-icons/fi";
import { GiWeight } from "react-icons/gi";
import { CgArrowTopRight } from "react-icons/cg";
import { FaRegMoneyBillAlt } from "react-icons/fa";

import { CustomButton } from ".";

import { Service } from "../../model";
import * as FormatHelper from "../../helper/FormatHelper";

import "./TransportType.css";

function TransportTypeItem(props: any) {
   const transportType = props.transportType;
   const service: Service = props.service;
   const tkbm = props.tkbm ? true : false;
   const pointerStyle = props.onSelect ? "transport-type-item-pointer" : "";

   return (
      <div>
         <div onClick={() => (props.onSelect ? props.onSelect(transportType) : "")}>
            {!transportType.desc && (
               <div className={`transport-type-empty`}>
                  <Skeleton delay={1} width={200} />
                  <Skeleton delay={1} width={400} />
                  <Skeleton delay={1} width={400} />
               </div>
            )}
            {transportType.desc && (
               <div className={`transport-type-item ${pointerStyle} ${props.single ? "" : "regular-bottom-border"}`} style={props.simple ? { minHeight: 85 } : {}}>
                  <img alt="Transport Type" src={process.env.PUBLIC_URL + "/images" + transportType.image} className={"image"} />
                  <div className={"item-content"}>
                     <div
                        style={{
                           marginTop: "auto",
                           marginBottom: "auto",
                           display: "flex",
                           flexDirection: "column",
                           float: "left",
                           width: "100%",
                           paddingRight: window.innerWidth > 1000 ? 10 : 0,
                        }}
                     >
                        <div className="description" style={!props.simple && window.innerWidth > 1000 ? { width: 325 } : {}}>
                           {transportType.desc} {tkbm && "+ TKBM"}
                        </div>
                        {service && (
                           <div style={{ display: "flex", justifyContent: window.innerWidth > 425 ? "space-between" : "flex-start" }}>
                              <div className="more-info-section">
                                 <div className="more-info-title">
                                    <GiWeight className="more-info-icon" />
                                    Kapasitas
                                 </div>
                                 <div className="more-info-content">{service.capacityKg / 1000} Ton</div>
                              </div>
                              <div className="more-info-section">
                                 <div className="more-info-title">
                                    <CgArrowTopRight className="more-info-icon" />
                                    Maksimum
                                 </div>
                                 <div className="more-info-content">
                                    {service.maxLoadCapacity / 1000} Ton / {(service.maxLoadCapacity / service.capacityKg) * 100} %
                                 </div>
                              </div>
                              {!props.simple && window.innerWidth > 425 && (
                                 <div className="more-info-section">
                                    <div className="more-info-title">
                                       <FaRegMoneyBillAlt className="more-info-icon" />
                                       Surcharge
                                    </div>
                                    <div className="more-info-content">{service.overCapacitySurcharge} %</div>
                                 </div>
                              )}
                           </div>
                        )}
                        {!service && (
                           <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div className="more-info-section">
                                 <div className="more-info-title">
                                    <FiBox className="more-info-icon" />
                                    {window.innerWidth < 450 ? "Kapasitas" : "Kapasitas P x L x T"}
                                 </div>
                                 <div className="more-info-content">{transportType.dimension}</div>
                              </div>
                              {!props.simple && window.innerWidth > 425 && (
                                 <div className="more-info-section">
                                    <div className="more-info-title">
                                       <FiPackage className="more-info-icon" />
                                       Volume
                                    </div>
                                    <div className="more-info-content">{transportType.bcm}</div>
                                 </div>
                              )}
                              <div className="more-info-section">
                                 <div className="more-info-title">
                                    <GiWeight className="more-info-icon" />
                                    Berat
                                 </div>
                                 <div className="more-info-content">{FormatHelper.formatIntNumber(transportType.capacity)} Kg</div>
                              </div>
                              {!props.simple && <CustomButton loading loadingRef={transportType.capacity} icon={"more"} style={{ marginTop: 2 }} onClick={() => (props.onSelect ? props.onSelect(transportType) : "")} />}
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}

export default TransportTypeItem;
