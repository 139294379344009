import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CacheService } from '../../service';

import './AboutUs.css';

function Home(props) {
   const history = useHistory();
   const user = CacheService.getUser();

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [props, history, user]);

   if (user && user.gid) {
      return <div></div>;
   } else {
      return (
         <div className="about-us">
            <div className="title bold-bold-font">Tentang Lintas</div>
            <div className="content big-font">
               Lintas adalah Marketplace Logistik pertama di Indonesia yang menghubungkan pengguna jasa logistik dengan para penyedia jasa layanan logistik secara efisien. Lintas hadir dengan Visi menjadi platform Logistik dengan jangkauan terluas melalui proses efisien berbiaya rendah yang memaksimalkan manfaat sosial dan meminimalkan dampak lingkungan.
            </div>
            <div className="title bold-bold-font">Visi</div>
            <div className="content big-font">
               Menjadi Platform Logistik dengan jangkauan terluas melalui proses efisien berbiaya rendah yang memaksimalkan manfaat sosial dan meminimalkan dampak lingkungan.
            </div>
            <div className="title bold-bold-font">Misi</div>
            <div className="content big-font">
               Menjadi pemberdaya logistik dan transportasi global yang berfokus pada pelanggan melalui akses yang mudah, layanan berkualitas, dan reputasi terpercaya dengan kolaborasi bersama mitra ahli dan berpengalaman disertai manfaat timbal balik.
            </div>
            <div className="content-left big-font">
               Lintas menawarkan peluang usaha yang luas bagi para mitra dan berbagai kemudahan layanan bagi para pelanggan. Berbagai keunggulan platform Lintas dapat dinikmati oleh para mitra maupun pelanggan.
            </div>
            <div className="content-left big-font">
               Semua mitra yang bergabung di Lintas tentunya dapat memperoleh pengalaman mitra yang terbaik dari berbagai aspek layanan seperti perencanaan, pelaksanaan dan pengendalian operasional yang efisien. Tak hanya itu, para mitra juga dapat merasakan berbagai keunggulan platform Lintas secara langsung:
               <ol>
                  <li>Permintaan bertumbuh</li>
                  <li>Biaya komisi terendah</li>
                  <li>Pengelolaan mudah</li>
                  <li>Penganggaran transparan</li>
                  <li>Perencanaan teroptimasi</li>
                  <li>Pemantauan waktu nyata</li>
                  <li>Pengkajian cerdas</li>
                  <li>Sumberdaya terproteksi</li>
                  <li>Pembayaran tercepat</li>
               </ol>
            </div>
            <div className="content-left big-font">
               Para pelanggan tentunya juga dapat memperoleh pengalaman terbaik dari platform Lintas yang menyediakan layanan dengan keunggulan pengiriman muatan dengan harga pasti, durasi akurat, dan mitra berkualitas. Berbagai keunggulan platform Lintas lainnya juga dapat dirasakan oleh pelanggan, seperti:
               <ol>
                  <li>Pilihan terbanyak</li>
                  <li>Harga pasti</li>
                  <li>Jadwal fleksibel </li>
                  <li>Pemesanan mudah</li>
                  <li>Pembayaran aman</li>
                  <li>Pengiriman terjamin</li>
                  <li>Mitra terverifikasi</li>
                  <li>Status transparan</li>
                  <li>Durasi akurat</li>
               </ol>
            </div>
            <div className="content-left big-font">
               Semua keunggulan layanan logistik tersebut dapat langsung didapat melalui platform Lintas. Tunggu apalagi? Ayo bergabung menjadi bagian dari Lintas, bersama kita wujudkan sistem logistik yang efisien dan memberikan dampak sosial yang optimal.
            </div>
         </div >
      );
   }
}

export default Home;
