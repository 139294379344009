import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ShipmentService, ComplaintService, CacheService, ErrorService } from "../../service";

import { InputText, PopupLayout, SquareButton, ImageUpload, InputSelect, InputStatic, ImagesPanel, ImagesGallery } from "../common";

import * as AlertHelper from "../../helper/AlertHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";
import * as CommonHelper from "../../helper/CommonHelper";

import * as Master from "../../reference/Master";

import { Complaint, Image, Shipment, User } from "../../model";

import { isAdmin } from "../../model/User";

import "./ProcessComplaint.css";

let modalContext: ModalContextInterface;

export default function ProcessComplaint(props: any) {
   const history = useHistory();
   const { control, errors } = useForm();

   const [user] = useState<User>(CacheService.getUser());
   const [shipment, setShipment] = useState<Shipment>();

   const [complaint, setComplaint] = useState<Complaint>();
   const [type, setType] = useState("");
   const [images, setImages] = useState([]);
   const [customerImages, setCustomerImages] = useState(new Array<Image>());
   const [transporterImages, setTransporterImages] = useState(new Array<Image>());
   const [providerImages, setProviderImages] = useState(new Array<Image>());
   const [remarks, setRemarks] = useState("");

   const [showCustomerImagesGallery, setCustomerShowImagesGallery] = useState(false);
   const [showTransporterImagesGallery, setTransporterShowImagesGallery] = useState(false);
   const [showProviderImagesGallery, setProviderShowImagesGallery] = useState(false);
   const [imageIndex, setImageIndex] = useState(0);

   const complaintTypeOptions = Master.complaintTypes.map((complaintType) => ({ value: complaintType.code, label: complaintType.desc }));
   const resolutionTypeOptions = Master.complaintResolutionTypes.map((resolutionType) => ({ value: resolutionType.code, label: resolutionType.desc }));

   const [isAction, setAction] = useState(false);

   useEffect(() => {
      setShipment(props.shipment);
   }, [props.shipment]);

   useEffect(() => {
      //console.log("complaint:", JSON.stringify(complaint, null, 2));
      //console.log("user:", JSON.stringify(user, null, 2));
      //console.log("shipment:", JSON.stringify(shipment, null, 2));
      setAction(
         // if new complaint
         (!complaint && user.customer?.gid === shipment?.customer?.gid) ||
            // if pending response
            (complaint?.status === Master.COMPLAINT_STATUS_PENDING_RESPONSE && user.transporter?.gid === shipment?.transporter?.gid) ||
            // if pending resolution
            (complaint?.status === Master.COMPLAINT_STATUS_PROVIDER_RESPONSE && isAdmin(user))
      );
   }, [complaint, shipment, user]);

   useEffect(() => {
      const cmp = props.complaint;
      setComplaint(cmp);
      if (cmp) {
         //setCustomerImages(props.complaint.customerComplaintImages);
         ComplaintService.getComplaintImages(cmp.gid, Master.COMPLAINT_IMAGE_TYPE_CUSTOMER).then((images: Image[]) => {
            setCustomerImages(images);
         });
         ComplaintService.getComplaintImages(cmp.gid, Master.COMPLAINT_IMAGE_TYPE_TRANSPORTER).then((images: Image[]) => {
            setTransporterImages(images);
         });
         ComplaintService.getComplaintImages(cmp.gid, Master.COMPLAINT_IMAGE_TYPE_PROVIDER).then((images: Image[]) => {
            setProviderImages(images);
         });
      }
   }, [props.complaint]);

   const validate = () => {
      let isValidated = true;

      if (!type && !props.complaint) {
         AlertHelper.alertWarning("Mohon pilih jenis keluhan", modalContext);
         isValidated = false;
      } else if (!remarks) {
         AlertHelper.alertWarning("Mohon isi catatan", modalContext);
         isValidated = false;
      } else if (images.length === 0 && !props.complaint) {
         AlertHelper.alertWarning("Mohon lampirkan bukti pendukung keluhan", modalContext);
         isValidated = false;
      }

      return isValidated;
   };

   const onClose = () => {
      props.setShow(false);
      AlertHelper.enableScroll(true, "ProcessComplaint");
   };

   const onOK = () => {
      if (!validate()) {
         return;
      }

      processComplaint(undefined, undefined);
   };

   const processComplaint = (signImg: Image | undefined, photos: Image[] | undefined) => {
      let complaint: Complaint = props.complaint;
      AlertHelper.alertLoading("Memproses keluhan", modalContext);

      // new complaint
      if (!complaint) {
         complaint = new Complaint();
         complaint.customerComplaintType = type;
         complaint.customerComplaintDesc = remarks;
         complaint.customerComplaintImages = images;

         ComplaintService.registerComplaint(props.shipment.gid, complaint)
            .then((resp: Complaint) => {
               props.setComplaint(resp);
               ShipmentService.getShipmentStatus(props.shipment.gid).then((status) => {
                  let shp = CommonHelper.copyObject(shipment);
                  shp.status = status;
                  props.setShipment(shp);
                  props.setShow(false);
                  AlertHelper.alertSuccess("Memproses keluhan", modalContext);
               });
            })
            .catch((error: Error) => {
               ErrorService.handleError(error, history, modalContext);
            });

         // response from transporter or provider
      } else if (!complaint.providerResponseDesc) {
         const respComplaint = { gid: complaint.gid, type: type ? type : "", desc: remarks, images: images };

         ComplaintService.responseComplaint(respComplaint)
            .then((resp: Complaint) => {
               props.setComplaint(resp);
               props.setShow(false);
               AlertHelper.alertSuccess("Memproses keluhan", modalContext);
            })
            .catch((error: Error) => {
               ErrorService.handleError(error, history, modalContext);
            });

         // resolution from lintas
      } else if (!complaint.resolutionDesc) {
         const respComplaint = { gid: complaint.gid, type: type ? type : "", desc: remarks };

         ComplaintService.resolveComplaint(respComplaint)
            .then((resp: Complaint) => {
               props.setComplaint(resp);
               ShipmentService.getShipmentStatus(props.shipment.gid).then((status) => {
                  let shp = CommonHelper.copyObject(shipment);
                  shp.status = status;
                  props.setShipment(shp);
                  props.setShow(false);
                  AlertHelper.alertSuccess("Memproses keluhan", modalContext);
               });
            })
            .catch((error: Error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            AlertHelper.enableScroll(!props.show, "ProcessComplaint");
            return (
               props.show && (
                  <div className="process-complaint">
                     <PopupLayout full className="process-complaint-container" title="Keluhan" onClickPrev={onClose} onClickClose={onClose}>
                        <div className="process-complaint-content">
                           <form>
                              {!complaint && (
                                 <div>
                                    <InputSelect
                                       name="complaintType"
                                       placeholder="Jenis Keluhan"
                                       loadingRef={props.shipment}
                                       control={control}
                                       error={errors.complaintType}
                                       options={complaintTypeOptions}
                                       onSelect={(value: any, event: any) => {
                                          setType(value);
                                       }}
                                       style={{ marginTop: 10 }}
                                    />
                                    <InputText
                                       name="remarks"
                                       placeholder="Keterangan"
                                       rows={2}
                                       onChange={(e: any) => {
                                          setRemarks(e.target.value);
                                       }}
                                    />
                                    <ImageUpload multiple reference="complaint-photo" images={images} setImages={setImages} photo text="Pilih Foto" desc="Foto Bukti Keluhan" style={{ marginTop: 15, width: window.innerWidth - 40 }} />
                                 </div>
                              )}
                              {complaint && (
                                 <div>
                                    <div className="user">
                                       <div>{complaint?.customerUser.name}</div>
                                       <div>{CalendarHelper.getDateMonthTime(complaint?.customerComplaintDate)}</div>
                                    </div>
                                    <InputStatic placeholder={"Jenis Keluhan"} value={CommonHelper.getLabel(complaintTypeOptions, complaint?.customerComplaintType)} />
                                    <InputStatic placeholder={"Catatan"} value={complaint?.customerComplaintDesc} />
                                    <ImagesPanel
                                       images={customerImages}
                                       width={window.innerWidth > 500 ? 460 : window.innerWidth - 35}
                                       size={4}
                                       onPress={(index: number) => {
                                          setImageIndex(index);
                                          setCustomerShowImagesGallery(true);
                                       }}
                                       style={{ marginTop: 15 }}
                                    />
                                 </div>
                              )}
                              {complaint?.status === Master.COMPLAINT_STATUS_PENDING_RESPONSE && user.transporter?.gid === shipment?.transporter?.gid && (
                                 <div className="response">
                                    <InputText
                                       name="transporterResponseDesc"
                                       placeholder="Tanggapan Pengemudi"
                                       rows={2}
                                       onChange={(e: any) => {
                                          setRemarks(e.target.value);
                                       }}
                                    />
                                    <ImageUpload multiple reference="complaint-trans-resp-photo" images={images} setImages={setImages} photo text="Pilih Foto" desc="Foto Tanggapan Keluhan" style={{ marginTop: 15, width: window.innerWidth - 40 }} />
                                 </div>
                              )}
                              {complaint?.transporterResponseDesc && (
                                 <div className="response">
                                    <div className="user">
                                       <div>{complaint?.transporterUser?.name}</div>
                                       <div>{CalendarHelper.getDateMonthTime(complaint?.transporterResponseDate)}</div>
                                    </div>
                                    <InputStatic placeholder={"Tanggapan Pengemudi"} value={complaint?.transporterResponseDesc} />
                                    <ImagesPanel
                                       images={transporterImages}
                                       width={window.innerWidth > 500 ? 460 : window.innerWidth - 35}
                                       size={4}
                                       onPress={(index: number) => {
                                          setImageIndex(index);
                                          setTransporterShowImagesGallery(true);
                                       }}
                                       style={{ marginTop: 15 }}
                                    />
                                 </div>
                              )}
                              {complaint?.status === Master.COMPLAINT_STATUS_TRASPORTER_RESPONSE && (
                                 <div className="response">
                                    <InputText
                                       name="providerResponseDesc"
                                       placeholder="Tanggapan Penyedia Jasa"
                                       rows={2}
                                       onChange={(e: any) => {
                                          setRemarks(e.target.value);
                                       }}
                                    />
                                    <ImageUpload multiple reference="complaint-trans-resp-photo" images={images} setImages={setImages} photo text="Pilih Foto" desc="Foto Tanggapan Keluhan" style={{ marginTop: 15, width: window.innerWidth - 40 }} />
                                 </div>
                              )}
                              {complaint?.providerResponseDesc && (
                                 <div className="response">
                                    <div className="user">
                                       <div>{complaint?.providerUser?.name}</div>
                                       <div>{CalendarHelper.getDateMonthTime(complaint?.providerResponseDate)}</div>
                                    </div>
                                    <InputStatic placeholder={"Tanggapan Penyedia Jasa"} value={complaint?.providerResponseDesc} />
                                    <ImagesPanel
                                       images={providerImages}
                                       width={window.innerWidth > 500 ? 460 : window.innerWidth - 35}
                                       size={4}
                                       onPress={(index: number) => {
                                          setImageIndex(index);
                                          setProviderShowImagesGallery(true);
                                       }}
                                       style={{ marginTop: 15 }}
                                    />
                                 </div>
                              )}
                              {complaint?.status === Master.COMPLAINT_STATUS_PROVIDER_RESPONSE && isAdmin(user) && (
                                 <div className="resolution-input">
                                    <InputSelect
                                       name="resolutionType"
                                       placeholder="Resolusi"
                                       loadingRef={props.shipment}
                                       control={control}
                                       error={errors.resolutionType}
                                       options={resolutionTypeOptions}
                                       onSelect={(value: any, event: any) => {
                                          setType(value);
                                       }}
                                       style={{ marginTop: 10 }}
                                    />
                                    <InputText
                                       name="resolutionDesc"
                                       placeholder="Keterangan"
                                       rows={2}
                                       onChange={(e: any) => {
                                          setRemarks(e.target.value);
                                       }}
                                    />
                                 </div>
                              )}
                              {complaint?.resolutionDesc && (
                                 <div className="resolution">
                                    <div className="user">
                                       <div>{complaint?.adminUser?.name}</div>
                                       <div>{CalendarHelper.getDateMonthTime(complaint?.resolutionDate)}</div>
                                    </div>
                                    <InputStatic placeholder={"Resolusi"} value={CommonHelper.getLabel(resolutionTypeOptions, complaint?.resolutionType)} />
                                    <InputStatic placeholder={"Keterangan"} value={complaint?.resolutionDesc} />
                                 </div>
                              )}
                           </form>
                        </div>
                        {isAction && (
                           <div className="process-complaint-bottom">
                              <div className="process-complaint-buttons">
                                 <SquareButton ok text="OK" style={{ marginRight: 5 }} onClick={onOK} />
                                 <SquareButton cancel text="Batal" warning style={{ marginLeft: 5 }} onClick={onClose} />
                              </div>
                           </div>
                        )}
                     </PopupLayout>
                     {customerImages && <ImagesGallery title="Foto Bukti Keluhan" images={customerImages} index={imageIndex} show={showCustomerImagesGallery} setShow={setCustomerShowImagesGallery} />}
                     {transporterImages && <ImagesGallery title="Foto Tanggapan Keluhan" images={transporterImages} index={imageIndex} show={showTransporterImagesGallery} setShow={setTransporterShowImagesGallery} />}
                     {providerImages && <ImagesGallery title="Foto Tanggapan Keluhan" images={providerImages} index={imageIndex} show={showProviderImagesGallery} setShow={setProviderShowImagesGallery} />}
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
