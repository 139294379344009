import React from 'react';
import './ShowcaseItem.css';

function ShowcaseItem(props) {

   return (
      <div className="showcase-item-panel">
         <div
            className="showcase-item-img"
            style={{
               backgroundImage: `url(${process.env.PUBLIC_URL + '/images' + props.imageUrl})`,
            }}
         >
         </div>
      </div>
   );
}

export default ShowcaseItem;
