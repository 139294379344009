import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import MenuIcon from "../common/MenuIcon";

import * as Config from "../../Config";

import { CacheService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import * as Master from "../../reference/Master";

import "antd/dist/antd.css";

const { SubMenu } = Menu;
let modalContext: ModalContextInterface;

function TopMenuItem(props: any) {
   const onClick = (menu: any) => {
      CacheService.savePrev();
      CacheService.resetPage();
      if (menu.title === "Salin Referal Link") {
         let user = CacheService.getUser();
         navigator.clipboard.writeText(Config.REFFERAL_LINK + (user.provider?.refId ? user.provider.refId.toLowerCase() : user.provider?.refId));
         AlertHelper.alertSuccess("Salin referral link", modalContext);
      } else if (menu.title === "Telepon") {
         window.open("tel:+" + Master.MOBILE_LINTAS);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Call Admin");
      } else if (menu.title === "Chat / Pesan") {
         window.open("https://wa.me/" + Master.MOBILE_LINTAS);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Event Whatsapp Admin");
      }
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <Menu.Item
                  className=""
                  key={props.item.title}
                  {...props}
                  onClick={() => {
                     onClick(props.item);
                  }}
               >
                  {props.item.title} <Link to={props.item.link} />
               </Menu.Item>
            );
         }}
      </ModalContext.Consumer>
   );
}

function TopMenu(props: any) {
   //console.log('menu: ', JSON.stringify(props.menu));
   if (props.menu.submenus && props.menu.submenus.length > 0) {
      return (
         <SubMenu icon={<MenuIcon title={props.menu.title} />} title={props.menu.title} key={"sub-" + props.id} {...props}>
            {props.menu.submenus.map((m: any, key: number) => (
               <TopMenuItem item={m} {...props} key={"sub-" + props.id + "-" + key} eventKey={"sub-" + props.id + "-" + key}></TopMenuItem>
            ))}
         </SubMenu>
      );
   } else {
      return <TopMenuItem item={props.menu} icon={<MenuIcon title={props.menu.title} />} key={"sub-" + props.id} {...props} />;
   }
}

export default TopMenu;
