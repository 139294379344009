import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TimeKeeper from "react-timekeeper";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ShipmentService, CacheService, ErrorService } from "../../service";

import { Shipment, User } from "../../model";

import { InputText, PopupLayout, SquareButton, Calendar, CustomButton, ModalView } from "../common";

import * as AlertHelper from "../../helper/AlertHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";

import "./Reschedule.css";

let modalContext: ModalContextInterface;

export default function ProcessShipment(props: any) {
   const history = useHistory();

   const [rescheduleDate, setRescheduleDate] = useState<Date>(new Date());
   const [remarks, setRemarks] = useState("");

   const [rescheduleTime, setRescheduleTime] = useState("");
   const [showTime, setShowTime] = useState(false);

   const shipment: Shipment = props.shipment;
   const user: User = CacheService.getUser();

   useEffect(() => {
      let initialDate = new Date(props.shipment.rescheduleToDate ? props.shipment.rescheduleToDate : props.shipment.deliveryDate);
      let initialTime = CalendarHelper.getTime(initialDate);
      initialDate = initialDate >= CalendarHelper.getToday() ? initialDate : CalendarHelper.getToday();
      setRescheduleDate(initialDate);
      setRescheduleTime(initialTime);
   }, [props.shipment.rescheduleToDate, props.shipment.deliveryDate]);

   const validate = () => {
      let isValidated = true;

      if (!remarks) {
         AlertHelper.alertWarning("Mohon isi catatan", modalContext);
         isValidated = false;
      }

      return isValidated;
   };

   const onClose = () => {
      props.setShow(false);
      AlertHelper.enableScroll(true, "Reschedule");
   };

   const onOK = () => {
      if (!validate()) {
         return;
      }

      AlertHelper.alertLoading("Memproses perubahan jadwal", modalContext);

      ShipmentService.rescheduleShipment(shipment.gid || "", rescheduleDate, remarks)
         .then((resp) => {
            props.setShow(false);
            AlertHelper.alertSuccess("Proses berhasil, mohon tunggu konfirmasi dari " + (user.customer ? "penyedia jasa angkutan" : "pelanggan"), modalContext);
            props.setShipment(resp);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onDateSelected = (date: Date) => {
      setRescheduleDate(CalendarHelper.dateSetHourMinute(new Date(date.toString()), rescheduleDate.getHours(), rescheduleDate.getMinutes()));
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            AlertHelper.enableScroll(!props.show, "Reschedule");
            return (
               props.show && (
                  <div className="reschedule">
                     <PopupLayout className="reschedule-container" title="Ganti Jadwal" small={true} onClickPrev={onClose} onClickClose={onClose}>
                        <div className="reschedule-content">
                           <div className="reschedule-calendar-panel">
                              <span className="reschedule-calendar-title regular-bold-font">Tanggal</span>
                              <div className="reschedule-calendar">
                                 <Calendar date={rescheduleDate} setDate={onDateSelected} week center></Calendar>
                              </div>
                           </div>
                           <div className="reschedule-time-panel">
                              <span className="reschedule-time-title regular-bold-font">Jam</span>
                              <div className="reschedule-time-content">
                                 <div className="reschedule-time regular-dash-border regular-bold-font">{CalendarHelper.getDayTime(rescheduleDate)}</div>
                                 <CustomButton icon="clock" onClick={() => setShowTime(true)} />
                                 <ModalView show={showTime}>
                                    <TimeKeeper
                                       time={rescheduleTime}
                                       onChange={(newTime) => {
                                          setRescheduleDate(CalendarHelper.dateSetHourMinute(rescheduleDate, newTime.hour, newTime.minute));
                                          setRescheduleTime(newTime.formatted12);
                                       }}
                                       onDoneClick={() => setShowTime(false)}
                                       switchToMinuteOnHourSelect
                                    />
                                 </ModalView>
                              </div>
                           </div>
                           <InputText
                              name="remarks"
                              placeholder="Catatan"
                              rows={2}
                              onChange={(e: any) => {
                                 setRemarks(e.target.value);
                              }}
                           />
                           <div className="reschedule-buttons">
                              <SquareButton ok text="OK" style={{ marginRight: 5 }} onClick={onOK} />
                              <SquareButton cancel text="Batal" warning style={{ marginLeft: 5 }} onClick={onClose} />
                           </div>
                        </div>
                     </PopupLayout>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
