import React from 'react';
import {Collapse} from 'antd';

import './UsefulInfo.css';

const {Panel} = Collapse;

function UsefulInfo () {
  const usefulInfos = [
    {
      title: 'Reschedule policy',
      desc: 'Free Reschedule is applicable if you submit the reschedule request by at maximum 72 hours (3 days) before your initial photoshoot date',
    },
    {
      title: 'Cancellation Policy',
      desc: 'Free Cancellation is applicable if you submit the cancellation request by at maximum 72 hours (3 days) before your initial photoshoot date',
    },
  ];

  return (
    <div className="category-useful-info">
      <div className="category-useful-info-header">
        Useful Information
      </div>
      <div className="category-useful-info-desc">
        What you need to know to get the most of your photo shoot session
      </div>
      <Collapse>
        {usefulInfos.map ((usefulInfo, key) => (
          <Panel header={usefulInfo.title} key={key}>
            <div className="category-useful-info-item-desc">
              {usefulInfo.desc}
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default UsefulInfo;
