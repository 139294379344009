import React from 'react';
import './WhyUsItem.css';

function WhyUsItem(props) {
   return (
      <div className="why-us-item">
         <div className="why-us-item-left">
            <span>
               <i
                  className={
                     'fa ' +
                     props.whyUs.icon +
                     ' fa-lg why-us-item-icon color-' +
                     props.id
                  }
               />
            </span>
         </div>
         <div className="why-us-item-right">
            <div className="why-us-item-title">{props.whyUs.title}</div>
            <div className="why-us-item-info">
               {props.whyUs.info}
            </div>
         </div>
      </div>
   );
}

export default WhyUsItem;
