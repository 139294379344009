import { Tnc } from "../model"

let desk_p1 = "Syarat dan Ketentuan yang ditetapkan di bawah ini mengatur pemakaian jasa yang ditawarkan oleh PT. Lintas Teknologi Internasional terkait penggunaan situs www.lintas.app dan aplikasi telepon seluler Lintas.app sebagai platform untuk transaksi berbagai jasa transportasi dan logistik. Pelanggan disarankan membaca dengan seksama karena dapat berdampak kepada hak dan kewajiban Pelanggan di bawah hukum."
let desk_p2 = "Dengan mengakses, mendaftar dan/atau menggunakan situs www.lintas.app dan aplikasi telepon seluler Lintas.app, maka Pelanggan dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat dan Ketentuan. Syarat dan Ketentuan ini merupakan bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pelanggan dengan PT. Lintas Teknologi Internasional. Jika Pelanggan tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat dan Ketentuan, maka Pelanggan tidak diperkenankan menggunakan layanan di situs www.lintas.app dan aplikasi telepon seluler Lintas.app."
let desk_p3 = "Harap diketahui bahwa PT Lintas Teknologi Internasional dapat mengubah, memodifikasi, menambah dan menghapus Syarat dan Ketentuan ini sewaktu-waktu tanpa pemberitahuan sebelumnya. Syarat dan Ketentuan harus dibaca secara berkala. Dengan terus mengakses, mendaftar, dan/atau menggunakan situs www.lintas.app dan aplikasi telepon seluler Lintas.app setelah perubahan terhadap Syarat dan Ketentuan, Pelanggan sepakat dan menyetujui perubahan tersebut."

let deskripsi = [desk_p1, desk_p2, desk_p3]

let preface_p1 = "Bahwa PT. Lintas Teknologi Internasional (“Lintas”) adalah perusahaan yang bergerak dalam bisnis portal web melalui situs www.lintas.app dan aplikasi telepon seluler Lintas.app sebagai platform untuk transaksi berbagai jasa transportasi dan logistik (“Platform”)."
let preface_p2 = "Bahwa Pelanggan adalah pihak yang bermaksud untuk mengirimkan sejumlah Muatan dari satu atau lebih Pengirim di satu atau lebih Lokasi Asal kepada satu atau lebih Penerima di satu atau lebih Lokasi Tujuan dengan menggunakan Jasa Pengiriman yang disediakan Transporter yang dipilih, dipesan, dan dibayar melalui Platform. "
let preface_p3 = "Bahwa Transporter adalah perusahaan atau usaha perseorangan yang bergerak dalam penyediaan jasa pengurusan transportasi dan logistik melalui jalur darat, laut, dan/atau udara. Transporter mendaftar, menawarkan dan menjual jasanya kepada Pelanggan melalui Platform."
let preface_p4 = "Bahwa Syarat dan Ketentuan adalah perjanjian antara Pelanggan dan Lintas yang berisikan seperangkat peraturan yang mengatur hak, kewajiban, tanggung jawab Pelanggan dan Lintas, serta tata cara penggunaan sistem layanan Lintas."

let pendahuluan = [preface_p1, preface_p2, preface_p3, preface_p4]

// Pasal 1 - Definisi
const Pasal1 = {
  title: "Pasal 1: Definisi",
  clauses: [
    {content: "Jasa Transaksi adalah jasa perdagangan dalam jaringan yang dapat disediakan oleh Lintas:",
    subitems: 
    [
      {text: "Pendaftaran dan penerbitan Isi Materi/Konten Jasa Pengiriman dari Transporter;",
      points: []},
      {text: "Pencarian Jasa Pengiriman dari Pelanggan;",
      points: []},
      {text: "Pertemuan Jasa Pengiriman antara yang dicari Pelanggan dan disediakan Transporter;",
      points: []},
      {text: "Verifikasi Pesanan;",
      points: []},
      {text: "Pembayaran Pesanan;",
      points: []},
      {text: "Pengelolaan pembatalan/penolakan dan perubahan Pesanan;",
      points: []},
      {text: "Layanan Pelanggan dan Transporter;",
      points: []}
    ]},
    {content: "Jasa Pengiriman adalah jasa transportasi dan logistik yang dapat disediakan oleh Transporter:",
    subitems:
    [
      {text: "Penjemputan Muatan;",
      points: []},
      {text: "Pengangkutan Muatan;",
      points: []},
      {text: "Bongat muat Muatan;",
      points: []}
    ]},
    {content: "Isi Materi/Konten adalah informasi yang akurat dan lengkap, teks, gambar, dan informasi lainnya yang berkaitan dengan Transporter, Jasa Pengiriman, Kendaraan Angkut, Pengemudi, Biaya Jasa Pengiriman yang diberikan oleh Transporter dalam format dan jadwal yang diminta Lintas melalui Platform.",
    subitems: [],},
    {content: "Pesanan adalah permintaan Jasa Pengiriman untuk sejumlah Muatan dari satu atau lebih lokasi Asal ke satu atau lebih lokasi Tujuan yang ditempatkan Pelanggan kepada suatu Transporter dengan Biaya Jasa Pengiriman tertentu.",
    subitems: [],},
    {content: "Biaya Jasa Pengiriman adalah biaya untuk setiap pengiriman Muatan dari satu atau lebih Lokasi Asal ke satu atau lebih lokasi Tujuan termasuk pajak yang terdiri dari Biaya Penjemputan, Biaya Pengangkutan, Biaya Bongkar Muat apabila dipesan, Biaya Tol Jalan Bebas Hambatan apabila ada, serta Biaya Transaksi.",
    subitems: [],},
    {content: "Pengirim adalah pihak dengan nama dan nomor kontak telepon seluler di Lokasi Asal pengiriman yang ditetapkan oleh Pelanggan melalui Platform.",
    subitems: [],},
    {content: "Penerima adalah pihak dengan nama dan nomor kontak telepon seluler di Lokasi Tujuan pengiriman yang ditetapkan oleh Pelanggan melalui Platform.",
    subitems: [],},
    {content: "Lokasi Asal adalah alamat atau lokasi pada peta digital yang ditetapkan oleh Pelanggan di mana Pengirim berada dengan Muatan untuk dijemput dan diangkut oleh Transporter.",
    subitems: [],},
    {content: "Lokasi Tujuan adalah alamat atau lokasi pada peta digital yang ditetapkan oleh Pelanggan di mana Penerima berada dan ke mana Muatan diangkut dan dikirimkan oleh Transporter.",
    subitems: [],},
    {content: "Muatan adalah sejumlah barang milik Pelanggan, Pengirim, atau Penerima dengan berat dan dimensi tertentu yang akan dikirimkan dari Lokasi Asal ke Lokasi Tujuan menggunakan Jasa Pengiriman dengan Kendaraan Angkut dan Pengemudi yang ditetapkan oleh Transporter.",
    subitems: [],},
    {content: "Nota Pengiriman adalah dokumen digital yang berisi informasi tentang pengiriman yang dipesan seorang Pelanggan untuk sejumlah Muatan dari satu atau lebih Pengirim di satu atau lebih Lokasi Asal ke satu atau lebih Penerima di satu atau lebih Lokasi Tujuan.",
    subitems: [],},
    {content: "Penanggungjawab Transporter adalah pihak dengan nama, nomor kontak telepon selular, dan KTP yang ditetapkan oleh Transporter untuk mewakili Transporter.",
    subitems: [],},
    {content: "Pengemudi adalah pihak dengan nama, nomor kontak telepon seluler, KTP, dan SIM yang masih berlaku sesuai klasifikasi Kendaraan Angkut yang dikemudikan; yang ditetapkan oleh Transporter untuk mengemudikan Kendaraan Angkut dan melaksanakan Jasa Pengiriman sesuai Pesanan Pelanggan.",
    subitems: [],},
    {content: "Kendaraan Angkut adalah kendaraan dengan klasifikasi, STNK dan Buku KIR yang masih berlaku; yang ditetapkan oleh Transporter untuk melaksanakan Jasa Pengiriman sesuai klasifikasi Kendaraan Angkut yang dipesan Pelanggan.",
    subitems: [],},
    {content: "Durasi/Waktu Pengiriman adalah waktu dalam satuan jam untuk setiap pengiriman Muatan dari satu atau lebih Lokasi Asal ke satu atau lebih lokasi Tujuan yang terdiri dari Durasi/Waktu Tunggu dan Durasi/Waktu Perjalanan.",
    subitems: [],},
    {content: "Durasi/Waktu Tunggu adalah waktu dalam satuan jam yang dihabiskan Kendaraan Angkut dan Pengemudi untuk menunggu di Lokasi Asal atau Lokasi Tujuan yang dapat mencakup waktu tunggu muat, waktu muat, waktu tunggu berangkat, waktu tunggu bongkar, waktu bongkar, waktu tunggu kembali, waktu istirahat Pengemudi, waktu rusak dan perbaikan Kendaraan.",
    subitems: [],},
    {content: "Durasi/Waktu Perjalanan adalah waktu dalam satuan jam yang dihabiskan Kendaraan Angkut dan Pengemudi untuk bergerak dari Lokasi Asal ke Lokasi Tujuan.",
    subitems: [],},
    {content: "Status Pengiriman adalah informasi tentang keadaan Kendaraan Angkut dan Pengemudi sepanjang pengiriman yang ditetapkan oleh Pengemudi atau Administrator Transporter berdasarkan informasi dari Pengemudi.",
    subitems: [],},
    {content: "Lokasi Pengiriman adalah lokasi pada peta digital yang menunjukkan posisi terkini dari Kendaraan Angkut dan Pengemudi sepanjang pengiriman yang ditentukan berdasarkan data lokasi GPS telepon seluler Pengemudi yang ditangkap oleh Platform.",
    subitems: [],},
    {content: "Nota Penerimaan adalah dokumen digital dan/atau kertas yang berisi bukti penerimaan Muatan yang ditandatangani oleh Penerima di lokasi Tujuan sebagai bukti penyelesaian Jasa Pengiriman oleh Transporter untuk memenuhi Pesanan Pelanggan.",
    subitems: [],},
    {content: "Keadaan Kahar adalah kondisi atau situasi berlaku umum yang ditetapkan pemerintah Republik Indonesia dan/atau disepakati bersama Para Pihak termasuk, tetapi tidak terbatas pada, bencana alam, kebakaran, badai, banjir, wabah, perang, kerusuhan, gangguan stabilitas politik, pemogokan kerja, dan sebagainya.",
    subitems: [],},
  ]
}

const Pasal2 = {
  title: "Pasal 2: Akun dan Keamanan",
  clauses: [
    {content: "Pelanggan dengan ini menyatakan bahwa Pelanggan adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.",
    subitems: []},
    {content: "Pelanggan memahami bahwa 1 (satu) nomor telepon hanya dapat digunakan untuk mendaftar 1 (satu) akun Pelanggan.",
    subitems: []},
    {content: "Lintas tanpa pemberitahuan terlebih dahulu kepada Pelanggan, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap dugaan pelanggaran atau pelanggaran Syarat dan Ketentuan dan/atau hukum yang berlaku, yakni berupa tindakan suspensi akun, dan/atau penghapusan akun Pelanggan.",
    subitems: []},
    {content: "Lintas memiliki kewenangan untuk menutup akun Pelanggan baik sementara maupun permanen apabila didapati adanya tindakan kecurangan dalam bertransaksi, pelanggaran terhadap Syarat dan Ketentuan, termasuk namun tidak terbatas pada kebijakan penalti, dan pembocoran data. ",
    subitems: []},
    {content: "Pelanggan dilarang untuk menciptakan dan/atau menggunakan perangkat keras/lunak/fitur dan/atau alat lainnya, termasuk namun tidak terbatas pada emulator, robot, macro, crawler dan/atau perangkat otomatis yang bertujuan untuk mengakses atau menggunakan layanan pada sistem Lintas, seperti namun tidak terbatas pada:",
    subitems: 
    [
      {text: "manipulasi data",
      points: []},
      {text: "membuat banyak akun",
      points: []},
      {text: "memanipulasi perangkat yang bertujuan untuk merugikan Lintas",
      points: []},
      {text: "kegiatan perambanan (crawling/scraping) atau penyalinan konten",
      points: []},
      {text: "kegiatan otomatisasi dalam transaksi, jual beli, promosi, dan lain sebagainya",
      points: []},
      {text: "mengumpulkan (harvest) atau mencuri data",
      points: []},
      {text: "melakukan spamming, mengirimkan komunikasi elektronik dalam jumlah besar, mengirimkan surat berantai",
      points: []},
      {text: "aktivitas lain yang secara wajar dapat dinilai sebagai tindakan manipulasi layanan dan sistem",
      points: []}
    ]},
    {content: "Jika Pelanggan adalah juga Transporter, Pelanggan tidak diperbolehkan menggunakan akun Pelanggan sendiri atau akun Pelanggan orang lain untuk memesan Jasa Pengiriman yang akan Pelanggan sediakan sendiri sebagai Transporter.",
    subitems: []},
    {content: "Pelanggan bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun untuk semua aktivitas yang terjadi dalam akun Pelanggan.",
    subitems: []},
    {content: "Lintas tidak akan meminta username, password maupun kode SMS verifikasi atau kode OTP milik akun Pelanggan untuk alasan apapun, oleh karena itu Lintas menghimbau Pelanggan agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang mengatasnamakan Lintas atau pihak lain yang tidak dapat dijamin keamanannya. ",
    subitems: []},
    {content: "Pelanggan dengan ini menyatakan bahwa Lintas tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun Pelanggan yang diakibatkan oleh kelalaian Pelanggan, termasuk namun tidak terbatas pada:",
    subitems: 
    [
      {text: "menyetujui dan/atau memberikan akses masuk akun yang dikirimkan oleh Lintas melalui pesan notifikasi kepada pihak lain melalui perangkat Pelanggan",
      points: []},
      {text: "meminjamkan akun kepada pihak lain",
      points: []},
      {text: "mengakses link atau tautan yang diberikan oleh pihak lain",
      points: []},
      {text: "memberikan atau memperlihatkan kode verifikasi (OTP), password atau email kepada pihak lain",
      points: []},
      {text: "kelalaian Pelanggan lainnya yang mengakibatkan kerugian ataupun kendala pada akun Pelanggan",
      points: []}
    ]},
    {content: "Pelanggan memahami dan menyetujui bahwa untuk mempergunakan fasilitas keamanan one time password (OTP) maka penyedia jasa telekomunikasi terkait dapat sewaktu-waktu mengenakan biaya kepada Pelanggan.",
    subitems: []},
  ]
}

const Pasal3 = {
  title: "Pasal 3: Jasa Transaksi",
  clauses: [
    {content: "Lintas menyediakan Jasa Transaksi kepada Pelanggan dan Transporter sesuai pasal 1 ayat 1 untuk pertemuan Pelanggan dan Transporter dan pelaksanaan segala transaksi berkaitan dengan Pesanan Jasa Pengiriman. Lintas mengenakan Biaya Transaksi kepada Pelanggan.",
    subitems: []},
    {content: "Lintas menyediakan akses ke Platform kepada Pelanggan untuk mencari Transporter yang menyediakan Jasa Pengiriman untuk pengiriman Muatan berdasarkan informasi, namun tidak terbatas pada: tipe Kendaraan Angkut, Lokasi Asal, Lokasi Tujuan, dan jadwal pengiriman. Lintas tidak bertanggungjawab atas Isi Materi/Konten yang disediakan oleh Transporter;",
    subitems: []},
    {content: "Lintas menyediakan fitur pada Platform kepada Pelanggan untuk memesan Jasa Pengiriman dari salah satu Transporter yang dipilih dari daftar penyedia berdasarkan hasil pencarian. Pelanggan bertanggungjawab untuk memberikan informasi yang lengkap dan akurat mengenai:",
    subitems: 
    [
      {text: "Kategori, deskripsi, ukuran, dan berat Muatan;",
      points: []},
      {text: "Alamat lengkap dan/atau titik lokasi di peta digital untuk Lokasi Asal;",
      points: []},
      {text: "Nama lengkap dan nomor telepon Pengirim di Lokasi Asal;",
      points: []},
      {text: "Alamat lengkap dan/atau titik lokasi di peta digital untuk Lokasi Tujuan;",
      points: []},
      {text: "Nama lengkap dan nomor telepon Penerima di Lokasi Tujuan;",
      points: []},
    ]},
    {content: "Lintas menyediakan fitur pada Platform kepada Pelanggan untuk melakukan pembayaran Biaya Jasa Pengiriman melalui berbagai metode pembayaran untuk Pesanan yang ditempatkan.",
    subitems: 
    [
      {text: "Pelanggan wajib untuk melunasi pembayaran Biaya Jasa Pengiriman secara penuh di muka untuk memastikan keabsahan Pesanan paling lambat 24 jam setelah penempatan Pesanan apabila jadwal Pengiriman lebih dari 24 jam dan 30 menit sebelum jadwal penjemputan apabila jadwal Pengiriman kurang dari 24 jam.",
      points: []},
      {text: "Apabila Pelanggan tidak menyelesaikan pembayaran dalam jangka yang waktu yang ditetapkan, maka Pesanan akan dibatalkan.",
      points: []},
    ]},
    {content: "Lintas meminta konfirmasi penerimaan dari Transporter untuk Pesanan yang telah dibayar Pelanggan untuk memastikan kesanggupan Transporter memenuhi Pesanan:",
    subitems: 
    [
      {text: "Apabila Transporter menyanggupi pemenuhan Pesanan, Informasi Pesanan (Jadwal, Lokasi Asal, Lokasi Tujuan, Pengirim, Penerima, dan/atau Muatan) tidak tunduk terhadap perubahan apapun setelah dikonfirmasi oleh Transporter;",
      points: []},
      {text: "Apabila Transporter tidak menyanggupi pemenuhan Pesanan, yang dapat terjadi karena ketidaktersediaan Kendaraan Angkut dan/atau Pengemudi untuk jadwal pengiriman sesuai Pesanan, Pelanggan dapat memilih untuk mencari dan memesan dari Transporter lain atau mendapatkan pengembalian Biaya Jasa Pengiriman secara penuh ke rekening yang ditentukan Pelanggan selambat-lambatnya 3 (tiga) hari kerja.",
      points: []},
    ]},
    {content: "Lintas dapat menahan/menunda Pesanan untuk melakukan investigasi dan/atau menolak untuk memproses Pesanan Jasa Pengiriman melalui Platform.",
    subitems: []},
    {content: "Lintas wajib mengelola pembatalan atau penolakan Pesanan baik yang diajukan/disebabkan oleh Pelanggan atau Transporter beserta perubahan dan/atau ganti rugi kepada Pelanggan yang dapat ditimbulkan sesuai ketentuan pasal 4 tentang Jasa Pengiriman.",
    subitems: []},
    {content: "Lintas wajib menyediakan layanan Pelanggan untuk resolusi permasalahan akses Platform, pengelolaan Pesanan, atau transaksi pembayaran:",
    subitems: 
    [
      {text: "Lintas memiliki semua informasi akun Pelanggan, informasi mengenai Pesanan Jasa Pengiriman, informasi terkait transaksi Jasa Pengiriman mencakup pembayaran, pencairan dana, pengembalian uang, denda dan penyesuaian dengan Pelanggan dan Transporter. Lintas tidak perlu membayar royalti atau biaya apapun kepada Pelanggan sehubungan dengan penggunaan informasi tersebut;",
      points: []},
      {text: "Lintas memiliki hak untuk menentukan apakah Pesanan Jasa Pengiriman mengalami kegagalan dan untuk meminta Pelanggan membayar ganti rugi dalam bentuk pemotongan Biaya Jasa Pengiriman yang telah dibayarkan apabila Lintas menentukan bahwa Pelanggan memiliki tanggung jawab sehubungan dengan Syarat dan Ketentuan.",
      points: []},
    ]},
  ]
}

const Pasal4 = {
  title: "Pasal 4: Jasa Pengiriman",
  clauses: [
    {content: "Pelanggan wajib mempersiapkan Muatan dari Pengirim di Lokasi Asal sebelum tanggal dan waktu pengiriman sesuai Pesanan yang ditentukan oleh Pelanggan dan dikonfirmasi Transporter melalui Platform serta tercantum pada Nota Pengiriman. Pelanggan wajib melakukan pengemasan terhadap Muatan agar tidak rusak selama pengiriman.",
    subitems: []},
    {content: "Lintas akan menginstruksikan Transporter untuk menjemput Muatan dari Pengirim di Lokasi Asal pada tanggal dan waktu sesuai Pesanan yang ditentukan oleh Pelanggan dan dikonfirmasi Transporter melalui Platform serta tercantum pada Nota Pengiriman.",
    subitems: 
    [
      {text: "Transporter yang diwakili oleh Pengemudi dapat, dengan disaksikan oleh Pelanggan dan/atau Pengirim, membuka dan memeriksa bagian dalam kemasan untuk memastikan bahwa Muatan sesuai dengan tipe, deskripsi, dimensi, dan berat yang tercantum pada Pesanan dan/atau Nota Pengiriman dan tidak melanggar ketentuan pasal 5 tentang Muatan.",
      points: []},
      {text: "Apabila terdapat ketidaksesuaian Muatan antara informasi Pesanan yang disampaikan Pelanggan dan tercantum pada Nota Pengiriman dengan kondisi aktual Muatan di Lokasi Asal, maka Transporter dapat mengajukan penolakan pengiriman kepada Lintas dan Lintas dapat menetapkan sebagai pembatalan Pesanan oleh Pelanggan sesuai ketentuan pasal 4 ayat 6",
      points: []},
    ]},
    {content: "Transporter wajib memberitahukan Lintas dan Pelanggan melalui nomor telepon masing-masing apabila terjadi kegagalan atau keterlambatan penjemputan selambat-lambatnya 3 jam sebelum jadwal penjemputan sesuai Pesanan yang tercantum pada Nota Pengiriman yang disebabkan, namun tidak terbatas pada: ketidaktersediaan/kerusakan Kendaraan Angkut, ketidaktersediaan/kendala Pengemudi, kesalahan alamat/titik peta digital Lokasi Asal, atau ketidaksesuaian nama/nomor telepon Pengirim.",
    subitems: []},
    {content: "Apabila terjadi kegagalan penjemputan karena ketidaktersediaan/kerusakaan Kendaraan Angkut atau ketidaktersediaan/kendala Pengemudi sesuai pasal 4 ayat 10, maka Lintas akan mengembalikan Biaya Jasa Pengiriman secara penuh ke rekening yang ditentukan Pelanggan selambat-lambatnya 3 (tiga) hari kerja dan memberikan kompensasi berupa voucher potongan Biaya Pengiriman sebesar Rp. 100.000,00 (seratus ribu rupiah) kepada Pelanggan yang dapat digunakan untuk Pesanan melalui Platform berikutnya.",
    subitems: []},
    {content: "Apabila terjadi keterlambatan penjemputan yang tidak diinformasikan kepada Lintas dan Pelanggan sesuai pasal 4 ayat 3 di atas, maka Lintas dapat membatalkan Pesanan serta menetapkan sebagai kegagalan penjemputan oleh Transporter dengan ketentuan sesuai pasal 4 ayat 4.",
    subitems: []},
    {content: "Dalam hal terjadi pembatalan Pesanan oleh Pelanggan dalam kurun waktu 3 jam sebelum jadwal penjemputan sesuai Pesanan yang tercantum pada Nota Pengiriman, maka Lintas akan mengembalikan Biaya Jasa Pengiriman dikurangi Biaya Penjemputan yang telah ditetapkan untuk Pesanan dan ganti rugi pembatalan sebesar Rp. 100.000,00 (seratus ribu rupiah) ke rekening yang ditentukan Pelanggan selambat-lambatnya 3 (tiga) hari kerja.",
    subitems: []},
    {content: "Apabila terdapat ketidaksesuaian Pengemudi (KTP dan/atau foto) dan/atau Kendaraan Angkut (tipe kendaraan, nomor polisi, dan/atau foto) antara informasi dalam Pesanan dan tercantum pada Nota Pengiriman dengan kondisi aktual Pengemudi dan/atau Kendaraan Angkut, maka Pelanggan dapat mengajukan penolakan pengiriman kepada Lintas dan Lintas dapat menetapkan sebagai pembatalan Pesanan oleh Pelanggan sesuai ketentuan pasal 4 ayat 6 tanpa potongan Biaya Penjemputan dan ganti rugi pembatalan.",
    subitems: []},
    {content: "Lintas dan/atau Transporter tidak menyediakan tenaga kerja dan/atau peralatan bantu bongkar muat Muatan ke/dari Kendaraan Angkut Transporter di Lokasi Asal atau Lokasi Tujuan, kecuali apabila termasuk dalam Pesanan yang ditentukan Pelanggan dengan menginformasikan kondisi di Lokasi Asal dan Lokasi Tujuan (misal luas, ketinggian, lantai area bongkar muat) dikonfirmasi Transporter melalui Platform serta tercantum pada Nota Pengiriman.",
    subitems: 
    [
      {text: "Apabila layanan bongkar muat termasuk dalam Pesanan namun kondisi di Lokasi Asal dan Tujuan tidak diinformasikan atau tidak sesuai dengan informasi yang disampaikan Pelanggan, maka Transporter dapat menagihkan kepada Pelanggan dan Pelanggan wajib membayar kepada Transporter di luar Biaya Pengiriman yang telah dibayarkan sebelumnya.",
      points: []},
      {text: "Apabila layanan bongkar muat tidak termasuk dalam Pesanan, maka Pelanggan dan/atau Pengirim bertanggung jawab untuk menyediakan sendiri tenaga kerja dan peralatan bongkar muat di Lokasi Asal dan Tujuan.",
      points: []},
      {text: "Apabila layanan bongkar muat tidak termasuk dalam Pesanan namun Pelanggan, Pengirim dan/atau Transporter dapat menyediakan tenaga kerja dan/atau peralatan untuk aktivitas bongkar muat di Lokasi Asal atau Lokasi Tujuan dan setuju dengan biaya yang ditimbulkan, maka Transporter dapat menagihkan kepada Pelanggan dan Pelanggan wajib membayar kepada Transporter di luar Biaya Pengiriman yang telah dibayarkan sebelumnya.",
      points: []},
      {text: "Apabila layanan bongkar muat tidak termasuk dalam Pesanan namun Pelanggan, Pengirim, dan/atau Transporter tidak dapat menyediakan tenaga kerja dan/atau peralatan sehingga aktivitas bongkar muat tidak dapat dilakukan ke/dari Kendaraan Angkut Transporter, maka Transporter dapat mengajukan penolakan pengiriman kepada Lintas dan Lintas dapat menetapkan sebagai pembatalan Pesanan di Lokasi Asal oleh Pelanggan sesuai ketentuan pasal 4 ayat 13 atau kegagalan pengiriman di Lokasi Tujuan karena Pelanggan sesuai ketentuan pasal 4 ayat 18.",
      points: []},
    ]},
    {content: "Transporter yang diwakili oleh Pengemudi dapat, dengan disaksikan oleh Pelanggan, Pengirim, dan/atau Penerima, mengambil bukti foto serah terima Muatan untuk diunduh ke Platform yang terdiri dari:",
    subitems: 
    [
      {text: "Foto Muatan sebelum muat di Lokasi Asal;",
      points: []},
      {text: "Foto kondisi ruang angkut Kendaraan Angkut sebelum muat;",
      points: []},
      {text: "Foto kondisi ruang angkut Kendaraan Angkut beserta Muatan setelah muat;",
      points: []},
      {text: "Foto kondisi ruang angkut Kendaraan Angkut beserta Muatan sebelum bongkar;",
      points: []},
      {text: "Foto kondisi ruang angkut Kendaraan Angkut setelah bongkar;",
      points: []},
      {text: "Foto Muatan setelah Bongkar di Lokasi Tujuan.",
      points: []},
    ]},
    {content: "Pelanggan yang diwakili Pengirim di Lokasi Asal wajib menandatangani bukti penjemputan beserta nama jelas, nomor telepon, tanggal, dan waktu penjemputan secara digital pada Nota Pengiriman di Platform atau secara langsung di atas kertas Nota Pengiriman yang dicetak dan dibawa oleh Transporter yang diwakili Pengemudi.",
    subitems: []},
    {content: "Lintas akan menginstruksikan Transporter untuk melakukan pengiriman dan penyerahan Muatan kepada Penerima di Lokasi Tujuan sesuai Pesanan yang ditentukan oleh Pelanggan dan dikonfirmasi Transporter melalui Platform serta tercantum pada Nota Pengiriman.",
    subitems: []},
    {content: "Transporter akan mengirimkan Muatan dari Lokasi Asal ke Lokasi Tujuan dalam kurun waktu sesuai estimasi Durasi/Waktu Pengiriman sesuai rute yang dikalkulasi melalui algoritma peta digital oleh Lintas.",
    subitems: []},
    {content: "Muatan yang telah dijemput oleh Transporter dan diserahterimakan dari Pengirim di Lokasi Asal sesuai Nota Pengiriman menjadi tanggung jawab Transporter. Transporter wajib menyimpan, memelihara dan memastikan kebersihan, keamanan dan keselamatan Muatan tersebut sejak diambil, diangkut, dikirimkan, hingga diserahterimakan kepada Penerima di Lokasi Tujuan.",
    subitems: []},
    {content: "Lintas menyediakan fitur pada Platform kepada Pelanggan untuk memantau Status Pengiriman yang ditentukan oleh Pengemudi atau Administrator Transporter melalui Platform dan terdiri dari:",
    subitems: 
    [
      {text: "Jemput Muatan;",
      points: []},
      {text: "Tiba di Lokasi Asal / Tunggu Muat;",
      points: []},
      {text: "Muat;",
      points: []},
      {text: "Tunggu Berangkat;",
      points: []},
      {text: "Jalan'",
      points: []},
      {text: "Tiba di Lokasi Tujuan / Tunggu Bongkar;",
      points: []},
      {text: "Bongkar;",
      points: []},
      {text: "Tunggu Kembali;",
      points: []},
      {text: "Kembali;",
      points: []},
      {text: "Pengemudi Istirahat;",
      points: []},
      {text: "Kendaraan Rusak;",
      points: []},
      {text: "Kendaraan Perbaikan.",
      points: []},
    ]},
    {content: "Transporter wajib memberitahukan Lintas dan Pelanggan melalui nomor telepon masing-masing apabila terjadi kegagalan atau keterlambatan pengiriman selambat-lambatnya 3 jam sebelum jadwal tiba di Lokasi Tujuan sesuai estimasi Durasi/Waktu Pengiriman yang disebabkan, namun tidak terbatas pada: kerusakan Kendaraan Angkut, kendala Pengemudi, kesalahan alamat/titik peta digital Lokasi Tujuan, ketidaksesuaian nama/nomor telepon Penerima, atau kerusakan/kehilangan Muatan.",
    subitems: []},
    {content: "Apabila terjadi keterlambatan pengiriman Muatan karena kerusakan Kendaraan Angkut atau kendala Pengemudi, maka Lintas setuju untuk memberikan potongan Biaya Pengiriman sebesar Rp. 100.000,00 (seratus ribu rupiah) kepada Pelanggan ditambah:",
    subitems: 
    [
      {text: "Keterlambatan 1 (satu) hari kalender	: 5% dari Biaya Pengiriman;",
      points: []},
      {text: "Keterlambatan 2 (dua) hari kalender	: 10% dari Biaya Pengiriman;",
      points: []},
      {text: "Keterlambatan 3 (tiga) hari kalender	: 15% dari Biaya Pengiriman;",
      points: []},
      {text: "Keterlambatan 4 (empat) hari kalender	: 20% dari Biaya Pengiriman;",
      points: []},
      {text: "Keterlambatan 5 (lima) hari kalender	: 25% dari Biaya pengiriman;",
      points: []},
      {text: "Keterlambatan 6 (enam) hari kalender	: 30% dari Biaya Pengiriman.",
      points: []},
    ]},
    {content: "Apabila terjadi kegagalan pengiriman Muatan yang disebabkan kerusakan/kehilangan Muatan atau keterlambatan 7 (tujuh) hari kalender atau lebih yang akan dianggap sebagai kehilangan Muatan, maka Lintas setuju untuk memberikan 100% Biaya Pengiriman kepada Pelanggan ditambah biaya ganti rugi kerusakan/kehilangan yang diatur pada pasal 8 tentang Kehilangan dan Kerusakan Muatan.",
    subitems: []},
    {content: "Ketentuan keterlambatan dan/atau kegagalan pengiriman Muatan sesuai pasal 4 ayat 16 dan 17 dapat dikecualikan apabila Pelanggan setuju untuk memberikan toleransi atas kendala yang ditemui Transporter dalam proses pengiriman Muatan.",
    subitems: []},
    {content: "Apabila terjadi kegagalan pengiriman Muatan karena kesalahan alamat/titik peta digital Lokasi Tujuan atau ketidaksesuaian nama/nomor telepon Penerima, maka Pelanggan wajib menentukan Lokasi Tujuan alternatif untuk dikirimkan oleh Transporter dengan Nota Pengiriman terpisah yang dibuat oleh dari Lintas. Pelanggan wajib untuk membayar Biaya Pengiriman ke Lokasi Tujuan alternatif yang ditentukan.",
    subitems: []},
    {content: "Dalam hal terjadi penolakan penerimaan Muatan oleh Penerima di Lokasi Tujuan sesuai Pesanan yang tidak disebabkan oleh kerusakan/kehilangan Muatan, Transporter wajib memberitahukan Lintas dan Lintas dapat menetapkan sebagai kegagalan pengiriman yang disebabkan oleh Pelanggan sesuai dengan ketentuan sesuai pasal 4 ayat 17.",
    subitems: []},
    {content: "Apabila kegagalan penjemputan atau pengiriman yang disebabkan oleh Pelanggan dilakukan secara berulang, maka Lintas dapat melakukan suspensi/penghentian akses sementara untuk menempatkan Pesanan melalui Platform terhadap Pelanggan. Keputusan untuk membuka kembali atau menutup secara permanen akses Pelanggan ke Platform diambil melalui proses klarifikasi antara Lintas dan Transporter.",
    subitems: []},
    {content: "Pelanggan yang diwakili Penerima di Lokasi Tujuan wajib menandatangani bukti penerimaan beserta nama jelas, nomor telepon, tanggal, dan waktu penjemputan secara digital pada Nota Pengiriman di Platform atau secara langsung di atas kertas Nota Pengiriman yang dicetak dan dibawa oleh Transporter yang diwakili Pengemudi.",
    subitems: []},
  ]
}

const Pasal5 = {
  title: "Pasal 5: Muatan",
  clauses: [
    {content: "Pelanggan wajib memastikan berat dan dimensi Muatan masih memenuhi kapasitas maksimal berat dan dimensi Kendaraan Angkut yang dipesan.",
    subitems: []},
    {content: "Perhitungan berat dan dimensi Muatan yang diangkut menggunakan standar perhitungan berat dan dimensi yang berlaku umum atau internasional, yaitu dengan cara:",
    subitems: 
    [
      {text: "Berat, yaitu perhitungan berat berdasarkan angka yang tertera pada timbangan beban;",
      points: []},
      {text: "Volumetrik, yaitu perhitungan dimensi berdasarkan angka yang tertera pada meteran. Perhitungan menggunakan rumus dalam satuan meter: panjang x lebar x tinggi (CBM).",
      points: []},
    ]},
    {content: "Pelanggan wajib memastikan Muatan yang dikirimkan tidak termasuk ke dalam barang yang tidak dapat dikirimkan, antara lain:",
    subitems: 
    [
      {text: "Barang Terlarang, termasuk namun tidak terbatas pada:",
      points: 
      [
        "Uang (tunai, koin, mata uang asing);",
        "Narkotika, ganja, morfin, dan produk lainnya yang menyebabkan kecanduan;",
        "Pornografi dalam bentuk apapun;",
        "Pengiriman yang memiliki durasi lebih lama dari waktu transit yang diperlukan;",
        "Hewan hidup dan tanaman;",
        "Bahan makanan yang mudah rusak dan minuman yang membutuhkan pendingin atau lingkungan yang dikendalikan;",
        "Bahan peledak, senjata api, persenjataan, dan bagian-bagiannya;",
        "Perangkat judi dan tiket lotere;",
        "Barang-barang yang dikendalikan oleh pemerintah;",
        "Barang hasil tindak kejahatan, misalnya barang curian dan sebagainya; dan/atau",
        "Barang lain yang dilarang oleh hukum dan peraturan perundang-undangan yang berlaku;",
      ]},
      {text: "Barang Luar Biasa, termasuk namun tidak terbatas pada:",
      points: 
      [
        "Karya seni, termasuk karya yang dibuat atau dikerjakan dengan menggunakan keterampilan, rasa atau bakat kreatif untuk dijual, dipertunjukkan, atau koleksi, termasuk, namun tidak terbatas pada barang-barang (dan bagian-bagiannya) seperti lukisan, gambar, vas, permadani;",
        "Film, gambar hasil foto, termasuk negatif fotografi, chromes fotografi, slide fotografi;",
        "Komoditas yang secara alamiah sangat rentan terhadap kerusakan, atau nilai pasar yang sangat variabel, atau sulit untuk dipastikan;",
        "Barang antik, komoditas yang menunjukkan gaya atau mode dari era masa lalu yang sejarahnya, usia atau kelangkaan kontribusi untuk nilainya. Item ini termasuk namun tidak terbatas pada, furnitur, peralatan makan, gelas, dan barang-barang koleksi seperti koin, perangko;",
        "Barang pecah belah berupa perhiasan, termasuk kostum perhiasan, jam tangan dan bagian-bagiannya, batu permata atau batu (mulia atau semi mulia) berlian industri dan perhiasan yang terbuat dari logam mulia;",
        "Bulu binatang, termasuk namun tidak terbatas pada pakaian bulu, pakaian dengan trimming dan kulit berbulu;",
        "Logam Mulia, termasuk namun tidak terbatas pada, emas dan perak batangan atau bubuk, endapan atau platinum (kecuali sebagai bagian integral dari mesin elektronik);",
        "Barang digital dan/atau barang tidak berwujud berisi konversi satuan isi ulang yang memiliki nilai ekonomis, seperti voucher pulsa elektrik, voucher game elektrik, token listrik;",
        "Perangko, cukai atas minuman keras, materai;",
        "Stok persediaan darah; dan/atau",
        "Koin Emas (harus dikemas dengan coin header atau Safe-T Mailer dan harus dijaga untuk tidak bersentuhan antara satu dan lainnya atau yang dibungkus dalam bahan yang berlapis).",
      ]},
      {text: "Dokumen Berharga, termasuk namun tidak terbatas pada:",
      points: 
      [
        "Sertifikat Kepemilikan dan/atau Sertifikat Hak Milik (SHM) dan Guna Bangunan (HGB);",
        "Bukti Kepemilikan Kendaraan Bermotor (BPKB), Sertifikat Tanda Kelulusan, Paspor; dan/atau",
        "Sertifikat Bank Deposit Obligasi Barang-barang lainnya yang didefinisikan oleh Kami sebagai Dokumen Berharga.",
      ]},
    ]},
    {content: "Dalam hal Pelanggan melanggar ketentuan pasal 5 ini, Pelanggan mengakui dan setuju untuk bertanggung jawab penuh dalam hal timbulnya konsekuensi hukum, dan setuju untuk melepaskan Lintas dan Transporter dari segala bentuk pertanggungjawaban hukum. Pelanggan juga setuju bahwa Lintas memiliki hak penuh untuk menindaklanjuti pelanggaran ini melalui proses hukum.",
    subitems: []},
  ]
}

const Pasal6 = {
  title: "Pasal 6: Biaya Jasa Pengiriman",
  clauses: [
    {content: "Biaya Jasa Pengiriman yang harus dibayarkan Pelanggan untuk setiap Pesanan berdasarkan rute yang dikalkulasi algoritma peta digital meliputi: Biaya Penjemputan, Biaya Pengangkutan, Biaya Bongkar Muat apabila dipesan, Biaya Tol Jalan Bebas Hambatan apabila ada, serta Biaya Platform.",
    subitems: []},
    {content: "Transporter bertanggung jawab menetapkan Biaya Unit Penjemputan dan Biaya Unit Pengangkutan, dan Biaya Unit Bongkar Muat apabila didaftarkan sebagai layanan Jasa Pengiriman.",
    subitems: []},
    {content: "Lintas menetapkan Biaya Tol Jalan Bebas Hambatan sesuai dengan kategori Kendaraan Angkut yang dipesan berdasarkan tarif yang dipublikasikan BPJT (Badan Pengatur Jalan Tol) Kementerian Pekerjaan Umum Republik Indonesia.",
    subitems: []},
    {content: "Transporter dapat menyesuaikan Biaya Unit Penjemputan, Biaya Unit Pengangkutan, dan/atau Biaya Unit Bongkar Muat sewaktu-waktu sesuai keperluan dan pertimbangan Transporter melalui Situs dan Aplikasi.",
    subitems: []},
    {content: "Lintas dapat menyesuaikan Biaya Tol Jalan Bebas Hambatan sewaktu-waktu sesuai perubahan tariff yang dipublikasikan BPJT (Badan Pengatur Jalan Tol) Kementerian Pekerjaan Umum Republik Indonesia.",
    subitems: []},
  ]
}

const Pasal7 = {
  title: "Pasal 7: Kehilangan dan Kerusakan Muatan",
  clauses: [
    {content: "Dalam hal terjadi kehilangan dan kerusakan Muatan, Lintas akan meminta Transporter untuk menyediakan dokumen pendukung yang diperlukan yang dapat termasuk, namun tidak terbatas pada: Berita Acara Kehilangan dan Kerusakan, Surat Keterangan Kehilangan dari Kepolisian Republik Indonesia, dan dokumen lainnya yang dianggap perlu.",
    subitems: []},
    {content: "Atas kehilangan atau kerusakan Muatan, Lintas akan mewajibkan Transporter memberikan ganti rugi kepada Pelanggan sebesar nilai yang lebih kecil antara:",
    subitems: 
    [
      {text: "Sepuluh (10) kali Biaya Jasa Pengiriman untuk Pesanan sesuai Nota Pengiriman, atau;",
      points: []},
      {text: "Nilai Muatan yang hilang atau rusak dari surveyor independen yang ditunjuk Lintas.",
      points: []},
    ]},
    {content: "Transporter dapat menolak klaim ganti rugi apabila kehilangan atau kerusakan Muatan disebabkan oleh hal-hal sebagai berikut:",
    subitems: 
    [
      {text: "Kesalahan pemberian informasi mengenai Muatan yang dikirim, lokasi dan/atau jadwal penjemputan Muatan;", 
      points: []},
      {text: "Sifat dasar dan alamiah Muatan tersebut, contohnya: pembusukan;", 
      points: []},
      {text: "Risiko teknik yang disebabkan karena kegagalan pabrikasi sepanjang bungkus atau kemasan Muatan tidak dibuka oleh Transporter, contohnya: Produk tidak berfungsi atau berubah fungsi;", 
      points: []},
      {text: "Penahanan, penyitaan dan/atau pemusnahan Muatan yang dilakukan oleh instansi Pemerintah seperti Bea Cukai, Karantina, Kepolisian dan Kejaksaan sebagai akibat hukum dari keadaan dan/atau jenis Muatan yang dikirim; atau", 
      points: []},
      {text: "Kerusakan yang disebabkan oleh tindakan pihak ketiga seperti pengancaman dengan senjata, pencurian, perampokan, pembajakan dan penjarahan.", 
      points: []},
    ]},
  ]
}

const Pasal8 = {
  title: "Pasal 8: Penyelesaian Jasa Pengiriman",
  clauses: [
    {content: "Penyelesaian Jasa Pengiriman sesuai Pesanan pada Nota Pengiriman ditetapkan melalui kondisi yang terpenuhi lebih dahulu antara:",
    subitems: 
    [
      {text: "Pelanggan memberikan konfirmasi penyelesaian atau pembatalan Jasa Pengiriman melalui Situs atau Aplikasi; atau",
      points: []},
      {text: "Dua puluh empat (24) jam sejak Pelanggan memberikan bukti penerimaan sesuai ketentuan pasal 4 ayat 9.",
      points: []},
    ]},
  ]
}

const Pasal9 = {
  title: "Pasal 9: Pernyataan dan Jaminan",
  clauses: [
    {content: "Lintas adalah portal web dengan model Customer to Customer Marketplace, yang menyediakan layanan kepada pengguna untuk dapat menjadi Pelanggan maupun Transporter di Platform. Dengan demikian transaksi yang terjadi adalah transaksi antar anggota Lintas, sehingga Pelanggan memahami bahwa batasan tanggung jawab Lintas secara proporsional adalah sebagai penyedia jasa platform. Lintas selalu berupaya untuk menjaga layanan Lintas aman, nyaman, dan berfungsi dengan baik, tetapi kami tidak dapat menjamin operasi terus-menerus atau akses ke layanan kami dapat selalu sempurna. Informasi dan data dalam platform Lintas memiliki kemungkinan untuk tidak terjadi secara real time.",
    subitems: []},
    {content: "Pelanggan setuju untuk memanfaatkan layanan Lintas atas risiko Pelanggan sendiri, dan layanan Lintas diberikan sebagaimana adanya dan sebagaimana tersedia. Sejauh diizinkan oleh hukum yang berlaku, Lintas (direktur dan karyawan) tidak bertanggung jawab, dan Pelanggan setuju untuk tidak menuntut Lintas bertanggung jawab, atas segala kerusakan atau kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang diakibatkan secara langsung atau tidak langsung dari:",
    subitems: 
    [
      {text: "Penggunaan atau ketidakmampuan Pelanggan dalam menggunakan Layanan Lintas.",
      points: []},
      {text: "Biaya Pengiriman atau petunjuk lain yang tersedia dalam layanan Lintas.",
      points: []},
      {text: "Keterlambatan atau gangguan dalam layanan Lintas.",
      points: []},
      {text: "Kelalaian dan kerugian yang ditimbulkan oleh masing-masing Pelanggan.",
      points: []},
      {text: "Kualitas layanan pengiriman.",
      points: []},
      {text: "Pelanggaran Hak atas Kekayaan Intelektual.",
      points: []},
      {text: "Perselisihan antar pengguna.",
      points: []},
      {text: "Pencemaran nama baik pihak lain.",
      points: []},
      {text: "Setiap penyalahgunaan layanan yang sudah dibayar oleh pihak Pelanggan.",
      points: []},
      {text: "Kerugian akibat pembayaran tidak resmi kepada pihak lain selain ke rekening resmi Lintas, yang dengan cara apapun mengatasnamakan Lintas maupun kelalaian penulisan rekening dan/atau informasi lainnya dan/atau kelalaian pihak bank.",
      points: []},
      {text: "Biaya Pengiriman untuk mengambil sejumlah barang yang tertinggal di Lokasi Awal.",
      points: []},
      {text: "Virus atau perangkat lunak berbahaya lainnya (bot, script, automation tool) yang diperoleh dengan mengakses, atau menghubungkan ke layanan Lintas.",
      points: []},
      {text: "Gangguan, bug, kesalahan atau ketidakakuratan apapun dalam layanan Lintas.",
      points: []},
      {text: "Kerusakan pada perangkat keras Pelanggan dari penggunaan setiap layanan Lintas.",
      points: []},
      {text: "Isi, tindakan, atau tidak adanya tindakan dari pihak ketiga.",
      points: []},
      {text: "Tindak penegakan yang diambil sehubungan dengan akun Pelanggan. Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun Pelanggan.",
      points: []},
    ]},
    {content: "Jika Pelanggan memiliki perselisihan dengan satu atau lebih Transporter, Pelanggan melepaskan Lintas (termasuk direktur dan karyawan) dari klaim dan tuntutan atas kerusakan dan kerugian (aktual dan tersirat) dari setiap jenis dan sifatnya, yang dikenal dan tidak dikenal, yang timbul dari atau dengan cara apapun berhubungan dengan sengketa tersebut, termasuk namun tidak terbatas pada kerugian yang timbul dari pengiriman barang yang telah dilarang. Dengan demikian maka Pelanggan dengan sengaja melepaskan segala perlindungan hukum (yang terdapat dalam undang-undang atau peraturan hukum yang lain) yang akan membatasi cakupan ketentuan pelepasan ini.",
    subitems: []},
  ]
}

const Pasal10 = {
  title: "Pasal 10: Ganti Rugi dan Klaim Pihak Ketiga",
  clauses: [
    {content: "Pelanggan akan mengganti kerugian, membela, dan membebaskan Lintas beserta entitas induk, anak, afiliasi dan direktur, pejabat, karyawan, agen, dan subkontraktor Lintas terhadap semua klaim atau tindakan dari pihak ketiga, dan kewajiban, kerugian, beban, kerusakan, dan biaya (termasuk di dalamnya, namun tidak terbatas pada, biaya pengacara yang wajar) yang terkait dengan, untuk hal-hal yang timbul dari pelanggaran ataupun dugaan atas pelanggaran kewajiban, pernyataan atau jaminan Syarat dan Ketentuan ini.",
    subitems: []},
    {content: "Jika suatu saat Lintas menentukan bahwa suatu tuntutan ganti rugi dari pihak ketiga yang berkaitan dengan pelanggaran Pelanggan atas Syarat dan Ketentuan ini dapat mempengaruhi Lintas secara material, maka Lintas dapat mengambil kendali penyelesaian tuntutan ganti rugi tersebut atas biaya dari Pelanggan. Pelanggan tidak boleh menyetujui untuk tunduk pada putusan apapun atau tunduk pada penyelesaian klaim tanpa persetujuan terlebih dahulu dari Lintas secara tertulis, persetujuan mana tidak dapat ditahan tanpa dasar yang jelas.",
    subitems: []},
  ]
}

const Pasal11 = {
  title: "Pasal 11: Hukum yang Berlaku",
  clauses: [
    {content: "Perjanjian ini dan pelaksanaannya diatur dan diinterpretasikan menurut hukum yang berlaku di Republik Indonesia.",
    subitems: []},
  ]
}

const Pasal12 = {
  title: "Pasal 12: Penyelesaian Sengketa",
  clauses: [
    {content: "Apabila perselisihan timbul sehubungan dengan Syarat dan Ketentuan ini, Lintas dan Pelanggan setuju untuk bertemu dan mencoba menyelesaikannya dalam 30 (tiga puluh) hari kerja sejak terjadinya sengketa tersebut. Apabila perselisihan tidak dapat diselesaikan melalui negosiasi, kedua pihak akan menyelesaikan perselisihan melalui arbitrase sesuai dengan peraturan yang dikeluarkan oleh Badan Arbitrase Nasional Indonesia. Arbitrase akan dilakukan di hadapan 3 arbiter. Tempat arbitrase adalah di Jakarta, Indonesia. Bahasa yang digunakan dalam arbitrase adalah Bahasa Indonesia.",
    subitems: []},
  ]
}

export const tnc : Tnc = {
  descriptions: deskripsi,
  prefaces: pendahuluan,
  articles: [Pasal1, Pasal2, Pasal3, Pasal4, Pasal5, Pasal6, Pasal7, Pasal8, Pasal9, Pasal10, Pasal11, Pasal12]
}