import {CacheService} from '.';

import * as Config from '../Config';

class FileUploadService {
   async uploadImageBase64(module: string, title: string, imgBase64: string) {
      const token = CacheService.getToken();

      const file = DataURIToBlob(imgBase64);

      const formData = new FormData();
      formData.append('file', file, module);

      const response = await fetch(`${Config.STORAGE_API}/upload?module=${module}&title=${title}`, {
         method: 'POST',
         headers: {
            authorization: `${token ? token.token : ''}`,
         },
         body: formData,
      });
      const image = await response.json();
      return image;
   }
}

function DataURIToBlob(dataURI: string) {
   const splitDataURI = dataURI.split(',');
   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
   const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

   const ia = new Uint8Array(byteString.length);
   for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

   return new Blob([ia], {type: mimeString});
}

const Service = new FileUploadService();

export default Service;
