import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { Shipment } from "../../model";
import { isCustomer } from "../../model/User";
import * as ShipmentRef from "../../reference/Shipment";

import { CacheService, ShipmentService, ErrorService } from "../../service";

import { CustomButton, SectionIcon, PageView, InputSearch } from "../common";

import * as SearchHelper from "../../helper/SearchHelper";

import "./Shipment.css";

let modalContext: ModalContextInterface;

export default function ShipmentSummary(props: any) {
   const history = useHistory();
   const { match } = props;
   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [status, setStatus] = useState(match.params.status ? match.params.status.toUpperCase() : "");
   const [shipments, setShipments] = useState(ShipmentRef.getEmptyShipments(pageSize));
   const [searchShipment, setSearchShipment] = useState(match.params.desc ? match.params.desc : "");
   const [page, setPage] = useState(CacheService.getPage());
   const [pageCount, setPageCount] = useState(CacheService.getPageCount());
   const [user] = useState(CacheService.getUser());

   useEffect(() => {
      //props.setShowBottomNavigation(true);
      props.setShowBackNavigation(true);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      if (searchShipment === "") {
         setStatus("");
      }
   }, [searchShipment]);

   useEffect(() => {
      let search = "";
      if (searchShipment !== match.params.desc) {
         search = searchShipment;
      }

      setShipments(ShipmentRef.getEmptyShipments(pageSize));
      SearchHelper.searchInputs([search], () => {
         ShipmentService.searchShipment(search, status, pageSize, page)
            //ShipmentService.searchShipment(search, "", pageSize, page)
            .then((result) => {
               setShipments(result.shipments);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [page, history, searchShipment, status, match.params.desc]);

   const onPressNewShipment = (shipment: Shipment) => {
      history.push("/booking");
   };

   const onPressMore = (shipment: Shipment) => {
      CacheService.setShipment(shipment);
      // if (window.document.documentElement && window.innerWidth < 1000) {
      //    window.document.documentElement.requestFullscreen();
      // }
      CacheService.savePrev();
      history.push(`/shipment-detail/${shipment.gid}`);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="shipment">
                  <div className="shipment-header regular-bottom-border">
                     <div className="shipment-header-search">
                        <div className="shipment-header-search-section">
                           <SectionIcon truck className="carriers-header-search-icon" />
                           <div className="shipment-header-search-input">
                              <InputSearch placeholder="Cari Pengiriman" value={searchShipment} setValue={setSearchShipment} />
                           </div>
                        </div>
                     </div>
                     {isCustomer(user) && (
                        <div style={{ flex: 1, width: "100%", display: "flex", justifyContent: "flex-end" }}>
                           <CustomButton icon="truck" text="Pengiriman Baru" onClick={onPressNewShipment} style={{ width: 160 }} />
                        </div>
                     )}
                  </div>
                  <div
                     id="search-result"
                     className={
                        "shipment-header-search-result " +
                        (window.innerWidth < 1000
                           ? user.customer
                              ? props.showBottomNavigation
                                 ? "shipment-header-search-result-with-actions"
                                 : "shipment-header-search-result-with-actions-no-nav"
                              : props.showBottomNavigation
                              ? "shipment-header-search-result-no-actions"
                              : "shipment-header-search-result-no-actions-no-nav"
                           : "")
                     }
                  >
                     <PageView
                        shipments={shipments}
                        onPressMore={(shipment: Shipment) => {
                           onPressMore(shipment);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText="Belum terdapat pengiriman"
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
