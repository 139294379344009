import {Place} from '../model';

export const defaultLatLng = {
   lat: -6.1757003,
   lng: 106.8278006,
};

export const defaultPlace: Place = {
   id: '',
   name: '',
   address: '',
   lat: defaultLatLng.lat,
   lng: defaultLatLng.lng,
};

export const getEmptyPlaces = (count: number) => {
   const places = [];
   for (let i = 0; i < count; i++) {
      places.push({
         name: '',
         formatted_address: '',
      });
   }
   return places;
};
