import { gql } from "@apollo/client";

import { graphClient, initClient } from "./GraphService";

import * as TransportTypeRef from "../reference/TransportType";

const sleep = (ms: number) => {
   return new Promise((resolve) => setTimeout(resolve, ms));
};

class ReferenceService {
   async initTransportTypes() {
      // only query once, wait if there is already prev query but still waiting for result

      if (TransportTypeRef.isInitTransportTypes) {
         while (TransportTypeRef.transportTypes.length === 0) {
            await sleep(500);
         }
         return TransportTypeRef.transportTypes;
      }

      TransportTypeRef.setInitTransportTypes(true);

      await initClient();

      let gqlReq = `
            query { 
               GetTransportTypes {
                  code
                  desc
                  image
                  dimension
                  bcm
                  capacityText
                  capacity
                  category
                  tollFeeCategory
                  ferryFeeCategory
               }
            }`;

      //console.log("gql query: ", gqlReq);
      let result;

      try {
         result = await graphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (error) {
         console.error(error);
      }

      //console.log("result: ", JSON.stringify(result, null, 2));

      let resp = result ? result.data.GetTransportTypes : [];
      TransportTypeRef.setTransportTypes(resp);

      // console.log("GetTransportTypes:", JSON.stringify(resp, null, 2));

      return resp;
   }
}

const Service = new ReferenceService();

export default Service;
