import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { CarrierService, ShipmentService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import SearchInput from "../common/SearchInput";
import SubNavigation from "../common/SubNavigation";
import { CarrierView } from "../common";

import * as SearchHelper from "../../helper/SearchHelper";

import { Carrier } from "../../model";
import * as CarrierRef from "../../reference/Carrier";
import * as Master from "../../reference/Master";

import "./SelectCarrier.css";

let modalContext: ModalContextInterface;

export default function SelectCarrier(props: any) {
   const history = useHistory();
   const [search, setSearch] = useState("");
   const [carriers, setCarriers] = useState<Carrier[]>(CarrierRef.getEmptyCarriers(10));

   useEffect(() => {
      if (props.show) {
         searchCarrier(search);
      }
   }, [search, props.show]);

   const searchCarrier = (input: any) => {
      SearchHelper.searchInput(input, () => {
         let carrier = new Carrier();
         carrier.name = input;
         carrier.status = Master.STATUS_VERIFIED;
         CarrierService.searchCarrier(carrier, 10, 1).then((result) => {
            setCarriers(result.carriers);
         });
      });
   };

   const onSelectCarrier = (carrier: any) => {
      console.log("selectCarrier: ", JSON.stringify(carrier, null, 2));
      AlertHelper.alertLoading("Menyimpan data kendaraan angkutan", modalContext);

      ShipmentService.assignShipmentCarrier(props.shipment.gid, carrier.gid)
         .then((result) => {
            props.onSelectCarrier(carrier);
            props.setShow(false);
            AlertHelper.alertSuccess("Proses berhasil", modalContext);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onClickPrev = () => {
      props.setShow(false);
   };
   const onClickClose = () => {
      props.setShow(false);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               props.show && (
                  <div className="select-carrier">
                     <div className="select-carrier-container">
                        <SubNavigation title="Pilih Kendaraan Angkutan" onClickPrev={onClickPrev} onClickClose={onClickClose} />
                        <div className="select-carrier-search dark-border-bottom-side">
                           <div className="select-carrier-search-input">
                              <SearchInput
                                 carrierholder="Kendaraan Angkutan"
                                 onChangeText={(text: any) => {
                                    //searchCarrier(text);
                                    setSearch(text);
                                 }}
                                 style={{ marginBottom: 5 }}
                              />
                           </div>
                           <div className="select-carrier-search-result">
                              {carriers.map((carrier: Carrier, key: number) => (
                                 <CarrierView key={key} carrier={carrier} onPressMore={(carrier: any) => onSelectCarrier(carrier)} />
                              ))}
                           </div>
                        </div>
                     </div>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
