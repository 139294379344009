import React, { useEffect, useState } from "react";

import "./FadeView.css";

function FadeView(props: any) {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setShow(true);
      }, 1);
   });

   return (
      props.show && (
         <div className={props.className + " " + (show ? "show" : "hide")} style={props.style ? props.style : props.duration ? { WebkitTransitionDuration: props.duration } : {}}>
            {props.children}
         </div>
      )
   );
}

export default FadeView;
