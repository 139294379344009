export default class TransportType {
   code: string;
   desc: string;
   image: string;
   dimension: string;
   bcm: string;
   capacity: string;
   category: string;
   tollFeeCategory: number;
   ferryFeeCategory: number;
}
