import React, { useState } from "react";
import { Layout, Carousel } from "antd";
import AdItemProvider from "./AdItemProvider";

import "antd/dist/antd.css";

import { FadeView } from "../../common";

const ads1 = {
   fields: {
      imageUrl: "/images/cover/cover-1.jpg",
      text: "Aplikasi Logistik Online di Indonesia, Cara mudah cari Muatan",
   },
};

const ads2 = {
   fields: {
      imageUrl: "/images/cover/cover-2.jpg",
      text: "Tentukan sendiri biaya angkut dan tawarkan ke pelanggan. Tanpa Potongan Komisi",
   },
};

const ads3 = {
   fields: {
      imageUrl: "/images/cover/cover-3.jpg",
      text: "Biaya jalan, bensin & tol dibayar didepan",
   },
};

export const providerAds = [ads1, ads2, ads3];

function Ads(props: any) {
   const [ads, setAds] = useState<unknown[]>(providerAds);

   return (
      <Layout>
         <FadeView show={true} duration="0">
            <Carousel autoplay={true} speed={30000} autoplaySpeed={30000} effect={"fade"} dots={false} pauseOnHover={false}>
               {ads.map((ad, i) => {
                  return <AdItemProvider ad={ad} key={i} {...props} />;
               })}
            </Carousel>
         </FadeView>
      </Layout>
   );
}

export default Ads;
