import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Select } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { CacheService, VoucherService, ErrorService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";

import { Voucher } from "../../../model";

import { getEmptyVouchers } from "../../../reference/Voucher";

import * as Master from "../../../reference/Master";

import "./Vouchers.css";

const { Option } = Select;
let modalContext: ModalContextInterface;

export default function Vouchers(props: any) {
   const history = useHistory();

   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [vouchers, setVouchers] = useState(getEmptyVouchers(pageSize));
   const [searchVoucher, setSearchVoucher] = useState("");
   // const [searchProvider, setSearchProvider] = useState("");
   const [status, setStatus] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setVouchers(getEmptyVouchers(pageSize));
      SearchHelper.searchInputs([searchVoucher, /*searchProvider*/ ""], () => {
         // let provider = new Provider();
         // provider.name = searchProvider;
         let voucher = new Voucher();
         voucher.code = searchVoucher;
         // voucher.provider = provider;
         voucher.status = status;

         VoucherService.searchVoucher(voucher, pageSize, page)
            .then((result) => {
               setVouchers(result.vouchers);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchVoucher, /*searchProvider,*/ status, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchVoucher, /*searchProvider,*/ status]);

   const onPressNewVoucher = (voucher: Voucher) => {
      history.push("/admin/voucher/add");
   };

   const onPressMore = (voucher: Voucher) => {
      CacheService.setVoucher(voucher);
      history.push(`/admin/voucher/update/${voucher.gid}`);
   };

   const handleChange = (value: any) => {
      setStatus(value);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="vouchers">
                  <div className="vouchers-header regular-bottom-border">
                     <div className="vouchers-header-search">
                        <div className="vouchers-header-search-section">
                           <SectionIcon home className="vouchers-header-search-icon" />
                           <div className="vouchers-header-search-input">
                              <InputSearch placeholder="Cari Voucher" value={searchVoucher} setValue={setSearchVoucher} />
                           </div>
                        </div>
                        <div className="vouchers-header-search-section">
                           <SectionIcon option className="vouchers-header-search-icon" />
                           <div className="vouchers-header-search-input">
                              <Select className="vouchers-header-search-select" placeholder="Status" onChange={handleChange} allowClear>
                                 <Option value={Master.STATUS_ACTIVE}>Aktif</Option>
                                 <Option value={Master.STATUS_INACTIVE}>Tidak Aktif</Option>
                              </Select>
                           </div>
                        </div>
                     </div>
                     <div className="vouchers-header-button">
                        <CustomButton icon="truck" text="Tambah Voucher" onClick={onPressNewVoucher} style={{ width: 190 }} />
                     </div>
                  </div>
                  <div id="search-result" className={"search-result " + (user.isAdmin ? (props.showBottomNavigation ? "search-result-admin" : "search-result-admin-no-nav") : props.showBottomNavigation ? "search-result" : "search-result-no-nav")}>
                     <PageView
                        vouchers={vouchers}
                        onPressMore={(voucher: Voucher) => {
                           console.log("voucher:", JSON.stringify(voucher, null, 2));
                           onPressMore(voucher);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchVoucher && /*!searchProvider &&*/ !status && "Belum terdapat pool / pangkalan"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
