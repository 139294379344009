import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'antd';

import './RegisterSuccess.css';

function Register (props) {
  const history = useHistory ();
  const onContinue = () => history.push ('/profile');

  return (
    <div className="register-success">
      <div className="register-success-left">
        <img src="/images/doodle-register-success.png" alt="register-success" />
      </div>
      <div className="register-success-right">
        <div className="title">
          You have successfully created your account!
        </div>
        <div className="desc">
          Get the best photography experience for all your needs
        </div>
        <div className="register-success-form">
          <Button type="primary" onClick={onContinue}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Register;
