import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Radio } from "antd";

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ProviderService, CacheService, ErrorService } from "../../service";

import * as AlertHelper from "../../helper/AlertHelper";

import { ImageUpload, InputText, InputStatic, FormLayout, PlaceSelection, PlaceMapSelection } from "../common";

import { Provider, Image, Place } from "../../model";

import * as Master from "../../reference/Master";

import * as PlaceRef from "../../reference/Place";

import "./UpdateProvider.css";

let modalContext: ModalContextInterface;

function UpdateProvider(props: any) {
   const history = useHistory();
   const { control, errors, handleSubmit, setValue } = useForm();

   const user = CacheService.getUser();
   const providerGid = user.provider?.gid;
   console.log("UpdateProvider user:", JSON.stringify(CacheService.getUser(), null, 2));

   const [provider, setProvider] = useState<Provider>(new Provider());
   const [profileImg, setProfileImg] = useState<Image>();
   const [showSelectPlace, setShowSelectPlace] = useState(false);
   const [showSelectPlaceMap, setShowSelectPlaceMap] = useState(false);
   const [location, setLocation] = useState<Place>(PlaceRef.defaultPlace);
   const [status, setStatus] = useState("");

   const onChangeStatusOptions = (e: any) => {
      setStatus(e.target.value);
   };

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      ProviderService.getProvider(providerGid)
         .then((resp) => {
            setProvider(resp);
            setProfileImg(resp.profileImg);
            setLocation(resp.location ? resp.location : PlaceRef.defaultPlace);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [providerGid, history]);

   useEffect(() => {
      if (provider) {
         setValue([{ name: provider.name }, { phone: provider.phone }, { email: provider.email }, { profileDesc: provider.profileDesc }, { status: provider.status }]);
         setStatus(provider.status || "");
      }
   }, [provider, setValue]);

   const onSubmit = (data: any) => {
      if (!profileImg || !profileImg.id) {
         AlertHelper.alertWarning("Mohon upload/unggah foto armada", modalContext);
         return;
      }

      AlertHelper.alertLoading("Memperbarui data jasa angkutan ...", modalContext);

      const pvd: Provider = new Provider();
      pvd.name = data.name;
      pvd.phone = data.phone;
      pvd.email = data.email;
      pvd.profileImg = profileImg;
      pvd.profileImgUrl = profileImg.url;
      pvd.location = location;
      pvd.profileDesc = data.profileDesc;

      if (user.isAdmin) {
         pvd.status = status;
      }

      ProviderService.updateProvider(pvd, location)
         .then((result) => {
            AlertHelper.alertSuccess("Proses pembaruan data", modalContext);
         })
         .catch((error: any) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div>
                  <PlaceSelection show={showSelectPlace} setShow={setShowSelectPlace} setShowSelectPlaceMap={setShowSelectPlaceMap} place={location} setPlace={setLocation} />
                  <PlaceMapSelection show={showSelectPlaceMap} setShow={setShowSelectPlaceMap} setShowSelectPlace={setShowSelectPlace} place={location} setPlace={setLocation} />
                  <FormLayout doodle="/images/doodle/checklist.png" title="Pembaruan Data" desc="Anda dapat memperbarui data jasa angkutan anda">
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <ImageUpload single reference="provider-profile" image={profileImg} fleet text="Foto Armada" setImage={setProfileImg} />
                        <InputText name="name" placeholder="Nama Jasa Angkutan" control={control} error={errors.name} />
                        <InputText name="phone" placeholder="No. Telepon" phone control={control} error={errors.phone} />
                        <InputText name="email" placeholder="Email" email control={control} error={errors.email} />
                        <InputStatic
                           placeholder={"Alamat"}
                           onClick={() => {
                              setShowSelectPlace(true);
                           }}
                           value={location.address}
                        />
                        <InputText name="profileDesc" placeholder="Deskripsi" control={control} rows={2} size={2000} error={errors.profileDesc} />
                        {user.isAdmin && <Radio.Group name="status" options={Master.statusOptions} onChange={onChangeStatusOptions} value={status} buttonStyle="solid" />}
                        <div className="action-button">
                           <Button type="primary" htmlType="submit" style={{ marginTop: 20 }}>
                              Simpan
                           </Button>
                        </div>
                     </form>
                  </FormLayout>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default UpdateProvider;
