import React, { useState } from "react";

import { CapturePhoto } from "..";
import { ImageUpload } from "..";
import { CacheService } from "../../../service";

export function CaptureUploadTest(props: any) {
   const [photo, setPhoto] = useState("");
   const [images, setImages] = useState();
   const { match }: any = props;

   CacheService.setWebView(match.params.container === "webview");
   console.log("webview: ", CacheService.isWebView());

   return (
      <div style={{ height: window.innerHeight - 130, overflow: "auto", padding: 10, marginTop: 5, marginBottom: 5 }}>
         <ImageUpload multiple reference="shipment-photo" images={images} setImages={setImages} photo text="Pilih Photo" desc="Foto Muatan" />
         <CapturePhoto imageBase64={photo} setImageBase64={setPhoto} />
      </div>
   );
}

export default CaptureUploadTest;
