import React, {useEffect} from 'react';
import {useState} from 'react';

import SyncLoader from 'react-spinners/SyncLoader';

import FadeView from './FadeView';

import * as Color from '../../constants/Color';

import './LoadingView.css';

export default function LoadingView(props: any) {
   const [color] = useState(Color.primary);
   const [show, setShow] = useState(props.show);

   useEffect(() => {
      if (props.show) {
         setShow(props.show);
      } else {
         setTimeout(() => setShow(props.show), 100);
         //AlertHelper.enableScroll();
      }
   }, [props.show]);

   useEffect(() => {
      if (props.hide) {
         setShow(false);
         props.setShow(false);
         props.setHide(false);
         //AlertHelper.enableScroll();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.hide, props.setShow, props.setHide]);

   return (
      props.show && (
         <div className="loading-container" style={{height: window.innerHeight}}>
            <FadeView className="" show={show} duration="100">
               <div className="loading-content">
                  <div className="loading-icon">
                     <SyncLoader color={color} loading size={12} />
                  </div>
                  <div className="loading-title bold-font">{props.text ? props.text : 'Loading ...'}</div>
               </div>
            </FadeView>
         </div>
      )
   );
}
