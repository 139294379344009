import React from 'react';
import {Controller} from 'react-hook-form';
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/id_ID';

import Skeleton from 'react-loading-skeleton';

import './InputDate.css';

export default function InputDate(props: any) {
   const loadingStyle = props.loading && !props.loadingRef ? 'input-date-label-loading' : 'input-date-label';
   return (
      <div className="input-date">
         {props.loading && !props.loadingRef ? (
            <div className="input-date-skeleton regular-border">
               <Skeleton height={20} />
            </div>
         ) : (
            <Controller
               as={DatePicker}
               onChange={props.onChange}
               name={props.name}
               format="DD-MM-YYYY"
               locale={locale}
               control={props.control}
               rules={{
                  required: {
                     value: true,
                     message: ` Mohon isi ${props.placeholder.toLowerCase()}`,
                  },
               }}
               placeholder="dd-mm-yyyy"
            />
         )}
         {props.error && (
            <div className="error">
               <i className="fa fa-times-circle fa-lg" />
               {props.error.message}
            </div>
         )}
         <div className="input-date-label-container">
            <div className={`input-date-label ${loadingStyle} regular-grey-font`}>{props.placeholder}</div>
         </div>
      </div>
   );
}
