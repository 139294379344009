import {Shipment} from '.';

/*
	ID          int     `json:"id"`
	Shipment       *Shipment  `json:"shipment"`
	Code        string  `json:"code"`
	Description string  `json:"description"`
	Count       float64 `json:"count"`
	UnitPrice   float64 `json:"unitPrice"`
	SubTotal    float64 `json:"subTotal"`
	FeeType     string  `json:"feeType"`
	GroupType   string  `json:"groupType"`
	GroupOrder  int     `json:"groupOrder"`
	Status      string  `json:"status"`
*/

export default class Fee {
   id?: number;
   shipment?: Shipment;
   code: string;
   description: string;
   count: number;
   unitPriceBase: number;
   unitPriceComs: number;
   unitPrice: number;
   subTotal: number;
   feeType?: string;
   groupType?: string;
   groupOrder?: number;
   status: string;
}
