import * as React from "react";
import { useState, useEffect } from "react";
import ReactCalendar from "react-calendar";

import CustomButton from "./CustomButton";

import * as CalendarHelper from "../../helper/CalendarHelper";

import "react-calendar/dist/Calendar.css";
import "./Calendar.css";

import * as Font from "../../constants/Font";
import * as Layout from "../../constants/Layout";

const days = ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];

export default function Calendar(props: any) {
   const [firstDate, setFirstDate] = useState(new Date());
   const [dates, setDates] = useState(new Array<Date>());
   const [showCal, setShowCal] = useState(false);
   const [selectedDate, setSelectedDate] = useState(new Date(props.date));
   console.log("Calendar selectedDate:", selectedDate);

   const { innerWidth: width } = window;
   const small = width < Layout.width;
   const daysCount = props.week ? 7 : small ? 5 : 7;
   const daysGap = small ? 2 : 3;

   useEffect(() => {
      if (window.innerWidth >= 450) {
         firstDate.setHours(0, 0, 0, 0);
         const newDates = new Array<Date>();
         for (let i = 0; i < daysCount; i++) {
            newDates.push(new Date(firstDate.getTime() + i * 24 * 60 * 60 * 1000));
         }
         setDates(newDates);
      }
   }, [firstDate, daysCount]);

   const onSelectDate = (date: any) => {
      setSelectedDate(date);
      props.setDate(date);
   };

   // @ts-ignore
   const onChange = (date: Date) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date < today) {
         return;
      }

      setShowCal(false);

      date.setHours(0, 0, 0, 0);
      props.setDate(date);
      setSelectedDate(date);

      let fd = new Date(date.getTime() - daysGap * 24 * 60 * 60 * 1000);
      fd = fd.getTime() < today.getTime() ? today : fd;
      //console.log("First Date:", fd);
      setFirstDate(fd);
   };

   const showDatepicker = () => {
      setShowCal(true);
   };

   //console.log("calendar:", window.innerWidth);

   return (
      <div className={(props.week ? "custom-calendar-week" : "custom-calendar") + "custom-calendar custom-calendar-container " + props.className} style={{ ...props.style }}>
         {showCal && (
            <div className={props.center ? "custom-calendar-picker-center" : "custom-calendar-picker"}>
               <ReactCalendar
                  formatMonthYear={(locale, date) => CalendarHelper.dateFormat(date, "mmm yyyy")}
                  formatShortWeekday={(locale, date) => CalendarHelper.dateFormat(date, "ddd")}
                  onChange={(date: Date | Date[]) => {
                     setSelectedDate(new Date(date.toString()));
                  }}
                  value={props.date}
                  onClickDay={(date: Date) => {
                     onChange(date);
                  }}
               />
            </div>
         )}
         {window.innerWidth >= 450 && dates.map((date, key) => <DateButton date={date} selectedDate={selectedDate} setSelectedDate={setSelectedDate} key={key} onPress={() => onSelectDate(date)} />)}
         {window.innerWidth < 450 && (
            <div className="custom-calendar-date regular-dash-border regular-bold-font">
               <span>{CalendarHelper.getDay(props.date)}</span> , <div>{CalendarHelper.getDateShortMonth(props.date)}</div>
            </div>
         )}
         <div
            style={{
               textAlign: "center",
            }}
         >
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
               <CustomButton icon="calendar" onClick={showDatepicker} />
            </div>
            {window.innerWidth >= 450 && <div style={{ ...Font.regularFont, marginTop: 3 }}></div>}
         </div>
      </div>
   );
}

function DateButton(props: any) {
   return (
      <div onClick={props.onPress} className="custom-calendar-button">
         <div
            className="custom-calendar-background"
            style={{
               backgroundColor: CalendarHelper.isSameDay(props.date, props.selectedDate) ? "#8393ca" : "#fff",
               display: "flex",
               flexDirection: "column",
            }}
         >
            <div
               style={{
                  ...Font.regularFont,
                  cursor: "pointer",
                  margin: "auto",
                  color: CalendarHelper.isSameDay(props.date, props.selectedDate) ? "#fff" : "#555",
               }}
            >
               {new Date(props.date).getDate()}
            </div>
         </div>
         <div style={{ ...Font.regularFont, marginTop: 3, textAlign: "center", cursor: "pointer" }}>{days[new Date(props.date).getDay()]}</div>
      </div>
   );
}
