import React from "react";
import { Controller } from "react-hook-form";
import { Select as SelectField } from "antd";

import Skeleton from "react-loading-skeleton";

import "./InputSelect.css";

export default function InputSelect(props: any) {
   return (
      <div className="input-select" style={props.style}>
         {props.loading && !props.loadingRef ? (
            <div className="input-select-skeleton regular-border">
               <Skeleton height={20} />
            </div>
         ) : (
            <Controller
               as={SelectField}
               showSearch
               name={props.name}
               control={props.control}
               options={props.options}
               onChange={props.onChange}
               onSelect={props.onSelect}
               defaultValue={props.defaultValue}
               disabled={props.disabled}
               className="input-select-select"
               filterOption={(input: any, option: any) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
               }}
               rules={
                  !props.optional
                     ? {
                          required: {
                             value: true,
                             message: ` Mohon pilih ${props.placeholder.toLowerCase()}`,
                          },
                       }
                     : {}
               }
            />
         )}
         {props.error && (
            <div className="error">
               <i className="fa fa-times-circle fa-lg" />
               {props.error.message}
            </div>
         )}
         {props.placeholder && !props.hidePlaceholder && (
            <div className="input-select-label-container">
               <div className="input-select-label regular-grey-font">{props.placeholder}</div>
            </div>
         )}
      </div>
   );
}
