import * as React from "react";

import { FiTruck, FiPackage, FiPhone } from "react-icons/fi";
import { AiOutlineUser, AiOutlineHome } from "react-icons/ai";
import { CgOptions } from "react-icons/cg";
import { BsStar } from "react-icons/bs";
import { BiBuildingHouse } from "react-icons/bi";
//import {FaRegCalendarAlt, FaRegMoneyBillAlt, FaRegClock} from 'react-icons/fa';

import "./SectionIcon.css";

interface SectionIconParams {
   truck?: boolean;
   person?: boolean;
   option?: boolean;
   star?: boolean;
   home?: boolean;
   package?: boolean;
   building?: boolean;
   phone?: boolean;
   color?: string;
   className?: string;
}

export default function SectionIcon(props: SectionIconParams) {
   return (
      <div className={`section-icon section-icon-${props.color ? props.color : "purple"} ${props.className}`}>
         {props.truck && <FiTruck className="section-icon-img" />}
         {props.person && <AiOutlineUser className="section-icon-img" />}
         {props.home && <AiOutlineHome className="section-icon-img" />}
         {props.option && <CgOptions className="section-icon-img" />}
         {props.star && <BsStar className="section-icon-img" />}
         {props.phone && <FiPhone className="section-icon-img" />}
         {props.package && <FiPackage className="section-icon-img" />}
         {props.building && <BiBuildingHouse className="section-icon-img" />}
      </div>
   );
}
