import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Radio, InputNumber } from "antd";
//import useWebSocket from 'react-use-websocket';

import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ImageUpload } from "../common";

import Skeleton from "react-loading-skeleton";

import { ImageView, FadeView, RouteView, CustomButton, SquareButton, DeliveryProgress, ImagesPanel, ImagesGallery, ShipmentSteps, SelectOption as SelectDeclineReason } from "../common";

import * as ReferenceHelper from "../../helper/ReferenceHelper";
import * as FormatHelper from "../../helper/FormatHelper";
import * as AlertHelper from "../../helper/AlertHelper";
import * as ConfirmHelper from "../../helper/ConfirmHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as CommonHelper from "../../helper/CommonHelper";
import * as DirectionHelper from "../../helper/DirectionHelper";
import * as PositionHelper from "../../helper/PositionHelper";
import * as PricingHelper from "../../helper/PricingHelper";

import { CacheService, ShipmentService, ComplaintService, ErrorService, CarrierService, ServiceService } from "../../service";

import { Shipment, Carrier, Image, Fee, Transporter, Option, Step, Route, Complaint, Payment, Service } from "../../model";
import { isCustomer, isProvider, isAdmin, isTransporter, isShipmentTransporter } from "../../model/User";

import * as ShipmentRef from "../../reference/Shipment";
import * as StepRef from "../../reference/Step";
import * as RouteRef from "../../reference/Route";

import * as Master from "../../reference/Master";

import SelectCarrier from "./SelectCarrier";
import SelectTransporter from "./SelectTransporter";
import Reschedule from "./Reschedule";
import ProcessShipment from "./ProcessShipment";
import ProcessComplaint from "./ProcessComplaint";
import Rating from "./Rating";
import StepImages from "./StepImages";
import TrackPosition from "./TrackPosition";

import "./ShipmentDetail.css";

let modalContext: ModalContextInterface;

export default function ShipmentDetail(props: any) {
   const history = useHistory();
   const { match }: any = props;

   const [paymentRefNo] = useState(new URLSearchParams(window.location.search).get("order_id"));
   const [shipmentGid, setShipmentGid] = useState();
   const [nextDestination, setNextDestination] = useState();
   const [user] = useState(CacheService.getUser());

   const [showReschedule, setShowReschedule] = useState(false);
   const [showProcessShipment, setShowProcessShipment] = useState(false);
   const [showProcessComplaint, setShowProcessComplaint] = useState(false);
   const [showStepImages, setShowStepImages] = useState(false);
   const [showImagesGallery, setShowImagesGallery] = useState(false);
   const [showPaymentImagesGallery, setShowPaymentImagesGallery] = useState(false);
   const [showSelectCarrier, setShowSelectCarrier] = useState(false);
   const [showSelectTransporter, setShowSelectTransporter] = useState(false);
   const [showSelectDeclineReason, setShowSelectDeclineReason] = useState(false);
   const [showRating, setShowRating] = useState(false);
   const [showTrackPosition, setShowTrackPosition] = useState(false);
   const [showFeeAndPayment, setShowFeeAndPayment] = useState(false);
   const [showAction, setShowAction] = useState(false);
   const [showPartialPaymentAction, setShowPartialPaymentAction] = useState(false);
   const [showFullPaymentAction, setShowFullPaymentAction] = useState(false);
   const [showFinalPaymentAction, setShowFinalPaymentAction] = useState(false);
   const [showPaymentOptions, setShowPaymentOptions] = useState(false);
   const [showConfirmOptions, setShowConfirmOptions] = useState(false);
   const [confirmStepIndex, setConfirmStepIndex] = useState(1);

   const [shipment, setShipment] = useState<Shipment>(ShipmentRef.emptyShipment);
   const [service, setService] = useState<Service>();
   const [payment, setPayment] = useState<Payment>(ShipmentRef.emptyShipment.payment);
   const [complaint, setComplaint] = useState<Complaint>();
   const [carrier, setCarrier] = useState<Carrier>();
   const [transporter, setTransporter] = useState<Transporter>();
   const [images, setImages] = useState(new Array<Image>());
   const [paymentImages, setPaymentImages] = useState(new Array<Image>());
   const [paymentImage, setPaymentImage] = useState<Image>();
   const [stepImages, setStepImages] = useState(new Array<Image>());
   const [routes, setRoutes] = useState<Route[]>(RouteRef.getEmptyRoutes(2));
   const [currentRoute, setCurrentRoute] = useState<Route>();
   const [fees, setFees] = useState<Fee[]>();
   const [steps, setSteps] = useState<Step[]>(StepRef.getEmptySteps(8));
   const [imageIndex, setImageIndex] = useState(0);
   const [paymentImageIndex, setPaymentImageIndex] = useState(0);

   const [confirmType, setConfirmType] = useState(Master.CONFIRM_WITH_BOOKING_FEE);
   const [topStatus, setTopStatus] = useState(Master.TOP_NO);
   const [topDuration, setTopDuration] = useState(0);
   const [providerAmount, setProviderAmount] = useState(0);
   const [chargeAmount, setChargeAmount] = useState(0);
   const [planPaymentBookingAmount, setPlanPaymentBookingAmount] = useState(0);
   const [planPaymentInitialAmount, setPlanPaymentInitialAmount] = useState(0);
   const [planPaymentFinalAmount, setPlanPaymentFinalAmount] = useState(0);
   const [planPayoutBookingAmount, setPlanPayoutBookingAmount] = useState(0);
   const [planPayoutInitialAmount, setPlanPayoutInitialAmount] = useState(0);
   const [planPayoutFinalAmount, setPlanPayoutFinalAmount] = useState(0);

   const [paymentType, setPaymentType] = useState(Master.PAYMENT_TYPE_FULL);
   const [paymentMethod, setPaymentMethod] = useState(Master.PAYMENT_METHOD_TRANSFER);
   const [paymentAmount, setPaymentAmount] = useState(0);
   const [payoutAmount, setPayoutAmount] = useState(0);

   const [contactName, setContactName] = useState("");
   const [contactMobile, setContactMobile] = useState("");
   const [contactImgUrl, setContactImgUrl] = useState("");
   const [contactNameCustomer, setContactNameCustomer] = useState("");
   const [contactMobileCustomer, setContactMobileCustomer] = useState("");
   const [contactImgUrlCustomer, setContactImgUrlCustomer] = useState("");
   const [contactNameProvider, setContactNameProvider] = useState("");
   const [contactMobileProvider, setContactMobileProvider] = useState("");
   const [contactImgUrlProvider, setContactImgUrlProvider] = useState("");
   const [bookingCodeLabel, setBookingCodeLabel] = useState("");
   const [bookingCode, setBookingCode] = useState("");
   const [transportTypeLabel, setTransportTypeLabel] = useState("");
   const [transportType, setTransportType] = useState("");
   const [statusLabel, setStatusLabel] = useState("");
   const [status, setStatus] = useState("");
   const [stepStatus, setStepStatus] = useState("");
   const [statusDesc, setStatusDesc] = useState("");
   const [statusReason, setStatusReason] = useState("");
   const [deliveryDateLabel, setDeliveryDateLabel] = useState("");
   const [deliveryDate, setDeliveryDate] = useState("");
   const [rescheduleInfo, setRescheduleInfo] = useState("");
   const [providerNameLabel, setProviderNameLabel] = useState("");
   const [providerName, setProviderName] = useState("");
   const [carrierInfoLabel, setCarrierInfoLabel] = useState("");
   const [carrierInfo, setCarrierInfo] = useState("");
   const [transporterNameLabel, setTransporterNameLabel] = useState("");
   const [transporterName, setTransporterName] = useState("");
   const [descLabel, setDescLabel] = useState("");
   const [desc, setDesc] = useState("");
   const [placeLabel, setPlaceLabel] = useState("");
   const [place, setPlace] = useState("");
   const [picPlaceLabel, setPicPlaceLabel] = useState("");
   const [picPlaceName, setPicPlaceName] = useState("");
   const [picPlaceMobile, setPicPlaceMobile] = useState("");
   const [feeLabel, setFeeLabel] = useState("");
   const [platformFeeLabel, setPlatformFeeLabel] = useState("");
   const [platformFeeAmount, setPlatformFeeAmount] = useState(0);
   const [platformFee, setPlatformFee] = useState("");
   const [discountProvider, setDiscountProvider] = useState(0);
   const [discountProviderLabel, setDiscountProviderLabel] = useState("");
   const [discountProviderAmount, setDiscountProviderAmount] = useState("");
   const [discountLabel, setDiscountLabel] = useState("");
   const [totalChargeLabel, setTotalChargeLabel] = useState("");
   const [totalLabel, setTotalLabel] = useState("");
   const [total, setTotal] = useState("");
   const [totalProviderLabel, setTotalProviderLabel] = useState("");
   const [totalProvider, setTotalProvider] = useState("");
   const [totalPlatformLabel, setTotalPlatformLabel] = useState("");
   const [totalPlatform, setTotalPlatform] = useState("");
   const [paymentLabel, setPaymentLabel] = useState("");
   const [paymentTotalLabel, setPaymentTotalLabel] = useState("");
   const [paymentTotal, setPaymentTotal] = useState("");
   const [photoLabel, setPhotoLabel] = useState("");
   const [paymentReceiptLabel, setPaymentReceiptLabel] = useState("");
   const [deliveryProgressLabel, setDeliveryProgressLabel] = useState("");

   const [step, setStep] = useState<Step>();
   const [nextStep, setNextStep] = useState<Step>();

   /* WebSocket approach not yet supported
   const [socketUrl] = useState(Config.POSITION_WS_API);

   const {
      sendMessage,
      lastMessage,
      //readyState,
   } = useWebSocket(socketUrl);

   const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
   }[readyState];
   
   useEffect(() => {
      if (lastMessage) {
         console.log('position: ' + JSON.stringify(JSON.parse(lastMessage.data), null, 2));
      }
   }, [lastMessage]);
   */

   const onClickCallContact = () => {
      window.open("tel:+" + contactMobile);
   };

   const onClickCallContactCustomer = () => {
      window.open("tel:+" + contactMobileCustomer);
   };

   const onClickCallContactProvider = () => {
      window.open("tel:+" + contactMobileProvider);
   };

   const onClickChatContact = () => {
      window.open("https://wa.me/" + contactMobile);
   };

   const onClickChatContactCustomer = () => {
      window.open("https://wa.me/" + contactMobileCustomer);
   };

   const onClickChatContactProvider = () => {
      window.open("https://wa.me/" + contactMobileProvider);
   };

   const onClickCallPlacePic = () => {
      window.open("tel:+" + picPlaceMobile);
   };

   const onClickChatPlacePic = () => {
      window.open("https://wa.me/" + picPlaceMobile);
   };

   const onChangeConfirmTypeOptions = (e: any) => {
      let value = e.target.value;
      setConfirmType(value);
   };

   const onChangeTopStatusOptions = (e: any) => {
      let value = e.target.value;
      setTopStatus(value);
   };

   const onChangePaymentTypeOptions = (e: any) => {
      let value = e.target.value;
      setPaymentType(value);
      if (value === Master.PAYMENT_TYPE_FULL) {
         setPaymentAmount(shipment.payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount);
         setShowFullPaymentAction(true);
         setShowPartialPaymentAction(false);
      } else {
         let minPaymentAmount = 0;
         if (payment.status === Master.PAYMENT_STATUS_PENDING) {
            minPaymentAmount = payment.planPaymentBooking;
         } else if (payment.status === Master.PAYMENT_STATUS_CONFIRM) {
            minPaymentAmount = payment.planPaymentInitial;
         } else if (payment.status === Master.PAYMENT_STATUS_PARTIAL) {
            minPaymentAmount = payment.planPaymentFinal;
         }

         if (status === Master.SHIPMENT_STATUS_PENDING_PAYMENT) {
            AlertHelper.alertInfo("Anda dapat menyesuaikan jumlah pembayaran sendiri dengan minimum pembayaran sebesar " + FormatHelper.formatCurrency(minPaymentAmount), modalContext);
         }

         if (payment.status === Master.PAYMENT_STATUS_PENDING) {
            setPaymentAmount(payment.planPaymentBooking);
         } else if (payment.status === Master.PAYMENT_STATUS_CONFIRM) {
            setPaymentAmount(payment.planPaymentInitial);
         } else if (payment.status === Master.PAYMENT_STATUS_PARTIAL) {
            setPaymentAmount(payment.planPaymentFinal);
         }

         setShowPartialPaymentAction(true);
         setShowFullPaymentAction(false);
      }
   };

   const onChangePaymentMethodOptions = (e: any) => {
      let value = e.target.value;
      setPaymentMethod(value);
   };

   useEffect(() => {
      props.setShowBottomNavigation(false);
      props.setShowBackNavigation(true);
   }, [props]);

   useEffect(() => {
      if (match.params.id) {
         setShipmentGid(match.params.id);
      }
   }, [match.params.id]);

   useEffect(() => {
      if (paymentRefNo) {
         ShipmentService.verifyPayment(paymentRefNo).then((shipmentGid) => {
            setShipmentGid(shipmentGid);
         });
      }
   }, [paymentRefNo]);

   useEffect(() => {
      if (!shipmentGid) {
         return;
      }

      ShipmentService.getShipment(shipmentGid)
         .then((resp) => {
            let shipment: Shipment = CommonHelper.copyObject(resp.shipment);

            /* WebSocket approach not yet supported
            if (shipment.carrier) {
               let msg = {
                  t: Master.MESSAGE_TYPE_SUBSCRIBE, // Subscribe
                  i: [shipment.carrier.id],
               };
               sendMessage(JSON.stringify(msg));
            }*/

            // get service to check whether service has been verified before shipment can be accepted
            ServiceService.getServiceByProvider(shipment.provider?.gid, shipment.transportTypeCode).then((resp) => {
               setService(resp);
               // console.log("service: ", JSON.stringify(resp, null, 2));
            });

            let fees: any;
            if (resp.fees) {
               fees = resp.fees.filter((fee: any) => fee.subTotal > 0);
            }

            // console.log("stepStatusShipment: ", shipment);
            // console.log("stepStatus: ", shipment.stepStatus);

            setShipment(shipment);
            setStepStatus(shipment.stepStatus);
            setImages(resp.images);
            setFees(fees);
            setSteps(resp.steps);
            setRoutes(resp.routes);
            setCarrier(shipment.carrier);
            setTransporter(shipment.transporter);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   }, [shipmentGid, user, history]);

   useEffect(() => {
      if (!shipment || !shipment.gid) {
         return;
      }

      let pmt = shipment.payment;
      setPayment(pmt);
      setContactImgUrl((user.customer ? (shipment.transporter ? shipment.transporter.profileImgUrl : shipment.provider?.profileImgUrl) : shipment.customer?.profileImgUrl) || Master.IMAGE_ALT_PERSON);
      setContactName((user.customer ? (shipment.transporter ? shipment.transporter.name : shipment.provider?.name) : shipment.picOrderName) || "");
      setContactMobile(
         CommonHelper.formatMobile(
            shipment.status === Master.SHIPMENT_STATUS_NEW_ORDER || shipment.status === Master.SHIPMENT_STATUS_PENDING_PAYMENT
               ? Master.MOBILE_LINTAS
               : (isCustomer(user) ? (shipment.transporter ? shipment.transporter.phone : shipment.provider?.phone) : shipment.picOrderMobile) || Master.MOBILE_LINTAS
         )
      );

      let imgs = new Array<Image>();

      if (payment.bookingPaymentImgUrl) {
         let img = new Image();
         img.name = "Bukti Pembayaran Booking";
         img.url = payment.bookingPaymentImgUrl;
         imgs.push(img);
      }
      if (payment.initialPaymentImgUrl) {
         let img = new Image();
         img.name = "Bukti Pembayaran Awal";
         img.url = payment.initialPaymentImgUrl;
         imgs.push(img);
      }
      if (payment.bookingPaymentImgUrl) {
         let img = new Image();
         img.name = "Bukti Pembayaran Pelunasan";
         img.url = payment.finalPaymentImgUrl;
         imgs.push(img);
      }
      if (payment.bookingPayoutImgUrl) {
         let img = new Image();
         img.name = "Bukti Pembayaran Booking Mitra";
         img.url = payment.bookingPayoutImgUrl;
         imgs.push(img);
      }
      if (payment.initialPayoutImgUrl) {
         let img = new Image();
         img.name = "Bukti Pembayaran Awal Mitra";
         img.url = payment.initialPayoutImgUrl;
         imgs.push(img);
      }
      if (payment.finalPayoutImgUrl) {
         let img = new Image();
         img.name = "Bukti Pembayaran Pelunasan Mitra";
         img.url = payment.finalPayoutImgUrl;
         imgs.push(img);
      }

      setPaymentImages(imgs);

      // console.log("paymentImages: ", JSON.stringify(imgs, null, 2));
      // console.log("shipment: ", JSON.stringify(shipment, null, 2));

      setContactImgUrlCustomer(shipment.customer?.profileImgUrl || Master.IMAGE_ALT_PERSON);
      setContactNameCustomer(shipment.picOrderName || "");
      setContactMobileCustomer(CommonHelper.formatMobile(shipment.picOrderMobile || Master.MOBILE_LINTAS));
      setContactImgUrlProvider((shipment.transporter ? shipment.transporter.profileImgUrl : shipment.provider?.profileImgUrl) || Master.IMAGE_ALT_PERSON);
      setContactNameProvider((shipment.transporter ? shipment.transporter.name : shipment.provider?.name) || "");
      setContactMobileProvider(CommonHelper.formatMobile((shipment.transporter ? shipment.transporter.phone : shipment.provider?.phone) || Master.MOBILE_LINTAS));

      setBookingCodeLabel("Kode Booking");
      setBookingCode(shipment.bookingCode ? shipment.bookingCode : "");
      setTransportTypeLabel("Jenis Angkutan");
      setTransportType(shipment.transportTypeDesc ? shipment.transportTypeDesc : "");
      setStatusLabel("Status");
      setStatus(shipment.status);
      setStepStatus(shipment.stepStatus);
      setStatusDesc(ReferenceHelper.getShipmentStatusDesc(shipment.status));
      setStatusReason(shipment.declineDesc);
      setDeliveryDateLabel("Tgl. Pengiriman");
      setDeliveryDate(CalendarHelper.getDayDateMonthTime(shipment.deliveryDate));
      setProviderNameLabel("Jasa Angkutan");
      setProviderName(shipment.provider?.name ? shipment.provider?.name : "");
      setCarrierInfoLabel("Kendaraan Angkutan");
      setCarrierInfo(shipment.carrier?.code && shipment.carrier?.licenseNo ? shipment.carrier?.code + " / " + shipment.carrier?.licenseNo : isProvider(user) ? "Pilih kendaraan" : "-");
      setTransporterNameLabel("Pengemudi");
      setTransporterName(shipment.transporter?.name ? shipment.transporter?.name : isProvider(user) ? "Pilih pengemudi" : "-");
      setDescLabel("Info Muatan");
      setDesc(shipment.description ? shipment.description : "-");
      setFeeLabel(isProvider(user) ? "Info Pembayaran" : "Info Biaya");
      setPaymentLabel(pmt.status === Master.PAYMENT_STATUS_PENDING ? "Pembayaran Belum Diterima" : pmt.status === Master.PAYMENT_STATUS_PARTIAL ? "Pembayaran Sebagian" : "Pembayaran Lunas");
      setPaymentTotalLabel("Sisa Tagihan");
      setPaymentTotal(FormatHelper.formatCurrency(shipment.payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount - shipment.payment.initialPaymentAmount - shipment.payment.finalPaymentAmount));
      setTotalChargeLabel("Total Tagihan");
      setDiscountLabel("Diskon " + shipment.payment.discountCode);
      setDiscountProviderLabel("Diskon");
      setTotalLabel("Total");
      setPaymentReceiptLabel("Bukti Pembayaran");
      setPhotoLabel("Foto Muatan");
      setDeliveryProgressLabel("Proses Pengiriman");

      setChargeAmount(shipment.payment.totalCharge);
      setPaymentAmount(shipment.payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount - shipment.payment.initialPaymentAmount - shipment.payment.finalPaymentAmount);

      if (payment.planPayoutBooking !== 0 && payment.planPayoutBooking !== payment.bookingPayoutAmount) {
         setPayoutAmount(payment.planPayoutBooking);
      } else if (payment.planPayoutInitial !== 0 && payment.planPayoutInitial !== payment.initialPayoutAmount) {
         setPayoutAmount(payment.planPayoutInitial);
      } else if (payment.planPayoutFinal !== 0 && payment.planPayoutFinal !== payment.finalPayoutAmount) {
         setPayoutAmount(payment.planPayoutFinal);
      }

      if ((isProvider(user) && shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING) || (isCustomer(user) && shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING)) {
         setRescheduleInfo("Mohon konfirmasi permintaan perubahan jadwal ke " + CalendarHelper.getDayDateMonthTime(shipment.rescheduleToDate) + " karena " + shipment.rescheduleRemarks);
      } else if (shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING || shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING) {
         setRescheduleInfo("Menunggu konfirmasi permintaan perubahan jadwal ke " + CalendarHelper.getDayDateMonthTime(shipment.rescheduleToDate));
      }

      const pltTotalFees = getPlatformTotalFees(fees ? fees : []);

      setPlatformFeeLabel("Biaya Platform + Admin");
      setPlatformFeeAmount(pltTotalFees);
      setPlatformFee(FormatHelper.formatCurrency(pltTotalFees));
      setProviderAmount(getProviderTotalFees(fees ? fees : []));
      //setProviderAmount(shipment.payment.totalProviderAmount);

      setTotalProviderLabel("Pendapatan Mitra");
      setTotalProvider(FormatHelper.formatCurrency(shipment.payment.totalProviderAmount));
      setTotalPlatformLabel("Pendapatan Platform");
      setTotalPlatform(FormatHelper.formatCurrency(shipment.payment.totalPlatformAmount));

      if (isCustomer(user) || isAdmin(user)) {
         const totalFee = shipment.payment.totalPaymentAmount ? shipment.payment.totalPaymentAmount : 0;
         setTotal(FormatHelper.formatCurrency(totalFee));
      } else if (isProvider(user)) {
         const totalFee = getProviderTotalFees(fees ? fees : []);
         const discProvider = totalFee - shipment.payment.totalProviderAmount;
         setDiscountProvider(discProvider);
         setDiscountProviderAmount("- " + FormatHelper.formatCurrency(discProvider));
         setTotal(FormatHelper.formatCurrency(totalFee - discProvider));
      }

      ComplaintService.getComplaint(shipment.gid).then((resp: Complaint) => {
         const complaint: Complaint = CommonHelper.copyObject(resp);
         setComplaint(complaint);
      });

      // console.log("shipment: ", JSON.stringify(shipment, null, 2));
   }, [user, shipment, images, fees, steps, routes, carrier, transporter, payment, shipment.payment]);

   useEffect(() => {
      setShowAction(false);
      setShowPartialPaymentAction(false);
      setShowFullPaymentAction(false);
      setShowFinalPaymentAction(false);
      setShowConfirmOptions(false);

      if (
         user.isAdmin &&
         status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
         stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION &&
         payment.topDuration === 0 &&
         payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount
      ) {
         setShowFinalPaymentAction(true);
      } else if (
         /*isProvider(user) ||*/ (isAdmin(user) && status === Master.SHIPMENT_STATUS_NEW_ORDER) ||
         ((isCustomer(user) || isAdmin(user)) &&
            ((status === Master.SHIPMENT_STATUS_PENDING_PAYMENT && shipment.rescheduleStatus !== Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING && payment.bookingPaymentAmount === 0) ||
               (status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                  stepStatus === Master.STEP_CODE_COMPLETE_LOADING &&
                  payment.initialPaymentAmount === 0 && payment.topDuration === 0 &&
                  payment.status !== Master.PAYMENT_STATUS_COMPLETED))) ||
         (isShipmentTransporter(user, shipment) &&
            (status === Master.SHIPMENT_STATUS_CONFIRM_PAYMENT || status === Master.SHIPMENT_STATUS_CONFIRM_NO_PAYMENT || status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS) &&
            !(stepStatus === Master.STEP_CODE_COMPLETE_LOADING && payment.topDuration === 0 && payment.initialPaymentAmount === 0 && payment.status !== Master.PAYMENT_STATUS_COMPLETED) &&
            !(stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION && payment.topDuration === 0 && payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount) &&
            shipment.rescheduleStatus !== Master.RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING) ||
         (isAdmin(user) &&
            ((status === Master.SHIPMENT_STATUS_PENDING_PAYMENT &&
               shipment.rescheduleStatus !== Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING &&
               payment.bookingPaymentAmount !== 0 &&
               payment.planPayoutBooking !== 0 &&
               payment.planPayoutBooking !== payment.bookingPayoutAmount) ||
               (status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                  (stepStatus === Master.STEP_CODE_ARRIVE_AT_ORIGIN || stepStatus === Master.STEP_CODE_START_LOADING || stepStatus === Master.STEP_CODE_COMPLETE_LOADING || stepStatus === Master.STEP_CODE_TRAVEL_TO_DESTINATION) &&
                  payment.planPayoutInitial !== 0 &&
                  payment.planPayoutInitial !== payment.initialPayoutAmount) ||
               (status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                  (stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION || stepStatus === Master.STEP_CODE_START_UNLOADING || stepStatus === Master.STEP_CODE_COMPLETE_UNLOADING) &&
                  payment.planPayoutFinal !== 0 &&
                  payment.planPayoutFinal !== payment.finalPayoutAmount) ||
               status === Master.SHIPMENT_STATUS_DELIVERY_COMPLETED ||
               status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED)) ||
         (isShipmentTransporter(user, shipment) &&
            status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
            stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION &&
            payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount &&
            payment.totalPaymentAmount !== payment.initialPaymentAmount + payment.finalPaymentAmount) ||
         (isProvider(user) && shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING) ||
         (isCustomer(user) && shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING) ||
         (isAdmin(user) &&
            status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
            stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION &&
            payment.topDuration == 0 &&
            payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount) ||
         (isCustomer(user) && status === Master.SHIPMENT_STATUS_DELIVERY_COMPLETED) ||
         (isCustomer(user) && (status === Master.SHIPMENT_STATUS_COMPLAINT || status === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED)) ||
         ((isProvider(user) || isTransporter(user)) && (status === Master.SHIPMENT_STATUS_COMPLAINT || status === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED)) ||
         (isCustomer(user) && (status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED || status === Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED)) ||
         (isTransporter(user) && (status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED || status === Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED)) ||
         (isAdmin(user) && (status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED || status === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED)) ||
         (isAdmin(user) && complaint)
      ) {
         setShowAction(true);
      }
   }, [user, complaint, status, stepStatus, shipment, payment, nextStep]);

   useEffect(() => {
      setPlaceLabel(currentRoute?.desc || "");
      setPlace((currentRoute?.place && LocationHelper.getSubRegionCity(currentRoute?.place.address)) || "");
      setPicPlaceLabel((currentRoute?.picName && "Penanggung Jawab di Lokasi") || "");
      setPicPlaceName(currentRoute?.picName || "");
      setPicPlaceMobile(shipment.status === Master.SHIPMENT_STATUS_NEW_ORDER || shipment.status === Master.SHIPMENT_STATUS_PENDING_PAYMENT ? Master.MOBILE_LINTAS : currentRoute?.picMobile || Master.MOBILE_LINTAS);
   }, [currentRoute, shipment.status]);

   useEffect(() => {
      if (carrier) {
         setCarrierInfo(carrier.code + " / " + carrier.licenseNo);
      }
      if (transporter) {
         setTransporterName(transporter.name);
      }
   }, [carrier, transporter]);

   // update steps estimate to complete
   useEffect(() => {
      if (!shipmentGid || !routes || routes.length === 0) {
         return;
      }

      let currentDestination: any;
      // console.log("shipmentStep: ", shipment.stepStatus);
      if (shipment.stepStatus === Master.STEP_CODE_TRAVEL_TO_ORIGIN) {
         currentDestination = routes[0].place;
      } else if (shipment.stepStatus === Master.STEP_CODE_TRAVEL_TO_DESTINATION) {
         let routeStatus;
         for (let i = 1; i < routes.length && routeStatus !== Master.ROUTE_STATUS_IN_PROGRESS; i++) {
            routeStatus = routes[i].status;
            currentDestination = routes[i].place;
         }
      } else {
         return;
      }

      if (!currentDestination) {
         return;
      }

      // console.log("routes: ", JSON.stringify(routes, null, 2));
      console.log("currentDestination: ", JSON.stringify(currentDestination, null, 2));

      CarrierService.getPosition([shipment.carrier?.id || 0]).then((resp: any) => {
         // console.log("getPosition Resp: ", JSON.stringify(resp, null, 2));
         if (resp && resp.length > 0) {
            let latLng = resp[0].pos;
            let origin = { name: "", lat: latLng.lat, lng: latLng.lng };

            DirectionHelper.getDirectionApi(origin, [currentDestination], (response: any, status: any) => {
               // console.log("fetchDirection: ", JSON.stringify(response, null, 2));
               if (status === "OK") {
                  const duration = Math.round(response.duration * Master.GOOGLE_MAPS_DURATION_RATIO);
                  ShipmentService.updateShipmentStepEstimate(shipment.gid, duration).then((steps: Step[]) => {
                     setSteps(steps);
                  });
               }
            });
         }
      });
   }, [shipmentGid, routes, shipment.stepStatus, shipment.carrier, shipment.gid]);

   const onPressTrackPosition = () => {
      setShowTrackPosition(true);
   };

   const onPressFeeAndPayment = () => {
      setShowFeeAndPayment(!showFeeAndPayment);
   };

   const onPressUpdate = () => {
      //navigation.navigate('ShipmentUpdateScreen');
   };

   const updateShipmentStatus = (newStatus: string, reasonCode: string, reasonDesc: string, popUpMsg: string) => {
      AlertHelper.alertLoading(popUpMsg, modalContext);

      if (shipment.gid) {
         ShipmentService.updateShipmentStatus(shipment.gid, newStatus, reasonCode, reasonDesc)
            .then((resp) => {
               shipment.status = newStatus;
               setStatus(newStatus);
               setStatusReason(reasonDesc);
               setStatusDesc(ReferenceHelper.getShipmentStatusDesc(newStatus));
               setTimeout(() => {
                  AlertHelper.alertSuccess("Proses berhasil", modalContext);
               }, 1000);
            })
            .catch((error: Error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      }
   };

   const updateShipmentStatusForConfirmation = () => {
      if (confirmType === Master.CONFIRM_WITH_BOOKING_FEE) {
         updateShipmentStatus(Master.SHIPMENT_STATUS_PENDING_PAYMENT, "", "", "Memproses permintaan angkutan");
      } else if (confirmType === Master.CONFIRM_WITHOUT_BOOKING_FEE) {
         updateShipmentStatus(Master.SHIPMENT_STATUS_CONFIRM_NO_PAYMENT, "", "", "Memproses permintaan angkutan");
      }
   };

   const acceptShipment = () => {
      // get payment plan
      if (confirmStepIndex === 1) {
         let minPaymentBookingAmount = 0;

         const totalCharge = shipment.payment.totalCharge;
         if (providerAmount > chargeAmount) {
            AlertHelper.alertWarningWithRef("Biaya Mitra tidak boleh melebihi Biaya Angkutan " + FormatHelper.formatCurrency(chargeAmount), modalContext, "warning");
            return;
         } else if (chargeAmount > totalCharge) {
            AlertHelper.alertWarningWithRef("Biaya Angkutan tidak boleh melebihi " + FormatHelper.formatCurrency(shipment.payment.totalCharge), modalContext, "warning");
            return;
         } else if (topStatus === Master.TOP_YES && topDuration == 0) {
            AlertHelper.alertWarningWithRef("Metode Pembayaran dengan ToP telah dipilih. Mohon isi durasi ToP", modalContext, "warning");
            return;
         }

         if (fees) {
            fees?.forEach((fee) => {
               if (fee.code === Master.FEE_CODE_COLLECTION || fee.code === Master.FEE_CODE_ADMIN) {
                  minPaymentBookingAmount += fee.subTotal;
               } else if (fee.code === Master.FEE_CODE_TRANSPORT) {
                  if (fee.subTotal < Master.CANCEL_FEE_AMOUNT) {
                     minPaymentBookingAmount += fee.subTotal;
                  } else {
                     minPaymentBookingAmount += Master.CANCEL_FEE_AMOUNT + fee.subTotal * Master.COMS_RATIO;
                  }
               }
            });
         }

         // experiment with booking fee Rp 50.000
         if (chargeAmount > 5000000) {
            minPaymentBookingAmount = 500000;
         } else if (chargeAmount > 1000000) {
            minPaymentBookingAmount = 100000;
         } else if (minPaymentBookingAmount > 50000) {
            minPaymentBookingAmount = 50000;
         }

         let minPaymentInitialAmount = Math.round((chargeAmount * 0.7) / 10000) * 10000 - minPaymentBookingAmount;

         if (topStatus == Master.TOP_YES) {
            minPaymentBookingAmount = 0;
            minPaymentInitialAmount = 0;
         }

         const paymentFinalAmount = chargeAmount - minPaymentBookingAmount - minPaymentInitialAmount;

         setPlanPaymentBookingAmount(minPaymentBookingAmount);
         setPlanPaymentInitialAmount(minPaymentInitialAmount);
         setPlanPaymentFinalAmount(paymentFinalAmount);

         setConfirmStepIndex(2);

         // get payout plan
      } else if (confirmStepIndex === 2) {
         const minPaymentInitialAmount = Math.round((providerAmount * 0.7) / 10000) * 10000;
         const paymentFinalAmount = providerAmount - minPaymentInitialAmount;

         setPlanPayoutBookingAmount(0);
         setPlanPayoutInitialAmount(minPaymentInitialAmount);
         setPlanPayoutFinalAmount(paymentFinalAmount);

         setConfirmStepIndex(3);

         // get provider payout plan
      } else if (confirmStepIndex === 3) {
         if (shipment.gid) {
            const totalCharge = shipment.payment.totalCharge;

            console.log("chargeAmount: ", chargeAmount);
            console.log("totalCharge: ", totalCharge);
            console.log("providerAmount: ", providerAmount);

            // console.log("chargeAmount: ", chargeAmount, " totalCharge: ", shipment.payment.totalCharge, " providerAmount: ", providerAmount);
            if (chargeAmount < totalCharge || providerAmount <= chargeAmount || topDuration > 0) {
               AlertHelper.alertLoading("Merubah skema pembayaran ...", modalContext);

               ShipmentService.updateShipmentCharge(
                  shipment.gid,
                  chargeAmount,
                  providerAmount,
                  planPaymentBookingAmount,
                  planPaymentInitialAmount,
                  planPaymentFinalAmount,
                  planPayoutBookingAmount,
                  planPayoutInitialAmount,
                  planPayoutFinalAmount,
                  topDuration
               )
                  .then((resp) => {
                     shipment.payment = resp;
                     setPayment(shipment.payment);
                     setTimeout(() => {
                        updateShipmentStatusForConfirmation();
                     }, 2500);
                  })
                  .catch((error: Error) => {
                     ErrorService.handleError(error, history, modalContext);
                  });
            } else if (chargeAmount === totalCharge && providerAmount <= chargeAmount) {
               updateShipmentStatusForConfirmation();
            } else if (providerAmount > chargeAmount) {
               AlertHelper.alertWarningWithRef("Biaya Mitra tidak boleh melebihi Biaya Angkutan " + FormatHelper.formatCurrency(chargeAmount), modalContext, "warning");
            } else if (chargeAmount > totalCharge) {
               AlertHelper.alertWarningWithRef("Biaya Angkutan tidak boleh melebihi " + FormatHelper.formatCurrency(shipment.payment.totalCharge), modalContext, "warning");
            }
         }
      }
   };

   const onPressConfirmAccept = () => {
      //ConfirmHelper.confirm("Terima permintaan angkutan?", () => acceptShipment, modalContext);
      if (service?.verificationStatus === Master.STATUS_PENDING_DOCUMENT) {
         AlertHelper.alertError(
            "Persyaratan administratif anda seperti dokumen KTP, SIM, KIR, STNK belum lengkap. Mohon lengkapi persyaratan administratif terlebih dahulu.",
            "Persayaratan administratif belum lengkap seperti KTP, SIM, KIR, STNK.",
            modalContext,
            "acceptShipment"
         );
      } else {
         setShowConfirmOptions(true);
         setConfirmStepIndex(1);
      }
   };

   const onPressPay = () => {
      if (isCustomer(user)) {
         if (showPaymentOptions) {
            let minPaymentAmount = 0;
            if (payment.status === Master.PAYMENT_STATUS_PENDING) {
               minPaymentAmount = payment.planPaymentBooking;
            } else if (payment.status === Master.PAYMENT_STATUS_CONFIRM) {
               minPaymentAmount = payment.planPaymentInitial;
            } else if (payment.status === Master.PAYMENT_STATUS_PARTIAL) {
               minPaymentAmount = payment.planPaymentFinal;
            }

            if (paymentAmount < minPaymentAmount) {
               AlertHelper.alertWarning("Minimum pembayaran adalah sebesar " + FormatHelper.formatCurrency(minPaymentAmount), modalContext);
               setPaymentAmount(minPaymentAmount);
               return;
            } else if (shipment.payment.totalPaymentAmount < shipment.payment.bookingPaymentAmount + paymentAmount) {
               let maxPaymentAmount = shipment.payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount;
               AlertHelper.alertWarning("Maksimum pembayaran adalah sebesar " + FormatHelper.formatCurrency(maxPaymentAmount), modalContext);
               setPaymentAmount(maxPaymentAmount);
               return;
            }
            AlertHelper.alertLoading("Loading ...", modalContext);
            ShipmentService.getPaymentUrl(shipment.gid, paymentAmount).then((paymentUrl) => {
               window.location.href = paymentUrl;
            });
         } else {
            setShowPaymentOptions(true);
            setShowFullPaymentAction(true);
         }

         // user is admin to confirm the payment
      } else if (user.isAdmin) {
         if (showPaymentOptions) {
            acceptPayment();
         } else {
            setShowPaymentOptions(true);
            setShowFullPaymentAction(true);
         }
      }
   };

   const onPressPayout = () => {
      if (showPaymentOptions) {
         makePayout();
      } else {
         setShowPaymentOptions(true);
         setShowFullPaymentAction(true);
      }
   };

   const onCheckPaymentStatus = () => {
      AlertHelper.alertLoading("Memperbarui status pembayaran ...", modalContext, "paymentStatus");
      ShipmentService.getShipmentPayment(shipmentGid)
         .then((resp) => {
            let shp: Shipment = CommonHelper.copyObject(resp.shipment);
            shipment.status = shp.status;
            shipment.stepStatus = shp.stepStatus;
            shipment.payment = shp.payment;
            setShipment(shipment);
            setStepStatus(shipment.stepStatus);
            setTimeout(() => {
               AlertHelper.hideLoading(modalContext, "paymentStatus");
            }, 1000);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onPressFinalPay = () => {
      AlertHelper.alertLoading("Loading ...", modalContext);
      ShipmentService.getPaymentUrl(shipment.gid, 0).then((paymentUrl) => {
         window.location.href = paymentUrl;
      });
   };

   const acceptPayment = () => {
      if (paymentMethod === Master.PAYMENT_METHOD_TRANSFER && !paymentImage) {
         AlertHelper.alertWarning("Mohon sertakan bukti transfer", modalContext);
         return;
      }
      if (shipment.payment.totalPaymentAmount < shipment.payment.bookingPaymentAmount + paymentAmount) {
         let maxPaymentAmount = shipment.payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount;
         AlertHelper.alertWarning("Maksimum pembayaran adalah sebesar " + FormatHelper.formatCurrency(maxPaymentAmount), modalContext);
         setPaymentAmount(maxPaymentAmount);
         return;
      }
      AlertHelper.alertLoading("Memproses pembayaran ...", modalContext);
      ShipmentService.acceptPayment(shipment.gid ? shipment.gid : "", paymentAmount, paymentMethod, paymentImage)
         .then((payment) => {
            //setShowPartialPaymentAction(false);
            //setShowFullPaymentAction(false);
            console.log("payment: ", JSON.stringify(payment, null, 2));

            if (shipment.payment.status === Master.PAYMENT_STATUS_PENDING && payment.status !== Master.PAYMENT_STATUS_PENDING) {
               let shp: Shipment = CommonHelper.copyObject(shipment);
               shp.status = Master.SHIPMENT_STATUS_CONFIRM_PAYMENT;
               setShipment(shp);
            }

            shipment.payment = payment;
            setPayment(payment);
            setPaymentImage(undefined);

            AlertHelper.alertSuccess("Proses berhasil", modalContext);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const makePayout = () => {
      // console.log("payment: ", JSON.stringify(payment, null, 2));
      // console.log("payoutAmount: ", JSON.stringify(payoutAmount, null, 2));
      if (paymentMethod === Master.PAYMENT_METHOD_TRANSFER && !paymentImage) {
         AlertHelper.alertWarning("Mohon sertakan bukti transfer", modalContext);
         return;
      }

      if (payment.planPayoutBooking !== 0 && payment.bookingPayoutAmount === 0 && payment.planPayoutBooking !== payment.bookingPayoutAmount) {
         if (payment.planPayoutBooking !== payoutAmount) {
            AlertHelper.alertWarning("Nilai pembayaran booking mitra tidak sesuai " + FormatHelper.formatCurrency(payment.planPayoutBooking), modalContext);
            return;
         }
      } else if (payment.planPayoutInitial !== 0 && payment.initialPayoutAmount === 0 && payment.planPayoutInitial !== payment.initialPayoutAmount) {
         if (payment.planPayoutInitial !== payoutAmount) {
            AlertHelper.alertWarning("Nilai pembayaran awal mitra tidak sesuai " + FormatHelper.formatCurrency(payment.planPayoutInitial), modalContext);
            return;
         }
      } else if (payment.planPayoutFinal !== 0 && payment.finalPayoutAmount === 0 && payment.planPayoutFinal !== payment.finalPayoutAmount) {
         if (payment.planPayoutFinal !== payoutAmount) {
            AlertHelper.alertWarning("Nilai pembayaran pelunasan mitra tidak sesuai " + FormatHelper.formatCurrency(payment.planPayoutFinal), modalContext);
            return;
         }
      }
      AlertHelper.alertLoading("Memproses pembayaran mitra ...", modalContext);
      ShipmentService.makePayout(shipment.gid ? shipment.gid : "", payoutAmount, paymentMethod, paymentImage)
         .then((payment) => {
            shipment.payment = payment;
            shipment.status = Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED;
            setPayment(payment);
            setPaymentMethod(Master.PAYMENT_METHOD_CASH);
            setTimeout(() => { setPaymentMethod(Master.PAYMENT_METHOD_TRANSFER) }, 100);
            setPaymentImage(undefined);

            AlertHelper.alertSuccess("Proses berhasil", modalContext);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onAcceptFinalPayment = () => {
      setPaymentAmount(payment.totalPaymentAmount - payment.bookingPaymentAmount - payment.initialPaymentAmount);
      ConfirmHelper.confirm("Telah menerima pembayaran tunai sebesar " + FormatHelper.formatCurrency(paymentAmount), () => acceptPayment, modalContext);
   };

   const onPressReschedule = () => {
      setShowReschedule(true);
   };

   const confirmDelivery = () => {
      updateShipmentStatus(Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED, "", "", "Memproses konfirmasi muatan telah diterima");
   };

   const onPressConfirmDelivery = () => {
      ConfirmHelper.confirm("Konfirmasi pengiriman selesai", () => confirmDelivery, modalContext);
   };

   const onPressComplaint = () => {
      setShowProcessComplaint(true);
   };

   const updateShipmentInDeliveryPosition = () => {
      PositionHelper.updateShipmentInDeliveryPosition();
      CacheService.setGpsPermissionRequested(true);
   };

   const processShipment = () => {
      AlertHelper.alertLoading("Memproses Angkutan Anda", modalContext);

      ShipmentService.processShipment(shipment, "", "", undefined, undefined, undefined, undefined)
         .then((resp) => {
            if (CacheService.isGpsPermissionRequested()) {
               PositionHelper.updateShipmentInDeliveryPosition();
            } else {
               ConfirmHelper.confirm(
                  "Lintas mengambil data lokasi anda untuk proses tracking pengiriman angkutan walaupun aplikasi tidak ditutup atau tidak digunakan. \nLintas collects location data to enable shipment delivery tracking even when the app is closed or not in use. ",
                  () => updateShipmentInDeliveryPosition,
                  modalContext
               );
            }

            // console.log("shipmentStatusProcess", resp.shipmentStatus);
            // console.log("shipmentStepProcess", resp.shipmentStepStatus);

            shipment.status = resp.shipmentStatus;
            shipment.stepStatus = resp.shipmentStepStatus;
            setSteps(resp.steps);
            setRoutes(resp.routes);
            setStatus(resp.shipmentStatus);
            setStepStatus(resp.shipmentStepStatus);
            setStatusDesc(ReferenceHelper.getShipmentStatusDesc(resp.shipmentStatus));
            setTimeout(() => AlertHelper.alertSuccess("Proses berhasil", modalContext), 1000);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const onPressProcess = () => {
      if (nextStep?.code === Master.STEP_CODE_COMPLETE_LOADING || nextStep?.code === Master.STEP_CODE_COMPLETE_UNLOADING || nextStep?.code === Master.STEP_CODE_START_TRANSIT) {
         setShowProcessShipment(true);
      } else {
         if (!carrier) {
            AlertHelper.alertWarning("Silahkan pilih kendaraan angkutan terlebih dahulu", modalContext);
         } else if (!transporter) {
            AlertHelper.alertWarning("Silahkan pilih pengemudi terlebih dahulu", modalContext);
         } else {
            ConfirmHelper.confirm(ReferenceHelper.getStepAction(nextStep?.code) + "?", () => processShipment, modalContext);
         }
      }
   };

   const processReschedule = (accept: boolean) => {
      AlertHelper.alertLoading("Memproses perubahan jadwal", modalContext);

      ShipmentService.processRescheduleShipment(shipment.gid || "", accept ? Master.YES : Master.NO)
         .then((resp) => {
            AlertHelper.alertSuccess((accept ? "Menerima" : "Menolak") + " Menerima perubahan jadwal dari " + (user.customer ? "penyedia jasa angkutan" : "pelanggan"), modalContext);
            setShipment(resp.shipment);
            setSteps(resp.steps);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const acceptReschedule = () => {
      processReschedule(true);
   };

   const rejectReschedule = () => {
      processReschedule(false);
   };

   const onPressProcessReschedule = (accept: boolean) => {
      ConfirmHelper.confirm((accept ? "Terima" : "Tolak") + " perubahan jadwal", accept ? () => acceptReschedule : () => rejectReschedule, modalContext);
   };

   const paymentTransfered = () => {
      updateShipmentStatus(Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED, "", "", "Memproses update angkutan");
   };

   const onPressPaymentTransfered = () => {
      ConfirmHelper.confirm("Pembayaran telah ditransfer?", () => paymentTransfered, modalContext);
   };

   const onSelectCarrier = (carrier: Carrier) => {
      setCarrier(carrier);
   };

   const onSelectTransporter = (transporter: Transporter) => {
      setTransporter(transporter);
   };

   const onSelectDeclineReason = (option: Option) => {
      //console.log('Decline reason: ', JSON.stringify(option, null, 2));
      updateShipmentStatus(Master.SHIPMENT_STATUS_DECLINE, option.code, option.desc, "Proses konfirmasi tidak dapat menerima permintaan angkutan");
      setShowSelectDeclineReason(false);
   };

   const onShowStepImages = (step: Step) => {
      AlertHelper.alertLoading("Loading ...", modalContext);
      setStep(step);

      ShipmentService.getShipmentStepImages(shipment.gid, step.id)
         .then((resp) => {
            AlertHelper.hideLoading(modalContext);
            console.log("step: ", JSON.stringify(step, null, 2));
            const signImg: Image = new Image().init(
               -1,
               "Tanda Tangan " + (step.code === Master.STEP_CODE_COMPLETE_LOADING ? "Pengirim" : "Penerima"), //'image.png',
               "done", // UploadFileStatus
               0, //1024,
               "image/jpeg",
               step.signImgUrl
            );
            const images: Image[] = [...resp, signImg];
            console.log("images: ", JSON.stringify(images, null, 2));

            setStepImages(images);
            setShowStepImages(true);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   const refreshShipmentStatus = (status: string) => {
      console.log("refreshShipmentStatus: ", status);
      shipment.status = status;
      setStatus(status);
      setStatusDesc(ReferenceHelper.getShipmentStatusDesc(status));
   };

   const getProviderTotalFees = (fees: Fee[]) => {
      let totalFees = 0;
      fees?.forEach((fee) => {
         if (fee.feeType === Master.FEE_TYPE_PROVIDER) {
            totalFees += PricingHelper.roundThousand(fee.unitPriceBase * fee.count);
         }
      });
      return totalFees;
   };

   const getPlatformTotalFees = (fees: Fee[]) => {
      let totalFees = 0;
      fees?.forEach((fee) => {
         totalFees += PricingHelper.roundThousand(fee.unitPriceComs * fee.count);
      });
      return totalFees;
   };

   const showProviderFees = (fee: Fee) => {
      let showFee = FormatHelper.formatCurrency(PricingHelper.roundThousand(fee.unitPriceBase * fee.count));
      return showFee;
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="shipment-detail">
                  <div className="shipment-detail-header">
                     <ShipmentSteps status={shipment.status} />
                  </div>
                  <div
                     className="shipment-detail-container"
                     style={
                        window.outerWidth < 1000 && showPartialPaymentAction
                           ? { height: window.outerHeight - 305 }
                           : window.outerWidth < 1000 && showFullPaymentAction
                              ? { height: window.outerHeight - 280 }
                              : window.outerWidth < 1000 && showFinalPaymentAction
                                 ? { height: window.outerHeight - 220 }
                                 : window.outerWidth < 1000 && showConfirmOptions
                                    ? { height: window.outerHeight - 454 }
                                    : window.outerWidth < 1000 && showAction
                                       ? { height: window.outerHeight - 185 }
                                       : { height: window.outerHeight - 120 }
                     }
                  >
                     <FadeView show={true} duration="0" style={window.innerWidth < 1000 ? { alignSelf: "stretch", width: "100%" } : {}}>
                        <SelectTransporter show={showSelectTransporter} setShow={setShowSelectTransporter} shipment={shipment} onSelectTransporter={onSelectTransporter} onSelectCarrier={onSelectCarrier} />
                        <SelectCarrier show={showSelectCarrier} setShow={setShowSelectCarrier} shipment={shipment} onSelectCarrier={onSelectCarrier} />
                        <SelectDeclineReason title="Berikan alasan tidak dapat menerima angkutan" show={showSelectDeclineReason} setShow={setShowSelectDeclineReason} options={Master.declineReasons} onSelectOption={onSelectDeclineReason} />
                        <Reschedule show={showReschedule} setShow={setShowReschedule} shipment={shipment} setShipment={setShipment} />
                        <Rating show={showRating} setShow={setShowRating} shipment={shipment} setShipment={setShipment} user={user} />
                        <ProcessShipment show={showProcessShipment} setShow={setShowProcessShipment} nextStep={nextStep} setSteps={setSteps} setRoutes={setRoutes} shipment={shipment} refreshShipmentStatus={refreshShipmentStatus} />
                        <ProcessComplaint show={showProcessComplaint} setShow={setShowProcessComplaint} shipment={shipment} setShipment={setShipment} complaint={complaint} setComplaint={setComplaint} />
                        <TrackPosition show={showTrackPosition} setShow={setShowTrackPosition} shipment={shipment} steps={steps} destination={nextDestination} routes={routes} />
                        <StepImages show={showStepImages} setShow={setShowStepImages} step={step} images={stepImages} />
                        <div className="shipment-detail-info">
                           <div className="shipment-detail-item">
                              <div className="shipment-detail-top">
                                 <div className="shipment-detail-shipment-no regular-font"></div>
                              </div>
                              {!isAdmin(user) && (
                                 <div className="shipment-detail-content">
                                    <ImageView className="shipment-detail-pic-image" uploadUrl={contactImgUrl} alt="pic" />
                                    <div className="shipment-detail-pic-name regular-bold-font">{contactName || <Skeleton delay={1} width={100} />}</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                       <CustomButton loading loadingRef={bookingCode} icon={"call"} dynamicText text="Panggil" onClick={onClickCallContact} width={95} style={{ marginLeft: 5 }} />
                                       <CustomButton loading loadingRef={bookingCode} icon={"message"} dynamicText text="Pesan" onClick={onClickChatContact} width={95} style={{ marginLeft: 5 }} />
                                    </div>
                                 </div>
                              )}
                              {isAdmin(user) && (
                                 <div className="shipment-detail-content shipment-detail-content-admin regular-bottom-border">
                                    <ImageView className="shipment-detail-pic-image" uploadUrl={contactImgUrlCustomer} alt="pic" />
                                    <div className="shipment-detail-pic-name regular-bold-font">{contactNameCustomer || <Skeleton delay={1} width={100} />}</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                       <CustomButton loading loadingRef={bookingCode} icon={"call"} dynamicText text="Panggil" onClick={onClickCallContactCustomer} width={95} style={{ marginLeft: 5 }} />
                                       <CustomButton loading loadingRef={bookingCode} icon={"message"} dynamicText text="Pesan" onClick={onClickChatContactCustomer} width={95} style={{ marginLeft: 5 }} />
                                    </div>
                                 </div>
                              )}
                              {isAdmin(user) && (
                                 <div className="shipment-detail-content shipment-detail-content-admin regular-bottom-border">
                                    <ImageView className="shipment-detail-pic-image" uploadUrl={contactImgUrlProvider} alt="pic" />
                                    <div className="shipment-detail-pic-name regular-bold-font">{contactNameProvider || <Skeleton delay={1} width={100} />}</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                       <CustomButton loading loadingRef={bookingCode} icon={"call"} dynamicText text="Panggil" onClick={onClickCallContactProvider} width={95} style={{ marginLeft: 5 }} />
                                       <CustomButton loading loadingRef={bookingCode} icon={"message"} dynamicText text="Pesan" onClick={onClickChatContactProvider} width={95} style={{ marginLeft: 5 }} />
                                    </div>
                                 </div>
                              )}
                              <div className="shipment-detail-bottom">
                                 <div className="shipment-detail-bottom-content">
                                    <div className="shipment-detail-bottom-content-left">
                                       <div className="shipment-detail-title regular-bold-font">{bookingCodeLabel || <Skeleton delay={1} width={75} />}</div>
                                       <div className="shipment-detail-desc regular-font">{bookingCode || <Skeleton delay={1} width={150} />}</div>
                                    </div>
                                    <div className="shipment-detail-bottom-content-left">
                                       <div className="shipment-detail-title regular-bold-font">{transportTypeLabel || <Skeleton delay={1} width={75} />}</div>
                                       <div className="shipment-detail-desc regular-font">{transportType || <Skeleton delay={1} width={150} />}</div>
                                    </div>
                                 </div>
                                 <div className="shipment-detail-bottom-content">
                                    <div className="shipment-detail-bottom-content-left">
                                       <div className="shipment-detail-title regular-bold-font">{statusLabel || <Skeleton delay={1} width={75} />}</div>
                                       <div className="shipment-detail-desc regular-font">{statusDesc || <Skeleton delay={1} width={75} />}</div>
                                       {statusReason && <div className="shipment-detail-desc regular-font">{statusReason}</div>}
                                    </div>
                                    <div className="shipment-detail-bottom-content-right-even">
                                       <div className="shipment-detail-bottom-content-right-sub">
                                          <div className="shipment-detail-title regular-bold-font">{deliveryDateLabel || <Skeleton delay={1} width={75} />}</div>
                                          <div className="shipment-detail-desc regular-font">{deliveryDate || <Skeleton delay={1} width={75} />}</div>
                                       </div>
                                    </div>
                                 </div>
                                 {rescheduleInfo && <div className="shipment-detail-reschedule regular-font">{rescheduleInfo}</div>}
                                 <div className="regular-top-border" style={{ paddingTop: 0, marginTop: 10 }}></div>
                                 {isCustomer(user) && (
                                    <div className="shipment-detail-bottom-content">
                                       <div className="shipment-detail-bottom-content-left">
                                          <div className="shipment-detail-title regular-bold-font">{providerNameLabel || <Skeleton delay={1} width={75} />}</div>
                                          <div className="shipment-detail-desc regular-font">{providerName || <Skeleton delay={1} width={100} />}</div>
                                       </div>
                                       <div>
                                          <CustomButton loading loadingRef={bookingCode} icon={"more"} style={{ marginLeft: 5 }} />
                                       </div>
                                    </div>
                                 )}
                                 <div style={{ display: "flex" }}>
                                    {(!isCustomer(user) || shipment.transporter) && (
                                       <div className="shipment-detail-bottom-content" style={{ flex: 1 }}>
                                          <div className="shipment-detail-bottom-content-left">
                                             <div className="shipment-detail-title regular-bold-font">{transporterNameLabel || <Skeleton delay={1} width={75} />}</div>
                                             <div className="shipment-detail-desc regular-font">{transporterName || <Skeleton delay={1} width={100} />}</div>
                                          </div>
                                          <div className="shipment-detail-bottom-content-right" style={{ paddingRight: 10 }}>
                                             {isProvider(user) && (
                                                <CustomButton
                                                   loading
                                                   loadingRef={bookingCode}
                                                   icon={"person"}
                                                   onClick={() => {
                                                      setShowSelectTransporter(true);
                                                   }}
                                                />
                                             )}
                                          </div>
                                       </div>
                                    )}
                                    {(!isCustomer(user) || shipment.transporter) && (
                                       <div className="shipment-detail-bottom-content" style={{ flex: 1 }}>
                                          <div className="shipment-detail-bottom-content-left">
                                             <div className="shipment-detail-title regular-bold-font">{carrierInfoLabel || <Skeleton delay={1} width={75} />}</div>
                                             <div className="shipment-detail-desc regular-font">{carrierInfo || <Skeleton delay={1} width={100} />}</div>
                                          </div>
                                          <div className="shipment-detail-bottom-content-right">
                                             {isProvider(user) && (
                                                <CustomButton
                                                   loading
                                                   loadingRef={bookingCode}
                                                   icon={"truck"}
                                                   onClick={() => {
                                                      setShowSelectCarrier(true);
                                                   }}
                                                />
                                             )}
                                          </div>
                                       </div>
                                    )}
                                 </div>
                                 <div className="shipment-detail-bottom-content regular-bottom-border" style={{ paddingBottom: 10, marginBottom: 10 }}>
                                    <div className="shipment-detail-bottom-content-left">
                                       <div className="shipment-detail-title regular-bold-font">{descLabel || <Skeleton delay={1} width={75} />}</div>
                                       <div className="shipment-detail-desc regular-font">{desc || <Skeleton delay={1} width={150} />}</div>
                                    </div>
                                    <div className="shipment-detail-bottom-content-right">
                                       <CustomButton loading loadingRef={bookingCode} icon={"edit"} onClick={onPressUpdate} />
                                    </div>
                                 </div>
                                 <div className="shipment-detail-bottom-content">
                                    <div className="shipment-detail-bottom-content-left">
                                       <div className="shipment-detail-title regular-bold-font">{placeLabel || <Skeleton delay={1} width={75} />}</div>
                                       <div className="shipment-detail-desc regular-font">
                                          {place || <Skeleton delay={1} width={150} />}
                                          <br />
                                          {(currentRoute?.place && LocationHelper.getPlaceShortAddress(currentRoute?.place)) || <Skeleton delay={1} width={150} />}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="shipment-detail-bottom-content">
                                    <div className="shipment-detail-bottom-content-left">
                                       <div className="shipment-detail-title regular-bold-font">{picPlaceLabel || <Skeleton delay={1} width={75} />}</div>
                                       <div className="shipment-detail-desc regular-font">{picPlaceName || <Skeleton delay={1} width={150} />}</div>
                                    </div>
                                    {shipment.status !== Master.SHIPMENT_STATUS_NEW_ORDER && shipment.status !== Master.SHIPMENT_STATUS_PENDING_PAYMENT && (
                                       <div className="shipment-detail-bottom-content-right-center">
                                          <CustomButton loading loadingRef={bookingCode} icon={"call"} text="Panggil" onClick={onClickCallPlacePic} dynamicText width={95} style={{ marginLeft: 5 }} />
                                          <CustomButton loading loadingRef={bookingCode} icon={"message"} text="Pesan" onClick={onClickChatPlacePic} dynamicText width={95} style={{ marginLeft: 5 }} />
                                       </div>
                                    )}
                                 </div>
                                 <div className="shipment-detail-bottom-content regular-bottom-border" style={{ paddingBottom: 10, marginBottom: 10, justifyContent: "space-between" }}>
                                    <RouteView shipment={shipment} stepStatus={stepStatus} routes={routes} setCurrentRoute={setCurrentRoute} setNextDestination={setNextDestination} />
                                    <CustomButton loading loadingRef={bookingCode} icon={"map"} onClick={onPressTrackPosition} />
                                 </div>
                                 {!isTransporter(user) && (
                                    <div className="shipment-detail-bottom-content">
                                       <div className="shipment-detail-bottom-content-left">
                                          <div className="shipment-detail-bottom-content">
                                             <div className="shipment-detail-bottom-content-left regular-bold-font" style={{ lineHeight: "34px", verticalAlign: "middle" }}>
                                                {feeLabel || <Skeleton delay={1} width={75} />}
                                             </div>
                                             <CustomButton loading loadingRef={bookingCode} icon={showFeeAndPayment ? "close" : "more"} onClick={onPressFeeAndPayment} />
                                          </div>
                                          {showFeeAndPayment && (
                                             <div className="shipment-detail-desc regular-font" style={{ paddingRight: 0 }}>
                                                <div className="shipment-fee-content">
                                                   {!fees && <Skeleton delay={1} width={75} count={2} />}
                                                   {
                                                      // payment view from customer & admin
                                                      fees &&
                                                      (isCustomer(user) || isAdmin(user)) &&
                                                      fees.map((fee: Fee, key: any) => (
                                                         <div key={key} className="shipment-fee-item">
                                                            <div className="shipment-fee-item-desc regular-font">{fee.description}</div>
                                                            <div className="shipment-fee-item-subtotal regular-font">{FormatHelper.formatCurrency(fee.subTotal)}</div>
                                                         </div>
                                                      ))
                                                   }
                                                   {payment && payment.discountAmount > 0 && (isCustomer(user) || isAdmin(user)) && (
                                                      <div className="shipment-fee-item">
                                                         <div className="shipment-fee-item-desc regular-font">{discountLabel}</div>
                                                         <div className="shipment-fee-item-subtotal regular-font">- {FormatHelper.formatCurrency(payment.discountAmount)}</div>
                                                      </div>
                                                   )}
                                                   {
                                                      // payment view from provider
                                                      fees &&
                                                      isProvider(user) &&
                                                      fees.map(
                                                         (fee: Fee, key: any) =>
                                                            fee.feeType === Master.FEE_TYPE_PROVIDER && (
                                                               <div key={key} className="shipment-fee-item">
                                                                  <div className="shipment-fee-item-desc regular-font">{fee.description}</div>
                                                                  <div className="shipment-fee-item-subtotal regular-font">{showProviderFees(fee)}</div>
                                                               </div>
                                                            )
                                                      )
                                                   }
                                                   {isProvider(user) && discountProvider > 0 && (
                                                      <div className="shipment-fee-item">
                                                         <div className="shipment-fee-item-desc regular-font">{discountProviderLabel}</div>
                                                         <div className="shipment-fee-item-subtotal regular-font">{discountProviderAmount || <Skeleton delay={1} width={75} />}</div>
                                                      </div>
                                                   )}
                                                   <div className="shipment-fee-total regular-top-border" style={{ marginTop: 10 }}>
                                                      <div className="shipment-fee-item-desc regular-bold-font">{totalLabel || <Skeleton delay={1} width={75} />}</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">{total || <Skeleton delay={1} width={75} />}</div>
                                                   </div>
                                                </div>
                                             </div>
                                          )}
                                       </div>
                                       <div className="shipment-detail-bottom-content-right"></div>
                                    </div>
                                 )}
                                 {(isCustomer(user) || user.isAdmin) && showFeeAndPayment && (
                                    <div className="shipment-detail-bottom-content">
                                       <div className="shipment-detail-bottom-content-left">
                                          <div className="shipment-detail-title regular-bold-font">{paymentLabel || <Skeleton delay={1} width={75} />}</div>
                                          <div className="shipment-detail-desc regular-font" style={{ paddingRight: 0 }}>
                                             <div className="shipment-fee-conten9t">
                                                {isProvider(user) && payment && platformFeeAmount > 0 && (
                                                   <div className="shipment-fee-item">
                                                      <div className="shipment-fee-item-desc regular-font">{platformFeeLabel}</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">{platformFee}</div>
                                                   </div>
                                                )}
                                                {isProvider(user) && payment && payment.discountAmount > 0 && (
                                                   <div className="shipment-fee-item">
                                                      <div className="shipment-fee-item-desc regular-font">{discountLabel}</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">- {FormatHelper.formatCurrency(payment.discountAmount)}</div>
                                                   </div>
                                                )}
                                                {payment && (
                                                   <div className="shipment-fee-item">
                                                      <div className="shipment-fee-item-desc regular-font">{totalChargeLabel}</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">{FormatHelper.formatCurrency(payment.totalPaymentAmount)}</div>
                                                   </div>
                                                )}
                                                {payment && payment.bookingPaymentAmount > 0 && (
                                                   <div className="shipment-fee-item">
                                                      <div className="shipment-fee-item-desc regular-font">{payment.totalPaymentAmount === payment.bookingPaymentAmount ? "Pembayaran Lunas" : "Pembayaran Booking"}</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">- {FormatHelper.formatCurrency(payment.bookingPaymentAmount)}</div>
                                                   </div>
                                                )}
                                                {payment && payment.initialPaymentAmount > 0 && (
                                                   <div className="shipment-fee-item">
                                                      <div className="shipment-fee-item-desc regular-font">{payment.totalPaymentAmount === payment.bookingPaymentAmount + payment.initialPaymentAmount ? "Pembayaran Lunas" : "Pembayaran Awal"}</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">- {FormatHelper.formatCurrency(payment.initialPaymentAmount)}</div>
                                                   </div>
                                                )}
                                                {payment && payment.finalPaymentAmount > 0 && (
                                                   <div className="shipment-fee-item">
                                                      <div className="shipment-fee-item-desc regular-font">Pembayaran Lunas</div>
                                                      <div className="shipment-fee-item-subtotal regular-font">- {FormatHelper.formatCurrency(payment.finalPaymentAmount)}</div>
                                                   </div>
                                                )}
                                                <div className="shipment-fee-total regular-top-border" style={{ marginTop: 10 }}>
                                                   <div className="shipment-fee-item-desc regular-bold-font">{paymentTotalLabel || <Skeleton delay={1} width={75} />}</div>
                                                   <div className="shipment-fee-item-subtotal regular-font">{paymentTotal || <Skeleton delay={1} width={75} />}</div>
                                                </div>
                                                {user.isAdmin && (
                                                   <div>
                                                      <div className="shipment-fee-total regular-top-border" style={{ marginTop: 15 }}>
                                                         <div className="shipment-fee-item-desc regular-bold-font">{totalProviderLabel || <Skeleton delay={1} width={75} />}</div>
                                                         <div className="shipment-fee-item-subtotal regular-font">{totalProvider || <Skeleton delay={1} width={75} />}</div>
                                                      </div>
                                                      <div className="shipment-fee-total regular-bottom-border" style={{ marginBottom: 15, paddingTop: 0, paddingBottom: 10 }}>
                                                         <div className="shipment-fee-item-desc regular-bold-font">{totalPlatformLabel || <Skeleton delay={1} width={75} />}</div>
                                                         <div className="shipment-fee-item-subtotal regular-font">{totalPlatform || <Skeleton delay={1} width={75} />}</div>
                                                      </div>
                                                   </div>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                       <div className="shipment-detail-bottom-content-right"></div>
                                    </div>
                                 )}
                                 {paymentImages && showFeeAndPayment && (
                                    <div className="shipment-detail-bottom-content">
                                       <div className="shipment-detail-bottom-content-left">
                                          <div className="shipment-detail-title regular-bold-font">{paymentReceiptLabel || <Skeleton delay={1} width={75} />}</div>
                                          <ImagesPanel
                                             images={paymentImages}
                                             width={window.innerWidth > 500 ? 500 : window.innerWidth - 30}
                                             size={window.innerWidth < 1000 ? 2 : 4}
                                             onPress={(index: number) => {
                                                setPaymentImageIndex(index);
                                                //setImages(images);
                                                setShowPaymentImagesGallery(true);
                                             }}
                                          />
                                       </div>
                                    </div>
                                 )}
                                 {images && (
                                    <div className="shipment-detail-bottom-content">
                                       <div className="shipment-detail-bottom-content-left">
                                          <div className="shipment-detail-title regular-bold-font">{photoLabel || <Skeleton delay={1} width={75} />}</div>
                                          <ImagesPanel
                                             images={images}
                                             width={window.innerWidth > 500 ? 500 : window.innerWidth - 30}
                                             size={window.innerWidth < 1000 ? 2 : 4}
                                             onPress={(index: number) => {
                                                setImageIndex(index);
                                                //setImages(images);
                                                setShowImagesGallery(true);
                                             }}
                                          />
                                       </div>
                                    </div>
                                 )}
                                 {shipment.etaDate && shipment.etaTime && (
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
                                       <div style={{ flex: 1 }}>
                                          <div className="shipment-detail-title regular-bold-font">Estimasi Sampai</div>
                                          <div className="shipment-detail-desc regular-font">
                                             {shipment.etaDate}, {shipment.etaTime}
                                          </div>
                                       </div>
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </FadeView>
                     {paymentImages && <ImagesGallery title="Foto Muatan" images={paymentImages} index={paymentImageIndex} show={showPaymentImagesGallery} setShow={setShowPaymentImagesGallery} />}
                     {images && <ImagesGallery title="Foto Muatan" images={images} index={imageIndex} show={showImagesGallery} setShow={setShowImagesGallery} />}
                     <FadeView className="shipment-detail-delivery-progress" show={true} duration="0" style={{ alignSelf: "stretch" }}>
                        <div className="regular-bold-font">{deliveryProgressLabel || <Skeleton delay={1} width={150} />}</div>
                        <DeliveryProgress steps={steps} setNextStep={setNextStep} onShowStepImages={onShowStepImages} />
                     </FadeView>
                  </div>
                  {
                     /* isProvider(user) ||*/ isAdmin(user) && status === Master.SHIPMENT_STATUS_NEW_ORDER && (
                        <div className="shipment-detail-button-panel">
                           {showConfirmOptions && (
                              <div style={{ display: "flex", flexDirection: "column", paddingBottom: 0 }}>
                                 <div className="shipment-detail-button-divider"></div>
                                 <div className="regular-bold-font" style={{ paddingBottom: 5 }}>
                                    Terima Permintaan Angkutan
                                 </div>
                                 {user.isAdmin && confirmStepIndex === 1 && (
                                    <div className="shipment-detail-payment-section">
                                       <div className="regular-bold-font" style={{ paddingBottom: 10 }}>
                                          Biaya Angkutan
                                       </div>
                                       <InputNumber
                                          name="chargeAmount"
                                          className="shipment-detail-payment-amount"
                                          value={chargeAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setChargeAmount(value || 0)}
                                       />
                                       <div className="regular-font" style={{ paddingTop: 5, paddingBottom: 10 }}>
                                          Anda dapat memberikan potongan harga dari harga estimasi sistem
                                       </div>
                                       <div className="regular-bold-font" style={{ paddingBottom: 10 }}>
                                          Biaya Mitra
                                       </div>
                                       <InputNumber
                                          name="providerAmount"
                                          className="shipment-detail-payment-amount"
                                          value={providerAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setProviderAmount(value || 0)}
                                       />
                                       <Radio.Group name="type" options={Master.confirmTypeOptions} onChange={onChangeConfirmTypeOptions} value={confirmType} buttonStyle="solid" style={{ paddingBottom: 5, marginTop: 10 }} />
                                       {confirmType === Master.CONFIRM_WITHOUT_BOOKING_FEE && (
                                          <div className="regular-font" style={{ paddingTop: 5, paddingBottom: 10 }}>
                                             Pelanggan tidak membayar booking fee / tanda jadi. Pembayaran awal akan ditagihkan ketika angkutan telah tiba di lokasi asal muatan
                                          </div>
                                       )}
                                       {confirmType === Master.CONFIRM_WITH_BOOKING_FEE && (
                                          <div className="regular-font" style={{ paddingTop: 5 }}>
                                             Pelanggan diminta membayar Rp 50.000 atau maksimal sebesar biaya angkutan sebagai booking fee / tanda jadi.
                                          </div>
                                       )}
                                       <Radio.Group name="type" options={Master.topOptions} onChange={onChangeTopStatusOptions} value={topStatus} buttonStyle="solid" style={{ paddingBottom: 5, marginTop: 10 }} />
                                       {topStatus === Master.TOP_NO && (
                                          <div className="regular-font" style={{ paddingTop: 5, paddingBottom: 10 }}>
                                             Pelanggan melunasi pembayaran ketika angkutan telah sampai tujuan.
                                          </div>
                                       )}
                                       {topStatus === Master.TOP_YES && (
                                          <div>
                                             <div className="regular-font" style={{ paddingTop: 5 }}>
                                                Pelanggan melunasi pembayaran di kemudian hari.
                                             </div>
                                             <div className="shipment-detail-payment-title regular-bold-font" style={{}}>
                                                Durasi ToP (Hari)
                                             </div>
                                             <InputNumber
                                                name="topDuration"
                                                className="shipment-detail-payment-amount"
                                                value={topDuration}
                                                formatter={(value) => (value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                                parser={(value) => parseInt((value ? value : "").replace(/[A-Z,a-z]\s?|(\.*)/g, ""))}
                                                onChange={(value: any) => setTopDuration(value || 0)}
                                             />
                                          </div>
                                       )}
                                    </div>
                                 )}
                                 {user.isAdmin && confirmStepIndex === 2 && (
                                    <div className="shipment-detail-payment-section">
                                       <div className="shipment-detail-payment-title regular-bold-font">Biaya Angkutan</div>
                                       <div className="regular-font" style={{ paddingBottom: 5 }}>
                                          {FormatHelper.formatCurrency(chargeAmount)}
                                       </div>
                                       <div className="shipment-detail-payment-title regular-bold-font">Pembayaran Booking</div>
                                       <InputNumber
                                          name="planPaymentBookingAmount"
                                          className="shipment-detail-payment-amount"
                                          value={planPaymentBookingAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setPlanPaymentBookingAmount(value || 0)}
                                       />
                                       <div className="shipment-detail-payment-title regular-bold-font">Pembayaran Awal</div>
                                       <InputNumber
                                          name="planPaymentInitialAmount"
                                          className="shipment-detail-payment-amount"
                                          value={planPaymentInitialAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setPlanPaymentInitialAmount(value || 0)}
                                       />
                                       <div className="shipment-detail-payment-title regular-bold-font">Pelunasan Pembayaran</div>
                                       <InputNumber
                                          name="planPaymentFinalAmount"
                                          className="shipment-detail-payment-amount"
                                          value={planPaymentFinalAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setPlanPaymentFinalAmount(value || 0)}
                                       />
                                    </div>
                                 )}
                                 {user.isAdmin && confirmStepIndex === 3 && (
                                    <div className="shipment-detail-payment-section">
                                       <div className="shipment-detail-payment-title regular-bold-font">Biaya Mitra</div>
                                       <div className="regular-font" style={{ paddingBottom: 5 }}>
                                          {FormatHelper.formatCurrency(providerAmount)}
                                       </div>
                                       <div className="shipment-detail-payment-title regular-bold-font">Penerimaan Mitra saat Konfirmasi Pengiriman</div>
                                       <InputNumber
                                          name="planPayoutBookingAmount"
                                          className="shipment-detail-payment-amount"
                                          value={planPayoutBookingAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setPlanPayoutBookingAmount(value || 0)}
                                       />
                                       <div className="shipment-detail-payment-title regular-bold-font">Penerimaan Mitra saat Tiba Lokasi Jemput</div>
                                       <InputNumber
                                          name="planPayoutInitialAmount"
                                          className="shipment-detail-payment-amount"
                                          value={planPayoutInitialAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setPlanPayoutInitialAmount(value || 0)}
                                       />
                                       <div className="shipment-detail-payment-title regular-bold-font">Pelunasan Penerimaan Mitra Sampai Lokasi Tujuan</div>
                                       <InputNumber
                                          name="planPayoutFinalAmount"
                                          className="shipment-detail-payment-amount"
                                          value={planPayoutFinalAmount}
                                          formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "")}
                                          parser={(value) => parseInt((value ? value : "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                          onChange={(value: any) => setPlanPayoutFinalAmount(value || 0)}
                                       />
                                    </div>
                                 )}
                                 <div style={{ display: "flex", width: "100%" }}>
                                    <SquareButton ok text="OK" style={{ marginRight: 2 }} onClick={acceptShipment} />
                                    <SquareButton
                                       cancel
                                       text="Batal"
                                       warning
                                       style={{ marginLeft: 2 }}
                                       onClick={() => {
                                          setShowConfirmOptions(false);
                                       }}
                                    />
                                 </div>
                              </div>
                           )}
                           {!showConfirmOptions && (
                              <div style={{ display: "flex", width: "100%" }}>
                                 <SquareButton ok text="Terima" style={{ marginRight: 2 }} onClick={onPressConfirmAccept} />
                                 <SquareButton
                                    cancel
                                    text="Tidak Terima"
                                    warning
                                    style={{ marginLeft: 2 }}
                                    onClick={() => {
                                       setShowSelectDeclineReason(true);
                                    }}
                                 />
                              </div>
                           )}
                        </div>
                     )
                  }
                  {
                     // payment booking / initial payment
                     (isCustomer(user) || isAdmin(user)) &&
                     ((status === Master.SHIPMENT_STATUS_PENDING_PAYMENT && shipment.rescheduleStatus !== Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING && payment.bookingPaymentAmount === 0) ||
                        (status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                           (stepStatus === Master.STEP_CODE_ARRIVE_AT_ORIGIN || stepStatus === Master.STEP_CODE_COMPLETE_LOADING) &&
                           payment.initialPaymentAmount === 0 && payment.topDuration === 0 &&
                           payment.status !== Master.PAYMENT_STATUS_COMPLETED)) && (
                        <div className="shipment-detail-button-panel" style={{ flexDirection: "column" }}>
                           {showPaymentOptions && (
                              <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
                                 <div className="shipment-detail-button-divider"></div>
                                 <div className="shipment-detail-payment-title regular-bold-font">{isCustomer(user) ? "Bayar Biaya Angkutan" : "Terima Pembayaran"}</div>
                                 <Radio.Group name="type" options={Master.paymentTypeOptions} onChange={onChangePaymentTypeOptions} value={paymentType} buttonStyle="solid" style={{ paddingBottom: 5, marginTop: 0 }} />
                                 <InputNumber
                                    name="initialPayment"
                                    className="shipment-detail-payment-amount"
                                    value={paymentAmount}
                                    formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace("Rp 0", "Rp ") : "")}
                                    readOnly={paymentType === Master.PAYMENT_TYPE_FULL}
                                    parser={(value) => parseInt((value ? value : "").replace(/\D+/g, "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                    onChange={(value: any) => setPaymentAmount(value || 0)}
                                 />
                                 {paymentType === Master.PAYMENT_TYPE_PARTIAL && (
                                    <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 5 }}>
                                       <div className="regular-bold-font">Sisa Tagihan</div>
                                       <div className="regular-bold-font">{FormatHelper.formatCurrency(shipment.payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount - paymentAmount)}</div>
                                    </div>
                                 )}
                                 {isAdmin(user) && <Radio.Group name="method" options={Master.paymentMethodOptions} onChange={onChangePaymentMethodOptions} value={paymentMethod} buttonStyle="solid" style={{ paddingBottom: 0, marginTop: 10 }} />}
                                 {isAdmin(user) && paymentMethod === Master.PAYMENT_METHOD_TRANSFER && (
                                    <div className="shipment-detail-payment-image">
                                       <ImageUpload single reference="payment-receipt" image={paymentImage} photo text="Bukti Pembayaran" setImage={setPaymentImage} />
                                    </div>
                                 )}
                              </div>
                           )}
                           <div style={{ display: "flex" }}>
                              <SquareButton ok text={!showPaymentOptions ? (isCustomer(user) ? "Bayar Biaya Angkutan" : "Terima Pembayaran") : isCustomer(user) ? "Lanjut Ke Pembayaran" : "Konfirmasi Pembayaran"} onClick={onPressPay} />
                              {!showPaymentOptions && status !== Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS && <SquareButton calendar text="Ganti Jadwal" style={{ marginLeft: 5 }} onClick={onPressReschedule} />}
                           </div>
                        </div>
                     )
                  }
                  {
                     // payout payment
                     isAdmin(user) &&
                     ((status === Master.SHIPMENT_STATUS_PENDING_PAYMENT &&
                        shipment.rescheduleStatus !== Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING &&
                        payment.bookingPaymentAmount != 0 &&
                        payment.planPayoutBooking !== 0 &&
                        payment.bookingPayoutAmount == 0 &&
                        payment.planPayoutBooking !== payment.bookingPayoutAmount) ||
                        (status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                           (stepStatus === Master.STEP_CODE_COMPLETE_LOADING || stepStatus === Master.STEP_CODE_TRAVEL_TO_DESTINATION) &&
                           (payment.initialPaymentAmount !== 0 || payment.topDuration > 0) &&
                           payment.planPayoutInitial !== 0 &&
                           payment.initialPayoutAmount == 0 &&
                           payment.planPayoutInitial !== payment.initialPayoutAmount) ||
                        ((status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS || status === Master.SHIPMENT_STATUS_DELIVERY_COMPLETED || status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED) &&
                           (stepStatus === Master.STEP_CODE_COMPLETE_UNLOADING) &&
                           (payment.finalPaymentAmount !== 0 || payment.topDuration > 0) &&
                           payment.planPayoutFinal !== 0 &&
                           payment.finalPaymentAmount == 0 &&
                           payment.planPayoutFinal !== payment.finalPayoutAmount)) && (
                        <div className="shipment-detail-button-panel" style={{ flexDirection: "column" }}>
                           {showPaymentOptions && (
                              <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
                                 <div className="shipment-detail-button-divider"></div>
                                 <div className="shipment-detail-payment-title regular-bold-font">
                                    {payment.planPayoutBooking !== 0 && payment.planPayoutBooking !== payment.bookingPayoutAmount
                                       ? "Pembayaran Booking Mitra"
                                       : payment.planPayoutInitial !== 0 && payment.planPayoutInitial !== payment.initialPayoutAmount
                                          ? "Pembayaran Awal Mitra"
                                          : "Pembayaran Pelunasan Mitra"}
                                 </div>
                                 <InputNumber
                                    name="initialPayout"
                                    className="shipment-detail-payment-amount"
                                    value={payoutAmount}
                                    formatter={(value) => (value ? `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".").replace("Rp 0", "Rp ") : "")}
                                    readOnly={paymentType === Master.PAYMENT_TYPE_FULL}
                                    parser={(value) => parseInt((value ? value : "").replace(/\D+/g, "").replace(/[R,p]\s?|(\.*)/g, ""))}
                                    onChange={(value: any) => setPayoutAmount(value || 0)}
                                 />
                                 <Radio.Group name="method" options={Master.paymentMethodOptions} onChange={onChangePaymentMethodOptions} value={paymentMethod} buttonStyle="solid" style={{ paddingBottom: 0, marginTop: 10 }} />
                                 {paymentMethod === Master.PAYMENT_METHOD_TRANSFER && (
                                    <div className="shipment-detail-payment-image">
                                       <ImageUpload single reference="payment-receipt" image={paymentImage} photo text="Bukti Pembayaran" setImage={setPaymentImage} />
                                    </div>
                                 )}
                              </div>
                           )}
                           <div style={{ display: "flex" }}>
                              <SquareButton ok text={!showPaymentOptions ? "Pembayaran Mitra" : "Bayar Biaya Mitra"} onClick={onPressPayout} />
                           </div>
                        </div>
                     )
                  }
                  {isShipmentTransporter(user, shipment) &&
                     status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                     stepStatus === Master.STEP_CODE_COMPLETE_LOADING &&
                     payment.topDuration == 0 &&
                     payment.initialPaymentAmount === 0 &&
                     payment.status !== Master.PAYMENT_STATUS_COMPLETED && (
                        <div className="shipment-detail-button-panel" style={{ flexDirection: "column" }}>
                           <div style={{ display: "flex" }}>
                              <SquareButton ok text="Cek Konfirmasi Pembayaran" onClick={onCheckPaymentStatus} />
                           </div>
                        </div>
                     )}
                  {isProvider(user) && shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_CUSTOMER_REQUEST_PENDING && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton ok text="Terima Perubahan Jadwal" style={{ marginRight: 5 }} onClick={() => onPressProcessReschedule(true)} />
                        <SquareButton cancel warning text="Tolak Perubahan Jadwal" onClick={() => onPressProcessReschedule(false)} />
                     </div>
                  )}
                  {isCustomer(user) && shipment.rescheduleStatus === Master.RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton ok text="Terima Perubahan Jadwal" style={{ marginRight: 5 }} onClick={() => onPressProcessReschedule(true)} />
                        <SquareButton cancel warning text="Tolak Perubahan Jadwal" onClick={() => onPressProcessReschedule(false)} />
                     </div>
                  )}
                  {isShipmentTransporter(user, shipment) &&
                     (status === Master.SHIPMENT_STATUS_CONFIRM_PAYMENT || status === Master.SHIPMENT_STATUS_CONFIRM_NO_PAYMENT || status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS) &&
                     !(stepStatus === Master.STEP_CODE_COMPLETE_LOADING && payment.topDuration === 0 && payment.initialPaymentAmount === 0 && payment.status !== Master.PAYMENT_STATUS_COMPLETED) &&
                     !(stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION && payment.topDuration === 0 && payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount) &&
                     shipment.rescheduleStatus !== Master.RESCHEDULE_STATUS_PROVIDER_REQUEST_PENDING && (
                        <div className="shipment-detail-button-panel">
                           <SquareButton ok text={nextStep && FormatHelper.formatTitle(ReferenceHelper.getStepAction(nextStep.code))} style={{ marginRight: 2 }} onClick={onPressProcess} />
                        </div>
                     )}
                  {/* if payment is cash then the payment confirmation from admin */}
                  {isAdmin(user) &&
                     status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                     stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION &&
                     payment.topDuration === 0 &&
                     payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount && (
                        <div className="shipment-detail-button-panel" style={{ flexDirection: "column" }}>
                           <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
                              <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 5 }}>
                                 <div className="regular-bold-font">Sisa Tagihan</div>
                                 <div className="regular-bold-font">{FormatHelper.formatCurrency(payment.totalPaymentAmount - shipment.payment.bookingPaymentAmount - payment.initialPaymentAmount)}</div>
                              </div>
                              <Radio.Group name="method" options={Master.paymentMethodOptions} onChange={onChangePaymentMethodOptions} value={paymentMethod} buttonStyle="solid" style={{ paddingBottom: 0, marginTop: 10 }} />
                              {paymentMethod === Master.PAYMENT_METHOD_TRANSFER && (
                                 <div className="shipment-detail-payment-image">
                                    <ImageUpload single reference="payment-receipt" image={paymentImage} photo text="Bukti Pembayaran" setImage={setPaymentImage} />
                                 </div>
                              )}
                           </div>
                           <div style={{ display: "flex" }}>
                              <SquareButton ok text={"Menerima Pelunasan"} onClick={onAcceptFinalPayment} />
                           </div>
                        </div>
                     )}
                  {isShipmentTransporter(user, shipment) &&
                     status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                     stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION &&
                     payment.topDuration === 0 && // non TOP
                     payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount &&
                     payment.totalPaymentAmount !== payment.initialPaymentAmount + payment.finalPaymentAmount && (
                        <div className="shipment-detail-button-panel" style={{ flexDirection: "column" }}>
                           <div style={{ display: "flex" }}>
                              <SquareButton ok text="Cek Konfirmasi Pembayaran" onClick={onCheckPaymentStatus} />
                           </div>
                        </div>
                     )}
                  {isCustomer(user) &&
                     status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS &&
                     stepStatus === Master.STEP_CODE_ARRIVE_AT_DESTINATION &&
                     payment.totalPaymentAmount !== payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount && (
                        <div className="shipment-detail-button-panel" style={{ flexDirection: "column" }}>
                           <div style={{ display: "flex", flexDirection: "column", paddingBottom: 10 }}>
                              <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 5 }}>
                                 <div className="regular-bold-font">Sisa Tagihan</div>
                                 <div className="regular-bold-font">{FormatHelper.formatCurrency(payment.totalPaymentAmount - payment.bookingPaymentAmount - payment.initialPaymentAmount)}</div>
                              </div>
                           </div>
                           <div style={{ display: "flex" }}>
                              <SquareButton ok text={"Bayar Sisa Tagihan"} onClick={onPressFinalPay} />
                           </div>
                        </div>
                     )}
                  {isCustomer(user) && status === Master.SHIPMENT_STATUS_DELIVERY_COMPLETED && payment.totalPaymentAmount === payment.bookingPaymentAmount + payment.initialPaymentAmount + payment.finalPaymentAmount && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton ok text="Konfirmasi Pengiriman" onClick={onPressConfirmDelivery} />
                        <SquareButton cancel text="Ajukan Keluhan" warning style={{ marginLeft: 5 }} onClick={onPressComplaint} />
                     </div>
                  )}
                  {isCustomer(user) && (status === Master.SHIPMENT_STATUS_COMPLAINT || status === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED) && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton warning alert text="Lihat Keluhan" onClick={() => setShowProcessComplaint(true)} />
                     </div>
                  )}
                  {(isProvider(user) || isTransporter(user)) && (status === Master.SHIPMENT_STATUS_COMPLAINT || status === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED) && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton
                           warning
                           alert
                           text={status === Master.SHIPMENT_STATUS_COMPLAINT && complaint?.status !== Master.COMPLAINT_STATUS_PROVIDER_RESPONSE ? "Proses Keluhan" : "Lihat Keluhan"}
                           onClick={() => setShowProcessComplaint(true)}
                        />
                     </div>
                  )}
                  {isAdmin(user) && complaint && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton
                           warning
                           alert
                           text={status === Master.SHIPMENT_STATUS_COMPLAINT && complaint?.status === Master.COMPLAINT_STATUS_PROVIDER_RESPONSE ? "Proses Keluhan" : "Lihat Keluhan"}
                           onClick={() => setShowProcessComplaint(true)}
                        />
                     </div>
                  )}
                  {isCustomer(user) && (status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED || status === Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED) && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton star text="Berikan Penilaian" onClick={() => setShowRating(true)} />
                     </div>
                  )}
                  {isTransporter(user) && (status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED || status === Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED) && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton star text="Berikan Penilaian" onClick={() => setShowRating(true)} />
                     </div>
                  )}
                  {isAdmin(user) && (status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED || status === Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED) && (
                     <div className="shipment-detail-button-panel">
                        <SquareButton ok text="Transfer Pembayaran Transporter" onClick={() => onPressPaymentTransfered()} />
                     </div>
                  )}
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
