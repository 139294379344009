import * as React from "react";
import Skeleton from "react-loading-skeleton";

import { CustomButton } from ".";

import { Site } from "../../model";

import { CacheService } from "../../service";

import "./SiteView.css";

export function SiteView(props: any) {
   const item: Site = props.site;

   const onPressMore = () => {
      props.onPressMore(item);
   };

   return (
      <div className="site-view-item regular-bottom-border">
         <div className="site-view-content">
            <div className="site-view-desc">
               <div className="regular-bold-font">{item.name || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
               {CacheService.getUser().isAdmin && (
                  <div className="site-view-provider">
                     {item.provider?.name && <span className="site-view-provider-info regular-white-font">{item.provider?.name}</span>}
                     {!item.provider?.name && <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 215 : 200} />}
                  </div>
               )}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.name} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default SiteView;
