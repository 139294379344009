import React from 'react';
import { Carousel } from 'antd';
import ShowcaseItem from './ShowcaseItem';

import 'antd/dist/antd.css';
import './Showcase.css';

function Showcase(props) {
   const category = props.category;

   return (
      <div style={window.innerWidth < 1000 ? { height: 240 } : {}}>
         <Carousel
            autoplay="true"
            speed="10000"
            autoPlaySpeed="10000"
            effect="scrollx"
            dots={false}
            pauseOnHover={false}
            slidesToSlide={1}
         >
            {category.imageUrls.slice(0, 1).map((imageUrl, i) => {
               return <ShowcaseItem desc={category.desc} imageUrl={imageUrl} key={i} />;
            })}
         </Carousel>
         <div className="showcase-item-content">
            <div className="showcase-item-text">
               <div className="showcase-item-desc">{props.category.desc}</div>
            </div>
         </div>
      </div>
   );
}

export default Showcase;
