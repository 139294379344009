import * as React from "react";
import { useState, useEffect, useRef } from "react";

import { AiOutlineInfoCircle, AiOutlineWarning } from "react-icons/ai";

import { CustomButton, FadeView } from ".";

import * as Color from "../../constants/Color";
import * as Master from "../../reference/Master";

import * as AlertHelper from "../../helper/AlertHelper";
import * as CommonHelper from "../../helper/CommonHelper";

import { CacheService } from "../../service";

import "./AlertView.css";

let timer: any;

export default function AlertView(props: any) {
   const [show, setShow] = useState(props.show);
   const [alertColor, setAlertColor] = useState(Color.alertError);
   const [borderColor, setBorderColor] = useState(Color.alertErrorBorder);
   const [title, setTitle] = useState("Peringatan Kesalahan");
   const isMountedRef = useRef(false);

   useEffect(() => {
      if (props.show) {
         let wait = props.type === Master.ALERT_WARNING ? 0 : 500;
         setTimeout(() => {
            setShow(props.show);
            isMountedRef.current = true;

            if (timer) {
               clearTimeout(timer);
            }

            timer = setTimeout(
               () => {
                  if (isMountedRef.current) {
                     props.setShow(false);
                     AlertHelper.enableScroll(true, props.src);
                  }
                  if (props.anchor && props.anchor.current) {
                     props.anchor.current.scrollIntoView();
                     props.setAnchor(null);
                  }
               },
               props.type === Master.ALERT_ERROR ? 10000 : 2000
            );

            return () => {
               isMountedRef.current = false;
            };
         }, wait);
      } else {
         setShow(props.show);
         //AlertHelper.enableScroll();
      }
   }, [props]);

   useEffect(() => {
      setTitle(props.type === Master.ALERT_ERROR ? Master.ALERT_ERROR_TITLE : props.type === Master.ALERT_WARNING ? Master.ALERT_WARNING_TITLE : props.type === Master.ALERT_INFO ? Master.ALERT_INFO_TITLE : Master.ALERT_SUCCESS_TITLE);

      setAlertColor(props.type === Master.ALERT_SUCCESS || props.type === Master.ALERT_INFO ? Color.alertSuccess : props.type === Master.ALERT_WARNING ? Color.alertWarning : Color.alertError);

      setBorderColor(props.type === Master.ALERT_SUCCESS || props.type === Master.ALERT_INFO ? Color.alertSuccessBorder : props.type === Master.ALERT_WARNING ? Color.alertWarningBorder : Color.alertErrorBorder);
   }, [props.type]);

   const onPress = () => {
      if (timer) {
         clearTimeout(timer);
      }
      props.setShow(false);
      AlertHelper.enableScroll(true, props.src);
   };

   const onContactWa = () => {
      let shipmentStr = "";
      //if (window.location.pathname === "/Confirm-Shipment") {
      if (CacheService.getNewShipment()) {
         let shipment = CommonHelper.copyObject(CacheService.getNewShipment());
         shipment.path = undefined;
         shipmentStr = JSON.stringify(shipment, null, 0);
      }
      window.open("https://wa.me/" + Master.MOBILE_LINTAS + "?text=" + encodeURI("Mohon bantuan, mengalami kendala error. " + props.msg) + "%0a%0a" + encodeURI(shipmentStr));
   };

   return (
      props.show && (
         <div className="alert-container" style={{ height: window.innerHeight }}>
            <FadeView show={show} duration="1000">
               <div onClick={onPress}>
                  <div className="alert-content">
                     <div className="alert-border regular-border" style={{ borderColor: borderColor }}>
                        {props.type === Master.ALERT_SUCCESS || props.type === Master.ALERT_INFO ? <AiOutlineInfoCircle size={30} color={alertColor} /> : <AiOutlineWarning size={30} color={alertColor} />}
                        <div className="alert-title bold-font">
                           <span style={{ color: alertColor }}>{props.title ? props.title : title}</span>
                        </div>
                        <div>
                           <div className="alert-desc bold-font">{props.desc}</div>
                           {props.type === Master.ALERT_ERROR ? (
                              <div style={{ paddingTop: 10, display: "flex", justifyContent: "center" }}>
                                 <CustomButton icon="call" text="Hubungi CS via WA" onClick={onContactWa} />
                              </div>
                           ) : (
                              <div></div>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </FadeView>
         </div>
      )
   );
}
