import { ApolloClient, InMemoryCache, NormalizedCacheObject, gql } from "@apollo/client";
import { CacheService } from ".";

import * as Config from "../Config";

import * as Master from "../reference/Master";

export let graphClient: ApolloClient<NormalizedCacheObject>;
export let authGraphClient: ApolloClient<NormalizedCacheObject>;
export let hybridGraphClient: ApolloClient<NormalizedCacheObject>;

export const initClient = async () => {
   const params = {
      uri: Config.LINTAS_API + "/query",
      cache: new InMemoryCache(),
      headers: {
         authorization: `${Master.APP_VERSION}`,
      },
   };
   graphClient = new ApolloClient(params);
};

export const initAuthClient = async () => {
   const token = CacheService.getToken();
   const tokenValidity = CacheService.getTokenValidity();

   const params = {
      uri: Config.LINTAS_API + "/query",
      headers: {
         authorization: `${Master.APP_VERSION} ${token ? token.token : ""}`,
      },
      cache: new InMemoryCache(),
   };
   authGraphClient = new ApolloClient(params);

   // console.log("Params: ", params);
   // console.log("Token: " + (token ? JSON.stringify(token, null, 2) : token));
   // console.log("Token Validity: " + (tokenValidity ? tokenValidity.toISOString() : tokenValidity));

   if (token === null || token.refreshToken === null) {
      throw new Error("refresh token is null");
   }

   if (tokenValidity < new Date()) {
      // console.log("Token Expired ");
      initClient();

      let q = `
            mutation {
               GetNewToken(refreshToken: "${token?.refreshToken}") {
                  token
                  refreshToken
                  tokenValidity
               }
            }`;

      console.log("gql:", q);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${q}
         `,
      });

      let newToken = result.data.GetNewToken;
      CacheService.setToken({ token: newToken.token, refreshToken: newToken.refreshToken });

      let tokenValidity = new Date();
      tokenValidity.setSeconds(tokenValidity.getSeconds() + newToken.tokenValidity);
      CacheService.setTokenValidity(tokenValidity);
      // console.log("result:", newToken);

      const params = {
         uri: Config.LINTAS_API + "/query",
         headers: {
            authorization: `${Master.APP_VERSION} ${newToken ? newToken.token : ""}`,
         },
         cache: new InMemoryCache(),
      };
      authGraphClient = new ApolloClient(params);

      // console.log("Params: ", params);
   }

   //console.log('initAuthClient', params);
};

export const initHybridClient = async () => {
   const token = CacheService.getToken();
   const tokenValidity = CacheService.getTokenValidity();

   const params = {
      uri: Config.LINTAS_API + "/query",
      headers: {
         authorization: `${Master.APP_VERSION} ${token ? token.token : ""}`,
      },
      cache: new InMemoryCache(),
   };
   hybridGraphClient = new ApolloClient(params);

   // console.log("Params: ", params);
   // console.log("Token: " + (token ? JSON.stringify(token, null, 2) : token));
   // console.log("Token Validity: " + (tokenValidity ? tokenValidity.toISOString() : tokenValidity));

   if (token !== null && token.refreshToken === null) {
      throw new Error("refresh token is null");
   }

   if (token !== null && tokenValidity < new Date()) {
      // console.log("Token Expired ");
      initClient();

      let q = `
            mutation {
               GetNewToken(refreshToken: "${token?.refreshToken}") {
                  token
                  refreshToken
                  tokenValidity
               }
            }`;

      console.log("gql:", q);

      let result = await hybridGraphClient.mutate({
         mutation: gql`
            ${q}
         `,
      });

      let newToken = result.data.GetNewToken;
      CacheService.setToken({ token: newToken.token, refreshToken: newToken.refreshToken });

      let tokenValidity = new Date();
      tokenValidity.setSeconds(tokenValidity.getSeconds() + newToken.tokenValidity);
      CacheService.setTokenValidity(tokenValidity);
      // console.log("result:", newToken);

      const params = {
         uri: Config.LINTAS_API + "/query",
         headers: {
            authorization: `${Master.APP_VERSION} ${newToken ? newToken.token : ""}`,
         },
         cache: new InMemoryCache(),
      };
      hybridGraphClient = new ApolloClient(params);

      // console.log("Params: ", params);
   }

   //console.log('initAuthClient', params);
};
