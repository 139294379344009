import { Customer } from "../model";
import * as Image from "./Image";
import * as Place from "./Place";

export const newCustomer: Customer = {
   id: undefined,
   gid: "",
   name: "",
   type: "",
   phone: "",
   email: "",
   level: "",
   rating: -1,
   location: Place.defaultPlace,
   profileImg: Image.newImage,
   profileImgUrl: "",
};

export const getEmptyCustomers = (count: number) => {
   const customers = Array<Customer>();
   for (let i = 0; i < count; i++) {
      customers.push(newCustomer);
   }
   return customers;
};
