import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { CacheService, CustomerService, ErrorService, AuthService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";
import * as AlertHelper from "../../../helper/AlertHelper";
import * as GraphHelper from "../../../helper/GraphHelper";

import { Customer } from "../../../model";

import * as Master from "../../../reference/Master";

import { getEmptyCustomers } from "../../../reference/Customer";

import "./Customers.css";

let modalContext: ModalContextInterface;

export default function RegisterCustomer(props: any) {
   const history = useHistory();
   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [customers, setCustomers] = useState(getEmptyCustomers(pageSize));
   const [searchCustomerName, setSearchCustomer] = useState("");
   const [searchCustomerMobile, setSearchProvider] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setCustomers(getEmptyCustomers(pageSize));
      SearchHelper.searchInputs([searchCustomerName, searchCustomerMobile], () => {
         let customer = new Customer();
         customer.name = searchCustomerName;
         customer.phone = searchCustomerMobile;

         CustomerService.searchCustomer(customer, pageSize, page)
            .then((result) => {
               setCustomers(result.customers);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchCustomerName, searchCustomerMobile, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchCustomerName, searchCustomerMobile]);

   const onPressNewCustomer = (shipment: Customer) => {
      history.push("/register/customer/" + searchCustomerMobile);
   };

   const onPressMore = (customer: Customer) => {
      AlertHelper.alertLoading("Loading ...", modalContext);

      AuthService.getSudoToken(customer.gid || "", Master.ROLE_CUSTOMER)
         .then((auth) => {
            console.log("auth: ", JSON.stringify(auth, null, 2));
            modalContext?.setLogin(true);

            CacheService.resetPage();
            // force change on current page, if not current variable page in Navigation caused the page cache remain on current page
            setPage(1);

            CacheService.setToken({ token: auth.token, refreshToken: auth.refreshToken });
            console.log("auth: ", JSON.stringify(auth, null, 2));

            let user = GraphHelper.graphToUser(auth.user, Master.ROLE_CUSTOMER);

            CacheService.setUser(user);
            props.setUser(user);

            setTimeout(() => {
               AlertHelper.hideLoading(modalContext);
               history.replace("/home/login");
            }, 1000);
         })
         .catch((error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="customers">
                  <div className="customers-header regular-bottom-border">
                     <div className="customers-header-search">
                        <div className="customers-header-search-section">
                           <SectionIcon person className="customers-header-search-icon" />
                           <div className="customers-header-search-input">
                              <InputSearch placeholder="Cari Pelanggan" value={searchCustomerName} setValue={setSearchCustomer} />
                           </div>
                        </div>
                        {user.isAdmin && (
                           <div className="customers-header-search-section">
                              <SectionIcon phone className="customers-header-search-icon" />
                              <div className="customers-header-search-input">
                                 <InputSearch placeholder="Cari Telepon" value={searchCustomerMobile} setValue={setSearchProvider} />
                              </div>
                           </div>
                        )}
                     </div>
                     {user.isAdmin && customers.length === 0 && (
                        <div className="customers-header-button">
                           <CustomButton icon="truck" text="Tambah Pelanggan" onClick={onPressNewCustomer} style={{ width: 190 }} />
                        </div>
                     )}
                  </div>
                  <div id="search-result" className={"search-result " + (user.isAdmin ? (props.showBottomNavigation ? "search-result-admin" : "search-result-admin-no-nav") : props.showBottomNavigation ? "search-result" : "search-result-no-nav")}>
                     <PageView
                        customers={customers}
                        onPressMore={(customer: Customer) => {
                           console.log("customer: ", JSON.stringify(customer, null, 2));
                           onPressMore(customer);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchCustomerName && !searchCustomerMobile && "Belum terdapat pengemudi"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
