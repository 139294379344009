import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Radio, Slider, Checkbox } from "antd";
import { ModalContext, ModalContextInterface } from "../../hooks/ModalContext";

import { ShipmentService, FileUploadService, ErrorService, CacheService } from "../../service";

import { InputText, PopupLayout, SquareButton, InputSign, CapturePhoto } from "../common";

import * as AlertHelper from "../../helper/AlertHelper";
import * as FormatHelper from "../../helper/FormatHelper";
import * as ReferenceHelper from "../../helper/ReferenceHelper";
import * as PositionHelper from "../../helper/PositionHelper";
import * as ConfirmHelper from "../../helper/ConfirmHelper";

import * as Master from "../../reference/Master";

import "./ProcessShipment.css";
import { Image } from "../../model";

const typeOptions = Master.transitTypes.map((entityType) => ({ label: entityType.desc, value: entityType.code }));

let modalContext: ModalContextInterface;

export default function ProcessShipment(props: any) {
   const history = useHistory();

   const [photo, setPhoto] = useState("");
   const [remarks, setRemarks] = useState("");
   const [sign, setSign] = useState("");
   const [signer, setSigner] = useState("");
   const [isSharedCapacity, setSharedCapacity] = useState(false);
   const [loadCapacity, setLoadCapacity] = useState(0);

   const [isTransit, setTransit] = useState(false);
   const [isCompleteLoading, setCompleteLoading] = useState(false);

   const [type, setType] = useState(Master.TRANSIT_TYPE_LOADING);

   const formRef = useRef<HTMLDivElement>(null);

   const validate = () => {
      let isValidated = true;

      //console.log('sign: ', JSON.stringify(sign, null, 2));
      //console.log('remarks: ', JSON.stringify(remarks, null, 2));

      if (!isTransit) {
         if (!photo) {
            AlertHelper.alertWarning("Mohon lampirkan bukti", modalContext);
            isValidated = false;
         } else if (isCompleteLoading && (!loadCapacity || loadCapacity === 0)) {
            AlertHelper.alertWarning("Mohon isi kapasitas muatan", modalContext);
            isValidated = false;
         } else if (!remarks) {
            AlertHelper.alertWarning("Mohon isi catatan", modalContext);
            isValidated = false;
         } else if (!sign) {
            AlertHelper.alertWarning("Mohon tanda tangan", modalContext);
            isValidated = false;
         }
      }

      if (!isValidated) {
         if (!formRef.current) {
            return isValidated;
         }
         formRef.current.scrollTo({ behavior: "smooth", top: window.innerHeight - 145 });
      }

      return isValidated;
   };

   const onChangeTypeOptions = (e: any) => {
      setType(e.target.value);
   };

   const sharedCapacitySelected = (e: any) => {
      setSharedCapacity(e.target.checked);
   };

   const onSliderChange = (value: any) => {
      if (isNaN(value)) {
         return;
      }
      setLoadCapacity(value);
   };

   const onClose = () => {
      props.setShow(false);
      AlertHelper.enableScroll(true, "ProcessShipment");
   };

   const onOK = () => {
      if (!validate()) {
         return;
      }

      //console.log('sign: ', JSON.stringify(sign, null, 2));
      AlertHelper.alertLoading("Memproses Angkutan Anda", modalContext);

      if (sign) {
         const module = "step-sign";
         const title = "Tanda Tangan";

         FileUploadService.uploadImageBase64(module, `${title} ${signer}`, sign).then((signImg) => {
            const module = "step-photo";
            const title = `Foto Bukti ${FormatHelper.formatTitle(ReferenceHelper.getStepAction(props.nextStep.code))}`;
            FileUploadService.uploadImageBase64(module, title, photo).then((photoImg) => {
               processShipment(signImg, [photoImg]);
            });
         });
      } else {
         processShipment(undefined, undefined);
      }
   };

   const updateShipmentInDeliveryPosition = () => {
      PositionHelper.updateShipmentInDeliveryPosition();
      CacheService.setGpsPermissionRequested(true);
   };

   const processShipment = (signImg: Image | undefined, photos: Image[] | undefined) => {
      ShipmentService.processShipment(props.shipment, isTransit ? type : "", remarks, signImg, photos, isSharedCapacity, loadCapacity)
         .then((resp) => {
            if (CacheService.isGpsPermissionRequested()) {
               PositionHelper.updateShipmentInDeliveryPosition();
            } else {
               ConfirmHelper.confirm(
                  "Lintas mengambil data lokasi anda untuk proses tracking pengiriman angkutan walaupun aplikasi tidak ditutup atau tidak digunakan. \nLintas collects location data to enable shipment delivery tracking even when the app is closed or not in use. ",
                  () => updateShipmentInDeliveryPosition,
                  modalContext
               );
            }
            setTransit(false);
            setSign("");
            setPhoto("");
            props.setSteps(resp.steps);
            props.setRoutes(resp.routes);
            props.refreshShipmentStatus(resp.shipmentStatus);
            props.setShow(false);
            AlertHelper.alertSuccess("Proses berhasil", modalContext);
         })
         .catch((error: Error) => {
            ErrorService.handleError(error, history, modalContext);
         });
   };

   useEffect(() => {
      if (props.nextStep) {
         if (props.nextStep.code === Master.STEP_CODE_START_TRANSIT) {
            setTransit(true);
         } else if (props.nextStep.code === Master.STEP_CODE_COMPLETE_LOADING) {
            setSigner("Pengirim");
            setCompleteLoading(true);
         } else if (props.nextStep.code === Master.STEP_CODE_COMPLETE_UNLOADING) {
            setSigner("Penerima");
         }
      }
   }, [props.nextStep]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            AlertHelper.enableScroll(!props.show, "ProcessShipment");
            return (
               props.show && (
                  <div className="process-shipment">
                     <PopupLayout full className="process-shipment-container" title={props.nextStep && FormatHelper.formatTitle(ReferenceHelper.getStepAction(props.nextStep.code))} onClickPrev={onClose} onClickClose={onClose}>
                        <div ref={formRef} className="process-shipment-content" style={{ height: window.innerHeight - 145 }}>
                           {!isTransit && props.nextStep && <CapturePhoto imageBase64={photo} setImageBase64={setPhoto} />}
                           <form>
                              {isCompleteLoading && (
                                 <Checkbox onClick={sharedCapacitySelected} checked={isSharedCapacity} style={{ marginTop: 10 }}>
                                    Terdapat muatan lain / berbagi muatan
                                 </Checkbox>
                              )}
                              {isCompleteLoading && (
                                 <div style={{ marginTop: 5, display: "flex", justifyContent: "space-between" }}>
                                    <div>Kapasitas Muatan</div>
                                    <div>{loadCapacity} %</div>
                                 </div>
                              )}
                              {isCompleteLoading && (
                                 <div style={{ display: "flex" }}>
                                    <Slider min={0} max={100} onChange={onSliderChange} value={typeof loadCapacity === "number" ? loadCapacity : 0} style={{ flex: 1 }} />
                                    <div style={{ width: 30, textAlign: "right", paddingTop: 5 }}>100</div>
                                 </div>
                              )}
                              {isTransit && <Radio.Group name="type" options={typeOptions} onChange={onChangeTypeOptions} value={type} buttonStyle="solid" />}
                              {!isTransit && (
                                 <InputText
                                    name="remarks"
                                    placeholder="Catatan"
                                    rows={2}
                                    onChange={(e: any) => {
                                       setRemarks(e.target.value);
                                    }}
                                 />
                              )}
                              {!isTransit && <InputSign placeholder={"Tanda Tangan " + signer} setSign={setSign} />}
                              <div></div>
                           </form>
                        </div>
                        <div className="process-shipment-bottom">
                           <div className="process-shipment-buttons">
                              <SquareButton ok text="OK" style={{ marginRight: 5 }} onClick={onOK} />
                              <SquareButton cancel text="Batal" warning style={{ marginLeft: 5 }} onClick={onClose} />
                           </div>
                        </div>
                     </PopupLayout>
                  </div>
               )
            );
         }}
      </ModalContext.Consumer>
   );
}
