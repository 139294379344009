import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Steps } from "antd";

import { RiTruckLine } from "react-icons/ri";
import { FiPackage } from "react-icons/fi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { AiOutlineCheckSquare, AiOutlineHome } from "react-icons/ai";

import { CustomButton } from ".";

import "./ShipmentSteps.css";
import * as Master from "../../reference/Master";
import { CacheService } from "../../service";

const { Step } = Steps;

function BookingSteps(props: any) {
   const history = useHistory();
   const [bookingStatus, setBookingStatus] = useState(false);
   const [paymentStatus, setPaymentStatus] = useState(false);
   const [deliveryInProgressStatus, setDeliveryInProgressStatus] = useState(false);
   const [deliveryCompletedStatus, setDeliveryCompletedStatus] = useState(false);
   const [deliveryConfirmedStatus, setDeliveryConfirmedStatus] = useState(false);

   useEffect(() => {
      //console.log('step status: ', props.status);
      if (props.status === Master.SHIPMENT_STATUS_NEW_ORDER || props.status === Master.SHIPMENT_STATUS_PENDING_PAYMENT) {
         setBookingStatus(true);
      } else if (props.status === Master.SHIPMENT_STATUS_CONFIRM_PAYMENT) {
         setBookingStatus(true);
         setPaymentStatus(true);
      } else if (props.status === Master.SHIPMENT_STATUS_CONFIRM_NO_PAYMENT) {
         setBookingStatus(true);
         setPaymentStatus(false);
      } else if (props.status === Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS) {
         setBookingStatus(true);
         setPaymentStatus(true);
         setDeliveryInProgressStatus(true);
      } else if (props.status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED) {
         setBookingStatus(true);
         setPaymentStatus(true);
         setDeliveryInProgressStatus(true);
         setDeliveryCompletedStatus(true);
      } else if (props.status === Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED) {
         setBookingStatus(true);
         setPaymentStatus(true);
         setDeliveryInProgressStatus(true);
         setDeliveryCompletedStatus(true);
         setDeliveryConfirmedStatus(true);
      }
   }, [props.status]);

   const onSelectPrev = () => {
      let prev = CacheService.getPrev();
      let path = window.location.pathname;
      if (prev && prev.indexOf("/shipment") > -1) {
         history.push(prev);
      } else if (path.indexOf("/new-shipment-detail") > -1) {
         history.push("/shipment");
      } else if (path.indexOf("/shipment-detail") > -1) {
         history.push("/shipment");
      } else if (path.indexOf("/shipment/payment") > -1) {
         history.push("/shipment");
      } else {
         history.goBack();
      }
   };

   return (
      <div className="shipment-steps">
         <div className="shipment-steps-prev">
            <CustomButton icon="prev" navigate transparent onClick={onSelectPrev} />
         </div>
         <Steps className="shipment-steps-step">
            <Step status={bookingStatus && paymentStatus ? "finish" : bookingStatus ? "process" : "wait"} title="Booking" icon={<FiPackage />} />
            <Step status={paymentStatus && deliveryInProgressStatus ? "finish" : paymentStatus ? "process" : "wait"} title="Pembayaran" icon={<FaRegMoneyBillAlt />} />
            <Step status={deliveryInProgressStatus && deliveryCompletedStatus ? "finish" : deliveryInProgressStatus ? "process" : "wait"} title="Pengiriman" icon={<RiTruckLine />} />
            <Step status={deliveryCompletedStatus && deliveryConfirmedStatus ? "finish" : deliveryCompletedStatus ? "process" : "wait"} title="Sampai Tujuan" icon={<AiOutlineHome />} />
            <Step status={deliveryConfirmedStatus ? "finish" : "wait"} title="Konfirmasi" icon={<AiOutlineCheckSquare />} />
         </Steps>
      </div>
   );
}

export default BookingSteps;
