import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Select } from "antd";

import { ModalContext, ModalContextInterface } from "../../../hooks/ModalContext";

import { CustomButton, InputSearch, SectionIcon, PageView } from "../../common";

import { CacheService, CarrierService, ErrorService } from "../../../service";

import * as SearchHelper from "../../../helper/SearchHelper";

import { Provider, Carrier } from "../../../model";

import { getEmptyCarriers } from "../../../reference/Carrier";
import * as Master from "../../../reference/Master";

import "./Carriers.css";

const { Option } = Select;
let modalContext: ModalContextInterface;

export default function RegisterCarrier(props: any) {
   const history = useHistory();

   const pageSize = 10;
   const [init, setInit] = useState(false);
   const [carriers, setCarriers] = useState(getEmptyCarriers(pageSize));
   const [searchCarrier, setSearchCarrier] = useState("");
   const [searchProvider, setSearchProvider] = useState("");
   const [status, setStatus] = useState("");
   const [page, setPage] = React.useState(CacheService.getPage());
   const [pageCount, setPageCount] = React.useState(CacheService.getPageCount());
   const user = CacheService.getUser();

   useEffect(() => {
      props.setShowBackNavigation(false);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      setCarriers(getEmptyCarriers(pageSize));
      SearchHelper.searchInputs([searchCarrier, searchProvider], () => {
         let provider = new Provider();
         provider.name = searchProvider;
         let carrier = new Carrier();
         carrier.name = searchCarrier;
         carrier.provider = provider;
         carrier.status = status;

         CarrierService.searchCarrier(carrier, pageSize, page)
            .then((result) => {
               setCarriers(result.carriers);
               if (page === 1) {
                  setPageCount(result.pageCount);
               }
            })
            .catch((error) => {
               ErrorService.handleError(error, history, modalContext);
            });
      });
   }, [searchCarrier, searchProvider, status, page, history]);

   useEffect(() => {
      setPage(1);
   }, [searchCarrier, searchProvider, status]);

   const onPressNewCarrier = (shipment: Carrier) => {
      history.push("/admin/carrier/add");
   };

   const onPressMore = (carrier: Carrier) => {
      CacheService.setCarrier(carrier);
      history.push(`/admin/carrier/update/${carrier.gid}`);
   };

   const onChangeStatus = (value: any) => {
      setStatus(value);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            modalContext = context;
            return (
               <div className="carriers">
                  <div className="carriers-header regular-bottom-border">
                     <div className="carriers-header-search">
                        <div className="carriers-header-search-section">
                           <SectionIcon truck className="carriers-header-search-icon" />
                           <div className="carriers-header-search-input">
                              <InputSearch placeholder="Cari Kendaraan" value={searchCarrier} setValue={setSearchCarrier} />
                           </div>
                        </div>
                        {user.isAdmin && (
                           <div className="carriers-header-search-section">
                              <SectionIcon building className="carriers-header-search-icon" />
                              <div className="carriers-header-search-input">
                                 <InputSearch placeholder="Cari Penyedia Jasa" value={searchProvider} setValue={setSearchProvider} />
                              </div>
                           </div>
                        )}
                        {user.isAdmin && (
                           <div className="carriers-header-search-section">
                              <SectionIcon option className="carriers-header-search-icon" />
                              <div className="carriers-header-search-input">
                                 <Select className="carriers-header-search-select" placeholder="Status" onChange={onChangeStatus} allowClear>
                                    <Option value={Master.STATUS_PENDING}>Pending</Option>
                                    <Option value={Master.STATUS_ACTIVE}>Aktif</Option>
                                    <Option value={Master.STATUS_INACTIVE}>Tidak Aktif</Option>
                                 </Select>
                              </div>
                           </div>
                        )}
                     </div>
                     {user.provider && (
                        <div className="carriers-header-button">
                           <CustomButton icon="truck" text="Tambah Kendaraan" onClick={onPressNewCarrier} style={{ width: 190 }} />
                        </div>
                     )}
                  </div>
                  <div id="search-result" className={"search-result " + (user.isAdmin ? (props.showBottomNavigation ? "search-result-admin" : "search-result-admin-no-nav") : props.showBottomNavigation ? "search-result" : "search-result-no-nav")}>
                     <PageView
                        carriers={carriers}
                        onPressMore={(carrier: Carrier) => {
                           //console.log('carrier: ', JSON.stringify(carrier, null, 2));
                           onPressMore(carrier);
                        }}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        emptyText={!searchCarrier && !searchProvider && !status && "Belum terdapat kendaraan angkutan"}
                     />
                  </div>
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}
