import * as Master from "../reference/Master";
import { Provider, Customer, Transporter, Image, Shipment } from ".";
import ProviderUser from "./ProviderUser";

export default class User {
   id?: number;
   gid?: string;
   refId?: string;
   name?: string;
   email?: string;
   isEmailVerified?: string;
   mobile?: string;
   role?: string;
   provider?: Provider;
   providerUser?: ProviderUser;
   customer?: Customer;
   transporter?: Transporter;
   isAdmin?: boolean;
   profileImg?: Image;
   profileImgUrl?: string;
   status: string;

   constructor();
   constructor(id?: number, name?: string, email?: string, mobile?: string, role?: string, provider?: Provider);
   constructor(id?: number, name?: string, email?: string, mobile?: string, role?: string, provider?: Provider) {
      this.id = id;
      this.name = name;
      this.email = email;
      this.mobile = mobile;
      this.role = role;
      this.provider = provider;
   }
}

export const isAdmin = (user: User) => {
   if (!user) {
      return false;
   }
   return user.role === Master.USER_ROLE_ADMIN;
};

export const isCustomer = (user: User) => {
   if (!user) {
      return false;
   }
   return user.role === Master.USER_ROLE_CUSTOMER;
};

export const isProvider = (user: User) => {
   //console.log('user: ', JSON.stringify(user, null, 2));
   if (!user) {
      return false;
   }
   return user.role === Master.USER_ROLE_PROVIDER;
};

export const isTransporter = (user: User) => {
   if (!user) {
      return false;
   }
   return user.role === Master.USER_ROLE_TRANSPORTER;
};

export const isShipmentTransporter = (user: User, shipment: Shipment) => {
   if (!user) {
      return false;
   }
   return user.transporter && user.transporter.gid === shipment.transporter?.gid;
};
