import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ReactGA from "react-ga";

import mixpanel from "mixpanel-browser";

import * as Config from "./Config";

import { CacheService } from "./service";

export default function Tracking(props: any) {
   const location = useLocation();

   useEffect(() => {
      const user = CacheService.getUser();
      const curPath = window.location.pathname;
      let trackPath = curPath;

      if (curPath) {
         if (curPath.includes("/select-transport-type")) {
            trackPath = "/select-transport-type";
         } else if (curPath.includes("/new-shipment")) {
            trackPath = "/new-shipment";
         } else if (curPath.includes("/change-transport-type")) {
            trackPath = "/change-transport-type";
         }
      }

      if (curPath !== CacheService.getLastPath() && (user === null || (user && user.customer))) {
         // console.log("user: ", JSON.stringify(user, null, 2));
         console.log("path: ", curPath);

         ReactGA.initialize(Config.GA_REF); // Dev
         ReactGA.pageview(curPath);
         CacheService.setLastPath(curPath);

         mixpanel.init(Config.MIXPANEL_TOKEN, { debug: true });
         mixpanel.track("Path " + trackPath);
      }
   }, [location]);

   return <div></div>;
}
