import React, { useState, useEffect } from "react";

import { ImageView, CustomButton } from ".";

import Skeleton from "react-loading-skeleton";

import { Service } from "../../model";

import { transportTypeSet } from "../../reference/TransportType";
import * as Master from "../../reference/Master";

import * as FormatHelper from "../../helper/FormatHelper";

import { CacheService } from "../../service";

import * as ReferenceHelper from "../../helper/ReferenceHelper";

import "./ServiceView.css";

export function ShipmentView(props: any) {
   const item: Service = props.service;
   const [priceInfo, setPriceInfo] = useState("");

   const onPressMore = () => {
      props.onPressMore(item);
   };

   const status = ReferenceHelper.getServiceVerificationStatusDesc(item.verificationStatus);

   useEffect(() => {
      setPriceInfo("");
      if (item.serviceTypeCode === Master.SERVICE_TYPE_TRANSPORT) {
         let info = FormatHelper.formatCurrency(item.pricePerKmLong) + " s.d. " + FormatHelper.formatCurrency(item.pricePerKmShort) + " / " + item.priceUom;
         setPriceInfo(info);
      } else if (item.serviceTypeCode === Master.SERVICE_TYPE_ADDON) {
         let info =
            (item.priceScheme === Master.SERVICE_PRICE_SCHEME_WEIGHT ? FormatHelper.formatCurrency(item.pricePerUnit) : FormatHelper.formatCurrency(item.pricePerKmLong) + " s.d. " + FormatHelper.formatCurrency(item.pricePerKmShort)) +
            " / " +
            item.capacityKg / 1000 +
            " Ton";
         setPriceInfo(info);
      }
   }, [item]);

   return (
      <div className="service-view-item regular-bottom-border">
         <div className="service-view-content">
            <div>
               <ImageView
                  className="service-view-transport-type-image"
                  url={item.serviceTypeCode === Master.SERVICE_TYPE_TRANSPORT ? "/images" + transportTypeSet.get(item.serviceCode)?.image : item.serviceTypeCode === Master.SERVICE_TYPE_ADDON ? "/images/misc/worker.jpg" : null}
                  profile
                  alt="transport-type"
               />
            </div>
            <div className="service-view-pic">
               <div className="regular-bold-font">{item.serviceDesc || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
               <div className="regular-font">{priceInfo ? priceInfo : <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
               <div className="service-view-detail">
                  {CacheService.getUser().isAdmin && item.provider?.name && <span className="service-view-pvd regular-white-font">{item.provider?.name}</span>}
                  {status && <span className={`service-view-status ${item.verificationStatus} regular-white-font`}>{status}</span>}
               </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.serviceTypeCode} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default ShipmentView;
