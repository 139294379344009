import * as React from "react";
import { Image } from "../../model";
import * as Config from "../../Config";

import CustomButton from "./CustomButton";

import "./ImagesPanel.css";

const margin = 3;
const heightRatio = 1;

export default function ImagesPanel(props: any) {
   const isSignImg = (image: Image) => {
      return image.name.indexOf("Tanda Tangan") !== -1;
   };

   return (
      <div className="images-panel" style={{ width: props.width, ...props.style }}>
         {props.images &&
            props.images.map((image: Image, key: number) => (
               <div key={key} onClick={() => (props.onPress ? props.onPress(key) : null)}>
                  <img
                     alt={"images" + key}
                     src={`${Config.STORAGE_API}${image.url}`}
                     className={isSignImg(image) ? "image-sign" : "image"}
                     style={{
                        // Horizontal padding: 10, Between images padding: (3 * props.size)
                        width: (props.width - 10) / props.size - margin,
                        height: ((props.width - 10) / props.size - margin) * heightRatio,
                     }}
                  />
                  {props.setImages && (
                     <CustomButton
                        icon={"remove"}
                        className="delete-button"
                        style={{
                           top: (props.width / props.size) * heightRatio - 28,
                           left: props.width / props.size - 25 - margin,
                        }}
                        onClick={() => {
                           let arr = [...props.images];
                           const deleted = arr.splice(key, 1);
                           props.setImages(deleted);
                        }}
                     />
                  )}
               </div>
            ))}
      </div>
   );
}
