import { transportTypes } from "./TransportType";
import { Service } from "../model";
import { ServiceOption } from "../model/Service";
import * as Master from "./Master";

export const newService: Service = {
   provider: undefined,
   serviceTypeCode: "",
   serviceTypeDesc: "",
   serviceCode: "",
   serviceDesc: "",
   priceScheme: "",
   pricePerUnit: 0,
   priceUom: "",
   pricePerKmShort: 0,
   maxKmShort: 0,
   pricePerKmMed: 0,
   maxKmMed: 0,
   pricePerKmLong: 0,
   minKm: 0,
   minPrice: 0,
   isSpecialPrice: false,
   pickupPricePerKm: 0,
   pickupFreeKmDistance: 0,
   pickupCoverageKm: 50,
   returnPriceRatio: 100,
   coverageKm: 0,
   transitPrice: 0,
   overCapacitySurcharge: 1,
   capacityKg: 0,
   maxLoadCapacity: 0,
   verificationStatus: "",
   status: "",
};

export const getEmptyServices = (count: number) => {
   const services = Array<Service>();
   for (let i = 0; i < count; i++) {
      services.push(newService);
   }
   return services;
};

export const getServiceOptionSet = (): Map<String, ServiceOption> => {
   const serviceOptionSet = new Map<String, ServiceOption>();
   transportTypes.forEach((transportType) => {
      serviceOptionSet.set(transportType.code, {
         code: transportType.code,
         desc: transportType.desc,
         type: Master.SERVICE_TYPE_TRANSPORT,
         uom: Master.UOM_KM,
      });
   });

   serviceOptionSet.set("AOTKBM", {
      code: "AOTKBM",
      desc: "Layanan Tenaga Kerja Bongkar Muat",
      type: Master.SERVICE_TYPE_ADDON,
      uom: Master.UOM_UNIT,
   });

   return serviceOptionSet;
};
