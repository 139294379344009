import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ModalContext /*, ModalContextInterface */ } from "../../hooks/ModalContext";
import { FadeView } from "../common";
import { ComposedChart, CartesianGrid, XAxis, YAxis, Bar, Legend, Tooltip } from "recharts";
import { AnalyticService } from "../../service";
import * as FormatHelper from "../../helper/FormatHelper";
import { InputSelect, InputDate } from "../common";
import moment from "moment";
import * as Color from "../../constants/Color";

import "./BarAnalytic.css";

// let modalContext: ModalContextInterface;

function BarAnalytic(props: any) {
   const [init, setInit] = useState(false);
   const { control, errors } = useForm();
   const [defaultStart, setDefaultStart] = useState<Date>(new Date());
   const [defaultEnd, setDefaultEnd] = useState<Date>(new Date());
   const [period, setPeriod] = useState("");
   const [output, setOutput] = useState("");
   const [breakdown, setBreakdown] = useState("");
   const [input, setInput] = useState("");
   const [startDate, setStartDate] = useState<Date>(new Date());
   const [endDate, setEndDate] = useState<Date>(new Date());
   const [dataKPI, setDataKPI] = useState<any[]>();
   const [dataTemp, setDataTemp] = useState<any[]>();
   const [dataGT, setDataGT] = useState<any[]>();
   const [dataShow, setDataShow] = useState<any[]>();
   const [dataGO, setDataGO] = useState<any[]>();
   const [dataPO, setDataPO] = useState<any[]>();
   const [totalOutput, setTotalOutput] = useState(0);
   const [growthOutput, setGrowthOutput] = useState(0);
   const [barBreakdownType, setBarBreakdownType] = useState<string[]>();
   const [isVolume, setIsVolume] = useState(false);
   const [isStartDate, setIsStartDate] = useState(false);
   const [isEndDate, setIsEndDate] = useState(false);

   const isCreate = true;
   const outputdefault = "plcd_order_val";
   const breakdowndefault = "transporttype";
   const inputdefault = "amt_ship_disc";
   const perioddefault = "daily";

   const outputoptions = [
      { value: "comp_order_val", label: "Completed Order Value" },
      { value: "comp_order_vol", label: "Completed Order Volume" },
      { value: "plcd_order_val", label: "Placed Order Value" },
      { value: "plcd_order_vol", label: "Placed Order Volume" },
      { value: "cncl_order_val", label: "Cancelled Order Value" },
      { value: "cncl_order_vol", label: "Cancelled Order Volume" },
   ];

   // const breakdownoptions = [
   //   {value:"transporttype", label:"By Transport Type"},{value:"vehicletype", label:"By Vehicle Type"},
   //   {value:"shippertype", label:"By Shipper Type (B2B/B2C)"},{value:"origin", label:"By Origin (Province)"},
   //   {value:"destination", label:"By Destination (Province)"},{value:"loadcategory", label:"By Load Category"}];

   const breakdownoptions = [
      { value: "transporttype", label: "By Transport Type" },
      { value: "vehicletype", label: "By Vehicle Type" },
   ];

   // const inputoptions = [
   //   {value:"amt_ship_disc", label:"$ Shipper Discount"},{value:"per_ship_disc", label:"% Shipper Discount"},
   //   {value:"amt_trns_inc", label:"$ Transport Incentive"},{value:"per_trns_inc", label:"% Transport Incentive"},
   //   {value:"amt_mrkt_cost", label:"$ Marketing Cost"},{value:"per_mrkt_cost", label:"% Marketing Cost"},
   //   {value:"amt_sales_cost", label:"$ Sales Cost"},{value:"per_sales_cost", label:"% Sales Cost"}];

   const periodoptions = [
      { value: "hourly", label: "Hourly" },
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "monthly", label: "Monthly" },
      { value: "quarterly", label: "Quarterly" },
      { value: "semiannual", label: "Semi-Annually" },
      { value: "annually", label: "Annually" },
   ];

   const COLORS = [Color.red, Color.yellow, Color.green, Color.blue, "#ff7f7d", "#ffff85", "#90EE90", "#ADD8E6"];

   // useEffect(() => {
   //   console.log("OUTPUT: ", output);
   //   console.log("BREAKDOWN: ", breakdown);
   //   console.log("PERIOD: ", period);
   //   console.log("STARTDATE: ", startDate);
   //   console.log("ENDDATE: ", endDate);
   //   console.log("ISSTARTDATE: ", isStartDate);
   //   console.log("ISENDDDATE: ", isEndDate);
   // },[output, breakdown, period, startDate, endDate, isStartDate, isEndDate]);

   useEffect(() => {
      //props.setShowBottomNavigation(true);
      props.setShowBackNavigation(true);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(true);
   }, [props]);

   useEffect(() => {
      const enddefault = new Date();
      enddefault.setDate(enddefault.getDate());
      const startdefault = new Date();
      const month = enddefault.getMonth();
      startdefault.setMonth(startdefault.getMonth() - 1);
      while (startdefault.getMonth() === month) {
         startdefault.setDate(startdefault.getDate() - 1);
      }

      startdefault.setHours(0);
      startdefault.setMinutes(0);
      startdefault.setSeconds(0);

      enddefault.setHours(23);
      enddefault.setMinutes(59);
      enddefault.setSeconds(59);

      setDefaultStart(startdefault);
      setDefaultEnd(enddefault);

      AnalyticService.GetKPI(outputdefault, breakdowndefault, inputdefault, perioddefault, startdefault, enddefault).then((result) => {
         setDataKPI(result);
         // console.log("RES: ", result);
      });
   }, []);

   useEffect(() => {
      FormatDataKPI(dataKPI);
      SetBarBreakdown(dataKPI);
      // console.log("KPI DATA ", dataKPI);
   }, [dataKPI]);

   useEffect(() => {
      console.log("Data TEMP: ", dataTemp);
      if (period !== "") {
         if (isStartDate === true && isEndDate === true) AddEmptyDate(dataTemp, dataGT, period, startDate, endDate);
      } else {
         AddEmptyDate(dataTemp, dataGT, perioddefault, defaultStart, defaultEnd);
      }
   }, [dataTemp]);

   useEffect(() => {
      TotalGrowth(dataGO);
   }, [dataGO]);

   useEffect(() => {
      console.log("DATA GT ", dataGT);
      console.log("DATA SHOW ", dataShow);
      console.log("DATA GO ", dataGO);
      console.log("DATA PO: ", dataPO);
   }, [dataShow, dataGO, dataPO, dataGT]);

   useEffect(() => {
      var startstring = Date.parse(startDate.toString());
      var endstring = Date.parse(endDate.toString());
      var start = new Date(startstring);
      var end = new Date(endstring);

      var datestart = new Date(start.getTime());
      datestart.setHours(7, 0, 0, 0);
      var dateend = new Date(end.getTime());
      dateend.setHours(30, 59, 59, 999);

      setInput(inputdefault);

      if (output !== "" && breakdown !== "" && input !== "" && period !== "" && isStartDate !== false && isEndDate !== false) {
         AnalyticService.GetKPI(output, breakdown, input, period, datestart, dateend).then((result) => {
            setDataKPI([...result]);
            console.log("RES CALL: ", result);
         });
      }
   }, [output, breakdown, input, period, startDate, endDate, isStartDate, isEndDate]);

   useEffect(() => {
      if (output === "comp_order_vol" || output === "plcd_order_vol" || output === "cncl_order_vol") {
         setIsVolume(true);
      } else {
         setIsVolume(false);
      }
   }, [output]);

   function FormatDataKPI(res: any) {
      var tempReqDate = "00-00-0000";
      var count: Array<number> = [];
      var KPIobj: Array<any> = [];
      var GrowthTotalobj: Array<any> = [];
      var tempTotal = 0;

      tempReqDate = "00-00-0000";
      for (let k = 0; k < res?.length; k++) {
         var KPIitem: any = {};
         var GrowthTotalitem: any = {};

         if (res[k].requestDate === tempReqDate) {
            KPIitem = KPIobj[k - 1];
            KPIitem[res[k].name] = res[k].value;
            tempTotal = tempTotal + res[k].value;

            GrowthTotalitem["requestDate"] = res[k].requestDate;

            count.push(k - 1);
         } else {
            KPIitem["requestDate"] = res[k].requestDate;
            KPIitem[res[k].name] = res[k].value;
            tempTotal = res[k].value;

            GrowthTotalitem["requestDate"] = res[k].requestDate;

            tempReqDate = res[k].requestDate;
         }

         KPIitem["Total"] = tempTotal;
         GrowthTotalitem["Total"] = tempTotal;

         // console.log("LOOP KPI ITEM, ", KPIitem);
         KPIobj.push(KPIitem);
         GrowthTotalobj.push(GrowthTotalitem);
      }

      // console.log("ARRAY KPI FIX: " , KPIobj);
      // console.log("COUNT2: ", count);

      for (let l = count.length - 1; l >= 0; l--) {
         KPIobj.splice(count[l], 1);
         GrowthTotalobj.splice(count[l], 1);
      }

      setDataTemp(KPIobj);
      setDataGT(GrowthTotalobj);
   }

   function AddEmptyDate(data: any, growthtotaldata: any, period: string, startDate: Date, endDate: Date) {
      var startstring = Date.parse(startDate.toString());
      var endstring = Date.parse(endDate.toString());
      var start = new Date(startstring);
      var end = new Date(endstring);
      const startutc = Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
      const endutc = Date.UTC(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate());
      let arrTemp: Array<any> = [];
      let arrGO: Array<any> = [];
      let diffTime = Math.abs(endutc - startutc);
      const day = 60 * 60 * 24 * 1000;
      const week = day * 7;
      let diffDays = 0;
      let diffWeeks = 0;
      let diffMonths = 0;
      let diffQuarters = 0;
      let diffSemesters = 0;
      let diffYears = 0;

      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const quarter = ["Q1", "Q2", "Q3", "Q4"];
      const semester = ["H1", "H2"];

      switch (period) {
         case "hourly":
            setDataShow(data);
            setDataGO(growthtotaldata);
            break;
         case "daily":
            diffDays = Math.ceil(diffTime / day) + 1;

            for (let i = 0; i < diffDays; i++) {
               let itemTemp: any = {};
               let tempDate = new Date(start.getTime() + day * i);

               itemTemp["requestDate"] = tempDate.toISOString().substring(0, 10);
               itemTemp["Total"] = 0;

               arrTemp.push(itemTemp);
               arrGO.push(itemTemp);
            }

            for (let j = 0; j < arrTemp.length; j++) {
               for (let k = 0; k < data?.length; k++) {
                  if (arrTemp[j].requestDate === data[k].requestDate) {
                     arrTemp[j] = data[k];
                     arrGO[j] = growthtotaldata[k];
                  }
               }
            }

            setDataShow(arrTemp);
            setDataGO(arrGO);
            break;
         case "weekly":
            diffWeeks = Math.ceil(diffTime / week);
            var startWeek = moment(start).isoWeek();
            let tempWYear = start.getFullYear();
            let weekcount = 0;

            for (let i = 0; i < diffWeeks; i++) {
               let itemTemp: any = {};
               let weeknum = i - weekcount;
               if ((startWeek + weeknum) % 52 === 1 && startWeek + weeknum !== 1 && startWeek + weeknum !== 1) {
                  weekcount = i;
                  startWeek = 1;
                  tempWYear = tempWYear + 1;
               }
               weeknum = i - weekcount;

               let tempDate = "W" + (startWeek + weeknum) + " '" + tempWYear.toString().substring(2, 4);

               itemTemp["requestDate"] = tempDate;
               itemTemp["Total"] = 0;

               arrTemp.push(itemTemp);
               arrGO.push(itemTemp);
            }

            for (let j = 0; j < arrTemp.length; j++) {
               for (let k = 0; k < data?.length; k++) {
                  if (arrTemp[j].requestDate === data[k].requestDate) {
                     arrTemp[j] = data[k];
                     arrGO[j] = growthtotaldata[k];
                  }
               }
            }

            setDataShow(arrTemp);
            setDataGO(arrGO);
            break;
         case "monthly":
            diffMonths = getMonthsBetween(start, end, true);
            let startmonth = start.getMonth();
            let tempMYear = start.getFullYear();
            let monthcount = 0;

            console.log("DIFF MONTHS: ", diffMonths);

            for (let i = 0; i < diffMonths; i++) {
               let itemTemp: any = {};
               let monthnum = i - monthcount;
               if ((startmonth + monthnum) % 11 === 1 && startmonth + monthnum !== 1) {
                  monthcount = i;
                  startmonth = 0;
                  tempMYear = tempMYear + 1;
               }
               monthnum = i - monthcount;

               itemTemp["requestDate"] = month[startmonth + monthnum] + " '" + tempMYear.toString().substring(2, 4);
               itemTemp["Total"] = 0;

               arrTemp.push(itemTemp);
               arrGO.push(itemTemp);
            }

            for (let j = 0; j < arrTemp.length; j++) {
               for (let k = 0; k < data?.length; k++) {
                  if (arrTemp[j].requestDate === data[k].requestDate) {
                     arrTemp[j] = data[k];
                     arrGO[j] = growthtotaldata[k];
                  }
               }
            }

            setDataShow(arrTemp);
            setDataGO(arrGO);
            break;
         case "quarterly":
            diffMonths = getMonthsBetween(start, end, false);
            diffQuarters = Math.floor(diffMonths / 3) + 1;
            let startQuarter = Math.floor(start.getMonth() / 3);
            let tempQYear = start.getFullYear();
            let quartercount = 0;

            for (let i = 0; i < diffQuarters; i++) {
               let itemTemp: any = {};
               let quarternum = i - quartercount;
               if ((startQuarter + quarternum) % 3 === 1 && startQuarter + quarternum !== 1) {
                  quartercount = i;
                  startQuarter = 0;
                  tempQYear = tempQYear + 1;
               }
               quarternum = i - quartercount;

               itemTemp["requestDate"] = quarter[startQuarter + quarternum] + " '" + tempQYear.toString().substring(2, 4);
               itemTemp["Total"] = 0;

               arrTemp.push(itemTemp);
               arrGO.push(itemTemp);
            }

            for (let j = 0; j < arrTemp.length; j++) {
               for (let k = 0; k < data?.length; k++) {
                  if (arrTemp[j].requestDate === data[k].requestDate) {
                     arrTemp[j] = data[k];
                     arrGO[j] = growthtotaldata[k];
                  }
               }
            }

            setDataShow(arrTemp);
            setDataGO(arrGO);
            break;
         case "semiannual":
            diffMonths = getMonthsBetween(start, end, false);
            diffSemesters = Math.floor(diffMonths / 6) + 1;
            let startsemester = Math.floor(start.getMonth() / 6);
            let tempHYear = start.getFullYear();
            let semestercount = 0;

            for (let i = 0; i < diffSemesters; i++) {
               let itemTemp: any = {};
               let semesternum = i - semestercount;
               if (Math.floor(startsemester + semesternum) / 2 === 1 && startsemester + semesternum !== 1) {
                  semestercount = i;
                  startsemester = 0;
                  tempHYear = tempHYear + 1;
               }
               semesternum = i - semestercount;

               itemTemp["requestDate"] = semester[startsemester + semesternum] + " '" + tempHYear.toString().substring(2, 4);
               itemTemp["Total"] = 0;

               arrTemp.push(itemTemp);
               arrGO.push(itemTemp);
            }

            for (let j = 0; j < arrTemp.length; j++) {
               for (let k = 0; k < data?.length; k++) {
                  if (arrTemp[j].requestDate === data[k].requestDate) {
                     arrTemp[j] = data[k];
                     arrGO[j] = growthtotaldata[k];
                  }
               }
            }

            setDataShow(arrTemp);
            setDataGO(arrGO);
            break;
         case "annually":
            diffYears = end.getFullYear() - start.getFullYear() + 1;
            let startYear = start.getFullYear();

            for (let i = 0; i < diffYears; i++) {
               let itemTemp: any = {};
               let tempAYear = startYear + i;

               itemTemp["requestDate"] = "Y" + tempAYear.toString();
               itemTemp["Total"] = 0;

               arrTemp.push(itemTemp);
               arrGO.push(itemTemp);
            }

            for (let j = 0; j < arrTemp.length; j++) {
               for (let k = 0; k < data?.length; k++) {
                  if (arrTemp[j].requestDate === data[k].requestDate) {
                     arrTemp[j] = data[k];
                     arrGO[j] = growthtotaldata[k];
                  }
               }
            }

            setDataShow(arrTemp);
            setDataGO(arrGO);
            break;
      }
   }

   function getMonthsBetween(date1: Date, date2: Date, roundUpFractionalMonths: boolean) {
      //Months will be calculated between start and end dates.
      //Make sure start date is less than end date.
      //But remember if the difference should be negative.
      var startDate = date1;
      var endDate = date2;
      var inverse = false;
      if (date1 > date2) {
         startDate = date2;
         endDate = date1;
         inverse = true;
      }

      //Calculate the differences between the start and end dates
      var yearsDifference = endDate.getFullYear() - startDate.getFullYear();
      var monthsDifference = endDate.getMonth() - startDate.getMonth();
      var daysDifference = endDate.getDate() - startDate.getDate();

      var monthCorrection = 0;
      //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
      //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
      if (roundUpFractionalMonths === true && daysDifference > 0) {
         monthCorrection = 1;
      }
      //If the day difference between the 2 months is negative, the last month is not a whole month.
      else if (roundUpFractionalMonths !== true && daysDifference < 0) {
         monthCorrection = -1;
      }

      return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection);
   }

   function TotalGrowth(data: any) {
      let valueTotal = 0;
      let endvalue = 0;
      let startvalue = 0;
      let exponent = 0;
      let periodvalue = 0;
      let arrPO: Array<any> = [];

      for (let i = 0; i < data?.length; i++) {
         let itemPO: any = {};

         valueTotal = valueTotal + data[i].Total;

         if (i > 0) {
            if (data[i].Total === 0) {
               periodvalue = 0;
            } else {
               periodvalue = (data[i].Total / data[i - 1].Total - 1) * 100;
            }

            itemPO["requestDate"] = data[i].requestDate;
            itemPO["nilai"] = periodvalue;
            arrPO.push(itemPO);
         }

         if (i === 0) {
            startvalue = data[i].Total;
         } else if (i === data?.length - 1) {
            endvalue = data[i].Total;
            exponent = i + 1;
         } else {
            continue;
         }
      }

      let valueTemp = endvalue / startvalue;
      let valueGrowth = (Math.pow(valueTemp, 1 / exponent) - 1) * 100;

      setTotalOutput(valueTotal);
      setGrowthOutput(valueGrowth);
      setDataPO(arrPO);
   }

   function SetBarBreakdown(data: any) {
      let arrBreakdown1: string[] = [];
      let arrBreakdown2: string[] = [];

      for (let j = 0; j < data?.length; j++) {
         arrBreakdown1.push(data[j].name);
      }
      arrBreakdown2 = [...Array.from(new Set(arrBreakdown1))];
      setBarBreakdownType(arrBreakdown2);
   }

   function NumberFormat(labelValue: number) {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e9
         ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
         : // Six Zeroes for Millions
         Math.abs(Number(labelValue)) >= 1.0e6
         ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
         : // Three Zeroes for Thousands
         Math.abs(Number(labelValue)) >= 1.0e3
         ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
         : Math.abs(Number(labelValue));
   }

   function CustomBarLabel(props: any) {
      const { x, y, width, value } = props;

      if (value !== 0) {
         return <text x={x + width / 2} y={y} textAnchor="middle" dy={-6}>{`${NumberFormat(value.toPrecision(2))}`}</text>;
      } else {
         return <text x={x + width / 2} y={y} textAnchor="middle" dy={-6}></text>;
      }
   }

   function OutputTable(props: any) {
      let rows = [];
      let weeks = props?.length / 7;
      let days = 7;

      for (var i = 0; i < weeks; i++) {
         let rowID = `row${i}`;
         let cell = [];

         for (var idx = 0; idx < days; idx++) {
            let cellID = `cell${i}-${idx}`;

            if (i * days + idx < props?.length) {
               cell.push(
                  <td className="periodcell" key={cellID} id={cellID}>
                     <b>{props[i * days + idx].requestDate}:</b> <br /> {props[i * days + idx].nilai.toPrecision(3)}%
                  </td>
               );
            }
         }
         rows.push(
            <tr className="periodrows" key={i} id={rowID}>
               {cell}
            </tr>
         );
      }

      return rows;
   }

   function DatePickerChanged(e: any, type: string) {
      if (type === "start") {
         setStartDate(e);
         setIsStartDate(true);
      } else if (type === "end") {
         setEndDate(e);
         setIsEndDate(true);
      }
   }

   return (
      <ModalContext.Consumer>
         {(context) => {
            return (
               <FadeView show={true} duration="0" className="bar-analytic-fadeview">
                  <div className="bar-analytic-container">
                     <div className="bar-analytic-content">
                        <h2>Key Performance Indicators</h2>
                        <form>
                           <div className="kpi-wrapper">
                              <div className="parameter-wrapper">
                                 <div className="parameter-title">
                                    <h3>Chart Metric</h3>
                                 </div>
                                 <div className="parameter-metric">
                                    <div className="parameter-output">
                                       {/* <InputSelect loading={!isCreate} loadingRef={output} name="output" placeholder="Output Metric" control={control} error={errors.output} options={outputoptions} /> */}
                                       <InputSelect loading={!isCreate} loadingRef={output} name="output" placeholder="Output Metric" control={control} error={errors.output} options={outputoptions} onChange={(e: any) => setOutput(e[0])} />
                                    </div>
                                    <div className="parameter-breakdown">
                                       <InputSelect
                                          loading={!isCreate}
                                          loadingRef={breakdown}
                                          name="breakdown"
                                          placeholder="Output Metric Breakdown"
                                          control={control}
                                          error={errors.breakdown}
                                          options={breakdownoptions}
                                          onChange={(e: any) => setBreakdown(e[0])}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className="chart-wrapper">
                                 <div className="chart-parameter">
                                    <div className="chart-parameter-period">
                                       <InputSelect loading={!isCreate} loadingRef={period} name="period" placeholder="Period" control={control} error={errors.period} options={periodoptions} onChange={(e: any) => setPeriod(e[0])} />
                                    </div>
                                    <div className="chart-parameter-startdate">
                                       <InputDate loading={!isCreate} loadingRef={startDate} name="startDate" placeholder="Tanggal Mulai" control={control} error={errors.startDate} onChange={(e: any) => DatePickerChanged(e[0], "start")} />
                                    </div>
                                    <div className="chart-parameter-enddate">
                                       <InputDate loading={!isCreate} loadingRef={endDate} name="endDate" placeholder="Tanggal Berakhir" control={control} error={errors.endDate} onChange={(e: any) => DatePickerChanged(e[0], "end")} />
                                    </div>
                                 </div>
                                 <div className="chart-display">
                                    <div className="chart-display-recharts">
                                       {/* <ResponsiveContainer width={700} height={window.innerWidth < 1000 ? 250 : 450}> */}
                                       <ComposedChart data={dataShow} margin={{ top: 30, right: 5, left: 25, bottom: 5 }} width={700} height={window.innerWidth < 1000 ? 250 : 450}>
                                          <CartesianGrid stroke="#f5f5f5" />
                                          <XAxis dataKey="requestDate" interval={0} angle={-45} tick={{ textAnchor: "end" }} padding={{ left: 20, right: 20 }} height={100} />
                                          <YAxis tickFormatter={(value: any) => new Intl.NumberFormat("en", { notation: "compact", compactDisplay: "short" }).format(value)} />
                                          <Tooltip formatter={(value: any) => new Intl.NumberFormat("en").format(value)} />
                                          <Legend />
                                          {barBreakdownType?.map((item: any, index: number) => {
                                             if (index === barBreakdownType?.length - 1) {
                                                return <Bar dataKey={item} stackId="a" barSize={30} fill={COLORS[index % COLORS.length]} label={<CustomBarLabel />} />;
                                             } else {
                                                return <Bar dataKey={item} stackId="a" barSize={30} fill={COLORS[index % COLORS.length]} />;
                                             }
                                          })}
                                       </ComposedChart>
                                       {/* </ResponsiveContainer> */}
                                    </div>
                                    <div className="chart-display-growth">
                                       <div className="chart-display-growth-total">
                                          <div className="output-growth-total">
                                             <h4>Output Metric</h4>
                                             <table>
                                                <tbody>
                                                   <tr>
                                                      <th>Total</th>
                                                   </tr>
                                                   <tr>{isVolume ? <td>{totalOutput} shipments</td> : <td>{FormatHelper.formatCurrency(totalOutput)}</td>}</tr>
                                                   <tr>
                                                      <th>Growth</th>
                                                   </tr>
                                                   <tr>
                                                      <td>{growthOutput.toPrecision(3)}%</td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                          {/* <div className="input-growth-total">
                            <h4>Input Metric</h4>
                            <table>
                              <tbody>
                                <tr>
                                  <th>Total</th>
                                </tr>
                                <tr>
                                  <td> - </td>
                                </tr>
                                <tr>
                                  <th>Growth</th>
                                </tr>
                                <tr>
                                  <td> - </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> */}
                                       </div>
                                    </div>
                                 </div>
                                 <div className="chart-indicators">
                                    <h3>Output Metric %P/P</h3>
                                    <table className="periodtable">
                                       <tbody>{OutputTable(dataPO)}</tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default BarAnalytic;
