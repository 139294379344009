import { gql } from "@apollo/client";

import { Service } from "../model";
import { authGraphClient, initAuthClient } from "./GraphService";

class ServiceService {
   async registerService(service: Service) {
      await initAuthClient();
      //console.log("registerService:", JSON.stringify(service, null, 2));

      let g = `
         mutation {
            RegisterService(
               inputService: {
                  serviceTypeCode: "${service.serviceTypeCode}", 
                  serviceCode: "${service.serviceCode}", 
                  priceScheme: "${service.priceScheme ? service.priceScheme : ""}", 
                  pricePerUnit: ${service.pricePerUnit ? service.pricePerUnit : 0}, 
                  priceUom: "${service.priceUom}", 
                  pricePerKmShort: ${service.pricePerKmShort ? service.pricePerKmShort : 0}, 
                  maxKmShort: ${service.maxKmShort ? service.maxKmShort : 0}, 
                  pricePerKmMed: ${service.pricePerKmMed ? service.pricePerKmMed : 0}, 
                  maxKmMed: ${service.maxKmMed ? service.maxKmMed : 0},
                  pricePerKmLong: ${service.pricePerKmLong ? service.pricePerKmLong : 0},
                  minKm: ${service.minKm ? service.minKm : 0},
                  minPrice: ${service.minPrice ? service.minPrice : 0},
                  pickupPricePerKm: ${service.pickupPricePerKm ? service.pickupPricePerKm : 0},
                  pickupFreeKmDistance: ${service.pickupFreeKmDistance ? service.pickupFreeKmDistance : 0},
                  pickupCoverageKm: ${service.pickupCoverageKm ? service.pickupCoverageKm : 0},
                  returnPriceRatio: ${service.returnPriceRatio ? service.returnPriceRatio : 0},
                  coverageKm: ${service.coverageKm ? service.coverageKm : 0}, 
                  transitPrice: ${service.transitPrice ? service.transitPrice : 0},
                  overCapacitySurcharge: ${service.overCapacitySurcharge ? service.overCapacitySurcharge : 0},
                  capacityKg: ${service.capacityKg ? service.capacityKg : 0},
                  maxLoadCapacity: ${service.maxLoadCapacity ? service.maxLoadCapacity : 0},
                  verificationStatus: "${service.verificationStatus}"
                  status: "${service.status}"
               }
            )
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let serviceID = result.data.RegisterService;

      //console.log(result.data);

      return serviceID;
   }

   async updateService(service: Service) {
      await initAuthClient();
      //console.log("updateService:", JSON.stringify(service, null, 2));

      let g = `
            mutation {
               UpdateService(
                  inputService: {
                     gid:  "${service.gid}",
                     serviceTypeCode: "${service.serviceTypeCode}", 
                     serviceCode: "${service.serviceCode}", 
                     priceScheme: "${service.priceScheme ? service.priceScheme : ""}",
                     pricePerUnit: ${service.pricePerUnit ? service.pricePerUnit : 0}, 
                     priceUom: "${service.priceUom}", 
                     pricePerKmShort: ${service.pricePerKmShort ? service.pricePerKmShort : 0}, 
                     maxKmShort: ${service.maxKmShort ? service.maxKmShort : 0}, 
                     pricePerKmMed: ${service.pricePerKmMed ? service.pricePerKmMed : 0}, 
                     maxKmMed: ${service.maxKmMed ? service.maxKmMed : 0},
                     minKm: ${service.minKm ? service.minKm : 0},
                     minPrice: ${service.minPrice ? service.minPrice : 0},
                     pricePerKmLong: ${service.pricePerKmLong ? service.pricePerKmLong : 0},
                     pickupPricePerKm: ${service.pickupPricePerKm ? service.pickupPricePerKm : 0},
                     pickupFreeKmDistance: ${service.pickupFreeKmDistance ? service.pickupFreeKmDistance : 0},
                     pickupCoverageKm: ${service.pickupCoverageKm ? service.pickupCoverageKm : 0},
                     returnPriceRatio: ${service.returnPriceRatio ? service.returnPriceRatio : 0}, 
                     coverageKm: ${service.coverageKm ? service.coverageKm : 0}, 
                     transitPrice: ${service.transitPrice ? service.transitPrice : 0},
                     overCapacitySurcharge: ${service.overCapacitySurcharge ? service.overCapacitySurcharge : 0},
                     capacityKg: ${service.capacityKg ? service.capacityKg : 0},
                     maxLoadCapacity: ${service.maxLoadCapacity ? service.maxLoadCapacity : 0},
                     verificationStatus: "${service.verificationStatus}",
                     status: "${service.status}",
                  }
               )
            }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let serviceID = result.data.RegisterService;

      //console.log(result.data);

      return serviceID;
   }

   async searchService(service: Service, pageSize: number, page: number) {
      await initAuthClient();
      console.log("searchService:", JSON.stringify(service, null, 2));

      let servicePageCountGql =
         page === 1
            ? `
            GetServicesPageCount(
               serviceTypeCode: "${service.serviceTypeCode}", 
               serviceDesc: "${service.serviceDesc}",
               providerName: "${service.provider ? service.provider.name : ""}",
               status: "${service.status}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetServices(
                  serviceTypeCode: "${service.serviceTypeCode}", 
                  serviceDesc: "${service.serviceDesc}",
                  providerName: "${service.provider ? service.provider.name : ""}",
                  status: "${service.status}",
                  pageSize: ${pageSize},
                  page: ${page}) 
               {
                  id
                  gid
                  serviceTypeCode
                  serviceCode
                  serviceDesc
                  priceScheme
                  pricePerUnit
                  priceUom
                  pricePerKmShort
                  maxKmShort
                  pricePerKmMed
                  maxKmMed
                  pricePerKmLong
                  coverageKm
                  capacityKg
                  maxLoadCapacity
                  verificationStatus
                  status
                  provider {
                     name
                  }
               }
               ${servicePageCountGql}
            }`;

      //console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));
      let resp = { services: result.data.GetServices, pageCount: result.data.GetServicesPageCount };
      console.log("searchService:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getService(gid?: string) {
      await initAuthClient();
      // console.log("getService:", gid);

      let gqlReq = `
            query { 
               GetService(gid: "${gid}") {
                  id
                  gid
                  serviceTypeCode
                  serviceCode
                  priceScheme
                  pricePerUnit
                  priceUom
                  pricePerKmShort
                  maxKmShort
                  pricePerKmMed
                  maxKmMed
                  pricePerKmLong
                  minKm
                  minPrice
                  pickupPricePerKm
                  pickupFreeKmDistance
                  pickupCoverageKm
                  returnPriceRatio
                  coverageKm
                  transitPrice
                  overCapacitySurcharge
                  capacityKg
                  maxLoadCapacity
                  verificationStatus
                  status
                  provider {
                     name
                  }
               }
            }`;

      console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log("result:", JSON.stringify(result, null, 2));

      let service: Service = result ? result.data.GetService : null;

      // console.log("getService:", JSON.stringify(service, null, 2));

      return service;
   }

   async getServiceByProvider(providerGid?: string, transportTypeCode?: string) {
      await initAuthClient();
      // console.log("getServiceByProvider:", gid);

      let gqlReq = `
            query { 
               GetServiceByProvider(providerGid: "${providerGid}", transportTypeCode: "${transportTypeCode}") {
                  id
                  gid
                  serviceTypeCode
                  serviceCode
                  priceScheme
                  pricePerUnit
                  priceUom
                  pricePerKmShort
                  maxKmShort
                  pricePerKmMed
                  maxKmMed
                  pricePerKmLong
                  minKm
                  minPrice
                  pickupPricePerKm
                  pickupFreeKmDistance
                  pickupCoverageKm
                  returnPriceRatio
                  coverageKm
                  transitPrice
                  overCapacitySurcharge
                  capacityKg
                  maxLoadCapacity
                  verificationStatus
                  status
                  provider {
                     name
                  }
               }
            }`;

      // console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      // console.log("result:", JSON.stringify(result, null, 2));

      let service: Service = result ? result.data.GetServiceByProvider : null;

      // console.log("getServiceByProvider: ", JSON.stringify(service, null, 2));

      return service;
   }

   async getServiceOptions() {
      await initAuthClient();

      let gqlReq = `
            query { 
               GetServiceOptions(
                  status: ""
               )
            }`;

      //console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log("result:", JSON.stringify(result, null, 2));
      let serviceOptions = result.data.GetServiceOptions;
      //console.log("getServiceOptions:", JSON.stringify(serviceOptions, null, 2));

      return serviceOptions;
   }
}

const SrvService = new ServiceService();

export default SrvService;
