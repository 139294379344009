import * as React from "react";
import Skeleton from "react-loading-skeleton";

import { CustomButton } from ".";

import { Voucher } from "../../model";

import "./VoucherView.css";

export function VoucherView(props: any) {
   const item: Voucher = props.voucher;
   // console.log("voucher: ", JSON.stringify(item, null, 2));

   const onPressMore = () => {
      props.onPressMore(item);
   };

   return (
      <div className="voucher-view-item regular-bottom-border">
         <div className="voucher-view-content">
            <div className="voucher-view-desc">
               <div className="regular-bold-font">{item.code || <Skeleton delay={1} width={window.innerWidth < 500 ? window.innerWidth - 165 : 350} />}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.code} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
            </div>
         </div>
      </div>
   );
}

export default VoucherView;
