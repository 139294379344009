import * as Master from "../reference/Master";

export function getVerificationStatusDesc(code: string) {
   switch (code) {
      case Master.STATUS_PENDING:
         return "Pending";
      case Master.STATUS_VERIFIED:
         return "Terverifikasi";
      default:
         return code;
   }
}

export function getServiceVerificationStatusDesc(code: string | undefined) {
   if (!code) {
      return "";
   }
   switch (code) {
      case Master.STATUS_PENDING:
         return "Data Belum Lengkap";
      case Master.STATUS_PENDING_DOCUMENT:
         return "Pending Administrasi";
      case Master.STATUS_VERIFIED:
         return "Terverifikasi";
      default:
         return code;
   }
}

export function getShipmentStatusDesc(code: string) {
   switch (code) {
      case Master.SHIPMENT_STATUS_NEW_ORDER:
         return "Menunggu Konfirmasi";
      case Master.SHIPMENT_STATUS_PENDING_PAYMENT:
         return "Menunggu Pembayaran";
      case Master.SHIPMENT_STATUS_CONFIRM_PAYMENT:
         return "Pembayaran Terkonfirmasi";
      case Master.SHIPMENT_STATUS_CONFIRM_NO_PAYMENT:
         return "Terkonfirmasi";
      case Master.SHIPMENT_STATUS_DELIVERY_IN_PROGRESS:
         return "Proses Pengiriman";
      case Master.SHIPMENT_STATUS_DELIVERY_COMPLETED:
         return "Menunggu Konfirmasi Pengiriman";
      case Master.SHIPMENT_STATUS_DELIVERY_CONFIRMED:
         return "Pengiriman Selesai";
      case Master.SHIPMENT_STATUS_PAYMENT_TRANSFERED:
         return "Pembayaran Selesai";
      case Master.SHIPMENT_STATUS_DECLINE:
         return "Tidak Diterima oleh Mitra Lintas";
      case Master.SHIPMENT_STATUS_CANCEL:
         return "Dibatalkan oleh Pelanggan";
      case Master.SHIPMENT_STATUS_CANCEL_NO_CONFIRMATION:
         return "Dibatalkan Tidak Mendapatkan Konfirmasi";
      case Master.SHIPMENT_STATUS_CANCEL_NO_PAYMENT:
         return "Dibatalkan Tidak Menerima Pembayaran";
      case Master.SHIPMENT_STATUS_COMPLAINT:
         return "Memproses Keluhan";
      case Master.SHIPMENT_STATUS_COMPLAINT_RESOLVED:
         return "Proses Keluhan Selesai";
      default:
         return code;
   }
}

export function getStepDesc(code: string) {
   switch (code) {
      case Master.STEP_CODE_TRAVEL_TO_ORIGIN:
         return "Pengambilan muatan";
      case Master.STEP_CODE_ARRIVE_AT_ORIGIN:
         return "Sampai tempat asal muatan";
      case Master.STEP_CODE_START_LOADING:
         return "Mulai memuat / loading muatan";
      case Master.STEP_CODE_COMPLETE_LOADING:
         return "Selesai memuat / loading muatan";
      case Master.STEP_CODE_TRAVEL_TO_DESTINATION:
         return "Menuju tempat tujuan muatan";
      case Master.STEP_CODE_ARRIVE_AT_DESTINATION:
         return "Sampai tempat tujuan muatan";
      case Master.STEP_CODE_TRAVEL_TO_TRANSIT:
         return "Menuju tempat transit muatan";
      case Master.STEP_CODE_ARRIVE_AT_TRANSIT:
         return "Sampai tempat transit muatan";
      case Master.STEP_CODE_START_UNLOADING:
         return "Mulai bongkar muatan";
      case Master.STEP_CODE_COMPLETE_UNLOADING:
         return "Selesai bongkar muatan";
      case Master.STEP_CODE_START_TRANSIT:
         return "Proses transit muatan";
      case Master.STEP_CODE_COMPLETE_TRANSIT:
         return "Selesai transit muatan";
      default:
         return code;
   }
}

export function getStepAction(code: string | undefined) {
   //console.log('getStepAction: ', code);
   switch (code) {
      case Master.STEP_CODE_TRAVEL_TO_ORIGIN:
         return "Mulai ambil muatan";
      case Master.STEP_CODE_ARRIVE_AT_ORIGIN:
         return "Sampai tempat asal muatan";
      case Master.STEP_CODE_START_LOADING:
         return "Mulai memuat / loading muatan";
      case Master.STEP_CODE_COMPLETE_LOADING:
         return "Selesai memuat / loading muatan";
      case Master.STEP_CODE_TRAVEL_TO_DESTINATION:
         return "Menuju tempat tujuan muatan";
      case Master.STEP_CODE_ARRIVE_AT_DESTINATION:
         return "Sampai tempat tujuan muatan";
      case Master.STEP_CODE_TRAVEL_TO_TRANSIT:
         return "Menuju tempat transit muatan";
      case Master.STEP_CODE_ARRIVE_AT_TRANSIT:
         return "Sampai tempat transit muatan";
      case Master.STEP_CODE_START_UNLOADING:
         return "Mulai bongkar muatan";
      case Master.STEP_CODE_COMPLETE_UNLOADING:
         return "Selesai bongkar muatan";
      case Master.STEP_CODE_START_TRANSIT:
         return "Mulai transit muatan";
      case Master.STEP_CODE_COMPLETE_TRANSIT:
         return "Selesai transit muatan";
      default:
         return code;
   }
}
