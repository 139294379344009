import { PrivacyPolicy } from "../model";

let desk_p1 =
   'Kebijakan Privasi ini adalah komitmen nyata dari PT. Lintas Teknologi Internasional (“Lintas“) untuk menghargai dan melindungi setiap data atau informasi pribadi Pengguna, baik Transporter maupun Shipper, melalui situs www.lintas.app dan aplikasi telepon seluler Lintas.app sebagai platform untuk transaksi berbagai jasa transportasi dan logistik (“Platform”). Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari platform Lintas sebagaimana tercantum dalam Syarat dan Ketentuan Shipper, Perjanjian Kerjasama Transporter, serta dan informasi lain yang tercantum di platform), menetapkan dasar atas perolehan, pengumpulan, pengolahan, penganalisisan, penampilan, pengiriman, pembukaan, penyimpanan, perubahan, penghapusan dan/atau segala bentuk pengelolaan yang terkait dengan data atau informasi yang mengidentifikasikan atau dapat digunakan untuk mengidentifikasi Pengguna yang diberikan oleh Pengguna kepada Lintas atau yang Lintas kumpulkan dari Pengguna maupun pihak ketiga (selanjutnya disebut sebagai "Data Pribadi").';

let desk_p2 =
   "Dengan mengklik “Daftar” atau “Register” atau pernyataan serupa yang tersedia di laman pendaftaran platform, Pengguna menyatakan bahwa setiap Data Pribadi Pengguna merupakan data yang benar dan sah, Pengguna mengakui bahwa ia telah diberitahukan dan memahami ketentuan Kebijakan Privasi ini serta Pengguna memberikan persetujuan kepada Lintas untuk memperoleh, mengumpulkan, mengolah, menganalisis, menampilkan, mengirimkan, membuka, menyimpan, mengubah, menghapus, mengelola dan/atau mempergunakan data tersebut untuk tujuan sebagaimana tercantum dalam Kebijakan Privasi.";

let desk_p3 =
   "Harap diketahui bahwa PT Lintas Teknologi Internasional dapat mengubah, memodifikasi, menambah dan menghapus Kebijakan Privasi ini sewaktu-waktu tanpa pemberitahuan sebelumnya. Kebijakan Privasi ini harus dibaca secara berkala. Dengan terus mengakses, mendaftar, dan/atau menggunakan situs www.lintas.app dan aplikasi telepon seluler Lintas.app setelah perubahan terhadap Kebijakan Privasi, Pengguna sepakat dan menyetujui perubahan tersebut.";

let deskripsi = [desk_p1, desk_p2, desk_p3];

const Pasal1 = {
   title: "Pasal 1: Perolehan dan Pengumpulan Data Pribadi Pengguna",
   clauses: [
      {
         content:
            "Lintas mengumpulkan Data Pribadi Pengguna dengan tujuan untuk memproses transaksi Pengguna, mengelola dan memperlancar proses penggunaan platform, serta tujuan-tujuan lainnya selama diizinkan oleh peraturan perundang-undangan yang berlaku. Adapun data Pengguna yang dikumpulkan termasuk: data yang diserahkan secara mandiri oleh Pengguna, data yang terekam pada saat Pengguna menggunakan platform, dan data yang diperoleh dari sumber lain.",
         subitems: [],
      },
      {
         content: "Data yang diserahkan secara mandiri oleh Pengguna, termasuk namun tidak terbatas pada data yang diserahkan pada saat Pengguna:",
         subitems: [
            { text: "membuat atau memperbarui akun Lintas, termasuk diantaranya nama pengguna (username), alamat email, nomor telepon, password, alamat, foto, KTP, SIM, dan informasi lainnya yang dapat mengidentifikasi Pengguna;", points: [] },
            { text: "menghubungi Lintas, termasuk melalui layanan pengguna;", points: [] },
            { text: "mengisi survei yang dikirimkan oleh Lintas atau pihak lain yang ditunjuk secara resmi untuk mewakili Lintas;", points: [] },
            { text: "melakukan interaksi dengan Pengguna lainnya melalui fitur pesan, diskusi produk, ulasan, rating, layanan pengguna, dan fitur interaktif (baik satu atau dua arah) lainnya yang terdapat dalam platform;", points: [] },
            {
               text: "mempergunakan layanan-layanan pada platform, termasuk data transaksi yang detail, diantaranya jenis, jumlah dan/atau keterangan dari produk atau layanan yang dibeli, alamat pengiriman, kanal pembayaran yang digunakan, jumlah transaksi, tanggal dan waktu transaksi, serta detail transaksi lainnya;",
               points: [],
            },
            {
               text: "mengisi data-data pembayaran pada saat Pengguna melakukan aktivitas transaksi pembayaran melalui platform, termasuk namun tidak terbatas pada data rekening bank, kartu kredit, virtual account, instant payment, internet banking, gerai ritel; dan/atau",
               points: [],
            },
            { text: "menggunakan fitur pada platform yang membutuhkan izin akses ke data yang relevan yang tersimpan dalam perangkat Pengguna.", points: [] },
         ],
      },
      {
         content: "Data yang terekam pada saat Pengguna mempergunakan platform, termasuk namun tidak terbatas pada:",
         subitems: [
            { text: "data lokasi riil atau perkiraannya seperti alamat IP, lokasi Wi-Fi dan geo-location;", points: [] },
            { text: "data berupa waktu dari setiap aktivitas Pengguna sehubungan dengan penggunaan platform, termasuk waktu pendaftaran, login dan transaksi;", points: [] },
            { text: "data Informasi Pribadi tertentu, termasuk nama, alamat fisik, alamat email, dan nomor telepon anda;", points: [] },
            {
               text: "data penggunaan atau preferensi Pengguna, diantaranya interaksi Pengguna dalam menggunakan platform, pilihan yang disimpan, serta pengaturan yang dipilih. Data tersebut diperoleh menggunakan cookies, pixel tags, dan teknologi serupa yang menciptakan dan mempertahankan pengenal unik;",
               points: [],
            },
            {
               text: "data perangkat, diantaranya jenis perangkat yang digunakan untuk mengakses platform, termasuk model perangkat keras, sistem operasi dan versinya, perangkat lunak, nomor IMEI, nama file dan versinya, pilihan bahasa, pengenal perangkat unik, pengenal iklan, nomor seri, informasi gerakan perangkat, dan/atau informasi jaringan seluler; dan/atau",
               points: [],
            },
            {
               text: "data catatan (log), diantaranya catatan pada server yang menerima data seperti alamat IP perangkat, tanggal dan waktu akses, fitur aplikasi atau laman yang dilihat, proses kerja aplikasi dan aktivitas sistem lainnya, jenis peramban (browser), dan/atau platform atau layanan pihak ketiga yang Pengguna gunakan sebelum berinteraksi dengan platform;",
               points: [],
            },
            {
               text: "data teknis tertentu sehubungan dengan penggunaan anda seperti, alamat protokol internet, informasi halaman web yang sebelumnya atau selanjutnya dilihat, durasi setiap kunjungan/sesi, identitas perangkat internet atau alamat kontrol akses media, dan informasi perangkat lainnya termasuk informasi mengenai produsen, model, dan sistem operasi dari perangkat yang anda gunakan untuk mengakses Aplikasi atau situs web kami.",
               points: [],
            },
         ],
      },
      {
         content: "Data yang diperoleh dari sumber lain, termasuk namun tidak terbatas pada:",
         subitems: [
            {
               text: "data berupa geo-location (GPS) dari mitra Lintas yang turut membantu Lintas dalam mengembangkan dan menyajikan layanan-layanan dalam platform kepada Pengguna, antara lain mitra penyedia layanan pembayaran, transporter, infrastruktur platform, dan mitra- mitra lainnya.",
               points: [],
            },
            {
               text: "data berupa email, nomor telepon, nama, gender, dan/atau tanggal lahir dari mitra transporter Lintas tempat Pengguna membuat atau mengakses akun Lintas, seperti layanan media sosial, atau platform/aplikasi yang menggunakan application programming interface (API) Lintas atau yang digunakan Lintas;",
               points: [],
            },
            { text: "data dari penyedia layanan finansial, termasuk namun tidak terbatas pada lembaga atau biro pemeringkat kredit atau Lembaga Pengelola Informasi Perkreditan (LPIP);", points: [] },
            { text: "data dari penyedia layanan finansial (apabila Pengguna menggunakan fitur spesifik seperti mengajukan pinjaman melalui platform/Aplikasi Lintas); dan/atau", points: [] },
            {
               text: "data berupa email dari penyedia layanan pemasaran. Lintas dapat menggabungkan data yang diperoleh dari sumber tersebut dengan data lain yang dimilikinya.data lokasi riil atau perkiraannya seperti alamat IP, lokasi Wi-Fi dan geo-location;",
               points: [],
            },
         ],
      },
      { content: "Lintas dapat menggabungkan data yang diperoleh dari sumber tersebut di atas dengan data lain yang dimilikinya.", subitems: [] },
   ],
};

const Pasal2 = {
   title: "Pasal 2: Penggunaan Data Pribadi",
   clauses: [
      {
         content: "Lintas dapat menggunakan Data Pribadi yang diperoleh untuk memproses segala bentuk permintaan, aktivitas maupun transaksi yang dilakukan oleh Pengguna melalui platform, termasuk untuk keperluan pengiriman barang kepada penerima.",
         subitems: [],
      },
      {
         content: "Lintas dapat menggunakan Data Pribadi yang diperoleh untuk menyediakan fitur-fitur untuk memberikan, mewujudkan, memelihara dan memperbaiki layanan kami, termasuk:",
         subitems: [
            {
               text: "menawarkan, memperoleh, menyediakan, atau memfasilitasi layanan pemesanan jasa pengiriman seperti pada laman beranda, penelusuran, pencarian, rekomendasi layanan, asuransi, pembiayaan, pinjaman, maupun layanan lainnya melalui platform;",
               points: [],
            },
            { text: "memungkinkan fitur untuk mempersonalisasikan akun Lintas Pengguna, seperti daftar pengiriman, draft, Transporter favorit; dan/atau", points: [] },
            {
               text: "melakukan kegiatan internal yang diperlukan untuk menyediakan layanan pada platform Lintas, seperti pemecahan masalah software, bug, permasalahan operasional, melakukan analisis data, pengujian, dan penelitian, dan untuk memantau dan menganalisis kecenderungan penggunaan dan aktivitas.",
               points: [],
            },
         ],
      },
      {
         content: "Lintas dapat menggunakan Data Pribadi yang diperoleh untuk membantu Pengguna pada saat berkomunikasi dengan Layanan Pengguna Lintas, di antaranya untuk:",
         subitems: [
            { text: "memeriksa dan mengatasi permasalahan Pengguna;", points: [] },
            { text: "mengarahkan pertanyaan Pengguna kepada petugas layanan pengguna yang tepat untuk mengatasi permasalahan;", points: [] },
            { text: "mengawasi dan memperbaiki tanggapan layanan pengguna Lintas;", points: [] },
            {
               text: "menghubungi Pengguna melalui email, surat, telepon, fax, dan metode komunikasi lainnya, termasuk namun tidak terbatas, untuk membantu dan/atau menyelesaikan proses transaksi maupun proses penyelesaian kendala, serta menyampaikan berita atau notifikasi lainnya sehubungan dengan perlindungan Data Pribadi Pengguna oleh Lintas, termasuk kegagalan perlindungan Data Pribadi Pengguna;",
               points: [],
            },
            {
               text: "menggunakan informasi yang diperoleh dari Pengguna untuk tujuan penelitian, analisis, pengembangan dan pengujian layanan guna meningkatkan keamanan layanan-layanan pada platform, serta mengembangkan fitur dan layanan baru; dan",
               points: [],
            },
            {
               text: "menginformasikan kepada Pengguna terkait layanan, promosi, studi, survei, berita, perkembangan terbaru, acara dan informasi lainnya, baik melalui platform maupun melalui media lainnya. Lintas juga dapat menggunakan informasi tersebut untuk mempromosikan dan memproses kontes dan undian, memberikan hadiah, serta menyajikan iklan dan konten yang relevan tentang layanan Lintas dan mitra usahanya.",
               points: [],
            },
         ],
      },
      {
         content:
            "Lintas dapat menggunakan Data Pribadi yang diperoleh untuk melakukan monitoring ataupun investigasi terhadap transaksi-transaksi mencurigakan atau transaksi yang terindikasi mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan Ketentuan Shipper, Perjanjian Kerjasama Transporter, atau ketentuan hukum yang berlaku, serta melakukan tindakan-tindakan yang diperlukan sebagai tindak lanjut dari hasil monitoring atau investigasi transaksi tersebut.",
         subitems: [],
      },
      { content: "Lintas dapat menggunakan Data Pribadi yang diperoleh untuk memfasilitasi transaksi penggabungan, penjualan aset perusahaan, konsolidasi atau restrukturisasi, pembiayaan atau akuisisi yang melibatkan Lintas.", subitems: [] },
      {
         content:
            "Dalam keadaan tertentu, Lintas mungkin perlu untuk menggunakan ataupun mengungkapkan Data Pribadi Pengguna untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan dan kewajiban peraturan perundang-undangan yang berlaku, termasuk dalam hal terjadinya sengketa atau proses hukum antara Pengguna dan Lintas, atau dugaan tindak pidana seperti penipuan atau pencurian data.",
         subitems: [],
      },
   ],
};

const Pasal3 = {
   title: "Pasal 3: Pengungkapan Data Pribadi Pengguna",
   clauses: [
      {
         content:
            "Lintas berkomitmen untuk menjaga kerahasiaan Data Pribadi Pengguna yang berada di bawah kendali Lintas dan menjamin tidak ada pengungkapan, penjualan, pengalihan, distribusi dan/atau peminjaman Data Pribadi Pengguna kepada pihak ketiga lain, tanpa persetujuan dari Pengguna, kecuali dalam hal-hal sebagai berikut:",
         subitems: [
            {
               text: "Dibutuhkan adanya pengungkapan Data Pribadi Pengguna kepada mitra atau pihak ketiga lain yang membantu Lintas dalam menyajikan layanan yang tersedia atau layanan yang di kemudian hari akan tersedia pada platform dan memproses segala bentuk aktivitas Pengguna dalam platform, termasuk memproses transaksi, verifikasi pembayaran, promosi, dan penyediaan layanan;",
               points: [],
            },
            {
               text: "Lintas dapat menyediakan Data Pribadi Pengguna kepada mitra usaha sesuai dengan persetujuan Pengguna untuk menggunakan layanan mitra usaha, termasuk diantaranya aplikasi atau platform lain yang telah saling mengintegrasikan API atau layanannya, atau mitra usaha yang mana Lintas telah bekerjasama untuk menyelenggarakan promosi, perlombaan, atau layanan khusus yang tersedia pada platform;",
               points: [],
            },
            {
               text: "Lintas dapat menyediakan Data Pribadi Pengguna kepada pihak ketiga yang menggunakan dan mengintegrasikan API publik yang disediakan oleh Lintas (termasuk namun tidak terbatas pada penyedia layanan mitra usaha Lintas) dengan aplikasi atau platform yang dioperasikannya untuk kepentingan penyelesaian transaksi antara Pembeli dan Penjual pada platform atau tujuan penggunaan Data Pribadi lainnya yang telah disebutkan pada pasal 2 Kebijakan Privasi ini;",
               points: [],
            },
            {
               text: "Dibutuhkan adanya komunikasi antara mitra usaha Lintas (seperti penyedia layanan jasa pengiriman, penyedia jasa pembayaran, dan penyedia fitur atau fasilitas layanan lainnya) dengan Pengguna dalam hal penyelesaian kendala maupun hal-hal lainnya;",
               points: [],
            },
            {
               text: "Lintas dapat menyediakan Data Pribadi Pengguna kepada vendor, konsultan, mitra pemasaran, firma/lembaga riset, atau penyedia layanan sejenis dalam rangka kegiatan pemasaran yang dilakukan oleh pihak ketiga, peningkatan dan pemeliharaan kualitas layanan Lintas, serta kegiatan publikasi lainnya;",
               points: [],
            },
            {
               text: "Pengguna menghubungi Lintas melalui media publik seperti blog, media sosial, dan fitur tertentu pada platform, yang mana komunikasi antara Pengguna dan Lintas tersebut dapat dilihat dan diketahui oleh khalayak ramai;",
               points: [],
            },
            { text: "Lintas dapat membagikan Data Pribadi Pengguna kepada anak perusahaan dan afiliasinya untuk membantu memberikan layanan atau melakukan pengolahan data untuk dan atas nama Lintas;", points: [] },
            { text: "Lintas dapat mengungkapkan dan/atau memberikan Data Pribadi Pengguna kepada pihak-pihak yang berkepentingan dalam rangka pelaksanaan analisis kelayakan kredit Pengguna;", points: [] },
            { text: "Lintas mengungkapkan Data Pribadi Pengguna dalam upaya mematuhi kewajiban hukum dan/atau adanya permintaan yang sah dari aparat penegak hukum atau instansi penyelenggara negara yang berwenang.", points: [] },
         ],
      },
      {
         content:
            "Sehubungan dengan pengungkapan Data Pribadi Pengguna sebagaimana dijelaskan di atas, Lintas akan/mungkin perlu mengungkapkan Data Pribadi Pengguna kepada penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait Lintas, dan/atau pihak ketiga lainnya yang berlokasi di luar wilayah Indonesia. Meskipun demikian, penyedia layanan pihak ketiga, agen dan/atau afiliasi atau perusahaan terkait dan/atau pihak ketiga lainnya tersebut hanya akan mengelola dan/atau memanfaatkan Data Pribadi Pengguna sehubungan dengan satu atau lebih tujuan sebagaimana diatur dalam Kebijakan Privasi ini dan sesuai dengan ketentuan peraturan perundangan-undangan yang berlaku.",
         subitems: [],
      },
   ],
};

const Pasal4 = {
   title: "Pasal 4: Cookies",
   clauses: [
      { content: "Cookies adalah file kecil yang secara otomatis akan mengambil tempat di dalam perangkat Pengguna yang menjalankan fungsi dalam menyimpan preferensi maupun konfigurasi Pengguna selama mengunjungi suatu platform.", subitems: [] },
      { content: "Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk disampaikan.", subitems: [] },
      {
         content:
            "Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat menghambat tersedianya layanan Lintas secara optimal pada saat Pengguna mengakses platform).",
         subitems: [],
      },
      {
         content:
            "Lintas menggunakan fitur Google Analytics Demographics and Interest. Data yang Lintas peroleh dari fitur tersebut, seperti umur, jenis kelamin, minat Pengguna dan informasi lainnya yang dapat mengidentifikasi Pengguna, akan Lintas gunakan untuk pengembangan fitur, fasilitas, dan/atau konten yang terdapat pada platform Lintas. Jika Pengguna tidak ingin data miliknya terlacak oleh Google Analytics, Pengguna dapat menggunakan Add-On Google Analytics Opt-Out Browser.",
         subitems: [],
      },
      {
         content:
            "Lintas dapat menggunakan fitur-fitur yang disediakan oleh pihak ketiga dalam rangka meningkatkan layanan dan konten Lintas, termasuk diantaranya ialah penilaian, penyesuaian, dan penyajian iklan kepada setiap Pengguna berdasarkan minat atau riwayat kunjungan. Jika Pengguna tidak ingin iklan ditampilkan berdasarkan penyesuaian tersebut, maka Pengguna dapat mengaturnya melalui browser.",
         subitems: [],
      },
   ],
};

const Pasal5 = {
   title: "Pasal 5: Pilihan Pengguna dan Transparansi",
   clauses: [
      {
         content:
            "Perangkat seluler pada umumnya (iOS dan Android) memiliki pengaturan sehingga aplikasi Lintas tidak dapat mengakses data tertentu tanpa persetujuan dari Pengguna. Perangkat iOS akan memberikan pemberitahuan kepada Pengguna saat aplikasi Lintas pertama kali meminta akses terhadap data tersebut, sedangkan perangkat Android akan memberikan pemberitahuan kepada Pengguna saat aplikasi Lintas pertama kali dimuat. Dengan menggunakan aplikasi dan memberikan akses terhadap aplikasi, Pengguna dianggap memberikan persetujuannya terhadap pengumpulan dan penggunaan Data Pribadi Pengguna dalam perangkatnya.",
         subitems: [],
      },
      {
         content:
            "Setelah transaksi melalui platform berhasil, Transporter maupun Shipper (sebagaimana didefinisikan dalam Perjanjian Kerjasam Transporter dan Syarat dan Ketentuan Shipper) memiliki kesempatan untuk memberikan penilaian dan ulasan terhadap satu sama lain. Informasi ini mungkin dapat dilihat secara publik dengan persetujuan Pengguna.",
         subitems: [],
      },
      {
         content: "Pengguna dapat mengakses dan mengubah informasi berupa alamat email, nomor telepon, tanggal lahir, jenis kelamin, daftar alamat, metode pembayaran, dan rekening bank melalui fitur pengaturan (settings) pada platform.",
         subitems: [],
      },
      {
         content:
            "Pengguna dapat menarik diri (opt-out) dari informasi atau notifikasi berupa buletin, ulasan, diskusi produk, pesan pribadi, atau pesan pribadi dari Admin yang dikirimkan oleh Lintas melalui fitur pengaturan pada platform. Lintas tetap dapat mengirimkan pesan atau email berupa keterangan transaksi atau informasi terkait akun Pengguna.",
         subitems: [],
      },
      {
         content:
            "Sepanjang tidak bertentangan dengan ketentuan peraturan perundang-undangan yang berlaku, Pengguna dapat menghubungi Lintas untuk melakukan penarikan persetujuan terhadap perolehan, pengumpulan, penyimpanan, pengelolaan dan penggunaan data Pengguna. Apabila terjadi demikian maka Pengguna memahami konsekuensi bahwa Pengguna tidak dapat menggunakan layanan platform maupun layanan Lintas lainnya.",
         subitems: [],
      },
   ],
};

const Pasal6 = {
   title: "Pasal 6: Keamanan, Penyimpanan dan Penghapusan Data Pribadi Pengguna",
   clauses: [
      {
         content:
            "Lintas melindungi setiap Data Pribadi Pengguna yang disimpan dalam sistemnya, serta melindungi data tersebut dari akses, penggunaan, modifikasi, pengambilan dan/atau pengungkapan tidak sah dengan menggunakan sejumlah tindakan dan prosedur keamanan, termasuk kata sandi dan/atau kode OTP (One Time Password) Pengguna.",
         subitems: [],
      },
      {
         content:
            "Data Pribadi Pengguna juga dapat disimpan atau diproses di luar negara oleh pihak yang bekerja untuk Lintas di negara lain, atau oleh penyedia layanan pihak ketiga, vendor, pemasok, mitra, kontraktor, atau afiliasi Lintas. Dalam hal tersebut, Lintas akan memastikan bahwa Data Pribadi tersebut tetap terlindungi sesuai dengan komitmen Lintas dalam Kebijakan Privasi ini.",
         subitems: [],
      },
      {
         content:
            "Walaupun Lintas telah menggunakan upaya terbaiknya untuk mengamankan dan melindungi Data Pribadi Pengguna, perlu diketahui bahwa pengiriman data melalui Internet tidak pernah sepenuhnya aman. Dengan demikian, Lintas tidak dapat menjamin 100% keamanan data yang disediakan atau dikirimkan kepada Lintas oleh Pengguna dan pemberian informasi oleh Pengguna merupakan risiko yang ditanggung oleh Pengguna sendiri.",
         subitems: [],
      },
      {
         content:
            "Lintas akan menghapus dan/atau menganonimkan Data Pribadi Pengguna yang ada di bawah kendali Lintas apabila (i) Data Pribadi Pengguna tidak lagi diperlukan untuk memenuhi tujuan dari pengumpulannya; dan (ii) penyimpanan tidak lagi diperlukan untuk tujuan kepatuhan terhadap peraturan perundang-undangan yang berlaku.",
         subitems: [],
      },
      {
         content:
            "Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa Data Pribadi Pengguna disimpan oleh pihak lain, termasuk instansi penyelenggara negara yang berwenang. Dalam hal kami membagikan Data Pribadi Pengguna kepada instansi penyelenggara negara yang berwenang dan/atau instansi lainnya yang dapat ditunjuk oleh pemerintah yang berwenang atau memiliki kerja sama dengan Lintas, Pengguna menyetujui dan mengakui bahwa penyimpanan Data Pribadi Pengguna oleh instansi tersebut akan mengikuti kebijakan penyimpanan data masing-masing instansi tersebut.",
         subitems: [],
      },
   ],
};

const Pasal7 = {
   title: "Pasal 7: Akses dan Perbaikan Data Pribadi Pengguna atau Penarikan Kembali Persetujuan",
   clauses: [
      {
         content:
            "Lintas mengambil langkah-langkah yang wajar untuk memastikan bahwa Data Pribadi Pengguna diproses secara akurat dan lengkap. Namun demikian, penting bagi Pengguna untuk memberi tahu Lintas secara tepat waktu tentang segala perubahan pada Data Pribadi Pengguna atau jika ada kesalahan dalam Data Pribadi Pengguna yang berada di bawah kendali Lintas.",
         subitems: [],
      },
      {
         content:
            "Pengguna berhak untuk mengakses atau mengoreksi Data Pribadi Pengguna yang berada di bawah kendali Lintas melalui layanan pengguna Lintas yang tersedia dalam Kebijakan Privasi ini. Namun demikian, permohonan tersebut hanya akan diproses oleh Lintas apabila Pengguna telah menyerahkan bukti identitas yang memadai untuk melakukan akses atau koreksi terhadap data tersebut. Lintas berhak menolak permohonan untuk mengakses, atau untuk memperbaiki, sebagian atau semua Data Pribadi Pengguna yang Lintas miliki atau kuasai jika diizinkan atau diperlukan berdasarkan perundang-undangan yang berlaku. Hal ini termasuk dalam keadaan di mana Data Pribadi tersebut dapat berisi referensi kepada orang lain atau di mana permintaan untuk akses atau permintaan untuk mengoreksi adalah untuk alasan yang Lintas anggap tidak relevan, tidak serius, atau menyulitkan.",
         subitems: [],
      },
      { content: "Lintas dapat membebankan biaya administrasi kepada Pengguna untuk menangani permintaan Pengguna dalam mengakses atau mengoreksi Data Pribadi Pengguna.", subitems: [] },
      {
         content:
            "Pengguna dapat menarik persetujuan yang telah Pengguna berikan terkait dengan pemrosesan Data Pribadi Pengguna yang ada di bawah kendali Lintas dengan mengirimkan permintaan tersebut melalui layanan pengguna Lintas. Lintas akan memproses permintaan Pengguna dalam kurun waktu yang wajar sejak permintaan penarikan persetujuan tersebut disampaikan, dan selanjutnya tidak memproses Data Pribadi Pengguna sesuai dengan permintaan awal Pengguna, kecuali diwajibkan oleh peraturan perundang-undangan yang berlaku. Dalam hal terdapat penarikan persetujuan, Lintas mungkin tidak dapat melaksanakan kewajiban-kewajibannya berdasarkan setiap perjanjian antara Pengguna dengan Lintas. Sehubungan dengan hal tersebut, terdapat juga kemungkinan bahwa dikarenakan Lintas tidak dapat melaksanakan kewajiban-kewajibannya oleh karena Pengguna menarik persetujuannya, setiap hubungan hukum antara Pengguna dan Lintas menjadi berakhir dan tidak dapat dilanjutkan.",
         subitems: [],
      },
   ],
};

const Pasal8 = {
   title: "Pasal 8: Pengaduan terkait Perlindungan Data Pengguna",
   clauses: [
      {
         content:
            "Jika Pengguna memiliki kekhawatiran tentang penanganan atau perlakuan Lintas terhadap Data Pribadi Pengguna atau jika Pengguna yakin bahwa privasinya telah dilanggar, Pengguna dapat menghubungi Lintas melalui layanan penguna Lintas dengan menjelaskan identitas dan sifat keluhan Pengguna.",
         subitems: [],
      },
      { content: "Lintas akan menyelidiki keluhan Pengguna dan berupaya untuk memberikan tanggapan terhadap keluhan tersebut dalam kurun waktu yang wajar setelah menerima keluhan yang disampaikan oleh Pengguna.", subitems: [] },
   ],
};

const Pasal9 = {
   title: "Pasal 9: Penyimpanan dan Penghapusan Informasi",
   clauses: [{ content: "Lintas akan menyimpan informasi selama akun Pengguna tetap aktif dan dapat melakukan penghapusan sesuai dengan ketentuan peraturan hukum yang berlaku.", subitems: [] }],
};

export const privpol: PrivacyPolicy = {
   descriptions: deskripsi,
   prefaces: [],
   articles: [Pasal1, Pasal2, Pasal3, Pasal4, Pasal5, Pasal6, Pasal7, Pasal8, Pasal9],
};
