import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { Checkbox } from "antd";
import { FadeView, ShipmentSteps, ProviderView } from "../common";

import { TransportTypeOption } from "./transport-type";

import { Shipment, TransportType, Provider, Service } from "../../model";

import { transportTypeSet, getEmptyTransportTypes } from "../../reference/TransportType";
import * as Master from "../../reference/Master";

import * as CommonHelper from "../../helper/CommonHelper";

import { CacheService, ProviderService } from "../../service";

import "./SelectProviderTransportType.css";

function SelectTransportType(props: any) {
   const history = useHistory();
   const { match }: any = props;
   const id = match.params.id;
   const shipment: Shipment = props.shipment;

   const [provider, setProvider] = useState<Provider>(new Provider());
   const [services, setServices] = useState<Service[]>();
   const [selectTransportLabel, setSelectTransportLabel] = useState("");
   const [transportTypes, setTransportTypes] = useState<TransportType[]>(getEmptyTransportTypes(3));
   const [requireTkbm, setRequireTkbm] = useState(shipment.isReqTransWorker ? true : false);

   const onPressMore = () => {
      if (provider.gid) {
         if (id) {
            history.push(`/provider/${provider.gid}`);
         } else {
            history.push(`/admin/review/provider/${provider.gid}`);
         }
      }
   };

   useEffect(() => {
      props.setShowBackNavigation(false);
      props.setShowBottomNavigation(true);
   }, [props]);

   useEffect(() => {
      // if (!match.params.id) {
      //    return;
      // }

      let providerId = id;
      if (providerId) {
         providerId = providerId.toUpperCase();
      }

      ProviderService.getProviderRef(providerId).then((result) => {
         let provider: Provider = CommonHelper.copyObject(result.provider);
         provider.transWorkerService = result.transWorkerService;
         CacheService.setProvider(provider);
         setProvider(provider);
         setServices(result.services);
      });
   }, [id]);

   useEffect(() => {
      if (!services) {
         return;
      }
      const transTypes = new Array<TransportType>();
      for (let i = 0; i < services?.length; i++) {
         const serviceCode = services[i].serviceCode;
         //console.log("service code: " + serviceCode);
         const transType = transportTypeSet.get(serviceCode);
         if (transType) {
            transTypes.push(transType);
         }
      }
      setSelectTransportLabel("Pilih Angkutan");
      setTransportTypes(transTypes);
   }, [services]);

   const onSelectTransportType = (transportType: TransportType) => {
      shipment.transportTypeCode = transportType.code;
      shipment.transportTypeDesc = transportType.desc;
      shipment.fees = [];
      shipment.payment.totalPaymentAmount = 0;
      shipment.isProviderRef = true;

      if (!provider.transWorkerService) {
         shipment.isReqTransWorker = false;
      }

      const selectedServices = services?.filter((service) => service.serviceCode === transportType.code);
      const pvd = JSON.parse(JSON.stringify(provider));
      pvd.service = selectedServices ? selectedServices[0] : undefined;

      CacheService.setNewShipment(shipment);
      CacheService.setProvider(pvd);
      CacheService.setTransportType(transportType);

      let transportTypeCode = transportType.code.toLowerCase();

      if (id) {
         if (CacheService.getUser() && CacheService.getUser().customer) {
            console.log("user: ", JSON.stringify(CacheService.getUser(), null, 2));
            history.push("/new-shipment/" + transportTypeCode);
         } else {
            history.push("/login/" + Master.PATH_REFERRAL);
         }
      } else {
         history.push("/admin/review/new-shipment/" + transportTypeCode);
      }
   };

   const requireTkbmSelected = (e: any) => {
      shipment.isReqTransWorker = e.target.checked;
      setRequireTkbm(e.target.checked);
   };

   return (
      <div className="select-transport-type">
         <div className="select-transport-type-content">
            {id && <ShipmentSteps status={Master.SHIPMENT_STATUS_NEW_ORDER} />}
            <FadeView show={true} duration="0">
               <div className="select-transport-type-provider">
                  <ProviderView provider={provider} onSelect={onPressMore} full />
               </div>
               <div className="select-transport-type-header regular-bottom-border">
                  <div className="select-transport-type-header-label regular-bold-font">{selectTransportLabel || <Skeleton delay={1} width={150} />}</div>
                  {selectTransportLabel && (
                     <Checkbox onClick={requireTkbmSelected} disabled={!provider.transWorkerService} checked={provider.transWorkerService && requireTkbm} className="regular-bold-font">
                        Membutuhkan tenaga kerja bongkar muat
                     </Checkbox>
                  )}
               </div>
               {transportTypes && <TransportTypeOption data={transportTypes} onSelect={onSelectTransportType} />}
            </FadeView>
         </div>
      </div>
   );
}

export default SelectTransportType;
