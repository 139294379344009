import React, { useEffect, useState } from "react";
//import {useHistory} from 'react-router';
import Skeleton from "react-loading-skeleton";
import Rating from "react-rating";

import { AiFillStar } from "react-icons/ai";

import { ModalContext } from "../../hooks/ModalContext";

import {} from "google-maps";
import GoogleMapReact from "google-map-react";

import {} from "../common";

import { ShipmentSteps, MapMarker, ImageView, ImagesGallery, ImagesPanel } from "../common";

import { ProviderService, ReviewService } from "../../service";

import { Provider, Fleet, Review, Image } from "../../model";

import * as Category from "../../reference/Category";

import * as Config from "../../Config";

import * as FormatHelper from "../../helper/FormatHelper";

import { yellow } from "../../constants/Color";

import ReviewView from "../common/ReviewView"

import "./ProviderInfo.css";

//let modalContext: ModalContextInterface;

function RenderReview(props: any) {
   return props.reviews.map((rating: any, i: any) => <ReviewView review={rating} onPressMore={props.onPressMore} key={i} />);
}


function ProviderInfo(props: any) {
   //const history = useHistory();
   const path = window.location.pathname;
   // isReview is true when this module is used by provider to check the service price
   const isReview = path.indexOf("/admin/review") > -1;

   const { match }: any = props;

   const [provider, setProvider] = useState<Provider>();
   const [fleets, setFleets] = useState<Fleet[]>();
   const [reviews, setReviews] = useState<Review[]>();
   const [images, setImages] = useState<Image[]>();
   const [mapCenter, setMapCenter] = useState({ lat: -6.2087634, lng: 106.845599 });
   const [showImagesGallery, setShowImagesGallery] = useState(false);
   const [imageIndex, setImageIndex] = useState(0);

   useEffect(() => {
      if (!match.params.id) {
         return;
      }
      ProviderService.getProviderInfo(match.params.id).then((result) => {
         setProvider(result.provider);
         setFleets(result.fleets);
         // setReviews(result.reviews);
         setImages(result.carrierProfileImages);
      });
   }, [match.params.id]);

   useEffect(() => {
      if (!match.params.id) {
         return;
      }
      ReviewService.GetReviewsProvider(match.params.id).then((result) => {
         setReviews(result);
      })
   })

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   useEffect(() => {
      setMapCenter({ lat: provider?.location?.lat || -6.2087634, lng: provider?.location?.lng || 106.845599 });
   }, [provider]);

   return (
      <ModalContext.Consumer>
         {(context) => {
            //modalContext = context;
            return (
               <div className="provider-info">
                  <div className="provider-info-content">
                     {!isReview && <ShipmentSteps status={props.shipment.status} />}
                     <div className="provider-info-map regular-border">
                        <div className="content regular-bottom-border">
                           <div className="section">
                              <ImageView className="service-provider-view-image regular-border" size={75} uploadUrl={provider?.profileImgUrl} alt="provider" />
                              <div className="section-info">
                                 <div className="regular-bold-font">{provider?.name || <Skeleton delay={1} width={200} />}</div>
                                 <div className="regular-font">{provider?.location?.address || <Skeleton delay={1} width={275} />}</div>
                                 <div className="section-info-rating">
                                    <div style={{ display: "flex" }}>
                                       <Rating
                                          stop={5}
                                          placeholderRating={(provider?.name && provider.rating) || 0}
                                          readonly
                                          emptySymbol={<AiFillStar style={{ color: "#ccc" }} />}
                                          placeholderSymbol={<AiFillStar style={{ color: yellow }} />}
                                          fullSymbol={<AiFillStar style={{ color: yellow }} />}
                                       />
                                       <div className="service-provider-view-rating-content regular-font">{(provider?.name && FormatHelper.formatFloatNumber(provider?.rating || 0).replace(",", ".")) || <Skeleton delay={1} width={50} />}</div>
                                    </div>
                                    <div
                                       className="regular-font"
                                       style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                       }}
                                    >
                                       {(provider?.name && provider.reviewCount + " Review") || <Skeleton delay={1} width={50} />}
                                    </div>
                                    <div
                                       className="regular-font"
                                       style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                       }}
                                    >
                                       {(provider?.name && provider.deliveryCount + " Pengiriman") || <Skeleton delay={1} width={50} />}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <GoogleMapReact bootstrapURLKeys={{ key: Config.GOOGLE_MAP_KEY }} center={mapCenter} defaultZoom={13}>
                           {provider && <MapMarker size="small" lat={provider.location?.lat} lng={provider.location?.lng} />}
                        </GoogleMapReact>
                     </div>
                     <div className="provider-info-title regular-bold-grey-font  regular-side-border">Profil Penyedia Jasa Angkutan</div>
                     <div className="provider-info-desc regular-font regular-side-border">{provider?.profileDesc}</div>
                     <div className="provider-info-category regular-side-border">
                        {fleets &&
                           fleets.map((fleet, i) => {
                              let category = Category.categorySet.get(fleet.fleetCategoryCode);
                              return (
                                 <div className="provider-info-category-item" key={i}>
                                    <img className="provider-info-category-img" alt={fleet.fleetCategoryCode} src={process.env.PUBLIC_URL + "/images/category/category-" + fleet.fleetCategoryCode.toLowerCase() + ".png"} />
                                    <div className="provider-info-category-label regular-bold-font">
                                       {fleet.count} {category?.desc}
                                    </div>
                                 </div>
                              );
                           })}
                     </div>
                     <div className="provider-info-title regular-bold-grey-font  regular-side-border">Foto Kendaraan Angkutan</div>
                     <div className="provider-info-images regular-font regular-bottom-side-border">
                        <ImagesPanel
                           images={images}
                           width={window.innerWidth > 500 ? 460 : window.innerWidth - 40}
                           size={4}
                           onPress={(index: number) => {
                              setImageIndex(index);
                              //setImages(images);
                              setShowImagesGallery(true);
                           }}
                        />
                     </div>
                     <div className="provider-info-title regular-bold-grey-font  regular-side-border">Review</div>
                     <div className="provider-info-review regular-bottom-side-border">
                        {reviews && <RenderReview reviews={reviews} />}
                     </div>
                  </div>
                  {images && <ImagesGallery title="Foto Muatan" images={images} index={imageIndex} show={showImagesGallery} setShow={setShowImagesGallery} />}
               </div>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default ProviderInfo;
