import React, { useEffect, useState } from "react";
import { PageView, FadeView } from "../common";
import { ModalContext } from "../../hooks/ModalContext";

import { Review } from "../../model";

import { ReviewService } from "../../service";

import { CacheService } from "../../service";

import "./ReviewProvider.css";

// let modalContext: ModalContextInterface;

function ReviewProvider(props: any) {
   const [init, setInit] = useState(false);
   const [reviews, setReviews] = useState<Review[]>();
   // const [status] = useState(match.params.status ? match.params.status.toUpperCase() : "");
   // const [shipments, setShipments] = useState(ShipmentRef.getEmptyShipments(6));
   // const [searchShipment, setSearchShipment] = useState(match.params.desc ? match.params.desc : "");
   const [page, setPage] = useState(CacheService.getPage());
   const [pageCount] = useState(CacheService.getPageCount());
   const [user] = useState(CacheService.getUser());

   useEffect(() => {
      //props.setShowBottomNavigation(true);
      props.setShowBackNavigation(true);
   }, [props]);

   useEffect(() => {
      if (!init) {
         props.setShowBottomNavigation(true);
         setInit(true);
      }
   }, [init, props]);

   useEffect(() => {
      ReviewService.GetReviewsProvider(user.provider?.gid).then((result) => {
         setReviews(result);
      });
   }, [user.provider]);

   useEffect(() => {
      props.setShowBackNavigation(true);
      props.setShowBottomNavigation(false);
   }, [props]);

   const onPressMore = (review: Review) => {
      // CacheService.setShipment(review);
      // history.push(`/shipment-detail/${shipment.gid}`);
   };

   return (
      <ModalContext.Consumer>
         {(context) => {
            // modalContext = context;
            return (
               <FadeView show={true} duration="0">
                  <div className="review-home">
                     <div id="search-result" className={"review-home-content"} style={!props.setShowBottomNavigation && window.innerWidth < 1000 ? { height: window.innerHeight - 194 } : {}}>
                        <div className="provider-info-title regular-bold-grey-font  regular-side-border">Review</div>
                        {console.log("REVIEWS: ", reviews)}
                        <PageView
                           reviews={reviews}
                           onPressMore={(reviews: Review) => {
                              onPressMore(reviews);
                           }}
                           page={page}
                           setPage={setPage}
                           pageCount={pageCount}
                           emptyText="Tidak ditemukan pengiriman"
                        />
                     </div>
                  </div>
               </FadeView>
            );
         }}
      </ModalContext.Consumer>
   );
}

export default ReviewProvider;
