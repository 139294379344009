import { gql } from "@apollo/client";
import { OrderJson, SearchJson } from "../model";

import { authGraphClient, initAuthClient } from "./GraphService";

class AnalyticService {
  async GetSearches() {
    await initAuthClient();
    console.log("GetSearches");

    let gqlReq = `
      query {
        GetSearches {
          id
          customerId
          phone
          originDesc
          destinationDesc
          transportTypeCode
          transWorkerStatus
          requestDate
          deliveryDate
          count
        }
      }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let searchResult = result ? result.data.GetSearches : null;
    console.log("GetSearches:", JSON.stringify(searchResult, null, 2));

    return searchResult;
  }

  async GetOrdersCount(duration: number) {
    await initAuthClient();
    console.log("GetOrdersCount");

    let gqlReq = `
      query {
        GetOrdersCount(duration: ${duration}) {
          requestDate
          countNewOrder
          countAcceptOrder
          countConfirmPayment
          countTotal
        }
      }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let orderscountResult = result ? result.data.GetOrdersCount : null;
    // let scountResult = { searchcount: result.data.GetSearchCount };
    console.log("GetOrdersCount:", JSON.stringify(orderscountResult, null, 2));

    return orderscountResult;
  }

  async GetSearchesCount(duration: number) {
    await initAuthClient();
    console.log("GetSearchesCount");

    let gqlReq = `
      query {
        GetSearchesCount(duration: ${duration}) {
          requestDate
          countFound
          countNotFound
          countTotal
        }
      }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let scountResult = result ? result.data.GetSearchesCount : null;
    // let scountResult = { searchcount: result.data.GetSearchCount };
    console.log("GetSearchesCount:", JSON.stringify(scountResult, null, 2));

    return scountResult;
  }

  async GetSearchOrderAnalytic(duration: number) {
    await initAuthClient();
    console.log("GetSearchOrderAnalytic");

    let gqlReq = `
    query {
      GetSearchesCount(duration: ${duration}) {
        requestDate
        countFound
        countNotFound
        countTotal
      }
      GetOrdersCount(duration: ${duration}) {
        requestDate
        countNewOrder
        countAcceptOrder
        countConfirmPayment
        countTotal
      }
    }`;

    let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

    const datasearch: SearchJson = result ? result.data.GetSearchesCount : null;
    const dataorder: OrderJson = result ? result.data.GetOrdersCount : null;

    const resp = { search: datasearch, order: dataorder };

    console.log("GetSearchOrderAnalytic:", JSON.stringify(resp, null, 2));

    return resp;
  }

  async GetServicesAnalytic(option: string) {
    await initAuthClient();
    console.log("GetServicesAnalytic");

    let gqlReq = `
    query {
      GetServicesAnalytic (option: "${option}") {
        name
        count
      }
    }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let serviceResult = result ? result.data.GetServicesAnalytic : null;
    console.log("GetServicesAnalytic:", JSON.stringify(serviceResult, null, 2));

    return serviceResult;
  }

  async GetSitesCarriersAnalytic(option: string) {
    await initAuthClient();
    console.log("GetSitesCarriersAnalytic");

    let gqlReq = `
    query {
      GetSitesCarriersAnalytic (option: "${option}") {
        name
        count
      }
    }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let sitesCarriersResult = result ? result.data.GetSitesCarriersAnalytic : null;
    console.log("GetSitesCarriersAnalytic:", JSON.stringify(sitesCarriersResult, null, 2));

    return sitesCarriersResult;
  }

  async GetOriginDestinationsAnalytic() {
    await initAuthClient();
    console.log("GetOriginDestinationsAnalytic");

    let gqlReq = `
    query {
      GetOriginDestinationsAnalytic {
        name
        count
      }
    }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let originDestinationsResult = result ? result.data.GetOriginDestinationsAnalytic : null;
    console.log("GetOriginDestinationsAnalytic:", JSON.stringify(originDestinationsResult, null, 2));

    return originDestinationsResult;
  }

  async GetTransportOriginAnalytic(found: string) {
    await initAuthClient();
    console.log("GetTransportOriginAnalytic");

    let gqlReq = `
    query {
      GetTransportOriginAnalytic (found: "${found}") {
        name
        count
      }
    }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let transportoriginResult = result ? result.data.GetTransportOriginAnalytic : null;
    console.log("GetTransportOriginAnalytic:", JSON.stringify(transportoriginResult, null, 2));

    return transportoriginResult;
  }

  async GetKPI(output: string, breakdown: string, input: string, period : string, startDate: Date, endDate: Date) {
    await initAuthClient();
    console.log("GetKPI");

    let gqlReq = `
    query {
      GetKPI(output: "${output}", breakdown: "${breakdown}", input: "${input}", period: "${period}", startDate: "${startDate.toISOString()}", endDate: "${endDate.toISOString()}") {
        requestDate
        name
        value
      }
    }`;

    console.log("gql query: ", gqlReq);

    let result;

    result = await authGraphClient.query({
      query: gql`
          ${gqlReq}
       `,
    });

    console.log("result:", JSON.stringify(result, null, 2));
    let kpiresult = result ? result.data.GetKPI : null;
    console.log("GetKPI:", JSON.stringify(kpiresult, null, 2));

    return kpiresult;
  }
}

const Analytic = new AnalyticService();

export default Analytic;