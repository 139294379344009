import * as React from 'react';

import FadeView from './FadeView';

import './ModalView.css';

export default function ModalView(props: any) {
  return (
    <div>
      <FadeView className="modal-container" show={props.show} duration="1000">
        <div className="modal-content">{props.children}</div>
      </FadeView>
    </div>
  );
}
