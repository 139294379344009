import * as React from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";

import { FadeView, SquareButton } from ".";

import * as Color from "../../constants/Color";
import * as Master from "../../reference/Master";

import * as AlertHelper from "../../helper/AlertHelper";

import "./ConfirmView.css";

export default function ConfirmView(props: any) {
   const onConfirm = () => {
      setTimeout(() => props.confirm(), 1000);
      props.setShow(false);
   };

   const onCancel = () => {
      props.setShow(false);
   };

   React.useEffect(() => {
      AlertHelper.enableScroll(!props.show, "ConfirmView");
   }, [props.show]);

   const desc = props.desc ? props.desc.split("\n").map((str: string, key: number) => <p key={key}>{str}</p>) : "";

   return (
      <div>
         <FadeView className="confirm-container" show={props.show} duration="5000">
            <div>
               <div className="confirm-content">
                  <div className="confirm-border regular-border" style={{ borderColor: Color.alertInfoBorder }}>
                     {<AiOutlineInfoCircle size={30} color={Color.alertInfo} />}
                     <div className="confirm-title big-bold-font" style={{ color: Color.alertInfo }}>
                        {Master.ALERT_CONFIRM_TITLE}
                     </div>
                     <div className="confirm-desc regular-bold-font">{desc}</div>
                     <div className="confirm-button-panel">
                        <SquareButton ok text="OK" style={{ marginRight: 5, width: window.innerWidth < 1000 ? (window.innerWidth - 40) / 2 : 250 }} onClick={onConfirm} />
                        <SquareButton cancel text="Batal" warning style={{ marginLeft: 5 }} onClick={onCancel} />
                     </div>
                  </div>
               </div>
            </div>
         </FadeView>
      </div>
   );
}
