import { gql } from "@apollo/client";

import { User, Provider, Place } from "../model";
import { transportTypeSet } from "../reference/TransportType";

import * as Master from "../reference/Master";

import { graphClient, authGraphClient, hybridGraphClient, initAuthClient, initClient, initHybridClient } from "./GraphService";

import { ReferenceService, CacheService } from ".";

import * as GraphHelper from "../helper/GraphHelper";
import * as LocationHelper from "../helper/LocationHelper";
import * as CommonHelper from "../helper/CommonHelper";

class ProviderService {
   async registerProvider(user: User, provider: Provider) {
      await initAuthClient();
      let g = `
         mutation {
            RegisterProvider(
               inputUser: {
                  name: "${user.name}", 
                  email: "${user.email}", 
                  mobile: "${user.mobile}"
               }, 
               inputProvider: {
                  name: "${provider.name}", 
                  type: "PERSONAL", 
                  phone: "${provider.phone}", 
                  email: "${user.email}", 
                  profileImgID: ${provider.profileImg?.id}, 
                  profileImgUrl: "${provider.profileImg?.url}", 
                  profileDesc: "",
               }
               inputPlace: {
                  name: "${provider.location?.name}", 
                  address: "${provider.location?.address}", 
                  lat: ${provider.location?.lat}, 
                  lng: ${provider.location?.lng}
               }
            ) {
               token
               refreshToken
               user {
                  id
                  gid
                  name
                  email
                  mobile
                  provider {
                     id
                     gid
                  }
               }
            }
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      //let auth = result.data.RegisterProvider[0];
      let auth = result.data.RegisterProvider;
      let usr: User | undefined;

      if (CacheService.getUser().role === Master.ROLE_PROVIDER) {
         CacheService.setToken({ token: auth.token, refreshToken: auth.refreshToken });

         usr = GraphHelper.graphToUser(auth.user, Master.ROLE_PROVIDER);
         CacheService.setUser(usr);
      }

      console.log("result:", result.data);
      return usr;
   }

   async updateProvider(provider: Provider, location: Place) {
      await initAuthClient();

      const user = CacheService.getUser();

      let gStatus = ``;
      if (user.isAdmin) {
         gStatus = `status: "${provider.status}",`;
      }

      let g = `
         mutation {
            UpdateProvider(
               inputProvider: {
                  name: "${provider.name}", 
                  type: "", 
                  phone: "${provider.phone}",
                  email: "${provider.email}",
                  profileImgID: ${provider.profileImg?.id},
                  profileImgUrl: "${provider.profileImgUrl}",
                  profileDesc: """${provider.profileDesc}""",
                  ${gStatus}
               },
               inputLocation: {
                  name: "${location.name}",
                  address: "${location.address}",
                  lat: ${location.lat},
                  lng: ${location.lng},
               }
            )
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let providerId = result.data.UpdateProvider;

      console.log("result:", result.data);
      return providerId;
   }

   async updateProviderBank(provider: Provider) {
      await initAuthClient();
      let g = `
         mutation {
            UpdateProviderBank(
               inputBank: {
                  bankCode: "${provider.bankCode}", 
                  bankName: "${provider.bankName}", 
                  bankAccNo: "${provider.bankAccNo}", 
                  bankAccName: "${provider.bankAccName}", 
               },
            )
         }`;

      console.log("gql:", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let providerId = result.data.UpdateProviderBank;

      console.log("result:", result.data);
      return providerId;
   }

   async getProvider(gid?: string) {
      await initAuthClient();
      console.log("getProvider:", gid);

      let gqlReq = `
            query { 
               GetProvider(gid: "${gid}") {
                  id
                  gid
                  name
                  type 
                  phone
                  email
                  level
                  rating
                  profileDesc
                  bankName
                  bankCode
                  bankAccNo
                  bankAccName
                  status
                  location {
                     name
                     address
                     lat
                     lng
                  }
                  profileImg {
                     id
                     name
                     status
                     type
                     url
                  }
               }
            }`;

      console.log("gql query:", gqlReq);
      let result;

      try {
         result = await authGraphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      console.log("result:", JSON.stringify(result, null, 2));
      let provider: Provider = result ? result.data.GetProvider : null;
      console.log("getProvider:", JSON.stringify(provider, null, 2));

      return provider;
   }

   async getProviderInfo(gid?: string) {
      await initClient();
      console.log("getProvider:", gid);

      let gqlReq = `
            query { 
               GetProviderInfo(gid: "${gid}") {
                  provider {
                     gid
                     name
                     type 
                     phone
                     email
                     level
                     rating
                     location {
                        name
                        address
                        lat
                        lng
                     }
                     profileImgUrl
                     profileDesc
                     reviewCount
                     deliveryCount
                  }
                  fleets {
                     fleetCategoryCode
                     count
                  }
                  reviews {
                     reviewer
                     rating
                     remarks
                  }
                  carrierProfileImages {
                     id
                     name
                     size
                     type
                     url
                     status
                  }
               }
            }`;

      console.log("gql query:", gqlReq);
      let result;

      try {
         result = await graphClient.query({
            query: gql`
               ${gqlReq}
            `,
         });
      } catch (err) {
         console.error(err);
         throw err;
      }

      console.log("result:", JSON.stringify(result, null, 2));
      let providerInfo = result ? result.data.GetProviderInfo : null;
      console.log("getProviderInfo:", JSON.stringify(providerInfo, null, 2));

      return providerInfo;
   }

   async getProviderRef(refId?: string) {
      let isAuth = CacheService.getUser() != null;

      if (isAuth) {
         await initAuthClient();
      } else {
         await initClient();
      }

      //console.log("getProvider:", refId);

      let gqlParams = refId ? `(refId: "${refId}")` : "";

      let gqlReq = `
            query { 
               GetProviderRef${gqlParams} {
                  provider {
                     gid
                     name
                     type 
                     phone
                     email
                     level
                     rating
                     location {
                        name
                        address
                        lat
                        lng
                     }
                     profileImgUrl
                     profileDesc
                     reviewCount
                     deliveryCount
                  }
                  services {
                     serviceCode
                     priceScheme
                     pricePerUnit
                     pricePerKmShort
                     maxKmShort
                     pricePerKmMed
                     maxKmMed
                     pricePerKmLong
                     minKm
                     minPrice
                     pickupPricePerKm
                     pickupFreeKmDistance
                     pickupCoverageKm
                     capacityKg
                     maxLoadCapacity
                     overCapacitySurcharge
                     returnPriceRatio
                     transitPrice
                  }
                  transWorkerService {
                     serviceCode
                     priceScheme
                     pricePerUnit
                     pricePerKmShort
                     maxKmShort
                     pricePerKmMed
                     maxKmMed
                     pricePerKmLong
                     minKm
                     minPrice
                     pickupPricePerKm
                     pickupFreeKmDistance
                     pickupCoverageKm
                     capacityKg
                     maxLoadCapacity
                     overCapacitySurcharge
                     returnPriceRatio
                     transitPrice
                  }
               }
            }`;

      console.log("gql query:", gqlReq);
      let result;

      try {
         if (isAuth) {
            result = await authGraphClient.query({
               query: gql`
                  ${gqlReq}
               `,
            });
         } else {
            result = await graphClient.query({
               query: gql`
                  ${gqlReq}
               `,
            });
         }
      } catch (err) {
         console.error(err);
         throw err;
      }

      //console.log("result:", JSON.stringify(result, null, 2));
      let providerRef = result ? result.data.GetProviderRef : null;
      console.log("GetProviderRef:", JSON.stringify(providerRef, null, 2));

      return providerRef;
   }

   async searchServiceProvider(serviceCode: string, distance: number, weight: number, origin: Place, destinations: Place[], deliveryDate: Date, transWorkerSrvStatus: string, harbor: string) {
      await ReferenceService.initTransportTypes();
      // await initClient();
      await initHybridClient();
      // console.log("searchServiceProvider:", JSON.stringify(destinations, null, 2));

      let transportType = transportTypeSet.get(serviceCode);
      let deliveryDtStr = deliveryDate instanceof Date ? deliveryDate.toISOString() : deliveryDate;
      let isTest = CacheService.isTestBooking() ? "true" : "false";

      console.log("transportType: ", JSON.stringify(transportType, null, 2));

      let gqlDes = ``;
      for (const destination of destinations) {
         gqlDes += `{
            name: "",
            address: "${destination.address}",
            lat: ${destination.lat}, 
            lng: ${destination.lng}
         },`;
      }

      // let lastDestination = destinations[destinations.length - 1];

      let gqlReq = `
            query { 
               GetServiceProviders(
                  serviceCode: "${serviceCode}", 
                  distance: ${distance}, 
                  weight: ${weight}, 
                  origin: {
                     name: "${CommonHelper.gqlStr(origin.name)}",
                     address: "${CommonHelper.gqlStr(origin.address)}",
                     addressDesc: "${CommonHelper.gqlStr(LocationHelper.getSubRegionCity(origin.address))}",
                     lat: ${origin.lat},
                     lng: ${origin.lng},
                  },
                  destinations: [${destinations?.map(
                     (destination) =>
                        `{
                        name: "${CommonHelper.gqlStr(destination?.name)}",
                        address: "${CommonHelper.gqlStr(destination?.address)}",
                        addressDesc: "${LocationHelper.getSubRegionCity(destination.address)}",
                        lat: ${destination?.lat},
                        lng: ${destination?.lng},
                        picName: "${destination?.picName}",
                        picMobile: "${destination?.picMobile}",
                     }`
                  )}],
                  deliveryDate: "${deliveryDtStr}",
                  transWorkerSrvStatus: "${transWorkerSrvStatus}",
                  isTest: ${isTest}) {
                  id
                  gid
                  name
                  type
                  phone
                  email
                  level
                  rating
                  service {
                     serviceCode
                     priceScheme
                     pricePerUnit
                     pricePerKmShort
                     maxKmShort
                     pricePerKmMed
                     maxKmMed
                     pricePerKmLong
                     minKm
                     minPrice
                     isSpecialPrice
                     pickupPricePerKm
                     pickupFreeKmDistance
                     pickupCoverageKm
                     capacityKg
                     maxLoadCapacity
                     overCapacitySurcharge
                     returnPriceRatio
                     transitPrice
                     verificationStatus
                  }
                  transWorkerService {
                     serviceCode
                     priceScheme
                     pricePerUnit
                     pricePerKmShort
                     maxKmShort
                     pricePerKmMed
                     maxKmMed
                     pricePerKmLong
                     minKm
                     minPrice
                     pickupPricePerKm
                     pickupFreeKmDistance
                     pickupCoverageKm
                     capacityKg
                     maxLoadCapacity
                     overCapacitySurcharge
                     returnPriceRatio
                     transitPrice
                  }
                  location {
                     name
                     address
                     lat
                     lng
                  }
                  profileImgUrl
                  reviewCount
                  deliveryCount
                  site {
                     gid
                     name
                  }
               }
               GetFerryFee(ferryFeeCategory: ${transportType.ferryFeeCategory}, originHarbor: "${harbor}")
            }`;

      console.log("gql query:", gqlReq);
      CacheService.setApiRequest(gqlReq);

      let result = await hybridGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      console.log("searchServiceProvider result: ", JSON.stringify(result, null, 2));
      let resp = { providers: result.data.GetServiceProviders, tollFee: 0, ferryFee: result.data.GetFerryFee };
      // console.log("searchServiceProvider:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getTollFee(serviceCode: string, origin: Place, destinations: Place[]) {
      await initClient();
      console.log("getTollFee:", JSON.stringify(destinations, null, 2));

      let transportType = transportTypeSet.get(serviceCode);

      let gqlDes = ``;
      for (const destination of destinations) {
         gqlDes += `{
            name: "",
            address: "${destination.address}",
            lat: ${destination.lat}, 
            lng: ${destination.lng}
         },`;
      }

      let gqlReq = `
            query { 
               GetTollFee(
                  tollFeeCategory: ${transportType?.tollFeeCategory},
                  originPlace: {
                     name: "",
                     address: "${origin.address}",
                     lat: ${origin.lat}, 
                     lng: ${origin.lng}
                  },
                  destinationPlaces: [${gqlDes}],
               )
            }`;

      console.log("gql query:", gqlReq);
      let result;

      result = await graphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetTollFee;
      console.log("getTollFee:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async searchProvider(provider: Provider, pageSize: number, page: number) {
      await initAuthClient();
      console.log("searchProvider:", JSON.stringify(provider, null, 2));

      let providerPageCountGql =
         page === 1
            ? `
            GetProvidersPageCount(
               providerName: "${provider ? provider.name : ""}",
               status: "",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetProviders(
                  providerName: "${provider ? provider.name : ""}",
                  status: "",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  id
                  gid
                  name
                  profileImgUrl
               }
               ${providerPageCountGql}
            }`;

      console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      console.log("result:", JSON.stringify(result, null, 2));

      let resp = { providers: result.data.GetProviders, pageCount: result.data.GetProvidersPageCount };

      console.log("searchProviders:", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getProviderNearestSite(provider: Provider, origin: Place) {
      await ReferenceService.initTransportTypes();
      await initAuthClient();
      console.log("getProviderNearestSite:", JSON.stringify(provider, null, 2), JSON.stringify(origin, null, 2));

      let gqlReq = `
            query { 
               GetProviderNearestSite(
                  gid: "${provider.gid}", 
                  origin: {
                     name: "${origin.name}",
                     address: "${origin.address}",
                     lat: ${origin.lat},
                     lng: ${origin.lng},
                  }) {
                  gid
                  location {
                     name
                     address
                     lat
                     lng
                  }
               }
            }`;

      console.log("gql query:", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));
      let resp = result.data.GetProviderNearestSite;
      console.log("getProviderNearestSite:", JSON.stringify(resp, null, 2));

      return resp;
   }
}

const Service = new ProviderService();

export default Service;
