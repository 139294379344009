import React, { useState } from "react";

import { MdPlace, MdCheck, MdCall, MdSms, MdNavigateNext } from "react-icons/md";
import { FaRegCalendarAlt, FaCameraRetro, FaRegImage } from "react-icons/fa";
import { BiCheckCircle, BiCopy } from "react-icons/bi";
import { AiOutlineSwap, AiOutlineUser, AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { GoChevronLeft, GoClock } from "react-icons/go";
import { FiTruck } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";

import "./CustomButton.css";

const map = "map";
const call = "call";
const message = "message";
const calendar = "calendar";
const clock = "clock";
const camera = "camera";
const image = "image";
const more = "more";
const check = "check";
const remove = "remove";
const swap = "swap";
const edit = "edit";
const ok = "ok";
const cancel = "cancel";
const prev = "prev";
const add = "add";
const close = "close";
const truck = "truck";
const person = "person";
const whatsapp = "whatsapp";
const copy = "copy";

interface CustomButtonParams {
   loading?: boolean;
   loadingRef?: string;
   dynamicText?: boolean;
   text?: string;
   secondary?: boolean;
   warning?: boolean;
   navigate?: boolean;
   transparent?: boolean;
   onClick?: any;
   className?: any;
   style?: any;
   height?: number;
   width?: number;
   iconRight?: boolean;
   icon?: "map" | "call" | "message" | "calendar" | "clock" | "camera" | "image" | "more" | "check" | "remove" | "swap" | "edit" | "ok" | "cancel" | "add" | "prev" | "close" | "truck" | "person" | "whatsapp" | "copy";
}

export default function CustomButton(props: CustomButtonParams) {
   const [panelStyle] = useState(props.text ? (props.dynamicText && window.innerWidth < 450 ? "custom-button-panel" : "custom-button-panel-with-text") : "custom-button-panel");
   const [style] = useState(props.width ? (props.dynamicText && window.innerWidth < 450 ? props.style : { ...props.style, width: props.width }) : props.style);
   const backgroundColorStyle = props.secondary
      ? "custom-button-bg-secondary"
      : props.warning
      ? "custom-button-bg-warning"
      : props.transparent
      ? "custom-button-bg-transparent"
      : props.navigate
      ? "custom-button-bg-navigate"
      : "custom-button-bg-primary";
   const imageStyle =
      props.icon === "remove"
         ? "custom-button-image-delete"
         : props.transparent && props.navigate
         ? "custom-button-image-navigate-dark"
         : props.transparent
         ? "custom-button-image-dark" + (!props.text ? " big-image" : "")
         : props.navigate
         ? "custom-button-image-navigate"
         : "custom-button-image" + (!props.text ? " big-image" : "");
   const getIcon = (icon: any) => {
      switch (icon) {
         case map:
            return <MdPlace className={imageStyle} />;
         case call:
            return <MdCall className={imageStyle} />;
         case calendar:
            return <FaRegCalendarAlt name="calendar-alt" className={imageStyle} />;
         case clock:
            return <GoClock className={imageStyle} />;
         case camera:
            return <FaCameraRetro className={imageStyle} />;
         case image:
            return <FaRegImage className={imageStyle} />;
         case message:
            return <MdSms className={imageStyle} />;
         case more:
            return <MdNavigateNext className={imageStyle} />;
         case check:
            return <BiCheckCircle className={imageStyle} />;
         case swap:
            return <AiOutlineSwap className={imageStyle} />;
         case edit:
            return <RiEditLine className={imageStyle} />;
         case ok:
            return <MdCheck className={imageStyle} />;
         case cancel:
            return <ImCancelCircle className={imageStyle} />;
         case remove:
            return <RiDeleteBinLine className={imageStyle} />;
         case add:
            return <AiOutlinePlus className={imageStyle} />;
         case prev:
            return <GoChevronLeft className={imageStyle} />;
         case close:
            return <AiOutlineClose className={imageStyle} />;
         case truck:
            return <FiTruck className={imageStyle} />;
         case person:
            return <AiOutlineUser className={imageStyle} />;
         case whatsapp:
            return <BsWhatsapp className={imageStyle} />;
         case copy:
            return <BiCopy className={imageStyle} />;
      }
   };

   return (
      <div className="custom-button">
         {props.loading && !props.loadingRef ? (
            <div className={panelStyle + " custom-button-bg-loading " + props.className} style={style} onClick={props.onClick}></div>
         ) : props.iconRight ? (
            <div className={panelStyle + " " + backgroundColorStyle + " " + props.className} style={style} onClick={props.onClick}>
               {props.text ? props.dynamicText && window.innerWidth < 450 ? null : <div className="custom-button-left-text regular-bold-white-font">{props.text}</div> : null}
               {getIcon(props.icon)}
            </div>
         ) : (
            <div className={panelStyle + " " + backgroundColorStyle + " " + props.className} style={style} onClick={props.onClick}>
               {getIcon(props.icon)}
               {props.text ? props.dynamicText && window.innerWidth < 450 ? null : <div className="custom-button-text regular-bold-white-font">{props.text}</div> : null}
            </div>
         )}
      </div>
   );
}
