import { Place } from "../model";
import * as FormatHelper from "../helper/FormatHelper";

export function getCity(address?: string) {
   let city = "";
   if (address) {
      const addrs = address.split(",");
      if (addrs.length > 2) {
         let lastIndex = addrs.length - 1;
         if (addrs[lastIndex].trim() === "Indonesia") {
            city = addrs[lastIndex - 2];
         } else {
            city = addrs[lastIndex - 1];
         }
      } else {
         city = address;
      }
   }

   return city;
}

export function getSubRegionCity(address?: string): string {
   let subRegionCity = "";
   if (address) {
      const addrs = address.split(",");
      console.log("getSubRegionCity address: ", address);
      if (addrs.length > 2) {
         let lastIndex = addrs.length - 1;
         if (addrs[lastIndex].trim() === "Indonesia") {
            subRegionCity = addrs[lastIndex - 3] + ", " + addrs[lastIndex - 2];
         } else {
            subRegionCity = addrs[lastIndex - 2] + ", " + addrs[lastIndex - 1];
         }
      } else {
         subRegionCity = address;
      }
   }

   subRegionCity = subRegionCity.replace("Kec. ", "");
   subRegionCity = subRegionCity.replace("Kota ", "");
   subRegionCity = subRegionCity.replace("SBY", "Surabaya");

   return subRegionCity ? subRegionCity.trim() : subRegionCity;
}

export function getPlaceShortAddress(place?: Place): string {
   let shortAddress = getShortAddress(place?.address);
   if (place?.name && shortAddress.toLowerCase().indexOf(place?.name.toLowerCase()) === -1) {
      shortAddress = FormatHelper.formatCamel(place?.name) + ", " + shortAddress;
   }
   return shortAddress ? shortAddress.trim() : " ";
}

export function getShortAddress(address?: string): string {
   let shortAddress = "";
   if (address) {
      const addrs = address.split(",");
      if (addrs.length > 2) {
         let lastIndex = addrs.length - 1;
         if (addrs[lastIndex].trim() === "Indonesia") {
            lastIndex = lastIndex - 4;
         } else {
            lastIndex = lastIndex - 3;
         }
         for (let i = 0; i <= lastIndex; i++) {
            if (i > 0) {
               shortAddress += ", ";
            }
            shortAddress += addrs[i];
         }
      } else {
         shortAddress = address;
      }
   }

   return shortAddress ? shortAddress.trim() : " ";
}

export function getAddress(address?: string): string {
   let shortAddress = "";
   if (address) {
      shortAddress = address.replace("Indonesia", "");
   }

   return shortAddress ? shortAddress.trim() : shortAddress;
}

export function getCityFromPlaceDesc(address?: string) {
   let city = "";
   if (address) {
      const addrs = address.split(",");
      if (addrs.length === 2) {
         city = addrs[1].replace("Kota", "");
      } else {
         city = address.replace("Kota", "");
      }
   }

   city = city.replace("SBY", "Surabaya");
   return city;
}

export function formatDistance(distance: number) {
   if (distance === undefined || distance === 0) {
      return "";
   } else {
      return Number(distance).toFixed(0) + " KM";
   }
}

export function formatTripTime(time: number) {
   if (time === undefined || time === 0) {
      return "";
   } else {
      let day = Math.floor(time / (24 * 3600));
      let hour = Math.floor(time / 3600);

      if (day > 0) {
         hour = Math.floor(time % (24 * 3600));
         hour = Math.floor(hour / 3600);
      }

      let minute = Math.round((time % 3600) / 60);
      let tripTime = hour + " Jam " + (minute < 10 ? `0${minute} Jam` : minute) + " Menit";

      if (day > 0) {
         tripTime = day + " Hari " + tripTime;
      }

      return tripTime;
   }
}

export function calcDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
   const PI: number = 3.141592653589793;

   let radlat1 = (PI * lat1) / 180;
   let radlat2 = (PI * lat2) / 180;

   let theta = lng1 - lng2;
   let radtheta = (PI * theta) / 180;

   let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

   if (dist > 1) {
      dist = 1;
   }

   dist = Math.acos(dist);
   dist = (dist * 180) / PI;
   dist = dist * 60 * 1.1515;

   dist = dist * 1.609344;

   return dist;
}
