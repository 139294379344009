import React, { useEffect, useState } from "react";

import { ImageView, CustomButton } from ".";

import Skeleton from "react-loading-skeleton";

import { Option } from "../../model";

import "./OptionView.css";

export function OptionView(props: any) {
   const item: Option = props.option;
   const [desc, setDesc] = useState("");

   const onPressSelect = () => {
      props.onPressSelect(item);
   };

   useEffect(() => {
      if (item.desc) {
         setDesc(item.desc);
      }
   }, [item]);

   return (
      <div className="option-view-item regular-bottom-border">
         <div className="option-view-content">
            {item.imageUrl && (
               <div>
                  <ImageView className="option-view-pic-image" uploadUrl={item.imageUrl} alt="carrier" />
               </div>
            )}
            <div className="option-view-pic" style={item.imageUrl ? { paddingLeft: 10 } : {}}>
               <div className="regular-bold-font">{desc || <Skeleton delay={1} width={350} />}</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
               <CustomButton loading loadingRef={item.code} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressSelect} />
            </div>
         </div>
      </div>
   );
}

export default OptionView;
