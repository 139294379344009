import * as React from "react";

import { IconContext } from "react-icons";

import { MdFirstPage } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { MdLastPage } from "react-icons/md";
import { FaCameraRetro, FaRegCalendarAlt } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";

import "./SquareButton.css";

export default function SquareButton(props: any) {
   const panelStyle =
      props.page && props.current
         ? "square-button-page-panel dark-border"
         : props.page
         ? "square-button-page-panel regular-border"
         : props.ok || props.cancel || props.camera || props.join || props.star || props.alert || props.calendar
         ? "square-button-panel"
         : "square-button-panel-text-only";

   const colorStyle = props.page ? "square-button-page-color" : props.primary ? "square-button-primary-color" : props.warning ? "square-button-warning-color" : "square-button-regular-color";

   const textStyle =
      props.page && props.current
         ? "regular-bold-purple-font"
         : props.page
         ? "regular-bold-grey-font"
         : props.current
         ? "square-button-text-only big-bold-font"
         : props.ok || props.cancel || props.camera || props.join || props.star || props.alert || props.calendar
         ? "square-button-text bold-white-font"
         : "square-button-text-only bold-white-font";

   return (
      <IconContext.Provider value={{ color: "white", size: "24px" }}>
         <div className={panelStyle + " " + colorStyle} style={props.style} onClick={props.onClick}>
            {props.ok ? <AiOutlineCheckCircle className="square-button-icon bold-white-font" /> : null}
            {props.cancel ? <AiOutlineCloseCircle className="square-button-icon bold-white-font" /> : null}
            {props.star ? <AiOutlineStar className="square-button-icon bold-white-font" /> : null}
            {props.camera ? <FaCameraRetro className="square-button-icon bold-white-font" /> : null}
            {props.calendar ? <FaRegCalendarAlt className="square-button-icon bold-white-font" style={{ height: 18, width: 18 }} /> : null}
            {props.join ? <AiOutlineUserAdd className="square-button-icon bold-white-font" /> : null}
            {props.alert ? <FiAlertTriangle className="square-button-icon bold-white-font" /> : null}
            {props.page ? (
               props.first ? (
                  <MdFirstPage className="bold-font" />
               ) : props.prev ? (
                  <MdNavigateBefore className="bold-font" />
               ) : props.next ? (
                  <MdNavigateNext className="bold-font" />
               ) : props.last ? (
                  <MdLastPage className="bold-font" />
               ) : null
            ) : null}
            {props.text ? <div className={textStyle}>{props.text}</div> : null}
            {props.next && !props.page ? <MdNavigateNext /> : null}
         </div>
      </IconContext.Provider>
   );
}
