import React, { useState, useEffect } from "react";

import Skeleton from "react-loading-skeleton";

import { FadeView, ImageView } from ".";
import OriginDestination from "./OriginDestination";
import CustomButton from "./CustomButton";

import { Shipment } from "../../model";
import { isAdmin, isCustomer, isProvider, isTransporter } from "../../model/User";

import * as ReferenceHelper from "../../helper/ReferenceHelper";
import * as CalendarHelper from "../../helper/CalendarHelper";
import * as LocationHelper from "../../helper/LocationHelper";
import * as CommonHelper from "../../helper/CommonHelper";

import { CacheService } from "../../service";

import * as Master from "../../reference/Master";

import "./ShipmentView.css";

export function ShipmentView(props: any) {
   const shipment: Shipment = props.shipment;
   const [bookingCode, setBookingCode] = useState(shipment.bookingCode);
   const [statusLabel, setStatusLabel] = useState("");
   const [status, setStatus] = useState("");
   const [deliveryDateLabel, setDeliveryDateLabel] = useState("");
   const [deliveryDate, setDeliveryDate] = useState("");
   const [deliveryEndDateLabel, setDeliveryEndDateLabel] = useState("");
   const [deliveryEndDay, setDeliveryEndDay] = useState("");
   const [deliveryEndDate, setDeliveryEndDate] = useState("");
   const [user] = useState(CacheService.getUser());
   const [contactName, setContactName] = useState("");
   const [contactMobile, setContactMobile] = useState("");
   const [contactImgUrl, setContactImgUrl] = useState("");
   //const [mobile, setMobile] = useState('');
   const [providerName, setProviderName] = useState("");
   const [verificationStatus, setVerificationStatus] = useState("");

   const onClickCall = () => {
      window.open("tel:+" + contactMobile);
   };

   const onClickChat = () => {
      window.open("https://wa.me/" + contactMobile);
   };

   const onPressMore = () => {
      props.onPressMore(shipment);
   };

   useEffect(() => {
      if (shipment.bookingCode) {
         setBookingCode("# " + shipment.bookingCode);
         setStatusLabel("Status");
         setStatus(ReferenceHelper.getShipmentStatusDesc(shipment.status));
         setDeliveryDateLabel("Tanggal Pengiriman");
         setDeliveryDate(CalendarHelper.getDayDateMonthTime(shipment.deliveryDate));
         setDeliveryEndDateLabel("Estimasi Sampai");
         setDeliveryEndDay(CalendarHelper.getDay(shipment.deliveryEndDate) + ",");
         setDeliveryEndDate(CalendarHelper.getDateMonthTime(shipment.deliveryEndDate));
         setContactName((user.customer ? (shipment.transporter ? shipment.transporter.name : shipment.provider?.name) : shipment.picOrderName) || "");
         setContactMobile(CommonHelper.formatMobile((user.customer ? (shipment.transporter ? shipment.transporter.phone : shipment.provider?.phone) : shipment.picOrderMobile) || Master.MOBILE_LINTAS));
         //setMobile((isCustomer(user) ? (item.transporter ? item.transporter.mobile : item.provider?.phone) : item.customer?.phone) || '');
         setContactImgUrl((user.customer ? (shipment.transporter ? shipment.transporter.profileImgUrl : shipment.provider?.profileImgUrl) : shipment.customer?.profileImgUrl) || Master.IMAGE_ALT_PERSON);
         setProviderName((isCustomer(user) && shipment.transporter ? shipment.provider?.name : "") || "");
         setVerificationStatus(ReferenceHelper.getServiceVerificationStatusDesc(shipment.provider?.service?.verificationStatus));
      } else {
         setBookingCode("");
         setContactName("");
         setContactImgUrl("");
         setProviderName("");
         setStatusLabel("");
         setStatus("");
         setDeliveryEndDay("");
         setDeliveryDateLabel("");
         setDeliveryDate("");
         setDeliveryEndDateLabel("");
         setDeliveryEndDate("");
         setVerificationStatus("");
      }
   }, [shipment, user]);

   return (
      <div>
         <FadeView show={true} duration="0" style={props.style}>
            <div className="shipment-view-item regular-bottom-border">
               <div className="shipment-view-top">
                  <div className="shipment-view-shipment-no regular-bold-font">{bookingCode || <Skeleton delay={1} width={75} />}</div>
               </div>
               <div className="shipment-view-content">
                  <div>
                     <ImageView className="shipment-view-pic-image" uploadUrl={contactImgUrl} profile alt="carrier" />
                  </div>
                  <div className="shipment-view-pic-name">
                     <span className=" regular-bold-font">{contactName}</span>
                     <br />
                     <div className="service-view-provider">{providerName && <span className="service-view-provider-info regular-white-font">{providerName}</span>}</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                     <CustomButton loading loadingRef={bookingCode} icon={"call"} dynamicText text="Panggil" onClick={onClickCall} width={100} style={{ marginLeft: 5 }} />
                     <CustomButton loading loadingRef={bookingCode} icon={"message"} dynamicText text="Pesan" onClick={onClickChat} width={100} style={{ marginLeft: 5 }} />
                  </div>
               </div>
               <div className="shipment-view-bottom">
                  <div className="shipment-view-origin-destination">
                     <OriginDestination origin={LocationHelper.getCityFromPlaceDesc(shipment.originDesc)} destination={LocationHelper.getCityFromPlaceDesc(shipment.destinationDesc)} />
                  </div>
                  <div style={{ flex: 1 }}>
                     <div className="shipment-view-title regular-bold-font">{statusLabel || <Skeleton delay={1} width={75} />}</div>
                     <div className="shipment-view-desc regular-font">{status || <Skeleton delay={1} width={100} />}</div>
                     {isCustomer(user) && (
                        <div>
                           <div className="shipment-view-title regular-bold-font">{deliveryEndDateLabel || <Skeleton delay={1} width={75} />}</div>
                           <div className="shipment-view-desc" style={{ display: "flex", flexWrap: "wrap" }}>
                              <div className="regular-font" style={{ paddingRight: 3 }}>
                                 {deliveryEndDay || <Skeleton delay={1} width={100} />}
                              </div>
                              <div className="regular-font">{deliveryEndDate}</div>
                           </div>
                        </div>
                     )}
                     {(isAdmin(user) || isProvider(user) || isTransporter(user)) && (
                        <div>
                           <div className="shipment-view-title regular-bold-font">{deliveryDateLabel || <Skeleton delay={1} width={75} />}</div>
                           <div className="shipment-view-desc regular-font">{deliveryDate || <Skeleton delay={1} width={100} />}</div>
                        </div>
                     )}
                     {user && user.isAdmin && shipment.provider?.service?.verificationStatus !== Master.STATUS_VERIFIED && (
                        <div>
                           <div className={verificationStatus ? "shipment-view-status regular-white-font" : ""}>{verificationStatus || <Skeleton delay={1} width={150} />}</div>
                        </div>
                     )}
                  </div>
                  <div>
                     <CustomButton loading loadingRef={bookingCode} icon={"more"} style={{ marginLeft: 5 }} onClick={onPressMore} />
                  </div>
               </div>
            </div>
         </FadeView>
      </div>
   );
}

export default ShipmentView;
