import { gql } from "@apollo/client";

import { Carrier } from "../model";
import { authGraphClient, initAuthClient } from "./GraphService";

import * as Config from "../Config";

class CarrierService {
   async registerCarrier(carrier: Carrier) {
      await initAuthClient();
      console.log("registerCarrier: ", JSON.stringify(carrier, null, 2));

      let g = `
         mutation {
            RegisterCarrier(
               inputCarrier: {
                  code: "${carrier.code}", 
                  name: "${carrier.name}", 
                  fleetCategoryCode: "${carrier.fleetCategoryCode}", 
                  transportTypeCode: "${carrier.transportTypeCode}", 
                  licenseNo: "${carrier.licenseNo}", 
                  licenseExpiry: "${carrier.licenseExpiry.toISOString()}", 
                  licenseImgID: ${carrier.licenseImg?.id}, 
                  certificateNo: "${carrier.certificateNo}", 
                  certificateExpiry: "${carrier.certificateExpiry.toISOString()}", 
                  certificateImgID: ${carrier.certificateImg?.id},
                  profileImgID: ${carrier.profileImg?.id},
                  profileImgUrl: "${carrier.profileImgUrl}", 
                  capacityLength: ${carrier.capacityLength},
                  capacityWidth: ${carrier.capacityWidth},
                  capacityHeight: ${carrier.capacityHeight}, 
                  verificationStatus: "",
                  status: ""
               }
            )
         }`;

      console.log("gql: ", g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let carrierID = result.data.RegisterCarrier;

      console.log(result.data);

      return carrierID;
   }

   async updateCarrier(carrier: Carrier) {
      await initAuthClient();
      //console.log('updateCarrier: ', JSON.stringify(carrier, null, 2));

      let g = `
            mutation {
               UpdateCarrier(
                  inputCarrier: {
                     gid:  "${carrier.gid}",
                     code: "${carrier.code}", 
                     name: "${carrier.name}", 
                     fleetCategoryCode: "${carrier.fleetCategoryCode}", 
                     transportTypeCode: "${carrier.transportTypeCode}", 
                     licenseNo: "${carrier.licenseNo}", 
                     licenseExpiry: "${carrier.licenseExpiry.toISOString()}", 
                     licenseImgID: ${carrier.licenseImg?.id}, 
                     certificateNo: "${carrier.certificateNo}", 
                     certificateExpiry: "${carrier.certificateExpiry.toISOString()}", 
                     certificateImgID: ${carrier.certificateImg?.id},
                     profileImgID: ${carrier.profileImg?.id},
                     profileImgUrl: "${carrier.profileImgUrl}", 
                     capacityLength: ${carrier.capacityLength},
                     capacityWidth: ${carrier.capacityWidth},
                     capacityHeight: ${carrier.capacityHeight}, 
                     verificationStatus: "${carrier.verificationStatus}",
                     status: "${carrier.status}",
                  }
               )
            }`;

      //console.log('gql: ', g);

      let result = await authGraphClient.mutate({
         mutation: gql`
            ${g}
         `,
      });

      let carrierID = result.data.RegisterCarrier;

      console.log(result.data);

      return carrierID;
   }

   async searchCarrier(carrier: Carrier, pageSize: number, page: number) {
      await initAuthClient();
      console.log("searchCarrier: ", JSON.stringify(carrier, null, 2));

      let carrierPageCountGql =
         page === 1
            ? `
            GetCarriersPageCount(
               carrierName: "${carrier.name ? carrier.name : ""}", 
               providerName: "${carrier.provider ? carrier.provider.name : ""}",
               status: "${carrier.status ? carrier.status : ""}",
               pageSize: ${pageSize})`
            : ``;

      let gqlReq = `
            query { 
               GetCarriers(
                  carrierName: "${carrier.name ? carrier.name : ""}", 
                  providerName: "${carrier.provider ? carrier.provider.name : ""}",
                  status: "${carrier.status ? carrier.status : ""}",
                  pageSize: ${pageSize}, 
                  page: ${page}) 
               {
                  id
                  gid
                  name
                  code
                  transportTypeCode
                  licenseNo
                  profileImgUrl
                  provider {
                     name
                  }
                  verificationStatus
               }
               ${carrierPageCountGql}
            }`;

      console.log("gql query: ", gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));
      let resp = { carriers: result.data.GetCarriers, pageCount: result.data.GetCarriersPageCount };
      console.log("searchCarrier: ", JSON.stringify(resp, null, 2));

      return resp;
   }

   async getCarrier(gid?: string) {
      await initAuthClient();
      //console.log('getCarrier: ', gid);

      let gqlReq = `
            query { 
               GetCarrier(gid: "${gid}") {
                  id
                  gid
                  name
                  code
                  transportTypeCode
                  fleetCategoryCode
                  profileImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  licenseNo
                  licenseExpiry
                  licenseImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  certificateNo
                  certificateExpiry
                  certificateImg {
                     id
                     name
                     status
                     type
                     url
                  }
                  capacityLength
                  capacityWidth
                  capacityHeight
                  verificationStatus
                  status
               }
            }`;

      //console.log('gql query: ', gqlReq);
      let result;

      result = await authGraphClient.query({
         query: gql`
            ${gqlReq}
         `,
      });

      //console.log('result: ', JSON.stringify(result, null, 2));

      let carrier: Carrier = result ? result.data.GetCarrier : null;

      //console.log('getCarrier: ', JSON.stringify(carrier, null, 2));

      return carrier;
   }

   async updatePosition(ids: number[], lat: number, lng: number) {
      await initAuthClient();

      let request = ids.map((id) => {
         return { id: id, pos: { lat: lat, lng: lng } };
      });

      console.log("updatePosition: ", JSON.stringify(request, null, 2));

      const response = await fetch(`${Config.LINTAS_API}/position/update`, {
         method: "POST",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         },
         body: JSON.stringify(request),
      });

      return response.body;
   }

   async getPosition(ids: number[]) {
      await initAuthClient();

      let request = ids.map((id) => {
         return { id: id };
      });

      const response = await fetch(`${Config.LINTAS_API}/position`, {
         method: "POST",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         },
         body: JSON.stringify(request),
      });

      const content = await response.json();
      console.log("getPosition response: ", JSON.stringify(content));
      return content;
   }
}

const Service = new CarrierService();

export default Service;
