import React from "react";
import FadeView from "./FadeView";

import Skeleton from "react-loading-skeleton";

import "./Places.css";

export default function Places(props: any) {
   //console.log('places ==>' + JSON.stringify(props.data));
   return (
      <div className={`cmn-places ${props.className}`}>
         <div className="place-container">
            {props.data.map((place: any, key: number) => (
               <FadeView show={true} duration="0" key={key}>
                  <div onClick={() => props.onPress(place)} style={{ cursor: "pointer" }}>
                     <div className="place-item regular-bottom-border">
                        <div className="place-name regular-bold-purple-font">{place.name || <Skeleton delay={1} width={200} />}</div>
                        <div className="place-address regular-font">{place.formatted_address || (!place.name && <Skeleton delay={1} width={300} count={2} />)}</div>
                     </div>
                  </div>
               </FadeView>
            ))}
         </div>
      </div>
   );
}
